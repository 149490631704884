import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { pepLevelDescriptions, pepLevelTitles } from 'src/app/services/dashboard.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { Evidence, IndividualProfile, PoiEntry } from 'src/nswag';
import tippy from 'tippy.js';

const pepLevels = [
  "na",
  "PEP Tier 1",
  "PEP Tier 2",
  "PEP Tier 3",
  "PEP Tier 4",
  "By Association"
];
@Component({
  selector: 'mon-peps',
  templateUrl: './peps.component.html',
  styleUrls: ['./peps.component.scss']
})
export class PepsComponent implements OnChanges, AfterViewInit {
  public isPEP: boolean;
  public isCurrent: boolean;
  public isPrevious: boolean;
  public isPersonOfInterest: boolean;
  public isByAssociation: boolean;
  public evidences: Evidence[]
  public PEPLevelDescription: string;

  constructor() { }

  @Input() profile: IndividualProfile;
  @Input() clientId: string;
  @Input() name: string;
  @Input() incHeader = true;
  @Input() allowDiscounting = true;
  @Input() reportMode: boolean = false;

  ngAfterViewInit(): void {
    if (this.isByAssociation) {
      this.addPEPHoverInformation(5);
    }
    else if (this.isPEP) {
      this.checkPEPHoverInformation();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isCurrent = this.profile?.pepEntries.current?.length > 0;
    this.isPrevious = this.profile?.pepEntries?.former?.length > 0;
    this.isPersonOfInterest = this.profile?.poiEntries?.length > 0;
    this.isByAssociation = this.profile?.pepByAssociationEntries?.length > 0;
    this.isPEP = this.isCurrent || this.isPrevious || this.isPersonOfInterest;
    if (this.isPEP) {
      //this.name = UtilityService.getIndividualName(this.profile);
      const level = this.findPEPLevel();
      this.PEPLevelDescription = level > 0 ? "Level " + this.findPEPLevel() + " PEP" : "PEP";

      // Parse for evidence
      this.evidences = Array<Evidence>();
      this.parseEvidence(this.profile?.pepEntries?.current);
      this.parseEvidence(this.profile?.pepEntries?.former);
      this.parseEvidence(this.profile?.pepByAssociationEntries);
      this.parseEvidence(this.profile?.poiEntries);

      this.evidences.sort((a, b) => {
        if (a.publicationDateIso) {
          if (b.publicationDateIso) {
            var x = new Date(a.publicationDateIso);
            var y = new Date(b.publicationDateIso);
            return x > y ? 1 : -1;
          }
          return 1;
        }
        if (a.captureDateIso) {
          if (b.captureDateIso) {
            var x = new Date(a.captureDateIso);
            var y = new Date(b.captureDateIso);
            return x > y ? 1 : -1;
          }
          return 1;
        }
        return 0;
      });
    }
  }

  private findPEPLevel(): number {
    return pepLevels.findIndex(l => l == this.profile.pepEntries.pepTier);
  }

  public checkPEPHoverInformation() {
    let i = this.findPEPLevel()
    if (i > -1) {
      this.addPEPHoverInformation(i);
    }
  }

  addPEPHoverInformation(pepLevel: number) {
    let el: any = document.getElementById('pepLevelElement');
    if (el) {
      let tippyCtrl = el._tippy;
      if (!tippyCtrl) {
        tippy('#pepLevelElement', {
          allowHTML: true,
          content: "<b>" + pepLevelTitles[pepLevel] + "</b></br>" + pepLevelDescriptions[pepLevel]
        });
      }
    }
  }

  parseEvidence(list: any[]) {
    if (list) {
      for (let val of list) {
        for (let id of val.evidenceIds) {
          var ev = this.profile.evidences.find((ev, i) => {
            return ev.evidenceId == id;
          });
          if (ev) {
            this.evidences.push(ev);
          }
        }
      }
    }
  }

  getCountry(code: string): string {
    return UtilityService.getCountryFromCode(code);
  }
}
