import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import { RecipientQuestionSetResponse } from 'src/nswag';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { UtilityService } from '../utilities/utilities';

Chart.register(MatrixController, MatrixElement);

interface IGroupReportData {
  x: string,
  y: string,
  rating: string,
  score: number
};

@Component({
  selector: 'heatmap',
  templateUrl: './heatmap.component.html',
  styleUrls: ['./heatmap.component.scss']
})
export class HeatmapComponent implements OnInit {

  public chart: any;
  @Input() recipientResponse: RecipientQuestionSetResponse[] = [];
  @Input() type: string;
  public questionSetLabels: string[] = ['Overall Rating'];
  public recipientLabels: string[] = [];
  public overallScores: IGroupReportData[] = [];
  constructor(public utilityService: UtilityService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadChart();
    });
  }

   async ngOnChanges(changes: SimpleChanges) {
     if (changes['recipientResponse']) {
       setTimeout(() => {
         this.loadChart();
       }, 1);
     }
   }

  ngOnInit(): void {

  }

  public loadChart() {
    // filter chart //
    if (this.type == undefined) return;
    if (this.recipientResponse == undefined) return;

    const typeResponses = this.recipientResponse.filter(x => x.category === this.type)
    if (typeResponses.length == 0) {
      return;
    }
    if (this.chart != undefined){
      this.chart?.destroy?.();
    } 

    const responses = typeResponses.sort((a, b) => a.name.localeCompare(b.name));
    responses.forEach(element => {
      if (!this.questionSetLabels.includes(element.name)){
        this.questionSetLabels.push(element.name);
      }

      if (!this.recipientLabels.includes(element.companyName)){
        const info: IGroupReportData = { x: 'Overall Rating', y: element.companyName, rating: element.overallRating, score: element.overallScore };
        this.overallScores.push(info);
        this.recipientLabels.push(element.companyName);
      }
    });

    const ctx = document.getElementById('myChart_heatmap_' + this.type) as HTMLCanvasElement;
    if (ctx != null){
      ctx.height = 60 + (this.recipientLabels.length * 64);
      const data = this.utilityService.getGroupReportData(responses, this.overallScores, this.questionSetLabels.length, this.recipientLabels.length);
      const options = this.utilityService.getGroupReportOptions(this.questionSetLabels, this.recipientLabels);
      const myChart = new Chart(ctx, { type: 'matrix', data: data, options: options, plugins: [ChartDataLabels]});
      this.chart = myChart;
    }
  }
}
