import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { PrimeNgModule } from 'src/app/modules/prime-ng/prime-ng.module';
import { PrimeLoaderService } from './prime-loader.service';

@Component({
  selector: 'app-prime-loader',
  imports: [CommonModule, PrimeNgModule],
  standalone: true,
  templateUrl: './prime-loader.component.html',
  styleUrls: ['./prime-loader.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})

export class PrimeLoaderComponent {
  constructor(public primeLoaderService: PrimeLoaderService) {}
}
