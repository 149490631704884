<!-- Display matched profile -->
<div *ngIf="isMatched; else notMatched">
    <div id="investigations-tabs">
        <div *ngIf="canClose" class="float-end">
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
            </button>
        </div>
        
        <ul ngbNav #nav="ngbNav" class="nav-tabs flex-nowrap">
            <li [ngbNavItem]="0" (click)="setTabAsClicked(0)">
                <a ngbNavLink><span title="Profile" aria-label="Profile" class="icon-user"></span></a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <div class="row zero-margin-padding">
                            <div class="col zero-margin-padding">
                                <app-investigation *ngIf="client?.client?.id" [selectedClient]="client.client" [currentModule]="currentModule"></app-investigation> 
                            </div>
                        </div>
                        <mon-individualprofile *ngIf="isIndividual" [isArchived]="isArchived" [client]="client" [profile]="profile" (clientChange)="onClientChange($event)"></mon-individualprofile>
                        <mon-businessprofile *ngIf="isBusiness" [isArchived]="isArchived" [client]="client" [profile]="profile" (clientChange)="onClientChange($event)"></mon-businessprofile>
                        <div *ngIf="allowDiscounting" class="row">
                            <div class="col">
                                <app-detail-links 
                                    [client]="client"
                                    [profile]="profile"
                                    [discounted]="true"
                                    [screened]="true"
                                    (addToMonitoringEvent)="addToMonitoring()"
                                    (viewMatchesEvent)="viewMatches()"
                                    (searchMatchesEvent)="searchMatches()">
                                </app-detail-links>
                            </div>
                        </div>                    
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="1" (click)="setTabAsClicked(1)">
                <a ngbNavLink>
                    <mon-alerticon [lampsStatus]="client?.matchStatus?.hasSanctions" [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Sanctions" icon="icon-prohibited"></mon-alerticon>
                </a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-sanctions [clientId]="client?.client?.id" [profile]="profile" [name]="name" [allowDiscounting]="allowDiscounting"></mon-sanctions>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" (click)="setTabAsClicked(2)">
                <a ngbNavLink>
                    <mon-alerticon [lampsStatus]="client?.matchStatus?.isPEP" [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="PEPs" icon="icon-library2"></mon-alerticon>
                </a>
                <ng-template ngbNavContent>
                    <div class="card-body overflow-auto text-wrap text-break text-break">
                        <mon-peps *ngIf="isIndividual" [clientId]="client?.client?.id" [profile]="profile" [name]="name" [allowDiscounting]="allowDiscounting"></mon-peps>
                        <mon-soe *ngIf="isBusiness" [clientId]="client?.client?.id" [profile]="profile" [name]="name" [allowDiscounting]="allowDiscounting"></mon-soe>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="3" (click)="setTabAsClicked(3)">
                <a ngbNavLink>
                    <mon-alerticon [lampsStatus]="client?.matchStatus?.hasLawEnforcement" [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Legal" icon="icon-balance"></mon-alerticon>
                </a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-legal [clientId]="client?.client?.id" [profile]="profile" [name]="name" [allowDiscounting]="allowDiscounting"></mon-legal>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="4" (click)="setTabAsClicked(4)">
                <a ngbNavLink>
                    <mon-alerticon [lampsStatus]="client?.matchStatus?.hasAdvMedia" [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Adverse Media" icon="icon-tv"></mon-alerticon>
                </a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-adversemedia [clientId]="client?.client?.id" [profile]="profile" [name]="name" [allowDiscounting]="allowDiscounting"></mon-adversemedia>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="5" (click)="setTabAsClicked(5)">
                <a ngbNavLink><span title="Associations" aria-label="Associations" class="icon-group-work"></span></a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-individual-associations [clientId]="client.client.id" [profile]="profile" [name]="name" [allowDiscounting]="allowDiscounting"></mon-individual-associations>
                        <p></p>
                        <p></p>
                        <mon-business-associations [clientId]="client.client.id" [profile]="profile" [name]="name" [allowDiscounting]="allowDiscounting"></mon-business-associations>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>

</div>

<!-- Display button to view matches -->
<ng-template #notMatched>
    <app-not-matched 
        [client]="client" 
        [profile]="profile" 
        [name]="name" 
        [discounted]="matchesDiscounted" 
        [matches]="hasMatches" 
        [screened]="screened"
        [isArchived]="isArchived"
        [allowDiscounting] = "allowDiscounting"
        [currentModule]="currentModule"
        (clientChange)="onClientChange($event)"
        (addToMonitoringEvent)="addToMonitoring()"
        (viewMatchesEvent)="viewMatches()"
        (searchMatchesEvent)="searchMatches()">
    </app-not-matched>
</ng-template>
