import { Component, AfterViewInit, OnDestroy, } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnDestroy, AfterViewInit {


  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  next() {

  }
}
