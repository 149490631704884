import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'formly-field-primeng-calendar',
  template: `
   <textarea
    rows="5"
    pInputTextarea 
    [formControl]="formControl"
    [formlyAttributes]="field"
    [autoResize]="true"
    [ngClass]="field.className"
    pTooltip="{{ field.props.tooltip }}"
    tooltipPosition="{{ field.props.tooltipPosition || 'top' }}">
    </textarea>
    `,
  styleUrls: [
    '../report-content/report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ]
})
export class FormlyFieldPrimengTextarea extends FieldType {
  get formControl(): FormControl {
    return this.field.formControl as FormControl;
  }
}
