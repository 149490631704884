import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { Recipient } from 'src/nswag';
import { RiskAssessmentService } from '../../services/risk-assessment.service';

@Component({
  selector: 'existing-recipient-dialog',
  templateUrl: './existing-recipient-dialog.component.html',
  styleUrls: ['./existing-recipient-dialog.component.scss']
})
export class ExistingRecipientDialogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal, public riskAssessmentService: RiskAssessmentService, public alertService: AlertService) { }

  public checkedRecipients = new Array<Recipient>();
  public currentRecipient: Recipient;

  ngOnInit(): void {
    this.resetModelData();
    // populate recipients
    this.riskAssessmentService.loadExistingRecipients();
  }

  private resetModelData() {
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  canAdd(): boolean {
    var canAdd = true;
    // check if at least one tick box selected
    if (this.checkedRecipients?.length < 1) canAdd = false;
    return canAdd;
  }

  public setRecipientData(data: Array<Recipient>) {
    this.checkedRecipients = data;
  }

  addExistingRecipients() {
    this.riskAssessmentService.addExistingRecipient(this.checkedRecipients).subscribe(result => {
      if (result.isSuccess) {
        this.riskAssessmentService.loadRecipients(this.riskAssessmentService.CurrentAssessment.id);
        this.dismissModal();
      }
    },
    (error) => {
      this.dismissModal();
      this.alertService.error("Unfortunately, something has gone wrong. Please try again or contact support.");
      console.error(error);
    });
  }
}
