import { Injectable } from '@angular/core';
import { ErrorDetail } from '../models/ErrorDetail';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ErrorService {
  private problemDetailsSubject = new BehaviorSubject<ErrorDetail | null>(null);
  problemDetails$ = this.problemDetailsSubject.asObservable();

  public setProblemDetails(details: ErrorDetail) : void {
    this.problemDetailsSubject.next(details);
  }
  public setProblemDetailsFromError(error) : void {
    const json = JSON.parse(error.response);
    const problemDetail = json as ErrorDetail;
    this.setProblemDetails(problemDetail);
  }
  public reset() : void {
    this.setProblemDetails(null);
  }
}
