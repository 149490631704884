import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from './_confirmation-dialog.component';

interface IDialogParts {
  dialogSize: string;
  title: string;
  message: string;
  btnCancelText: string;
  incReason: boolean;
  btnOkText: string;
  messageClass: string;
  mandatoryNotes: boolean;
}

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

  reason: string;

  public confirm(
    title: string,
    message: string,
    mandatoryNotes: boolean = false,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm'|'lg' = 'sm',
    incReason: boolean = false,
    messageClass: string = ''): Promise<boolean> {
      return this.createDialog({ dialogSize, title, message, btnCancelText, incReason, btnOkText, messageClass, mandatoryNotes });
  }

  public information(
    title: string,
    message: string,
    mandatoryNotes: boolean = false,
    btnCancelText: string = 'OK',
    btnOkText = null,
    dialogSize: 'sm'|'lg' = 'sm',
    incReason: boolean = false,
    messageClass: string = ''): Promise<boolean> {
    return this.createDialog({ dialogSize, title, message, btnCancelText, incReason, btnOkText, messageClass, mandatoryNotes });
  }

  private createDialog({ dialogSize, title, message, btnCancelText, btnOkText, incReason, messageClass, mandatoryNotes }: IDialogParts) {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.incReason = incReason;
    modalRef.componentInstance.messageClass = messageClass;
    modalRef.componentInstance.checkForMandatoryNotes = mandatoryNotes;
    modalRef.componentInstance.reasonChanged.subscribe((r: string) => {
      this.reason = r;
    });
    this.reason = "";
    return modalRef.result;
  }
}
