import { Component, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { AddProjectRequestByAdminCommand, ListAccountsModel, ListTenantsModel, ProjectList } from 'src/nswag';
import { IntelligenceService } from '../../services/intelligence.service';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'request-project-dialog',
  templateUrl: './request-project-dialog.component.html',
  styleUrls: ['./request-project-dialog.component.scss']
})

export class RequestProjectDialogComponent implements OnInit {
  public tenantName: string;
  public userName: string;
  public reportName: string;
  public showUser: boolean = false;
  public showReportName: boolean = false;
  public tenantId: string;
  public userId: string;

  public tenantList: ListTenantsModel[];
  public userList: ListAccountsModel[]; 
  public projectList: ProjectList;
  public isSaving: boolean = false;
  public tenantFocus: boolean = false;
  public duplicateName: boolean = false;
  public tenantLoadedClicked: boolean = false;

  constructor(private activeModal: NgbActiveModal, public intelService: IntelligenceService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.loadTenants();
  }

  public onBlur(e: Event): void {
    this.tenantFocus = false;
    e.stopPropagation();
  }

  public onFocus(e: Event): void {
    this.tenantFocus = true;
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  isTenantValid(): boolean {
    if (!this.tenantName) return false;
    return this.tenantList.some(x => x.name == this.tenantName);
  }

  isUserValid(): boolean {
    if (!this.userName) return false;
    return this.userList.some(x => x.userId == this.userName);
  }

  isReportNameValid(): boolean {
    if (this.reportName?.trim().length < 2 || !this.reportName) return false;
    return true;
  }

  tenantTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.tenantSubSet(term);
      })
    );

  private tenantSubSet(term: string){
    var list = [];
      this.tenantList.forEach(v => {
        if (v.name.toLowerCase().indexOf(term.toLowerCase()) > -1) {
          if (list.length < 10) {
            list.push(v.name);
          }
        }
      });
    return list;
  }

  loadTenants(): void {
    this.intelService.getTenantsByFeature().subscribe({
      next: (result) => {
        if (result.isSuccess){
          this.tenantList = result.data;
        }
      }
    })
  }

  loadTenant(): void {
    // get the tenant id 
    this.tenantLoadedClicked = true;

    if (this.tenantName && this.tenantName?.trim()?.length != 0){
      var tenant = this.tenantList.find(x => x.name == this.tenantName);
      if (!tenant) return;
      this.tenantId = tenant.tenantId;
      // get users for that tenant
      this.intelService.getUsersByTenant(this.tenantId).subscribe({
        next: (result) => {
          if (result.isSuccess){
            this.userList = result.data;
            this.showUser= true;
          }
        },
      })
    }
  }


  selectUser(userId: string): void {
    var user = this.userList.find(x => x.userId == userId);
    this.userId = user.userId;
    if (user) {
      this.showReportName = true;
    }
    else {
      this.showReportName = false
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  canRequestReport(): boolean {
    if (this.isSaving) return false;
    if (!this.isTenantValid()) return false;
    if (!this.isUserValid()) return false;
    if (!this.isReportNameValid()) return false;
    return true;
  }

  updateModal(): void {
    this.showUser = false;
    this.userList = null;
    this.userName = null;
    this.showReportName = false;
    this.reportName = null;
    this.tenantId = null;
    this.userId = null;
    this.duplicateName = false;
    this.tenantLoadedClicked = false;
  }
 
  resetFlag(): void {
    this.duplicateName = false;
  }

  public submitRequest() {
    this.isSaving = true;

    var command = new AddProjectRequestByAdminCommand();
    command.tenantId = this.tenantId;
    command.userId = this.userId;
    command.reportName = this.reportName;

    this.intelService.requestProjectAdmin(command).subscribe({
      next: (result) => {
        this.dismissModal();
        this.intelService.loadTenantProjectPage(1);
        this.alertService.success("The Intelligence Report request has been sent successfully", { autoClose: true, keepAfterRouteChange: false });
        this.isSaving = false;
      }, 
      error: (err) => {
          const errorMessage = JSON.parse(err.response);
          this.duplicateName = errorMessage?.errors?.some(x => x.errorMessage === 'Project name already exists');
          this.isSaving = false;
      },
    })
  }
}
