import { Component, Input, OnInit } from '@angular/core';
import { AssessmentRecipient, RecipientQuestionSetResponse } from 'src/nswag';
import { RiskAssessmentService } from '../services/risk-assessment.service';
import { UtilityService } from '../utilities/utilities';

@Component({
  selector: 'risks-table',
  templateUrl: './risks-table.component.html',
  styleUrls: ['./risks-table.component.scss']
})
export class RisksTableComponent implements OnInit {
  public recipientResponse: RecipientQuestionSetResponse[] = []
  @Input() currentRecipient: AssessmentRecipient;
  @Input() category: string;
 
  constructor(public riskAssessmentService: RiskAssessmentService, public utilityService: UtilityService) { }
  
  async ngOnInit() {
    let recipientIds = [];
    recipientIds.push(this.currentRecipient.selectedRecipientId)
    this.riskAssessmentService.getResponseBySelectedRecipient(recipientIds).subscribe(result => {
      if (result.isSuccess){
        this.recipientResponse = this.category ? result?.data?.results?.filter(X => X.category === this.category) : result?.data?.results;
      }
    });
  }
}
