import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'app-saveinvestigation',
  templateUrl: './saveinvestigation.component.html',
  styleUrls: ['./saveinvestigation.component.scss']
})
export class SaveandreportComponent implements OnInit, OnDestroy {

  constructor(public dashService: DashboardService, private modalService: NgbModal) { }

  public invName: string;

  public showReport: boolean = false;
  
  ngOnInit() {

  }

  ngOnDestroy() {
    this.modalService.dismissAll(false);
  }

  openSave(content: any) {
    this.invName = this.dashService.investigationName;
    this.modalService.open(content, { scrollable: true }).result.then((result) => {
      if (result) {
        this.dashService.investigationName = this.invName;
        this.dashService.saveInvestigation();
      }
    }).catch((onRejected) => { /* modal closed */ });
  }

  closeSaveModal(modal: any) {
    if (this.invName && this.invName.length >= 4) {
      modal.close(true);
    }
  }
}
