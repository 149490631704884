import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Assessment, AssessmentRecipient, SelectedRecipientStatus } from 'src/nswag';

@Component({
  selector: 'app-result-status-badge',
  templateUrl: './result-status-badge.component.html',
  styleUrls: ['./result-status-badge.component.scss']
})
export class ResultStatusBadgeComponent implements OnInit, OnChanges {

  constructor() { }

  @Input() recipient: AssessmentRecipient;
  @Input() assessment: Assessment;
  public displayScore: string;
  public displayStyle: string;

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.recipient){
        this.displayStyle = this.recipient.status === SelectedRecipientStatus.Complete ? this.recipient?.displayStatus ?? "NA" : this.recipient?.status;
        this.displayScore = this.recipient?.displayStatus ?? "NA";
    }
    else{
      this.displayStyle = this.assessment?.status == 'Created' ? this.assessment.status : 'InProgress' ?? 'NA';
      this.displayScore = this.assessment?.status ?? 'NA';
    }
  }
  public onBadgeHover() {

  }
}
