<section class="pagebreak">
  <br /><br />
  <h2>Financial Crime Checks</h2>
  <div *ngIf="noProfileSubject">
    <form [formGroup]="investigationFormGroup">
      <section>
        <br />
        <h4 class="text-dark">Sanctions</h4>
        <div class="content">
          <div class="wrapper">
            <textarea #textarea id="sanctionCheck" contenteditable="true" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
              formControlName="sanctionsSummary"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="sanctionCheckText"></p>
            <p>No Sanctions found</p>
          </div>
        </div>
      </section>

      <section>
        <br />
        <h4 class="text-dark">Law Enforcement</h4>
        <div class="content">
          <div class="wrapper">
            <textarea #textarea id="lawCheck" contenteditable="true" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
              formControlName="litigationSummary"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="lawCheckText"></p>
            <mon-legal *ngIf="isSPIL" [clientId]="null" [profile]="null" [name]="mainSubject.name" [actor]="mainSubject"
              [incHeader]="false" [allowDiscounting]="false"></mon-legal>
            <p *ngIf="!isSPIL">No Law Enforcement found</p>
          </div>
        </div>
      </section>

      <section>
        <br />
        <h4 class="text-dark">Political Exposure</h4>
        <div class="content">
          <div class="wrapper">
            <textarea #textarea id="pepCheck" contenteditable="true" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
              formControlName="pepSummary"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="pepCheckText"></p>
            <p>No Political Exposure found</p>
          </div>
        </div>
      </section>

      <section>
        <br />
        <h4 class="text-dark">Adverse Media</h4>
        <div class="content">
          <div class="wrapper">
            <textarea #textarea id="adverseCheck" contenteditable="true" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
              formControlName="adverseMediaSummary"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="adverseCheckText"></p>
            <p>No Adverse Media found</p>
          </div>
        </div>
      </section>

      <section>
        <br />
        <h4 class="text-dark">Associated Individuals</h4>
        <div class="content">
          <div class="wrapper">
            <textarea #textarea id="aInd" contenteditable="true" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
              formControlName="linkedPeopleSummary"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="aIndText"></p>
            <p>No Associated Individuals found</p>
          </div>
        </div>
      </section>

      <section>
        <br />
        <h4 class="text-dark">Associated Companies</h4>
        <div class="content">
          <div class="wrapper">
            <textarea #textarea id="aComp" contenteditable="true" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
              formControlName="linkedBusinessesSummary"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="aCompText"></p>
          </div>
          <p>No Associated Companies found</p>
        </div>
      </section>
    </form>
  </div>
  <div *ngFor="let profileEntry of profileList">
    <div *ngIf="nodeIsNotFilteredOut(profileEntry.actor)">
      <div class="lamps-result">
        <br />
        <h3 class="text-light bg-dark">{{profileEntry.actor.name}}</h3>
        <section>
          <div class="content">
            <form [formGroup]="profileEntry.actor.reportActorFreeText">
              <div class="wrapper">
                <textarea #textarea id="lamps" contenteditable="true" type="textarea" class="discardable-text"
                  placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
                  formControlName="lampsSummary"></textarea> <span class="edit-icon"> click to edit</span>
                <p id="lampsText"></p>
              </div>
            </form>
            <mon-individualprofile *ngIf="profileEntry.individualProfile" [client]="profileEntry.client"
              [profile]="profileEntry.individualProfile" [showAllLists]="true"></mon-individualprofile>
            <mon-businessprofile *ngIf="profileEntry.businessProfile" [client]="profileEntry.client"
              [profile]="profileEntry.businessProfile" [showAllLists]="true"></mon-businessprofile>
          </div>
        </section>
        <section>
          <br />
          <h4 class="text-dark">Sanctions</h4>
          <div class="content">
            <form [formGroup]="profileEntry.actor.reportActorFreeText">
              <div class="wrapper">
                <textarea #textarea id="lSanctions" contenteditable="true" type="textarea" class="discardable-text"
                  placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
                  formControlName="sanctionsSummary"></textarea> <span class="edit-icon"> click to edit</span>
                <p id="lSanctionsText"></p>
              </div>
            </form>
            <mon-sanctions [clientId]="getClient(profileEntry)" [profile]="getProfile(profileEntry)"
              [name]="getProfileName(profileEntry)" [incHeader]="false" [allowDiscounting]="false" [reportMode]="true"></mon-sanctions>
          </div>
        </section>

        <section>
          <br />
          <h4 class="text-dark">Law Enforcement</h4>
          <div class="content">
            <form [formGroup]="profileEntry.actor.reportActorFreeText">
              <div class="wrapper">
                <textarea #textarea id="lLaw" contenteditable="true" type="textarea" class="discardable-text"
                  placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
                  formControlName="litigationSummary"></textarea> <span class="edit-icon"> click to edit</span>
                <p id="lLawText"></p>
              </div>
            </form>
            <mon-legal [clientId]="getClient(profileEntry)" [profile]="getProfile(profileEntry)"
              [name]="getProfileName(profileEntry)" [incHeader]="false" [allowDiscounting]="false"
              [actor]="profileEntry.actor" [reportMode]="true"></mon-legal>
          </div>
        </section>

        <section>
          <br />
          <h4 class="text-dark">Political Exposure</h4>
          <div class="content">
            <form [formGroup]="profileEntry.actor.reportActorFreeText">
              <div class="wrapper">
                <textarea #textarea id="lPep" contenteditable="true" type="textarea" class="discardable-text"
                  placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
                  formControlName="pepSummary"></textarea> <span class="edit-icon"> click to edit</span>
                <p id="lPepText"></p>
              </div>
            </form>
            <mon-peps *ngIf="profileEntry.individualProfile" [clientId]="getClient(profileEntry)"
              [profile]="profileEntry.individualProfile" [name]="getProfileName(profileEntry)" [incHeader]="false"
              [allowDiscounting]="false" [reportMode]="true"></mon-peps>
            <mon-soe *ngIf="profileEntry.businessProfile" [clientId]="getClient(profileEntry)"
              [profile]="profileEntry.businessProfile" [name]="getProfileName(profileEntry)" [incHeader]="false"
              [allowDiscounting]="false" [reportMode]="true"></mon-soe>
          </div>
        </section>

        <section>
          <br />
          <h4 class="text-dark">Adverse Media</h4>
          <div class="content">
            <form [formGroup]="profileEntry.actor.reportActorFreeText">
              <div class="wrapper">
                <textarea #textarea id="lAdverse" contenteditable="true" type="textarea" class="discardable-text"
                  placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
                  formControlName="adverseMediaSummary"></textarea> <span class="edit-icon"> click to edit</span>
                <p id="lAdverseText"></p>
              </div>
            </form>
            <mon-adversemedia [clientId]="getClient(profileEntry)" [profile]="getProfile(profileEntry)"
              [name]="getProfileName(profileEntry)" [incHeader]="false" [allowDiscounting]="false" [reportMode]="true"></mon-adversemedia>
          </div>
        </section>

        <section>
          <br />
          <h4 class="text-dark">Associated People</h4>
          <div class="content">
            <form [formGroup]="profileEntry.actor.reportActorFreeText">
              <div class="wrapper">
                <textarea #textarea id="lAp" contenteditable="true" type="textarea" class="discardable-text"
                  placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
                  formControlName="linkedPeopleSummary"></textarea> <span class="edit-icon"> click to edit</span>
                <p id="lApText"></p>
              </div>
            </form>
            <mon-individual-associations [clientId]="getClient(profileEntry)" [profile]="getProfile(profileEntry)"
              [name]="getProfileName(profileEntry)" [allowDiscounting]="false"></mon-individual-associations>
          </div>
        </section>

        <section>
          <br />
          <h4 class="text-dark">Associated Companies</h4>
          <div class="content">
            <form [formGroup]="profileEntry.actor.reportActorFreeText">
              <div class="wrapper">
                <textarea #textarea id="lAc" contenteditable="true" type="textarea" class="discardable-text"
                  placeholder="Click here to start adding text" (keyup)="resizeAllTextAreas()"
                  formControlName="linkedBusinessesSummary"></textarea> <span class="edit-icon"> click to edit</span>
                <p id="lAcText"></p>
              </div>
            </form>
            <mon-business-associations [profile]="getProfile(profileEntry)" [clientId]="getClient(profileEntry)"
              [name]="getProfileName(profileEntry)" [incHeader]="false"
              [allowDiscounting]="false"></mon-business-associations>
          </div>
        </section>
        <br />

        <h4 class="text-dark">Discounted Information</h4>
        <div class="content">
          <app-discounted-businesses [profile]="getProfile(profileEntry)"
            [incHeader]="false"></app-discounted-businesses>
        </div>
        <div class="content">
          <app-discounted-individuals [profile]="getProfile(profileEntry)"
            [incHeader]="false"></app-discounted-individuals>
        </div>
        <div class="content">
          <app-discounted-evidence [profile]="getProfile(profileEntry)" [incHeader]="false"></app-discounted-evidence>
        </div>
      </div>
    </div>
  </div>
</section>