import { Injectable } from "@angular/core";
import { ProfileService } from "src/app/profile.service";
import { AssessmentRecipient, IndustryVerticalsAverage, QuestionSetDetail, RecipientQuestionSetResponse } from "src/nswag";
import { CHART_TYPE } from "../../charts/charts.component";
import { RiskAssessmentService } from "../../services/risk-assessment.service";

@Injectable({
  providedIn: 'root'
})

export class RecipientReportService {
  public rating: string;
  public chartType = CHART_TYPE;

  public get currentRecipient(): AssessmentRecipient { return this._currentRecipient };
  public recipientResponseControls: RecipientQuestionSetResponse[] = [];
  public recipientResponseCapabilities: RecipientQuestionSetResponse[] = [];
  public questionSets: QuestionSetDetail[] = [];
  public companyName: string;
  public userName: string;
  public industryAverages: IndustryVerticalsAverage[] = [];

  public get showSpinner(): boolean { return this._showSpinner; };
  public get showReport(): boolean { return this._showReport; };

  private _currentRecipient: AssessmentRecipient;

  private _showReport = false;
  private _showSpinner = false;

  constructor(private profileService: ProfileService, private riskAssessmentService: RiskAssessmentService) {
  }

  public displayIndividualReport(currentRecipient: AssessmentRecipient) {
    this._currentRecipient = currentRecipient ?? null;
    this.initialiseIndividualReport(currentRecipient);
  }

  public hideReport() {
    this.decomissionReport();
  }

  private initialiseIndividualReport(currentRecipient: AssessmentRecipient) {
    this.startReportSetup();
    this.companyName = this.profileService.companyName;
    this.userName = this.profileService.fullName;
    this.riskAssessmentService.getQuestionSets().subscribe(res => {
      if (res.isSuccess) {
        this.questionSets = res.data.results;
        this.riskAssessmentService.getResponseBySelectedRecipient([currentRecipient.selectedRecipientId]).subscribe(result => {
          this.recipientResponseCapabilities = result.data.results.filter(x => x.category === 'Capabilities');
          this.recipientResponseControls = result.data.results.filter(x => x.category === 'Controls');
          this.riskAssessmentService.loadIndustryVerticalsAverageByIndustryVerticalsId(this.riskAssessmentService.CurrentAssessment.industryVerticalsId).subscribe(res => {
            if (res.isSuccess) {
              this.rating = currentRecipient.displayStatus;
              this.endReportSetup();
            }
          })
        });
      }
    });
  }

  private startReportSetup() {
    this._showReport = true;
    this._showSpinner = true;

    var w: any = window;
    if (w.fcWidget) {
      w.fcWidget.hide();
    }
  }

  private endReportSetup() {
    setTimeout(() => {
      this._showSpinner = false;
    }, 3000);
  }

  private decomissionReport() {
    this._currentRecipient = null;
    this._showReport = false;
    this._showSpinner = false;

    var w: any = window;
    if (w.fcWidget) {
      w.fcWidget.show();
    }
  }

}
