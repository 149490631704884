<div class="container mt-5">
    <div class="card">
        <form [formGroup]="uploadForm">
        <div class="card-header">
            <h5>Tenant Media Uploader</h5>
        </div>
        <div class="card-body">
            <div class="row mb-3">
                <div class="col-4">
                    <label class="fw-bold">Tenant Name</label>
                    <input disabled class="form-control" type="text" [value]="tenantName"/>
                </div>
                <div class="col">
                    <label class="fw-bold">Tenant Id</label>
                    <input disabled class="form-control" type="text" [value]="tenantId" />
                </div>
            </div>
            <div class="row">
                <div class="mb-3">
                    <div class="required-input mb-1">
                        <label class="fw-bold">Media File</label>
                    </div>
                    <div class="input-group">
                        <input #fileInput class="form-control" type="file" (change)="onSelectFile($event)"/>
                        <span class="input-group-text" id="basic-addon1">
                            {{(bytesToMB(uploadForm.controls.file?.value?.fileSize)?.toFixed(1) ?? '') + "MB"}}
                        </span>
                    </div>
                    <div *ngIf="uploadForm.controls.file.invalid && uploadForm.controls.file.dirty">
                        <div *ngIf="uploadForm.controls.file.errors?.['invalidFileSize']" class="alert alert-danger col-12 my-1">File size too large. Must be under 20MB.</div>
                        <div *ngIf="uploadForm.controls.file.errors?.['invalidFileType']" class="alert alert-danger col-12 my-1">Invalid file type.</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="input-group mb-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="guidCheckbox" formControlName="showMediaIdInput" (change)="onCheckboxChange()" />
                        <label class="form-check-label" for="guidCheckbox">
                        Assign a media Id?
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="uploadForm.controls.showMediaIdInput.value" class="mb-3">
                    <div class="required-input mb-1">
                        <label class="fw-bold">Media Id</label> 
                    </div>
                    <div class="input-group">
                        <span class="input-group-text" id="basic-addon1">
                            <i *ngIf="uploadForm.controls.mediaId.pristine" class="icon-pencil text-warning"></i>
                            <i *ngIf="uploadForm.controls.mediaId.valid && uploadForm.controls.mediaId.dirty" class="icon-check text-success"></i>
                            <i *ngIf="uploadForm.controls.mediaId.errors && uploadForm.controls.mediaId.dirty" class="icon-cross text-danger"></i>
                        </span>
                        <input class="form-control" type="text" formControlName="mediaId" placeholder="Enter GUID"/>
                        <button class="btn btn-primary mb-0" type="button" id="button-addon2" (click)="generateGuid()"><span class="icon-magic-wand"></span></button>
                    </div>
                    <p class="my-1"><small><strong>Please note: </strong>When providing a media id <strong>that already exists</strong>, please ensure that it belongs to the tenant listed above.</small></p>
                    <div class="input-group" *ngIf="uploadForm.controls.mediaId.invalid && uploadForm.controls.mediaId.dirty">
                        <div *ngIf="uploadForm.controls.mediaId.errors" class="alert alert-danger col-12 mt-1">Enter or generate a Guid</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="required-input mb-1">
                    <label class="fw-bold">Media Description</label>
                </div>
                <div class="input-group">
                    <input class="form-control" type="text" formControlName="mediaDescription" placeholder="Enter a brief description"/>
                </div>
                <div class="input-group" *ngIf="uploadForm.controls.mediaDescription.invalid && uploadForm.controls.mediaDescription.dirty">
                    <div *ngIf="uploadForm.controls.mediaDescription.errors?.['required'] || uploadForm.controls.mediaDescription.errors?.['emptyWhenTrimmed']" class="alert alert-danger col-12 mt-1">Please enter a description.</div>     
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button [disabled]="uploadForm.invalid || isLoading" class="btn btn-primary" (click)="upload()">
                <span *ngIf="isLoading" class="spinner-border"></span>
                <span *ngIf="!isLoading">Upload</span>
            </button>
        </div>
    </form>
    </div>
</div>
