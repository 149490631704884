import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertStatus, Counter, LAMPSTypes } from 'src/nswag';
import { MonitorService } from '../../services/monitor.service';
import { HelperService } from 'src/app/utils/helper.service';

@Component({
  selector: 'mon-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @Input() lampsType: LAMPSTypes;
  @Input() counter: Counter;
  @Input() title: string;
  @Input() openTitle: string;
  @Input() totalTitle: string;
  @Input() icon: string;
  @Input() clickable: boolean = true;
  @Output() showOpen = new EventEmitter<boolean>();
  @Output() outLampsTypes = new EventEmitter<LAMPSTypes>();

  constructor(private monitorService: MonitorService) { }

  public selectAlerts() {
    this.monitorService.loadLAMPSClients(this.lampsType,AlertStatus.Open);
    this.showOpen.emit(true);
    this.outLampsTypes.emit(this.lampsType);
  }

  public selectTotals() {
    this.monitorService.loadLAMPSClients(this.lampsType);
    this.showOpen.emit(false);
    this.outLampsTypes.emit(this.lampsType);
  }

  public abbrNumbers(number, decPlaces) {
    return HelperService.abbrNum(number, decPlaces);
  }
}
