<p class="text-xl font-light">Select the date of submission for the report</p>
<div class="grid mt-3">
  <div class="col">
    <p-calendar 
      class="full-width-calendar"
      appendTo="body"
      [(ngModel)]="date"
      [showIcon]="true"
      [selectionMode]="'single'"
      [maxDate]="today">
      </p-calendar>
  </div>
</div>

<div class="grid" *ngIf="date">
  <div class="col">
    <p-messages severity="info">
      <ng-template pTemplate>
        <p class="m-0">I, hereby declare that the information provided in this {{report?.type}} <strong *ngIf="report?.title">'{{report.title}}'</strong> is accurate and complete to the best of my knowledge. I acknowledge my responsibility to maintain the confidentiality of this report and confirm that a signed copy of this report was submitted to the relevant authorities on {{date | date: 'fullDate'}}.</p>
      </ng-template>
    </p-messages>
  </div>
</div> 

<div *ngIf="date" class="form-check">
    <input class="form-check-input" type="checkbox" id="flexCheckDefault" [(ngModel)]="userAgreeDeclaration">
    <label class="form-check-label" for="flexCheckDefault">
        I understand and accept the terms of the declaration
    </label>
</div>

<div class="flex-container-end mt-4">
  <p-button label="Close" class="mr-1" type="button" severity="danger" [outlined]="true" (click)="closeDialog()"/>
  <p-button [label]="!loading ? 'Mark as Submitted': 'Loading...'" class="ml-1" [loading]="loading" [disabled]="!date || !userAgreeDeclaration || loading" type="button" (click)="onSubmit()"/>
</div>
