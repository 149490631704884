import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/utilitities/utilities';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';
import { ClientResult, DeleteIdvCheckCommand, IdvUserProfile, Log, ZignSecClient } from 'src/nswag';
import tippy from 'tippy.js';
import { IDVService } from '../idv.service';
import { ProfileService } from 'src/app/profile.service';
import { IdvStatusCodeService } from '../status-codes.service';

@Component({
  selector: 'app-idv',
  templateUrl: './idv.component.html',
  styleUrls: ['./idv.component.scss']
})
export class IdvComponent implements OnInit, OnDestroy {

  public idvProfiles: IdvUserProfile[] = [];
  public clientResult: ClientResult = null;

  public idvEnabled= true;

  public showAdvancedOptions = false;
  public showIDVReport = false;
  public auditLogs: Log[] = [];

  public showMain = true;
  public isLoading = false;

  public currentProfile: IdvUserProfile = null;
  public focusedProfile: IdvUserProfile = null;
  private currentSearchString = "";
  public isAdministrator: boolean = false;
  
  public pageSize = 10;
  public currentPage = 1;
  public profileTotal = 1;

  constructor(private idvClient: ZignSecClient, private idvService: IDVService, private modalService: NgbModal, private confirmationService: ConfirmationDialogService, private profileService: ProfileService) { }

  ngOnInit(): void {
    this.isLoading = false;
    this.searchProfile("");
    this.isAdministrator = this.profileService.IsAdministrator;
  }

  ngOnDestroy() {
    // guide tour
    //this.guideTour = null;
    // close all modal pop upon component destroy
    this.modalService.dismissAll(false);
  }

  public displaySearchOptions() {
    if (this.showAdvancedOptions == true)
      this.showAdvancedOptions = false;
    else
      this.showAdvancedOptions = true;
  }

  public viewIDVReport(event) {
    event.stopPropagation();
    this.showMain = false;
    this.showIDVReport = true;
  }

  public getCountry(idvProfile: IdvUserProfile) : string {
    return UtilityService.getCountryFromCode(idvProfile.country);
  }

  public dropDownClicked(event) {
    event.stopPropagation();
  }

  public changeProfile(idvProfile: IdvUserProfile){
    // Used to close the details panel
    this.currentProfile = idvProfile;
  }
  
  public closeIdvReport(cancelled: boolean) {
    this.showMain = true;
    this.showIDVReport = false;
  }

  public closeHistoryReport(cancelled: boolean) {
    this.showMain = true;
  }

  public deleteIDV(event){
    event.stopPropagation();

    this.confirmationService.confirm("Confirm deletion", "Are you sure you want to delete this client? This will remove the client from monitoring along with their monitoring history.")
    .then(r => {
      if (r) {
        let command = new DeleteIdvCheckCommand({
          id: this.focusedProfile.id,
          version: this.focusedProfile.version
        });
        this.idvClient.delete(command).subscribe(result => {
          let index = this.idvProfiles.indexOf(this.focusedProfile, 0);
          if (index > -1) {
            this.idvProfiles.splice(index, 1);
          }
          this.focusedProfile = null;
          this.currentProfile = null;
        });
      }
    })
    .catch((onRejected) => { /* modal closed */ });
  }

  public selectProfile(profile: IdvUserProfile) {
    this.currentProfile = profile;
  }

  public startIDV() {
    this.idvService.launchIDV();
  }
  
  public searchProfile(search: string) {
    this.currentPage = 1;
    this.currentSearchString = search;
    this.startSearchProfile();
  }

  public startSearchProfile() {
    this.idvClient.list(this.currentSearchString,null, false,this.currentPage,this.pageSize).subscribe(r => {
      if (r.isSuccess) {
        this.idvProfiles = r.data.data;
        this.profileTotal = r.data.totalCount;
      }
    });
  }

  public selectIDVPerson(idvPerson: IdvUserProfile) {
    this.currentProfile = idvPerson;
    this.loadClient(idvPerson);
  }

  public selectFocusedPerson(idvPerson: IdvUserProfile) {
    this.focusedProfile = idvPerson;
  }

  private loadClient(idvProfile : IdvUserProfile) {
    if (idvProfile?.clientId) {
      this.idvClient.getClientById(idvProfile.clientId).subscribe( result => {
        if (result.isSuccess) {
          this.clientResult = result.data;
        }
      });
    }
    else {
      this.clientResult = null;
    }
  }

  public statusCodeToClass(status: string): string {
    return IdvStatusCodeService.statusCodeToClass(status);
  }

  public onBadgeHover(idvProfile: IdvUserProfile) {
    if(idvProfile.rejectionReason != null) {
      var badgeId = "badge-" + idvProfile.id;
      let el: any = document.getElementById(badgeId);
      if (el) {
        let tippyCtrl = el._tippy;
        if (!tippyCtrl) {
          tippy('#' + badgeId, {
            allowHTML: true,
            content: idvProfile.rejectionReason
          });
        }
      }
    }
  }

  public handlePageChange(event: number): void {
    this.currentPage = event;
    this.startSearchProfile();
  }
}
