<table class="table table-striped table-hover table-themis table-pointer" aria-labelledby="tableLabel">
  <thead>
    <tr class="text-light bg-primary">
      <th scope="col">Company Name</th>
      <th scope="col">Account</th>
      <th scope="col">Tenant Description</th>
      <th scope="col">Project Number</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let client of clientList | paginate : {
        itemsPerPage: pageSize,
        currentPage: currentPage,
        totalItems: clientTotal
      };
      let i = index">
      <tr (click)="toggleRowDetails(i, client)" class="align-middle">
        <td>{{ displayCompanyName(client) }}</td>
        <td>{{ displayAccount(client) }}</td>
        <td>{{ displayTenantDescription(client) }}</td>
        <td>{{ displayProjectNumber(client) }}</td>
        <td class="text-center verti">
          <span [class.active-row]="selectedRowIndex === i" class="icon-chevron-down"></span>
        </td> 
      </tr>

      <!-- expandable project details -->
      <tr *ngIf="selectedRowIndex === i" >
          <td colspan="5" class="bg-light">
            <ng-container [ngTemplateOutlet]="showProjects"></ng-container>
          </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<pagination-controls class="pagination"
                     previousLabel="Prev"
                     nextLabel="Next"
                     [responsive]="true"
                     (pageChange)="handlePageChange($event)">
</pagination-controls>

<ng-template #showProjects> 
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col">Project</th>
        <th scope="col">Status</th>
        <th scope="col">Date of Request</th>
        <th scope="col" class="text-center">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let project of intelService?.ProjectList" (mouseenter)="setFocusedProject(project)">
        <td>{{ project?.name }}</td>
        <td>{{ project?.status }}</td>
        <td>{{ getDate(project?.createdOn) }}</td>
        <td class="text-center verti">
          <div ngbDropdown class="d-inline-block mon" id="monitor-list">
            <div class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle (click)="dropDownClicked($event)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-three-dots" viewBox="0 0 16 16">
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
              </svg>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div ngbDropdownItem (click)="uploadDocument(true)">{{!project.reportMediaId ? "Upload Report" : "Re-Upload Report"}}</div>
              <div ngbDropdownItem (click)="uploadDocument(false)">{{!project.proposalMediaId ? "Upload Proposal" : "Re-Upload Proposal"}}</div>
              <div ngbDropdownItem (click)="showEditDialog(project)">Edit</div>
              <div ngbDropdownItem (click)="viewAudit(project)">Audit Trail</div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
