import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MonitorLinkService } from 'src/app/services/monitor-link.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { BusinessProfile, ClientResult, EntityTypes, IndividualProfile } from 'src/nswag';
import { IActorBase } from '../../models/Actor';
import { UntypedFormGroup } from '@angular/forms';


class reportProfileList {
  actor: IActorBase;
  individualProfile: IndividualProfile;
  businessProfile: BusinessProfile;
  client: ClientResult;
}

@Component({
  selector: 'app-report-lampsinformation',
  templateUrl: 'lampsinformation.component.html',
  styleUrls: ['lampsinformation.component.scss']
})

export class LampsInformationReportComponent implements OnInit {
  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public profileList: reportProfileList[] = [];

  public noProfileSubject = true;

  public isSPIL = false;

  public mainSubject: IActorBase;

  public investigationFormGroup: UntypedFormGroup;

  constructor(private dashService: DashboardService) { }

  ngOnInit() {
    this.investigationFormGroup = this.dashService.getInvestigation()?.reportActorFreeText;
    this.sortLAMPSResults();
    setTimeout(() => {
      this.resizeAllTextAreas();
    }, 3000);
  }

  private sortLAMPSResults() {
    this.mainSubject = this.dashService.getInvestigation();
    this.isSPIL = this.mainSubject.actorType == EntityTypes.Individual;

    let connectedEdges = this.dashService.getConnectedActorEdges(this.mainSubject);
    let directprofileList: reportProfileList[] = [];
    let indirectprofileList: reportProfileList[] = [];

    this.dashService.cytoscapeObject.nodes().forEach((node: cytoscape.NodeSingular) => {
      var actor: IActorBase = node.data("actor");
      this.dashService.loadActorForms(actor);
      if (actor?.profileId) {
        var profileEntry = new reportProfileList();
        profileEntry.actor = actor;
        if (actor?.id == this.mainSubject.id) {
          // Add as first actor
          this.profileList.push(profileEntry);
          this.noProfileSubject = false;
        }
        else {
          if (connectedEdges.findIndex(v => v.sourceActor.id == this.mainSubject.id || v.targetActor.id == this.mainSubject.id) != null) {            
            // its a connected node
            directprofileList.push(profileEntry);
          }
          else {
            // indirect node
            indirectprofileList.push(profileEntry);
          }
        }
        this.dashService.profileLoader(actor).subscribe(profile=>{
          if (actor.actorType == EntityTypes.Company) {
              profileEntry.businessProfile = profile;
          }
          else {
            profileEntry.individualProfile = profile;
          }
          this.createDummyClient(profileEntry);
        });
      }
    });

    // Append direct and indirect profilelists
    this.profileList.push(...directprofileList);
    this.profileList.push(...indirectprofileList);    
  }
  private createDummyClient(profileEntry: reportProfileList) {
    profileEntry.client = MonitorLinkService.createDummyClientresult(this.getProfile(profileEntry));
  }
  
  
  nodeIsNotFilteredOut(actor: IActorBase) : boolean{
    // display the actor if they are active OR the subject)
    if (actor?.isActive || actor?.id == this.dashService.getInvestigation()?.id) {
      return true;
    } 
    // don't display if inactives are filtered out
    else if (this.dashService?.excludeInactive) {
      return false;
    }
    return true;
  }

  resizeAllTextAreas() {
    this.textareas.toArray().forEach((item) => { 
      item.nativeElement.style.height = 'auto';
      item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
    }); 
  }

  getProfile(profileEntry:reportProfileList) : any {
    return profileEntry.businessProfile ?? profileEntry.individualProfile;
  }
  
  getClient(profileEntry:reportProfileList) : any {
    return profileEntry?.client?.client?.id;
  }

  getProfileName(profileEntry:reportProfileList) : string {
    if (profileEntry.individualProfile) {
      return UtilityService.getIndividualName(profileEntry.individualProfile);
    }
    return profileEntry?.businessProfile?.name;
  }
}
