import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/profile.service';
import { AlertStatus, ClientResult, LAMPSStatus, MonitoringClient, UpdateAlertStatusCommand } from 'src/nswag';

@Component({
  selector: 'mon-alertstatus',
  templateUrl: './alertstatus.component.html',
  styleUrls: ['./alertstatus.component.scss']
})
export class AlertstatusComponent implements OnChanges {

  @ViewChild(NgbDropdown) private dropdown: NgbDropdown;
  @Input() client: ClientResult;
  @Output() clientChange = new EventEmitter<ClientResult>(); 
  
  public status: AlertStatus;

  // Enum copy Required for HTML template
  public _alertStatus = AlertStatus;

  public buttonColour: string;

  public checkCanClose: boolean;

  public reason = "";
  public placeholderClosingText = 'Reason for closing this alert';
  public placeholderOpeningText = 'Reason for opening this alert';
  public readonly = false;
  public isMandatoryNotesEnabled = false
  public hasErrors: boolean = false;

  constructor(private monitorClient: MonitoringClient, private profileService: ProfileService) { 
    this.isMandatoryNotesEnabled = this.profileService.isMandatoryNotesEnabled;
    if (this.isMandatoryNotesEnabled){
      this.placeholderOpeningText += ' (Required)';
      this.placeholderClosingText += ' (Required)';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {  
    this.readonly = false;
    this.reason = "";
    this.status = this.client?.matchStatus?.alertStatus;
    this.setButtonColour();
    this.checkCanClose = this.status != AlertStatus.Closed && 
    (this.client?.matchStatus?.isMatched != LAMPSStatus.No || 
      this.client?.matchStatus?.matches == LAMPSStatus.No);
  }

  public validation(text = null): void {
    if (text != null){
      this.hasErrors = (!text?.trim()?.length || text?.trim().length < 1) && this.isMandatoryNotesEnabled;
    }
    else{
      this.hasErrors = (!this.reason?.trim()?.length || this.reason?.trim().length < 1) && this.isMandatoryNotesEnabled;
    }
  }

  getStatus() : string {
    return (this.status == AlertStatus.Closed ? "Monitored" : this.status);
  }
  
  changeStatus(newStatus: AlertStatus) {
    this.validation();
    if (this.hasErrors) return;
    this.readonly = true;
    var command = new UpdateAlertStatusCommand({ status: newStatus, clientId: this.client.client.id, notes: this.reason });
    this.monitorClient.updateAlertStatus(command,this.client.client.id).subscribe(value => {
      if (value.isSuccess) {
        this.client = value.data;
        this.clientChange.emit(this.client);
      }
      this.dropdown.close();
    });
    
  }

  setButtonColour() {
    switch (this.status) {
      case AlertStatus.Open:
      // case AlertStatus.Escalated:
      // case AlertStatus.Investigating:
        this.buttonColour = "btn-danger";
        break;
      case AlertStatus.Closed:
        this.buttonColour = "btn-outline-secondary";
        break;
    }
  }
}
