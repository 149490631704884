<div class="card-body  overflow-auto text-wrap text-break text-break">
    <div id="individual-profile-display">
        <!-- <div class="row">
            <div class="col">
                <img *ngIf="profileImageId" onerror="src='/assets/images/EDD-Avatar-individual.png'" [src]="'/media/'+profileImageId+'' | secure | async" class="avatar-image">
                <img *ngIf="profileImageId == null" src="/assets/images/EDD-Avatar-individual.png" class="avatar-image" />
            </div>
        </div> -->
        <div class="row">
            <div class="col">
                <h4>Documents for {{name}}</h4>
            </div>
        </div>
        <div *ngFor="let document of idvProfile?.documents" class="row">

            <div class="col">
                <img *ngIf="isIdentity(document)" [src]="'/media/'+document.id+'' | secure | async" class="media-width">
                <img *ngIf="isImage(document)" [src]="'/media/'+document.id+'' | secure | async" class="media-width">
                <video *ngIf="isMovie(document)" controls  class="media-width">
                    <source [src]="'/media/'+document.id" class="media-width" type="video/mp4">
                </video>
            </div>
        </div>
    </div>
</div>
