import { Injectable } from '@angular/core';
import { Address2, Address } from 'src/nswag';
import { IActorBase } from '../dashboard/models/Actor';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }
  static concatCommaStr(s: string, separator: string = ", "): string {
    return this.concatStr(s, separator);
  }

  static concatStr(s: string, separator: string = ""): string {
      if (s) {
          return s + separator;
      }
      return "";
  }

  static concatSpaceStr(s: string, separator: string = " "): string {
      return this.concatStr(s, separator);
  }

  static hasAddress(addresses: Address2[]): boolean{
    return (addresses && addresses.length > 0);
  }

  static getAddress(addresses: Address2[]) : string {
    if (this.hasAddress(addresses)) {
        var add = addresses[0];
        return this.concatCommaStr(add.line1) + this.concatCommaStr(add.line2) + this.concatCommaStr(add.city) + this.concatCommaStr(add.county) + this.concatCommaStr(add?.countryIsoCode) + this.concatStr(add.postcode);
    }
    return "Data not available";
  }

  static getCountry(addresses: Address2[]) : string {
    if (this.hasAddress(addresses)) {
        return addresses[0]?.countryIsoCode;
    }
    return "Data not available";
  }

  static getDOB(datesOfBirth: string[]) {
    return datesOfBirth?.length > 0 ? datesOfBirth[0] : null;
  }

  static getNationality(nationalityCodes: string[]) {
    return nationalityCodes?.length > 0 ? nationalityCodes[0] : null;
  }
    
  static addAddress(resultActor: any, actor: IActorBase): Address {
    if (this.hasAddress(resultActor.addresses)) {
        var address = resultActor.addresses[0];
        return new Address({
            addressLine1: address.line1,
            addressLine2: address.line2,
            country: address.countryIsoCode,
            countyOrState: address.county,
            postOrZipCode: address.postcode,
            fullAddress: this.getAddress(resultActor.addresses)
        });
    }
    return null;
  }

  static abbrNum(number, decPlaces) {
    //if number is greater than 10,000, abbreviate it
    if (number >= 10000) {

      // 2 decimal places => 100, 3 => 1000, etc
      decPlaces = Math.pow(10,decPlaces);

      // Enumerate number abbreviations
      let abbrev = [ "k", "m", "b", "t" ];

      // Go through the array backwards, so we do the largest first
      for (let i=abbrev.length-1; i>=0; i--) {

          // Convert array index to "1000", "1000000", etc
          let size = Math.pow(10,(i+1)*3);

          // If the number is bigger or equal do the abbreviation
          if(size <= number) {
              // Here, we multiply by decPlaces, round, and then divide by decPlaces.
              // This gives us nice rounding to a particular decimal place.
              number = Math.round(number*decPlaces/size)/decPlaces;

              // Handle special case where we round up to the next abbreviation
              if((number == 1000) && (i < abbrev.length - 1)) {
                  number = 1;
                  i++;
              }

              // Add the letter for the abbreviation
              number += abbrev[i];

              // We are done... stop
              break;
          }
      }
    }

    return number;
  }
}
