import { Injectable } from "@angular/core";
import { Profile } from "oidc-client";
import { ProfileService } from "src/app/profile.service";
import { MonitorLinkService } from "src/app/services/monitor-link.service";
import { BusinessMatch, BusinessProfile, ClientResult, FinCrimeCheckClient, GetAccountByIdModel, IndividualMatch, IndividualProfile, MonitoringClient, ScreeningClient } from "src/nswag";

enum EnabledFeature {
    Screening,
    Monitoring,
    Search,
    None
}

@Injectable({
    providedIn: 'root'
})

export class MatchReportService {
    public get individualProfiles(): IndividualProfile[] { return this._individualProfiles; }
    public get businessProfiles(): BusinessProfile[] { return this._businessProfiles; }
    public get individualMatches(): IndividualMatch[] { return this._individualMatches; }
    public get businessMatches(): BusinessMatch[] { return this._businessMatches; }

    public get showSpinner() : boolean { return this._showSpinner; };
    public get showReport() : boolean { return this._showReport; };
    public get userAccount(): Profile { return this._userAccount; };
    public get clients(): ClientResult[] { return this._clients; };
    public get fullName(): string { return this.profileService.fullName; }
    public get companyName(): string { return this.profileService.companyName; };

    private _individualMatches : IndividualMatch[] = [];
    private _businessMatches : BusinessMatch[] = [];

    private _individualProfiles: IndividualProfile[] = [];
    private _businessProfiles: BusinessProfile[] = [];

    private _clients: ClientResult[] = [];

    private _showReport = false;
    private _showSpinner = false;

    private _userAccount: Profile;

    private _enabledFeature = EnabledFeature.None;

    constructor(private profileService: ProfileService, private monitorClient: MonitoringClient, private screeningClient: ScreeningClient, private fcClient: FinCrimeCheckClient) {
        this._userAccount = this.profileService.getProfile();
        for (let feature of this.profileService.features) {
            if (feature == "Screen") {
                this._enabledFeature = EnabledFeature.Screening;
                break;
            }
            else if (feature == "Search") {
                this._enabledFeature = EnabledFeature.Search;
                break;
            }
            else if (feature == "Monitor") {
                this._enabledFeature = EnabledFeature.Monitoring;
                break;
            }
        }
    }

    public displayIndividualReport(matches : IndividualMatch[], profiles: IndividualProfile[], inMonitoring : boolean) {
        this._individualMatches = matches ?? [];
        this._businessMatches = [];
        this.initialiseIndividualReport(profiles);
    }

    public displayBusinessReport(matches : BusinessMatch[], profiles: BusinessProfile[], inMonitoring : boolean) {
        this._individualMatches = [];
        this._businessMatches = matches ?? [];
        this.initialiseBusinessReport(profiles);
    }

    public hideReport() {
        this.decomissionReport();
    }

    private initialiseIndividualReport(profiles: IndividualProfile[]) {

        this.startReportSetup();

        let total = this._individualMatches.length;
        this._individualProfiles = new Array(total);
        this._clients = new Array(total);
        for (let match of this._individualMatches) {
            let profile = profiles?.find( v => v?.resourceId == match.resourceId);
            if (profile) {
                this.addIndividualProfile(profile);
            }
            else if (this._enabledFeature == EnabledFeature.Monitoring) {
                this.monitorClient.getIndividualProfile(match.resourceId).subscribe(result => {
                    if (result.isSuccess) {
                        this.addIndividualProfile(result.data);
                    }
                });
            }
            else if (this._enabledFeature == EnabledFeature.Screening) {
                this.screeningClient.getIndividualProfile(match.resourceId).subscribe(result => {
                    if (result.isSuccess) {
                        this.addIndividualProfile(result.data);
                    }
                });
            }
            else if (this._enabledFeature == EnabledFeature.Search) {
                this.fcClient.getIndividualProfile(match.resourceId).subscribe(result => {
                    if (result.isSuccess) {
                        this.addIndividualProfile(result.data);
                    }
                });
            }
        }
        this.endReportSetup();
    }

    private initialiseBusinessReport(profiles: BusinessProfile[]) {

        this.startReportSetup();

        let total = this._businessMatches.length;
        this._businessProfiles = new Array(total);
        this._clients = new Array(total);
        for (let match of this._businessMatches) {
            let profile = profiles?.find( v => v?.resourceId == match.resourceId);
            if (profile) {
                this.addBusinessProfile(profile);
            }
            else if (this._enabledFeature == EnabledFeature.Monitoring) {
                this.monitorClient.getBusinessProfile(match.resourceId).subscribe(result => {
                    if (result.isSuccess) {
                        this.addBusinessProfile(result.data);
                    }
                });
            }
            else if (this._enabledFeature == EnabledFeature.Screening) {
                this.screeningClient.getBusinessProfile(match.resourceId).subscribe(result => {
                    if (result.isSuccess) {
                        this.addBusinessProfile(result.data);
                    }
                });
            }
            else if (this._enabledFeature == EnabledFeature.Search) {
                this.fcClient.getBusinessProfile(match.resourceId).subscribe(result => {
                    if (result.isSuccess) {
                        this.addBusinessProfile(result.data);
                    }
                });
            }
        }
        this.endReportSetup();
    }

    private startReportSetup() {
        this._showReport = true;
        this._showSpinner = true;

        var w: any = window;
        if (w.fcWidget) {
          w.fcWidget.hide();
        }
    }

    private endReportSetup() {
        setTimeout(() => {
            this._showSpinner = false;
        }, 3000);
    }

    private addIndividualProfile(profile: IndividualProfile) {
        let pos = this._individualMatches.findIndex( v => v.resourceId == profile.resourceId);
        if (pos > -1) {
            this.individualProfiles[pos] = profile;
            this._clients[pos] = MonitorLinkService.createDummyClientresult(profile);
        }
    }

    private addBusinessProfile(profile: BusinessProfile) {
        let pos = this._businessMatches.findIndex( v => v.resourceId == profile.resourceId);
        if (pos > -1) {
            this.businessProfiles[pos] = profile;
            this._clients[pos] = MonitorLinkService.createDummyClientresult(profile);
        }
    }

    private decomissionReport() {
        this._individualMatches = [];
        this._businessMatches = [];
        this._businessProfiles = [];
        this._individualProfiles = [];
        this._showReport = false;
        this._showSpinner = false;

        var w: any = window;
        if (w.fcWidget) {
            w.fcWidget.show();
        }
    }

}
