import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuditLog } from 'src/nswag';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss']
})
export class AuditListComponent implements OnInit {

  @Input() currentPage = 1;
  @Input() auditList : AuditLog[];
  @Input() auditTotal = 0;
  @Input() pageSize = 10;
  @Input() hidePagination = false;

  @Output() changePageEvent = new EventEmitter<number>();

  constructor() { 
  }

  ngOnInit(): void {
  }

  public handlePageChange(event: number): void {
    this.currentPage = event;
    this.changePageEvent.emit(event);
  }

  // Displays company or person's name
  public displayClientName(audit: AuditLog) : string {
    return audit?.clientName;
  }

  public displayCreatedByName(audit: AuditLog) : string {
    return audit?.createdBy;
  }

  public displayMatchStatus(audit: AuditLog) : string {
    return audit?.isMatched;
  }

  public displayCreationDate(audit: AuditLog) : string {
    let date = audit?.createdOn;
    if (date){
      var formattedDate = new Date(date);
      return UtilityService.convertToLocalDateTime(formattedDate, "short");
    }
    return date;
  }
  
  public displayResults(audit: AuditLog) : string {
    return audit?.results;
  }
}
