import { UpdateAccountCommand } from 'src/nswag';
import { AccountManagementService } from './../../services/account-management.service';
import { Component, OnInit } from '@angular/core';
import { ChangePasswordCommand } from 'src/nswag';
import { ProfileService } from 'src/app/profile.service';

@Component({
  selector: 'app-manage-self',
  templateUrl: './manage-self.component.html',
  styleUrls: ['./manage-self.component.scss']
})
export class ManageSelfComponent implements OnInit {

  firstName: string;
  lastName: string;
  email: string;
  emailConfirmed: boolean;

  constructor(
    private accountService: AccountManagementService, private profileService: ProfileService) {
      this.firstName = this.profileService.firstName;
      this.lastName = this.profileService.lastName;
      this.email = this.profileService.email;
      this.emailConfirmed = this.profileService.emailVerified;
    }

  changePasswordModel = new ChangePasswordCommand();

  ngOnInit(): void {
    
  }

  updateProfile() {
    const cmd = new UpdateAccountCommand();
    cmd.firstName = this.firstName;
    cmd.lastName = this.lastName;
    cmd.email = this.email;
    this.accountService.updateProfile(cmd);
    this.resetForm();

    // update local profile
    this.profileService.firstName = this.firstName;
    this.profileService.lastName = this.lastName;
    this.profileService.email = this.email;
  }

  changePassword() {
    this.accountService.changePassword(this.changePasswordModel);
    this.resetForm();
  }

  resetForm() {
    const form = document.getElementById('userDetailsForm');
    const elements = form.getElementsByTagName('small');
    for (let index = 0; index < elements.length; index++) {
      const element = elements.item(index);
      element.innerText = '';
    }
  }

}
