import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthorizeService } from '../authorize.service';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, private authService: AuthorizeService) { }

  @Input() countdown = 300;

  ngOnInit(): void {
  }

  refreshSessionToken() {
    this.authService.refreshToken();
    this.activeModal.dismiss();
  }

  public async logout() {
    this.authService.signOut(null,true);
  }

  public getCountDown(): string {
    let minutes = Math.floor(this.countdown / 60);
    let remainingSeconds = this.countdown % 60;
    let result = "";
    if (minutes > 1) {
      result = `${minutes} minutes and `;
    }
    else if (minutes == 1) {
      result = `${minutes} minute and `;
    }
    if (remainingSeconds > 1 || remainingSeconds == 0) {
      result += `${remainingSeconds} seconds`;
    }
    else {
      result += `${remainingSeconds} second`;
    }
    return result;
  }
  
}
