import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiResponseOfGuid, MediaClient, UploadFileMetadata, UploadTenantMediaCommand } from 'src/nswag';
import { AlertService } from '../_alert';
import { FileService } from '../utils/file.service';
import { Guid } from '../utils/guid';

@Component({
  selector: 'app-media-admin',
  standalone: false,
  templateUrl: './media-admin.component.html',
  styleUrl: './media-admin.component.scss'
})

export class MediaAdminComponent {

  @ViewChild('fileInput') fileInput;
  public maxFileSizeInBytes = 20 * 1024 * 1024; // 20MB
  public tenantId: string;
  public tenantName: string;
  public isLoading: boolean = false;

  private prohibitedMimeTypes = {
    '.exe': 'application/x-msdownload',
    '.bat': 'application/x-bat',
    '.cmd': 'application/cmd',
    '.com': 'application/x-com',
    '.cpl': 'application/cpl',
    '.dll': 'application/x-msdownload',
    '.jar': 'application/java-archive',
    '.js': 'application/javascript',
    '.msi': 'application/x-msi',
    '.vb': 'application/x-vbscript',
    '.wsf': 'application/scriptfile',
    '.app': 'application/x-app',
    '.dmg': 'application/x-apple-diskimage',
    '.pkg': 'application/vnd.apple.installer+xml',
    '.sh': 'application/x-sh',
    '.php': 'application/x-httpd-php',
    '.pl': 'application/x-perl',
    '.py': 'application/x-python-code',
    '.rb': 'application/x-ruby'
  };

  private alertServiceOptions = {
    autoClose: false,
    keepAfterRouteChange: false
  };
  
  constructor(private router: Router, public mediaClient: MediaClient, public alertService: AlertService) {
    const state = this.router.getCurrentNavigation()?.extras?.state
    this.tenantId = state?.tenantId ?? this.tenantId;
    this.tenantName = state?.tenantName ?? this.tenantName;
    this.uploadForm.controls.mediaId.disable();
  }

  public uploadForm = new FormGroup({
    file: new FormControl(null, [Validators.required, this.fileSizeValidator(), this.mimeTypeValidator()]),
    showMediaIdInput: new FormControl(false),
    mediaId: new FormControl(null, [Validators.required, this.guidValidator()]),
    mediaDescription: new FormControl('', [Validators.required, this.trimmedNotEmptyValidator()])
  }, { updateOn: 'change' });
  
  public onSelectFile(event) {
    if (event?.target?.files && event?.target?.files[0]) {
      const file = event.target.files[0];
      FileService.LoadFile(file, (meta) => {
        this.uploadForm.controls.file.setValue(meta);
        this.uploadForm.controls.file.markAsDirty();
      });
    }
  }

  public onCheckboxChange(): void {
    const mediaIdControl = this.uploadForm.controls.mediaId;
    if (!this.uploadForm.controls.showMediaIdInput.value) {
      this.uploadForm.controls.mediaId.reset();
      mediaIdControl.disable()
    } else {
      mediaIdControl.enable(); 
    }
  }

  public generateGuid(): void {
    this.uploadForm.controls.mediaId.patchValue(Guid.newGuid());
    this.uploadForm.controls.mediaId.markAsDirty();
  }

  public upload() {
    if(this.uploadForm.valid) {
      this.isLoading = true;
      const command = new UploadTenantMediaCommand({ 
        mediaId: this.uploadForm.controls.mediaId.value,
        file: this.uploadForm.controls.file.value,
        mediaDescription: this.uploadForm.controls.mediaDescription.value,
        tenantId: this.tenantId
      });
  
      this.mediaClient.uploadTenantMedia(command).subscribe({
        next: (result: ApiResponseOfGuid) => {
          this.alertService.success(`Media uploaded successfully. MediaId: '${result.data}'.`, this.alertServiceOptions);
          this.clearFormModels();
        },
        error: (err) => {
          this.alertService.error("The media upload failed.", this.alertServiceOptions);
        },
      }).add(() => this.isLoading = false);
    }
  }

  public bytesToMB(bytes: any): any {
    return bytes / (1024 * 1024)
  }

  private clearFormModels(): void {
    this.fileInput.nativeElement.value = null; // remove decoupled file from non-formcontrol input 
    this.uploadForm.reset();
    this.uploadForm.controls.mediaId.disable();
  }

  private trimmedNotEmptyValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const isValid = control?.value?.trim()?.length > 0;
      return isValid ? null : { 'emptyWhenTrimmed': { value: control.value } };
    };
  }  

  private guidValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control?.value) {
        const isValid = Guid.isGuid(control.value);
        return isValid ? null : { 'invalidGuid': { value: control.value } };
      }
      return null;
    };
  }  

  private fileSizeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const file = control.value;
      if (file) {
        const isValid = file.fileSize <= this.maxFileSizeInBytes;
        return isValid ? null : { 'invalidFileSize': { value: control.value } };
      }
      return null;
    };
  }

  private mimeTypeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const file: UploadFileMetadata = control.value;
       if (file) {
        const isValid = file.fileType && !this.isProhibitedMimeType(file.fileType);
        return isValid ? null : { 'invalidFileType': { value: control.value } };
      } 
      return null;
    };
  }

  private isProhibitedMimeType(mimeType: string): boolean {
    return Object.values(this.prohibitedMimeTypes).includes(mimeType);
  }
}
