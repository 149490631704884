import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Assessment } from 'src/nswag';
import { RiskAssessmentService } from '../services/risk-assessment.service';
import { ProfileService } from 'src/app/profile.service';

@Component({
  selector: 'assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrls: ['./assessment-list.component.scss']
})
export class AssessmentListComponent implements OnInit {

  @Input() currentPage = 1;
  @Input() assessmentList : Assessment[];
  @Input() assessmentTotal = 0;
  @Output() viewGroupReportEvent = new EventEmitter<Assessment>();
  @Output() editEvent = new EventEmitter<Assessment>();
  @Output() copyEvent = new EventEmitter<Assessment>();
  @Output() sendEvent = new EventEmitter<Assessment>();
  @Output() deleteEvent = new EventEmitter<Assessment>();
  @Output() viewAuditHistoryEvent = new EventEmitter<Assessment>();
  @Output() manageRecipientsEvent = new EventEmitter<Assessment>();
  @Output() manageQuestionsEvent = new EventEmitter<Assessment>();


  @Output() changePageEvent = new EventEmitter<number>();

  @ViewChild(NgbDropdown)
  private menu: NgbDropdown;
  public isAdministrator: boolean = false;

  public pageSize = RiskAssessmentService.pageSize;
  // These are to support various row sizes per page - left in in case we use it when server side support added
  //public pageSizes = [10, 20, 50];
  //public currentIndex = -1;

  public currentAssessment: Assessment;

  constructor(private riskAssessmentService: RiskAssessmentService, private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.isAdministrator = this.profileService.IsAdministrator;
  }

  public closeMenu(){
    this.menu.close();
  }

  public handlePageChange(event: number): void {
    this.currentPage = event;
    this.changePageEvent.emit(event);
  }

  // Displays company or person's name
  public displayAssessment(assessmentResult: Assessment) : string {
    return assessmentResult?.name;
  }

  public displayDescription(assessmentResult: Assessment) : string {
    return assessmentResult?.description;
  }

  public displayDateCreated(assessmentResult: Assessment) : string {
    return assessmentResult?.createdOn?.toDateString();
  }

  public displayDateSent(assessmentResult: Assessment) : string {
    return assessmentResult?.sentOn?.toDateString();
  }

  public displayAuthor(assessmentResult: Assessment) : string {
    return assessmentResult?.createdBy;
  }

  public displayStatus(assessmentResult: Assessment) : string {
    return assessmentResult?.status?.toString();
  }

  public setFocusedAssessment(assessmentResult: Assessment) {
    this.currentAssessment = assessmentResult;
  }

  public dropDownClicked(event) {
    event.stopPropagation();
  }

  public viewGroupReport(event) {
    event.stopPropagation();
    this.viewGroupReportEvent.emit(this.currentAssessment);
  }

  public editAssessment(event) {
    event.stopPropagation();
    this.editEvent.emit(this.currentAssessment);
  }

  public deleteAssessment(event) {
    event.stopPropagation();
    this.deleteEvent.emit(this.currentAssessment);
  }

  public copyAssessment(event) {
    event.stopPropagation();
    this.copyEvent.emit(this.currentAssessment);
  }

  public sendAssessment(event) {
    event.stopPropagation();
    this.sendEvent.emit(this.currentAssessment);
  }

  public viewAuditHistory(event) {
    event.stopPropagation();
    this.viewAuditHistoryEvent.emit(this.currentAssessment);
  }

  public manageRecipients(event) {
    event.stopPropagation();
    this.manageRecipientsEvent.emit(this.currentAssessment);
  }

  public manageQuestionSets(event) {
    event.stopPropagation();
    this.manageQuestionsEvent.emit(this.currentAssessment);
  }
}
