import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShepherdService } from 'angular-shepherd';
import { AlertService } from '../_alert';
import { ConfirmationDialogService } from '../_confirmation-dialog/ConfirmationDialog.service';
import { CreateAssessmentDialogComponent, SaveType } from './create-assessment-dialog/create-assessment-dialog.component';
import { ManageQuestionSetsDialogComponent } from './manage-question-sets-dialog/manage-question-sets-dialog.component';
import { RiskAssessmentService } from './services/risk-assessment.service';
import { ViewAuditTrailComponent } from './view-audit-trail/view-audit-trail.component';
import { steps as defaultSteps, defaultStepOptions } from './data';
import { Assessment } from 'src/nswag';

@Component({
  selector: 'app-risk-assessment',
  templateUrl: './risk-assessment.component.html',
  styleUrls: ['./risk-assessment.component.scss'],
})
export class RiskAssessmentComponent implements OnInit, OnDestroy {
  @Input() showViewAssessments: boolean;
  public showGroupReport: boolean = false;
  public currentAssessment: Assessment;
  public showMain: boolean = true;

  constructor(private confirmationDialogService: ConfirmationDialogService, public riskAssessmentService: RiskAssessmentService, private modalService: NgbModal, private alertService: AlertService, private confirmationService: ConfirmationDialogService, private guideTour: ShepherdService) {
  }

  ngOnInit(): void {
    this.showViewAssessments = true;
    this.riskAssessmentService.loadAssessments();
  }

  ngOnDestroy() {
    this.guideTour = null;
    // close all modal pop upon component destroy
    this.modalService.dismissAll(false);
  }

  public changePage(page: number) {
    this.riskAssessmentService.loadAssessmentsPage(page);
  }

  public searchAssessment(search: string) {
    this.riskAssessmentService.searchForAssessments(search);
  }

  public goBack() {
    this.showViewAssessments = true;
  }

  public viewGroupReport(assessment) {
    if (assessment !== undefined){
      this.showMain = false;
      this.showGroupReport = true;
      this.currentAssessment = assessment;
    }

  }

  public closeGroupReport(cancelled: boolean) {
    this.showMain = true;
    this.showGroupReport = false;
  }

  public editAssessment(assessment) {
    if (assessment.sentOn) {
      this.confirmationDialogService.information('Unable to perform action', 'This Assessment cannot be edited as it has been sent to it\'s recipients', false, 'OK');
    } else {
      const model = this.modalService.open(CreateAssessmentDialogComponent);
      var industryName = this.riskAssessmentService.getIndustryNameById(assessment?.industryVerticalsId);
      model.componentInstance.industry = industryName;
      model.componentInstance.existingAssessment = assessment;
      model.componentInstance.saveType = SaveType.EDIT;
      model.componentInstance.ButtonText = 'Save';
      model.componentInstance.TitleText = SaveType.EDIT;
    }
  }

  public copyAssessment(assessment) {
    const model = this.modalService.open(CreateAssessmentDialogComponent);
    var industryName = this.riskAssessmentService.getIndustryNameById(assessment?.industryVerticalsId);
    model.componentInstance.industry = industryName;
    model.componentInstance.existingAssessment = assessment;
    model.componentInstance.saveType = SaveType.COPY;
    model.componentInstance.ButtonText = SaveType.COPY;
    model.componentInstance.TitleText = SaveType.COPY;
  }

  public sendAssessment(assessment) {
    this.riskAssessmentService.sendAssessment(assessment).subscribe(result => {
      this.alertService.success('The assessment has been sent successfully', { autoClose: true });
      assessment.status = 'Sent';
      this.riskAssessmentService.loadAssessments();
    },
      error => {
        // TODO: change to a modal?
        const errorMessages = JSON.parse(error.response)?.errors;
        console.dir(errorMessages);
        if (errorMessages && errorMessages.length) {
          errorMessages.forEach(
            err => {
              console.log(err.errorCode);
              let errorMessage = 'An error occured. Please try again.'
              switch (err.errorCode) {
                // FIXME: figure out how to add enum to nswag
                case 'NoQuestionSetsSelected':
                  errorMessage = 'To send an Assessment at least one Question Set must be selected.';
                  break;
                case 'NoRecipientsSelected':
                  errorMessage = 'To send an Assessment at least one Recipient must be assigned.';
                  break;
              }

              return this.alertService.error(errorMessage, { autoClose: true });
            }
          );
        } else {
          this.alertService.error('One or more assessments failed to send. Please check details and try again.', { autoClose: true });
        }
      });
  }

  public deleteAssessment(assessment) {
    if (assessment.sentOn && assessment === 'Created') {
      this.confirmationDialogService.information('Unable to perform action', 'This Assessment cannot be deleted as it has been sent to it\'s recipients', false, 'OK');
    } else {
      this.confirmationService.confirm("Confirm deletion", "Are you sure you want to delete this assessment?").then(r => {
        if (r) {
          this.riskAssessmentService.deleteAssessment(assessment).subscribe(r => {
            this.riskAssessmentService.removeAssessment();
            this.alertService.success("The assessment has been deleted", { autoClose: true });
          });
        }
      });
    }
  }

  public viewAuditHistory(assessment) {
    const model = this.modalService.open(ViewAuditTrailComponent, { size: 'lg', scrollable: true });
    model.componentInstance.assessment = assessment;
  }

  public manageRecipients(assessment) {
    this.riskAssessmentService.SetCurrentAssessmentList(assessment);
    this.showViewAssessments = false;
  }
  public manageQuestions(assessment) {
    const model = this.modalService.open(ManageQuestionSetsDialogComponent, { size: 'lg', scrollable: true });
    model.componentInstance.assessment = assessment;
  }

  //Tour guide start function
  start() {
    this.guideTour.defaultStepOptions = defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(defaultSteps);
    this.guideTour.start();
  }
}
