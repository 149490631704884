import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModule } from '../loader/loader.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { IntelligenceAdminListComponent } from './intelligence-admin/intelligence-admin-list/intelligence-admin-list.component';
import { IntelligenceAdminComponent } from './intelligence-admin/intelligence-admin.component';
import { IntelligenceComponent } from './intelligence.component';
import { IntelligenceRequestComponent } from './intelligence-request/intelligence-request.component';
import { DatePickerModule } from "../date-picker/date-picker.module";
import { UploadReportComponent } from './intelligence-admin/upload-report/upload-report.component';
import { EditProjectDialogComponent } from './intelligence-admin/edit-project-dialog/edit-project-dialog.component';
import { ViewAuditTrailComponent } from './view-audit-trail/view-audit-trail.component';
import { IntelligenceListComponent } from './intelligence-list/intelligence-list.component';
import { IntelligenceRequestButtonComponent } from './intelligence-request-button/intelligence-request-button.component';
import { RequestProjectDialogComponent } from './intelligence-admin/request-project-dialog/request-project-dialog.component';

@NgModule({
    declarations: [
        IntelligenceComponent,
        IntelligenceAdminComponent,
        IntelligenceAdminListComponent,
        IntelligenceRequestComponent,
        UploadReportComponent,
        EditProjectDialogComponent,
        ViewAuditTrailComponent,
        IntelligenceListComponent,
        IntelligenceRequestButtonComponent,
        RequestProjectDialogComponent
    ],
    exports: [
        IntelligenceComponent,
        IntelligenceAdminComponent,
        IntelligenceAdminListComponent,
        IntelligenceRequestComponent,
        UploadReportComponent,
        EditProjectDialogComponent,
        ViewAuditTrailComponent,
        IntelligenceListComponent,
        IntelligenceRequestButtonComponent,
        RequestProjectDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        LoaderModule,
        NgxPaginationModule,
        NgSelectModule,
        DatePickerModule
    ]
})
export class IntelligenceModule { }
