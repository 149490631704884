import { Component, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { DashboardService } from "src/app/services/dashboard.service";
import { MonitorLinkService } from "src/app/services/monitor-link.service";
import { UtilityService } from "src/app/utilitities/utilities";
import { Address, Address2, BusinessProfile, ClientResult, CompanyInfo, EntityTypes, Evidence, FinCrimeCheckClient, Gender, IndividualProfile, LAMPSStatus, OfficerInfo, SanEntries, SelectBusinessResultCommand, SelectIndividualResultCommand } from 'src/nswag';
import { DummyCompanyActor, DummyIndividualActor, IActorBase } from "../models/Actor";
@Component({
  selector: "app-investigation-details",
  templateUrl: "./investigation-details.component.html",
  styleUrls: ["./investigation-details.component.scss"]
})
export class InvestigationDetailsComponent implements OnChanges{
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;

  @Input() actor: IActorBase;
  @Input() hasLAMPS: boolean;
  @Input() client: ClientResult;
  public canClearProfile: boolean;

  @Input() individualProfile: IndividualProfile;
  @Input() businessProfile: BusinessProfile;

  public profile: any;

  public currentTab = 0;
  
  public sanctions: SanEntries;
  public name: string;
  public isSPIL = false;
  public hasLawEnforcement: LAMPSStatus;

  public evidences: Evidence[];

  public isBusiness: boolean;

  public cachedProfiles: string[] = [];

  constructor(
    private dashService: DashboardService, private finCrimeCheckClient: FinCrimeCheckClient) {}


  ngOnChanges(changes: SimpleChanges): void {

    this.profile = this.individualProfile ?? this.businessProfile;
    if (this.actor) {
      this.isSPIL = this.actor.actorType == EntityTypes.Individual;
    }

    this.canClearProfile = this.actor != null && this.hasLAMPS && (this.actor.clientId == null || this.actor.clientId == undefined);

    if (changes.client) {
      // WE have a change to the client if it is null then create a dummy client
      if (changes.client.currentValue == null) {
        this.client = MonitorLinkService.createDummyClientresult(this.profile);
      }
    }
    else if (!this.client?.client?.id) {
      // In the case that the client is null OR a dummy was created we need to create another based on the profile
      this.client = MonitorLinkService.createDummyClientresult(this.profile);
    }
    this.hasLawEnforcement = this.isSPIL ? LAMPSStatus.Changed :  this.client?.matchStatus?.hasLawEnforcement;
  
    this.cachedProfiles = this.filterCacheProfiles();

    this.name = this.actor.name;

    if (this.individualProfile) {
      this.isBusiness = false;
      this.sanctions = this.individualProfile?.sanEntries;
      this.evidences = this.individualProfile?.evidences;
    }
    else if (this.businessProfile) {
      this.isBusiness = true;
      this.sanctions = this.businessProfile?.sanEntries;
      this.name = this.client?.client?.business?.name ?? this.name;
      this.evidences = this.businessProfile?.evidences;
    }
    else {
      this.isBusiness = (this.actor.actorType == EntityTypes.Company);
    }

  }

  onClientChange(newClient: ClientResult) {
  //    this.client = newClient;
  //  this.monitorService.refreshSelectedClient(newClient);
  }

  public setTabAsClicked(tabNumber: number) {
    this.currentTab = tabNumber;
  }

  public addBusinessToGraph(profileId: string) {
    const actor = new DummyCompanyActor(new CompanyInfo({
      id: profileId,
      isActive: true,
    }));
    actor.profileId = profileId;
    actor.hasLAMPS = true;
    if (profileId) {
      actor.colour = '#DC3545';
    }
    this.dashService.profileLoader(actor).subscribe( result => {
      if (result) {
        const profile = result as BusinessProfile;
        actor.name = profile.name;
        actor.additionReason = "Added by association with " + this.name;

        const info = actor.companyInfo;
        info.id = profile.resourceId;
        info.jurisdiction = profile.addresses?.length > 0 ? profile.addresses[0].countryIsoCode : "";
        if (info.jurisdiction) {
          info.jurisdictionName = UtilityService.getCountryFromCode(info.jurisdiction);
        }
        info.name = profile.name;
        info.registeredAddress = this.getAddress(profile.addresses);

        this.mapLinkedBusinessToNode(profileId, actor).then(() => {
          this.dashService.addActorTograph(this.actor, actor, this.getRelationship(profileId, this.profile));
        }).catch((error) => console.log('Something went wrong: ', error));
        
        this.cachedProfiles.push(profile.resourceId);
      }
    });
  }

  public removeLampsResult() {
    this.dashService.removeLampsResult().subscribe(r => {
      if(r) {
        this.canClearProfile = false;
      }
    });
  }

  public addIndividualToGraph(profileId: string) {
    const actor = new DummyIndividualActor(new OfficerInfo({
      id: profileId,
      isActive: true,
    }));
    actor.profileId = profileId;
    actor.hasLAMPS = true;

    this.dashService.profileLoader(actor).subscribe( result => {
      if (result) {
        const newprofile = result as IndividualProfile;
        actor.name = UtilityService.getIndividualName(newprofile);
        actor.additionReason = "Added by association with " + this.name;

        const info = actor.asOfficerInfo;
        info.id = newprofile.resourceId;
        info.jurisdiction = newprofile.addresses?.length > 0 ? newprofile.addresses[0].countryIsoCode : "";
        if (info.jurisdiction) {
          info.jurisdictionName = UtilityService.getCountryFromCode(info.jurisdiction);
        }
        info.name = actor.name;
        info.address = this.getAddress(newprofile.addresses);
        info.dateOfBirth = newprofile.datesOfBirthIso?.length > 0 ? newprofile.datesOfBirthIso[0] : "";
        info.gender = newprofile.gender.toUpperCase() == "MALE" ? Gender.Male : Gender.Female;
        if (newprofile?.nationalitiesIsoCodes?.length > 0) {
          info.nationality = UtilityService.getCountryFromCode(newprofile?.nationalitiesIsoCodes[0]);
        }

        this.mapLinkedIndividualToNode(profileId, actor).then(() => {
          this.dashService.addActorTograph(this.actor, actor, this.getRelationship(profileId, this.profile));
        }).catch((error) => console.log('Something went wrong: ', error));

        this.cachedProfiles.push(newprofile.resourceId);
      }
    }); 
  }

  private async mapLinkedBusinessToNode(profileId: string, actor: IActorBase): Promise<IActorBase>{
    if (profileId) {
      try {
        let cmd = new SelectBusinessResultCommand({
          actorId: actor.id,
          resultsMediaId: null,
          profileId: profileId,
        });
        const linkResult = await this.finCrimeCheckClient.selectBusinessResult(cmd).toPromise();
        if (linkResult.isSuccess) {
          this.dashService.singleLAMPSGet(actor.id);
        }  
      }
      catch (error) {
        console.log(error);
      }
    } 
    return actor;
  }

  private async mapLinkedIndividualToNode(profileId: string, actor: IActorBase): Promise<IActorBase>{
    if (profileId) {
      try {
        let cmd = new SelectIndividualResultCommand({
          actorId: actor.id,
          resultsMediaId: null,
          profileId: profileId,
        });
        const linkResult = await this.finCrimeCheckClient.selectIndividualResult(cmd).toPromise();
        if (linkResult.isSuccess) {
          this.dashService.singleLAMPSGet(actor.id);
        }  
      }
      catch (error) {
        console.log(error);
      }
    } 
    return actor;
  }

  private getRelationship(profileId: string, profile: any) {
    let link = profile.individualLinks.find(v => v.resourceId == profileId);
    if (link) {
      return link.relationship;
    }
    link = profile.businessLinks.find(v => v.resourceId == profileId);
    if (link) {
      return link.relationship;
    }
    return "";
  }
  private getAddress(addresses: Address2[]): Address {
    const i = UtilityService.findLongestAddress(addresses);
    if (i > -1) {
      return  new Address({
        addressLine1: addresses[i]?.line1,
        addressLine2: addresses[i]?.line2,
        country: addresses[i]?.county,
        countyOrState: addresses[i]?.county,
        fullAddress: UtilityService.getLongestAddress(addresses),
        postOrZipCode: addresses[i]?.postcode,
      });
    }
    return null;
  }

  private filterCacheProfiles(): string[] {
    const cachedProfiles = this.dashService.getCachedProfileList();
    if (this.profile) {
      const mainProfile = this.profile;
      this.dashService.cytoscapeObject.nodes().forEach(node => {
        const actor: IActorBase = node.data("actor");
        if (actor?.profileId && actor?.id != this.actor.id) {
          // It has a profile and it is not the same object as our actor
          const linkExists = actor.actorType == EntityTypes.Company ?
            mainProfile?.businessLinks?.find(p => p.resourceId == actor.profileId) :
            mainProfile?.individualLinks?.find(p => p.resourceId == actor.profileId);

          if (linkExists) {
            // This actor is in the links for this actor - check edges to see if it is already connected to this actor
            const cachePos = cachedProfiles.findIndex(p => p == actor.profileId);
            if (cachePos > -1) {
              let alreadyConnected = false;
              node.connectedEdges().forEach( edge => {
                if (edge.data("source") == this.actor.id || edge.data("target") == this.actor.id) {
                  // There is already link between this actor and the actor
                  alreadyConnected = true;
                }
              });
              if (!alreadyConnected) {
                // We want to allow the user to select it so remove this profile from the list
                cachedProfiles.splice(cachePos, 1);
              }
            }
          }
        }
      });
    }
    return cachedProfiles;
  }
}
