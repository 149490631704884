<div class="modal-header text-center">
    <h4 class="modal-title col"> Confirm Screening for '{{ screenName }}'</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body text-center">
    <div>
        <p>You have <span class="btn credits">{{credits}} credits left</span></p> 
    </div>
    <div>
        <p>You are about to use <b>1</b> credit, do you wish to proceed?</p>
    </div>
    <div>
        <button type="button" class="btn btn-outline-danger mx-2" (click)="dismissModal()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="screen()">Screen</button>
        <div class="col-auto text-center mt-2">
            <input class="form-check-input me-1 checkbox" type="checkbox" id="dontAskMeAgainChk" [value]="dontAskMeAgain" [(ngModel)]="dontAskMeAgain"/>
            <label class="form-check-label checkbox-font-size" for="dontAskMeAgain"> Don't ask me again</label>
        </div>
    </div>    
</div>