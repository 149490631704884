import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Recipient } from 'src/nswag';
import { RiskAssessmentService } from '../../services/risk-assessment.service';

@Component({
  selector: 'existing-recipient-list',
  templateUrl: './existing-recipient-list.component.html',
  styleUrls: ['./existing-recipient-list.component.scss']
})
export class ExistingRecipientListComponent {

  @Input() currentPage = 1;
  @Input() existingRecipientList : Recipient[];
  @Input() recipientTotal = 0;
  public pageSize = RiskAssessmentService.pageSize;
  public currentRecipient: Recipient;
  public checkedRecipients = new Array<Recipient>();
  @Output() recipientData = new EventEmitter<Recipient[]>();
  @Output() changePageEvent = new EventEmitter<number>();
  
  constructor(private riskAssessmentService: RiskAssessmentService) {
  }

  public isChecked(recipient: Recipient): boolean {
    return this.checkedRecipients.some(x => x.id === recipient.id);
  }

  public handlePageChange(event: number): void {
    this.currentPage = event;
    this.changePageEvent.emit(event);
  }

  public setFocusedRecipient(recipientResult: Recipient) {
    this.currentRecipient = recipientResult;
  }

  public selectRecipient(recipient) {
    var chk = document.getElementById("chk_" + recipient.id) as HTMLInputElement;
    chk.checked = chk.checked ? false : true;

    if (chk.checked)
      this.checkedRecipients.push(this.currentRecipient);
    else
      this.checkedRecipients = this.checkedRecipients.filter(x => x.id !== this.currentRecipient?.id);
      
    this.recipientData.emit(this.checkedRecipients);
  }

  public displayName(recipient: Recipient) : string {
    return recipient?.contactName;
  }

  public displayEmail(recipient: Recipient) : string {
    return recipient?.contactEmail;
  }

  public displayCompany(recipient: Recipient) : string {
    return recipient?.companyName;
  }

  public displayIndustry(recipient: Recipient) : string {
    let industry = this.riskAssessmentService.getIndustryNameById(recipient?.industryVerticalsId);
    if (recipient?.industryVerticalsId.toUpperCase() === '75915B64-74DA-4CA8-9B76-822D6F12EEE0'){
      industry += ': ' + recipient?.industryVerticalsOther;
    }
    return industry;
  }
}
