import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { IndustryList } from 'src/app/models/industry-list';
import { AlertService } from 'src/app/_alert';
import { AddAssessmentCommand } from 'src/nswag';
import { ManageQuestionSetsDialogComponent } from '../manage-question-sets-dialog/manage-question-sets-dialog.component';
import { RiskAssessmentService } from '../services/risk-assessment.service';

export enum SaveType {
  COPY = 'Copy',
  EDIT = 'Edit',
  ADD = 'Add'
}

@Component({
  selector: 'create-assessment-dialog',
  templateUrl: './create-assessment-dialog.component.html',
  styleUrls: ['./create-assessment-dialog.component.scss']
})
export class CreateAssessmentDialogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal, public riskAssessmentService: RiskAssessmentService, public alertService: AlertService, private route: ActivatedRoute, private modalService: NgbModal) { }

  public assessment = new AddAssessmentCommand();
  public existingAssessment = new AddAssessmentCommand();
  public industry: string;
  public industryVerticalsOther: string;
  public saveType: SaveType = SaveType.ADD;
  public tenantId;
  public industryFocus: boolean = false;
  public duplicateName: boolean = false;
  public isSaving = false;

  @Input() ButtonText: string;
  @Input() TitleText: string;

  ngOnInit(): void {
    if (Object.keys(this.existingAssessment).length !== 0) {
      this.assessment = Object.assign({}, this.existingAssessment);
    }

    if (!this.assessment.welcomeMessage || !this.assessment.emailMessage) {
      this.riskAssessmentService.getAssessmentSettings(null).subscribe(res => {
        if (res.isSuccess) {
          this.assessment.welcomeMessage = !this.assessment.welcomeMessage ? res?.data?.welcomeMessage : this.assessment.welcomeMessage;
          this.assessment.emailMessage = !this.assessment.emailMessage ? res?.data?.emailMessage : this.assessment.emailMessage;
        }
      });
    }
    
  }

  resetDuplicateNameFlag() {
    this.duplicateName = false;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  canCreate(): boolean {
    if (this.isSaving) return false;
    let canCreate = !this.isAssessmentNameValid() ? false : true;
    canCreate = this.industry === 'Other' && !this.isOtherIndustryValid() ? false : canCreate;
    canCreate = !this.isIndustryValid() ? false : canCreate;
    return canCreate;
  }

  public isIndustryValid(): boolean {
    return this.industry && IndustryList.some(x => x.Name === this.industry);
  }

  private isAssessmentNameValid(): boolean {
    return this.assessment?.name && this.assessment?.name?.trim()?.length > 1;
  }

  private isOtherIndustryValid(): boolean {
    return this.assessment?.industryVerticalsOther && this.assessment?.industryVerticalsOther?.trim()?.length > 1;
  }

  public onBlur(e: Event): void {
    this.industryFocus = false;
    e.stopPropagation();
  }

  public onFocus(e: Event): void {
    this.industryFocus = true;
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  public industryTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.industrySubSet(term);
      })
    );

  private industrySubSet(term: string) {
    const list = [];
    if (term.length >= 0) {
      IndustryList.sort((a, b) => a.Name > b.Name ? 1 : -1).forEach(v => {
        if (v.Name.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.Id.toLowerCase().indexOf(term.toLowerCase()) > -1) {
          list.push(v.Name);
        }
      });
    }
    return list;
  }

  createAssessment() {
    this.isSaving = true;
    this.assessment.industryVerticalsId = this.riskAssessmentService.getIndustryIdByName(this.industry);

    const error = (error) => {
      const errorMessage = JSON.parse(error.response);
      this.duplicateName = errorMessage?.errors?.some(x => x.errorMessage === 'Assessment name already exists');
      this.alertService.error('Unfortunately, something has gone wrong. Please try again or contact support.', { autoClose: true });
      this.isSaving = false;
      console.error(error);
    };

    switch (this.saveType) {
      case SaveType.COPY:
        this.riskAssessmentService.copyAssessment(this.assessment).subscribe(result => {
          this.isSaving = false;
          if (result.isSuccess) {
            this.riskAssessmentService.refreshAssessmentList(result.data);
            this.dismissModal();
          }
        },
        error);
        break;
      case SaveType.EDIT:
        this.riskAssessmentService.editAssessment(this.assessment).subscribe(result => {
          this.isSaving = false;
          if (result.isSuccess) {
            this.riskAssessmentService.refreshCurrentAssessment(result.data);
            this.dismissModal();
          }
        },
        error);
        break;
      case SaveType.ADD:
        this.riskAssessmentService.addAssessment(this.assessment).subscribe(result => {
          this.isSaving = false;
          if (result.isSuccess) {
            this.riskAssessmentService.refreshAssessmentList(result.data);
            this.dismissModal();
            this.displayManageQuestionSet(result.data);
          }
        },
        error);
        break;
    }
  }

  displayManageQuestionSet(assessment) {
    const model = this.modalService.open(ManageQuestionSetsDialogComponent);
    model.componentInstance.assessment = assessment;
  }
}
