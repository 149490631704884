<div *ngIf="showMain">
    <div class="mt-3">
        <div class="row">
            <div class="col monitor-background">
                <div class="row">
                    <div class="col-5 no-padding-left">
                        <!-- <a class="advanced mt-2 text-center col-1" (click)="displaySearchOptions()">Advanced</a> -->
                        <div class="float-start">
                            <button class="btn btn-secondary add-client-tour screen-btn-new" (click)="startIDV()">
                                +&nbsp;IDV Client
                            </button>    
                        </div>
                    </div>
                    <div class="col-7 search-column">
                        <app-idv-search (searchChange)="searchProfile($event)"></app-idv-search>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col">
                        <div *ngIf="showAdvancedOptions" [@inOutAnimation]>
                            <advanced-search-options [advancedQuery]="advancedSearchOptions" (searchData)="setSearchOptions($event)"></advanced-search-options>
                        </div>
                    </div>
                </div> -->
                <div class="row detail-table">
                    <div class="mt-5" *ngIf="idvEnabled">
                        <table *ngIf="idvProfiles.length > 0" class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
                            <thead>
                                <tr>
                                    <th class="text-center">Status</th>
                                    <th>Full Name</th>
                                    <th>Date of Birth</th>
                                    <th>Country</th>
                                    <th>Created by</th>
                                    <th>Created on</th>
                                    <th scope="col" class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let idvProfile of idvProfiles | paginate : {
                                        itemsPerPage: pageSize,
                                        currentPage: currentPage,
                                        totalItems: profileTotal
                                    };
                                    let i = index"
                                    (mouseenter)="selectFocusedPerson(idvProfile)" (click)="selectIDVPerson(idvProfile)" class="align-middle">
                                    <td class="text-center">
                                        <span id="badge-{{idvProfile.id}}" class="badge {{statusCodeToClass(idvProfile.status)}}" (mouseover)="onBadgeHover(idvProfile)">{{idvProfile.status}}</span>
                                    </td>
                                    <td>
                                        {{idvProfile.fullName}}
                                    </td>
                                    <td>
                                        {{idvProfile.dateOfBirth | date : 'yyyy-MM-dd'}}
                                    </td>
                                    <td>
                                        {{getCountry(idvProfile)}}
                                    </td>
                                    <td>
                                        {{idvProfile.createdBy}}
                                    </td>
                                    <td>
                                        {{idvProfile.lastModifiedOn | date: 'yyyy-MM-dd'}}
                                    </td>
                                    <td class="text-center">
                                        <div ngbDropdown class="d-inline-block mon" id="monitor-list">
                                            <div class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle (click)="dropDownClicked($event)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                                                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                                </svg>
                                            </div>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <div ngbDropdownItem (click)="viewIDVReport($event)">IDV Report</div>
                                                <hr class="divider">
                                                <div ngbDropdownItem (click)="deleteIDV($event)" [disabled]="!isAdministrator">Delete IDV</div>
                                            </div>    
                                        </div>    
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls *ngIf="idvProfiles.length > 0" class="pagination"
                            previousLabel="Prev"
                            nextLabel="Next"
                            [responsive]="true"
                            (pageChange)="handlePageChange($event)">
                        </pagination-controls>
                        <span *ngIf="idvProfiles.length === 0">
                            There are no records to display
                        </span>
                    </div>
                </div>
            </div>
            <div *ngIf="currentProfile" class="col-4 detail-data">
                <app-idv-detail [idvProfile]="currentProfile" [clientResult]="clientResult" (changeProfile)="changeProfile($event)"></app-idv-detail>
            </div>
        </div>
    </div>
</div>

<app-idv-report [showReport]="showIDVReport" [idvProfile]="focusedProfile" [clientResult]="clientResult" (hide)="closeIdvReport($event)" ></app-idv-report>
