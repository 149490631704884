import { Component, Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { EntityTypes, ISubjectInfo, SearchOfficersAndCompaniesByFreeTextSearchType} from 'src/nswag';
import { DashboardService } from '../services/dashboard.service';
import { SearchCriteria } from './models/search-criteria';
import { SubjectRow } from './models/search-table-models';
import { SearchResultState } from './models/search-result-state';

import { DiligenciaLanguage } from '../dashboard/models/Models';
import { PremiumSearchUtilityService } from '../utilitities/premium-search-utilities';
import { CountriesService } from '../utils/countries.service';

export type SortColumn = 'CompanyName' | 'CompanyAddress' | 'CompanyJurisdiction' | 'CompanyActive' | 
'PersonName' | 'PersonCompanyOperationName' | 'PersonPosition' | 'PersonNationality' | 'PersonJurisdiction' | 'PersonDateOfBirth' | 'PersonActive' |
'DiligenciaCompanyName' | 'DiligenciaCompanyIsSOE' | 'DiligenciaCompanyLicenseNo' | 'DiligenciaCompanyRegisteredCountryCity' | 'DiligenciaCompanySectors' | 'DiligenciaCompanyRegNumber' | 'DiligenciaCompanyStatus' | 
'DiligenciaPersonName'| 'DiligenciaPersonDateOfBirth' | 'DiligenciaPersonNationality' | 'DiligenciaPersonDateOfDeath' | 'DiligenciaPersonRulingFamilyConnection' | 'DiligenciaPersonCountryOfResidence' | 'DiligenciaPersonGender' | '' ;
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number | boolean, v2: string | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();
  
  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search.component.scss']
})


export class SearchResultsComponent {
  
  @Input() criteria: SearchCriteria;
  @Input() state: SearchResultState;
  @Input() isBusy: boolean = false;
  @Output() result = new EventEmitter<ISubjectInfo>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  
  currentColumn: string;
  currentDirection: string;

  displayPremiumLanguageSelect: boolean = false;

  constructor(public dashService: DashboardService, public router: Router, public premiumUtils: PremiumSearchUtilityService ) {
  }

  public get diligenciaLanguage(): typeof DiligenciaLanguage {
    return DiligenciaLanguage; 
  }

  selectSubject(subject: SubjectRow) {
    this.result.emit(subject.subjectInfo);
  }



  onSort({ column, direction }: SortEvent) {

    this.currentColumn = column;
    this.currentDirection = direction;

    this.headers.forEach(header => {
      if (header.sortable !== this.currentColumn) {
        header.direction = '';
      }
    });

    this.reSortColumns();
  }

  getJurisdictionName(code: string) {
    return CountriesService.getJurisdictionName(code);
  }
  getNationalityName(code: string) {
    return CountriesService.getNationalityName(code);
  }
  reSortColumns() {
    if (this.criteria.searchType == EntityTypes.Company) {
      if (this.criteria.serviceType == SearchOfficersAndCompaniesByFreeTextSearchType.Premium)  {
        this.reSortDiligenciaCompanies();
      } else {
        this.reSortCompanies();
      }
    }
    else {
      if (this.criteria.serviceType == SearchOfficersAndCompaniesByFreeTextSearchType.Premium) {
        this.reSortDiligenciaPeople();
      } else {
        this.reSortPeople();
      }
    }
  }

  reSortPeople() {
    if (this.currentDirection === '' || this.currentColumn === '') {
      // Need to clone the array!
      this.state.sortedPeople = this.state.listedPeople.slice(0);
      this.reFilterPeople();
    }
    if (this.currentColumn === 'PersonName') {
      this.state.sortedPeople = this.state.sortedPeople.sort((a, b) => {
        const res = compare(a.name,b.name);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'PersonCompanyOperationName') {
      this.state.sortedPeople = this.state.sortedPeople.sort((a, b) => {
        const res = compare(a.companyOp, b.companyOp);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'PersonPosition') {
      this.state.sortedPeople = this.state.sortedPeople.sort((a, b) => {
        const res = compare(a.position,b.position);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'PersonNationality') {
      this.state.sortedPeople = this.state.sortedPeople.sort((a, b) => {
        const res = compare(a.nationality, b.nationality);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'PersonJurisdiction') {
      this.state.sortedPeople = this.state.sortedPeople.sort((a, b) => {
        const res = compare(a.jurisdiction, b.jurisdiction);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'PersonDateOfBirth') {
      this.state.sortedPeople = this.state.sortedPeople.sort((a, b) => {
        const res = compare(a.dateOfBirth,b.dateOfBirth);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'PersonActive') {
      this.state.sortedPeople = this.state.sortedPeople.sort((a, b) => {
        const res = compare(a.isActive, b.isActive);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
  }

  reSortDiligenciaPeople() {
    if (this.currentDirection === '' || this.currentColumn === '') {
      // Need to clone the array!
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.slice(0);
      this.reFilterPeople();
    }
    if (this.currentColumn === 'DiligenciaPersonName') {
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.sort((a, b) => {
        const res = compare(this.premiumUtils.dtoToSelectedLanguage(a.nameDto), this.premiumUtils.dtoToSelectedLanguage(b.nameDto));
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaPersonGender') {
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.sort((a, b) => {
        const res = compare(this.premiumUtils.dtoToSelectedLanguage(a.genderDto), this.premiumUtils.dtoToSelectedLanguage(b.genderDto));
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
      else if (this.currentColumn === 'DiligenciaPersonNationality') {
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.sort((a, b) => {
        const res = compare(this.premiumUtils.dtoToSelectedLanguage(a.nationalityDto), this.premiumUtils.dtoToSelectedLanguage(b.nationalityDto));
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaPersonCountryOfResidence') {
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.sort((a, b) => {
        const res = compare(this.premiumUtils.dtoToSelectedLanguage(a.countryOfResidenceDto), this.premiumUtils.dtoToSelectedLanguage(b.countryOfResidenceDto));
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaPersonRulingFamilyConnection') {
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.sort((a, b) => {
        const res = compare(a.hasRulingFamilyConnection, b.hasRulingFamilyConnection);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaPersonDateOfBirth') {
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.sort((a, b) => {
        const res = compare(a.dateOfBirth, b.dateOfBirth);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaPersonDateOfDeath') {
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.sort((a, b) => {
        const res = compare(a.dateOfDeath, b.dateOfDeath);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
  }

  reSortDiligenciaCompanies() {
    if (this.currentDirection === '' || this.currentColumn === '') {
      // Need to clone the array!
      this.state.sortediligenciaOrgs = this.state.listedDiligenciaOrgs.slice(0);
      this.reFilterDiligenciaCompanies();
    }
    else if (this.currentColumn === 'DiligenciaCompanyName') {
      this.state.sortediligenciaOrgs = this.state.sortediligenciaOrgs.sort((a, b) => {
        const res = compare(this.premiumUtils.dtoToSelectedLanguage(a.nameDto), this.premiumUtils.dtoToSelectedLanguage(b.nameDto));
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaCompanyRegNumber') {
      this.state.sortediligenciaOrgs = this.state.sortediligenciaOrgs.sort((a, b) => {
        const res = compare(a.registrationNumber, b.registrationNumber);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaCompanyStatus') {
      this.state.sortediligenciaOrgs = this.state.sortediligenciaOrgs.sort((a, b) => {
        const res = compare(this.premiumUtils.dtoToSelectedLanguage(a.statusDto), this.premiumUtils.dtoToSelectedLanguage(b.statusDto));
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaCompanySectors') {
      this.state.sortediligenciaOrgs = this.state.sortediligenciaOrgs.sort((a, b) => {
        const res = compare(this.premiumUtils.dtoArrayToSelectedLanguage(a.sectorsDto).find(x => x != null), this.premiumUtils.dtoArrayToSelectedLanguage(b.sectorsDto).find(x => x != null));
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaCompanyRegisteredCountryCity') {
      this.state.sortediligenciaOrgs = this.state.sortediligenciaOrgs.sort((a, b) => {
        const res = compare(this.premiumUtils.dtoToSelectedLanguage(a.countryOfRegistrationDto), this.premiumUtils.dtoToSelectedLanguage(b.countryOfRegistrationDto));
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaCompanyLicenseNo') {
      this.state.sortediligenciaOrgs = this.state.sortediligenciaOrgs.sort((a, b) => {
        const res = compare(a.licenseNumber, b.licenseNumber);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'DiligenciaCompanyIsSOE') {
      this.state.sortediligenciaOrgs = this.state.sortediligenciaOrgs.sort((a, b) => {
        const res = compare(a.isStateOwned, b.isStateOwned);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
  }


  reSortCompanies() {
    if (this.currentDirection === '' || this.currentColumn === '') {
      // Need to clone the array!
      this.state.sortedCompanies = this.state.listedCompanies.slice(0);
      this.reFilterCompanies();
    }
    else if (this.currentColumn === 'CompanyName') {
      this.state.sortedCompanies = this.state.sortedCompanies.sort((a, b) => {
        const res = compare(a.name, b.name);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'CompanyJurisdiction') {
      this.state.sortedCompanies = this.state.sortedCompanies.sort((a, b) => {
        const res = compare(a.jurisdiction, b.jurisdiction);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'CompanyAddress') {
      this.state.sortedCompanies = this.state.sortedCompanies.sort((a, b) => {
        const res = compare(a.address,b.address);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
    else if (this.currentColumn === 'CompanyActive') {
      this.state.sortedCompanies = this.state.sortedCompanies.sort((a, b) => {
        const res = compare(a.isActive, b.isActive);
        return this.currentDirection === 'asc' ? res : -res;
      });
    }
  }

  filterPeopleSearch(searchInput: any, columnName: string, ischeckbox: boolean = false) {
      // Reload the full list
      this.state.sortedPeople = this.state.listedPeople.slice(0);
      // Sort them
      this.reSortPeople();

      if (searchInput == "" || !searchInput) {
        this.state.filterList.delete(columnName);
      }
      else {
        this.state.filterList.set(columnName, searchInput.toLowerCase());
      }
      this.reFilterPeople();

  }

  filterDiligenciaPeopleSearch(searchInput: any, columnName: string, ischeckbox: boolean = false) {
      // Reload the full list
      this.state.sortediligenciaPeople = this.state.listedDiligenciaPeople.slice(0);
      // Sort them
      this.reSortDiligenciaPeople();

      if (searchInput == "" || !searchInput) {
        this.state.filterList.delete(columnName);
      }
      else {
        this.state.filterList.set(columnName, searchInput.toLowerCase());
      }
      this.reFilterDiligenciaPeople();
  }

  reFilterPeople() {
    this.state.filterList.forEach((filterText,column) => {
        // Filter them
      if (column == "isActive") {
        // check box - true - filter our all inactives
        this.state.sortedPeople = this.state.sortedPeople.filter(s => {
          return s.isActive.toLowerCase() == filterText;
        });
      }
      else {
        this.state.sortedPeople = this.state.sortedPeople.filter(s => {
          return (s[column].toLowerCase().includes(filterText));
        });
      }
    })
  }

  reFilterDiligenciaPeople() {
    this.state.filterList.forEach((filterText,column) => {
      this.state.sortediligenciaPeople = this.state.sortediligenciaPeople.filter(s => {
        if (typeof s[column] === 'object' && !Array.isArray(s[column])) {
          // dto
          return (this.premiumUtils.dtoToSelectedLanguage(s[column])?.toLowerCase()?.includes(filterText));
        } else if (Array.isArray(s[column])) {
          // array dto
          return (this.premiumUtils.dtoArrayToSelectedLanguage(s[column])?.toString()?.toLowerCase()?.includes(filterText));
        } else if (typeof(s[column]) === 'boolean') {
            // bool value
            return (s[column]?.toString()?.toLowerCase() == filterText);
        } else {
          // string value 
          return (s[column]?.toLowerCase()?.includes(filterText));
        }
      });
    })
  }

  filterCompanySearch(searchInput: any, columnName: string, ischeckbox: boolean = false) {
      // Reload the full list
      this.state.sortedCompanies = this.state.listedCompanies.slice(0);
      // Sort them
      this.reSortCompanies();

      if (searchInput == "" || !searchInput) {
        this.state.filterList.delete(columnName);
      }
      else {
        this.state.filterList.set(columnName, searchInput.toLowerCase());
      }
      this.reFilterCompanies();
  }

  filterDiligenciaCompanySearch(searchInput: any, columnName: string, ischeckbox: boolean = false) {
      // Reload the full list
      this.state.sortediligenciaOrgs = this.state.listedDiligenciaOrgs.slice(0);
      // Sort them
      this.reSortDiligenciaCompanies();

      if (searchInput == "" || !searchInput) {
        this.state.filterList.delete(columnName);
      }
      else {
        this.state.filterList.set(columnName, searchInput.toLowerCase());
      }
      this.reFilterDiligenciaCompanies();
  }

  reFilterCompanies() {
    this.state.filterList.forEach((filterText, column) => {
      // Filter them
      if (column == "isActive") {
        // filter our all inactives
        this.state.sortedCompanies = this.state.sortedCompanies.filter(s => {
          return s.isActive.toLowerCase() == filterText;
        });
      }
      else {
        this.state.sortedCompanies = this.state.sortedCompanies.filter(s => {
          return (s[column].toLowerCase().includes(filterText));
        });
      }
    })
  }

  reFilterDiligenciaCompanies() {
    this.state.filterList.forEach((filterText, column) => {
        this.state.sortediligenciaOrgs = this.state.sortediligenciaOrgs.filter(s => {
          if (typeof s[column] === 'object' && !Array.isArray(s[column])) {
            // dto
            return (this.premiumUtils.dtoToSelectedLanguage(s[column])?.toLowerCase()?.includes(filterText));
          } else if (Array.isArray(s[column])) {
            // array dto
            return (this.premiumUtils.dtoArrayToSelectedLanguage(s[column])?.toString()?.toLowerCase()?.includes(filterText));
          } else if (typeof(s[column]) === 'boolean') {
             // bool value
             return (s[column]?.toString()?.toLowerCase() == filterText);
          } else {
            // string value
            return (s[column]?.toLowerCase()?.includes(filterText));
          }
        });
    })
  }
}
  

