import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BusinessClient, BusinessProfile, ClientResult, IndividualClient, IndividualProfile, MatchStatus } from 'src/nswag';

@Directive()
export class BaseReportComponent {
    @Input() client: ClientResult;
    
    constructor() { }

    public businessClient : BusinessClient;
    public individualClient: IndividualClient;
    public matchStatus: MatchStatus;
    public isBusiness: boolean;
    public isIndividual: boolean;
    public isScreened = false;
    public inMonitoring = false;
}

@Directive()
export class BaseReportSectionComponent extends BaseReportComponent implements OnChanges {

    @Input() individualProfile: IndividualProfile;
    @Input() businessProfile: BusinessProfile;

    public hasIndividualProfile: boolean;
    public hasBusinessProfile: boolean;
    public clientName: string;

    ngOnChanges(changes: SimpleChanges): void {
        this.businessClient = this.client?.client?.business;
        this.individualClient = this.client?.client?.individual;
        this.matchStatus = this.client?.matchStatus;   
        this.isBusiness = this.businessClient != null ;
        this.isIndividual = this.individualClient != null;

        this.hasIndividualProfile = this.individualProfile != null;
        this.hasBusinessProfile = this.businessProfile != null;

        this.clientName = this.businessClient?.name ?? this.individualClient?.name;

        this.isScreened = this.client?.client?.screened;
        this.inMonitoring = this.client?.client?.inMonitoring;
    }
}
