import { Component, OnInit } from '@angular/core';
import { Project } from 'src/nswag';
import { IntelligenceService } from './services/intelligence.service';
import { ProfileService } from '../profile.service';
import { DownloadService } from '../services/download.service';
import { ViewAuditTrailComponent } from './view-audit-trail/view-audit-trail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentModule } from '../monitor/client-report/client-report.component';

@Component({
  selector: 'app-intelligence',
  templateUrl: './intelligence.component.html',
  styleUrls: ['./intelligence.component.scss']
})
export class IntelligenceComponent implements OnInit {

  constructor(public intelService: IntelligenceService, private profileService: ProfileService, private downloadService: DownloadService, private modalService: NgbModal) { }

  public projects: Project[];
  public currentModule = CurrentModule.INTELLIGENCE;

  ngOnInit(): void {
    this.intelService.getUsersProjects(this.profileService.userId, 1, IntelligenceService.pageSize);
  }

  public viewAudit(project: Project) {
    const model = this.modalService.open(ViewAuditTrailComponent, { size: 'lg', scrollable: true });
    model.componentInstance.project = project;
  }
  
  public downloadReport(mediaId: string){
    this.downloadService.download(mediaId);
  }
  public setFocusedProject(project: Project) {
    this.intelService.setCurrentProject(project);
  }

  public changePage(page: number) {
    this.intelService.getUsersProjects(this.profileService.userId, page, IntelligenceService.pageSize);
  }
}
