import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '../loader/loader.module';
import { ScreeningClient } from 'src/nswag';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { AuditComponent } from './audit.component';
import { AuditListComponent } from './audit-list/audit-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuditReportComponent } from './audit-report/audit-report.component';

@NgModule({
    declarations: [
        AuditComponent,
        AuditListComponent,
        AuditReportComponent
    ],
    providers: [
        ScreeningClient
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        LoaderModule,
        NgSelectModule,
        DatePickerModule,
        NgxPaginationModule
    ],
    exports: [ AuditComponent, AuditReportComponent ]
})
export class AuditModule { }
