<div class="modal-header">
  <h4 class="col">Select question sets to add</h4>
  <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
    <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
  </button>
</div>
<div class="modal-body">
  <div *ngFor="let group of questionSetGroups; let y = index;">
    <h5>{{group.title}}</h5>
    <div class="accordion accordion--custom" id="accordionExample">
      <div class="accordion-item" *ngFor="let set of group.questionSet; let i = index;">
        <h2 class="accordion-header" [id]="'heading' + y + '_' + i">
          <input type="checkbox" class="form-check-input program_checkbox" [id]="'chk' + set.id">
          <button [id]="'accButton'  + y + '_' + i" class="accordion-button" type="button" (click)="renderSurvey(set.questions,  y + '_' + i)" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + y + '_' + i" aria-expanded="false" [attr.aria-controls]="'collapse' + y + '_' + i">
            <div>
              {{set.name}}
              <small class="set-desc">
                {{set.description}}
              </small>
            </div>
          </button>
        </h2>
        <div [id]="'collapse' + y + '_' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + y + '_' + i" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            {{set.questions}}
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</div>
<div class="modal-footer">
  <button class="btn m-1 bg-secondary text-light" (click)="dismissModal()">Cancel</button>
  <button [disabled]="!canSave()" class="btn m-1 bg-primary text-light" (click)="selectQuestionSets()">Save</button>
</div>
