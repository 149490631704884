<div id="individual-profile-display">
    <div class="row">
        <div class="col">
            <img src="{{image}}" class="avatar-image"/>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h4>{{name}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mon-alerticongroup [client]="client" [removeTooltip]="removeTooltip"></mon-alerticongroup>
        </div>
    </div>
    <div *ngIf="showAlertStatus" class="row">
        <div class="col">
            <mon-alertstatus *ngIf="!isArchived" [client]="client" (clientChange)="onClientChange($event)"></mon-alertstatus>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr>  
        </div>
    </div>
    
    <div class="row" id="left-card">
        <div class="col-3">
            <label class="displaylabel-style">Gender:</label>
        </div>
        <div class="col-9">
            <span class="displaydetail-style">{{profile?.gender}}</span>
        </div>
    </div>
    <div *ngIf="profile?.datesOfBirthIso?.length > 0" class="row" id="left-card">
        <div class="col-3">
            <label class="displaylabel-style">Date of birth:</label>
        </div>
        <div class="col-9">
            <span class="displaydetail-style">{{getDate(profile?.datesOfBirthIso)}}</span>
        </div>        
    </div>
    <div *ngIf="profile?.isDeceased" class="row" id="left-card">
        <div class="col-3">
        <label class="displaylabel-style">Date of Death:</label>
        </div>
        <div class="col-9">
            <span class="displaydetail-style">{{getDate(profile?.datesOfDeathIso)}}</span>
        </div>
    </div>
    <div *ngIf="nationalities?.length > 0">
        <div *ngIf="showAllLists; else showMoreNationalities">
            <div *ngFor="let n of nationalities; index as i" class="row" id="left-card">
                <div class="row" id="left-card">
                    <div class="col-3">
                        <label *ngIf="i==0"class="displaylabel-style">Nationalities:</label>
                    </div>
                    <div class="col-9">
                        <span class="displaydetail-style">{{n}}</span>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #showMoreNationalities>
            <div class="row" id="left-card">
                <div class="col-3">
                    <label class="displaylabel-style">Nationalities:</label>
                </div>
                <div class="col-9">
                    <span class="displaydetail-style">
                        <app-readmore [list]="nationalities"></app-readmore>
                    </span>
                </div>
            </div>
        </ng-template>    
    </div>
    <div *ngIf="addresses?.length > 0">
        <div *ngIf="showAllLists; else showMoreAddresses">
            <div *ngFor="let address of addresses; index as i" class="row" id="left-card">
                <div class="col-3">
                    <label *ngIf="i==0" class="displaylabel-style">Addresses:</label>
                </div>
                <div class="col-9">
                    <span class="displaydetail-style">{{getAddress(address)}}</span>
                </div>    
            </div>    
        </div>
        <ng-template #showMoreAddresses>
            <div class="row" id="left-card">
                <div class="col-3">
                    <label class="displaylabel-style">Addresses:</label>
                </div>
                <div class="col-9">
                    <span class="displaydetail-style">
                        <app-readmore [list]="addressList"></app-readmore>
                    </span>
                </div>
            </div>
        </ng-template>    
    </div>
    <div *ngIf="profile?.aliases?.length > 0">
        <div *ngIf="showAllLists; else showMoreAliases">
            <div *ngFor="let alias of profile?.aliases; index as i" class="row" id="left-card">
                <div class="col-3">
                    <label *ngIf="i==0" class="displaylabel-style">Aliases:</label>
                </div>
                <div class="col-9">
                    <span class="displaydetail-style">{{getAliasName(alias)}}</span>
                    <span class="displaydetail-style">{{getAliasType(alias)}}</span>
                </div>
            </div>    
        </div>
        <ng-template #showMoreAliases>
            <div class="row" id="left-card">
                <div class="col-3">
                    <label class="displaylabel-style">Aliases:</label>
                </div>
                <div class="col-9">
                    <span class="displaydetail-style">
                        <app-readmore [list]="aliasList"></app-readmore>
                    </span>
                </div>
            </div>
        </ng-template>    
    </div>    
</div>