<div *ngIf="recipientReportService.showSpinner" id="temp-page-modal">
  <div class="mx-auto text-center align-self-center mt-5 py-5">
    <div class="spinner-border text-primary mt-5" role="status">
      <span class="sr-only"></span>
    </div>
    <p class="text-center text-primary">Preparing report</p>
  </div>
</div>

<div
  *ngIf="recipientReportService.showReport"
  id="report-page-modal"
  class="overflow-auto"
>
  <form>
    <div class="button-container">
      <div class="float-left p-3">
        <button
          id="print-button"
          class="btn btn-primary print"
          aria-label="Print"
          (click)="onPrint()"
        >
          Print Report
          <span class="icon-printer"></span>
        </button>
      </div>
      <div class="float-end p-3">
        <button
          id="close-button"
          type="btn btn-link btn-primary"
          class="close"
          aria-label="Close"
          (click)="closeReport()"
        >
          <span class="icon-cross"></span>
        </button>
      </div>
    </div>

    <div id="recipient-report" class="container-report mx-auto">
      <img
        alt="Report Cover"
        src="../../../../assets/images/assessment-header.png"
        style="
          width: 100%;
          margin-left: 0px;
          margin-top: 0px;
          transform: rotate(0rad) translateZ(0px);
          -webkit-transform: rotate(0rad) translateZ(0px);
        "
        title=""
      />

      <section>
        <br/><br/>
        <h2>Disclaimer</h2>
        <ul class="disclaimer">
            <li class="c8 li-bullet-0">
                The information contained in this document is strictly confidential
                and intended only for the use of the recipient. Any unauthorised use, dissemination, printing or copying
                of this report is prohibited without the prior consent of the authors.
            </li>
            <li class="c8 li-bullet-0">
                The content of this report has been generated by {{userName}}, {{tenantCompanyName}} on {{dateNow}} using the Themis Financial Crime
                Search Engine.
            </li>

            <li class="c8 li-bullet-0">
                All data sources are named but have defined which data to include or not in this report.
            </li>
            <li class="c8 li-bullet-0">
                The content of this report may not reflect the view and opinions of {{tenantCompanyName}}. {{userName}} agrees to indemnify
                and hold harmless against any damages or claims resulting from such unauthorised use. This material represents
                the best judgement of the authors and is based solely on information provided to them.
            </li>
            <li class="c8 li-bullet-0">
                This report has therefore been put together by {{userName}} who take full responsibility and liability for
                any contents.
            </li>
            <li class="c8 li-bullet-0">
                Themis International Services or Themis MENA shall not be held responsible for any reason whatsoever with respect to the content of its report.
            </li>
        </ul>
    </section>

      <section>
        <br /><br />
        <h3>Background</h3>
        {{ contactName }} completed the Themis online Risk Assessment on
        {{ dateNow }}. <br /><br />
        The Themis anti-financial crime (“AFC”) Rating is the result of
        {{ contactName }} completing a self-assessment based on question sets
        selected by {{ tenantCompanyName }}. This has not, to the best of
        Themis&apos; knowledge, been validated by a qualified independent source
        and therefore Themis takes no responsibility for the responses provided
        by the company to the self-assessment questions which resulted in this
        rating. <br /><br />
        <b>Details of assignment</b>
        <br /><br />
        This report provides a rating of {{ companyName }}&apos;s anti-financial
        crime maturity based on a self-assessment of the
        {{ companyName }}&apos;s current AFC controls and capabilities compared
        to best practice standards as required by the FATF and the UK&apos;s
        financial regulators, the FCA and PRA. <br /><br />
        <b>Jurisdiction and applicable legal and regulatory considerations</b>
        <br /><br />
        The primary jurisdiction is the United Kingdom and applicable law and
        regulation that applies here. Internationally, all UN conventions will
        apply and the FATF Standards and relevant EU.
        <br /><br />
        <table class="grouptable">
          <tr>
            <th>Applicable UK Legislation</th>
            <th>UK Regulators</th>
          </tr>
          <tr>
            <td>
              Proceeds of Crime Act 2002<br />
              Modern Slavery Act 2015<br />
              Criminal Finances Act 2017<br />
              Bribery Act 2010
            </td>
            <td>
              Financial Conduct Authority<br />
              Prudential Regulatory Authority
            </td>
          </tr>
          <tr>
            <th>UK Key Regulation</th>
            <th>UK Enforcement Agencies</th>
          </tr>
          <tr>
            <td>
              FCA Handbook<br />
              Joint Money Laundering Steering Group (JMLSG) <br />Guidance
            </td>
            <td>
              Serious Fraud Office<br />
              National Crime Agency<br />
              Office of Financial Sanctions Implementation<br />
            </td>
          </tr>
          <tr>
            <th>UK Applicable Collaborative Bodies</th>
          </tr>
          <tr>
            <td>Joint Money Laundering Intelligence Taskforce (JMLIT)</td>
          </tr>
        </table>
      </section>

      <section>
        <br /><br />
        <h3>Themis Risk Assessment</h3>
        <b
          >A pioneering Fintech tool to understand an Organisation&apos;s AFC
          controls and capabilities</b
        >
        <br /><br />
        Fighting Financial crime is a critical priority for both regulators and
        governments, with corporate fines to match. Firms wishing to be
        progressive and demonstrate anti-financial crime (AFC) good governance
        need to understand their capabilities and controls regarding tackling
        unseen threats. Being similar to other independent risk ratings &ndash;
        like Moody&apos;s or S&P &ndash; the Themis Risk Assessment helps to
        identify gaps in an organisation&apos;s anti-financial crime provisions
        and expertise and can assist in de-risking regulatory challenges,
        including bolstering ESG commitments.
        <br /><br />
        <b>Why invest in a Themis Risk Assessment?</b>
        <br /><br />
        Obtaining independent evidence of an Organisation&apos;s AFC controls
        and capabilities can help demonstrate good governance and best practice
        in managing relationships across organisations, to all your
        stakeholders, as well as your commitment to legal & regulatory
        responsibilities.
        <br /><br />
        <b>AFC Controls and Capabilities Maturity</b>
        <br /><br />
        With the right culture comes capabilities. Most other assessments of AFC
        capabilities just analyse control frameworks and procedures vis-a-vis
        the risk associated with criminal activity. The Themis Risk Assessment
        has a more far-reaching approach that investigates the culture within an
        organisation. If the tone at the top of the business is not in tune with
        fighting financial crime, it will hinder the ability to demonstrate good
        governance &ndash; even if the protocols and controls appear to be in
        place. With capability and controls comes maturity. An organisation that
        fosters or bolsters a savvy AFC culture from within, is in a much
        stronger position to bring its control frameworks and best practice
        &ndash; or anti-financial crime controls - up to speed. When these
        controls work in tandem with a strong AFC capability, firms can
        demonstrate a true commitment to mitigating both the risk and impact of
        all financial crimes.
        <br /><br />
        This report provides Themis AFI risk assessment results for
        {{ companyName }}.
      </section>

      <section>
        <br /><br />
        <h3>Capabilities &ndash; Strategic</h3>
        The capabilities aspect of the Themis Risk Assessment provides a measure
        to see how easily the organisation can implement change to improve
        anti-financial crime activities. This is initially made up of tone at
        the top, culture and tools & systems, which are described as strategic
        capabilities.
        <br /><br />
        This combination provides a useful gauge to understand how much needs to
        change in {{ companyName }} in these areas which will act as a catalyst
        to improving the AFC maturity. Tone at the top, culture and tools and
        systems are integral in effective change management.
        <table class="imagetable">
          <tr>
            <td>
              <img src="../../../../assets/images/capability-strategic.png" />
            </td>
            <td>
              Culture is the DNA of the organisation and invariably Tone at the
              Top and the effectiveness of tools and systems can either drive or
              affect overall culture. For example, whilst not AFC related, a
              simple issue of not having a telephone directory of contact
              details for the organisation says a lot about how enacting AFC
              change could turn out if simple things like this are not in place
              already.
            </td>
          </tr>
        </table>
        The recommendation here is for senior management (including NEDs) to
        become actively involved in AFC Tone and the Top, AFC tools and systems
        and AFC culture company wide. This will improve the overall capabilities
        score which will then enable easier implementation of control
        recommendations. Themis can advise on a number of quick wins here
        through helping from designing poster campaigns to conducting one-to-one
        interviews and financial crime coaching, to specific Board Briefings.
        There is an opportunity for senior management to use the Rating model to
        begin to set the AFC agenda and influence IT and tools that will
        invariably improve the AFC culture.
      </section>

      <section>
        <br /><br />
        <h3>Capabilities &ndash; Operational</h3>
        <table class="imagetable">
          <tr>
            <td>
              The second aspect of capabilities covers Governance, Management
              Information and Reporting; Policies and Frameworks; Training and
              Investigations. These activities if done well will set up the
              operational capabilities and environments to manage financial
              crime threats effectively.
            </td>
            <td>
              <img src="../../../../assets/images/capability-operational.png" />
            </td>
          </tr>
        </table>
      </section>
      <section>
        <br /><br />
        <h3>Financial Crime Threat Controls</h3>
        These measure if controls are in place for financial crime risks such as
        money laundering, terrorist financing, sanctions breaches, fraud,
        bribery and corruption, cybercrime and modern slavery.
        <br /><br />
        All organisations, whether they are financial institutions or not,
        including NGOs, should, generally, have the infrastructure and
        frameworks in place to strengthen and develop their ability to manage
        the financial crime risks in their business and industry.
        <br /><br />
        <img
          src="../../../../assets/images/threat-controls.png"
          class="threat-img"
        />
      </section>

      <section>
        <br /><br />
        <div class="disclaimer capability-section">
          <div class="max-width">
            <h3>Risk Assessment Score</h3>
            The overall AFC rating score for {{ contactName }} is
            {{ rating }} based on the questions sets included in the assessment.
            The detailed controls and capabilities results are displayed below.
            <br /><br />
          </div>
          <div class="badge-img">
            <report-status-badge
              [currentRecipient]="currentRecipient"
            ></report-status-badge>
          </div>
        </div>
      </section>
      <section *ngIf="recipientResponseCapabilities.length > 0">
        <br /><br />
        <div>
          <div class="disclaimer capability-section">
            <h3>Capabilities Scores, Issues, Risks and Recommendations</h3>
            <div class="badge-img">
              <report-status-badge
                [currentRecipient]="currentRecipient"
              ></report-status-badge>
            </div>
          </div>
          <div
            [ngClass]="
              recipientResponseCapabilities.length === 0
                ? 'd-none'
                : 'd-block'
            "
            id="capChart"
          >
            <div *ngIf="recipientResponseCapabilities.length > 0">
              <charts
                [type]="recipientReportService.chartType.RADAR"
                [industryAverages]="industryAverages"
                [currentRecipient]="currentRecipient"
                [recipientResponse]="recipientResponseCapabilities"
                [questionSets]="questionSets"
                [category]="'Capabilities'"
              ></charts>
            </div>
            <div *ngIf="recipientResponseCapabilities.length > 0">
              <charts
                [type]="recipientReportService.chartType.BAR"
                [currentRecipient]="currentRecipient"
                [recipientResponse]="recipientResponseCapabilities"
                [questionSets]="questionSets"
                [category]="'Capabilities'"
              ></charts>
            </div>

            <risks-table
              [currentRecipient]="currentRecipient"
              category="Capabilities"
            ></risks-table>
          </div>
        </div>

      </section>

      <section *ngIf="recipientResponseControls.length > 0">
        <br /><br />
        <div>
          <div class="disclaimer capability-section">
            <h3>Controls Scores, Issues, Risks and Recommendations</h3>
            <div class="badge-img">
              <report-status-badge
                [currentRecipient]="currentRecipient"
              ></report-status-badge>
            </div>
          </div>
          <div
            [ngClass]="
              recipientResponseControls.length === 0 ? 'd-none' : 'd-block'
            "
            id="conChart"
          >
            <div *ngIf="recipientResponseControls.length > 0">
              <charts
                [type]="recipientReportService.chartType.RADAR"
                [industryAverages]="industryAverages"
                [currentRecipient]="currentRecipient"
                [recipientResponse]="recipientResponseControls"
                [questionSets]="questionSets"
                [category]="'Controls'"
              ></charts>
            </div>
            <div *ngIf="recipientResponseControls.length > 0">
              <charts
                [type]="recipientReportService.chartType.BAR"
                [currentRecipient]="currentRecipient"
                [recipientResponse]="recipientResponseControls"
                [questionSets]="questionSets"
                [category]="'Controls'"
              ></charts>
            </div>
            <risks-table
              [currentRecipient]="currentRecipient"
              category="Controls"
            ></risks-table>
          </div>
        </div>

      </section>
      <section>
        <appendix></appendix>
      </section>

      <section>
        <br/><br/><hr/>
        <h2>About Themis</h2>
        <br/>
        <p>
            This report has been created by {{companyName}} powered by Themis&copy;
        </p>
        <p>
            This report has been compiled by {{companyName}} using the Themis Financial Crime Search Engine.
            Themis accepts no liability or responsibility for the contents of this
            report.
        </p>
        <p>The information contained in this document is a combination of data from various sources. These include:</p>
        <div class="d-flex">
            <ul>
              <li>OpenCorporates</li>
              <li>Acuris Intelligence</li>
              <li>Themis Special Interest Index</li>
              <li>Diligencia</li>
            </ul>
            <ul>
              <li>ZignSec</li>
              <li>Companies House</li>
              <li>ICIJ</li>
            </ul>
        </div>
    
        <p>
            Themis helps clients and members identify and manage their specific financial crime
            risks, through a combination of insight, intelligence and innovation.
        </p>
        <p>
            Our cutting edge technology platform helps organisations understand these
            strategic threats through an ESG and socio-economic lens and protects their customers, staff, suppliers and
            shareholders from criminal attacks or association. In this way, we are fuelling sustainable change in
            the public and private sector.
        </p>
        <p>Specialist Areas</p>
        <p>
            Money Laundering | Sanctions | Bribery &amp; Corruption | Fraud | Tax evasion |
            Modern Slavery &amp; Human Trafficking | Market Abuse | Cyber Crime | Proliferation Financing | Illegal
            wildlife trade | Cyber Terrorism | Terrorist Financing
        </p>
        <p>
            For more information on Themis enhanced due diligence reports visit wearethemis.com/investigations.
        </p>
        <p></p>
        <br/><hr>
    
        <div class="footer">
            <table class="footer">
                <tbody>
                    <tr>
                        <td class="left-align">
                            <img alt="" src="../../../assets/images/data-provider-logos.png"
                                 style="width: 90%; margin-bottom:38px;" title="">
                        </td>
                        <td class="right-align" style="padding-bottom: 38px;">
                            <p class="footer"> Themis Due Diligence Report</p>
                            <p class="footer"> INNOVATION | INSIGHT | INTELLIGENCE</p>
                            <a href="https://wearethemis.com/" target="_blank" class="footer">wearethemis.com</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
    </div>
  </form>
</div>
