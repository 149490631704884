import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { PremiumSearchUtilityService } from 'src/app/utilitities/premium-search-utilities';
import { IDiligenciaPersonInfo } from 'src/nswag';
import { ReportUtilities } from '../../report-utils';

@Component({
  selector: 'app-diligencia-person-details',
  templateUrl: './diligencia-person-details.component.html',
  styleUrls: ['./diligencia-person-details.component.scss']
})
export class DiligenciaPersonDetailsComponent implements OnInit {

  
  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public rowColors: Map<string, string> = new Map<string, string>();
  public individual : IDiligenciaPersonInfo;

  constructor(public dashService: DashboardService, public premiumUtils: PremiumSearchUtilityService, private reportUtils: ReportUtilities) {}

  ngOnInit() {
    this.individual = this.dashService.getInvestigation().asDiligenciaPersonInfo
    this.reinstateRowColors();
    setTimeout(() => {
      this.resizeAllTextAreas();
    }, 3000);
  }

  public resizeAllTextAreas(): void {
    this.textareas.toArray().forEach((item) => { 
      item.nativeElement.style.height = 'auto';
      item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
    }); 
  }

  public setRowColor(controlName: string): void {
    var control = this.dashService.getInvestigation()?.reportActorFreeText?.controls[controlName];
    this.rowColors.set(controlName, this.reportUtils.getRowColor(control?.value));
  }

  private reinstateRowColors(): void {
    Object.keys(this.dashService.getInvestigation()?.reportActorFreeText?.controls).forEach(key => {
      this.setRowColor(key);
    });
  }
}
