import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ICompanyInfo } from 'src/nswag';
import { ReportUtilities } from '../../report-utils';

@Component({
  selector: 'app-report-companydetails',
  templateUrl: 'companydetails.component.html',
  styleUrls: ['companydetails.component.scss']
})

export class CompanyDetailsReportComponent implements OnInit {
  
  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public rowColors: Map<string, string> = new Map<string, string>();
  public company : ICompanyInfo;

  constructor(public dashService: DashboardService, public reportUtils: ReportUtilities) {}

  ngOnInit() {
    this.company = this.dashService.getInvestigation().asCompanyInfo;
    this.reinstateRowColors();
    setTimeout(() => {
      this.resizeAllTextAreas();
    }, 3000);
  }

  public resizeAllTextAreas(): void {
    this.textareas.toArray().forEach((item) => { 
      item.nativeElement.style.height = 'auto';
      item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
    }); 
  }

  public setRowColor(controlName: string): void {
    var control = this.dashService.getInvestigation()?.reportActorFreeText?.controls[controlName];
    this.rowColors.set(controlName, this.reportUtils.getRowColor(control?.value));
  }

  private reinstateRowColors(): void {
    Object.keys(this.dashService.getInvestigation()?.reportActorFreeText?.controls).forEach(key => {
      this.setRowColor(key);
    });
  }
}
