import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mon-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  @Input() search: string;
  @Output() searchData = new EventEmitter<string>();
  @Output() performSearch = new EventEmitter<boolean>();

  constructor() { 
  }

  public runSearch(){
    this.performSearch.emit(true);
  }

  public searchChange() {
    this.searchData.emit(this.search);
  }
}
