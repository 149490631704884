import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { PrimeNgModule } from 'src/app/modules/prime-ng/prime-ng.module';

@Component({
  selector: 'app-signature-pad',
  standalone: true,
  imports: [CommonModule, FormsModule, PrimeNgModule],
  templateUrl: './signature-pad.component.html',
  styleUrls: [
    './signature-pad.component.scss',
    '../regulatory-reports-base.component.scss'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})

export class SignaturePadComponent implements AfterViewInit {

  @ViewChild('signatureCanvas') signatureCanvas!: ElementRef<HTMLCanvasElement>;
  @Input() inputName: string;
  @Output() onSignature = new EventEmitter<string>();
  @Output() onClear = new EventEmitter();

  public textInputCharacterLimit: number = 50;
  public signaturePad!: SignaturePad;
  private textChangeRendered: boolean = false;

  ngAfterViewInit() {
    const canvas = this.signatureCanvas.nativeElement;
    this.signaturePad = new SignaturePad(canvas, { maxWidth: 1.8  });
    
    this.signaturePad.addEventListener("endStroke", () => {
      if (!this.signaturePad.isEmpty()) {
        this.emitSignatureUrl();
      }
    }, { once: false });

    this.setCanvasDefaults(canvas);

    // init required to load the font
    this.renderText(' ', false);
  }

  private setCanvasDefaults(canvas: HTMLCanvasElement): void {
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
  }

  private emitSignatureUrl(): void {
    const imageUrl = this.signaturePad.toDataURL();
    this.onSignature.emit(imageUrl);
  }

  public clearSignature(): void {
    this.signaturePad.clear();
    this.textChangeRendered = false;
    this.onClear.emit(true);
  }
  
  public renderText(input: string, setChangedOnRender: boolean = true): void {
    if (input && input.length <= this.textInputCharacterLimit) {
      const canvas = this.signatureCanvas.nativeElement;
      const context = canvas.getContext('2d');
      let fontSize = 70; // start with a large font size
      context.font = `${fontSize}px 'Oooh Baby'`; // set the initial font and size

      // reduce the font size until the text width is smaller than the canvas width
      while (context.measureText(input).width > canvas.width) {
        fontSize--;
        context.font = `${fontSize}px 'Oooh Baby'`;
      }

      // clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height);

      // calculate the position of the text to be centered
      const textX = canvas.width / 2;
      const textY = canvas.height / 2 + fontSize / 3; // slight adjustment for 'Oooh Baby' font

      // set the text alignment to center
      context.textAlign = 'center';

      // render the text
      context.fillText(input, textX, textY);
    
      if (setChangedOnRender) {
        this.textChangeRendered = true;
        this.emitSignatureUrl();
      }
    }
  }
  
  public get isCanvasEmpty(): boolean {
    return (this.signaturePad?.isEmpty() ?? true) && !this.textChangeRendered;
  }
}
