import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreeningComponent } from './screening.component';
import { LoaderModule } from '../loader/loader.module';
import { MonitorModule } from '../monitor/monitor.module';
import { ScreeningClient } from 'src/nswag';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatchesModule } from '../matching-service/matches.module';
import { ScreenClientButtonComponent } from './screen-client-button/screen-client-button.component';
import { ScreenClientDialogComponent } from './screen-client-dialog/screen-client-dialog.component';
import { AdvancedSearchOptionsModule } from '../advanced-search-options/advanced-search-options.module';
import { ProfileDetailsModule } from '../profile-details/profile-details.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagsModule } from '../tags/tags.module';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { AuditModule } from '../audit/audit.module';
import { ProblemDetailsModule } from '../problem-details/problem-details/problem-details.module';

@NgModule({
    declarations: [
        ScreeningComponent,
        ScreenClientButtonComponent,
        ScreenClientDialogComponent
    ],
    providers: [
        ScreeningClient
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        MonitorModule,
        LoaderModule,
        MatchesModule,
        ProfileDetailsModule,
        NgSelectModule,
        AdvancedSearchOptionsModule,
        TagsModule,
        DatePickerModule,
        AuditModule,
        ProblemDetailsModule
    ],
    exports: [ ScreeningComponent ]
})
export class ScreeningModule { }
