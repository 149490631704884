import { DiligenciaRelationshipType, EntityTypes } from '../../../nswag'
import * as cytoscape from 'cytoscape';
import tippy from 'tippy.js';
import { IActorBase, ActorEdge, DummyCompanyActor } from '../models/Actor';
import { DashboardService } from '../../services/dashboard.service';
import { PremiumSearchUtilityService } from 'src/app/utilitities/premium-search-utilities';

export class TippyController {

  protected isClicked: boolean = false;
  protected tippyControl: any;

  // Node related properties
  public sourceActor: IActorBase;
  public sourceNode: cytoscape.NodeSingular;

  // Node being dragged in the case of a merge
  public targetNode: cytoscape.NodeSingular;
  public targetActor: IActorBase;

  // Reason text used in adding / mrging / discounting
  public reason: string;

  public cy: cytoscape.Core;
  public canRemoveNode = false;
  public expandRemove = false;
  public canCollapseNode = false;
  public collapsedActorList = new Array<string>();

  public expandGroupCreate = false;
  public groupName = "";
  public groupEditFlag = false;
  public clickedPremiumNode: boolean = false;
  public clickedPremiumEdge: boolean = false;

  // Edge properties
  public actorEdge: ActorEdge;
  public cytoEdge: cytoscape.EdgeSingular;
  public edgeLabelFlag = false;
  public edgeLabel: string;

  // Canvas related property
  public position: cytoscape.Position;

  constructor(private dashService: DashboardService, private premiumUtils: PremiumSearchUtilityService) { }

  public hideTippy(force: boolean) {
    if (this.tippyControl) {
      this.tippyControl.hide();
    }
    if (force) {
      this.isClicked = false;
    }
  }

  public createTippyControl(node: any, htmlElement: string, xoffset?: number, yoffset?: number) {

    this.hideIfVisible();

    let dummyEl = document.createElement('div');
    var options: any = {

      trigger: 'manual',
      arrow: true,
      interactive: true,
      hideOnClick: true,
      animation: false,
      theme: 'light',
      placement: 'auto',
      getReferenceClientRect: node.popperRef().getBoundingClientRect,
      appendTo: (this.dashService.isFullscreen ? document.getElementById('graph-fullscreen') : document.body),
      zIndex: 2000,
      content: () => {
        // load the display from the template
        return document.getElementById(htmlElement);
      },
      onHide: (intstance) => {
        // Move the display element before it's deleted to use again next time.
        let div = document.getElementById(htmlElement);
        if (div) {
          let wrapperDiv = document.getElementById("tippyWrapper");
          wrapperDiv.appendChild(div);
        }
      }
      
      // offset: null //[xoffset, yoffset]
    }
    // Only inlcude if set as this disturbs the offset
    if (xoffset && yoffset) {
      options.offset = [xoffset, yoffset];
      options.arrow = false;
    }
    this.tippyControl = tippy(dummyEl, options);
    // Show the control
    this.tippyControl.show();
  }

  isVisible(): boolean {
    return this.tippyControl?.state?.isVisible;
  }

  hideIfVisible() {
    if (this.isVisible) {
      this.hideTippy(false);
    }
  }

  handleNodeMouseOver(event: cytoscape.EventObject) {
    if (this.isClicked) { return; }

    this.resetController();

    this.cy = event.cy;
    this.sourceNode = event.target;
    this.sourceActor = this.sourceNode.data('actor');
    if (this.sourceActor == null) {
      // We must be in a container node
      return;
    }
    this.createTippyControl(event.target, this.sourceActor.hoverElementName);
  }

  public handleMouseOut(event: cytoscape.EventObject) {
    if (!this.isClicked) {
      this.hideTippy(false);
    }
  }

  isPremiumRelationshipCategory(relationship: string) : boolean {
    return Object.values(DiligenciaRelationshipType).includes(relationship as DiligenciaRelationshipType);
  }

  resetController() {
    this.actorEdge=null;
    this.sourceActor=null;
    this.sourceNode=null;
    this.targetActor=null;
    this.targetNode=null;
    this.isClicked = false;
    this.canRemoveNode = false;
    this.expandRemove = false;
    this.canCollapseNode = false;
    this.collapsedActorList = new Array<string>();
    this.reason = "";
    this.expandGroupCreate = false;
    this.groupName = "";
    this.clickedPremiumNode = false;
    this.clickedPremiumEdge = false;
  }

  handleNodeClick(event: cytoscape.EventObject) {
    this.resetController();

    this.isClicked = true;
    this.cy = event.cy;
    this.sourceNode = event.target;
    this.sourceActor = this.sourceNode.data('actor');
    this.groupName = this.sourceActor.name ?? "";
    this.groupEditFlag = false;
    this.clickedPremiumNode = this.premiumUtils.isPremiumNode(this.sourceActor);

    if (this.cy.nodes().length > 1) {
      this.canRemoveNode = this.checkCanRemoveActor(this.sourceActor);
      this.checkCanCollapseNode();
    }
    this.createTippyControl(event.target, this.sourceActor.tapElementName);
  }


  handleNodeTapEnd(event: cytoscape.EventObject) {
    if (!event.target.isNode()) {
      // We are moving something that isnt a node!
      return;
    }
    if (event.target.parents()?.length > 0) {
      // The target is in a group so cant be merged
      return;
    }
    let sourceActor : IActorBase = event.target.data("actor");
    if (sourceActor?.actorType == EntityTypes.Group) {
      // We are moving a group so cant merge either
      return;
    }

    let pos = event.target.position();

    let self = this;
    event.cy.nodes().forEach(function (node) {
      if (node != event.target) {
        let npos = node.position();
        let width = node.width();
        let height = node.height();

        if (pos.x - width < npos.x && pos.x + width > npos.x && (pos.y - height) < npos.y && (pos.y + height) > npos.y) {
          // We are overlapping
          let actor = node.data('actor');
          if (actor) {
            if (actor.actorType == EntityTypes.Group) {
              event.target.move({parent: node.id()})
              self.dashService.addAuditTrail(sourceActor?.name + " added to group " + actor.name);
            }
            else {
              self.startNodeMerge(event, node);
            }
          }
          return;
        }
      }
    });
  }

  handleEdgeMouseOver(event: cytoscape.EventObject) {
    if (this.isClicked) { return; }
    this.resetController();
    this.actorEdge = event.target.data('actorEdge');
    this.createTippyControl(event.target, "edgeHoverElement");
  }

  handleCanvasClick(event: cytoscape.EventObject) {
    if (this.isClicked) { 
      this.resetController();
      return;
    }
    this.resetController();
    this.position = event.renderedPosition;
    this.createTippyControl(event.target, "tapCanvasElement", event.renderedPosition.y, event.renderedPosition.x-60);
  }

  handleEdgeClick(event: cytoscape.EventObject) {
    this.resetController();

    this.isClicked = true;
    this.cytoEdge = event.target;
    this.actorEdge = event.target.data('actorEdge');
    this.clickedPremiumEdge = this.premiumUtils.isPremiumEdge(this.actorEdge);

    if(this.clickedPremiumEdge) {
      return;
    }

    this.canRemoveNode = true;
    this.edgeLabel = this.actorEdge.description ?? "";
    this.edgeLabelFlag = false;
    this.createTippyControl(event.target, "tapEdgeElement");
  }

  get backgroundColour() : string  {
    if (this.sourceActor) {
      return this?.sourceActor?.backColour;
    }
  };
  set backgroundColour(colour: string) {
    if (!colour) return;
    if (this.sourceActor) {
      this.sourceActor.backColour = colour;
      this.dashService.setActorStyle(this.sourceActor);
    }
  }

  get borderColour() : string  {
    if (this.actorEdge) {
      return this?.actorEdge?.colour;
    }
    else if (this.sourceActor) {
      return this?.sourceActor?.colour;
    }
  };
  set borderColour(colour: string) {
    if (!colour) return;
    if (this.actorEdge && this.cytoEdge) {
      this.actorEdge.colour = colour;
      this.dashService.setEdgeStyle(this.cytoEdge);
    }
    else if (this.sourceActor) {
      this.sourceActor.colour = colour;
      this.dashService.setActorStyle(this.sourceActor);
    }
  }

  startNodeMerge(event: cytoscape.EventObject, targetNode: cytoscape.NodeSingular): boolean {
    this.targetNode = targetNode;
    this.targetActor = targetNode.data('actor');
    this.sourceActor = event.target.data('actor');
    this.sourceNode = event.target;
    this.reason = "";

    if (this.sourceActor.id == this.dashService.getInvestigation()?.id) {
      this.createTippyControl(event.target, "noInvestigationMergeElement");
      return;
    }
    if (this.sourceActor.hasLAMPS) {
      // Can't merge over a LAMPS node
      this.createTippyControl(event.target, "noLAMPSMergeElement");
      return;
    }
    if (this.sourceActor.actorType == EntityTypes.Operation || this.targetActor.actorType == EntityTypes.Operation) {
      // Can't merge operations
      this.createTippyControl(event.target, "noOperationMergeElement");
      return;
    }
    if (this.sourceActor.actorType == EntityTypes.Individual) {
      // Can't merge operations
      this.createTippyControl(event.target, "noIndividualMergeElement");
      return;
    }
    // check if we are dealing with companies
    if (this.checkMergeCompanies(event)) {
      return;
    }
    if (this.premiumUtils.isPremiumNode(this.sourceActor) || this.premiumUtils.isPremiumNode(this.targetActor)) {
      // Can't merge premium nodes
      this.createTippyControl(event.target, "noPremiumMergeElement");
      return;
    }

    this.isClicked = true;

    this.createTippyControl(event.target, "mergeElement");
  }

  private checkMergeCompanies(event: cytoscape.EventObject): boolean {
    if (this.sourceActor.actorType == EntityTypes.Company) {
      if (this.targetActor.actorType == EntityTypes.Company) {
        if (this.sourceActor instanceof DummyCompanyActor || this.targetActor instanceof DummyCompanyActor) {
          // We are good to merge dummy companies but only to other companies (dummy or real)
          this.createTippyControl(event.target, "mergeCompanies");
          return true;
        }
        else {
          this.createTippyControl(event.target, "noCompanyMergeElement");
          return true;          
        }
      }
      else {
        this.createTippyControl(event.target, "noCompanyMergeElement");
        return true;
      }
    }
    else if (this.targetActor.actorType == EntityTypes.Company) {
      this.createTippyControl(event.target, "noCompanyMergeElement");
      return true;
    }    
    return false;
  }

  checkCanRemoveActor(actor: IActorBase): boolean {
    if (actor.useFocusStyle) {
      return false;
    }
    var nodeCount: number = 0;
    this.cy.edges().forEach((edge: cytoscape.EdgeSingular, i: number) => {
      if (edge.data("source") == actor.id || edge.data("target") == actor.id) {
        nodeCount++;
        if (nodeCount == 2) {
          return;
        }
      }
    });
    return (nodeCount < 2);
  }

  checkCanCollapseNode() {
    if (this.canRemoveNode) {
      this.canCollapseNode = false;
    }
    else {
      this.canCollapseNode = false;
      this.cy.edges().forEach((edge: cytoscape.EdgeSingular, i: number) => {
        if (edge.data("source") == this.sourceActor.id) {
          var target = edge.data("actorEdge").targetActor;
          if (this.checkCanRemoveActor(target)) {
            this.collapsedActorList.push(target.id);
          }
        }
        else if (edge.data("target") == this.sourceActor.id) {
          var source = edge.data("actorEdge").sourceActor;
          if (this.checkCanRemoveActor(source)) {
            this.collapsedActorList.push(source.id);
          }
        }
      });
      this.canCollapseNode = this.collapsedActorList.length > 0;
    }
  }
}
