<div id="monitor-association">
    <h5 *ngIf="incHeader">Associated Individuals</h5>
    <div *ngIf="individualLinks?.length > 0; else emptyAssociation">
        <div class="col alert alert-info">
            This is a list of individuals associated with {{name}} that may pose a financial crime risk.
        </div>
        <div *ngFor="let ind of individualLinks">
            <div *ngIf="ind.discountReason == null || !ind.discountReason.isDiscounted" class="row pt-2">
                <div class="col">
                    <strong>{{getName(ind)}}</strong><br>
                    <span>{{ind.relationship}}</span>
                    <span *ngIf="ind.ownershipPercentage"><br>Owns {{ind.ownershipPercentage}}%</span>
                </div>
                <div *ngIf="canAddToGraph" class="col-sm-1">
                    <a *ngIf="checkCanAddToGraph(ind); else alreadyAdded" role="button" (click)="addToGraph(ind)"  title="Click to add to the graph">
                        <span class="icon-plus-circle"></span>
                    </a>
                    <ng-template #alreadyAdded>
                        <span class="icon-plus-circle icon-disabled"></span>
                    </ng-template>
                </div>
                <div *ngIf="allowDiscounting" class="col-sm-1">
                    <app-discount-reason [enabled]="checkCanDiscount(ind)" [individual]="ind" [profile]="profile" [clientId]="clientId"></app-discount-reason>
                </div>
            </div>
        </div>
    </div>

    <ng-template #emptyAssociation>
        <div class="col alert alert-info">
            <span>No associations have been found.</span>
        </div>
    </ng-template>
</div>
