import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestigationComponent } from './investigation.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerModule } from '../date-picker/date-picker.module';



@NgModule({
  imports: [CommonModule, FormsModule, NgbModule, DatePickerModule],
  declarations: [InvestigationComponent],
  exports: [InvestigationComponent]
})
export class InvestigationModule { }
