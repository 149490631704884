  <div>
    <div class="modal-header">
      <h4 class="modal-title col">Request an Intelligence Report</h4>
      <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismiss()">
        <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="input-group">
              <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="individualRadio" name="requestType" value="0" [checked]="!isBusiness" (change)="setClientType()">
                  <label class="form-check-label" for="individualRadio">Individual</label>
              </div>  
              <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="companyRadio" name="requestType" value="1" [checked]="isBusiness" (change)="setClientType()">
                  <label class="form-check-label" for="companyRadio">Company</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isBusiness; else personSection">
        <ng-container [ngTemplateOutlet]="companyBlock"></ng-container>
      </div>
      <ng-template #personSection>
        <ng-container [ngTemplateOutlet]="personBlock"></ng-container>
      </ng-template>
  
      <div class="modal-footer">
        <button class="btn btn-primary investigate-button" [disabled]="hasErrors() || loading" (click)="submitRequest()"> 
          <span *ngIf="loading" class="spinner-border"></span>
          <span *ngIf="!loading">Send Intelligence Report Request</span>
        </button>
        <hr>
      </div>
    </div>
  </div>
  
    <ng-template #companyBlock>
      <div class="container">
        <div class="row mt-1">
          <div class="col">
            <div class="d-flex">
              <div class="required-input">
                <label>Company Name</label>
              </div>
              <small class="required-label">&nbsp;&nbsp;(Required)</small>
            </div>
            <input type="text" [disabled]="disableAll" name="companyName" class="form-control" required [(ngModel)]="companyName" #name="ngModel" [minlength]="nameMininumLength">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
              <div *ngIf="name.errors?.required">
                Company Name is required.
              </div>
              <div *ngIf="name.errors?.minlength">
                Company Name must be at least 3 characters long.
              </div>
            </div>
          </div>
          <div class="col">
            <label>Company No.</label>
            <input type="text" [disabled]="disableAll" name="companyNumber" class="form-control" [(ngModel)]="companyNumber">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Jurisdiction</label>
            <div class="input-group client-nationality">
              <input id="jurisdiction" 
              [(ngModel)]="jurisdiction"
              [ngbTypeahead]="countryTypeAhead" 
              class="form-control mb-2"
              (focus)="onFocus($event)"
              (blur)="onBlur($event)"
              name="checkJurisdiction"
              #checkJurisdiction="ngModel"/>
            </div>
            <!-- <div *ngIf="(jurisdiction && !isJurisdictionValid()) && (checkJurisdiction.dirty || checkJurisdiction.touched) && !jurisdictionFocus" class="row">
              <div class="form-control alert alert-danger">
                Valid Country is required.
              </div>
            </div> -->
          </div>
        </div>
        <ng-container *ngTemplateOutlet="addressTemplate"></ng-container>
        <div class="row">
          <div class="col">
            <div class="mt-1 d-flex">
              <div class="required-input">
                <label for="information">Supporting Information</label>
              </div>
              <small class="required-label">&nbsp;&nbsp;(Required)</small>
            </div>
            <textarea [disabled]="disableAll" type="text" rows="10" class="form-control" name="information" [(ngModel)]="information" required #checkInfo="ngModel" [maxlength]="infoMaxLength"></textarea>
            <p class="my-1"><small>You have used {{information?.trim()?.length ?? 0}} out of {{infoMaxLength}} characters.</small></p>
            <div *ngIf="checkInfo.invalid && (checkInfo.dirty || checkInfo.touched)" class="alert alert-danger">
              <div *ngIf="checkInfo.errors?.required">
                To help with us with your request, supporting information is required.
              </div>
              <div *ngIf="checkInfo.errors?.maxLength">
                Supporting information too long.
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  
    <ng-template #personBlock>
      <div class="container">
        <div class="row mt-1">
          <div class="col">
            <div class="d-flex">
              <div class="required-input">
                <label>Full Name</label>
              </div>
              <small class="required-label">&nbsp;&nbsp;(Required)</small>
            </div>
            <input type="text" [disabled]="disableAll" name="fullName" class="form-control" required [(ngModel)]="fullName" #name="ngModel" [minlength]="nameMininumLength">
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
              <div *ngIf="name.errors?.required">
                Full Name is required.
              </div>
              <div *ngIf="name.errors?.minlength">
                Full Name must be at least 3 characters long.
              </div>
            </div>
          </div>
          <div class="col">
            <label>Date of Birth</label>
            <div>
                <app-date-picker (dataEvent)='dateChanged($event)' [dateOfBirth]="dateOfBirth"></app-date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label>Nationality</label>
              <div class="input-group client-nationality">
                <input 
                id="nationality"
                [(ngModel)]="nationality" 
                [ngbTypeahead]="nationalityTypeAhead" 
                class="form-control mb-2" 
                (focus)="onFocus($event)"
                (blur)="onBlur($event)"
                name="checkNationality"
                #checkNationality="ngModel"/>
              </div>
              <!-- <div *ngIf="(nationality && !isNationalityValid()) && (checkNationality.dirty || checkNationality.touched) && !nationalityFocus" class="row">
                <div class="form-control alert alert-danger">
                  Valid Nationality is required.
                </div>
              </div> -->
          </div>
          <div class="col">
            <label>Gender</label>
            <select [disabled]="disableAll" class="form-select" name="gender" [(ngModel)]="gender">
              <option value=""></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Unknown">Unknown</option>
            </select>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="addressTemplate"></ng-container>
        <div class="row">
          <div class="col">
            <div class="mt-1 d-flex">
              <div class="required-input">
                <label for="information">Supporting Information</label>
              </div>
              <small class="required-label">&nbsp;&nbsp;(Required)</small>
            </div>
            <textarea [disabled]="disableAll" type="text" rows="10" class="form-control" name="information" [(ngModel)]="information" required #checkInfo="ngModel" [maxlength]="infoMaxLength"></textarea>
            <p class="my-1"><small>You have used {{information?.trim()?.length ?? 0}} out of {{infoMaxLength}} characters.</small></p>
            <div *ngIf="checkInfo.invalid && (checkInfo.dirty || checkInfo.touched)" class="alert alert-danger">
              <div *ngIf="checkInfo.errors?.required">
                To help with us with your request, supporting information is required.
              </div>
              <div *ngIf="checkInfo.errors?.maxLength">
                Supporting information too long.
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  
    <ng-template #addressTemplate>
      <div class="row">
        <div class="col">
          <div class="mt-2">
            <label>Address line 1</label>
          </div>
          <div class="input-group">
            <input type="text" class="form-control" id="uniqueAddress" [(ngModel)]="address.line1" #name="ngModel">
          </div>
        </div>
        <div class="col">
          <div class="mt-2">
            <label>Address line 2</label>
          </div>
          <div class="input-group">
            <input type="text" class="form-control" id="uniqueAddressTwo" [(ngModel)]="address.line2">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mt-2">
            <label>City/Town</label>
          </div>
          <div class="input-group">
            <input type="text" class="form-control" id="uniqueCity" [(ngModel)]="address.city">
          </div>
        </div>
        <div class="col">
          <div class="mt-2">
            <label>County/State</label>
          </div>
          <div class="input-group">
            <input type="text" class="form-control" id="uniqueCounty" [(ngModel)]="address.county">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="mt-2">
            <label>Zip/Postcode</label>
          </div>
          <div class="input-group">
            <input type="text" class="form-control" id="uniqueZip" [(ngModel)]="address.postcode">
          </div>
        </div>
        <div class="col">
          <div class="mt-2">
            <label>Country</label>
          </div>
          <div class="input-group client-nationality">
            <input id="addressCountry" 
            [(ngModel)]="addressCountry"
            [ngbTypeahead]="countryTypeAhead" 
            class="form-control mb-2"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"
            name="checkAddressCountry"
            #checkAddressCountry="ngModel"/>
          </div>
          <!-- <div *ngIf="(addressCountry && !isAddressCountryValid()) && (checkAddressCountry.dirty || checkAddressCountry.touched) && !addressCountryFocus" class="row">
            <div class="form-control alert alert-danger">
              Valid Country is required.
            </div>
          </div> -->
        </div>
      </div>
    </ng-template>
