import { Component, Input, OnInit, QueryList, SecurityContext, ViewChildren } from '@angular/core';
import { BusinessLink, Evidence, IndividualLink, DiscountProperties, MonitoringClient, DiscountProfileItemCommand, DiscountType } from 'src/nswag';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DiscountReasonService } from './discount-reason.service';
import { AlertService } from 'src/app/_alert';
import { DashboardService } from 'src/app/services/dashboard.service';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'app-discount-reason',
  templateUrl: './discount-reason.component.html',
  styleUrls: ['./discount-reason.component.scss']
})
export class DiscountReasonComponent implements OnInit {
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;

  @Input() clientId: string;
  @Input() profile: any;
  @Input() evidence: Evidence;
  @Input() business: BusinessLink;
  @Input() individual: IndividualLink;
  @Input() enabled = true;

  public discountReason: string;
  private alertServiceOptions = {
    autoClose: true
  };
  
  constructor(private sanitizer: DomSanitizer, private discountService: DiscountReasonService, private monitorClient: MonitoringClient, private alertService: AlertService, private dashService: DashboardService) { }

  ngOnInit(): void {

  }

  public close() {
    this.popovers?.forEach((popover: NgbPopover) => {
      popover.close();
    });
  }

  public closeExistingPopovers() {
    this.discountService.open(this);
  }

  remove(p: NgbPopover) {
    if (this.evidence) {
      this.evidence.title
      this.addDiscount(this.evidence.evidenceId, DiscountType.Article, this.getUrl());
    }
    else if (this.business) {
      this.addDiscount(this.business.resourceId, DiscountType.AssociatedBusiness, this.business.name);
    }
    else if (this.individual) {
      this.addDiscount(this.individual.resourceId, DiscountType.AssociatedIndividual, UtilityService.getIndividualName(this.individual));
    }
    p.close();
  }

  private addDiscount(id: string, type: DiscountType, displayName: string) {

    var command: DiscountProfileItemCommand = new DiscountProfileItemCommand();
    command.discountItemType = type;
    command.profileId = this.profile.resourceId;
    command.clientId = this.clientId;
    command.discountProperties = new DiscountProperties({
      reason:  this.sanitizer.sanitize(SecurityContext.HTML, this.discountReason),
      isDiscounted: true,
      discountId: id,
      displayName: displayName
    });

    this.monitorClient.discountProfileItem(command).subscribe(result => {
      if(result.isSuccess) {
        switch (type) {
          case DiscountType.Article:
            this.evidence.discountReason = result.data;
            break;
          case DiscountType.AssociatedBusiness:
            this.business.discountReason = result.data;
            break;
          case DiscountType.AssociatedIndividual:
            this.individual.discountReason = result.data;
            break;
        }
        this.alertService.success('Item successfully discounted', this.alertServiceOptions);
      }
    });
  }

  private getUrl(): string {
    if (this.evidence?.originalUrl == 'C6 Internal') {
      return this.evidence?.assetUrl;
    }
    return (this.evidence?.originalUrl ?? this.evidence?.assetUrl);
  }

}
