import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private _htmlBodyContent: string;

constructor() { }

get htmlBodyContent() {
  return this._htmlBodyContent;
}

printHtml(content: string) : void {
  this._htmlBodyContent = content;
  this.print();
}

private print() : void {
  setTimeout(function() {
    window.print();
  }, 500);
}

}
