import { Injectable } from '@angular/core';
import { UserManagementClient } from 'src/nswag';
import { AssignUserRoleCommand, CreateAccountCommand, GetAccountByIdModel, RemoveUserRoleCommand, ReSendConfirmationEmailCommand, ResetPasswordRequestCommand, RoleManagementClient, UpdateAccountCommand } from 'src/nswag';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private client: UserManagementClient, private roleManagement: RoleManagementClient) { }

  getUsers(filterText: string) {
    return this.client.list(filterText);
  }
  getUser(id: string) {
    return this.client.get(id);
  }
  createUser(user: CreateAccountCommand) {
    return this.client.create(user);
  }
  deleteUser(id: string) {
    return this.client.delete(id);
  }
  updateUser(user: GetAccountByIdModel) {
    const cmd = new UpdateAccountCommand();
    cmd.email = user.email;
    cmd.firstName = user.firstName;
    cmd.lastName = user.lastName;
    cmd.id = user.userId;
    return this.client.update(cmd);
  }
  resetPassword(email: string) {
    var command = new  ResetPasswordRequestCommand();
    command.email = email;
    return this.client.resetPassword(command);
  }

  assignUserRole(userId: string, roleId: string) {
    var cmd = new AssignUserRoleCommand();
    cmd.userId = userId;
    cmd.roleId = roleId;
    return this.roleManagement.assignUserRole(cmd);
  }

  removeUserRole(userId: string, roleId: string) {
    var cmd = new RemoveUserRoleCommand();
    cmd.userId = userId;
    cmd.roleId = roleId;
    return this.roleManagement.removeUserRole(cmd);
  }

  resendEmailConfirmation(email: string) {
    var cmd = new ReSendConfirmationEmailCommand();
    cmd.email = email;
    return this.client.reSendConfirmationEmail(cmd);
  }
}
