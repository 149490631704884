import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { AssessmentRecipient } from 'src/nswag';
import { RiskAssessmentService } from '../../services/risk-assessment.service';

@Component({
  selector: 'send-assessment-recipient-dialog',
  templateUrl: './send-assessment-recipient-dialog.component.html',
  styleUrls: ['./send-assessment-recipient-dialog.component.scss']
})
export class SendAssessmentRecipientDialogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal, public riskAssessmentService: RiskAssessmentService, public alertService: AlertService) { }

  public assessmentRecipient = this.riskAssessmentService.GetCurrentRecipient();
  public defaultWelcomeMessage;
  public defaultEmailMessage;

  ngOnInit(): void {
    this.resetModelData();
    this.assessmentRecipient.emailMessage = this.assessmentRecipient.emailMessage ?? this.defaultEmailMessage;
    this.assessmentRecipient.welcomeMessage = this.assessmentRecipient.welcomeMessage ?? this.defaultWelcomeMessage;
  }

  private resetModelData() {
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  canSend(): boolean {
    var canSend = true;
    //if (!this.assessmentRecipient?.welcomeMessage || !this.assessmentRecipient?.emailMessage) canSend = false;
    return canSend;
  }

  sendAssessmentRecipient() {
    this.riskAssessmentService.sendAssessmentRecipient(this.assessmentRecipient).subscribe(() => {
      this.alertService.success('The assessment is being sent', { autoClose: true });
    },
    (errors) => {
      const errorMessages = JSON.parse(errors.response)?.errors;
      let errorMessage = 'An error occured. Please try again.'

      if (errorMessages && errorMessages.length) {
        errorMessages.forEach(error => {
          switch (error.errorCode) {
            case 'NoQuestionSetsSelected':
              errorMessage = 'To send an Assessment at least one Question Set must be selected.';
              break;
          }
          this.alertService.error(errorMessage, { autoClose: true });
        });
      }
    });
    this.activeModal.close();
  }
}
