<div class="table-responsive">
    <table class="table table-bordered">
        <thead class="table-dark text-light py-2">
          <tr>
            <th>Event</th>
            <th>Details</th>
            <th>User</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let entry of auditLogs">
            <td>
              {{entry.event}}
            </td>
            <td>
              {{entry.value}}
            </td>
            <td>
                {{entry.authorName}}
            </td>
            <td>
              {{getDate(entry.timestamp)}}
            </td>
          </tr>
        </tbody>
    </table>
</div>