<section>
  <br />
  <h2>Personal Details</h2>
  <h4 class="bg-dark text-light">{{officer?.name}}</h4>
  <form [formGroup]="dashService.getInvestigation().reportActorFreeText">
    <div class="row">
      <div class="col">
        <div class="wrapper">
          <textarea #textarea id="details" type="textarea" class="discardable-text"
            placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
            formControlName="detailsHeading"></textarea> <span class="edit-icon"> click to edit</span>
          <p id="detailsText"></p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="row">
        <div class="lamps-image-col">
          <div *ngIf="dashService.getInvestigation()?.profileImageId; else displayLAMPSAvatar">
            <img [src]="'/media/'+dashService.getInvestigation()?.profileImageId+'' | secure | async"
              class="avatar-image">
          </div>
          <ng-template #displayLAMPSAvatar>
            <img src="/assets/images/EDD-Avatar-individual.png" class="avatar-image" />
          </ng-template>
        </div>
        <div class="col" *ngIf="officer?.dateOfBirth || officer?.nationality">
          <p *ngIf="officer?.dateOfBirth" class="c24 c41">Date Of Birth: {{officer?.dateOfBirth}}</p>
          <p *ngIf="officer?.nationality" class="c24 c41">Nationality: {{officer?.nationality}}</p>
          <p *ngIf="officer?.startDate" class="c24 c41">Start Date: {{officer?.startDate | date}}</p>
          <p *ngIf="officer?.endDate" class="c24 c41">End Date: {{officer?.endDate | date}}</p>
        </div>
        <div class="col address" *ngIf="officer?.address?.fullAddress">
          <p *ngFor="let addressLine of reportUtils.splitFullAddress(officer?.address?.fullAddress)">{{addressLine}}</p>
        </div>
        <div class="col address" *ngIf="!officer?.address?.fullAddress">
          <p>{{officer?.address?.addressLine1}}</p>
          <p>{{officer?.address?.addressLine2}}</p>
          <p>{{officer?.address?.countyOrState}}</p>
          <p>{{officer?.address?.locality}}</p>
          <p>{{officer?.address?.postOrZipCode}}</p>
          <p>{{officer?.address?.country}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div *ngIf="validOfficers.length > 0">
            <p>Linked Companies: </p>
            <ul>
              <li *ngFor="let mergedOfficer of validOfficers">{{mergedOfficer.companyName}}
                ({{mergedOfficer.companyNumber}}). {{mergedOfficer.position}} {{mergedOfficer.isUBO ? "(UBO)" : ""}}
                {{mergedOfficer.isActive ? "(Active)" : "(Inactive)"}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Business Relationship</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="businessRelationship" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="businessRelationship"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="businessRelationshipText"></p>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Risk Level</p>
        </div>
        <div class="col">
          <p class="text-light">Comments</p>
        </div>
      </div>
      <div class="row" [style.background-color]="rowColors.get('ragStatus')">
        <div class="col">
          <select class="form-select form-control" id="uniqueOffDetOption" formControlName="ragStatus"
            (change)="setRowColor('ragStatus')">
            <option value="" selected></option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
            <option value="N/A">N/A</option>
          </select>
          <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
        </div>
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="ragStatus" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="riskLevelComments"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="ragStatusText"></p>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>