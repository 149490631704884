import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ProfileService } from 'src/app/profile.service';
import { AuditService, LogEntry } from 'src/app/services/audit.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnChanges {

  @Input() showReport: boolean;
  @Output() hide = new EventEmitter<boolean>();

  public showSpinner: boolean = false;
  public currentDate: string;


  constructor(private auditService: AuditService, private dashService: DashboardService, private profileService: ProfileService) {
    this.currentDate = this.getDate();
}

  ngOnChanges() {
    if (this.showReport) {
      this.showSpinner = true;

      var w: any = window;
      if (w.fcWidget) {
        w.fcWidget.hide();
      }

      setTimeout(() => {
        this.showSpinner = false;
      }, 3000);
    }
    else {
      var w: any = window;
      if (w.fcWidget) {
        w.fcWidget.show();
      }
    }
  }
  closeReport() {
    this.showReport = false;
    this.hide.emit(false);
  }
  onPrint(): void {
    window.print();  
  }

  getAuditEntries() : LogEntry[] {
    return this.auditService.getEntries();
  }
  
  getAuditUserFullName(): string {
    return this.auditService.getUserFullName();
  }
  
  getReportName(): string {
    return this.dashService.getInvestigation().name;
  }
  
  getCompanyName(): string {
    return this.profileService.companyName;
  }

  getDate(date?: Date): string {
    return UtilityService.convertToLocalDateTime(date, "long");
  }
  
}
