import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProfileService } from 'src/app/profile.service';
import { Assessment, IndustryVerticalsAverage, QuestionSetDetail, RecipientQuestionSetResponse } from 'src/nswag';
import { CHART_TYPE } from '../charts/charts.component';
import { RiskAssessmentService } from '../services/risk-assessment.service';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'group-report',
  templateUrl: './group-report.component.html',
  styleUrls: ['./group-report.component.scss']
})
export class GroupReportComponent implements OnChanges {

  constructor(private riskAssessmentService: RiskAssessmentService, private profileService: ProfileService) {}
  private showTimer;
  public loaded = false;
  public chartType = CHART_TYPE;
  @Input() currentAssessment: Assessment;
  public dataLoaded = [];
  public showSpinner: boolean = false;
  @Input() showGroupReport: boolean = false;
  public showReport: boolean;
  public recipientQuestionSetResponses: RecipientQuestionSetResponse[] = [];
  public questionSets: QuestionSetDetail[] = [];
  public industryAverages: IndustryVerticalsAverage[] = [];
  public tenantCompanyName: string;
  public companies: string;
  public isCapabilities: boolean = false;
  public isControls: boolean = false;
  public dateNow: string;
  @Output() hide = new EventEmitter<boolean>();

  ngOnChanges(): void {
    if (this.currentAssessment === undefined || !this.showGroupReport) return;
    this.showSpinner = true;
    this.showTimer = setTimeout(() => this.show(), 2000); // JIC
    this.tenantCompanyName = this.profileService.companyName;
    this.dateNow = UtilityService.convertToLocalDateTime(new Date(), "long");
    forkJoin({
      responses: this.riskAssessmentService.loadResponsesByCompletedAssessmentId(this.currentAssessment.id),
      questionSets: this.riskAssessmentService.getAssessmentQuestionSets(this.currentAssessment.id),
      industryAverages: this.riskAssessmentService.loadIndustryVerticalsAverageByIndustryVerticalsId(this.currentAssessment.industryVerticalsId)
        .pipe(catchError(e => of(null))),
    }).subscribe(res => {
      this.recipientQuestionSetResponses = res?.responses?.isSuccess ? res?.responses?.data?.results : null;
      this.questionSets = res?.questionSets?.isSuccess ? res?.questionSets?.data?.results : null;

      this.industryAverages = res?.industryAverages?.isSuccess ? res?.industryAverages?.data?.results : null;
      this.isCapabilities = res?.responses?.isSuccess ? this.recipientQuestionSetResponses?.some(x => x.category === 'Capabilities') : false;
      this.isControls = res?.responses?.isSuccess ? this.recipientQuestionSetResponses?.some(x => x.category === 'Controls') : false;
      this.companies = res?.responses?.isSuccess ? [...new Set(this.recipientQuestionSetResponses?.map(x => x.companyName))].toString() : null;
      clearTimeout(this.showTimer);
      this.show();
    });
  }

  private show(): void {
    this.showReport = true;
    this.showSpinner = false;
    
    var w: any = window;
    if (w.fcWidget) {
      w.fcWidget.hide();
    }
  }

  public onPrintChange = (event: MediaQueryListEvent) => {
    if (event.matches) {
      this.beforePrint()
    }
  }

  public onPrint(): void {
    if (window.matchMedia) {
      let mediaQueryList = window.matchMedia('print');
      mediaQueryList.addEventListener('change', (msq) => this.onPrintChange(msq));
    }

    window.onbeforeprint = this.beforePrint;
    window.print();
  }

  public beforePrint () {
    for (const id in Chart.instances) {
      Chart.instances[id].resize();
    }
  }

  closeReport() {
    let mediaQueryList = window.matchMedia('print')
    mediaQueryList.removeAllListeners('change');
    this.showReport = false;
    this.showGroupReport= false;
    this.hide.emit(false);

    var w: any = window;
    if (w.fcWidget) {
      w.fcWidget.show();
    }
  }

  get companyName(): string { return this.profileService?.companyName; }
  get userName(): string { return this.profileService?.fullName; }
}
