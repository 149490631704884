import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-report-wrapper',
  templateUrl: './report-wrapper.component.html',
  styleUrls: [
    './report-wrapper.component.scss',
    '../regulatory-reports-base.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ReportWrapperComponent implements AfterViewInit {

  @ViewChild('shadowOverlayContainer', { static: false }) shadowOverlayContainer: ElementRef;

  @Input() totalSteps: number;
  @Input() step: number;
  @Input() disableSave: boolean = true;
  @Input() showProgressBar: boolean = true;
  @Input() readonly: boolean = false;

  @Output() onPreviousSectionClickedEvent = new EventEmitter<number>();
  @Output() onNextSectionClickedEvent = new EventEmitter<number>();
  @Output() onSaveEvent = new EventEmitter<void>();
  @Output() onCompleteEvent = new EventEmitter<void>();
  @Output() onExitEvent = new EventEmitter<void>();

  constructor(private primengConfig: PrimeNGConfig) { }

  ngAfterViewInit(): void {
    this.primengConfig.overlayOptions = {
      appendTo: this.shadowOverlayContainer.nativeElement
    };
  }

  public onExit(): void {
   this.onExitEvent.emit();
  }
  
  public onSave(): void {
    this.onSaveEvent.emit();
  }
  
  public onComplete(): void {
    this.onCompleteEvent.emit();
  }

  public onPrevious(): void {
    this.onPreviousSectionClickedEvent.emit(this.step);
  }

  public onNext(): void{
    this.onNextSectionClickedEvent.emit(this.step);
  }
}
