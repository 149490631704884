import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ReportUtilities } from '../report-utils';

@Component({
  selector: 'app-risk-summary',
  templateUrl: './risk-summary.component.html',
  styleUrls: ['./risk-summary.component.scss']
})
export class RiskSummaryComponent implements OnInit {

  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public rowColors: Map<string, string> = new Map<string, string>();

  constructor(public dashService: DashboardService, private reportUtils: ReportUtilities) { }

  ngOnInit() {
    this.loadFormControls();
    this.reinstateRowColors();
  }

  private loadFormControls(): void {
    if (this.dashService.reportForms?.controls['sraHeading'] == null) {
      this.dashService.reportForms?.addControl('sraHeading', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('moneyLaunderingRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('moneyLaunderingComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('fraudRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('fraudComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('briberyCorruptionRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('briberyCorruptionComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('sanctionsRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('sanctionsComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('taxEvasionRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('taxEvasionComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('mshtRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('mshtComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('illegalWildlifeTradeRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('illegalWildlifeTradeComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('marketAbuseRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('marketAbuseComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('cyberCrimeRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('cyberCrimeComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('proliferationFinancingRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('proliferationFinancingComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('cyberTerrorismRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('cyberTerrorismComments', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('terroristFinancingRiskLevel', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('terroristFinancingComments', new UntypedFormControl(''));
    }
    setTimeout(() => {
      this.resizeAll();
    }, 3000);
  }

  public setRowColor(controlName: string): void {
    var control = this.dashService.reportForms?.controls[controlName];
    this.rowColors.set(controlName, this.reportUtils.getRowColor(control?.value));
  }

  public resizeAll(): void {
    if(this.textareas) {
      this.textareas.toArray().forEach((item) => { 
        item.nativeElement.style.height = 'auto';
        item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
      }); 
    }
  }

  private reinstateRowColors(): void {
    Object.keys(this.dashService.reportForms?.controls).forEach(key => {
      this.setRowColor(key);
    });
  }
}
