import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function emailMessageInvalid(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const variables = control?.value?.match(/\{\{(.*?)\}\}/gi);
    const acceptableVariables = ['{{CompanyName}}', '{{SenderName}}'];
    const invalidVariables = variables?.filter?.(x => acceptableVariables.indexOf(x) === -1);
    return invalidVariables && invalidVariables?.length > 0 ? { invalidPlaceHolders: invalidVariables?.join() }  : null;
  }
}

@Directive({
  selector: '[appRiskAssessmentEmailValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: RiskAssessmentEmailValidatorDirective,
    multi: true
  }]
})

export class RiskAssessmentEmailValidatorDirective implements Validator {
  validate(control: AbstractControl) : {[key: string]: any} | null {
    return emailMessageInvalid()(control);
  }
}
