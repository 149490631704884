import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { PanelModule } from 'primeng/panel';
import { PrimeNGShadowDOMDirective } from 'primeng-shadowdom-directives';

const initializeAppFactory = (primeConfig: PrimeNGConfig) => () => {
  primeConfig.ripple = true;
};

@NgModule({
  declarations: [],
  imports: [
    PrimeNGShadowDOMDirective,
    TableModule,
    OverlayModule,
    OverlayPanelModule,
    PanelModule,
    PanelMenuModule,
    RippleModule,
    InputTextareaModule,
    ProgressBarModule,
    ToastModule,
    ChipsModule,
    AutoCompleteModule,
    InputTextModule,
    CalendarModule,
    TooltipModule,
    PaginatorModule,
    DialogModule,
    InputGroupModule,
    InputGroupAddonModule,
    ListboxModule,
    MenuModule,
    SplitButtonModule,
    DropdownModule,
    RadioButtonModule,
    ButtonModule,
    TieredMenuModule,
    CardModule,
    DynamicDialogModule,
    MessagesModule,
    MessageModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    SpeedDialModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [PrimeNGConfig],
      multi: true,
   },
    MessageService,
    DialogService,
    ConfirmationService
  ],
  exports: [
    PrimeNGShadowDOMDirective,
    TableModule,
    OverlayModule,
    OverlayPanelModule,
    PanelModule,
    PanelMenuModule,
    RippleModule,
    InputTextareaModule,
    ProgressBarModule,
    ToastModule,
    ChipsModule,
    AutoCompleteModule,
    InputTextModule,
    CalendarModule,
    TooltipModule,
    PaginatorModule,
    DialogModule,
    InputGroupModule,
    InputGroupAddonModule,
    ListboxModule,
    MenuModule,
    SplitButtonModule,
    DropdownModule,
    RadioButtonModule,
    ButtonModule,
    TieredMenuModule,
    CardModule,
    DynamicDialogModule,
    MessagesModule,
    MessageModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    SpeedDialModule
  ],
  bootstrap: []
})
export class PrimeNgModule { }
