import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseOfGetDistinctClientTagsModel, TagClient } from 'src/nswag';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor(private tagClient: TagClient) { }

  public getClientTags(): Observable<ApiResponseOfGetDistinctClientTagsModel> {
    return this.tagClient.getClientTags();
  }
}
