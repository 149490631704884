import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ApiResponseOfDataProviderSettings, ToggleDiligenciaDataProviderCommand, DataProviderClient, UpdateAcurisDataProviderSettingsCommand } from 'src/nswag';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {
  constructor(public dataProviderClient: DataProviderClient) { }

  public getTenantDataProviderDetails(tenantId: string): Observable<ApiResponseOfDataProviderSettings> {
    return this.dataProviderClient.getTenantsProviderDetails(tenantId);
  }

  public updateDataProviderSettings(command: UpdateAcurisDataProviderSettingsCommand): Observable<ApiResponse> {
    return this.dataProviderClient.updateDataProviderSettings(command);
  }

  public configurePremiumSearchProvider(command: ToggleDiligenciaDataProviderCommand): Observable<ApiResponse> {
    return this.dataProviderClient.enableDiligenciaDataProvider(command);
  }
}
