import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { RegulatoryReportsService } from '../regulatory-reports.service';
import { MarkReportAsSubmittedCommand, Report } from 'src/nswag';
import { Message, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-report-declaration-dialog',
  templateUrl: './report-declaration-dialog.component.html',
  styleUrls: [
    './report-declaration-dialog.component.scss',
    '../regulatory-reports-base.component.scss'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ReportDeclarationDialogComponent implements OnInit {

  public today = new Date();
  public date: Date;
  public userAgreeDeclaration: boolean = false;

  public report: Report;
  public loading: boolean = false;

  @Output() submittedEvent = new EventEmitter<Date>();

  constructor(private reportService: RegulatoryReportsService, private messageService: MessageService, private dialogConfig: DynamicDialogConfig, private dialogRef: DynamicDialogRef) {} 
  
  ngOnInit(): void {
    if (this.dialogConfig.data) {
      this.report = this.dialogConfig.data.report;
    }
  }
  
  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.loading = true;
    var message: Message;
    const command = new MarkReportAsSubmittedCommand({ reportId: this.report.id, submittedOn: this.date});

    this.reportService.markReportAsSubmitted(command).subscribe({
      next: () => {
        message = {severity: 'success', summary: 'Report submitted', detail: `Report: ${this.report.title}`};
        this.submittedEvent.emit(this.date);
      },
      error: (err) => {
        console.error(err);
        message = {severity:'error', summary: 'Something went wrong', detail: `There was a problem submitting the report: ${this.report.title}. Please try again or contact our support team if the problem persists`};
      }
    }).add(() => {
      this.messageService.add(message);
      this.loading = false
    });
  }
}
