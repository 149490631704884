import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CallbackPipe } from './callback-pipe';
import { SecurePipe } from './secure.pipe';

@NgModule({
  declarations: [
    CallbackPipe,
    SecurePipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
      CallbackPipe,
      SecurePipe
  ],
  providers: [
  ],

})
export class PipesModule { }
