import { Injectable } from "@angular/core";
import { IndustryVerticalsAverage, QuestionSetDetail, RecipientQuestionSetResponse } from "src/nswag";

interface IGroupReportData {
  x: string,
  y: string,
  rating: string,
  score: number
};

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor() { }

  public groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );

  public GetQuestionSetCategories(questionSets: QuestionSetDetail[]) {
    return Object.keys(this.groupBy(questionSets, 'category'));
  }

  public GetScatterData(diags: RecipientQuestionSetResponse[], questionSets: QuestionSetDetail[], industryAverages: IndustryVerticalsAverage[], categories: string | string[]) {
    const datasets = [];
    const getIndustryVerticalScores = (ids: any, industryAverages: IndustryVerticalsAverage[]) => {
      return ids ?
        industryAverages?.map(
          ({ average, questionSetId }) => ids.includes(questionSetId) ?
            average === 0 ? null : average : null) : [0];
    }

    var companies = this.groupBy(diags, 'companyName');
    let controls;
    let capabilities;

    Object.keys(companies).forEach(companyName => {
      var company = companies[companyName];
      controls = this.groupBy(company, 'category').Controls;
      capabilities = this.groupBy(company, 'category').Capabilities;

      const controlScores = controls ? controls?.map(({ score }) => 100 * (score / 22)) : [0];
      const capabilityScores = capabilities ? capabilities?.map(({ score }) => 100 * (score / 22)) : [0];

      let x = controlScores.reduce((a, b) => a + b, 0) / controlScores.length;
      let y = capabilityScores.reduce((a, b) => a + b, 0) / capabilityScores.length;
      // convert
      if (categories?.length === 1 && categories.includes('Capabilities')) {
        x = y;
        y = 0;
      }

      datasets.push({
        label: companyName,
        data: [{
          x: x,
          y: y,
        }],
        pointRadius: 4,
        borderColor: 'rgba(100, 149, 237, 1)',
        backgroundColor: 'rgba(100, 149, 237, 0.6)'
      });
    });

    if (industryAverages.length > 0) {
      const controlIds = controls?.map(x => questionSets.find(y => y.name === x.name).id);
      const capabilityIds = capabilities?.map(x => questionSets.find(y => y.name === x.name).id);
      const controlScores = getIndustryVerticalScores(controlIds, industryAverages).filter(n => n !== null);
      const capabilityScores = getIndustryVerticalScores(capabilityIds, industryAverages).filter(n => n !== null);
      let x = controlScores.reduce((a, b) => a + b, 0) / controlScores.length;
      let y = capabilityScores.reduce((a, b) => a + b, 0) / capabilityScores.length;

      if (categories?.length === 1 && categories.includes('Capabilities')) {
        x = y;
        y = 0;
      }

      // datasets.push({
      //   label: 'Industry average',
      //   data: [{
      //     x: distribute(x),
      //     y: distribute(y),
      //   }],
      //   pointRadius: 10,
      //   borderColor: 'black',
      //   //backgroundColor: 'black'
      // });
    }
    datasets.sort((a, b) => a.data[0].y - b.data[0].y);
    // leave to test distribution
      // for(var i = 0 ; i < 101 ; i = i + 5) {
      //   let x = i;
      //   let y = 0;

      //   datasets.push({
      //     label: `test label ${i}`,
      //     data: [{
      //       x: x,
      //       y: y,
      //     }],
      //     pointRadius: 3,
      //   });


    // for(var c = 0 ; c < 101 ; c = c + 25) {
    //   for(var i = 0 ; i < 101 ; i = i + 25) {
    //     let x = i < 70 ? (5 * i) / 7 : ((5 * i) / 3) - (200 / 3);
    //     let y = c < 70 ? (5 * c) / 7 : ((5 * c) / 3) - (200 / 3);

    //     datasets.push({
    //       label: `${i}, ${c}`,
    //       data: [{
    //         x: x,
    //         y: y,
    //       }],
    //       pointRadius: 3,
    //       borderColor: 'black',
    //       backgroundColor: 'black'
    //     });
    //   }

    //}

    let scatter = { datasets: datasets };
    return scatter;
  }

  public GetLinearScatterOptions(category: string) {
    return {
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            callback: (value, index, ticks) => value === 0 ? category : null,
            font: { size: 16 }
          },
          grid: {
            drawBorder: false,
            color: (context) => context.tick.value === 0 ? '#000000' : null
          },
          min: -1,
          max: 1,
        },
        x: {
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
          min: 0,
          max: 100,
        }
      },
      plugins: {
        legend: { display: false },
        tooltips: { enabled: false },
        datalabels: {
          align: 'end',
          borderRadius: 5,
          font: { size: 11 },
          padding: 5,
          rotation: 90
        },
        quadrants: {
          text: 'rgb(255, 255, 255)',
          right: 'rgb(32, 112, 107)',
          middle: 'rgba(255, 166, 0, 0.8)',
          left: 'rgba(255, 0, 0, 0.8)',
        }
      }
    }
  }

  public GetScatterOptions() {
    return {
      layout: {
        padding: 20
      },
      scales: {
        y: {
          title: {
            text: 'Capabilities Maturity',
            display: true,
            align: 'end',
          },
          min: 0,
          max: 100,
          ticks: {
            display: false,
          },
          grid: {
            display: false
         }
        },
        x: {
          title: {
            text: 'Controls Maturity',
            display: true,
            align: 'end',
          },
          min: 0,
          max: 100,
          ticks: {
            display: false,
          },
          grid: {
            display: false
         }
        }
      },
      plugins: {
        legend: { display: false },
        tooltips: { enabled: false },
        datalabels: {
          display: true,
          align: 'bottom',
          borderRadius: 3,
          font: { size: 11 },
        },
        quadrants: {
          text: 'rgb(255, 255, 255)',
          topLeft: 'rgba(255, 166, 0, 1)',
          topLeftLight: 'rgba(255, 166, 0, 0.4)',

          topRight: 'rgb(32, 112, 107)',
          topRightLight: 'rgba(32, 112, 107, 0.4)',

          bottomRight: 'rgba(255, 166, 0, 1)',
          bottomRightLight: 'rgba(255, 166, 0, 0.4)',

          bottomLeft: 'rgba(255, 0, 0, 1)',
          bottomLeftLight: 'rgba(255, 0, 0, 0.4)',

        }
      }
    };
  }

  public getScatterPlugins(categories: string[]) {
    const fillQuadrantText = (ctx, options, label, x, y) => {
      //ctx.fillStyle = options.text;
      ctx.font = 'bold 28px Lato';
      const textWidth = ctx.measureText(label).width
      ctx.fillText(label, x - (textWidth / 2), y);
    };

    const plugins = [];
    plugins.push(
      categories.length === 1 ?
        this.GetLinearScatterPlugin(fillQuadrantText, categories[0] === 'Capabilities' ? 'Controls Risk' : 'Capabilities Risk') :
        this.GetQuatrantScatterPlugin(fillQuadrantText)
    );

    plugins.push({
      id: 'scatterDataLabels',
      afterDatasetsDraw(chart, args, options) {
        const inRange = (x, min, max) => ((x - min) * (x - max) <= 0);

        const checkIntercept = (x: any, y: any, width: number, taken, distribution: number) => {
          return (taken.some(taken =>
          (inRange(x, taken.x - taken.width, taken.x + taken.width) || inRange(x + width, taken.x - taken.width, taken.x + taken.width)) && inRange(y, taken.y - distribution, taken.y + distribution)));

        }

        const { ctx, data } = chart;
        ctx.save();
        const taken: Array<{ x: number, y: number, width: number }> = [];

        for (let i = 0 ; i < data.datasets.length ; i++) {
          taken.push({ x: chart.getDatasetMeta(i).data[0].x, y: chart.getDatasetMeta(i).data[0].y, width: 20 });
        }

        data.datasets.forEach((dataset, index) => {
          ctx.font = 'bold 15px Lato';
          const textWidth = ctx.measureText(dataset.label).width
          const maxY = chart.scales.y.getPixelForValue(100);
          const maxX = chart.scales.x.getPixelForValue(100);

          const minY = chart.scales.y.getPixelForValue(0);
          const minX = chart.scales.x.getPixelForValue(0);

          const midX = chart.scales.x.getPixelForValue(50);
          const midY = chart.scales.y.getPixelForValue(50);

          let x = chart.getDatasetMeta(index).data[0].x;

          if (categories.length === 1) {
            x = x < minX ? minX : x;
            x = x - (textWidth / 2);
            x = (x + textWidth) > maxX ? maxX - textWidth : x;
          }

          let y = categories.length > 1 ? chart.getDatasetMeta(index).data[0].y : minY + 10;
          const oriY = y;

          const distribution = categories.length > 1 ? 25 : 15;
          for (let i = 0 ; i < 500 ; i++) {
            if (checkIntercept(x, y, textWidth + 5, taken, distribution)) {
              if (categories.length > 1) {
                y = y > midY ? y + 5 : y - 5;
                y = y < maxY ? midY + 1 : y > minY ? midY - 1 : y;

                x = x > midX ? x + 5 : x - 5;
                x = x < minX ? midX + 1 : x > maxX ? midX - 1 : x;
              } else {
                y = minY + 10 + i;
              }
              if (i === 499) {
                y = oriY;
              }
            } else {
              taken.push({ x, y, width: textWidth });
              break;
            }
          }
          ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
          ctx.fillText(dataset.label, x, y);
          ctx.strokeStyle = 'rgba(0, 0, 0, 0.8)';
          ctx.beginPath();
          ctx.moveTo(x + (textWidth / 2), y - (categories.length > 1 ? 0 : 10));
          ctx.lineTo(chart.getDatasetMeta(index).data[0].x, chart.getDatasetMeta(index).data[0].y);
          ctx.stroke();
        });
        ctx.restore();


      }
    });

    return plugins;
  }

  public sortAlphabetically = (a, b, propertyName) => {
    var first = a[propertyName].toUpperCase();
    var second = b[propertyName].toUpperCase();
    return (first < second) ? -1 : (first > second) ? 1 : 0;
  }

  private GetLinearScatterPlugin(fillQuadrantText: (ctx: any, options: any, label: any, x: any, y: any) => void, riskText): any {
    return {
      id: 'quadrants',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, right, bottom }, scales: { x, y } } = chart;
        const start = x.getPixelForValue(0);
        const topY = y.getPixelForValue(1);
        const height = y.getPixelForValue(-1) - topY;
        const offset = 5;
        const blockWidth = ((x.getPixelForValue(100) - start - (offset * 4)) / 3);
        const halfWidth = blockWidth / 2;

        ctx.save();
        ctx.fillStyle = options.left;
        ctx.fillRect(offset + start, topY, blockWidth, height);
        ctx.fillStyle = options.text;

        fillQuadrantText(ctx, options, 'Higher Risk', offset + start + halfWidth, topY + 50);

        ctx.fillStyle = options.middle;
        ctx.fillRect(blockWidth + (2 * offset) + start, topY, blockWidth, height);
        ctx.fillStyle = options.text;

        fillQuadrantText(ctx, options, riskText, (2 * offset) + start + blockWidth + halfWidth, topY + 50);

        ctx.fillStyle = options.right;
        ctx.fillRect((blockWidth * 2) + (3 * offset) + start, topY, blockWidth, height);
        ctx.fillStyle = options.text;

        fillQuadrantText(ctx, options, 'Lower Risk', (3 * offset) + start + (blockWidth * 2) + halfWidth, topY + 50);

        ctx.restore();
      }
    };
  }

  private GetQuatrantScatterPlugin(fillQuadrantText: (ctx: any, options: any, label: any, x: any, y: any) => void): any {
    return {
      id: 'quadrants',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, right, bottom }, scales: { x, y } } = chart;
        const midX = x.getPixelForValue(50);
        const midY = y.getPixelForValue(50);
        const offset = 5;
        const leftOff = (offset + left);
        const topOff = (offset + top);

        ctx.save();

        ctx.fillStyle = options.topLeftLight;
        ctx.fillRect(leftOff, topOff, midX - left - (2 * offset), midY - top - (2 * offset));
        ctx.fillStyle = options.topLeft;

        fillQuadrantText(ctx, options, 'Controls Risk', x.getPixelForValue(25), y.getPixelForValue(75) + 10);

        ctx.fillStyle = options.topRightLight;
        ctx.fillRect(offset + midX, topOff, right - midX - (2 * offset), midY - top - (2 * offset));
        ctx.fillStyle = options.topRight;

        fillQuadrantText(ctx, options, 'Lower Risk', x.getPixelForValue(75), y.getPixelForValue(75) + 10);

        ctx.fillStyle = options.bottomRightLight;
        ctx.fillRect(offset + midX, offset + midY, right - midX - (2 * offset), bottom - midY - (2 * offset));
        ctx.fillStyle = options.bottomRight;

        fillQuadrantText(ctx, options, 'Capabilities Risk', x.getPixelForValue(75), y.getPixelForValue(25) + 10);


        ctx.fillStyle = options.bottomLeftLight;
        ctx.fillRect(leftOff, offset + midY, midX - left - (2 * offset), bottom - midY - (2 * offset));
        ctx.fillStyle = options.bottomLeft;

        fillQuadrantText(ctx, options, 'Higher Risk', x.getPixelForValue(25), y.getPixelForValue(25) + 10);

        ctx.restore();
      }
    };
  }

  public GetRadarData(diags: RecipientQuestionSetResponse[], questionSets: QuestionSetDetail[], category: string, industryAverages: IndustryVerticalsAverage[] = null) {
    let displayLabels = new Array<string | Array<string>>();
    let data = new Array<number>();
    let industryAverageData = [];
    questionSets.sort((a, b) => this.sortAlphabetically(a, b, "name")).forEach(qs => {
      if (qs.category === category) {
        let label = this.squashLabels(qs.name);
        displayLabels.push(label);
        const response = diags.find(x => x.questionSetId === qs.id);
        const industryAverage = industryAverages.find(x => x.questionSetId === qs.id);
        industryAverageData.push(industryAverage?.average ?? 0); // replace with 0 one api is up and running with useful data

        if (!response) {
          data.push(0);
        }
        else {
          if (response.score > 0) {
            // Ignore scores of zero as these can only occur if the user n/a'd every question
            var percentage = 100 * (response.score / 22);
            data.push(percentage);
          }
        }
      }
    })

    let datasets = [
      {

        'backgroundColor': 'rgba(43, 66, 81,0.0)',
        'borderColor': 'rgba(43, 66, 81,1)',
        'data': data,
        'label': 'AFC Rating.',
        'pointBackgroundColor': 'rgba(43, 66, 81,1)',
        'pointBorderColor': '#fff',
        'pointRadius': 3,
        'pointBorderWidth': 1
      },
      {
        'backgroundColor': 'rgba(248, 105, 107,0.3)',
        'borderColor': 'rgba(248, 105, 107,0.3)',
        'data': this.CreateDummyRadarData(data.length, 25),
        'pointRadius': 0
      },
      {
        'backgroundColor': 'rgba(252, 179, 121,0.3)',
        'borderColor': 'rgba(252, 179, 121,0.3)',
        'data': this.CreateDummyRadarData(data.length, 50),
        'pointRadius': 0
      },
      {
        'backgroundColor': 'rgba(144, 203, 126,0.3)',
        'borderColor': 'rgba(144, 203, 126,0.3)',
        'data': this.CreateDummyRadarData(data.length, 75),
        'pointRadius': 0
      },
      {
        'backgroundColor': 'rgba(99, 190, 123,0.3)',
        'borderColor': 'rgba(99, 190, 123,0.3)',
        'data': this.CreateDummyRadarData(data.length, 100),
        'pointRadius': 0
      }
    ];

    if (industryAverages.find(x => x.average > 0)) {
      const average = {
        'backgroundColor': 'rgba(100, 149, 237, 0.0)',
        'borderColor': 'rgba(100, 149, 237, 1)',
        'pointBackgroundColor': 'rgba(100, 149, 237, 1)',
        'pointBorderColor': '#fff',
        'data': industryAverageData,
        'label': 'Industry Average.',
        'pointRadius': 6,
        'pointBorderWidth': 2
      };
      datasets.push(average);
    }

    let radar = {
      labels: displayLabels,
      datasets: datasets
    };
    return radar;
  }

  private squashLabels(longLabel: string) {
    let label = [];
    if (longLabel.length > 12 && longLabel.includes(' ')) {
      let tempLabels = longLabel.split(' ');
      let tempString = '';
      tempLabels.forEach(l => {
        const tempLabel = tempString.concat(tempString !== '' ? ' ' : '', l);
        if (tempLabel.length < 15) {
          tempString = tempLabel;
        } else {
          label.push(tempString);
          tempString = l;
        }
      });

      label.push(tempString);
    } else {
      label.push(longLabel);
    }
    return label;
  }

  public GetRadarOptions(title: string) {
    return {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          text: title,
          display: true,
          align: 'start',
        },
        legend: {
          labels: {
            filter: (legendItem, chartData) => (legendItem.text !== undefined)
          },
          display: true,
          align: 'start',
          position: 'bottom',
        }
      },
      layout: {
        padding: {
          bottom: 10
        }
      },
      scale: {
        ticks: {
          beginAtZero: true,
          max: 100,
          display: false,
          stepSize: 25
        },
        pointLabels: {
          fontSize: 14,
          fontColor: '#2b4251'
        },
        gridLines: {
          color: '#2b4251'
        },
        angleLines: {
          color: '#2b4251'
        }
      }
    }
  }

  public GetRadarPlugin() {
    return {
      id: 'radarplugin',
      beforeDraw: (ctx, chart) => {
        var chartHeight = chart.height;
        var size = chartHeight * 5 / 100; //fontSize: 6% of canvas height
        size = (size < 8 ? 8 : size > 16 ? 16 : size);
        ctx.options.scale.pointLabels.fontSize = size;
        var legends = ctx.legend.legendItems;
        legends.forEach(function (e) {
          if (e.text === 'AFC Rating.') {
            e.fillStyle = 'rgb(43, 66, 81)'
          }
          else if (e.text === 'Industry Average.') {
            e.fillStyle = 'rgb(100, 149, 237)'
          }
        });
      }
    }
  }

  public GetRatingColor(rating: string) {
    switch (rating) {
      case 'AAA-': case 'AAA': case 'AAA+':
        return '#38A564';
      case 'AA-': case 'AA': case 'AA+':
        return '#80C08C';
      case 'A-': case 'A': case 'A+':
        return '#9CC0A2';
      case 'BBB-': case 'BBB': case 'BBB+':
        return '#F8D196';
      case 'BB-': case 'BB': case 'BB+':
        return '#FBBC57';
      case 'B-': case 'B': case 'B+':
        return '#ED9D84';
      case 'CCC-': case 'CCC': case 'CCC+':
        return '#E66C37';
      case 'D':
        return '#B35430';
      default:
        break;
    }
  }

  public GetBarData(diags: RecipientQuestionSetResponse[], industryAverages: IndustryVerticalsAverage[], questionSets: QuestionSetDetail[]) {
    let displayLabels = new Array<string | Array<string>>();
    let data = new Array<number>();
    let colors = new Array<string>();
    let borderColors = new Array<string>();
    let industryAverage: IndustryVerticalsAverage;

    diags.forEach(diag => {
      let label = this.squashLabels(diag.name);
      displayLabels.push(label);
      let percentage = 100 * (diag.score / 22);
      data.push(percentage);
      const color = this.GetRatingColor(diag.rating);
      colors.push(color);
      borderColors.push(color);

      const qs = questionSets.find(x => x.id === diag.questionSetId);
      industryAverage = industryAverages?.find?.(x => x.questionSetId === qs.id);

      if (industryAverage?.average > 0) {
        displayLabels.push([]);
        data.push(industryAverage?.average);
        const color = 'rgb(100, 149, 237)';
        colors.push(color);
        borderColors.push(color);
      }
    });

    let datasets = [
      {
        'axis': 'y',
        'backgroundColor': colors,
        'borderColor': borderColors,
        'data': data,
        'label': industryAverage !== null ? 'Industry Average' : null
      }
    ];

    let bar = {
      labels: displayLabels,
      datasets: datasets
    };
    return bar;
  }

  public GetBarOptionsWithAverages(title: string) {
    return {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y' as const,
      plugins: {
        title: {
          text: title,
          display: true,
          align: 'start',
        },
        legend: {
          labels: {
            filter: (legendItem, chartData) => legendItem.text.includes('Industry Average')
          },
          display: true,
          position: 'bottom',
        },
      },
    }
  }
  public GetBarOptions(title: string) {
    return {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y' as const,
      plugins: {
        title: {
          text: title,
          display: true,
          align: 'start',
        },
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          ticks: {
            autoSkip: false,
          },
        },
      }
    }
  }

  public GetBarPlugin(rating: any) {
    return {
      id: 'innerBarText',
      afterDatasetsDraw(chart) {
        const { ctx, chartArea: { left }, scales: { y } } = chart;

        ctx.save();

        for (let index = 0; index < rating.size; index++) {
          const element = Array.from(rating)[index];
          ctx.font = 'bolder 12px sans-serif';
          ctx.fillStyle = 'gray';
          ctx.fillText(element[1], left + 10, y.getPixelForValue(index));
        }
      }
    }
  }

  public GetBarPluginWithAverages(rating: any) {
    return {
      id: 'innerBarText',
      afterDatasetsDraw(chart) {
        const { ctx, chartArea: { left }, scales: { y } } = chart;

        ctx.save();
        var legends = chart.legend.legendItems;
        legends.forEach(function (e) {
          if (e.text === 'Industry Average.') {
            e.fillStyle = 'rgb(100, 149, 237)'
          }
        })

        for (let index = 0; index < rating.size; index++) {
          const element = Array.from(rating)[index];
          if (element !== undefined) {
            const labels = chart.scales.y._labelItems;
            var text = labels.find(x => x.label == element[0]);
            if (text !== undefined) {
              ctx.font = 'bolder 12px sans-serif';
              ctx.fillStyle = 'gray';
              ctx.fillText(element[1], left + 10, y.getPixelForValue(index == 0 ? index : index * 2));
            }
          }
        }
      },
      beforeDraw: (ctx, chart) => {
        var legends = ctx.legend.legendItems;
        legends.forEach(function (e) {
          if (e.text === 'Industry Average') {
            e.fillStyle = 'rgb(100, 149, 237)'
          }
        })
      }
    }
  }

  public getGroupReportData(responses: RecipientQuestionSetResponse[], overallScores: IGroupReportData[], widthLength: number, heightLength: number) {
    let infos: IGroupReportData[] = [];
    let colors: string[] = [];
    infos = infos.concat(overallScores);
    infos.forEach(element => {
      const color = this.GetRatingColor(element.rating);
      colors.push(color);
    });
    responses.forEach(element => {
      const info: IGroupReportData = { x: element.name, y: element.companyName, rating: element.rating, score: element.score };
      const color = this.GetRatingColor(element.rating);
      colors.push(color);
      infos.push(info);
    });

    return {
      datasets: [{
        label: 'My Matrix',
        data: infos,
        backgroundColor: colors,
        borderColor: 'rgb(255,255,255)',
        borderWidth: 1,
        width: ({ chart }) => (chart.chartArea || {}).width / widthLength,
        height: ({ chart }) => (chart.chartArea || {}).height / heightLength,
      }]
    };
  }

  public getGroupReportOptions(xLabels: string[], yLabels: string[]): any {
    return {
      maintainAspectRatio: false,
      plugins: {
        legend: false,
        position: 'top',
        tooltip: {
          callbacks: {
            title() {
              return '';
            },
            label(context) {
              const v = context.dataset.data[context.dataIndex];
              return [v.x, 'Company: ' + v.y, 'Rating: ' + v.rating];
            }
          }
        },
        datalabels: {
          color: '#000000',
          formatter(context) {
            return context.rating;
          }
        }
      },
      scales: {
        x: {
          type: 'category',
          labels: xLabels,
          position: 'top',
          ticks: {
            autoSkip: false,
            font: {
              size: 8,
            },
            maxRotation: 0,
            callback(label) {
              const lab = xLabels[label];
              if (/\s/.test(lab)) {
                return lab.split(" ");
              } else {
                return lab;
              }
            }
          },
          grid: {
            display: false
          }
        },
        y: {
          type: 'category',
          labels: yLabels,
          offset: true,
          ticks: {
            autoSkip: false,
            font: {
              size: 10,
            },
          },
          grid: {
            display: false
          }
        }
      }
    };
  }

  /// Created Dummy Radar Data for bandings
  private CreateDummyRadarData(length: number, value: number) {
    let result = new Array<number>(length);
    for (let index = 0; index < length; index++) {
      result[index] = value;
    }
    return result;
  }
}
