import { Component } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { AssessmentRecipient, IndustryVerticalsAverage, QuestionSetDetail, RecipientQuestionSetResponse } from 'src/nswag';
import { RecipientReportService } from './recipient-report.service';

@Component({
  selector: 'app-view-recipient-reports',
  templateUrl: './view-recipient-reports.component.html',
  styleUrls: ['./view-recipient-reports.component.scss']
})
export class ViewRecipientReportsComponent {

  constructor(public recipientReportService: RecipientReportService) { }

  public onPrintChange = (event: MediaQueryListEvent) => {
    if (event.matches) {
      this.beforePrint()
    }
  }

  public onPrint(): void {
    if (window.matchMedia) {
      let mediaQueryList = window.matchMedia('print');
      mediaQueryList.addEventListener('change', (msq) => this.onPrintChange(msq));
    }

    window.onbeforeprint = this.beforePrint;
    window.print();
  }

  public beforePrint () {
    for (const id in Chart.instances) {
      Chart.instances[id].resize();
    }
  }

  closeReport() {
    let mediaQueryList = window.matchMedia('print')
    mediaQueryList.removeAllListeners('change');
    this.recipientReportService.hideReport();
  }

  private now: string = new Date(Date.now()).toLocaleString().split(",")[0];
  get dateNow(): string { return this.recipientReportService.currentRecipient?.completedOn.toLocaleDateString(); }
  get contactName(): string { return this.recipientReportService.currentRecipient?.contactName; }
  get companyName(): string { return this.recipientReportService.currentRecipient?.companyName; }
  get tenantCompanyName(): string { return this.recipientReportService.companyName; }
  get userName(): string { return this.recipientReportService.userName; }
  get rating(): string { return this.recipientReportService.rating; }
  get currentRecipient(): AssessmentRecipient { return this.recipientReportService.currentRecipient; }
  get recipientResponseCapabilities(): RecipientQuestionSetResponse[] { return this.recipientReportService.recipientResponseCapabilities; }
  get industryAverages(): IndustryVerticalsAverage[] { return this.recipientReportService.industryAverages; }
  get questionSets(): QuestionSetDetail[] { return this.recipientReportService.questionSets;}
  get recipientResponseControls(): RecipientQuestionSetResponse[] { return this.recipientReportService.recipientResponseControls; }
}
