<div id="monitor-association">
    <h5 *ngIf="incHeader">Associated Companies</h5>
    <div *ngIf="businessLinks?.length > 0; else emptyAssociation">
        <div class="col alert alert-info">
            This is a list of companies associated with {{name}} that may pose a financial crime risk.
        </div>
        <div *ngFor="let business of businessLinks">
            <div *ngIf="business.discountReason == null || !business.discountReason.isDiscounted" class="row pt-2">
                <div class="col">
                    <strong>{{business.name}}</strong><br>
                    <span>{{business.relationship}}</span>
                    <span *ngIf="business.ownershipPercentage"><br>Owns {{business.ownershipPercentage}}%</span>
                </div>
                <div *ngIf="canAddToGraph" class="col-sm-1">
                    <a *ngIf="checkCanAddToGraph(business); else alreadyAdded" role="button" (click)="addToGraph(business)"  title="Click to add to the graph">
                        <span class="icon-plus-circle"></span>
                    </a>
                    <ng-template #alreadyAdded>
                        <span class="icon-plus-circle icon-disabled"></span>
                    </ng-template>
                </div>
                <div *ngIf="allowDiscounting" class="col-sm-1">
                    <app-discount-reason [enabled]="checkCanDiscount(business)" [business]="business" [profile]="profile" [clientId]="clientId"></app-discount-reason>
                </div>                
            </div>
        </div>
    </div>

    <ng-template #emptyAssociation>
        <div class="col alert alert-info">
            <span>No associations have been found.</span>
        </div>
    </ng-template>
</div>
