import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsComponent } from './tags.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TagClient } from 'src/nswag';



@NgModule({
  declarations: [
    TagsComponent
  ],
  providers:[
    TagClient
  ],
  exports: [
    TagsComponent
  ],
  imports: [
    CommonModule, 
    NgSelectModule,
    NgbModule, 
    FormsModule 
  ]
})
export class TagsModule { }
