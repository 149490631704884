<div id="monitor-legal">
    <p class="alert alert-info my-3" *ngIf="hasLegalEvidence">
        Selecting <strong>View URL Evidence</strong> will direct you to the link. Please note that the source may have expired and is provided by a third-party. Select <strong>View PDF Evidence</strong> to access the PDF version
    </p>
    <div *ngIf="isSPIL">
        <div class="">
            <ng-container *ngIf="isSPIL" [ngTemplateOutlet]="displaySPIL"></ng-container>
        </div>
        <div *ngIf="profile" class=" pt-4">
            <ng-container [ngTemplateOutlet]="displayEnforcement" [ngTemplateOutletContext]="{ entries: lawEntries, title:'Law Enforcement', noeventmessage: 'No law enforcement has been found.', banner: false }"></ng-container>
        </div>
    </div>
    <div *ngIf="!isSPIL" class="">
        <ng-container [ngTemplateOutlet]="displayEnforcement" [ngTemplateOutletContext]="{ entries: lawEntries, title:'Law Enforcement', noeventmessage: 'No law enforcement has been found.', banner: true }"></ng-container>
    </div>
    <div *ngIf="profile" class=" pt-4">
        <ng-container [ngTemplateOutlet]="displayEnforcement" [ngTemplateOutletContext]="{ entries: regEntries, title:'Regulatory Enforcement', noeventmessage: 'No regulatory enforcement has been found.', banner: true }"></ng-container>
    </div>
    
    <mon-disqualdirector *ngIf="profile?.ddEntries" [clientId]="clientId" [profile]="profile" [name]="name" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-disqualdirector>
    
    <mon-insolvency *ngIf="profile?.insEntries" [clientId]="clientId" [profile]="profile" [name]="name" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-insolvency>
    
    <ng-template #displayEnforcement let-entries="entries" let-noeventmessage="noeventmessage" let-title="title" let-banner="banner">
        <h5 *ngIf="banner && incHeader">{{title}}</h5>
        <div *ngIf="entries?.length >0; else noentries">
            <p *ngIf="banner" class="alert alert-danger">
                {{title}} has been reported against {{name}}
            </p>
            <div *ngFor="let entry of entries">
                <div *ngIf="checkCategory(entry)">
                    <div *ngIf="banner" class="" id="left-card">
                        <span class="displaydetail-style">{{getCategory(entry)}}</span>
                    </div>
                    <div class="">
                        <mon-events [clientId]="clientId" [profile]="profile" [events]="entry.events" [evidences]="evidences" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-events>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #noentries>
            <div *ngIf="banner" class="col alert alert-info">{{noeventmessage}}</div>
        </ng-template>
    </ng-template>
    
    <ng-template #displaySPIL>
        <div *ngIf="actor?.asIndividualInfo; let ind">
            <h5 *ngIf="incHeader">Themis Special Interest List</h5>
            <p class="alert alert-danger">Law enforcement has been reported against {{ind.name}}.</p>
            <!-- Display SPIL -->
            <div class=" pt-1">
                <div class="col-4 displaylabel-style">Crime Type:</div>
                <div class="col">{{crimeType(ind)}}</div>
            </div>
            <div class=" pt-1">
                <div class="col-4 displaylabel-style">Sentence:</div>
                <div class="col">{{ind.sentence}}</div>
            </div>
            <div class=" pt-1">
                <div class="col-4 displaylabel-style">Conviction Date:</div>
                <div class="col">{{ind.convictionDate}}</div>
            </div>
            <div class=" pt-1">
                <div class="col-4 displaylabel-style">Court:</div>
                <div class="col">{{ind.court}}</div>
            </div>
            <div class=" pt-1">
                <div class="col displaylabel-style">Sources:</div>
            </div>
            <div *ngFor="let source of getSources(ind)" class=" pt-1">
                <div class="col">
                <a [href]="getSafeUrl(source)" target="_blank">{{source}}</a>
                </div>
            </div>
        </div>
        <ng-template #displayEnforcement let-entries="entries" let-noeventmessage="noeventmessage" let-title="title" let-banner="false"></ng-template>
    
    </ng-template>
</div>