import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UtilityService } from 'src/app/utilitities/utilities';
import { BusinessLink, BusinessProfile, IndividualLink, IndividualProfile } from 'src/nswag';

@Component({
  selector: 'app-discounted-individuals',
  templateUrl: './discounted-individuals.component.html',
  styleUrls: ['./discounted-individuals.component.scss']
})
export class DiscountedIndividualsComponent implements OnChanges {

  @Input() profile: IndividualProfile;
  @Input() incHeader = true;

  public hasIndividualDiscounts = false;

  public individualLinks: IndividualLink[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profile) {
      this.individualLinks = this.profile?.individualLinks;
      this.hasIndividualDiscounts = this.individualLinks?.find( e => e.discountReason?.isDiscounted ) != null;
    }
  }

  getName(link: IndividualLink): string {
    return UtilityService.getIndividualName(link);
  }
}
