import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NgbActiveModal, NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { AddProjectCommand, Address, Address2, Client, EntityTypes, SendEmailCommand, UserInteractionClient } from 'src/nswag';
import { DatePickerResult } from 'src/app/date-picker/date-picker.component';
import { IntelligenceService } from '../services/intelligence.service';
import { CountriesService } from 'src/app/utils/countries.service';
import { OperatorFunction, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UtilityService } from 'src/app/utilitities/utilities';
import { IActorBase } from 'src/app/dashboard/models/Actor';
import { CurrentModule } from 'src/app/monitor/client-report/client-report.component';

@Component({
  selector: 'app-intelligence-request',
  templateUrl: './intelligence-request.component.html',
  styleUrls: ['./intelligence-request.component.scss']
})
export class IntelligenceRequestComponent implements OnInit {
  @Input() selectedClient: Client;
  @Input() currentModule: CurrentModule;
  @Input() selectedActor: IActorBase;
  
  public fullName: string;
  public dateOfBirth: string;

  public gender: string;

  public isBusiness: boolean = false;
  public companyName: string;
  public companyNumber: string;

  public information: string;
  public disableAll = false;
  public dateIsValid = true;
  public nameMininumLength: number = 3;
  public infoMaxLength: number = 1024;

  public nationalityFocus: boolean = false;
  public jurisdictionFocus: boolean = false;
  public addressCountryFocus: boolean = false;

  public addressCountry: string;
  public nationality: string;
  public jurisdiction: string;

  public address: Address2;

  public modal: NgbModalRef;
  public loading: boolean = false;
  
  constructor(public dashService: DashboardService, private activeModal: NgbActiveModal, private alertService: AlertService, public userInteractionClient: UserInteractionClient, private dateFormatter: NgbDateParserFormatter, public intelService: IntelligenceService) { }

  public hasErrors(): boolean {
    var hasErrors = false;
    const sharedFieldsHasErrors = !(this.information?.trim()?.length && this.information?.trim().length <= this.infoMaxLength)
    if (this.isBusiness){
      hasErrors = !(this.companyName?.trim().length >= this.nameMininumLength) || sharedFieldsHasErrors
    }
    else{
      hasErrors = !(this.fullName?.trim().length >= this.nameMininumLength) || !this.dateIsValid || sharedFieldsHasErrors;
    }

   return hasErrors;
  }

  public ngOnInit() {

    this.isBusiness = false;
    this.information = null;
    this.disableAll = false;

    if (this.currentModule === CurrentModule.MONITOR || this.currentModule === CurrentModule.SCREEN || this.currentModule == CurrentModule.EIDV) {
      const client = this.selectedClient;
      this.fullName = client?.individual?.name
      this.dateOfBirth = client?.individual?.dateOfBirth;
      this.nationality = client?.individual?.nationality;
      this.gender = client?.individual?.gender;
      this.jurisdiction = client?.business?.jurisdiction;
      this.companyName = client?.business?.name;
      this.companyNumber = "";
      this.address = this.mapAddressModels(client?.address);
      if (this.companyName !== undefined) {
        this.isBusiness = true;
      }
    }
    else if (this.currentModule === CurrentModule.SEARCH) {
      let actor = this.dashService.getInvestigation();
      switch (actor.actorType) {
        case EntityTypes.Company:
          this.isBusiness = true;
          this.companyName = actor.name;
          let companyInfo = actor.asCompanyInfo;
          this.companyNumber = companyInfo?.entityId;

          this.address = this.mapAddressModels(this.formatAddress(companyInfo?.registeredAddress?.fullAddress));
          this.jurisdiction = companyInfo?.jurisdictionName;
          this.fullName = "";
          this.dateOfBirth = "";
          this.nationality = "";
          this.gender = "";
              break;
        case EntityTypes.IDV:
          let profile = actor?.asUserProfile;
          this.fullName = actor?.name;
          this.dateOfBirth = profile?.dateOfBirth ? UtilityService.convertToLocalDateTime(profile.dateOfBirth, "short") : "";
          this.nationality = profile?.country;
          this.gender = profile?.gender;
          this.address  = this.mapAddressModels(this.formatAddress(profile?.address?.fullAddress));
          // Clear company data
          this.jurisdiction = "";
          this.companyName = "";
          this.companyNumber = "";
          break;
        case EntityTypes.Individual:
          let indi = actor.asIndividualInfo;
          this.fullName = actor?.name;
          this.dateOfBirth = indi?.dateOfBirth;
          this.nationality = indi?.nationality;
          this.gender = indi?.gender;
          this.address  = this.mapAddressModels(this.formatAddress(indi?.address?.fullAddress));
          // Clear company data
          this.jurisdiction = "";
          this.companyName = "";
          this.companyNumber = "";
          break;
        case EntityTypes.Officer:
        case EntityTypes.OfficerCompany:
          let off = actor.asOfficerInfo;
          this.fullName = actor?.name;
          this.dateOfBirth = off?.dateOfBirth;
          this.nationality = off?.nationality;
          this.gender = off?.gender;
          this.address  = this.mapAddressModels(this.formatAddress(off?.address?.fullAddress));
          // Clear company data
          this.jurisdiction = "";
          this.companyName = "";
          this.companyNumber = "";

          break;
        case EntityTypes.UBO:
          let ubo = actor.asUBOInfo;
          this.fullName = actor?.name;
          this.dateOfBirth = ubo?.dateOfBirth;
          this.nationality = ubo?.nationality;
          this.gender = ubo?.gender;
          this.address = this.mapAddressModels(this.formatAddress(ubo?.address?.fullAddress));
          // Clear company data
          this.jurisdiction = "";
          this.companyName = "";
          this.companyNumber = "";
          
          break;
        default:
          // Unexpected entity type - just blank all data
          this.fullName = "";
          this.dateOfBirth = "";
          this.nationality = "";
          this.gender = "";
          this.jurisdiction = "";
          this.companyName = "";
          this.companyNumber = "";
          this.address = this.mapAddressModels(new Address2);
          break;
      }
    }
    else if (this.currentModule === CurrentModule.INTELLIGENCE){
       this.address = this.mapAddressModels(new Address2);
       this.fullName = null;
       this.companyName = null;
       this.companyNumber = null;
       this.dateOfBirth = null;
       this.gender = null;
       this.jurisdiction = null;
       this.nationality = null;
    }
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  public setClientType() {
    this.isBusiness = !this.isBusiness;
  }

  public onBlur(e: Event): void {
    this.isBusiness ? this.jurisdictionFocus = false : this.nationalityFocus = false;
    this.addressCountryFocus = false;
    e.stopPropagation();
  }

  public onFocus(e: Event): void {
    this.isBusiness ? this.jurisdictionFocus = true : this.nationalityFocus = true;
    this.addressCountryFocus = true;
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  public isNationalityValid(): boolean {
    if (!this.nationality) return true;
    if (CountriesService.getNationalityCode(this.nationality) || CountriesService.getNationalityName(this.nationality))
      return true;
    else return false;
  }

  public isJurisdictionValid(): boolean {
    if (!this.jurisdiction) return true;
    if (CountriesService.getJurisdictionCode(this.jurisdiction) || CountriesService.getJurisdictionName(this.jurisdiction))
      return true;
    else return false;
  }

  public isAddressCountryValid(): boolean {
    if (!this.addressCountry) return true;
    if (CountriesService.getJurisdictionCode(this.addressCountry) || CountriesService.getJurisdictionName(this.addressCountry))
      return true;
    else return false;
  }

  public nationalityTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => {
      return this.nationalitySubSet(term);
    })
);

public countryTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => {
      return this.countrySubSet(term);
    })
);

  public submitRequest() {
    this.loading = true;
    this.disableAll = true;

    var command = new AddProjectCommand();
    if (this.isBusiness){
      command.companyName = this.companyName;
      command.companyNumber = this.companyNumber;
      command.jurisdiction = this.jurisdiction;
    }
    else{
      command.fullName = this.fullName;
      command.dateOfBirth = this.dateOfBirth ? new Date(this.dateOfBirth)  : null;
      command.gender = this.gender;
      command.nationality = this.nationality
    }

    command.additionalInfo = this.information;
    this.address.countryIsoCode = UtilityService.getCodeFromCountry(this.addressCountry);
    command.address = UtilityService.convertToAddress(this.address)
    
    this.intelService.requestProject(command).subscribe(r => {
      this.dismiss();
        this.intelService.refreshProjectList(r.data);
        this.alertService.success("The Intelligence Report request has been sent successfully", { autoClose: true, keepAfterRouteChange: false });
      },
      (error) => {
        this.alertService.error('Unfortunately, the request failed. Please try again.', { autoClose: true, keepAfterRouteChange: true });
      }).add(() => {
        this.disableAll = false;
        this.loading = false;
      });
  }

  public dateChanged(dateResult: DatePickerResult) {
    this.dateIsValid = dateResult.isValid;
    if (this.dateIsValid) {
      this.dateOfBirth = this.dateFormatter.format(dateResult.ngbValue);
    }
  }

  private formatAddress(input: string): Address2 {
    let address: Address = new Address({ fullAddress: input});
    let address2 = UtilityService.convertToAddress2(address);
    return address2;
  }

  private mapAddressModels(input: Address2): Address2 {
    this.addressCountry = UtilityService.getCountryFromCode(input?.countryIsoCode);
    let address: Address2 = new Address2({
      line1: input?.line1 ?? '',
      line2: input?.line2 ?? '',
      city: input?.city ?? '',                       
      countryIsoCode: input?.countryIsoCode ?? '', // reassigned from countryName on submit
      county: input?.county ?? '',
      countyAbbrev: input?.countyAbbrev ?? '',
      postcode: input?.postcode ?? '',
      addressType: input?.addressType ?? ''
    });
   return address;
  }

  private countrySubSet(term: string) {
    return CountriesService.getJurisdictionSubSet(term);
  }

  private nationalitySubSet(term: string) {
    return CountriesService.getNationalitySubSet(term);
  }
}