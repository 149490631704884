import { Component, Input, SimpleChanges } from '@angular/core';
import { BusinessMatch, LAMPSStatus } from 'src/nswag';
import { UtilityService } from '../../../utilitities/utilities';
import { BaseReportSectionComponent } from '../base-client-section.component';

@Component({
  selector: 'mon-business-matches-section',
  templateUrl: './business-matches-section.component.html',
  styleUrls: ['./business-matches-section.component.scss']
})
export class BusinessMatchesSectionComponent extends BaseReportSectionComponent {

  @Input() businessMatches: BusinessMatch[];
  public title: string;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.title = this.matchStatus?.matches == LAMPSStatus.Changed ? "New Matches" : "Matches";

  }

  isSOE(match: BusinessMatch) : boolean {
    return UtilityService.isSOE(match.datasets);
  }

  isFormerSOE(match: BusinessMatch) : boolean {
  return UtilityService.isFormerSOE(match.datasets);
  }

  isSanction(match: BusinessMatch) : boolean {
    return UtilityService.isSanction(match.datasets);
  }
  isFormerSanction(match: BusinessMatch) : boolean {
    return UtilityService.isFormerSanction(match.datasets);
  }
  hasLawEnforcement(match: BusinessMatch) : boolean {
    return UtilityService.isLawEnforcement(match.datasets);
  }
  hasAdverseMedia(match: BusinessMatch) : boolean {
    return UtilityService.hasAdverseMedia(match.datasets);
  }

  getCountries(match: BusinessMatch) : string {
    return UtilityService.getCountriesListFromCodes(match?.countries);
  }
}
