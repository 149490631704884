import { Component } from '@angular/core';
import { MatchReportService } from './match-report.service';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'app-match-report',
  templateUrl: './match-report.component.html',
  styleUrls: ['./match-report.component.scss']
})
export class MatchReportComponent  {
  public dateNow: string;

  constructor(public matchReportService: MatchReportService) {
    this.dateNow = UtilityService.convertToLocalDateTime(new Date(), "long");
  }

  public onPrint(): void {
    window.print();  
  }

  closeReport() {
    this.matchReportService.hideReport();
  }

  get userName(): string { return this.matchReportService.userAccount?.email; }
  get userFullName(): string { return this.matchReportService?.fullName; }
  get companyName(): string { return this.matchReportService?.companyName; }
}
