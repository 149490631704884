import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BusinessProfile, Evidence } from 'src/nswag';

@Component({
  selector: 'mon-soe',
  templateUrl: './soe.component.html',
  styleUrls: ['./soe.component.scss']
})
export class SoeComponent implements OnChanges {

  @Input() clientId: string;
  @Input() profile: BusinessProfile;
  @Input() name: string;
  @Input() incHeader = true;
  @Input() allowDiscounting = true;
  @Input() reportMode: boolean = false;

  public isSOE: boolean;
  public isPrevious : boolean;
  public level: string;
  public evidenceIds: string[];
  public evidences: Evidence[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profile) {
      this.isSOE = this.profile?.soeEntry?.status == "Current";
      this.isPrevious = !this.isSOE && this.profile?.soeEntry?.status == "Former";
      this.name = this.profile?.name;
      this.level = this.profile?.soeEntry?.level ?? "";
      this.evidenceIds = this.profile?.soeEntry?.evidenceIds;
      this.evidences = this.profile?.evidences;
    }
    else {
      this.isSOE = false;
    }
  }

}
