import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DdEntry, Evidence, IndividualProfile } from 'src/nswag';

@Component({
  selector: 'mon-disqualdirector',
  templateUrl: './disqualdirector.component.html',
  styleUrls: ['./disqualdirector.component.scss']
})
export class DisqualdirectorComponent implements OnChanges {

   @Input() profile: IndividualProfile;
   @Input() clientId: string;
   @Input() name: string;
   @Input() incHeader = true;
   @Input() allowDiscounting = true;
   @Input() reportMode: boolean = false;
   
   public ddEntries: DdEntry[];
   public evidences: Evidence[];
 
   constructor() { }
 
   ngOnChanges(changes: SimpleChanges): void {
     this.evidences = this.profile?.evidences;
     this.ddEntries = this.profile?.ddEntries;
   }
 
   public checkExists(val: string) {
     return val != null && val.trim().length > 0;
   }
}
