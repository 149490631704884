import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { UtilityService } from '../utilitities/utilities';
import { BusinessMatch, BusinessMatches, BusinessProfile, Client, IndividualMatch, IndividualProfile, MonitoringClient, SelectBusinessMatchCommand, SelectIndividualMatchCommand } from 'src/nswag';
import { FinCrimeCheckClient, SelectIndividualResultCommand, LAMPSStatus, IndividualMatches } from 'src/nswag';
import { BusinessResult, SelectBusinessResultCommand, IndividualResult, Gender, ScreeningClient, IndividualClient, BusinessClient } from 'src/nswag';
import { AddMatchedIndividualCommand, AddMatchedBusinessCommand, IndividualFinCrimeCheckResults, BusinessFinCrimeCheckResults, ClientResult } from 'src/nswag';
import { AlertService } from '../_alert';
import { ConfirmationDialogService } from '../_confirmation-dialog/ConfirmationDialog.service';
import { MatchesComponent } from './matches/matches.component';
import { CountriesService } from '../utils/countries.service';

enum NamePart {
  Name,
  FirstName,
  OtherName,
  LastName,
}

@Injectable({
  providedIn: 'root'
})

export class MatchesService {
  // Set to manage the loader spinner
  public isBusy = false;

  constructor(private modalService: NgbModal, private monitorClient: MonitoringClient, private confirmationService: ConfirmationDialogService, private alertService: AlertService, private fcClient: FinCrimeCheckClient, private screeningClient: ScreeningClient) { }

  public selectIndividualMatchFromNewClient(client: Client): Observable<ClientResult> {
    let s = new MonitoringNewIndividualMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(client, null);
  }

  public selectIndividualMatchFromClient(client: Client, profile: IndividualProfile): Observable<ClientResult> {
    let s = new MonitoringIndividualMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(client, profile);
  }

  public selectBusinessMatchFromNewClient(client: Client): Observable<ClientResult> {
    let s = new MonitoringNewBusinessMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(client, null);
  }

  public selectBusinessMatchFromClient(client: Client, profile: BusinessProfile): Observable<ClientResult> {
    let s = new MonitoringBusinessMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(client, profile);
  }

  public selectIndividualMatchForActor(actorId: string, dob: string, name: string, country: string, gender: Gender, isPremiumNode: boolean): Observable<IndividualResult> {
    let actorData: MatchActorIndData = {
      actorId: actorId,
      name: name,
      dateOfBirth: dob,
      gender: gender,
      country: country,
      isPremiumNode: isPremiumNode
    };
    let s = new SearchIndividualMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(actorData, null);
  }

  public selectBusinessMatchForActor(actorId: string, name: string, country: string, isPremiumNode: boolean): Observable<BusinessResult> {
    let actorData: MatchActorBusData = {
      actorId: actorId,
      name: name,
      country: country,
      isPremiumNode: isPremiumNode 
    };
    let s = new SearchBusinessMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(actorData, null);
  }

  // Searches for a potential match using a screening client
  public findIndividualMatchFromScreening(client: Client): Observable<ClientResult> {
    let s = new ScreeningNewIndividualMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(client, null);
  }
  public findBusinessMatchFromScreening(client: Client): Observable<ClientResult> {
    let s = new ScreeningNewBusinessMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(client, null);
  }

  // List Discounted matches for a Screening Client
  public selectIndividualMatchFromScreening(client: Client, profile: IndividualProfile): Observable<ClientResult> {
    let s = new ScreeningIndividualMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(client, profile);
  }
  public selectBusinessMatchFromScreening(client: Client, profile: BusinessProfile): Observable<ClientResult> {
    let s = new ScreeningBusinessMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    return s.selectMatch(client, profile);
  }

  public saveIndividualMatchForScreening(client: Client, resultsMediaId: string, match: IndividualMatch, matches: IndividualMatch[], viewedMoreMatches: boolean, observer: Observable<any>) {
    let s = new ScreeningNewIndividualMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    s.save(client, resultsMediaId, match, matches, viewedMoreMatches, observer);
  }

  public saveBusinessMatchForScreening(client: Client, resultsMediaId: string, match: BusinessMatch, matches: BusinessMatch[], viewedMoreMatches: boolean, observer: Observable<any>) {
    let s = new ScreeningNewBusinessMatching(this, this.modalService, this.monitorClient, this.confirmationService, this.alertService, this.fcClient, this.screeningClient);
    s.save(client, resultsMediaId, match, matches, viewedMoreMatches, observer);
  }
}

const matchTitle = "Match?";
const noMatchTitle = "No Matches?";
const matchMessage = "Do you want to set this as a match?";
const discountMessage = "Do you want to discount all the matches?";
const noMatchMessage = "Do you want to record that no matches were found?";
const matchSuccessMessage = "You have matched the subject to one of our profiles!";
const noMatchSuccessMessage = "No matches have been found for this subject!";
const discountSuccessMessage = "You have discounted ALL matches for this subject!";

abstract class MatchingBase {
  private modalRef: NgbModalRef;

  constructor(protected matchingService: MatchesService, protected modalService: NgbModal, protected monitorClient: MonitoringClient, protected confirmationService: ConfirmationDialogService, protected alertService: AlertService, protected fcClient: FinCrimeCheckClient, protected screeningClient: ScreeningClient) { }

  public abstract selectMatch(clientactor: any, profile: any): Observable<any>;

  protected abstract getMatches(command: any): Observable<any>;

  protected updateMatches(clientactor: any, profile: any, data: any) {
    if (profile) {
      if (data?.matches?.length > 0) {
        for (let match of data.matches) {
          if (!match.currentStatus) {
            if (match.resourceId == profile.resourceId) {
              match.currentStatus = "true-positive";
            }
            else {
              match.currentStatus = "false-positive";
            }
          }
        }
      }
    }
  }

  protected abstract get isBusiness(): boolean;

  protected abstract getSearchName(clientactor: any, namePart: NamePart): string;

  protected abstract get inMonitoring(): boolean;

  protected abstract getMatchResultsMediaId(result: any): string;

  protected abstract setMatch(clientactor: any, resultsMediaId: string, match: IndividualMatch, matches: IndividualMatch[], viewedMoreMatches: boolean): Observable<any>;

  protected getResponse(data: ClientResult) {
    return data;
  }

  protected showMatches(clientactor: any, profile: any): Observable<any> {
    let event = new Observable<any>((observer: any) => {
      this.modalRef = this.modalService.open(MatchesComponent);
      let actorClient = (clientactor instanceof Client) ? clientactor : new Client({
        // country/address is not populated due to varying location objects on actor types, need to consolidate
        individual: !this.isBusiness ? new IndividualClient({
          name: clientactor?.name,
          dateOfBirth: clientactor?.dateOfBirth,
          gender: clientactor?.gender
        }) : null,
        business: this.isBusiness ? new BusinessClient({
          name: clientactor?.name
        }) : null
      });
      this.modalRef.componentInstance.client = actorClient; // for ProfileRequest component input downstream

      this.getMatches(clientactor).subscribe(result => {
        if (result.isSuccess) {
          let resultsMediaId = this.getMatchResultsMediaId(result.data);
          this.updateMatches(clientactor, profile, result.data);
          let matches = result.data?.matches?.sort((a, b) => b.score - a.score);
          this.modalRef.componentInstance.threshold = result.data.threshold;
          if (this.isBusiness) {
            this.modalRef.componentInstance.businessMatches = matches;
            this.modalRef.componentInstance.init(this.inMonitoring);
            this.modalRef.componentInstance.searchName = this.getSearchName(clientactor, NamePart.Name);
            this.modalRef.componentInstance.businessMatch.subscribe(match => {
              this.userConfirmMatch(clientactor, resultsMediaId, match, matches, this.modalRef.componentInstance.viewedMoreMatches, observer);
            });
          }
          else {
            this.modalRef.componentInstance.individualMatches = matches;
            this.modalRef.componentInstance.init(this.inMonitoring);
            this.modalRef.componentInstance.searchName = this.getSearchName(clientactor, NamePart.Name);
            this.modalRef.componentInstance.searchFirstName = this.getSearchName(clientactor, NamePart.FirstName);
            this.modalRef.componentInstance.searchLastName = this.getSearchName(clientactor, NamePart.LastName);
            this.modalRef.componentInstance.searchOtherName = this.getSearchName(clientactor, NamePart.OtherName);
            this.modalRef.componentInstance.individualMatch.subscribe(match => {
              this.userConfirmMatch(clientactor, resultsMediaId, match, matches, this.modalRef.componentInstance.viewedMoreMatches, observer);
            });
          }
        }
      });
    });
    return event;
  }

  protected userConfirmMatch(clientactor: any, resultsMediaId: string, match: any, matches: any[], viewedMoreMatches: boolean, observer: any) {
    let title = (match ? matchTitle : noMatchTitle);
    let message = (match ? matchMessage : (matches?.length > 0 ? discountMessage : noMatchMessage));
    this.confirmationService.confirm(title, message, true, "Ok", "Cancel", "sm", true)
    .then(r => {
      if (r) {
        this.saveMatch(clientactor, resultsMediaId, match, matches, viewedMoreMatches, observer);
      }
    })
    .catch((onRejected) => { /* modal closed */ });
  }

  protected saveMatch(clientactor: any, resultsMediaId: string, match: any, matches: any[], viewedMoreMatches: boolean, observer: any) {
    this.matchingService.isBusy = true;
    // trim white space
    this.setMatch(clientactor, resultsMediaId, match, matches?.length > 0 ? matches : null, viewedMoreMatches).subscribe(r => {
      this.matchingService.isBusy = false;
      if (r.isSuccess) {
        this.alertService.success((match ? matchSuccessMessage : (matches?.length > 0 ? discountSuccessMessage : noMatchSuccessMessage)), { autoClose: true });
        this.close();
        observer.next(this.getResponse(r.data));
      }
    },
      (error) => {
        console.log(error.response);
        this.matchingService.isBusy = false;
      });
  }

  public close() {
    this.modalRef?.close();
  }
}

//////////////////////////////////////////////
/// Monitoring
//////////////////////////////////////////////
class MonitoringNewIndividualMatching extends MatchingBase {
  protected get isBusiness(): boolean {
    return false;
  }

  protected getSearchName(client: Client, namePart: NamePart): string {
    switch (namePart) {
      case NamePart.Name:
        return client.individual?.name;
        break;
      case NamePart.FirstName:
        return client.individual?.firstName;
        break;
      case NamePart.OtherName:
        return client.individual?.otherName;
        break;
      case NamePart.LastName:
        return client.individual?.lastName;
        break;
      default:
        return client.individual?.name;
        break;
    }
  }

  protected get inMonitoring(): boolean {
    return true;
  }

  public selectMatch(client: Client, profile: IndividualProfile): Observable<ClientResult> {
    return super.showMatches(client, profile);
  }

  protected getMatches(client: Client): Observable<any> {
    let individual = client.individual;
    return this.fcClient.searchIndividualByName(UtilityService.getCodeFromNationality(individual.nationality), individual.dateOfBirth, individual.firstName, individual.gender, individual.lastName, individual.name, individual.otherName);
  }

  protected getMatchResultsMediaId(result: IndividualFinCrimeCheckResults): string {
    return result.resultsMediaId;
  }

  protected setMatch(client: Client, resultsMediaId: string, match: IndividualMatch, matches: IndividualMatch[], viewedMoreMatches: boolean): Observable<any> {
    let command = new SelectIndividualMatchCommand({
      nodeId: client.id,
      resourceId: match?.resourceId,
      notes: this.confirmationService.reason,
      resultsMediaId: resultsMediaId,
      viewedMoreMatches: viewedMoreMatches,
    });
    return this.monitorClient.selectIndividualMatch(command, client.id);
  }
}

class MonitoringIndividualMatching extends MatchingBase {
  protected get isBusiness(): boolean {
    return false;
  }

  protected getSearchName(client: Client, namePart: NamePart): string {
    switch (namePart) {
      case NamePart.Name:
        return client.individual?.name;
        break;
      case NamePart.FirstName:
        return client.individual?.firstName;
        break;
      case NamePart.OtherName:
        return client.individual?.otherName;
        break;
      case NamePart.LastName:
        return client.individual?.lastName;
        break;
      default:
        return client.individual?.name;
        break;
    }
  }

  protected get inMonitoring(): boolean {
    return true;
  }

  public selectMatch(client: Client, profile: IndividualProfile): Observable<ClientResult> {
    return super.showMatches(client, profile);
  }

  protected getMatchResultsMediaId(result: IndividualMatches): string {
    return result.resultsMediaId;
  }

  protected getMatches(client: Client): Observable<any> {
    return this.monitorClient.getIndividualMatches(client.id, client.monitorRecordId);
  }

  protected setMatch(client: Client, resultsMediaId: string, match: IndividualMatch, matches: IndividualMatch[], viewedMoreMatches: boolean): Observable<any> {
    let command = new SelectIndividualMatchCommand({
      nodeId: client.id,
      resourceId: match?.resourceId,
      notes: this.confirmationService.reason,
      resultsMediaId: resultsMediaId,
      // viewedMoreMatches: viewedMoreMatches
    });
    return this.monitorClient.selectIndividualMatch(command, client.id);
  }
}

class MonitoringNewBusinessMatching extends MatchingBase {
  protected get isBusiness(): boolean {
    return true;
  }

  protected getSearchName(client: Client): string {
    return client.business?.name;
  }

  protected get inMonitoring(): boolean {
    return true;
  }

  public selectMatch(client: Client, profile: BusinessProfile): Observable<ClientResult> {
    return super.showMatches(client, profile);
  }

  protected getMatches(client: Client): Observable<any> {
    let business = client.business;
    return this.fcClient.searchBusinessByName(UtilityService.getCodeFromCountry(business.jurisdiction), business.name);
  }

  protected getMatchResultsMediaId(result: BusinessFinCrimeCheckResults): string {
    return result.resultsMediaId;
  }

  protected setMatch(client: Client, resultsMediaId: string, match: BusinessMatch, matches: BusinessMatch[], viewedMoreMatches: boolean): Observable<any> {
    let command = new SelectBusinessMatchCommand({
      nodeId: client.id,
      resourceId: match?.resourceId,
      notes: this.confirmationService.reason,
      resultsMediaId: resultsMediaId,
      viewedMoreMatches: viewedMoreMatches,
    });
    return this.monitorClient.selectBusinessMatch(command, client.id);
  }
}

class MonitoringBusinessMatching extends MonitoringNewBusinessMatching {
  protected getMatches(client: Client): Observable<ClientResult> {
    return this.monitorClient.getBusinessMatches(client.id, client.monitorRecordId);
  }
  protected getMatchResultsMediaId(result: BusinessMatches): string {
    return result.resultsMediaId;
  }
}

//////////////////////////////////////////////
// Search
//////////////////////////////////////////////
class MatchActorBaseData {
  actorId: string;
  name: string;
  country: string;
  isPremiumNode: boolean = false;
}

class MatchActorBusData extends MatchActorBaseData {
}

class MatchActorIndData extends MatchActorBaseData {
  dateOfBirth: string;
  gender: Gender;
}

class SearchIndividualMatching extends MatchingBase {
  protected get isBusiness(): boolean {
    return false;
  }

  protected getSearchName(actorData: MatchActorIndData): string {
    return actorData.name;
  }

  protected get inMonitoring(): boolean {
    return false;
  }

  public selectMatch(actorData: MatchActorIndData, profile: IndividualProfile): Observable<IndividualMatch> {
    return super.showMatches(actorData, profile);
  }

  protected getMatches(actorData: MatchActorIndData): Observable<any> {
    return this.fcClient.searchIndividualByName(UtilityService.getCodeFromCountry(actorData.country), actorData.dateOfBirth, null, actorData.gender, null, actorData.name, null);
  }

  protected getMatchResultsMediaId(result: IndividualFinCrimeCheckResults): string {
    return result.resultsMediaId;
  }

  protected setMatch(actorData: MatchActorIndData, resultsMediaId, match: IndividualMatch, matches: IndividualMatch[], viewedMoreMatches: boolean): Observable<any> {
    let command = new SelectIndividualResultCommand({
      actorId: actorData.actorId,
      profileId: match?.resourceId,
      resultsMediaId: resultsMediaId,
      isPremiumActor: actorData.isPremiumNode,
      viewedMoreMatches: viewedMoreMatches
    });
    return this.fcClient.selectIndividualResult(command);
  }
}

class SearchBusinessMatching extends MatchingBase {
  protected get isBusiness(): boolean {
    return true;
  }

  protected getSearchName(actorData: MatchActorBusData): string {
    return actorData.name;
  }

  protected get inMonitoring(): boolean {
    return false;
  }

  public selectMatch(actorData: MatchActorBusData, profile: BusinessProfile): Observable<BusinessMatch> {
    return super.showMatches(actorData, profile);
  }

  protected getMatches(actorData: MatchActorBusData): Observable<any> {
    return this.fcClient.searchBusinessByName(actorData.country, actorData.name);
  }

  protected getMatchResultsMediaId(result: BusinessFinCrimeCheckResults): string {
    return result.resultsMediaId;
  }

  protected setMatch(actorData: MatchActorBusData, resultsMediaId: string, match: BusinessMatch, matches: BusinessMatch[], viewedMoreMatches: boolean): Observable<any> {
    let command = new SelectBusinessResultCommand({
      actorId: actorData.actorId,
      profileId: match?.resourceId,
      resultsMediaId: resultsMediaId,
      isPremiumActor: actorData.isPremiumNode,
      viewedMoreMatches: viewedMoreMatches
    });
    return this.fcClient.selectBusinessResult(command);
  }
}

//////////////////////////////////////////////
/// Screening
//////////////////////////////////////////////
class ScreeningNewIndividualMatching extends MonitoringNewIndividualMatching {
  protected get isBusiness(): boolean {
    return false;
  }

  protected get inMonitoring(): boolean {
    return false;
  }

  // Screening just needs us to save the match
  public save(ind: IndividualClient, resultsMediaId: string, match: IndividualMatch, matches: IndividualMatch[], viewedMoreMatches: boolean, observer: any) {
    super.userConfirmMatch(ind, resultsMediaId, match, matches, viewedMoreMatches, observer);
  }

  protected getMatchResultsMediaId(result: IndividualFinCrimeCheckResults): string {
    return result.resultsMediaId;
  }

  protected setMatch(ind: IndividualClient, resultsMediaId: string, match: IndividualMatch, matches: IndividualMatch[], viewedMoreMatches: boolean): Observable<any> {

    let command = new AddMatchedIndividualCommand({
      firstName: ind.firstName,
      otherName: ind.otherName,
      lastName: ind.lastName,
      name: ind.name,
      clientRef: ind.clientRef,
      dateOfBirth: ind.dateOfBirth,
      gender: ind.gender,
      nationality: CountriesService.getNationalityCode(ind.nationality),
      resourceId: match?.resourceId,
      notes: this.confirmationService.reason,
      resultsMediaId: resultsMediaId,
      tags: ind?.tags,
      viewedMoreMatches: viewedMoreMatches,
    });
    return this.screeningClient.addMatchedIndividual(command);
  }
}

class ScreeningNewBusinessMatching extends MonitoringNewBusinessMatching {
  protected get isBusiness(): boolean {
    return true;
  }

  protected get inMonitoring(): boolean {
    return false;
  }

  // Screening just needs us to save the match
  public save(bus: BusinessClient, resultsMediaId: string, match: BusinessMatch, matches: BusinessMatch[], viewedMoreMatches: boolean, observer: any) {
    super.userConfirmMatch(bus, resultsMediaId, match, matches, viewedMoreMatches, observer);
  }

  protected getMatchResultsMediaId(result: IndividualFinCrimeCheckResults): string {
    return result.resultsMediaId;
  }

  protected setMatch(bus: BusinessClient, resultsMediaId: string, match: BusinessMatch, matches: BusinessMatch[], viewedMoreMatches: boolean): Observable<any> {
    
    let command = new AddMatchedBusinessCommand({
      name: bus.name,
      clientRef: bus.clientRef,
      jurisdiction: CountriesService.getJurisdictionCode(bus.jurisdiction),
      resourceId: match?.resourceId,
      notes: this.confirmationService.reason,
      resultsMediaId: resultsMediaId,
      tags: bus?.tags,
      viewedMoreMatches: viewedMoreMatches,
    });
    return this.screeningClient.addMatchedBusiness(command);
  }
}

class ScreeningBusinessMatching extends MatchingBase {
  protected get isBusiness(): boolean {
    return true;
  }

  protected getSearchName(client: Client): string {
    return client.business?.name;
  }

  protected get inMonitoring(): boolean {
    return false;
  }

  public selectMatch(client: Client, profile: BusinessProfile): Observable<BusinessMatch> {
    return super.showMatches(client, profile);
  }

  protected getMatches(client: Client): Observable<any> {
    return this.screeningClient.getBusinessMatches(client.id, client.monitorRecordId);
  }
  // TODO sotr out the data type
  protected getMatchResultsMediaId(result: any): string {
    return result?.resultsMediaId;
  }

  protected setMatch(client: Client, resultsMediaId: string, match: BusinessMatch, matches: BusinessMatch[], viewedMoreMatches: boolean): Observable<any> {
    let command = new SelectBusinessMatchCommand({
      nodeId: client.id,
      resourceId: match?.resourceId,
      notes: this.confirmationService.reason,
      resultsMediaId: resultsMediaId,
      viewedMoreMatches: viewedMoreMatches,
    });
    return this.screeningClient.selectBusinessMatch(command, client.id);
  }
}

class ScreeningIndividualMatching extends MatchingBase {
  protected get isBusiness(): boolean {
    return false;
  }

  protected getSearchName(client: Client, namePart: NamePart): string {
    switch (namePart) {
      case NamePart.Name:
        return client.individual?.name;
        break;
      case NamePart.FirstName:
        return client.individual?.firstName;
        break;
      case NamePart.OtherName:
        return client.individual?.otherName;
        break;
      case NamePart.LastName:
        return client.individual?.lastName;
        break;
      default:
        return client.individual?.name;
        break;
    }
  }

  protected get inMonitoring(): boolean {
    return false;
  }

  public selectMatch(client: Client, profile: IndividualProfile): Observable<IndividualMatch> {
    return super.showMatches(client, profile);
  }

  protected getMatches(client: Client): Observable<any> {
    return this.screeningClient.getIndividualMatches(client.id, client.monitorRecordId);
  }
  protected getMatchResultsMediaId(result: any): string {
    return result?.resultsMediaId;
  }
  protected setMatch(client: Client, resultsMediaId: string, match: IndividualMatch, matches: IndividualMatch[], viewedMoreMatches: boolean): Observable<any> {
    let command = new SelectIndividualMatchCommand({
      nodeId: client.id,
      resourceId: match?.resourceId,
      notes: this.confirmationService.reason,
      resultsMediaId: resultsMediaId,
      viewedMoreMatches: viewedMoreMatches
    });
    return this.screeningClient.selectIndividualMatch(command, client.id);
  }
}
