<ng-template [ngTemplateOutlet]="person"></ng-template>

<ng-template #person>
    <ng-template [ngTemplateOutlet]="displayPersonHeader"></ng-template>
    <ng-template [ngTemplateOutlet]="displayPersonDetail"></ng-template>
</ng-template>

<ng-template #displayPersonHeader>
  <div class="row">
    <div class="avatar-container">
        <label class="btn btn-default">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus bg-secondary" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
          </svg>
          <input id="fileInput" type='file' class="btn form-control" accept=".jpg,.jpeg,.png"  (change)="onSelectFile($event)" hidden>
          <div *ngIf="dashService.getInvestigation()?.profileImageId; else displayPersonLAMPSAvatar">
            <img onerror="src='/assets/images/EDD-Avatar-individual.png'" [src]="'/media/'+dashService.getInvestigation()?.profileImageId+'' | secure | async" class="center avatar-image">
          </div>
          <ng-template #displayPersonLAMPSAvatar>
            <img class="center avatar-image" src="/assets/images/EDD-Avatar-individual.png"/>
          </ng-template>
        </label>
    </div>
    <h4 class="text-center">{{dashService.getInvestigation()?.name}}</h4>
  </div>
</ng-template>

<ng-template #displayPersonDetail>
  <div class="container" *ngIf="dashService.getInvestigation().infoObject as subject">
    <div class="row">
      <div class="col">
        <div class="row">
          <label>Full Name</label>
        </div>
        <div class="row">
            <input type="text" class="form-control" [(ngModel)]='subject.name' (ngModelChange)="onEdit('Full Name', subject.name)">
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Date of Birth</label>
        </div>
        <div class="row" id="uniquePersonSubjectDate">
          <app-date-picker class="p-0" (dataEvent)="dateChanged($event)" [dateOfBirth]="subject.dateOfBirth"></app-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row">
          <label>Nationality / Country of Residence</label>
        </div>
        <div class="row">
            <input type="text" class="form-control" id="uniquePersonEditNation" [(ngModel)]='subject.nationality' (ngModelChange)="onEdit('Nationality', subject.nationality)" [ngbTypeahead]="nationalityTypeAhead">
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Gender</label>
        </div>
        <div class="row">
            <select class="form-select" id="uniqueSubEdit" [(ngModel)]="subject.gender" (ngModelChange)="onEdit('Gender', subject.gender)">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Unknown">Unknown</option>
            </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="row">
          <label>Address</label>
        </div>
        <div class="row">
            <input type="text" class="form-control" [(ngModel)]='subject.address.fullAddress' (ngModelChange)="onEdit('Full Address', subject.address.fullAddress)"> 
        </div>
      </div>
      <div class="col">
        <div class="row">
          <label>Jurisdiction</label>
        </div>
        <div class="row">
            <input id="jurisdiction" [(ngModel)]="subject.jurisdictionName" [ngbTypeahead]="searchService.jurisdictions" class="form-control" (ngModelChange)="onEdit('Jurisdiction', subject.jurisdictionName)"/>
        </div>
      </div>
    </div>
  </div>
</ng-template>
