import { Component, Input, SimpleChanges } from '@angular/core';
import { IndividualMatch, LAMPSStatus } from 'src/nswag';
import { UtilityService } from '../../../utilitities/utilities';
import { BaseReportSectionComponent } from '../base-client-section.component';

@Component({
  selector: 'mon-individual-matches-section',
  templateUrl: './individual-matches-section.component.html',
  styleUrls: ['./individual-matches-section.component.scss']
})
export class IndividualMatchesSectionComponent extends BaseReportSectionComponent {

  public title: string;
  @Input() individualMatches: IndividualMatch[];

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (this.matchStatus?.isMatched == LAMPSStatus.Discounted) {
      this.title = "Discounted Matches";
    }
    else if(this.matchStatus?.matches == LAMPSStatus.Changed) {      
      this.title = "New Matches";
    }
    else {      
      this.title = "Matches";
    }
  }
  
  isPEP(match: IndividualMatch) : boolean {
    if(!match.datasets) return false;
    return UtilityService.isPEP(match.datasets);
  }

  isFormerPEP(match: IndividualMatch) : boolean {
    if(!match.datasets) return false;
    return UtilityService.isFormerPEP(match.datasets);
  }

  isSanction(match: IndividualMatch) : boolean {
    if(!match.datasets) return false;
    return UtilityService.isSanction(match.datasets);
  }
  isFormerSanction(match: IndividualMatch) : boolean {
    if(!match.datasets) return false;
    return UtilityService.isFormerSanction(match.datasets);
  }
  hasLawEnforcement(match: IndividualMatch) : boolean {
    if(!match.datasets) return false;
    return UtilityService.isLawEnforcement(match.datasets);
  }
  hasAdverseMedia(match: IndividualMatch) : boolean {
    if(!match.datasets) return false;
    return UtilityService.hasAdverseMedia(match.datasets);
  }

  getCountries(match: IndividualMatch) : string {
    return UtilityService.getCountriesListFromCodes(match?.countries);
  }

}
