import Step from 'shepherd.js/src/types/step';

export const builtInButtons = {
    cancel: {
        classes: 'cancel-button',
        secondary: true,
        text: 'Exit',
        type: 'cancel'
    },
    next: {
        classes: 'next-button',
        text: 'Next',
        type: 'next'
    },
    back: {
        classes: 'back-button',
        secondary: true,
        text: 'Back',
        type: 'back'
    }
};

export const defaultStepOptions: Step.StepOptions = {
    classes: 'shepherd-theme-arrows custom-default-class',
    scrollTo: true,
    cancelIcon: {
        enabled: true
    }
};

export const steps: Step.StepOptions[] = [
    {
        attachTo: {
            element: null,
            on: 'bottom'
        },
        buttons: [
            builtInButtons.cancel,
            builtInButtons.next
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        id: 'intro',
        title:`
            <b>Welcome to Themis Risk Assessmment!</b>
                `,
        text:   `
            <p style="display: flex; justify-content: center;">
                <img src="/assets/images/tour-guide.jpg" class="avatar-image" width="400px" />
            <p>    
            <p>
                Let&rsquo;s start with a quick guided product tour and we will have you up and running in no time!
            </p>`
    },
    {
        attachTo: {
            element: '.add-client-tour',
            on: 'top'
        },
        buttons: [
            builtInButtons.cancel,
            builtInButtons.back,
            builtInButtons.next
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        id: 'installation',
        title: 'Create Assessment',
        text: 'Create a new assessment, easily and quickly.'
    },
    {
        attachTo: {
            element: '#searchFieldMain',
            on: 'bottom'
        },
        buttons: [
            builtInButtons.cancel,
            builtInButtons.back,
            builtInButtons.next
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        id: 'intro',
        title: 'Enter your Search Criteria',
        text: `
            <p>
                Input the name of the assessment you wish to find from your previously created assessment list.
            </p>`
    },
    {
        attachTo: {
            element: '.search-btn-new',
            on: 'bottom'
        },
        buttons: [
            builtInButtons.cancel,
            builtInButtons.back,
            builtInButtons.next
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        id: 'installation',
        title: 'Search Button',
        text: 'Click the search button to begin your search. Any matching assessment will be displayed below.'
    },
    {
        attachTo: {
            element: '.detail-table',
            on: 'top'
        },
        buttons: [
            builtInButtons.cancel,
            builtInButtons.back,
            builtInButtons.next
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        id: 'modal',
        title: 'Assessment List',
        text: `
            <p>
                This displays a list of assessments that have been created. You can navigate the list of assessment pages using the control panel at the bottom of the list.
            </p>`
    },
    {
        attachTo: {
            element: '.align-middle',
            on: 'top'
        },
        buttons: [
            builtInButtons.cancel,
            builtInButtons.back,
            builtInButtons.next
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        id: 'installation',
        title: 'Manage Recipient',
        text: 'Click each row in the assessment list to view or add new/existing recipients.'
    },
    {
        attachTo: {
            element: '#assessment-list',
            on: 'top'
        },
        buttons: [
            builtInButtons.cancel,
            builtInButtons.back,
            builtInButtons.next
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        id: 'installation',
        title: 'Action Menu',
        text: 'Click the three dots to get the drop-down menu. This will display the various actions that you can take.'
    },
    {
        attachTo: {
          element: '.tour-flag',
          on: 'top'
        },
        buttons: [
          builtInButtons.cancel,
          builtInButtons.back,
          builtInButtons.next
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        id: 'installation',
        title: 'Tour Guide',
        text: 'If at any time you need to access this tour guide. Simply click the flag button here.'
    },

    {
        buttons: [
            builtInButtons.cancel,
            builtInButtons.back
        ],
        id: 'noAttachTo',
        title: 'Well done!',
        classes: 'custom-class-name-1 custom-class-name-2',
        text: 'You can now continue assessing. Press the Exit button to begin.'
    }
];
