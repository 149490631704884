
<div *ngIf="showMain">
    <div class="mt-3">
        <div class="row">
            <div class="col archive-background">
                <div class="col col-3 col-sm-6">
                    <a role="button" class="btn btn-link" (click)="backClicked()">
                      <span class="icon-arrow-left"></span> Back 
                    </a>
                </div>
                <br>
                <div class="row mb-4 search-colum">
                    <div class="col">
                        <h3>Client Archive</h3> 
                    </div>

                    <div class="col-6 search-column">
                        <mon-search (searchData)="setSearch($event)" (performSearch)="searchClient()"></mon-search>    
                    </div>
                    <div class="col-1"><a class="advanced mt-2 text-center col-1" (click)="displaySearchOptions()">Advanced</a></div>
                </div>
                <div class="row">
                    <div class="col">
                        <div *ngIf="showAdvancedOptions" [@inOutAnimation]>
                            <advanced-search-options [advancedQuery]="advancedSearchOptions" (searchData)="setSearchOptions($event)"></advanced-search-options>
                        </div>
                    </div>
                </div>
                <div class="row detail-table">
                    <mon-list
                        [clientList]="service.ClientList"
                        [clientTotal]="service.ClientListTotal"
                        [currentPage]="service.ClientListPage"
                        [allowEdit]="false"
                        [isArchived]="true"
                        (showHistoryReportEvent)="viewHistoryReport($event)"
                        (showClientReportEvent)="null"
                        (recoverEvent)="recoverClient($event)"
                        (selectEvent)="selectClient($event)"
                        (changePageEvent)="changePage($event)">
                    </mon-list>

                    <div class="d-flex justify-content-end sc-export">
                        <mon-export-clients [showArchived]="true" [currentModule]="currentModule" [exportClientListCallBack]="service.exportClientList" [clientTotal]="service.ClientListTotal"></mon-export-clients>
                    </div>
                </div>
            </div>
            <div *ngIf="service.SelectedClient" class="col-4 detail-data">
                <mon-detail
                    [client]="service.SelectedClient"
                    [profile]="service.SelectedProfile"
                    [allowDiscounting]="false"
                    [isArchived]="true"
                    [currentModule]="currentModule"
                    (clientChange)="clientChange($event)"
                    (addToMonitoringEvent)="null"
                    (searchMatchesEvent)="null"
                    (viewMatchesEvent)="null">
                </mon-detail>
            </div>
        </div>
    </div>
</div>


<mon-clienthistory [showReport]="showHistoryReport" [auditLogs]="auditLogs" (hide)="closeHistoryReport($event)" [clientObject]="currentClient"></mon-clienthistory>
