<div id="investigations-tabs">
    <div class="float-end">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
        </button>
    </div>
    <ul ngbNav #nav="ngbNav" class="nav-tabs flex-nowrap">
        <li [ngbNavItem]="0" (click)="setTabAsClicked(0)">
            <a ngbNavLink><span title="Profile" aria-label="Profile" class="icon-profile"></span></a>
            <ng-template ngbNavContent>
                <div class="card-body  overflow-auto text-wrap text-break text-break">
                    <app-idv-profile-detail [idvProfile]="idvProfile" [clientResult]="clientResult"></app-idv-profile-detail>
                </div>
            </ng-template>
        </li>
        <li [ngbNavItem]="1" (click)="setTabAsClicked(1)">
            <a ngbNavLink><span title="Documents" aria-label="Documents" class="icon-file-image"></span></a>
            <ng-template ngbNavContent>
                <div class="card-body  overflow-auto text-wrap text-break text-break">
                    <app-idv-docs-detail [idvProfile]="idvProfile" [clientResult]="clientResult"></app-idv-docs-detail>
                </div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
