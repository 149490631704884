<div class="container">
  <div class="modal-header">
    <h4 class="col">{{TitleText}} Assessment</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row mt-2 required-input">
        <label>Title</label>
      </div>
      <div class="row">
        <div class="input-group required-name">
          <input (ngModelChange)="resetDuplicateNameFlag()" type="text" required minlength="2" name="name" class="form-control" [(ngModel)]="assessment.name" #name="ngModel">
        </div>
        <div *ngIf="name.invalid || duplicateName" class="row">
          <div *ngIf="(!name.value || name.errors?.required) && name.touched" class="form-control alert alert-danger">
            Title is required.
          </div>
          <div *ngIf="name.errors?.minlength" class="form-control alert alert-danger">
            Title must be at least 2 characters long.
          </div>
          <div *ngIf="duplicateName" class="form-control alert alert-danger">
            Title already exists, title must be unique.
          </div>
        </div>

      </div>
      <div class="row mt-2">
        <label>Description</label>
      </div>
      <div class="row">
        <div class="input-group">
          <textarea class="form-control" [(ngModel)]="assessment.description"></textarea>
        </div>
      </div>
      <div class="row mt-2 required-input">
        <label>Industry</label>
      </div>
      <div class="row">
        <div class="input-group client-nationality">
          <input
            id="industry"
            [(ngModel)]="industry"
            [ngbTypeahead]="industryTypeAhead"
            class="form-control"
            (focus)="onFocus($event)"
            (blur)="onBlur($event)"
            name="inv"
            #inv="ngModel"
            required />
        </div>
        <div *ngIf="(inv.invalid || !isIndustryValid()) && (inv.dirty || inv.touched) && !industryFocus" class="row">
          <div *ngIf="inv.errors?.required" class="form-control alert alert-danger">
            Industry is required.
          </div>
          <div *ngIf="!inv.invalid && !isIndustryValid()" class="form-control alert alert-danger">
            Valid Industry is required.
          </div>
        </div>
      </div>
      <div *ngIf="industry === 'Other'">
        <div class="row mt-2 required-input">
          <label>If Other, please specify</label>
        </div>
        <div class="row">
          <div class="input-group required-name">
            <input type="text" required minlength="2" name="invOther" #invOther="ngModel" class="form-control" [(ngModel)]="assessment.industryVerticalsOther">
          </div>
          <div *ngIf="invOther.invalid && (invOther.dirty || invOther.touched)" class="row">
            <div *ngIf="invOther.errors?.required" class="form-control alert alert-danger">
              Other is required.
            </div>
            <div *ngIf="invOther.errors?.minlength" class="form-control alert alert-danger">
              Other must be at least 2 characters long.
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <label>Email invitation message <span class="icon-question-circle ms-1" title="Tour Guide" [ngbTooltip]="showEmailToolTip"></span></label>
      </div>
      <div class="row">
        <div class="input-group">
          <textarea class="form-control" maxlength="500" rows="3" [(ngModel)]="assessment.emailMessage"></textarea>
        </div>
      </div>
      <div class="row mt-2">
        <label>Welcome page message <span class="icon-question-circle ms-1" title="Tour Guide" [ngbTooltip]="showWelcomeToolTip"></span></label>
      </div>
      <div class="row">
        <div class="input-group">
          <textarea class="form-control" maxlength="500" rows="3" [(ngModel)]="assessment.welcomeMessage"></textarea>
        </div>
      </div>
    </div>
    <div class="float-end p-2">
      <button class="btn m-1 bg-secondary text-light" id="uniqueCancelAssDialog" (click)="dismissModal()">Cancel</button>
      <button id="uniqueAddAssClient" class="btn m-1 bg-primary text-light" [disabled]="!canCreate()" (click)="createAssessment()"> 
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm btn-padding"></span>
        <span *ngIf="!isSaving">{{ButtonText}}</span>
      </button>
    </div>
  </div>
</div>

<!-- Templates for tour guide -->
<ng-template #showEmailToolTip>
  <app-assessment-tooltip [toolTipTemplate]="'emailToolTip'"></app-assessment-tooltip>
</ng-template>

<ng-template #showWelcomeToolTip>
  <app-assessment-tooltip [toolTipTemplate]="'welcomeToolTip'"></app-assessment-tooltip>
</ng-template>
