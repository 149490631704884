<button class="btn request-btn btn-yellow-bg" (click)="launch()" title="Request a report">Request a Report&nbsp;
    <svg _ngcontent-ng-cli-universal-c189="" version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g _ngcontent-ng-cli-universal-c189="" transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path _ngcontent-ng-cli-universal-c189="" d="M3025 5114 c-114 -11 -229 -28 -305 -46 -362 -82 -687 -263 -946
        -523 -301 -304 -477 -648 -556 -1085 -8 -49 -13 -155 -12 -310 0 -216 2 -246
        27 -367 66 -318 211 -624 411 -867 l57 -70 -74 -73 -73 -74 -51 34 c-97 66
        -216 69 -324 11 -31 -17 -228 -208 -591 -572 -607 -609 -583 -579 -583 -717 0
        -107 32 -165 158 -292 110 -109 172 -147 259 -159 63 -9 143 12 203 52 75 50
        1098 1079 1124 1132 52 104 46 223 -16 315 l-34 51 73 73 73 73 73 -60 c254
        -207 543 -341 889 -412 94 -20 139 -22 353 -23 267 0 344 11 560 75 1068 317
        1654 1463 1289 2520 -235 679 -847 1188 -1556 1295 -103 15 -353 26 -428 19z
        m352 -219 c599 -79 1111 -450 1364 -990 246 -525 218 -1144 -75 -1639 -271
        -458 -742 -772 -1271 -847 -113 -16 -375 -16 -480 0 -489 75 -905 330 -1192
        729 -78 108 -183 315 -227 447 -164 486 -106 1024 157 1462 358 597 1038 927
        1724 838z m-1999 -3329 c41 -22 190 -179 197 -209 4 -15 4 -39 0 -55 -7 -30
        -1020 -1053 -1067 -1078 -62 -32 -84 -22 -195 89 -100 100 -103 105 -103 147
        l0 44 518 519 c284 286 531 528 547 538 36 23 66 24 103 5z"></path><path _ngcontent-ng-cli-universal-c189="" d="M4177 4182 c-37 -37 -67 -71 -67 -76 0 -5 24 -34 53 -65 103 -112
        215 -306 265 -461 76 -232 87 -499 32 -733 -50 -209 -150 -403 -294 -571 l-59
        -68 72 -72 72 -71 51 55 c418 449 518 1140 247 1706 -57 120 -144 253 -229
        349 -34 39 -65 71 -68 73 -4 1 -38 -28 -75 -66z"></path></g></svg>
</button>