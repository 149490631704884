import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-discounted-links',
  templateUrl: './discounted-links.component.html',
  styleUrls: ['./discounted-links.component.scss']
})
export class DiscountedLinksComponent implements OnChanges {

  @Input() profile: any;
  @Input() incHeader = true;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
