import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DatePickerResult } from 'src/app/date-picker/date-picker.component';
import { FeatureState } from 'src/app/enums';
import { MatchesService } from 'src/app/matching-service/matches.service';
import { CountriesService } from 'src/app/utils/countries.service';
import { ProfileService } from 'src/app/profile.service';
import { SelfServiceService } from 'src/app/self-service/services/self-service.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { Address2, BusinessClient, BusinessMatch, Client, ClientResult, FinCrimeCheckClient, Gender, IndividualClient, IndividualMatch, MonitorType } from 'src/nswag';
import { ScreeningService } from '../services/screening.service';
import { SelfServiceScreenDialogComponent } from 'src/app/self-service/self-service-screen-dialog/self-service-screen-dialog.component';
import { ErrorService } from 'src/app/services/error.service';


@Component({
  selector: 'app-screen-client-dialog',
  templateUrl: './screen-client-dialog.component.html',
  styleUrls: ['./screen-client-dialog.component.scss'],
  providers: [ErrorService]
})
export class ScreenClientDialogComponent implements OnInit {
  public isBusiness = false;
  public isAdvancedSearch = false;
  @Input() nationality: string;
  @Input() jurisdiction: string;
  @Input() tags: string[] = [];
  @Input() screenName: string;
  @Input() screenFirstName: string;
  @Input() screenLastName: string;
  @Input() screenOtherName: string;
  @Input() clientRef: string;
  @Input() gender: Gender;
  @Input() dateOfBirth: string;
  // Passed in from IDV
  @Input() address: Address2;

  @Output() addClientEvent = new EventEmitter<ClientResult>();

  // Set as soon as screening has been run - used to deal with canceling without saving data
  // Note this is used externally by the caller of the modal that surrounds this.
  public hasScreeningRun = false;

  // Used for spinner when searching
  public isSearching = false;

  public ngbDate: NgbDateStruct;

  public individualMatches: IndividualMatch[];
  public businessMatches: BusinessMatch[];

  private resultsMediaId: string;
  public threshold: number;

  public clientExists: boolean;
  public nationalityFocus: boolean = false;
  public jurisdictionFocus: boolean = false;
  public isSelfServeUser: boolean = false;
  public viewedMoreMatches: boolean = false;

  // Copies of the inputs that are passed through to stop them changing in child component.
  public screenNameCopy: string;
  public screenFirstNameCopy: string;
  public screenLastNameCopy: string;
  public screenOtherNameCopy: string;
  public client: Client;

  constructor(private activeModal: NgbActiveModal, private dateFormatter: NgbDateParserFormatter, private fcClient: FinCrimeCheckClient, private matchesService: MatchesService, private screeningService: ScreeningService, private modalService: NgbModal, private profileService: ProfileService, private selfServeService: SelfServiceService, private errorService: ErrorService) { }
  
  updateClientInputModel(): void {
    if (this.isBusiness) {
      this.client.business = this.getBusiness();
    } else {
      this.client.individual = this.getIndividual();
    }
  }

  ngOnInit(): void {
    this.client = new Client;
    this.isSelfServeUser = this.profileService.isSelfServeUser();
    if (this.dateOfBirth) {
      var date = new Date(this.dateOfBirth);
      this.ngbDate = { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
    }
  }

  get isBusy() : boolean {
    return this.matchesService.isBusy;
  } 

  setViewedMoreMatches() {
    this.viewedMoreMatches = true;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  isIndividualNameValid(): boolean {
    if (!this.screenName) return false;

    const match = /[^\p{Letter}\p{Mark}\s-]+/gu;
    const invalidCharacters =  this.screenName.match(match);
    return invalidCharacters == null;
  }

  canSearch(): boolean {
    if (this.isSearching) return false;
    if (this.clientExists) return false;
    else if (this.screenName?.trim()?.length < 4) return false;
    else if (!this.screenName) return false;
    //else if (!this.isBusiness && this.isAdvancedSearch && ((!this.screenFirstName || this.screenFirstName?.trim().length < 2) || (!this.screenLastName || this.screenLastName?.trim().length < 2))) return false;
    //else if (!this.isBusiness && !this.isAdvancedSearch && (!this.screenName || this.screenName?.trim().length < 4)) return false;
    //else if (this.isBusiness && (!this.screenName || this.screenName?.trim().length < 4)) return false;
    else if (!this.isBusiness && !this.isNationalityValid() && this.nationality) return false;
    else if (this.isBusiness && !this.isJurisdictionValid() && this.jurisdiction) return false;
    else if (this.dateOfBirth == 'undefined--') return false;
    else if (!this.isBusiness && !this.isIndividualNameValid()) return false;

    return true;
  }

  checkClientExists(clientRef: string, clientType: MonitorType): boolean {
    this.clientExists = false;
    if (clientRef?.length < 1) return;
    this.screeningService.checkClientExists(clientRef, clientType).subscribe(res => {
      if (res.data) {
        this.clientExists = res.data;
      }
    });
    return this.clientExists;
  }

  checkBusinessClientExists() {
    this.checkClientExists(this.clientRef, MonitorType.Businesses);
  }

  checkIndividualClientExists() {
    this.checkClientExists(this.clientRef, MonitorType.Individuals);
  }

  performScreen() {
    if (this.isSelfServeUser){
      if (this.selfServeService.screeningCredits <= 0) {
        this.modalService.dismissAll();
        this.selfServeService.openAdvertDialog(FeatureState.OUTOFCREDITS)
        return;
      }

      if (this.selfServeService.doNotAskMeAgain) {
        this.searchClient();
        return;
      }

      const modalRef = this.modalService.open(SelfServiceScreenDialogComponent, { backdrop : 'static', keyboard : false, scrollable : true});
      modalRef.componentInstance.screenName = this.screenName?.trim();
      modalRef.componentInstance.performScreening.subscribe((value: boolean) => {
      if (value)
        this.searchClient();
      });
    }
    else {
      this.searchClient()
    }
  }

  searchClient() {
    //Create copies to pass to sub component
    this.screenNameCopy = this.screenName;
    this.screenFirstNameCopy = this.screenFirstName;
    this.screenLastNameCopy = this.screenLastName;
    this.screenOtherNameCopy = this.screenOtherName;

    this.resetResults();

    if (this.isBusiness) {
      this.isSearching = true;  
      this.client.business = this.getBusiness();
      this.fcClient.searchBusinessByName(UtilityService.getCodeFromCountry(this.jurisdiction), this.screenName).subscribe(result => {
        this.isSearching = false;  
        if (result.isSuccess) {
          this.hasScreeningRun = result.data.matches?.length > 0;
          this.businessMatches = result.data.matches;
          this.resultsMediaId = result.data.resultsMediaId;
          this.threshold = result.data.threshold;
          if (this.isSelfServeUser) this.selfServeService.getCredits();
        }
        else {
          if (result.errors.find(x => x.propertyName == FeatureState.OUTOFCREDITS)) {
            if (this.isSelfServeUser && this.selfServeService.screeningCredits <= 0) {
              this.modalService.dismissAll();
              this.selfServeService.openAdvertDialog(FeatureState.OUTOFCREDITS)
              return;
            }
          }
        }
      }, error => {
        this.isSearching = false;
        this.errorService.setProblemDetailsFromError(error);
      });
    }
    else {
      let name = this.isAdvancedSearch ? null : this.screenName;
      let firstName = this.isAdvancedSearch ? this.screenFirstName : null;
      let otherName = this.isAdvancedSearch ? this.screenOtherName : null;
      let lastName = this.isAdvancedSearch ? this.screenLastName : null;
  
      this.isSearching = true;
      this.client.individual = this.getIndividual();
      this.fcClient.searchIndividualByName(UtilityService.getCodeFromNationality(this.nationality), this.dateOfBirth, firstName, this.gender, lastName, name, otherName).subscribe(result => {
        this.isSearching = false;  
        if (result.isSuccess) {
          this.hasScreeningRun = result.data.matches?.length > 0;
          this.individualMatches = result.data.matches;
          this.resultsMediaId = result.data.resultsMediaId;
          this.threshold = result.data.threshold;
          if (this.isSelfServeUser) this.selfServeService.getCredits();
        }
        else {
          if (result.errors.find(x => x.propertyName == FeatureState.OUTOFCREDITS)) {
            if (this.isSelfServeUser && this.selfServeService.screeningCredits <= 0) {
              this.modalService.dismissAll();
              this.selfServeService.openAdvertDialog(FeatureState.OUTOFCREDITS);
              return;
            }
          } 
        }
      }, error => {
        this.isSearching = false;
        this.errorService.setProblemDetailsFromError(error);
        return;
      });
    }
  }

  setIndividualMatch(match: IndividualMatch) {
    let event = new Observable<ClientResult>((observer: any) => {
      this.matchesService.saveIndividualMatchForScreening(this.getIndividual(), this.resultsMediaId, match, this.individualMatches, this.viewedMoreMatches, observer);
    });
    event.subscribe(client => {
      this.addClientEvent.emit(client);
      this.activeModal.close();
    });
  }

  setBusinessMatch(match: BusinessMatch) {
    let event = new Observable<ClientResult>((observer: any) => {
      this.matchesService.saveBusinessMatchForScreening(this.getBusiness(), this.resultsMediaId, match, this.businessMatches, this.viewedMoreMatches, observer);
    });
    event.subscribe(client => {
      this.screeningService.ClientList.unshift(client);
      this.screeningService.refreshSelectedClient(client);
      this.activeModal.close();
    });
  }

  private getIndividual(): IndividualClient {
    return new IndividualClient({
      clientRef: this.clientRef,
      dateOfBirth: this.dateFormatter.format(this.ngbDate),
      gender: this.gender,
      name: this.screenName,
      firstName: this.screenFirstName,
      otherName: this.screenOtherName,
      lastName: this.screenLastName,
      nationality: this.nationality,
      tags: this.tags,
      address: this.address
    });
  }

  private getBusiness(): BusinessClient {
    return new BusinessClient({
      clientRef: this.clientRef,
      name: this.screenName,
      tags: this.tags,
      jurisdiction: this.jurisdiction,
    });
  }

  public onBlur(e: Event): void {
    this.isBusiness ? this.jurisdictionFocus = false : this.nationalityFocus = false;
    e.stopPropagation();
  }

  public onFocus(e: Event): void {
    this.isBusiness ? this.jurisdictionFocus = true : this.nationalityFocus = true;
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  public isNationalityValid(): boolean {
    if (CountriesService.getNationalityCode(this.nationality) || CountriesService.getNationalityName(this.nationality))
      return true;
    return false;
  }

  public isJurisdictionValid(): boolean {
    if (CountriesService.getJurisdictionCode(this.jurisdiction) || CountriesService.getJurisdictionName(this.jurisdiction))
      return true;
    return false;
  }

  nationalityTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.nationalitySubSet(term);
      })
    );

  countryTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.countrySubSet(term);
      })
    );

  addressCountryTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.countrySubSet(term);
      })
    );

  private countrySubSet(term: string) {
    return CountriesService.getJurisdictionSubSet(term);
  }

  private nationalitySubSet(term: string) {
    return CountriesService.getNationalitySubSet(term);
  }

  setClientType() {
    this.isBusiness = !this.isBusiness;
    this.resetResults();
  }

  setSearchType() {
    this.isAdvancedSearch = !this.isAdvancedSearch;
  }

  dateChanged(dateResult: DatePickerResult) {
    if (dateResult.isValid) {
      this.ngbDate = dateResult.ngbValue;
      this.dateOfBirth = this.dateFormatter.format(this.ngbDate);
    }
  }

  public showIndividualResults(): boolean {
    return !this.isBusiness && this.individualMatches != null;
  }

  public showBusinessResults(): boolean {
    return this.isBusiness && this.businessMatches != null;
  }

  public resetResults() {
    this.client = new Client;
    this.businessMatches = null;
    this.individualMatches = null;
    // So that we can cancel away as they have changed some search criteria
    this.hasScreeningRun = false;
  }
}
