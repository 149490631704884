import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiResponseOfGetReportFormSchemaModel, ApiResponseOfGetReportTypesModel, ApiResponseOfGetSavedReportContentModel, ApiResponseOfGuid, ApiResponseOfListReportsModel, ApiResponseOfNullableDateTime, CreateReportCommand, ExportToPDFCommand, FileResponse, GetReportTypesQuery, ListReportsQuery, MarkReportAsSubmittedCommand, RegulatoryReportClient, UpdateReportCommand } from 'src/nswag';
import { AlertService } from '../_alert';

@Injectable({
  providedIn: 'root'
})
export class RegulatoryReportsService {

  constructor(private reportClient: RegulatoryReportClient, private alertService: AlertService, private router: Router) { }

  public ListReports(query: ListReportsQuery): Observable<ApiResponseOfListReportsModel> {
    return this.reportClient.listReports(query);
  }

  public getReportTypes(): Observable<ApiResponseOfGetReportTypesModel> {
    const query = new GetReportTypesQuery();
    return this.reportClient.getReportTypes(query);
  }

  public createReport(command: CreateReportCommand): Observable<ApiResponseOfGuid> {
    return this.reportClient.createReport(command);
  }

  public updateReport(command: UpdateReportCommand): Observable<ApiResponseOfGuid> {
    return this.reportClient.updateReport(command);
  }

  public markReportAsSubmitted(command: MarkReportAsSubmittedCommand): Observable<void> {
    return this.reportClient.markReportAsSubmitted(command);
  }
  
  public archiveReport(id: string): Observable<ApiResponseOfNullableDateTime> {
    return this.reportClient.archiveReport(id);
  }

  public export(command: ExportToPDFCommand): Observable<FileResponse> {
    return this.reportClient.export(command);
  }

  public getReportFormSchema(reportTypeId?: string | undefined, reportVersionId?: string | undefined): Observable<ApiResponseOfGetReportFormSchemaModel> {
    return this.reportClient.getReportSchema(reportTypeId, reportVersionId);
  }

  public getSavedReportContent(id: string): Observable<ApiResponseOfGetSavedReportContentModel> {
    return this.reportClient.getSavedReportContentById(id);
  }
}
