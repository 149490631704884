<div class="background-layer">
  <div *ngIf="centre; else LeftAdjust">
    <div class=" mt-3 row justify-content-center">
      <div class="mb-1 col-lg-5 col-sm-12 ">
        <ng-container [ngTemplateOutlet]="SearchTypeDisplay"></ng-container>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-5 col-sm-12 mb-5">
        <ng-container [ngTemplateOutlet]="SearchInput"></ng-container>
      </div>
    </div>
  </div>
  <ng-template #LeftAdjust>
    <ng-container [ngTemplateOutlet]="SearchTypeDisplay"></ng-container>
    <div class="row">
      <div class="{{fullwidth ? 'col-12' : 'col-6'}}">
        <ng-container [ngTemplateOutlet]="SearchInput"></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #SearchTypeDisplay>
      <ul ngbNav #nav="ngbNav" class="nav-tabs flex-nowrap" [activeId]="getSelectedTab()" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink (click)="setCompanySearch()"><span title="Company" aria-label="Company" class="icon-city"></span>&nbsp;Company</a>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink (click)="setIndividualSearch()"><span title="Individual" aria-label="Individual" class="icon-user"></span>&nbsp;Individual</a>
        </li>
      </ul> 
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
      <div *ngIf="premiumSearchEnabled()" style="display: inline-block;">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="standard" name="searchProviderType" value="1" [checked]="isStandardSearch()" (change)="togglePremiumSearch(false)" (keyup.enter)="runSearch()">
          <label class="form-check-label" for="standard">Standard</label>
          <span container="body" class="icon-question-circle ms-2" [ngbTooltip]="standardSearchTooltip"></span>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="premium" name="searchProviderType" value="0" [checked]="isPremiumSearch()" (change)="togglePremiumSearch(true)" (keyup.enter)="runSearch()">
          <label class="form-check-label" for="premium">Premium</label>
          <span container="body" class="icon-question-circle ms-2" [ngbTooltip]="premiumSearchTooltip"></span>
        </div>
      </div>
  </ng-template>

  <ng-template #standardSearchTooltip>
      <br>
      <p>
        Our standard data sets help you meet all your legal and regulatory requirements, utilising a combination of individual and corporate records coupled with complete financial crime checks (covering law enforcement,
        litigation, insolvency, adverse media, state-owned enterprises, politically exposed peoples and sanctions (former and current)) as well as our special interest lists covering predicate crimes (including money laundering,
        sanctions, fraud, environmental crime, corruption, terrorist financing, illegal wildlife trade, illegal antiquities trade, modern slavery).
      </p>
  </ng-template>

  <ng-template #premiumSearchTooltip>
      <br>
      <p>Our Premium data sets include everything in our standard data sets, as well as access to specialist non-digitised corporate records in MENA, Sub-Sahara Africa and Centralised Asia.</p>
  </ng-template>

  <ng-template #SearchInput>
    <div class="input-group">
      <input id="searchFieldMain" class="form-control" [(ngModel)]="criteria.searchString" placeholder="{{searchFilterPlaceholderText}}" #name="ngModel" required (keyup.enter)="runSearch()" autofocus />
      <input id="jurisdiction" [(ngModel)]="criteria.jurisdictionName" [ngbTypeahead]="searchService.jurisdictions" class="form-control" (keyup.enter)="runSearch()" placeholder="{{searchLocationPlaceholder()}}" />
      <span class="input-group-text btn btn-primary search-btn-new" id="basic-addon2" (click)="runSearch()">
        Search
      </span>
    </div>
  </ng-template>
</div>
