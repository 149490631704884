import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { AssessmentRecipient, IndustryVerticalsAverage, QuestionSetDetail, RecipientQuestionSetResponse, RecipientQuestionSetResponseList } from 'src/nswag';
import { RiskAssessmentService } from '../services/risk-assessment.service';
import { UtilityService } from '../utilities/utilities';

export enum CHART_TYPE {
  RADAR = 'radar',
  BAR = 'bar',
  SCATTER = 'scatter'
}

@Component({
  selector: 'charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  @Input() recipientResponse: RecipientQuestionSetResponse[] = []
  public labels: string[] = [];
  public rating: string;
  public chart: any;
  public radarRendered: boolean = true;
  public name: string;
  public chartHeight: number = 400;
  @Input() currentRecipient: AssessmentRecipient;
  @Input() industryAverages: IndustryVerticalsAverage[] = [];
  @Input() category: string;
  @Input() questionSets: QuestionSetDetail[] = [];
  @Input() type: CHART_TYPE;

  constructor(public riskAssessmentService: RiskAssessmentService, public utilityService: UtilityService, public cdRef: ChangeDetectorRef) { }
  async ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.loadChart(this.category, this.type);
    this.cdRef.detectChanges();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['recipientResponse'] || changes['questionSets'] || changes['type']) {
      setTimeout(() => {
        this.loadChart(this.category, this.type);
      }, 1);
    }
  }

  public loadChart = (category: string = '', type: any) => {
    if (this.recipientResponse.length == 0) {
      return;
    }

    this.chart?.destroy?.();
    let data: any;
    let options: any;
    let plugins: any;

    const ctx = document.getElementById('myChart_'+ category + '_' + type) as HTMLCanvasElement;

    let hasAverage = false;
    for (let index = 0; index < this.recipientResponse.length; index++) {
      const diag = this.recipientResponse[index];
      const qs = this.questionSets.find(x => x.id === diag.questionSetId);
      if (qs === undefined) break;
      const industryAverage = this.industryAverages?.find?.(x => x.questionSetId === qs.id);
      if (industryAverage?.average > 0){
        hasAverage = true;
        break;
      }
    }

    if (type === CHART_TYPE.RADAR){
      data = this.utilityService.GetRadarData(this.recipientResponse, this.questionSets, category, this.industryAverages);
      options = this.utilityService.GetRadarOptions(category);
      plugins = [this.utilityService.GetRadarPlugin()];
      ctx.height = 600;
      ctx.width = 600;
    }
    else if(type === CHART_TYPE.BAR){
      let rating = new Map();
      this.recipientResponse.forEach(element => {
        rating.set(element.name, element.rating);
      });

      data = this.utilityService.GetBarData(this.recipientResponse, this.industryAverages, this.questionSets);
      options = hasAverage ? this.utilityService.GetBarOptionsWithAverages(category) : this.utilityService.GetBarOptions(category);
      plugins = [hasAverage ? this.utilityService.GetBarPluginWithAverages(rating) : this.utilityService.GetBarPlugin(rating)];

      ctx.height = (this.questionSets.length * 30) + 100;
      ctx.width = 600;
    }
    else if(type === CHART_TYPE.SCATTER){
      const categories = this.utilityService.GetQuestionSetCategories(this.questionSets);
      data = this.utilityService.GetScatterData(this.recipientResponse, this.questionSets, this.industryAverages, categories);

      if (categories.length === 1) {
        this.chartHeight = 200;
        options = this.utilityService.GetLinearScatterOptions(categories[0]);
      } else {
        options = this.utilityService.GetScatterOptions();
      }

      plugins = this.utilityService.getScatterPlugins(categories);
    }

    const myChart = new Chart(ctx, { type: type, data: data, options: options, plugins: plugins});
    this.chart = myChart;
    this.radarRendered = true;
  }
}
