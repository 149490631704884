<div id="monitor-businessprofile">
    <div class="row">
        <div class="col">
            <img src="{{image}}" class="avatar-image"/>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <h4>{{profile?.name}}</h4>
            <span>{{profile?.description}}</span>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col">
            <mon-alerticongroup [client]="client" [removeTooltip]="removeTooltip"></mon-alerticongroup>
        </div>
    </div>
    <div *ngIf="showAlertStatus" class="row">
        <div class="col">
            <mon-alertstatus *ngIf="!isArchived" [client]="client" (clientChange)="onClientChange($event)"></mon-alertstatus>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <hr>  
        </div>
    </div>

    <div *ngIf="businessTypes" class="row" id="left-card">
        <div class="col-4">
        <label class="displaylabel-style">Business type:</label>
        </div>
        <div class="col-8">
        <span class="displaydetail-style">{{businessTypes}}</span>
        </div>
    </div>
    <div *ngIf="jurisdiction" class="row" id="left-card">
        <div class="col-4">
        <label class="displaylabel-style">Jurisidictions:</label>
        </div>
        <div class="col-8">
        <span class="displaydetail-style">{{jurisdiction}}</span>
        </div>
    </div>
    <div *ngIf="profile?.addresses?.length > 0">
        <div *ngIf="showAllLists; else showMoreAddresses">
            <div *ngFor="let address of profile?.addresses; index as i" class="row" id="left-card">
                <div *ngIf="isAddress(address)">
                    <div class="col-4">
                        <label *ngIf="i==0" class="displaylabel-style">Addresses:</label>
                    </div>
                    <div class="col-8">
                        <span class="displaydetail-style">{{getAddress(address)}}</span>
                    </div>    
                </div>
            </div>    
        </div>
        <ng-template #showMoreAddresses>
            <div class="row" id="left-card">
                <div class="col-4">
                    <label class="displaylabel-style">Addresses:</label>
                </div>
                <div class="col-8">
                    <span class="displaydetail-style">
                        <app-readmore [list]="addressList"></app-readmore>
                    </span>
                </div>
            </div>
        </ng-template>    
    </div>
    <div *ngIf="profile?.aliases?.length > 0">
        <div *ngIf="showAllLists; else showMoreAliases">
            <div *ngFor="let alias of profile?.aliases; index as i" class="row" id="left-card">
                <div class="col-4">
                    <label *ngIf="i==0" class="displaylabel-style">Aliases:</label>
                </div>
                <div class="col-8">
                    <span class="displaydetail-style">{{getAliasName(alias)}}</span>
                    <span class="displaydetail-style">{{getAliasType(alias)}}</span>
                </div>
            </div>    
        </div>
        <ng-template #showMoreAliases>
            <div class="row" id="left-card">
                <div class="col-4">
                    <label class="displaylabel-style">Aliases:</label>
                </div>
                <div class="col-8">
                    <span class="displaydetail-style">
                        <app-readmore [list]="aliasList"></app-readmore>
                    </span>
                </div>
            </div>
        </ng-template>    
    </div>    
</div>