<div #shadowOverlayContainer id="rr-form-shadowdom-overlay-container"></div>
<div class="parent">
    <div class="container">
        <div class="grid mt-4">
            <div class="col flex-container-end">
                <p-button label="Exit Form" [text]="true" (click)="onExit()"/>
            </div>
        </div>
    
        <div class="progress-bar-wrapper">
            <div class="grid">
                <div class="col mt-2 mx-2" [hidden]="!showProgressBar">
                    Step {{step}} of {{totalSteps}}
                    <p-progressBar [style]="{height: '10px'}" [value]="(step / totalSteps) * 100" />
                </div>
            </div>
        </div>
    
        <div class="section-container">
            <ng-content></ng-content>
        </div>
    
        <div class="grid">
            <div class="col">
            <hr class="divider">
            </div>
        </div>
    
        <div class="grid my-4">
            <div class="col flex-container-start">
                <p-button *ngIf="step > 0" label="Back" [text]="true" class="mx-1" (click)="onPrevious()"/>
            </div>
            <div class="col flex-container-end">
                <p-button *ngIf="step > 0 && !readonly" [disabled]="disableSave" class="mx-1" label="Save" (click)="onSave()" />
                <p-button *ngIf="step !== totalSteps" label="Next" class="mx-1" [text]="true" (click)="onNext()"/>
                <p-button *ngIf="!readonly && step === totalSteps" label="Complete" class="mx-1" [text]="true" (click)="onComplete()"/>
            </div>
        </div>
    </div>
</div>
