import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Evidence } from 'src/nswag';

@Component({
  selector: 'app-discounted-evidence',
  templateUrl: './discounted-evidence.component.html',
  styleUrls: ['./discounted-evidence.component.scss']
})
export class DiscountedEvidenceComponent implements OnChanges {

  @Input() profile: any;
  @Input() incHeader = true;

  public hasDiscounts = false;

  constructor() { }  

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profile) {
      this.hasDiscounts = this.profile?.evidences?.find( e => e.discountReason?.isDiscounted ) != null;
    }
  }

  getDate(evidence: Evidence): string {
    return evidence?.publicationDateIso ?? evidence?.captureDateIso;
  }

  getTitle(evidence: Evidence): string {
    return evidence?.title?.length > 0 ? evidence?.title : evidence?.originalUrl ;
  }

  checkSummary(evidence: Evidence): boolean {
    return evidence?.summary ? true : false;
  }

  getOriginalUrl(evidence: Evidence): string {
    if (evidence?.originalUrl == 'C6 Internal') {
      return null;
    }
    return evidence?.originalUrl;
  }

}
