<app-problem-details></app-problem-details>

<div class="tenants-list style-background border">
  <button class="btn btn-secondary my-2" id="addTenantBtn" (click)="showCreateTenantModal()">
    &plus; Add Tenant
  </button>
  <div *ngIf="tenantsList?.length > 0">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Tenant Name</th>
          <th>Company Name</th>
          <th>Hostname</th>
          <th title="Active users / licenses">Users</th>
          <th>Demo</th>
          <th>License</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tenant of tenantsList">
          <td>{{ tenant.name }}</td>
          <td>{{ tenant.companyName }}</td>
          <td>{{ tenant.hostName }}</td>
          <td>{{ tenant.currentActiveUsers }} / {{ tenant.maxActiveUsers }}</td>
          <td><input type="checkbox" [checked]="tenant.demo" disabled></td>
          <td>{{ tenant.licenceType }}</td>
          <td>
            <div ngbDropdown class="d-inline-block" id="monitor-list">
              <div class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-three-dots" viewBox="0 0 16 16">
                  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
              </svg>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <div ngbDropdownItem (click)="loadUserManagementForTenant(tenant)">Manage Users</div>
                <div ngbDropdownItem (click)="showEditTenantModal(tenant)">Edit</div>
                <div ngbDropdownItem (click)="loadMediaAdmin(tenant)">Upload Media</div>
                <div ngbDropdownItem (click)="showDeleteTenantConfirmModal(tenant)">Delete</div>
              </div>
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<ng-template #deleteTenantModalContent>
  <div class="container">
    <div class="modal-header">
      <h3>Confirm</h3>
    </div>
    <div class="modal-body">
      <p>
        Are you sure you want to delete tenant '{{ selectedTenant?.name }}' ?
      </p>
      <button class="btn bg-dark text-light cancel-btn" (click)="dismissActiveModal()">
        Cancel
      </button>
      <button class="btn bg-danger text-light" id="deleteBtn" (click)="deleteTenant()">
        Delete
      </button>
    </div>
  </div>
</ng-template>

<ng-template #updateTenantModalContent>
  <div class="modal-header">
    <h3>Edit Tenant</h3>
  </div>
  <div class="modal-body form-group">
    <div *ngIf="canViewForm()" class="form" id="addTenantForm">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5>Details</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Tenant Identifier</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="5" [(ngModel)]="selectedTenant.name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <label>Tenant Description</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="5" [(ngModel)]="selectedTenant.description" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Company Name</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="1" [(ngModel)]="selectedTenant.companyName" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Maximum Active Users</label>
            </div>
            <div class="row">
              <input type="number" class="form-control" required minlength="1"
                [(ngModel)]="selectedTenant.maxActiveUsers" />
            </div>
          </div>
        </div>
        <hr class="divider" />
        <div class="row">
          <div class="col">
            <h5>Features</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <input type="checkbox" [(ngModel)]="screenFeature" class="form-check-input" title="Screening" id="screening" name="screening" />
              <label for="screening">&nbsp;Screening&nbsp;</label>

              <input type="checkbox" [(ngModel)]="monitorFeature" class="form-check-input" title="Monitoring" id="monitoring" name="monitoring" />
              <label for="monitoring">&nbsp;Monitoring&nbsp;</label>

              <input type="checkbox" [(ngModel)]="searchFeature" class="form-check-input" title="Search" id="search" name="search" />
              <label for="search">&nbsp;Search&nbsp;</label>

              <input type="checkbox" [(ngModel)]="idvFeature" class="form-check-input" title="IDV" id="idv" name="idv" />
              <label for="idv">&nbsp;IDV&nbsp;</label>
              
              <input type="checkbox" [(ngModel)]="riskFeature" class="form-check-input" title="Assessment" id="risk" name="risk" />
              <label for="risk">&nbsp;Assessment&nbsp;</label>

              <input type="checkbox" [(ngModel)]="apiFeature" class="form-check-input" title="API" id="api" name="api" />
              <label for="api">&nbsp;API&nbsp;</label>

              <input type="checkbox" [(ngModel)]="intelligenceFeature" class="form-check-input" title="Intelligence" id="intelligence" name="intelligence" />
              <label for="intelligence">&nbsp;Intelligence&nbsp;</label>
              
              <input type="checkbox" [(ngModel)]="sarFeature" class="sar form-check-input" title="Regulatory Report" id="sar" name="sar" />
              <label for="sar" class="sar">&nbsp;Regulatory Report</label>
            </div>
          </div>
        </div>
        <div *ngIf="searchFeature then premiumSearchProviderOptions"></div>
        <div *ngIf="
            searchFeature || screenFeature || monitorFeature;
            then dataProviderOptions
          "></div>
        <hr class="divider" />
        <div class="row">
          <div class="col">
            <h5>Additional Options</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <input type="checkbox" [(ngModel)]="selectedTenant.demo" class="form-check-input" title="Demo" id="demo"
                name="demo" />
              &nbsp;Demo / Sales Tenant&nbsp;
            </div>
          </div>
        </div>
        <div class="btn-container">
          <button class="btn bg-dark text-light" id="dismissBtn" (click)="cancelForm()">
            Cancel
          </button>
          <button class="btn bg-primary text-light" (click)="updateTenant()" [disabled]="
              !selectedTenant?.name ||
              !selectedTenant?.companyName ||
              !maxUsersValid(selectedTenant?.maxActiveUsers) ||
              !dataProviderSettingsValid()
            ">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addTenantModalContent>
  <div class="modal-header">
    <h3>Add New Tenant</h3>
  </div>
  <div class="modal-body form-group">
    <div *ngIf="canViewForm()" class="form" id="addTenantForm">
      <div class="container">
        <div class="row">
          <div class="col">
            <h5>Details</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Tenant Identifier</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="5"
                [(ngModel)]="addTenantCommand.tenantName" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row">
              <label>Tenant Description</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="5"
                [(ngModel)]="addTenantCommand.tenantDescription" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Company Name</label>
            </div>
            <div class="row">
              <input type="text" class="form-control" required minlength="2"
                [(ngModel)]="addTenantCommand.companyName" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="row required-input">
              <label>Maximum Active Users</label>
            </div>
            <div class="row">
              <input type="number" class="form-control" required min="1" max="1000"
                [(ngModel)]="addTenantCommand.maximumActiveUsers" />
            </div>
          </div>
        </div>
        <hr class="divider" />
        <div class="row">
          <div class="col">
            <h5>Features</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <input type="checkbox" [(ngModel)]="screenFeature" class="form-check-input" title="Screening" id="screening" name="screening" />
              <label for="screening">&nbsp;Screening&nbsp;</label>

              <input type="checkbox" [(ngModel)]="monitorFeature" class="form-check-input" title="Monitoring" id="monitoring" name="monitoring" />
              <label for="monitoring">&nbsp;Monitoring&nbsp;</label>

              <input type="checkbox" [(ngModel)]="searchFeature" class="form-check-input" title="Search" id="search" name="search" />
              <label for="search">&nbsp;Search&nbsp;</label>

              <input type="checkbox" [(ngModel)]="idvFeature" class="form-check-input" title="IDV" id="idv" name="idv" />
              <label for="idv">&nbsp;IDV&nbsp;</label>
              
              <input type="checkbox" [(ngModel)]="riskFeature" class="form-check-input" title="Assessment" id="risk" name="risk" />
              <label for="risk">&nbsp;Assessment&nbsp;</label>

              <input type="checkbox" [(ngModel)]="apiFeature" class="form-check-input" title="API" id="api" name="api" />
              <label for="api">&nbsp;API&nbsp;</label>

              <input type="checkbox" [(ngModel)]="intelligenceFeature" class="form-check-input" title="Intelligence" id="intelligence" name="intelligence" />
              <label for="intelligence">&nbsp;Intelligence&nbsp;</label>
              
              <input type="checkbox" [(ngModel)]="sarFeature" class="sar form-check-input" title="Regulatory Report" id="sar" name="sar" />
              <label for="sar" class="sar">&nbsp;Regulatory Report</label>
            </div>
          </div>
        </div>
        <div *ngIf="searchFeature then premiumSearchProviderOptions"></div>
        <div *ngIf="
            searchFeature || screenFeature || monitorFeature;
            then dataProviderOptions
          "></div>
        <hr class="divider" />
        <div class="row">
          <div class="col">
            <h5>Additional Options</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div>
              <input type="checkbox" [(ngModel)]="addTenantCommand.demo" class="form-check-input" title="Demo" id="demo"
                name="demo" />
              &nbsp;Demo / Sales Tenant&nbsp;
            </div>
          </div>
        </div>
        <div class="btn-container">
          <button class="btn bg-dark text-light" id="dismissBtn" (click)="cancelForm()">
            Cancel
          </button>
          <button class="btn bg-primary text-light" id="addBtn" (click)="addTenant()" [disabled]="
              !addTenantCommand?.tenantName ||
              !addTenantCommand?.companyName ||
              !maxUsersValid(addTenantCommand?.maximumActiveUsers) ||
              !dataProviderSettingsValid()
            ">
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #premiumSearchProviderOptions>
  <br>
  <div class="row">
    <div class="col">
      <input type="checkbox" [(ngModel)]="togglePremiumSearchProviderCommand.enable" class="form-check-input"
        [checked]="togglePremiumSearchProviderCommand.enable" />
      <label class="form-check-label" for="flexCheckDefault">
        Premium&nbsp;Search&nbsp;(Diligencia)
      </label>
    </div>
  </div>
</ng-template>

<ng-template #dataProviderOptions>
  <hr class="divider" />
  <div class="row">
    <div class="col">
      <h5>Fuzzy Matching Options</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8">
      <label>Threshold Percentage</label>
      <input type="range" id="rangeInput" name="rangeInput" class="form-range feature-setting" min="65" max="100"
        [value]="configurableThreshold" [(ngModel)]="configurableThreshold" oninput="amount.value=rangeInput.value" />
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="input-group">
        <output type="text" class="form-control" id="amount" name="amount" for="rangeInput"
          value="configurableThreshold">{{ configurableThreshold }}</output>
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-sm-8">
      <label>Date of Birth Matching Sensitivity</label>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div ngbDropdown id="dobMatchingDropdown">
        <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" placeholder="Select.."
          ngbDropdownToggle>
          {{ selectedDobOption }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div *ngFor="let option of dobSensitivityOptions">
            <button ngbDropdownItem (click)="changeSelectedDropdownItem(option)">
              {{ option }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>