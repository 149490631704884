import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Profile } from 'oidc-client';
import { ProfileService } from 'src/app/profile.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { ClientResult, IdvUserProfile } from 'src/nswag';

@Component({
  selector: 'app-idv-report',
  templateUrl: './idv-report.component.html',
  styleUrls: ['./idv-report.component.scss']
})
export class IdvReportComponent implements OnInit {

  @Input() showReport: boolean;
  @Input() idvProfile: IdvUserProfile;
  @Input() clientResult: ClientResult;
 
  @Output() hide = new EventEmitter<boolean>();

  public userAccount: Profile;
  public dateNow: string;

  constructor(private profileService: ProfileService) {this.userAccount = this.profileService.getProfile();}

  ngOnInit(): void { 
    this.dateNow = UtilityService.convertToLocalDateTime(new Date(), "long");
  }

  public onPrint(): void {
    window.print();  
  }

  public closeReport() {
    this.showReport = false;
    this.hide.emit(false);
  }

  public get userName(): string { return this.userAccount?.email; }
  public get userFullName(): string { return this.profileService?.fullName; }
  public get companyName(): string { return this.profileService?.companyName; }
}
