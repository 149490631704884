@switch (toolTipTemplate) {
@case ('assessToolTip') {<ng-container [ngTemplateOutlet]="assessToolTip"></ng-container>}
@case ('actToolTip') {<ng-container [ngTemplateOutlet]="actToolTip"></ng-container>}
@case ('assessToolTipTwo') {<ng-container [ngTemplateOutlet]="assessToolTipTwo"></ng-container>}
@case ('actToolTipTwo') {<ng-container [ngTemplateOutlet]="actToolTipTwo"></ng-container>}
@case ('emailToolTip') {<ng-container [ngTemplateOutlet]="emailToolTip"></ng-container>}
@case ('welcomeToolTip') {<ng-container [ngTemplateOutlet]="welcomeToolTip"></ng-container>}
@default {<ng-container [ngTemplateOutlet]="defaultTemplate"></ng-container>}
}

<ng-template #defaultTemplate>
    <div>No tool tip found</div>
</ng-template>

<!-- For assessment-list component -->

<ng-template #assessToolTip>
    <div class="tooltip-align">
        <br>
        <p>
            This displays a list of assessments that have already been created, you can navigate through these by using
            the control panel at the bottom of the list.
        </p>
        <p>
            Click on each assessment below to add new or existing recipients.
        </p>
    </div>
</ng-template>

<ng-template #actToolTip>
    <div class="tooltip-align">
        <br>
        <p>
            Click on the icon (three black dots) to retrieve the drop-down menu. This will display the various actions
            that you can take, such as:
        </p>
        <ul>
            <li>Manage Recipients</li>
            <li>Manage Question Sets</li>
            <li>Send Assessment</li>
            <li>View Group Report</li>
            <li>View Audit History</li>
            <li>Edit Assessment</li>
            <li>Delete Assessment</li>
        </ul>
    </div>
</ng-template>

<!-- For recipinet-list component -->
<ng-template #assessToolTipTwo>
    <div class="tooltip-align">
        <br>
        <p>
            This displays the list of recipients added to the selected assessment. You can navigate these using the
            control panel at the bottom of the list.
        </p>
        <p>
            Click on each recipient to view their assessment results.
        </p>
    </div>
</ng-template>

<ng-template #actToolTipTwo>
    <div class="tooltip-align">
        <br>
        <p>
            Click the three black dots icon to get the drop-down menu. This will display the various actions that you
            can take such as:
        </p>
        <ul>
            <li>Resend/Send</li>
            <li>Unlock/Lock</li>
            <li>View Report</li>
            <li>View Results</li>
            <li>View Answers</li>
            <li>Edit</li>
            <li>Remove</li>
        </ul>
    </div>
</ng-template>

<!-- For create-assessment-dialog component -->
<ng-template #emailToolTip>
    <div class="tooltip-align">
        <br>
        <p>
            This is the default email message your recipient will receive, you can choose to customise it by editing the
            message.
        </p>
        <p>
            The &quot;SenderName&quot; and &quot;CompanyName&quot; expressions in the default message&apos;s double
            curly braces will display your name and company as the sender when the recipient receives the assessment
            invite email.
        </p>
    </div>
</ng-template>

<ng-template #welcomeToolTip>
    <div class="tooltip-align">
        <br>
        <p>
            This is the default assessment welcome message that will be displayed when your recipient receives and
            clicks on the assessment invite link from their email. You can choose to customise this message by clicking
            and editing it as desired.
        </p>
        <p>
            Kindly note that to display the assessment name by its default, the &quot;AssessmentName&quot; expression in
            double curly braces should be left in its default state.
        </p>
    </div>
</ng-template>