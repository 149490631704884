<div *ngFor="let evidence of evidenceList; let pos=index">
    <div *ngIf="isEvidence(evidence)" class="row pt-2 law-evidence">
        <div class="col">
            <div *ngIf="!reportMode; else reportUrls">
                <a href="{{evidence.originalUrl}}" target="_blank">View URL Evidence</a>
                <span class="mx-2">&nbsp;</span>
                <a *ngIf="isEvidence(evidence)" (click)="!isLoading && handlePDFEvidenceClick(evidence)" target="_blank" class="psuedo-link">View PDF Evidence</a>
            </div>
           
            <ng-template #reportUrls>
                <div class="row py-1">
                    <div class="col-3 p-0">
                        <label>Evidence Url:</label>
                    </div>
                    <div class="col p-0">
                        <a href="{{evidence.originalUrl}}" target="_blank">{{evidence.originalUrl}}</a>
                    </div>
                </div>
                <div class="row py-1" *ngIf="evidence.assetUrl">
                    <div class="col-3 p-0">
                        <label>Evidence PDF Url:</label>
                    </div>
                    <div class="col p-0">
                        <a href="{{evidence.assetUrl}}" target="_blank">{{evidence.assetUrl}}</a>
                    </div>
                </div>
            </ng-template>
            <div *ngIf="incHeader; else showAll" class="pt-2">
                <app-readmore *ngIf="checkSummary(evidence)" [summary]="evidence?.summary"></app-readmore>
            </div>
            <ng-template #showAll>
                <div class="pt-2">
                    <span>{{evidence?.summary}}</span>
                </div>
            </ng-template>
            <span *ngIf="getDate(evidence)"><b>Date:</b> {{getDate(evidence)}}<br/></span>
        </div>
        <div *ngIf="allowDiscounting" class="col-sm-1 pt-1">
            <app-discount-reason [evidence]="evidence" [profile]="profile"  [clientId]="clientId"></app-discount-reason>
        </div>
    </div>
</div>
<app-loader [show]="isLoading"></app-loader>
