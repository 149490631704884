<button class="btn btn-sm btn-warning btn-yellow-bg" (click)="reportSubject(reportContent)">
    <svg xmlns="http://www.w3.org/2000/svg" width="20px"  viewBox="0 0 374.06 386.84">
      <path
        d="M127,238.88a39.23,39.23,0,1,0,53.91-13.13A39.22,39.22,0,0,0,127,238.88Zm50.28,30.58a19.62,19.62,0,1,1-6.57-26.95A19.64,19.64,0,0,1,177.31,269.46ZM214.1,111.15l-.23,19.54a5,5,0,0,1-3.22,4.59L192.05,142a4.85,4.85,0,0,1-5.44-1.46L174.4,125.81a9.9,9.9,0,0,0-10.93-3C113.78,141,79,148.09,51.6,193.08A127.45,127.45,0,0,0,33.37,250.3,39.11,39.11,0,1,0,51.89,326a127.52,127.52,0,0,0,232.58-96.88,125.63,125.63,0,0,0-8.45-24l93-51.43a9.81,9.81,0,0,0,4.36-12.2L343.65,67.25a9.82,9.82,0,0,0-12.45-5.59L220.5,102a9.69,9.69,0,0,0-6.4,9.12ZM29,305.59a19.64,19.64,0,0,1-6.57-26.95,19.36,19.36,0,0,1,11.12-8.59,123.26,123.26,0,0,0,3.05,19.41,132.11,132.11,0,0,0,6.18,18.47A19.37,19.37,0,0,1,29,305.59ZM350.32,136.7a4.91,4.91,0,0,1-2.17,6.14l-97.66,54C256.65,210,286.57,259.28,253,315c-49.55,82.18-174.52,63.25-197.27-30.12-16.73-68.68,33-110.32,56.87-122.15,6.38-2.66,51.1-19,51.1-19l14.45,17.39A9.9,9.9,0,0,0,189.06,164L227,150.18a9.83,9.83,0,0,0,6.45-9.09l.29-22.92L324.6,85a4.94,4.94,0,0,1,6.25,2.81l19.47,48.87Z" />
      <path d="M152.61,111.65a7.94,7.94,0,0,1-5.22-2l-51-44a8,8,0,0,1,10.45-12.11l51,44a8,8,0,0,1-5.23,14.06Z"
        fill="#1d1d1b" />
      <path d="M180.9,88.38a8,8,0,0,1-7.3-4.73L146,22.19a8,8,0,0,1,14.6-6.55L188.2,77.1a8,8,0,0,1-7.3,11.28Z"
        fill="#1d1d1b" />
      <path d="M216.65,83.29h-.36a8,8,0,0,1-7.64-8.34L211.6,7.65a8,8,0,0,1,16,.7l-2.94,67.29A8,8,0,0,1,216.65,83.29Z"
        fill="#1d1d1b" />
    </svg>
</button>

<ng-template #reportContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Want to tell us about a crime or an increased risk activity about {{dashService.getInvestigation()?.name}}? </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
     <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="name">Subject</label>
      <input type="text" id="name" name="name" class="form-control" required [(ngModel)]="subject" #name="ngModel">
      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
        <div *ngIf="name.errors?.required">
          Subject is required.
        </div>
      </div>
      <label for="information">Description</label>
      <textarea type="text" id="information" name="information" rows="10" class="form-control" required [(ngModel)]="information" #info="ngModel"></textarea>
      <div *ngIf="info.invalid && (info.dirty || info.touched)" class="alert alert-danger">
        <div *ngIf="info.errors?.required">
          Information is required.
        </div>
      </div>
    </div>
    <label for="file">Supporting document (optional)</label>
    <div class="custom-file">
      <input type="file" class="form-control" id="formFile" (change)="onFileChange($event)">
      <label class="custom-file-label" for="file">{{selectedFileName ? selectedFileName : ""}}</label>
      <div *ngIf="fileTooLarge" class="alert alert-danger">
        <div>
          This file is too large to upload.
          File size must be under {{maxFileSizeInKilobytes}}KB.
          Current size: {{selectedFileSizeInKilobytes}}KB.
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="submitWhistleblow(modal)">Submit</button>
  </div>
</ng-template>
