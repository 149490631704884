<div class="modal-header dialogueContainer">
  <h1 id="tableLabel" class="ms-2">Themis Search</h1>
  <button type="button" class="close me-2" aria-label="Close" (click)="dismiss()">
    <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
  </button>
</div>
<div class="modal-body" id="searchModal">
  <app-search-criteria [(criteria)]="searchCriteria" [searchType]="searchCriteria.searchType" [fullwidth]="true" (run)="runSearch()"></app-search-criteria>

  <div *ngIf="searchCriteria?.searchString && searchResultState?.showDummyCreateOption" class="row mt-5 mb-3">
    <div class="col">
      <div class="alert alert-info alert-sm">
        If the company or person is not in the list, try altering your search terms or click here to add the subject
        <button class="btn btn-primary btn-sm ms-4" (click)="createDummyAndRedirect()">Add</button>
      </div>
    </div>
  </div>

  <app-search-results [criteria]="searchCriteria" [state]="searchResultState" [isBusy]="searchService.isBusy" (result)="onResult($event)"></app-search-results>
</div>