<div class="container" *ngIf="dashService.getInvestigation() as subject">
    <div class="row">
        <div class="avatar-container">
            <label class="btn btn-default">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus bg-secondary" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                <input id="fileInput" type='file' class="btn form-control" accept=".jpg,.jpeg,.png"  (change)="onSelectFile($event)" hidden>
                <div *ngIf="dashService.getInvestigation()?.profileImageId; else displayCompanyLAMPSAvatar">
                    <img onerror="src='/assets/images/EDD-Avatar-company.png'" [src]="'/media/'+dashService.getInvestigation()?.profileImageId+'' | secure | async" class="center avatar-image">
                </div>
                <ng-template #displayCompanyLAMPSAvatar>
                    <img class="center avatar-image" src="/assets/images/EDD-Avatar-company.png" />
                </ng-template>
            </label>
        </div>
        <h4 class="text-center">{{subject?.asCompanyInfo?.name}}</h4>
    </div>
    <div class="row">
        <div class="col">
            <div class="row">
                <label>Company Name</label>
            </div>
            <div class="row">
                <input type="text" class="form-control" [(ngModel)]='subject.asCompanyInfo.name' (ngModelChange)="onEdit('Company Name', subject.asCompanyInfo.name)">
            </div>
        </div>
        <div class="col">
            <div class="row">
                <label>Company No.</label>
            </div>
            <div class="row">
                <input type="text" class="form-control" [(ngModel)]='subject.asCompanyInfo.entityId' (ngModelChange)="onEdit('Company Number', subject.asCompanyInfo.entityId)">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="row">
                <label>Jurisdiction</label>
              </div>
              <div class="row">
                <input id="jurisdiction" [(ngModel)]="subject.asCompanyInfo.jurisdictionName" [ngbTypeahead]="searchService.jurisdictions" class="form-control" (ngModelChange)="onEdit('Jurisdiction', subject.asCompanyInfo.jurisdictionName)"/>
              </div>
        </div>
        <div class="col">
            <div class="row">
                <label>Address</label>
            </div>
            <div class="row">
                <input type="text" class="form-control" [(ngModel)]='subject.asCompanyInfo.registeredAddress.fullAddress' (ngModelChange)="onEdit('Registered Address', subject.asCompanyInfo.registeredAddress.fullAddress)">
            </div>
        </div>
    </div>
  </div>
