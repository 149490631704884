<section>
  <br />
  <h2>Company Details</h2>
  <h4 class="bg-dark text-light">{{company?.name}}</h4>
  <form [formGroup]="dashService.getInvestigation().reportActorFreeText">
    <div class="row">
      <div class="col">
        <div class="wrapper">
          <textarea #textarea id="details" type="textarea" class="discardable-text"
            placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
            formControlName="detailsHeading"></textarea> <span class="edit-icon"> click to edit</span>
          <p id="detailsText"></p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="row">
        <div class="lamps-image-col">
          <div *ngIf="dashService.getInvestigation()?.profileImageId; else displayLAMPSAvatar">
            <img [src]="'/media/'+dashService.getInvestigation()?.profileImageId+'' | secure | async"
              class="avatar-image">
          </div>
          <ng-template #displayLAMPSAvatar>
            <img src="/assets/images/EDD-Avatar-company.png" class="avatar-image" />
          </ng-template>
        </div>
        <div class="col" *ngIf="company?.isActive || company?.jurisdiction">
          <p *ngIf="company?.isActive" class="c24 c41">Active: {{company?.isActive ? "Active" : "Inactive"}}</p>
          <p *ngIf="company?.jurisdiction" class="c24 c41">Jurisdiction: {{company?.jurisdiction}}</p>
        </div>
        <div class="col" *ngIf="company?.registeredAddress?.fullAddress">
          <p *ngFor="let addressLine of reportUtils.splitFullAddress(company?.registeredAddress?.fullAddress)">
            {{addressLine}}</p>
        </div>
        <div class="col address" *ngIf="!company?.registeredAddress?.fullAddress">
          <p>{{company?.registeredAddress?.addressLine1}}</p>
          <p>{{company?.registeredAddress?.addressLine2}}</p>
          <p>{{company?.registeredAddress?.countyOrState}}</p>
          <p>{{company?.registeredAddress?.locality}}</p>
          <p>{{company?.registeredAddress?.postOrZipCode}}</p>
          <p>{{company?.registeredAddress?.country}}</p>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Business Relationship</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="businessRelationship" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="businessRelationship"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="businessRelationshipText"></p>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Risk Level</p>
        </div>
        <div class="col">
          <p class="text-light">Comments</p>
        </div>
      </div>
      <div class="row" [style.background-color]="rowColors.get('ragStatus')">
        <div class="col">
          <select class="form-select form-control" id="uniqueCompOption" formControlName="ragStatus"
            (change)="setRowColor('ragStatus')">
            <option value="" selected></option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
            <option value="N/A">N/A</option>
          </select>
          <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
        </div>
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="ragStatus" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="riskLevelComments"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="ragStatusText"></p>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>