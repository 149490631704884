import { CompanyInfo, EntityTypes, OfficerInfo } from 'src/nswag';
import { DummyCompanyActor, DummyIndividualActor, IActorBase } from "../../dashboard/models/Actor";
import { SearchCriteria } from "./search-criteria";
import { CompanyRow, DiligenciaOrgRow, DiligenciaPersonRow, PersonRow } from "./search-table-models";
import { MD5 } from 'crypto-js';

export class SearchResultState {
  CreateDummyActor(searchCriteria: SearchCriteria) {
    var id = this.generateUniqueId(searchCriteria);
    var dummy: IActorBase;
    if (searchCriteria.searchType == EntityTypes.Company) {
      dummy = new DummyCompanyActor(new CompanyInfo({
        id: id,
        isActive: true,
        name: searchCriteria.searchString,
        jurisdiction: searchCriteria.jurisdictionCode,
        jurisdictionName: searchCriteria.jurisdictionName
      }));
    }
    else {
      dummy = new DummyIndividualActor(new OfficerInfo({
        isActive: true,
        id: id,
        name: searchCriteria.searchString,
        jurisdiction: searchCriteria.jurisdictionCode,
        jurisdictionName: searchCriteria.jurisdictionName
      }));
    }
    return dummy;
  }

 private generateUniqueId(searchCriteria: SearchCriteria): string {
    const {
      jurisdictionName,
      jurisdictionCode,
      searchType,
      searchString,
      serviceType,
    } = searchCriteria;
  
    // Generate a unique ID using property values and prefixes for null values
    const uniqueId = `${this.prefixIfNull(jurisdictionName?.toUpperCase())}_${this.prefixIfNull(jurisdictionCode?.toUpperCase())}_${searchType}_${this.prefixIfNull(searchString?.toUpperCase())}_${serviceType}`;
    // Generate hash 
    const hash = MD5(uniqueId).toString();
    // Convert the hash to a unique number
    const uniqueNumber = parseInt(hash, 16);
    return uniqueNumber.toString();
  }

  private prefixIfNull(value: any): string {
    return value !== null && value !== undefined ? value : 'null';
  }

  // list of columns currently filtered
  public filterList: Map<string, string> = new Map<string, string>();

  // Display dummy link
  public showDummyCreateOption: boolean = false;

  // Converted results from a search for browsing
  public listedCompanies: Array<CompanyRow>;
  public sortedCompanies: Array<CompanyRow>;
  public companyFilter: CompanyRow;
  // Diligencia
  public listedDiligenciaOrgs: Array<DiligenciaOrgRow>;
  public sortediligenciaOrgs: Array<DiligenciaOrgRow>;
  public diligenciaOrgFilter: DiligenciaOrgRow;

  // Converted results from a search for browsing
  public listedPeople: Array<PersonRow>;
  public sortedPeople: Array<PersonRow>;
  public peopleFilter: PersonRow;
  // Diligencia
  public listedDiligenciaPeople: Array<DiligenciaPersonRow>;
  public sortediligenciaPeople: Array<DiligenciaPersonRow>;
  public diligenciaPeopleFilter: DiligenciaPersonRow;

  public reset() {
    this.showDummyCreateOption = false;
    this.listedPeople = null;
    this.sortedPeople = null;
    this.peopleFilter = new PersonRow();

    this.listedCompanies = null;
    this.sortedCompanies = null;
    this.companyFilter = new CompanyRow();


    this.listedDiligenciaPeople = null;
    this.sortediligenciaPeople = null;
    this.diligenciaPeopleFilter = new DiligenciaPersonRow();

    this.listedDiligenciaOrgs = null;
    this.sortediligenciaOrgs = null;
    this.diligenciaOrgFilter = new DiligenciaOrgRow();

    this.filterList.clear();
  }
}
