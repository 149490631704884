import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ReportUtilities {
  constructor() { }

  public getRowColor(controlValue: string): string {
    switch(controlValue) {
        case "N/A":
          return "#dfdfdf";
        case "Low":
            return "#B0E3B7";
        case "Medium":
            return "#FFFACD";
        case "High":
            return "#f08080";
        default:
            return "";
      }
  }

  public splitFullAddress(data: string) {
    return data.split(',').map(item => item.trim());
  }
}
