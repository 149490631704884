import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CurrentModule } from 'src/app/monitor/client-report/client-report.component';
import { ClientResult } from 'src/nswag';

@Component({
  selector: 'app-not-matched',
  templateUrl: './not-matched.component.html',
  styleUrls: ['./not-matched.component.scss']
})
export class NotMatchedComponent implements OnChanges {
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;

  constructor() { }
  
  @Input() client: ClientResult;
  @Input() profile: any;
  @Input() discounted = false;
  @Input() matches = false;
  @Input() name : string;
  @Input() allowDiscounting = true;
  @Input() screened = false;
  @Input() isArchived: boolean = false;
  @Input() currentModule: CurrentModule;
  @Output() clientChange = new EventEmitter<ClientResult>(); 
  @Output() viewMatchesEvent = new EventEmitter<boolean>();
  @Output() addToMonitoringEvent = new EventEmitter<boolean>();
  @Output() searchMatchesEvent = new EventEmitter<boolean>();

  public inMonitoring = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.screened = changes?.client?.currentValue?.client?.screened ?? this.screened;
    this.inMonitoring = changes?.client?.currentValue?.client?.inMonitoring ?? this.inMonitoring;
  }

  public close() {
    this.clientChange.emit(null);
  }

  public onClientChange(newClient: ClientResult) {
    this.client = newClient;
    this.clientChange.emit(newClient);
  }

  public searchMatches() {
    this.searchMatchesEvent.emit(true);
  }

  public viewMatches() {
    this.viewMatchesEvent.emit(true);
  }

  public addToMonitoring() {
    this.addToMonitoringEvent.emit(true);
  }  
}
