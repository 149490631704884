import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-complete-report-dialog',
  templateUrl: './complete-report-dialog.component.html',
  styleUrls: [
    './complete-report-dialog.component.scss',
    '../regulatory-reports-base.component.scss'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CompleteReportDialogComponent implements OnInit {

  @Output() onCompleteEvent = new EventEmitter<string>();

  public usersFullName: string = '';
  public signatureUrl: string;

  constructor(private dialogConfig: DynamicDialogConfig, private dialogRef: DynamicDialogRef) { }

  ngOnInit(): void {
     if (this.dialogConfig.data) {
      this.usersFullName = this.dialogConfig.data.usersFullName;
    }
  }

  public onSignatureChange(url: string): void {
    this.signatureUrl = url;
  }

  public onClearSignature(): void {
    this.signatureUrl = null;
  }

  public onComplete(): void {
    this.onCompleteEvent.emit(this.signatureUrl);
  }

  public onClose(): void {
    this.dialogRef.close()
  }
}
