import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { SurveyClient, SubmitUserSatisfactionSurveyCommand, SubmitUserSatisfactionSurveySatisfactionLevel } from 'src/nswag';

@Component({
  selector: 'app-user-satisfaction',
  templateUrl: './user-satisfaction.component.html',
  styleUrls: ['./user-satisfaction.component.scss']
})
export class UserSatisfactionComponent implements OnInit {
  public selectedSatisfaction: SubmitUserSatisfactionSurveySatisfactionLevel;
  public satisfactionOptions: SubmitUserSatisfactionSurveySatisfactionLevel[];
  public selectedRecommendation: number; 
  public userComments: string;

  constructor(private activeModal: NgbActiveModal, private alertService: AlertService, public surveyClient: SurveyClient) { }

  ngOnInit(): void {
    this.populateSatisfactionEnumList();
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  private populateSatisfactionEnumList() {
    const options = Object.values(SubmitUserSatisfactionSurveySatisfactionLevel).filter((item) => {
      return isNaN(Number(item));
    })
    this.satisfactionOptions = options.sort((a, b) => options.indexOf(a) < options.indexOf(b) ? 1 :-1);
  }

  public submitSurvey(skip: boolean) {
    let command = new SubmitUserSatisfactionSurveyCommand
    command.satisfactionRating = this.selectedSatisfaction;
    command.recommendationLikelihoodLevel = this.selectedRecommendation;
    command.featureRequestFreeText = this.userComments;

    if (skip) {
      this.skip(command);
    } else {
      this.submit(command);
    }
  }

  public skip(command: SubmitUserSatisfactionSurveyCommand) {
    this.surveyClient.submit(command).subscribe(result => {
      this.dismiss();
      this.alertService.warn('Sorry, it seems like you skipped the survey feedback form. Please take a moment next time to share your valuable feedback so we can improve our services. Thank you!', { autoClose: true, keepAfterRouteChange: false });
    },
    (error) => {
      this.alertService.error('Unfortunately, the skip option failed. Please try again.', { autoClose: true, keepAfterRouteChange: true })
    });
  }

  public submit(command: SubmitUserSatisfactionSurveyCommand) {
    this.surveyClient.submit(command).subscribe(result => {
      this.dismiss();
      this.alertService.success('Thank you! Your feedback has been sent successfully', { autoClose: true, keepAfterRouteChange: false });
    },
    (error) => {
      this.alertService.error('Unfortunately, the feedback failed. Please try again.', { autoClose: true, keepAfterRouteChange: true })
    });
  }
}
