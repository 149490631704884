import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Profile } from 'oidc-client';
import { ProfileService } from 'src/app/profile.service';
import { BusinessMatch, BusinessProfile, IndividualMatch, IndividualProfile, Log } from 'src/nswag';
import { UtilityService } from '../../utilitities/utilities';
import { BaseReportComponent } from './base-client-section.component';

export enum CurrentModule {
  SEARCH = 'Search',
  SCREEN ='Screen',
  MONITOR = 'Monitor',
  INTELLIGENCE = 'Intelligence',
  EIDV = 'eIDV'
}

@Component({
  selector: 'mon-clientreport',
  templateUrl: './client-report.component.html',
  styleUrls: ['./client-report.component.scss']
})
export class ClientReportComponent  extends BaseReportComponent implements OnChanges {

  @Input() showReport: boolean;
  @Input() profile: any;
  @Input() individualMatches: IndividualMatch[];
  @Input() businessMatches: BusinessMatch[];
  @Input() module: CurrentModule;
  @Input() auditLogs: Log[] = [];

  @Output() hide = new EventEmitter<boolean>();

  public showSpinner: boolean = false;

  public userAccount: Profile;

  public individualProfile: IndividualProfile;
  public businessProfile: BusinessProfile;

  // Name of matching profile
  public name: string;
  public headerImage: string;
  public dateNow: string;

  constructor(private profileService: ProfileService) {
    super();
    this.userAccount = profileService.getProfile();
  }

  ngOnInit(): void {
    if (this.module === CurrentModule.MONITOR) {
      this.headerImage = '../../../assets/images/monitor-header.png';
    } 
    if (this.module === CurrentModule.SCREEN) {
      this.headerImage = '../../../assets/images/screen-header.png';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.showReport) {
      this.dateNow = UtilityService.convertToLocalDateTime(new Date(), "long");
      this.showSpinner = true;

      var w: any = window;
      if (w.fcWidget){
        w.fcWidget.hide();
      }

      this.individualProfile = null;
      this.businessProfile = null;

      if (this.client) {
        this.businessClient = this.client?.client?.business;
        this.individualClient = this.client?.client?.individual;
        this.matchStatus = this.client?.matchStatus;   
        this.isBusiness = this.businessClient != null ;
        this.isIndividual = this.individualClient != null;

        if (this.profile) {
          if (this.isBusiness) {
            this.businessProfile = this.profile;
            this.name = this.businessProfile?.name;  
          }
          else {
            this.individualProfile = this.profile;
            this.name = UtilityService.getIndividualName(this.individualProfile);
          }
        }
        this.showSpinner = false;

        this.isScreened = this.client?.client?.screened;
      }
    }
    else{
      var w: any = window;
      if (w.fcWidget) {
        w.fcWidget.show();
      }
    }
  }

  public onPrint(): void {
    window.print();  
  }

  closeReport() {
    this.showReport = false;
    this.hide.emit(false);
    
    var w: any = window;
    if (w.fcWidget) {
      w.fcWidget.show();
    }
  }
  
  get userName(): string { return this.userAccount?.email; }
  get userFullName(): string { return this.profileService?.fullName; }
  get companyName(): string { return this.profileService?.companyName; }
}
