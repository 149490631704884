import { Component } from '@angular/core';
import { IdvDetailBaseComponent } from './idv-detail-base.component';

@Component({
  selector: 'app-idv-detail',
  templateUrl: './idv-detail.component.html',
  styleUrls: ['./idv-detail.component.scss']
})
export class IdvDetailComponent extends IdvDetailBaseComponent {

  public close() {
    this.changeProfile.emit(null);
  }

  public setTabAsClicked(tabNumber: number) {
    // switch(tabNumber) { 
    //   case 0: { 
    //     this.tab0Clicked = true;
    //     break; 
    //   } 
    //   case 1: { 
    //     this.tab1Clicked = true;
    //     break; 
    //   } 
    //   case 2: { 
    //     this.tab2Clicked = true;
    //     break; 
    //   } 
    //   case 3: { 
    //     this.tab3Clicked = true;
    //     break; 
    //   } 
    //   case 4: {
    //     this.tab4Clicked = true;
    //     break;
    //   } 
    //   case 5: {
    //     this.tab5Clicked = true;
    //     break;
    //   } 
    //   default: { 
    //     break; 
    //   }
    // }
  }


}
