<div class="container-fluid">
  <header>
    <img alt="Report Cover" src="../../../assets/images/report-cover.png"
       style="width:100%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
       title="">
  </header>
  <div class="print-content-body">
    <div [innerHTML]="getInnerHtml()"></div>
  </div>
  <br/><hr>
  <br/><hr>

  <footer>
    <div class="footer">
      <table class="footer">
        <tbody>
          <tr>
            <td class="left-align">
              <img alt="" src="../../../assets/images/data-provider-logos.png"
                   style="width: 90%; margin-bottom:38px;" title="">
            </td>
            <td class="right-align">
              <p class="footer"> Themis Due Diligence Report</p>
              <p class="footer"> INNOVATION | INSIGHT | INTELLIGENCE</p>
              <p class="footer">www.wearethemis.com</p>
            </td>
          </tr>
        </tbody>
      </table>  
    </div>
  </footer>
</div>