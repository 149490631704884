<section *ngIf="connectedActorEdges.length > 0">
    <br /><br />
    <h2>Directly Associated Parties</h2>
    <form [formGroup]="this.dashService.getInvestigation().reportActorFreeText">
        <div class="wrapper">
            <textarea #textarea id="dap" type="textarea" class="discardable-text"
                placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
                formControlName="directlyAssociatedParties">Click here to start adding text</textarea> <span
                class="edit-icon"> click to edit</span>
            <p id="dapText"></p>
        </div>
    </form>
    <ng-container [ngTemplateOutlet]="directRelationships"></ng-container>
</section>

<ng-template #directRelationships>
    <div class="container">
        <div class="table related-parties">
            <div class="row bg-dark text-light">
                <div class="col">
                    Name
                </div>
                <div class="col">
                    Location
                </div>
                <div *ngIf="hasDirectStandardParties" class="col">
                    Status
                </div>
                <div class="col col-md-4">
                    Relationship
                </div>
            </div>
            <div *ngFor="let actorEdge of connectedActorEdges" class="row">
                <div class="col">
                    <p>{{getActorName(actorEdge)}}</p>
                </div>
                <div class="col">
                    <p>{{getActorLocation(actorEdge)}}</p>
                </div>
                <div *ngIf="hasDirectStandardParties" class="col">
                    <div>
                        <p>{{getActorStatus(actorEdge)}}</p>
                    </div>
                    <div>
                        <p>{{getActorStartDate(actorEdge) | date}}</p>
                    </div>
                    <div>
                        <p>{{getActorEndDate(actorEdge) | date}}</p>
                    </div>
                </div>
                <div class="col col-md-4">
                    <div class="relationship">
                        <ng-container
                            [ngTemplateOutlet]="premiumUtils.isPremiumEdge(actorEdge) ? premiumEdgeDesc : standardEdgeDesc"
                            [ngTemplateOutletContext]="{edge:actorEdge}">
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #indirectRelationships>
    <div class="container">
        <div class="table related-parties d-table">
            <div class="row bg-dark text-light">
                <div class="col">
                    Name
                </div>
                <div class="col">
                    Location
                </div>
                <div *ngIf="hasIndirectStandardParties" class="col">
                    Status
                </div>
                <div class="col col-md-4">
                    Relationship
                </div>
                <div class="col">
                    Other Party
                </div>
            </div>
            <div *ngFor="let actorEdge of levelTwoConnectedActorEdges" class="row">
                <td class="col">
                    <p>{{actorEdge.sourceActor.name}}</p>
                </td>
                <td class="col">
                    <p>{{getActorLocation(actorEdge)}}</p>
                </td>
                <td *ngIf="hasIndirectStandardParties" class="col">
                    <p>{{getActorStatus(actorEdge)}}</p>
                </td>
                <td class="col col-md-4">
                    <div class="relationship">
                        <ng-container
                            [ngTemplateOutlet]="premiumUtils.isPremiumEdge(actorEdge) ? premiumEdgeDesc : standardEdgeDesc"
                            [ngTemplateOutletContext]="{edge:actorEdge}">
                        </ng-container>
                    </div>
                </td>
                <td class="col">
                    <p>{{actorEdge.targetActor.name}}</p>
                </td>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #premiumEdgeDesc let-edge='edge'>
    <div *ngFor="let relationship of splitEdgeDescriptionByPipe(edge?.description)">
        <ng-container
            [ngTemplateOutlet]="isPremiumRelationshipCategory(relationship) ? premiumRelationshipDesc : premiumRelationshipCategory"
            [ngTemplateOutletContext]="{relationship:relationship}">
        </ng-container>
    </div>
</ng-template>

<ng-template #standardEdgeDesc let-edge='edge'>
    <p><small>{{edge?.description}}</small></p>
</ng-template>

<ng-template #premiumRelationshipDesc let-relationship='relationship'>
    <p class="fw-bold premium-category" *ngIf="relationship"><small>{{relationship}}</small></p>
</ng-template>

<ng-template #premiumRelationshipCategory let-relationship='relationship'>
    <ul *ngIf="relationship">
        <li>
            <p><small>{{relationship}}</small></p>
        </li>
    </ul>
</ng-template>

<section *ngIf="levelTwoConnectedActorEdges?.length > 0">
    <br /><br />
    <h2>Indirectly Associated Parties</h2>
    <form [formGroup]="this.dashService.getInvestigation().reportActorFreeText">
        <div class="wrapper">
            <textarea #textarea id="iap" type="textarea" class="discardable-text"
                placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
                formControlName="indirectlyAssociatedParties">Click here to start adding text</textarea> <span
                class="edit-icon"> click to edit</span>
            <p id="iapText"></p>
        </div>
    </form>
    <ng-container [ngTemplateOutlet]="indirectRelationships"></ng-container>
</section>
<br />