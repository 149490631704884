import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActorEdge, IActorBase } from 'src/app/dashboard/models/Actor';
import { DashboardService } from 'src/app/services/dashboard.service';
import { EntityTypes, ICompanyInfo, IIndividualInfo, OperationInfo } from 'src/nswag';

@Component({
  selector: 'app-report-redflags',
  templateUrl: 'redflags.component.html',
  styleUrls: ['redflags.component.scss']
})

export class RedFlagsComponent implements OnInit {

  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public selectedActor: IActorBase;

  public genericEdge: ActorEdge;
  public genericActor: IActorBase;

  public ActorTypeEnums = EntityTypes;

  public selectedCompanyInfo: ICompanyInfo;
  public selectedIndividualInfo: IIndividualInfo;
  public operations: OperationInfo[];

  constructor(public dashService: DashboardService) {
    this.selectedActor = this.dashService.getInvestigation();
    for (let edge of this.dashService.getConnectedActorEdges(this.selectedActor)) {
      if (edge.sourceActor.actorType == EntityTypes.Operation)
      {        
        // We have an associated operation
        this.genericEdge = edge;
        this.genericActor = edge.sourceActor;
      }
      else if (edge.targetActor.actorType == EntityTypes.Operation)
      {        
        // We have an associated operation
        this.genericEdge = edge;
        this.genericActor = edge.targetActor;
      }
      else if (edge.sourceActor.actorType == EntityTypes.Individual)
      {        
        // We have an associated individual
        this.genericEdge = edge;
        this.genericActor = edge.sourceActor;
      }
      else if (edge.targetActor.actorType == EntityTypes.Individual)
      {        
        // We have an associated individual
        this.genericEdge = edge;
        this.genericActor = edge.targetActor;
      }
    }

    this.selectedCompanyInfo = this.dashService.getInvestigation()?.asCompanyInfo;
    this.operations = this.dashService.getInvestigation()?.asCompanyInfo?.operations;
    this.selectedIndividualInfo = this.selectedActor?.asIndividualInfo;
  }

  ngOnInit() {
    setTimeout(() => {
      this.resizeAllTextAreas();
    }, 3000);
  }

  public splitCSVList(data: string) {
    if (!data) return null;
    return data.split(',').map(item => item.trim());
  }

  resizeAllTextAreas() {
    this.textareas.toArray().forEach((item) => { 
      item.nativeElement.style.height = 'auto';
      item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
    }); 
  }
}
