import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { IndustryList } from 'src/app/models/industry-list';
import { Guid } from 'src/app/utils/guid';
import { AlertService } from 'src/app/_alert';
import { AddAssessmentCommand, AddAssessmentRecipientCommand, TenantManagementClient, ApiResponseOfIndustryList, ApiResponseOfListOfGuid, ApiResponseOfQuestionSetDetailList, AssignAssessmentRecipientsCommand, CopyAssessmentCommand, GetAssessmentsQuery, QuestionSetDetail, Recipient, UpdateAssessmentCommand, UpdateAssessmentRecipientCommand, UpdateSelectedQuestionSetsCommand, SendEmailCommand, AssessmentRecipient, SendAssessmentRecipientEmailsCommand, ApiResponseOfAssessmentRecipientList, ApiResponseOfRecipientQuestionSetResponseList, QuestionSetSummaryList, AdminClient, UpdateAssessmentSettingsCommand, ApiResponseOfAssessmentSettings, ApiResponseOfAuditList, ApiResponseOfGetTenantDetailsModel, ApiResponseOfQuestionSetSummaryList } from 'src/nswag';
import { ApiResponseOfClientAuditLogs } from 'src/nswag';
import { RecipientManagementClient, AssessmentManagementClient, AssessmentSupportClient, ApiResponseOfBusinessProfile, Assessment, RecipientClient } from 'src/nswag';

@Injectable({
  providedIn: 'root'
})

export class RiskAssessmentService {
  public static pageSize = 10;

  public static defaultEmailMessage = 'You have been invited by {{SenderName}} from {{CompanyName}} to complete this risk assessment.';
  public static defaultWelcomeMessage = 'Welcome to the {{AssessmentName}} assessment.';

  constructor(private tenantClient: TenantManagementClient, private recipientManagementClient: RecipientManagementClient, private assessmentManagementClient: AssessmentManagementClient, private assessmentSupportClient: AssessmentSupportClient, private recipientClient: RecipientClient, private modalService: NgbModal, private alertService: AlertService, private adminClient: AdminClient) {
    this.currentAssessmentList = new Array<Assessment>();
  }

  private currentAssessmentList: Assessment[] = [];
  private currentAssessmentListTotal: number;
  private currentAssessmentListQuery = new GetAssessmentsQuery({ pageSize: RiskAssessmentService.pageSize });

  private currentAssessment: Assessment;

  private currentRecipientList: Recipient[] = [];
  private currentRecipientListTotal: number;
  private currentRecipientListQuery = new GetAssessmentsQuery();

  private currentRecipient: AssessmentRecipient;

  private currentExistingRecipientList: Recipient[] = [];
  private currentExistingRecipientListTotal: number;

  private selectedExistingRecipient: Assessment;

  public get AssessmentList(): Assessment[] {
    return this.currentAssessmentList;
  }

  public get AssessmentListTotal(): number {
    return this.currentAssessmentListTotal ?? 0;
  }

  public get AssessmentListPage(): number {
    return this.currentAssessmentListQuery?.pageNumber ?? 1;
  }

  public get CurrentAssessment(): Assessment {
    return this.currentAssessment;
  }

  //Recipients
  public get RecipientList(): AssessmentRecipient[] {
    return this.currentRecipientList;
  }

  public get RecipientListTotal(): number {
    return this.currentRecipientListTotal ?? 0;
  }

  public get RecipientListPage(): number {
    return this.currentRecipientListQuery?.pageNumber ?? 1;
  }

  public get CurrentRecipient(): AssessmentRecipient {
    return this.currentRecipient;
  }

  //Existing Recipients
  public get ExistingRecipientList(): Assessment[] {
    return this.currentExistingRecipientList;
  }

  public get ExistingRecipientListTotal(): number {
    return this.currentExistingRecipientListTotal ?? 0;
  }

  public get SelectedExistingRecipient(): Assessment {
    return this.selectedExistingRecipient;
  }

  public SetCurrentAssessmentList(assessment: Assessment) {
    if (!assessment) {
      this.resetGlobals(true);
      return;
    }

    this.currentAssessment = assessment;
    this.refreshAssessmentList(assessment);
  }

  public SetCurrentAssessment(assessment: Assessment) {
    if (!assessment) {
      this.resetGlobals(true);
      return;
    }

    this.currentAssessment = assessment;
    this.refreshAssessment(assessment);
  }
  public loadAssessmentsPage(pageNumber: number) {
    if (this.currentAssessmentListQuery) {
      this.currentAssessmentListQuery.pageNumber = pageNumber;
      this.internalSearchAssessmentsWithUpdatedQuery();
    }
  }

  public refreshAssessment(assessment: Assessment) {
    if (this.currentAssessment) {
      let i = this.currentAssessmentList.findIndex(val => val.id == assessment.id);
      if (i > -1) {
        this.currentAssessmentList[i] = assessment;
      }
    }
  }
  public refreshAssessmentList(assessment: Assessment) {
    if (this.currentAssessmentList) {
      if (this.currentAssessmentList.length < 10) {
        let i = this.currentAssessmentList.findIndex(val => val.id == assessment.id);
        if (i == -1) {
          this.currentAssessmentList = [assessment].concat(this.currentAssessmentList);
        } else {
          this.currentAssessmentList[i] = assessment;
        }
      }
      else {
        this.loadAssessments();
      }
    }
  }

  public loadAssessments() {
    var obs = this.assessmentManagementClient.getAssessments(new GetAssessmentsQuery({ pageNumber: 1, pageSize: 10 }));
    obs.subscribe(r => {
      if (r.isSuccess) {
        this.currentAssessmentList = r.data.results;
        this.currentAssessmentListTotal = r.data.totalCount;
      }
    });
  }

  public loadIndustries(): Observable<ApiResponseOfIndustryList> {
    return this.assessmentSupportClient.getIndustries();
  }

  public copyAssessment(assessment: Assessment) {
    let assessmentId = assessment.id
    let cmd = new CopyAssessmentCommand(assessment);
    return this.assessmentManagementClient.copyAssessment(assessmentId, cmd);
  }

  public addAssessment(assessment: Assessment) {
    let cmd = new AddAssessmentCommand(assessment);
    return this.assessmentManagementClient.addAssessment(cmd);
  }

  public editAssessment(assessment: Assessment) {
    const cmd = new UpdateAssessmentCommand(assessment);
    return this.assessmentManagementClient.updateAssessment(cmd);
  }

  public searchForAssessments(search: string) {
    this.internalSearchAssessments(search);
  }

  public deleteAssessment(assessment: Assessment): Observable<any> {
    return this.assessmentManagementClient.deleteAssessment(assessment?.id);
  }

  public getAssessment(assessment: Assessment): Observable<ApiResponseOfBusinessProfile> {
    return null;
    //this.riskAssessmentClient.getAssessment(assessment.id);
  }

  public getClientAuditLogs(clientId: string): Observable<ApiResponseOfClientAuditLogs> {
    return null;
    //this.AssessmentManagementClient.getClientLogs(clientId);
  }

  public refreshCurrentAssessment(assessment: Assessment) {
    this.SetCurrentAssessment(assessment);
  }

  public getIndustryNameById(id?: string): string {
    if (id) {
      let industry = IndustryList.find((v) => {
        if (id.toLowerCase() == v.Id.toLowerCase()) {
          return true;
        }
        return false;
      });
      return (industry?.Name ?? id);
    }
    return null;
  }

  public getIndustryIdByName(name?: string): string {
    if (name) {
      let industry = IndustryList.find((v) => {
        if (name.toLowerCase() == v.Name.toLowerCase()) {
          return true;
        }
        return false;
      });
      return (industry?.Id ?? name);
    }
    return null;
  }

  public removeAssessment() {
    this.loadAssessmentsPage(this.AssessmentListPage);
    this.SetCurrentAssessmentList(null);
  }

  private internalSearchAssessments(search?: string | null | undefined, pageNumber = 1) {
    this.resetGlobals(false);
    this.currentAssessmentListQuery = new GetAssessmentsQuery({
      search: search,
      pageSize: RiskAssessmentService.pageSize,
      pageNumber: pageNumber
    });
    this.internalSearchAssessmentsWithUpdatedQuery();
  }

  private internalSearchAssessmentsWithUpdatedQuery() {
    if (this.currentAssessmentListQuery) {
      var obs = this.assessmentManagementClient.getAssessments(this.currentAssessmentListQuery);
      obs.subscribe(r => {
        if (r.isSuccess) {
          this.currentAssessmentList = r.data.results;
          this.currentAssessmentListTotal = r.data.totalCount;
        }
      });
    }
  }

  public loadRecipients(assessmentId: string) {
    var obs = this.recipientManagementClient.getAssessmentRecipients(assessmentId, 1, 100);
    obs.subscribe(r => {
      if (r.isSuccess) {
        this.currentRecipientList = r.data.results;
        this.currentRecipientListTotal = r.data.totalCount;
      }
    });
  }

  public loadAllRecipients(){
    return this.recipientClient.getAllRecipients();
  }

  public loadRecipientsPage(pageNumber: number) {
    //   if (this.currentRecipientListQuery) {
    //     this.currentRecipientListQuery.pageNumber = pageNumber;
    //     this.internalSearchRecipientsWithUpdatedQuery();
    //   }
  }

  public refreshCurrentRecipient(recipient: Recipient) {
    this.SetCurrentRecipient(recipient);
  }

  public SetCurrentRecipient(recipient: Recipient) {
    if (!recipient) {
      this.resetGlobals(true);
      return;
    }

    this.currentRecipient = recipient;
    this.refreshRecipient(recipient);
  }

  public GetCurrentRecipient = () => this.currentRecipient;

  private refreshRecipient(recipient: Recipient) {
    if (this.currentRecipient) {
      let i = this.currentRecipientList.findIndex(val => val.id == recipient.id);
      if (i > -1) {
        this.currentRecipientList[i] = recipient;
      }
    }
  }

  public refreshRecipientsList(recipient: Recipient) {
    if (this.currentRecipientList) {
      let i = this.currentRecipientList.findIndex(val => val.id == recipient.id);
      if (i == -1) {
        this.currentRecipientList = [recipient].concat(this.currentRecipientList);
      }
    }
  }

  public removeFromRecipientsList(id: Guid) {
    if (this.currentRecipientList) {
      this.currentRecipientList = this.currentRecipientList.filter(item => item.id !== id);
    }
  }

  public addRecipient(recipient: Recipient) {
    let assessmentId = this.CurrentAssessment.id;
    let cmd = new AddAssessmentRecipientCommand({
      assessmentId: assessmentId,
      companyName: recipient.companyName,
      contactEmail: recipient.contactEmail,
      contactName: recipient.contactName,
      industryVerticalsId: recipient.industryVerticalsId,
      industryVerticalsOther: recipient.industryVerticalsOther
    });

    return this.recipientManagementClient.addAssessmentRecipient(cmd);
  }

  public editAssessmentRecipient(assessmentRecipient: Recipient) {
    let assessmentId = this.CurrentAssessment.id;
    let cmd = new UpdateAssessmentRecipientCommand({
      assessmentId: assessmentId,
      companyName: assessmentRecipient.companyName,
      contactEmail: assessmentRecipient.contactEmail,
      contactName: assessmentRecipient.contactName,
      industryVerticalsId: assessmentRecipient.industryVerticalsId,
      industryVerticalsOther: assessmentRecipient.industryVerticalsOther,
      id: assessmentRecipient.id
    });

    return this.recipientManagementClient.updateAssessmentRecipient(cmd);
  }

  public getAssessmentQuestionSets(assessmentId: string): Observable<ApiResponseOfQuestionSetSummaryList> {
    return this.assessmentManagementClient.getAssessmentQuestionSets(assessmentId);
  }

  public loadResponsesByAssessmentId(assessmentId: string) {
    return this.assessmentManagementClient.getAssessmentResponses(assessmentId)
  }

  public loadResponsesByCompletedAssessmentId(assessmentId: string) {
    return this.assessmentManagementClient.getCompletedAssessmentResponses(assessmentId)
  }

  public removeAssessmentRecipient(assessmentId: string, recipientId: string) {
    return this.recipientManagementClient.unAssignAssessmentRecipient(assessmentId, recipientId);
  }

  public lockAssessmentRecipient(assessmentId: string, recipientId: string, lock: boolean) {
    return this.recipientManagementClient.lockRecipient(assessmentId, recipientId, lock);
  }

  public loadExistingRecipients() {
    var obs = this.recipientClient.getAllRecipients();
    obs.subscribe(r => {
      if (r.isSuccess) {
        //remove existing recipients from object
        this.currentExistingRecipientList = r.data.results.filter(item => !this.currentRecipientList.find(x => x.id == item.id));
        this.currentExistingRecipientListTotal = r.data.totalCount;
      }
    });
  }

  public loadIndustryVerticalsAverageByIndustryVerticalsId(industryVerticalsId: string){
    return this.assessmentSupportClient.getIndustryAverageByIndustryVerticalsId(industryVerticalsId);
  }

  public addExistingRecipient(recipients: Recipient[]) {
    let cmd = new AssignAssessmentRecipientsCommand({
      assessmentId: this.CurrentAssessment.id,
      recipients: recipients
    });

    return this.recipientManagementClient.assignAssessmentRecipients(cmd);
  }

  private resetGlobals(justSelected: boolean) {
    if (!justSelected) {
      this.currentAssessmentList = [];
      this.currentAssessmentListQuery = null;
    }
    this.currentAssessment = null;
  }

  public getQuestionSets(): Observable<ApiResponseOfQuestionSetDetailList> {
    return this.assessmentSupportClient.getQuestionSets();
  }

  public getResponseBySelectedRecipient(selectedRecipientIds: string[]): Observable<ApiResponseOfRecipientQuestionSetResponseList> {
    return this.recipientManagementClient.getResponseBySelectedRecipientIds(1, 10, selectedRecipientIds);
  }

  public getSelectedQuestionSets(assessment: Assessment): Observable<ApiResponseOfListOfGuid> {
    return this.assessmentManagementClient.getAssessmentQuestionSetIds(assessment.id);
  }

  public getAuditTrail(assessment: Assessment, pageNumber: number, pageSize: number): Observable<ApiResponseOfAuditList> {
    return this.assessmentManagementClient.getAssessmentAudit(assessment.id, pageNumber, pageSize);
  }

  public submitQuestionSets(assessmentId: string, questionSets: QuestionSetSummaryList) {
    let cmd = new UpdateSelectedQuestionSetsCommand({
      assessmentId: assessmentId,
      questionSets: questionSets
    });

    return this.assessmentManagementClient.updateAssessmentQuestionSets(cmd);
  }

  public sendAssessment(assessment: Assessment) {
    return this.assessmentManagementClient.sendAssessment(
      new SendAssessmentRecipientEmailsCommand(
        {
          assessmentId: assessment.id,
          emailMessage: assessment.emailMessage,
          welcomeMessage: assessment.welcomeMessage
        }), assessment.id);
  }

  public sendAssessmentRecipient(assessmentRecipient: AssessmentRecipient) {
    let cmd = new SendAssessmentRecipientEmailsCommand();
    cmd.recipientIds = [this.currentRecipient.id];
    cmd.emailMessage = assessmentRecipient.emailMessage ?? this.CurrentAssessment.emailMessage;
    cmd.welcomeMessage = assessmentRecipient.welcomeMessage ?? this.CurrentAssessment.welcomeMessage;
    cmd.assessmentId = this.CurrentAssessment.id;

    return this.recipientManagementClient.sendRecipient(cmd, this.CurrentAssessment.id, this.currentRecipient.id);
  }

  public updateAssessmentSettings(command: UpdateAssessmentSettingsCommand): Observable<ApiResponseOfAssessmentSettings> {
    return this.adminClient.updateAssessmentSettings(command);
  }

  public getAssessmentSettings(tenantId: string): Observable<ApiResponseOfAssessmentSettings> {
    return this.adminClient.getAssessmentSettings(tenantId);
  }

  public getUsersTenantDetails(): Observable<ApiResponseOfGetTenantDetailsModel> {
    return this.tenantClient.getTenantDetails();
  }
}
