import { Component, OnInit } from '@angular/core';
import { LoginMenuService } from 'src/api-authorization/login-menu/login-menu.service';
import { ZignSecClient } from 'src/nswag';

@Component({
  selector: 'app-start-idv',
  templateUrl: './idvStart.component.html',
  styleUrls: ['./idvStart.component.scss']
})

export class IdvStartComponent implements OnInit {
  public isStarted = false;
  
  constructor(private idvClient: ZignSecClient, menuService: LoginMenuService) { 
    // Turn the menu off!
    menuService.hideMenu = true;
  }
  
  ngOnInit(): void {
  }

  public start() {
    if (!this.isStarted) {
      this.isStarted = true;
      this.idvClient.start().subscribe(r => {
        window.location.href = r.data;
      });
    }
  }

  public close() {
      window.close();
  }
}