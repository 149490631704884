import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SearchService } from 'src/app/search/services/search.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { AlertService } from 'src/app/_alert';
import { EntityTypes, MediaClient, UploadCommand, UploadFileMetadata } from 'src/nswag';
import { IActorBase } from '../models/Actor';
import { SubjectEditAuditLog } from '../models/Models';
import { DatePickerResult } from 'src/app/date-picker/date-picker.component';
import { FileService } from 'src/app/utils/file.service';

@Component({
  selector: 'app-subject-editor',
  templateUrl: './subject-editor.component.html',
  styleUrls: ['./subject-editor.component.scss']
})
export class SubjectEditorComponent {

  public EntityTypeEnums = EntityTypes;
  public dateIsValid = true;
  private subject: IActorBase;
  private latestEdit: SubjectEditAuditLog = new SubjectEditAuditLog();
  private maxFileSize: number = 2097152;
  @Input() dateOfBirth: string;
  
  constructor(private activeModal: NgbActiveModal, public dashService: DashboardService, public searchService: SearchService, private alertService: AlertService, private mediaClient: MediaClient, private dateFormatter: NgbDateParserFormatter) { 
    this.subject = this.dashService.getInvestigation();
  }

  dismissModal() {
    this.checkDirty();
    this.dashService.getInvestigation().infoObject.dateOfBirth = this.dateOfBirth;
    this.activeModal.dismiss();
  }

  public hasModelErrors(): boolean {
    return !this.dateIsValid;
  }

  public dateChanged(dateResult: DatePickerResult) {
    this.dateIsValid = dateResult.isValid;
    if (this.dateIsValid) {
      this.dateOfBirth = this.dateFormatter.format(dateResult.ngbValue);
    }
  }
  
  logEdit(log: SubjectEditAuditLog) {
    var fieldName = log.fieldName;
    var fieldValue = log.fieldValue;
    if(fieldName && fieldValue) {
      this.latestEdit.fieldName = fieldName;
      this.latestEdit.fieldValue = fieldValue;
      this.latestEdit.subjectName = this.subject.name;

      // Only keep the latest log value
      var logIndex = this.dashService?.subjectEditHistory?.findIndex(x => x.fieldName == fieldName);
      if(logIndex != -1) {
        this.dashService.subjectEditHistory[logIndex] = this.latestEdit;
      } else {
        this.dashService.subjectEditHistory.push(this.latestEdit);
      }
      this.latestEdit = new SubjectEditAuditLog();
    }
  }

  checkDirty() {
    if(this.dashService?.subjectEditHistory) {
      this.dashService?.subjectEditHistory?.forEach(e => {
        if(this.dashService?.subjectEditHistory?.length > 0)
        {
          this.dashService.hasChanged = true;
        }
      });
    }
  }

  onSelectFile(event) {
    const self = this;
    if (event?.target?.files && event?.target?.files[0]) {
      const file = event?.target?.files[0];
      FileService.LoadFile(file, (meta) => {
        if (meta.fileSize > this.maxFileSize) {
          var alertOptions = {
            autoClose: false,
            keepAfterRouteChange: false
          };
          this.alertService.warn('Image uploads must be less than 2MB in size.', alertOptions);
          event.target.value = null;
        } else {
          var payload: UploadCommand = new UploadCommand();
            payload.file = meta;

          this.mediaClient.upload(payload).subscribe(result => {
            if(result) {
              self.subject.profileImageId = result;
              self.dashService.setActorStyle(self.subject);
              self.dashService.addAuditTrail(`Uploaded Avatar Image '${result}'`);
              var editLog: SubjectEditAuditLog = new SubjectEditAuditLog();
                editLog.fieldName = 'Avatar Image';
                editLog.fieldValue = result;
              self.logEdit(editLog);
            }
          });
        }
      });
    }
  }
}
