import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Assessment, AssessmentRecipient, AssessmentRecipientList, Recipient, SelectedRecipientStatus } from 'src/nswag';
import { RiskAssessmentService } from '../../services/risk-assessment.service';

@Component({
  selector: 'recipient-list',
  templateUrl: './recipient-list.component.html',
  styleUrls: ['./recipient-list.component.scss']
})
export class RecipientListComponent implements OnChanges {

  @Input() currentPage = 1;
  @Input() recipientList : AssessmentRecipient[];
  @Input() recipientTotal = 0;
  @Output() sendEvent = new EventEmitter<AssessmentRecipient>();
  @Output() lockEvent = new EventEmitter<AssessmentRecipient>();
  @Output() viewAnswersEvent = new EventEmitter<AssessmentRecipient>();
  @Output() editEvent = new EventEmitter<Assessment>();
  @Output() removeEvent = new EventEmitter<Assessment>();
  @Output() screenEvent = new EventEmitter<Assessment>();
  @Output() monitorEvent = new EventEmitter<Assessment>();
  @Output() selectEvent = new EventEmitter<AssessmentRecipient>();
  @Output() viewResultsEvent = new EventEmitter<AssessmentRecipient>();

  @Output() changePageEvent = new EventEmitter<number>();

  public pageSize = RiskAssessmentService.pageSize;
  // These are to support various row sizes per page - left in in case we use it when server side support added
  //public pageSizes = [10, 20, 50];
  //public currentIndex = -1;

  public currentRecipient: AssessmentRecipient;
  public assessmentRecipientList: AssessmentRecipientList;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public handlePageChange(event: number): void {
    this.currentPage = event;
    this.changePageEvent.emit(event);
  }

  public canLock(): boolean{
    if (this.currentRecipient?.status === SelectedRecipientStatus.Locked
      || this.currentRecipient?.status === SelectedRecipientStatus.InProgress
      || this.currentRecipient?.status === SelectedRecipientStatus.Sent 
      || this.currentRecipient?.status === SelectedRecipientStatus.Complete){
        return true
    }
    return false;
  }

  public isLocked(): boolean {
    if (this.currentRecipient?.status === SelectedRecipientStatus.Locked 
      || this.currentRecipient?.status === SelectedRecipientStatus.Complete){
        return true;
    }
    return false;
  }

  public canViewResults(): boolean {
    if (this.currentRecipient?.status === SelectedRecipientStatus.Complete ||
      this.currentRecipient?.status === SelectedRecipientStatus.InProgress){
        return true;
    }
    return false;
  }

  public isComplete(): boolean {
    return this.currentRecipient?.status === SelectedRecipientStatus.Complete 
  }
  public isInProgress(): boolean {
    return this.currentRecipient?.status === SelectedRecipientStatus.InProgress 
  }

  public displayAssignedTo(recipientResult: AssessmentRecipient) : string {
    return recipientResult?.contactName;
  }

  public displayCompany(recipientResult: AssessmentRecipient) : string {
    return recipientResult?.companyName;
  }

  public displayDateCreated(recipientResult: AssessmentRecipient) : string {
    return recipientResult?.sentOn?.toDateString();
  }

  public isErrorSending = (recipientResult: AssessmentRecipient) : boolean => recipientResult.status === SelectedRecipientStatus.ErrorSending;

  public displayDateCompleted(recipientResult: AssessmentRecipient) : string {
    return recipientResult?.completedOn?.toDateString();
  }


  public selectRecipient(recipientResult: AssessmentRecipient) {
    this.selectEvent.emit(recipientResult);
  }

  public setFocusedRecipient(recipientResult: AssessmentRecipient) {
    this.currentRecipient = recipientResult;
  }

  public dropDownClicked(event) {
    event.stopPropagation();
  }

  public sendRecipient(event) {
    event.stopPropagation();
    this.sendEvent.emit(this.currentRecipient);
  }

  public lockRecipient(event) {
    event.stopPropagation();
    this.lockEvent.emit(this.currentRecipient);
  }

  public viewResults(event){
    event.stopPropagation();
    this.viewResultsEvent.emit(this.currentRecipient);
  }

  public viewAnswers(event) {
    event.stopPropagation();
    this.viewAnswersEvent.emit(this.currentRecipient);
  }

  public editRecipient(event) {
    event.stopPropagation();
    this.editEvent.emit(this.currentRecipient);
  }

  public removeRecipient(event) {
    event.stopPropagation();
    this.removeEvent.emit(this.currentRecipient);
  }

  public screenRecipient(event) {
    event.stopPropagation();
    this.screenEvent.emit(this.currentRecipient);
  }

  public monitorRecipient(event) {
    event.stopPropagation();
    this.monitorEvent.emit(this.currentRecipient);
  }
}
