<div *ngIf="hasBusinessDiscounts">
    <div *ngIf="incHeader">
        <br><hr>
        <h4>Discounted Associated Businesses</h4>
    </div>
    <div class="row pt-2">
        <div class="col-sm-8 ">
            <h5>Business</h5>
        </div>
        <div class="col-sm-4">
            <h5>Reason</h5>
        </div>
    </div>
    <div *ngFor="let bus of businessLinks; let pos=index">
        <div *ngIf="bus?.discountReason?.isDiscounted" class="row pt-2">
            <div class="col-sm-8">
                <strong>{{bus.name}}</strong><br>
                <span>{{bus.relationship}}</span>
                <span *ngIf="bus.ownershipPercentage"><br>Owns {{bus.ownershipPercentage}}%</span>
            </div>
            <div class="col-sm-4">
                <span>{{bus.discountReason.reason}}</span>
            </div>
        </div>
    </div>
</div>


