import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISubjectInfo } from 'src/nswag';
import { CompanyActor, edgeType, IActorBase, IndividualActor, OfficerActor, DiligenciaOrganisationActor, DiligenciaPersonActor } from '../dashboard/models/Actor';
import { DashboardService } from '../services/dashboard.service';
import { SearchCriteria } from './models/search-criteria';
import { SearchResultState } from './models/search-result-state';
import { SearchDialogService } from './search-dialog.service';
import { SearchService } from './services/search.service';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchDialogComponent {

    @Input() searchCriteria: SearchCriteria;
 
    public searchResultState: SearchResultState;
  
    constructor(public searchService: SearchService, private searchDialogService: SearchDialogService, private activeModal: NgbActiveModal, private dashService: DashboardService) {
      this.searchResultState = new SearchResultState();
    }

    runSearch() {
        this.searchService.runSearch(this.searchCriteria, this.searchResultState);
    }

    public dismiss() {
      this.activeModal.dismiss();
    }

    public createDummyAndRedirect() {
      this.searchDialogService.selectedActor = this.searchResultState.CreateDummyActor(this.searchCriteria);
      this.searchDialogService.selectedActor.additionReason = this.searchDialogService.selectedActor.name + "was manually added to the map.";
      this.activeModal.close(true);
    }

  public onResult(subject: ISubjectInfo) {
    if (subject?.officer) {
      this.searchDialogService.selectedActor = new OfficerActor(subject.officer);
    }
    else if (subject?.individual) {
      this.searchDialogService.selectedActor = new IndividualActor(subject.individual);
    }
    else if (subject?.company) {
      this.searchDialogService.selectedActor = new CompanyActor(subject.company);
    }
    else if (subject?.diligenciaPerson) {
      this.searchDialogService.selectedActor = new DiligenciaPersonActor(subject.diligenciaPerson);
    }
    else if (subject?.diligenciaOrganisation) {
      this.searchDialogService.selectedActor = new DiligenciaOrganisationActor(subject.diligenciaOrganisation);
    }
    else {
      console.log("Attempt to add unknown subject node.");
      return;
    }

    this.searchDialogService.selectedActor.additionReason = this.searchDialogService.selectedActor.name + "was manually added to the map.";
    this.activeModal.close(true);
  }
}
