<table class='table table-striped table-themis' aria-labelledby="tableLabel">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Created By</th>
      <th scope="col">Date of Screening</th>
      <th scope="col">Match Status</th>
      <th scope="col">Results</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let audit of auditList | paginate : {
      id: 'audit-list',
      itemsPerPage: pageSize,
      currentPage: currentPage,
      totalItems: auditTotal
    };
      let i = index" class="align-middle">

      <td>{{displayClientName(audit)}}</td>
      <td>{{displayCreatedByName(audit)}}</td>
      <td>{{displayCreationDate(audit)}}</td>
      <td>{{displayMatchStatus(audit)}}</td>
      <td>{{displayResults(audit)}}</td>
    </tr>
  </tbody>
</table>
  <pagination-controls class="pagination"
  previousLabel="Prev"
  nextLabel="Next"
  [responsive]="true"
  [autoHide]="hidePagination"
  (pageChange)="handlePageChange($event)">
</pagination-controls>
