import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdversemediaComponent } from './adversemedia/adversemedia.component';
import { EventsComponent } from './events/events.component';
import { EvidencesComponent } from './evidences/evidences.component';
import { ReadMoreModule } from '../_readmore/readmore.module';
import { DisqualdirectorComponent } from './disqualdirector/disqualdirector.component';
import { LegalComponent } from './legal/legal.component';
import { InsolvencyComponent } from './insolvency/insolvency.component';
import { SanctionsComponent } from './sanctions/sanctions.component';
import { SoeComponent } from './soe/soe.component';
import { IndividualProfileComponent } from './individualprofile/individualprofile.component';
import { BusinessprofileComponent } from './businessprofile/businessprofile.component';
import { AlertstatusComponent } from './alertstatus/alertstatus.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertIconsModule } from '../alert-icons/alter-icons.module';
import { PepsComponent } from './peps/peps.component';
import { BusinessAssociationsComponent } from './associations/business-associations/business-associations.component';
import { IndividualAssociationsComponent } from './associations/individual-associations/individual-associations.component';
import { DiscountReasonComponent } from './discount-reason/discount-reason.component';
import { FormsModule } from '@angular/forms';
import { DiscountedEvidenceComponent } from './discounted-evidence/discounted-evidence.component';
import { DiscountedLinksComponent } from './discounted-links/discounted-links.component';
import { DiscountedBusinessesComponent } from './discounted-links/discounted-businesses/discounted-businesses.component';
import { DiscountedIndividualsComponent } from './discounted-links/discounted-individuals/discounted-individuals.component';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [ 
    AdversemediaComponent, 
    EventsComponent,
    EvidencesComponent,
    DisqualdirectorComponent,
    LegalComponent,
    InsolvencyComponent,
    SanctionsComponent,
    IndividualProfileComponent,
    BusinessprofileComponent,
    SoeComponent,
    AlertstatusComponent,
    PepsComponent,
    BusinessAssociationsComponent,
    IndividualAssociationsComponent,
    DiscountReasonComponent,
    DiscountedEvidenceComponent,
    DiscountedLinksComponent,
    DiscountedBusinessesComponent,
    DiscountedIndividualsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReadMoreModule,
    AlertIconsModule,
    NgbModule,
    FormsModule,
    LoaderModule
  ],
  exports: [
    AlertstatusComponent,
    AdversemediaComponent,
    EventsComponent,
    EvidencesComponent,
    DisqualdirectorComponent,
    LegalComponent,
    InsolvencyComponent,
    SanctionsComponent,
    SoeComponent,
    IndividualProfileComponent,
    BusinessprofileComponent,
    PepsComponent,
    IndividualAssociationsComponent,
    BusinessAssociationsComponent,
    DiscountedEvidenceComponent,
    DiscountedLinksComponent,
    DiscountedIndividualsComponent,
    DiscountedBusinessesComponent
  ]

})
export class LampsPanelsModule {  }
