import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProblemDetailsComponent } from '../problem-details.component';



@NgModule({
  declarations: [ProblemDetailsComponent],
  imports: [
    CommonModule
  ],
  exports: [ProblemDetailsComponent]
})
export class ProblemDetailsModule { }
