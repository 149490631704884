import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientResult, IndividualClient } from 'src/nswag';
import { MonitorService } from '../services/monitor.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { ProfileService } from 'src/app/profile.service';
import { CountriesService } from 'src/app/utils/countries.service';

@Component({
  selector: 'mon-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() currentPage = 1;
  @Input() clientList : ClientResult[];
  @Input() clientTotal = 0;
  @Input() allowEdit = true;
  @Input() isArchived = false;
  @Input() showAlertDate: boolean = false;
  @Output() showClientReportEvent = new EventEmitter<ClientResult>();
  @Output() showHistoryReportEvent = new EventEmitter<ClientResult>();
  @Output() selectEvent = new EventEmitter<ClientResult>();
  @Output() deleteEvent = new EventEmitter<ClientResult>();
  @Output() recoverEvent = new EventEmitter<ClientResult>();
  @Output() editEvent = new EventEmitter<ClientResult>();
  @Output() changePageEvent = new EventEmitter<number>();
  @Output() sortPageEvent = new EventEmitter<boolean>();

  public sortAsc: boolean = false;
  public pageSize = MonitorService.pageSize;
  // These are to support various row sizes per page - left in in case we use it when server side support added
  //public pageSizes = [10, 20, 50];
  //public currentIndex = -1;

  public currentClient: ClientResult;
  public isAdministrator: boolean = false;

  constructor(private profileService: ProfileService) { 
    
  }

  ngOnInit(): void {
    this.isAdministrator = this.profileService.IsAdministrator;
  }

  public handlePageChange(event: number): void {
    this.currentPage = event;
    this.changePageEvent.emit(event);
  }

  public toggleSort(): void {
    this.sortAsc = !this.sortAsc
    this.sortPageEvent.emit(this.sortAsc);
}

  public displayAlertDate(clientResult: ClientResult) : string {
    let date = clientResult?.client?.alertGenerationDate;
    if (date){
      return UtilityService.convertToLocalDateTime(date, "short");
    }
  }
  // Displays company or person's name
  public displayClientName(clientResult: ClientResult) : string {
    return clientResult?.client?.business?.name ?? this.getDisplayName(clientResult?.client?.individual);
  }

  private getDisplayName(individual: IndividualClient): string {
    return UtilityService.getIndividualFullName(individual?.name, individual?.firstName, individual?.otherName, individual?.lastName);
  }

  public displayClientTags(clientResult: ClientResult) : string[] {
    return clientResult?.client?.business?.tags ?? clientResult?.client?.individual?.tags;
  }

  public displayClientIcon(clientResult: ClientResult) : string {
    return clientResult?.client?.business?.name ? "icon-city" : "icon-user";
  }
  
  public displayClientRef(clientResult: ClientResult) : string {
    return clientResult?.client?.business?.clientRef ?? clientResult?.client?.individual?.clientRef;
  }

  public displayDoB(clientResult: ClientResult) : string {
    let dob = clientResult?.client?.individual?.dateOfBirth;
    if (dob){
      dob = UtilityService.convertToLocalDateTime(new Date(dob), "short");
    }
    return dob;
  }
  
  public displayCountry(clientResult: ClientResult) : string {
    
    let name: string;
    if (this.isBusiness(clientResult))
    {
      name = CountriesService.getJurisdictionName(clientResult?.client?.business?.jurisdiction);
    }
    else {      
      name = CountriesService.getNationalityName(clientResult?.client?.individual?.nationality);
    }    
    return name;
  }

  private isBusiness(clientResult: ClientResult) : boolean {
    return clientResult?.client?.business ? true : false;
  }

  public selectClient(clientResult: ClientResult) {
    this.selectEvent.emit(clientResult);
  }

  public setFocusedClient(clientResult: ClientResult) {
    this.currentClient = clientResult;
  }

  public dropDownClicked(event) {
    event.stopPropagation();
  }

  public editClient(event) {
    event.stopPropagation();
    this.editEvent.emit(this.currentClient);
  }

  public deleteClient(event) {
    event.stopPropagation();
    this.deleteEvent.emit(this.currentClient);  
  }

  public recoverClient(event) {
    event.stopPropagation();
    this.recoverEvent.emit(this.currentClient);  
  }

  public viewClientReport(event) {
    event.stopPropagation();
    this.showClientReportEvent.emit(this.currentClient);
  }

  public viewHistoryReport(event) {
    event.stopPropagation();
    this.showHistoryReportEvent.emit(this.currentClient);
  }
}
