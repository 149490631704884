import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AlertStatus, BusinessMatch, Client, ClientResult, IndividualMatch, LAMPSStatus, MatchStatus } from 'src/nswag';

@Component({
  selector: 'app-lamps-group',
  templateUrl: './lamps-group.component.html',
  styleUrls: ['./lamps-group.component.scss']
})
export class LampsGroupComponent implements OnInit, OnChanges {

  @Input() datasets: string[];

  public client : ClientResult;

  constructor() {
    this.client = new ClientResult({
      client: new Client(),
      matchStatus: new MatchStatus({
        alertStatus: AlertStatus.Closed,
        hasAdvMedia: LAMPSStatus.No,
        hasLawEnforcement: LAMPSStatus.No,
        hasSanctions: LAMPSStatus.No,
        isPEP: LAMPSStatus.No,
        isMatched: LAMPSStatus.No
      })
    });
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.datasets) {
      for (let dataset of this.datasets) {
        switch (dataset) {
          case "PEP-CURRENT":
          case "PEP-FORMER":
          case "PEP-LINKED":
          case "POI":
          case "SOE-CURRENT":
          case "SOE-FORMER":
            this.client.matchStatus.isPEP = LAMPSStatus.Changed;
            break;
          case "SAN-CURRENT":
          case "SAN-FORMER":
            this.client.matchStatus.hasSanctions = LAMPSStatus.Changed;
            break;
          case "INS":
          case "DD":
          case "REL":
            this.client.matchStatus.hasLawEnforcement = LAMPSStatus.Changed;
            break;
          case "RRE":
            this.client.matchStatus.hasAdvMedia = LAMPSStatus.Changed;
            break;                                      
        }
      }
    }
    else {
      // These are set in the case that the profile has no LAMPS data to force the tick icon to display.
      this.client.matchStatus.isMatched = LAMPSStatus.Yes;
      this.client.client.screened = true;
      this.client.matchStatus.matches = LAMPSStatus.Yes;
    }
  }
}
