import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IDVService {

    constructor() {
    }

    public launchIDV() {
        let specs = 'fullscreen=yes,'; // IE only
        // size of window
        specs += 'height=800,';
        specs += 'width=600,';
        // location of window
        specs += 'top=100,';
        specs += 'left=100,';
        specs += 'location=no,'; // Opera only
        specs += 'menubar=no,';
        specs += 'resizable=no,'; // IE Only
        specs += 'scrollbars=no,'; // IE/FF/Op
        specs += 'status=no,';
        specs += 'titlebar=no,';
        specs += 'toolbar=no,'; // IE / FF
        specs= specs.substring(0, specs.length - 1);
        
        window.open('/idvStart', '_blank', specs);
    }
}