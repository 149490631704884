
<div>
    <div class="row justify-content-evenly summary-container">
        <div class="col-sm">
            <mon-alert (showOpen)="setShowOpenAlerts($event)" (outLampsTypes)="setLampsType($event)" title="Total Clients" icon="icon-alarm" totalTitle="Total Clients" openTitle="Total client open alerts" [counter]="summary?.total"></mon-alert>
        </div>
        <div class="col-sm">
            <mon-alert (showOpen)="setShowOpenAlerts($event)" (outLampsTypes)="setLampsType($event)" title="Sanctions" totalTitle="Clients with sanctions" openTitle="Clients with open sanction alerts" [counter]="summary?.sanctions" icon="icon-prohibited" [lampsType]="_lampsTypes.San"></mon-alert>
        </div>
        <div class="col-sm">
            <mon-alert (showOpen)="setShowOpenAlerts($event)" (outLampsTypes)="setLampsType($event)" title="Political Exposure" totalTitle="Clients with political exposure" openTitle="Clients with open political exposure alerts" icon="icon-library2"  [counter]="summary?.peps" [lampsType]="_lampsTypes.Pep"></mon-alert>
        </div>
        <div class="col-sm">
            <mon-alert (showOpen)="setShowOpenAlerts($event)" (outLampsTypes)="setLampsType($event)" title="Law Enforcement" totalTitle="Clients with law enforcement" openTitle="Clients with open law enforcement alerts" icon="icon-balance" [lampsType]="_lampsTypes.Law" [counter]="summary?.legal"></mon-alert>
        </div>
        <div class="col-sm">
            <mon-alert (showOpen)="setShowOpenAlerts($event)" (outLampsTypes)="setLampsType($event)" title="Adverse Media" totalTitle="Clients with adverse media" openTitle="Clients with open adverse media alerts" icon="icon-tv"  [lampsType]="_lampsTypes.Adv" [counter]="summary?.advMedia"></mon-alert>
        </div>
    </div>
</div>
