import { Injectable } from '@angular/core';
import { UploadFileMetadata } from 'src/nswag';

@Injectable({ providedIn: 'root' })
export class FileService
{
    public static LoadFile(file: File, callback: (meta: UploadFileMetadata) => void): void {        
        const meta = new UploadFileMetadata();
        const reader = new FileReader();
        meta.fileName = file.name;
        meta.fileSize = file.size;
        meta.fileType = file.type;
        meta.lastModifiedDate = new Date(file.lastModified);
        reader.onload = (e => {
            const parts = e?.target?.result?.toString()?.split(',');
            meta.fileAsBase64 = parts[1];
            callback(meta);
        });
        reader.readAsDataURL(file);
    }
}