<div *ngIf="hasIndividualDiscounts">
    <div *ngIf="incHeader">
        <br><hr>
        <h4>Discounted Associated Individuals</h4>
    </div>
    <div class="row pt-2">
        <div class="col-sm-8 ">
            <h5>Person</h5>
        </div>
        <div class="col-sm-4">
            <h5>Reason</h5>
        </div>
    </div>
    <div *ngFor="let ind of individualLinks; let pos=index">
        <div *ngIf="ind?.discountReason?.isDiscounted" class="row pt-2">
            <div class="col-sm-8">
                <strong>{{getName(ind)}}</strong><br>
                <span>{{ind.relationship}}</span>
                <span *ngIf="ind.ownershipPercentage"><br>Owns {{ind.ownershipPercentage}}%</span>
            </div>
            <div class="col-sm-4">
                <span>{{ind.discountReason.reason}}</span>
            </div>
        </div>
    </div>
</div>
