<section class="pagebreak">
    <br /><br />
    <h2>Risk Summary</h2>
    <form [formGroup]="dashService.reportForms">
        <div class="wrapper">
            <textarea #textarea id="riskSummary" type="textarea" class="discardable-text"
                placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAll()"
                formControlName="sraHeading"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="riskSummaryText"></p>
        </div>
        <div class="table d-table">
            <section>
                <div class="row table-dark text-light">
                    <div class="col">
                        <p class="text-light">Financial Crime Risk</p>
                    </div>
                    <div class="col">
                        <p class="text-light">Weighting</p>
                    </div>
                    <div class="col">
                        <p class="text-light">Comments</p>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('moneyLaunderingRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('moneyLaunderingRiskLevel')">
                        <p><strong>Money Laundering</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('moneyLaunderingRiskLevel')">
                        <select class="form-select form-control" formControlName="moneyLaunderingRiskLevel"
                            (change)="setRowColor('moneyLaunderingRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('moneyLaunderingRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="moneyLaundering" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="moneyLaunderingComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="moneyLaunderingText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('fraudRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('fraudRiskLevel')" >
                        <p><strong>Fraud</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('fraudRiskLevel')">
                        <select class="form-select form-control" formControlName="fraudRiskLevel"
                            (change)="setRowColor('fraudRiskLevel')">
                            <option value="" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('fraudRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea type="textarea" id="fraud" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="fraudComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="fraudText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('briberyCorruptionRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('briberyCorruptionRiskLevel')">
                        <p><strong>Bribery & Corruption</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('briberyCorruptionRiskLevel')">
                        <select class="form-select form-control" formControlName="briberyCorruptionRiskLevel"
                            (change)="setRowColor('briberyCorruptionRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('briberyCorruptionRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea type="textarea" id="bribery" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="briberyCorruptionComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="briberyText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('sanctionsRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('sanctionsRiskLevel')">
                        <p><strong>Sanctions</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('sanctionsRiskLevel')">
                        <select class="form-select form-control" formControlName="sanctionsRiskLevel"
                            (change)="setRowColor('sanctionsRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('sanctionsRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea type="textarea" id="sanctions" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="sanctionsComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="sanctionsText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('taxEvasionRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('taxEvasionRiskLevel')">
                        <p><strong>Tax Evasion</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('taxEvasionRiskLevel')">
                        <select class="form-select form-control" formControlName="taxEvasionRiskLevel"
                            (change)="setRowColor('taxEvasionRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('taxEvasionRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="tax" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="taxEvasionComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="taxText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('mshtRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('mshtRiskLevel')">
                        <p><strong>Modern Slavery/Human Trafficking</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('mshtRiskLevel')">
                        <select class="form-select form-control" formControlName="mshtRiskLevel"
                            (change)="setRowColor('mshtRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('mshtRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="msht" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="mshtComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="mshtText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('illegalWildlifeTradeRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('illegalWildlifeTradeRiskLevel')">
                        <p><strong>Illegal Wildlife Trade</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('illegalWildlifeTradeRiskLevel')">
                        <select class="form-select form-control" formControlName="illegalWildlifeTradeRiskLevel"
                            (change)="setRowColor('illegalWildlifeTradeRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('illegalWildlifeTradeRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="iwt" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="illegalWildlifeTradeComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="iwtText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('marketAbuseRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('marketAbuseRiskLevel')">
                        <p><strong>Market Abuse</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('marketAbuseRiskLevel')">
                        <select class="form-select form-control" formControlName="marketAbuseRiskLevel"
                            (change)="setRowColor('marketAbuseRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('marketAbuseRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="mAbuse" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="marketAbuseComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="mAbuseText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('cyberCrimeRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('cyberCrimeRiskLevel')">
                        <p><strong>Cyber Crime</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('cyberCrimeRiskLevel')">
                        <select class="form-select form-control" formControlName="cyberCrimeRiskLevel"
                            (change)="setRowColor('cyberCrimeRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('cyberCrimeRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="cybercrime" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="cyberCrimeComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="cybercrimeText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('proliferationFinancingRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('proliferationFinancingRiskLevel')">
                        <p><strong>Proliferation Financing</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('proliferationFinancingRiskLevel')">
                        <select class="form-select form-control" formControlName="proliferationFinancingRiskLevel"
                            (change)="setRowColor('proliferationFinancingRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('proliferationFinancingRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="pf" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="proliferationFinancingComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="pfText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('cyberTerrorismRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('cyberTerrorismRiskLevel')">
                        <p><strong>Cyber Terrorism</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('cyberTerrorismRiskLevel')">
                        <select class="form-select form-control" formControlName="cyberTerrorismRiskLevel"
                            (change)="setRowColor('cyberTerrorismRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('cyberTerrorismRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="ct" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="cyberTerrorismComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="ctText"></p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="row category" [style.background-color]="rowColors.get('terroristFinancingRiskLevel')">
                    <div class="col" [style.background-color]="rowColors.get('terroristFinancingRiskLevel')">
                        <p><strong>Terrorist Financing</strong></p>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('terroristFinancingRiskLevel')">
                        <select class="form-select form-control" formControlName="terroristFinancingRiskLevel"
                            (change)="setRowColor('terroristFinancingRiskLevel')">
                            <option value="High/Medium/Low" selected></option>
                            <option value="High">High</option>
                            <option value="Medium">Medium</option>
                            <option value="Low">Low</option>
                            <option value="N/A">N/A</option>
                        </select>
                        <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
                    </div>
                    <div class="col" [style.background-color]="rowColors.get('terroristFinancingRiskLevel')">
                        <div class="wrapper">
                            <textarea #textarea id="tf" type="textarea" class="discardable-text"
                                placeholder="Click here to start adding text" contenteditable="true"
                                (keyup)="resizeAll()" formControlName="terroristFinancingComments"></textarea> <span
                                class="edit-icon"> click to edit</span>
                            <p id="tfText"></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </form>
</section>
<br />
<hr>
<br />