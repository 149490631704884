<div *ngFor="let result of recipientResponse">
  <div *ngIf="result.feedback != null && result.feedback.length > 0" >
    <h5>{{result.name}}</h5>
    <div class="table-responsive">
      <table class='table table-striped table-themis risk-table' aria-labelledby="tableLabel">
        <thead>
          <tr>
            <th scope="col">Issues</th>
            <th scope="col">Risks</th>
            <th scope="col" colspan="2">Recommendations</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let feedback of result.feedback" class="align-middle">
              <td data-label="Issue">
                  {{feedback.issue}}
              </td>
              <td data-label="Risk">
                  {{feedback.risk}}
              </td>
              <td data-label="Recommendation" colspan="2">
                  {{feedback.recommendation}}
                  <ul *ngFor="let qRec of feedback.questionRecommendations">
                    <li>{{qRec}}</li>
                  </ul>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>