import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { EntityTypes } from 'src/nswag';
import { IActorBase } from '../models/Actor';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProfileService } from 'src/app/profile.service';
import { Profile } from 'oidc-client';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'app-reportbody',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnChanges, OnInit {

  @Input() showReport: boolean;
  @Output() hide = new EventEmitter<boolean>();

  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public showSpinner: boolean = false;
  
  // Required to be able to reference enums in the html template
  public ActorTypeEnums = EntityTypes;

  public selectedActor: IActorBase;

  public userAccount: Profile;

  // The map
  public png64: string;

  public actorType: any;

  public profile: any;
  public actor: IActorBase;
  public dateNow: string;
  constructor(public dashService: DashboardService, private profileService: ProfileService) {
  }

  ngOnInit() {
    this.userAccount = this.profileService.getProfile();
    if (this.dashService.reportForms?.controls['executiveSummary'] == null) {
      this.dashService.reportForms?.addControl('executiveSummary', new UntypedFormControl(''));
      this.dashService.reportForms?.addControl('background', new UntypedFormControl(''));
    }

  }

  public onPrint(): void {
    this.hideTextareaOnPrint(true);
    window.onafterprint = () => this.hideTextareaOnPrint(false);
    window.print();  
  }

  ngOnChanges() {

    if (this.showReport) {
      this.dateNow = UtilityService.convertToLocalDateTime(new Date(), "long");
      this.actor = this.dashService.getInvestigation();
      let sub = this.dashService.profileLoader(this.actor);
      if (sub) {
        sub.subscribe(profile=>{
          this.profile = profile;
        });
      }
  
      this.showSpinner = true;

      var w: any = window;
      if (w.fcWidget) {
        w.fcWidget.hide();
      }

      setTimeout(() => {
        this.showSpinner = false;
        this.resizeAllTextAreas();
      }, 3000);

      this.png64 = this.dashService.cytoscapeObject?.png();
      this.selectedActor = this.dashService.getInvestigation();

      if (this.dashService.getInvestigation()?.asUserProfile) {
        this.actorType = EntityTypes.IDV;
      }
      else {
        switch (this.selectedActor.actorType) {
          case EntityTypes.Company:
            this.actorType = EntityTypes.Company;
            break;
          case EntityTypes.Individual:
            this.actorType = EntityTypes.Individual;
            break;
          case EntityTypes.Officer:
            this.actorType = this.selectedActor.actorType;
            break;
          case EntityTypes.OfficerCompany:
            this.actorType = EntityTypes.Officer;
            break;
          case EntityTypes.UBO:
            this.actorType = EntityTypes.UBO;
            break;
          case EntityTypes.Dummy:
            this.actorType = EntityTypes.Dummy;
            break;
          case EntityTypes.DiligenciaPerson:
            this.actorType = EntityTypes.DiligenciaPerson;
            break;
          case EntityTypes.DiligenciaOrganisation:
            this.actorType = EntityTypes.DiligenciaOrganisation;
            break;
          default:
            this.actorType = EntityTypes.None;
            break;
        }
      }
    }
    else {
      var w: any = window;
      if (w.fcWidget) {
        w.fcWidget.show();
      }
    }
  }

  closeReport() {
    // set modal warning if report edited
    // check investigation-based forms
    if (this.dashService?.reportForms?.dirty) {
      this.dashService.hasChanged = true;
    } else {
      // check actor-based forms 
      this.dashService.cytoscapeObject.nodes().forEach(node => {
        var actor: IActorBase = node.data('actor');
        if (actor) {
          var form = actor.reportActorFreeText as UntypedFormGroup;
          if (form?.dirty) {
            this.dashService.hasChanged = true;
          }
        }
      });
    }

    this.showReport = false;
    this.hide.emit(false);
  }

  resizeAllTextAreas() {
    this.textareas.toArray().forEach((item) => { 
      item.nativeElement.style.height = 'auto';
      item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
    }); 
  }

  hideTextareaOnPrint(value1: boolean){
    const areas = [
      'executiveSummary', 'riskSummary','moneyLaundering','fraud','bribery','sanctions',
      'tax','msht','iwt','mAbuse','cybercrime','pf','ct','tf','background',
      'details', 'businessRelationship', 'ragStatus', 'dap', 'iap', 'sanctionCheck', 
      'lawCheck', 'pepCheck', 'adverseCheck', 'aInd', 'aComp', 'lamps', 'lSanctions', 
      'lLaw', 'lPep', 'lAdverse', 'lAp', 'lAc', 'redFlags', 
    ];

    areas.forEach(element => {
      const textarea: any = document.getElementById(element);
      const p: any = document.getElementById(element + 'Text');

      if (!textarea || !p) return;

      p.style.display = 'none';
      const value = textarea.value;

      if (value != ''){
        if (value1 == true){
          const strippedValue = value.replace(/\n/g, '<br />');
          textarea.style.display = 'none';
          p.style.display = 'block'
          p.innerHTML = strippedValue;
        }
        else{
          p.style.display = 'none'
          p.innerHTML = '';
          textarea.style.display = 'inline'; 
        }
      }
      else{
        if (element == 'executiveSummary' || element == 'background'){
          const section: any = document.getElementById(element + 'Section');
          section.style.display = value1 == true ? 'none' : 'block';
        }
      }
    });
  }

  get userName(): string { return this.userAccount?.email; }
  get userFullName(): string { return this.profileService?.fullName; }  
  get companyName(): string { return this.profileService?.companyName; }
}
