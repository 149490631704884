import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";
import { MonitorLinkService } from "src/app/services/monitor-link.service";
import { UtilityService } from "src/app/utilitities/utilities";
import { ApiResponseOfIndividualProfile, IndividualAlias, IndividualClient, IndividualMatch, IndividualProfile } from 'src/nswag';
import { BaseMatchesComponent } from "../basematches.component";

@Component({
  selector: 'mon-individualmatches',
  templateUrl: './individualmatches.component.html',
  styleUrls: ['./individualmatches.component.scss']
})
export class IndividualMatchesComponent extends BaseMatchesComponent implements OnChanges{

  @Input() matches: IndividualMatch[];
  @Input() threshold: number;
  @Output() match = new EventEmitter<IndividualMatch>();
  @Output() viewedMoreMatches = new EventEmitter<boolean>();

  public profiles: IndividualProfile[];
  public selectedProfile: IndividualProfile;
  public subThresholdMatches: IndividualMatch[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.resultCount = this.matches?.length ?? 0;
    this.showMoreFlag = new Array(this.resultCount).fill(false);
    this.profiles = new Array(this.resultCount).fill(null);
    this.showMoreMatches = false;
    this.subThresholdMatches = [];
    if (this.matches.some(m => m.score < this.threshold)) {;
      this.removeSubThresholdMatches();
    }
  }

  private removeSubThresholdMatches(): void {
    // store for optional loading later
    this.subThresholdMatches = this.matches.filter(m => m.score < this.threshold);
    // filter and reassign
    this.matches = this.matches.filter(m => m.score >= this.threshold);
  }

  public loadSubThresholdMatches(): void {
    // if not already loaded
    if (!this.showMoreMatches) {
      this.matches.push(...this.subThresholdMatches);
      this.toggleShowMore();
      this.viewedMoreMatches.emit();
    }    
  }

  public get canShowMoreMatches(): boolean {
    return !this.showMoreMatches && this.subThresholdMatches.length > 0;
  }

  closeReport() {
    this.matchReportService.hideReport();
  }

  changeIndividualShowFlag(event: any, individual: IndividualMatch,pos: number){
    event.stopPropagation();
    if (!this.showMoreFlag[pos]) {
      if (!this.profiles[pos]) {
        this.getIndividualProfile(individual).subscribe(r => {
          if (r.isSuccess) {
            this.showMoreFlag.fill(false);
            this.profiles[pos] = r.data;
            this.showMoreFlag[pos] = true;
            this.selectedProfile = r.data;
            this.createDummyClient();
          }
        });
      }
      else {
        this.showMoreFlag.fill(false);
        this.selectedProfile = this.profiles[pos];
        this.showMoreFlag[pos] = true;
        this.createDummyClient();
      }
    }
    else {
      this.showMoreFlag[pos] = false;
    }
  }

  private createDummyClient() {
    this.selectedClient = MonitorLinkService.createDummyClientresult(this.selectedProfile);
    this.selectedClient.client.individual = new IndividualClient();
  }

  private getIndividualProfile(profile: IndividualMatch): Observable<ApiResponseOfIndividualProfile> {
    if (this.profileService.monitoringEnabled()) {
      return this.monitorService.getIndividualProfile(profile);
    }
    else if (this.profileService.screeningEnabled()) {
      return this.screenService.getIndividualProfile(profile);
    }
    return null;
  }

  buildName(individual: IndividualMatch): string{
    return UtilityService.getIndividualFullName(individual.name, individual.firstName, individual.middleName, individual.lastName);
  }

  selectResult(match: IndividualMatch = null) {
    this.match.emit(match);
  }
  
  showIndividualCheck(pos: number) : IndividualProfile {
     return this.showMoreFlag[pos] ? this.profiles[pos] : undefined;
  }

  isPEP(match: IndividualMatch) : boolean {
    return UtilityService.isPEP(match.datasets);
  }

  isFormerPEP(match: IndividualMatch) : boolean {
  return UtilityService.isFormerPEP(match.datasets);
  }

  isSanction(match: IndividualMatch) : boolean {
    return UtilityService.isSanction(match.datasets);
  }
  isFormerSanction(match: IndividualMatch) : boolean {
    return UtilityService.isFormerSanction(match.datasets);
  }
  hasLawEnforcement(match: IndividualMatch) : boolean {
    return UtilityService.isLawEnforcement(match.datasets);
  }
  hasAdverseMedia(match: IndividualMatch) : boolean {
    return UtilityService.hasAdverseMedia(match.datasets);
  }

  getIndividualAliasList(aliases: IndividualAlias[]) {
    return UtilityService.getIndividualAliasList(aliases);
  }

  getImage(pos: number) {
    return this.profiles[pos]?.nationalitiesIsoCodes;
  }

  public expandMatchReport(matches: IndividualMatch[]) {
    return this.openMatches(matches, false);
  }
}
