import Step from 'shepherd.js/src/types/step';
//import { completeStep } from './home.component';

export const builtInButtons = {
  cancel: {
    classes: 'cancel-button',
    secondary: true,
    text: 'Exit',
    type: 'cancel'
    // action: function() {
    //   const items = [];

    //   items.push('home');
    //   localStorage.setItem('shepherd-tour', JSON.stringify(items));
    //   return this.hide();
    // }
  },
  next: {
    classes: 'next-button',
    text: 'Next',
    type: 'next'
  },
  back: {
    classes: 'back-button',
    secondary: true,
    text: 'Back',
    type: 'back'
  }
};

export const defaultStepOptions: Step.StepOptions = {
  classes: 'shepherd-theme-arrows custom-default-class',
  scrollTo: true,
  cancelIcon: {
    enabled: true
  }
};

export const steps: Step.StepOptions[] = [
  {
      attachTo: {
          element: null,
          on: 'bottom'
      },
      buttons: [
          builtInButtons.cancel,
          builtInButtons.next
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      id: 'intro',
      title: `
              <b>Welcome to Themis Search!</b>
              `,
      text: `
              <p style="display: flex; justify-content: center;"><img src="/assets/images/tour-guide.jpg" class="avatar-image" width="400px" />
              <p>
              <p>
              Let&rsquo;s start with a quick guided product tour and we will have you up and running in no time!
              </p>
            `
  },
  {
    attachTo: {
      element: '.navbar-nav',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'usage',
    title: 'Navigation Menu',
    text: 'This is the Menu bar where you can easily find products such as search, monitor & other investigative apps - all grouped together for quicker access.'
  },
  {
    attachTo: {
      element: '#home-menu',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'usage',
    title: 'Home Menu',
    text: 'This is the Home screen menu where you can easily find products such as search, monitor & other investigative apps - all grouped together for quicker access.'
  },
  {
    attachTo: {
      element: '#launcher-frame',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Feedback Button',
    text: `
        <p>
          If at any time you need help, we are just a click away. 
        </p>
        `
  },
  {
    attachTo: {
      element: '.tour-flag',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'installation',
    title: 'Tour Guide',
    text: 'If at any time you need to access this tour guide. Simply click the flag button here.'
  },
  
  {
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back
    ],
    id: 'noAttachTo',
    title: 'Congratulations',
    classes: 'custom-class-name-1 custom-class-name-2',
    text: 'You completed the guided tour! Now you are ready to start your search!'
  }
];
