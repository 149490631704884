<div class="parent">
  <div class="background-container">
    <div class="grid">
      <div class="col">
        <h1>Regulatory Reporting</h1>
      </div>
    </div>
    <br/>
    <div class="grid actions-row align-items-center">
      <div class="col py-1">
        <p-button class="float-start" [severity]="'primary'" label="Create New Report" (click)="showSelectionDialog()"/>
      </div>
      <div class="col py-1">            
        <p-inputGroup>
          <input type="text" [(ngModel)]="filterText" maxlength="50" placeholder="Search for existing reports" #name="ngModel" (keyup.enter)="search()" pInputText />
          <button type="button" pButton icon="pi pi-search" class="p-button" [severity]="'primary'" (click)="search()"></button>
        </p-inputGroup>
      </div>
    </div>
    <div class="grid mt-2">
      <div class="col">
        <p-table [value]="reports" [loading]="isTableLoading">
          <ng-template pTemplate="header">
            <tr>
              <th>Title</th>
              <th>Type</th>
              <th>Status</th>
              <th>Date Created</th>
              <th>Date Submitted</th>
              <th class="text-center">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-report let-i="rowIndex">
            <tr *ngIf="totalReports > 0">
              <td>{{ report.title }}</td>
              <td>{{ report.type }}</td>
              <td>{{ report.status }}</td>
              <td>{{ report.createdOn | date: 'yyyy-MM-dd h:mm:ss a' }}</td>
              <td>{{ report.submittedOn | date: 'yyyy-MM-dd' }}</td>
              <td class="text-center">
                <p-button (onClick)="showMenu($event, report)" icon="pi pi-ellipsis-v" [text]="true" [rounded]="true"></p-button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr *ngIf="totalReports === 0 && searchClicked && !loader.showLoader">
              <td colspan="6">
                <p-message severity="info" class="w-full row-message" text="No reports match your search criteria."></p-message>
              </td>
            </tr>
            <tr *ngIf="totalReports === 0 && !searchClicked && !loader.showLoader">
              <td colspan="6">
                <p-message severity="info" class="w-full row-message" text="You currently have no reports. To create one, click the 'Create New Report' button at the top of the page."></p-message>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="6">
                <div class="justify-content-center">
                  <span class="flex gap-2">
                    Loading reports...
                    <p-progressSpinner class="small-spinner"></p-progressSpinner>
                  </span>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <p-menu #menu [model]="menuActions" [popup]="true"></p-menu>
        <p-paginator [rows]="10" [totalRecords]="totalReports" (onPageChange)="handlePageChange($event)"></p-paginator>
      </div>
    </div>
  </div>
</div>
   
