import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SearchResultsComponent } from './search-results.component';
import { SearchCriteriaComponent } from './search-criteria.component';
import { SearchComponent } from './search.component';
import { SearchDialogComponent } from './search-dialog.component';
import { LoaderModule } from '../loader/loader.module';


@NgModule({
  declarations: [
    SearchResultsComponent,
    SearchCriteriaComponent,
    SearchComponent,
    SearchDialogComponent,
    NgbdSortableHeader
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbNavModule,
    LoaderModule
  ],
  exports: [
    SearchComponent,
    SearchCriteriaComponent,
    SearchResultsComponent,
  ],
  providers: [
  ],
})
export class SearchModule { }
