import Step from 'shepherd.js/src/types/step';

export const builtInButtons = {
  cancel: {
    classes: 'cancel-button',
    secondary: true,
    text: 'Exit',
    type: 'cancel'
    // action: function() {
    //   const items = JSON.parse(localStorage.getItem('shepherd-tour')) ?? [];

    //   items.push('search');
    //   localStorage.setItem('shepherd-tour', JSON.stringify(items));
    //   return this.hide();
    // }
  },
  next: {
    classes: 'next-button',
    text: 'Next',
    type: 'next'
  },
  back: {
    classes: 'back-button',
    secondary: true,
    text: 'Back',
    type: 'back'
  }
};

export const defaultStepOptions: Step.StepOptions = {
  classes: 'shepherd-theme-arrows custom-default-class',
  scrollTo: true,
  cancelIcon: {
    enabled: true
  }
};

export const steps: Step.StepOptions[] = [
  {
    attachTo: {
        element: null,
        on: 'bottom'
    },
    buttons: [
        builtInButtons.cancel,
        builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'intro',
    title: 'This is your Search results page',
    text: `
            <p>
            Here you can view the results of your search in the list below. Let&rsquo;s start with a quick product tour
            </p>
          `
  },
  {
    attachTo: {
      element: '.table > :not(:first-child)',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Filters',
    text: `
        <p>
          Easily filter your search to get your desired results.
        </p>
        `
  },
  {
    attachTo: {
      element: '.filter-result',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Filtered Results',
    text: `
        <p>
          This is a list of potential profile matches, browse and scroll through to find the correct match. 
        </p>
        `
  },
  {
    attachTo: {
      element: '.start-investigation-btn',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Start Investigation Button',
    text: `
        <p>
          If the company or person is not listed , try altering your search terms or click this button to begin a new investigation.
        </p>
        `
  },
  {
    attachTo: {
      element: '.tour-flag',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'installation',
    title: 'Tour Guide',
    text: 'If at any time you need to access this tour guide. Simply click the flag button here.'
  },

  {
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back
    ],
    id: 'noAttachTo',
    title: 'Well done!',
    classes: 'custom-class-name-1 custom-class-name-2',
    text: 'You can now continue with your search. Press the Exit button to carry on.'
  }
];
