import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions  } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';
import { Assessment, AssessmentRecipient, Recipient, SelectedRecipientStatus } from 'src/nswag';
import { RiskAssessmentService } from '../services/risk-assessment.service';
import { AddRecipientDialogComponent } from './add-recipient-dialog/add-recipient-dialog.component';
import { ExistingRecipientDialogComponent } from './existing-recipient-dialog/existing-recipient-dialog.component';
import { ResultsComponent } from './results/results.component';
import { SendAssessmentRecipientDialogComponent } from './send-assessment-recipient-dialog/send-assessment-recipient-dialog.component';
import { ViewRecipientAnswersComponent } from './view-recipient-answers/view-recipient-answers.component';

@Component({
  selector: 'manage-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss'],
})
export class RecipientComponent implements OnInit, OnDestroy {

  @Input() assessment : Assessment;

  constructor(private alertService: AlertService, public riskAssessmentService: RiskAssessmentService, private modalService: NgbModal, private confirmationDialogService : ConfirmationDialogService) {
  }

  ngOnInit(): void {
    this.riskAssessmentService.loadRecipients(this.assessment.id);
  }

  ngOnDestroy() {
    // close all modal pop upon component destroy
    this.modalService.dismissAll(false);
  }

  public changePage(page: number) {
    // this.riskAssessmentService.loadRecipientsPage(page);
  }

  public addRecipient(){
    const model = this.modalService.open(AddRecipientDialogComponent);
    model.componentInstance.ButtonText = 'Add';
    model.componentInstance.TitleText = 'Add New Recipient'
  }

  public existingRecipient(){
    var modalOptions: NgbModalOptions = { size: 'lg' };
    this.modalService.open(ExistingRecipientDialogComponent, modalOptions);
  }

  public selectRecipient(assessmentRecipient: AssessmentRecipient) {
    const modal = this.modalService.open(ResultsComponent, { size: 'lg' });
    modal.componentInstance.currentRecipient = assessmentRecipient;
  }

  public viewResults(assessmentRecipient: AssessmentRecipient){
    const modal = this.modalService.open(ResultsComponent, { size: 'lg' });
    modal.componentInstance.currentRecipient = assessmentRecipient;
  }

  public sendRecipient(assessmentRecipient: AssessmentRecipient) {
    this.riskAssessmentService.SetCurrentRecipient(assessmentRecipient);
    const sendDialog = this.modalService.open(SendAssessmentRecipientDialogComponent);
    sendDialog.componentInstance.defaultEmailMessage = this.assessment.emailMessage;
    sendDialog.componentInstance.defaultWelcomeMessage = this.assessment.welcomeMessage;
  }

  public lockRecipient(assessmentRecipient: AssessmentRecipient) {
    const lockRecipient = assessmentRecipient.status === SelectedRecipientStatus.Locked || assessmentRecipient.status === SelectedRecipientStatus.Complete ? false : true;
    this.confirmationDialogService.confirm('Confirm', `Are you sure you want to ${lockRecipient ? 'lock' : 'unlock'} this recipient from the assessment?`, false, `${lockRecipient ? 'Lock' : 'Unlock'}`, 'Cancel')
    .then((confirmed) => {
      if(confirmed) {
        this.riskAssessmentService.lockAssessmentRecipient(this.assessment.id, assessmentRecipient.id, lockRecipient)
        .subscribe(result => {
          if (result.isSuccess) {
            this.riskAssessmentService.refreshCurrentRecipient(result.data);
            this.alertService.success(`The Recipient has been ${lockRecipient ? 'locked' : 'unlocked'}.`, { autoClose: true });
          }
        },
        (error) => {
          console.error(error);
        });
      }
    })
    .catch(() => console.log('modal cancelled'));
  }


  public viewAnswers(currentRecipient:Recipient) {
    this.riskAssessmentService.SetCurrentRecipient(currentRecipient);
    const model = this.modalService.open(ViewRecipientAnswersComponent, { size: 'lg', scrollable: true });
  }
  public editRecipient(assessmentRecipient) {
    const model = this.modalService.open(AddRecipientDialogComponent);
    var industryName = this.riskAssessmentService.getIndustryNameById(assessmentRecipient?.industryVerticalsId);
    model.componentInstance.industry = industryName;
    model.componentInstance.existingAssessmentRecipient = assessmentRecipient;
    model.componentInstance.isEdit = true;
    model.componentInstance.ButtonText = 'Save';
    model.componentInstance.TitleText = 'Edit Recipient'
  }
  public removeRecipient(assessmentRecipient) {
    this.confirmationDialogService.confirm('Confirm', 'Are you sure you want to remove this recipient from the assessment?', false, 'Remove', 'Cancel')
    .then((confirmed) => {
      if(confirmed) {
        this.riskAssessmentService.removeAssessmentRecipient(this.assessment.id, assessmentRecipient.id)
        .subscribe(result => {
          if (result.isSuccess) {
            this.riskAssessmentService.removeFromRecipientsList(assessmentRecipient.id);
            this.alertService.success("The Recipient has been removed from the Assessment", { autoClose: true });
          }
        },
        (error) => {
          console.error(error);
        });
      }
    })
    .catch(() => console.log('modal cancelled'));
  };

  public screenRecipient() {
  }
  public monitorRecipient() {
  }
}
