import { Component, DoCheck, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Address2, ClientResult, IndividualAlias, IndividualProfile } from 'src/nswag';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'mon-individualprofile',
  templateUrl: './individualprofile.component.html',
  styleUrls: ['./individualprofile.component.scss']
})
export class IndividualProfileComponent implements OnChanges, DoCheck {

  @Input() profile: IndividualProfile;
  @Input() client: ClientResult;
  @Input() showAllLists = false;
  @Input() removeTooltip: boolean = false;
  @Input() isArchived: boolean = false;
  @Output() clientChange = new EventEmitter<ClientResult>(); 

  public name : string;
  public image : string;
  public nationalities : string[];
  public addressList : string[];
  public aliasList: string[];
  public addresses: Address2[];

  public showAlertStatus = false;

  constructor() { }

  ngDoCheck(): void {
      
  }

  onClientChange(newClient: ClientResult) {
    this.client = newClient;
    this.clientChange.emit(this.client);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profile instanceof IndividualProfile && this.profile?.resourceId) {
      this.name = UtilityService.getIndividualName(this.profile);
      this.image = this.profile?.profileImages?.length > 0  ? this.profile?.profileImages[0] : "/assets/images/EDD-Avatar-individual.png";
      this.nationalities = [];
      for (const n of this.profile?.nationalitiesIsoCodes) {
        if (!this.nationalities.find(v => v == n)) {
          this.nationalities.push( UtilityService.getCountryFromCode(n));
        }
      }
      this.addressList = []
      for (const address of this.profile?.addresses) {
        let add = UtilityService.getAddress(address);
        if (add?.length > 0 && add != address.countryIsoCode) {
          this.addressList.push (address.addressType + ": " + add);
        }
      }
      this.aliasList = [];
      for (const alias of this.profile?.aliases) {
        this.aliasList.push(this.getAliasName(alias) + this.getAliasType(alias));
      }

      this.addresses = [];
      for (const add of this.profile?.addresses) {
        if (this.isAddress(add)) {
          this.addresses.push(add);
        }
      }
    }

    this.showAlertStatus = !this.showAllLists && this.client?.client?.inMonitoring;
  }
  
  public getDate(dates: string[]) : string {
    return (dates?.length > 0 ? dates[0] : "" );
  }

  public getAddress(address: Address2): string {
    return address.addressType + ": " + UtilityService.getAddress(address);
  }

  private isAddress(address: Address2): boolean {
    let add = UtilityService.getAddress(address)
    return add?.length > 0 && add != address.countryIsoCode;
  }

  public getAliasName(alias: IndividualAlias) : string {
    return UtilityService.getIndividualName(alias);
  }
  
  public getAliasType(alias: IndividualAlias) : string {
    return alias.type ? " (" + alias.type + ")" : "";
  }
}
