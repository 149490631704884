import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestigationLinkerComponent } from './investigation-linker.component';



@NgModule({
  declarations: [
    InvestigationLinkerComponent
    
  ],
  imports: [
    CommonModule
  ],
exports: [
  InvestigationLinkerComponent
]
})
export class InvestigationLinkerModule { }
