import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportWrapperComponent } from './report-wrapper/report-wrapper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { ReportContentComponent } from './report-content/report-content.component';
import { RegulatoryReportClient } from 'src/nswag';
import { ReportSelectionDialogComponent } from './report-selection-dialog/report-selection-dialog.component';
import { RegulatoryReportsBaseComponent } from './regulatory-reports-base.component';
import { FormControl, Validators } from '@angular/forms';
import { FormlyFormFieldModule } from '@ngx-formly/primeng/form-field';
import { FormlyExtension, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { FormlyFieldPrimengTextarea } from './custom-formly-fields/FormlyFieldPrimengTextarea';
import { FormlyFieldHtml } from './custom-formly-fields/FormlyFieldHtml';
import { FormlyFieldHeading } from './custom-formly-fields/FormlyFieldHeading';
import { FormlyFieldPrimengCalendar } from './custom-formly-fields/FormlyFieldPrimengCalendar';
import { FormlyFieldPrimengCountryAutoComplete } from './custom-formly-fields/FormlyFieldPrimengCountryAutoComplete';
import { FormlyFieldPrimengDropdown } from './custom-formly-fields/FormlyFieldPrimengDropdown';
import { FormlyFieldPrimengInput } from './custom-formly-fields/FormlyFieldPrimengInput';
import { FormlyFieldPrimengRadioButton } from './custom-formly-fields/FormlyFieldPrimengRadioButton';
import { FormlyFieldRepeat } from './custom-formly-fields/FormlyFieldRepeat';
import { CompleteReportDialogComponent } from './complete-report-dialog/complete-report-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { PrimeNgModule } from '../modules/prime-ng/prime-ng.module';
import { PrimeLoaderComponent } from '../loader/prime-loader/prime-loader.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportDeclarationDialogComponent } from './report-declaration-dialog/report-declaration-dialog.component';
import { FormlyFieldPrimengPanel } from './custom-formly-fields/FormlyFieldPrimengPanel';

const disableAllFieldsExtension: FormlyExtension = {
  prePopulate(field: FormlyFieldConfig) {
    const isReadonly = field.options.formState.readonly;
    if (isReadonly) {
      if (field.props) {
        field.props.disabled = true;
      } else {
        field.props = {
          disabled: true
        }
      }
    }
  }
};

@NgModule({
  declarations: [
    RegulatoryReportsBaseComponent,
    ReportsComponent,
    ReportSelectionDialogComponent,
    ReportWrapperComponent,
    ReportContentComponent,
    CompleteReportDialogComponent,
    ReportDeclarationDialogComponent,
    FormlyFieldPrimengCountryAutoComplete,
    FormlyFieldPrimengCalendar,
    FormlyFieldPrimengDropdown,
    FormlyFieldHeading,
    FormlyFieldPrimengRadioButton,
    FormlyFieldRepeat,
    FormlyFieldHtml,
    FormlyFieldPrimengPanel,
    FormlyFieldPrimengTextarea,
    FormlyFieldPrimengInput
  ],
  imports: [
    CommonModule,
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    FormlyFormFieldModule, 
    FormlyPrimeNGModule,
    PrimeLoaderComponent,
    RouterModule.forChild([
      { path: 'regulatory-reports/:action', component: ReportContentComponent, canActivate: [AuthorizeGuard] }
    ]),
    FormlyModule.forChild({
      extensions: [
        { name: 'disableAllFields', extension: disableAllFieldsExtension }
      ],
      validators: [
        { name: 'email', validation: Validators.email },
        {
          name: 'atLeastOneCheckboxChecked',
          validation: (control: FormControl) => {
            return Object.values(control.value).some((v) => v === true) ? null : { 'atLeastOneCheckboxChecked': true };
          },
        },
        {
          name: 'maxArrayLength',
          validation: (control: FormControl, field: FormlyFieldConfig) => {
            const max = field.props?.maxArrayLength;
            if (!Array.isArray(control.value) || max === undefined) {
              return null;
            }
            return control.value.length <= max ? null : { 'maxArrayLength': true };
          }
        },
        {
          name: 'minLengthWithoutSpaces',
          validation: (control: FormControl, field: FormlyFieldConfig) => {
            const minLength = field.props?.minLength;
            if (!minLength) return null;

            const value = control.value;
            if (!value) return null

            const trimmedValue = value.replace(/\s/g, '');
            return trimmedValue.length >= minLength ? null : { 'minLength': true };
          },
        }
      ],
      validationMessages: [ 
        { name: 'email', message: 'Please enter a valid email address' },
        { name: 'required', message: (error, field) => `This field is required` },
        { name: 'minLength', message: (error, field) => `Must be at least ${field.props.minLength} characters` },
        { name: 'maxLength', message: (error, field) => `Must be less than ${field.props.maxLength} characters` },
        { name: 'atLeastOneCheckboxChecked', message: 'At least one option is required' },
        { name: 'maxArrayLength', message: (error, field) => `Must be less than ${field.props?.maxArrayLength} entries` }
      ],
      types: [
        { name: 'custom-countryautocomplete', component: FormlyFieldPrimengCountryAutoComplete, wrappers: [ 'form-field' ] },
        { name: 'custom-calendar', component: FormlyFieldPrimengCalendar, wrappers: [ 'form-field' ] },
        { name: 'custom-dropdown', component: FormlyFieldPrimengDropdown, wrappers: [ 'form-field' ] },
        { name: 'custom-radio', component: FormlyFieldPrimengRadioButton, wrappers: [ 'form-field' ] },
        { name: 'custom-textarea', component: FormlyFieldPrimengTextarea, wrappers: [ 'form-field' ] },
        { name: 'custom-input', component: FormlyFieldPrimengInput, wrappers: [ 'form-field' ] },
        { name: 'custom-heading', component: FormlyFieldHeading },
        { name: 'custom-html', component: FormlyFieldHtml },
        { name: 'custom-panel', component: FormlyFieldPrimengPanel },
        { name: 'custom-repeat', component: FormlyFieldRepeat, wrappers: [ 'form-field' ], defaultOptions: { defaultValue: [{}] } },
      ], 
    }),
    SignaturePadComponent,
  ],
  providers: [
    RegulatoryReportClient,
  ]
})
export class RegulatoryReportsModule { }
