import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagsModule } from '../tags/tags.module';
import { AdvancedSearchOptionsComponent } from './advanced-search-options.component';
import { DatePickerModule } from '../date-picker/date-picker.module';

@NgModule({
  declarations: [AdvancedSearchOptionsComponent],
  exports: [AdvancedSearchOptionsComponent],
  imports: [NgbModule, CommonModule, FormsModule, TagsModule, DatePickerModule]
})
export class AdvancedSearchOptionsModule { }
