import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/profile.service';
import { AddProjectCommand, ApiResponseOfAuditList, ApiResponseOfProject, GetProjectsByUserAccountIdQuery, GetTenantProjectDetailsQuery, IntelligenceClient, Project, TenantProjectDetails, UpdateProjectCommand, UploadCommand, TenantManagementClient, AddProjectRequestByAdminCommand } from 'src/nswag';

@Injectable({
  providedIn: 'root'
})

export class IntelligenceService {
  public static pageSize = 10;

  constructor(private adminClient: IntelligenceClient, private profileService: ProfileService, private tenantManagementClient: TenantManagementClient) {
    this.currentTenantProjectList = new Array<TenantProjectDetails>();
    this.currentProjectList = new Array<Project>();
  }

  private currentTenantProjectList: TenantProjectDetails[] = [];
  private currentTenantProjectListTotal: number;
  private currentTenantProjectListQuery = new GetTenantProjectDetailsQuery({ pageSize: IntelligenceService.pageSize });

  private currentProjectList: Project[] = [];
  private currentProjectListTotal: number;
  private currentProjectListQuery = new GetProjectsByUserAccountIdQuery({ pageSize: IntelligenceService.pageSize });

  private currentTenantProject: TenantProjectDetails;
  private currentProject: Project;

  // tenant project
  public get TenantProjectList(): TenantProjectDetails[] {
    return this.currentTenantProjectList;
  }

  public get TenantProjectListTotal(): number {
    return this.currentTenantProjectListTotal ?? 0;
  }

  public get TenantProjectListPage(): number {
    return this.currentTenantProjectListQuery?.pageNumber ?? 1;
  }

  public get CurrentTenantProject(): TenantProjectDetails {
    return this.currentTenantProject;
  }

  // user project
  public get ProjectList(): Project[] {
    return this.currentProjectList;
  }

  public get ProjectListTotal(): number {
    return this.currentProjectListTotal ?? 0;
  }

  public get ProjectListPage(): number {
    return this.currentProjectListQuery?.pageNumber ?? 1;
  }

  public get CurrentProject(): Project {
    return this.currentProject;
  }


  public loadTenantProjectPage(pageNumber: number) {
    if (this.currentTenantProjectListQuery) {
      this.currentTenantProjectListQuery.pageNumber = pageNumber;
      this.internalSearchTenantProjectsWithUpdatedQuery();
    }
  }

  public getTenantsByFeature() {
    return this.tenantManagementClient.getTenantsByFeature('Intelligence', 1, 100);
  }

  public getUsersByTenant(tenantId: string){
    return this.tenantManagementClient.intelUsers(null, tenantId);
  }

  public getUsersProjects(userId: string, page: number, pageSize: number) {
    this.currentProjectListQuery = new GetProjectsByUserAccountIdQuery({
      userAccountId: userId,
      pageSize: pageSize,
      pageNumber: page
    });
    var obs = this.adminClient.getProjectByUserAccountId(this.currentProjectListQuery);
    obs.subscribe(r => {
      if (r.isSuccess){
        this.currentProjectList = r.data.results;
        this.currentProjectListTotal = r.data.totalCount;
      }
    })
  }

  public requestProject(command: AddProjectCommand): Observable<ApiResponseOfProject>{
    return this.adminClient.requestProject(command);
  }

  public requestProjectAdmin(command: AddProjectRequestByAdminCommand) {
    return this.adminClient.adminRequestProject(command);
  }

  public updateProject(command: UpdateProjectCommand) : Observable<ApiResponseOfProject>{
    return this.adminClient.updateProject(command);
  }
  
  public uploadReport(command: UploadCommand): Observable<ApiResponseOfProject>{
    return this.adminClient.uploadReport(command, this.CurrentProject.id);
  }

  public uploadProposal(command: UploadCommand): Observable<ApiResponseOfProject>{
    return this.adminClient.uploadProposal(command, this.CurrentProject.id);
  }

  public getAuditTrail(project: Project, pageNumber: number, pageSize: number): Observable<ApiResponseOfAuditList> {
    return this.adminClient.getProjectAudit(pageNumber, pageSize, project.id);
  }

  public setCurrentProject(project: Project){
    this.currentProject = project;
  }

  public setCurrentTenantProject(tenantProject: Project){
    this.currentTenantProject = tenantProject;
  }

  public refreshProjectList(project: Project) {
    if (this.currentProjectList) {
      if (this.currentProjectList.length < 10) {
        let i = this.currentProjectList.findIndex(val => val.id == project.id);
        if (i == -1) {
          this.currentProjectList = [project].concat(this.currentProjectList);
        } else {
          this.currentProjectList[i] = project;
        }
      }
      else {
        this.getUsersProjects(this.profileService.userId, this.ProjectListPage, IntelligenceService.pageSize);
      }
    }
  }

  private internalSearchTenantProjectsWithUpdatedQuery() {
    if (this.currentTenantProjectListQuery) {
      var obs = this.adminClient.getTenantProjectDetails(this.currentTenantProjectListQuery);
      obs.subscribe(r => {
        if (r.isSuccess) {
          this.currentTenantProjectList = r.data.results;
          this.currentTenantProjectListTotal = r.data.totalCount;
        }
      });
    }
  }
}
