<h4>Status</h4><h5 class="text-center">AML Screening Checks Complete</h5>
<br/>
<div>
    <div class="row">
        <div class="col text-center">
            <mon-alerticon [lampsStatus]="client?.matchStatus?.hasSanctions" [alertStatus]="client?.matchStatus?.alertStatus" title="Sanctions" [small]="true" [alwaysShow]="true" icon="icon-prohibited"></mon-alerticon>
            <p *ngIf="isActive(matchStatus?.hasSanctions)">Sanctions<br><span class="badge rounded-pill bg-warning">Match</span></p>
            <p *ngIf="!isActive(matchStatus?.hasSanctions)">Sanctions<br><span class="badge rounded-pill bg-success">No Match</span></p>
        </div>
        <div class="col text-center">
            <mon-alerticon [lampsStatus]="client?.matchStatus?.isPEP" [alertStatus]="client?.matchStatus?.alertStatus" title="PEPs" [small]="true" [alwaysShow]="true" icon="icon-library2"></mon-alerticon>
           <p *ngIf="isActive(matchStatus?.isPEP)">Political Exposure<br><span class="badge rounded-pill bg-warning">Match</span></p>
           <p *ngIf="!isActive(matchStatus?.isPEP)">Political Exposure<br><span class="badge rounded-pill bg-success">No Match</span></p>
        </div>
        <div class="col text-center">
            <mon-alerticon [lampsStatus]="client?.matchStatus?.hasLawEnforcement" [alertStatus]="client?.matchStatus?.alertStatus" title="Legal" [small]="true" [alwaysShow]="true" icon="icon-balance"></mon-alerticon>
            <p *ngIf="isActive(matchStatus?.hasLawEnforcement)">Law Enforcement<br><span class="badge rounded-pill bg-warning">Match</span></p>
            <p *ngIf="!isActive(matchStatus?.hasLawEnforcement)">Law Enforcement<br><span class="badge rounded-pill bg-success">No Match</span></p>
        </div>
        <div class="col text-center">
            <mon-alerticon [lampsStatus]="client?.matchStatus?.hasAdvMedia" [alertStatus]="client?.matchStatus?.alertStatus" title="Adverse Media" [small]="true" [alwaysShow]="true" icon="icon-tv"></mon-alerticon>
            <p *ngIf="isActive(matchStatus?.hasAdvMedia)">Adverse Media<br><span class="badge rounded-pill bg-warning">Match</span></p>
            <p *ngIf="!isActive(matchStatus?.hasAdvMedia)">Adverse Media<br><span class="badge rounded-pill bg-success">No Match</span></p>
        </div>
    </div>
    <br/>
    <div *ngIf="inMonitoring" class="row">
        <div class="col-4">
            Alert status:
        </div>
        <div class="col-8">
            {{alertStatus}}
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            Match status:
        </div>
        <div class="col-8">
            {{hasMatches}}
        </div>        
    </div>
</div>


