import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IdvStartComponent } from './idvStart/idvStart.component';
import { IdvComponent } from './idv/idv.component';
import { IdvSearchComponent } from './idv-search/idv-search.component';
import { IdvDetailComponent } from './idv-detail/idv-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IdvProfileDetailComponent } from './idv-detail/idv-profile-detail/idv-profile-detail.component';
import { IdvDocsDetailComponent } from './idv-detail/idv-docs-detail/idv-docs-detail.component';
import { IdvDetailBaseComponent } from './idv-detail/idv-detail-base.component';
import { PipesModule } from '../pipes/pipes.module';
import { IdvReportComponent } from './idv-report/idv-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProfileDetailsModule } from '../profile-details/profile-details.module';
import { IdvClientDetailComponent } from './idv-client-detail/idv-client-detail.component';
import { LoaderModule } from '../loader/loader.module';
import { MonitorModule } from '../monitor/monitor.module';

@NgModule({
  declarations: [
    IdvStartComponent,
    IdvComponent,
    IdvSearchComponent,
    IdvDetailComponent,
    IdvProfileDetailComponent,
    IdvDocsDetailComponent,
    IdvDetailBaseComponent,
    IdvReportComponent,
    IdvClientDetailComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgxPaginationModule,
    PipesModule,
    ProfileDetailsModule,
    LoaderModule,
    MonitorModule
  ],
  exports: [
    IdvStartComponent,
    IdvComponent
  ]
})
export class IdvModule { }
