import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Client } from 'src/nswag';
import { ProfileRequestComponent } from '../modal/profile-request.component';
import { ProfileRequestService } from '../profile-request.service';

@Component({
  selector: 'app-profile-request-button',
  templateUrl: './profile-request-button.component.html',
  styleUrls: ['./profile-request-button.component.scss']
})
export class ProfileRequestButtonComponent implements OnInit {

  @Input() client: Client;

  public isLoading: boolean = false;
  private hasCredits: boolean = false;
  constructor(private profileRequestService: ProfileRequestService, public modalService: NgbModal) { }

  ngOnInit(): void {

  }

  public launch() {
    this.isLoading = true;
    var self = this;
    this.profileRequestService.checkCredits().subscribe(result => {
      if (result.data) {
        this.hasCredits = result.data.hasCredits;
      }
    },
    (error) => {
      console.log(error);
    }).add(() => {
      this.isLoading = false;
      this.launchModal(this.hasCredits, this.client);
    });
  }

  public launchModal(hasCredits: boolean, client: Client) {
    const modalRef = this.modalService.open(ProfileRequestComponent, {
      backdrop: 'static',
      windowClass: 'contrast-modal'
    });

    modalRef.componentInstance.client = client;
    modalRef.componentInstance.hasCredits = hasCredits;
  }
}
