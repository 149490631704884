<div>
    <div class="mt-3">
        <div class="row">
            <div class="col monitor-background">
                <div class="row mb-4">
                    <div class="col zero-margin-padding ">
                        <h2>Intelligence&nbsp;Reports</h2>
                    </div>
                    <div class="col">
                        <div class="col d-flex justify-content-end">
                            <div>
                                <app-intelligence-request-button></app-intelligence-request-button>
                            </div>
                        </div>      
                    </div>
                </div>
                <div class="row">
                    <int-list
                        [projectList]="intelService.ProjectList" 
                        [projectTotal]="intelService.ProjectListTotal"
                        [currentPage]="intelService.ProjectListPage" 
                        (changePageEvent)="changePage($event)"
                        (viewAuditHistoryEventEvent)="viewAudit($event)"
                        (downloadReportEvent)="downloadReport($event)">
                    </int-list>               
                </div>
            </div>
        </div>  
    </div>
</div>