import { AlertService } from 'src/app/_alert';
import { ChangePasswordCommand } from 'src/nswag';
import { AccountManagementClient, UpdateAccountCommand, GetAccountByIdModel } from 'src/nswag';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountManagementService {

  constructor(private client: AccountManagementClient, private alertService: AlertService) { }

  alertServiceOptions = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  changePassword(cmd: ChangePasswordCommand) {
    this.client.changePassword(cmd).subscribe(() => {
      this.alertService.success('Password changed successfully', this.alertServiceOptions);
    });
  }

  updateProfile(cmd: UpdateAccountCommand) {
    this.client.update(cmd).subscribe(() => {
      this.alertService.success('Profile updated successfully', this.alertServiceOptions);
    });
  }
}
