<div class="style-background">
  <div *ngIf="searchEnabled">
    <h2>Saved Investigations</h2>
    <ng-template [ngTemplateOutlet]="displayInvestigations" [ngTemplateOutletContext]="{graphs: savedGraphs, delete: true}"></ng-template>
  </div>
  <div class="mt-5" *ngIf="searchEnabled">
    <h2>Investigations shared with me</h2>
    <ng-template [ngTemplateOutlet]="displayInvestigations" [ngTemplateOutletContext]="{graphs: sharedGraphs, delete: false}"></ng-template>
  </div>
  
  <app-loader [show]="isLoading"></app-loader>
  
  <ng-template #displayInvestigations let-graphs="graphs" let-delete="delete">
    <span *ngIf="graphs?.length == 0">There are no investigations to display</span>
    <table *ngIf="graphs?.length > 0" class="table table-striped table-themis">
      <thead class="table-light">
        <tr>
          <th>Name</th>
          <th>Last Modified</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let graph of graphs">
          <td>
            <a class="btn btn-link" (click)="selectGraph(graph)">{{graph.name}}</a>
          </td>
          <td>
            {{graph.lastModifiedOn | date : 'medium'}}
          </td>
          <td>
            {{graph.owner}}
          </td>
          <td>
            <button class="btn " type="button" (click)="delete ? deleteGraph(graph) : unshareGraph(graph)" aria-label="Unshare"><span class="icon-trash2"></span></button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>
  </div>