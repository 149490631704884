import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UtilityService } from "src/app/utilitities/utilities";
import { IndividualLink, IndividualProfile } from 'src/nswag';

@Component({
  selector: "mon-individual-associations",
  templateUrl: "./individual-associations.component.html",
  styleUrls: ["./individual-associations.component.scss"]
})
export class IndividualAssociationsComponent implements OnChanges {

  @Input() clientId: string;
  @Input() profile: any;
  @Input() name: string;
  @Input() incHeader = true;
  @Input() canAddToGraph: boolean;
  @Input() cachedProfiles: string[] = [];
  @Input() allowDiscounting = true;
  @Output() newProfile = new EventEmitter<string>();

  public individualLinks: IndividualLink[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.individualLinks = this.profile?.individualLinks;
  }

  getName(link: IndividualLink): string {
     return UtilityService.getIndividualName(link);
  }

  addToGraph(profile: any) {
    this.newProfile.emit(profile.resourceId);
  }

  checkCanAddToGraph(profile: IndividualProfile): boolean {
    if (!this.canAddToGraph) {
      return false;
    }
    return !(this.cachedProfiles.find(v => v == profile.resourceId));
  }

  checkCanDiscount(profile: IndividualProfile) : boolean {    
    if(!this.canAddToGraph) {
      // Allow to discount if not used with the graph
      return true;
    }
    return !(this.cachedProfiles.find(v => v == profile.resourceId));
  }

}
