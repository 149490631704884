<div class="container mw-100">
  <div class="row">
    <div class="col">
      <button class="nav-link text-dark btn btn-sm btn-warning btn-yellow-bg tour-flag" (click)="start()" title="Tour">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="20px" viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
    
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M919 5106 c-56 -20 -97 -54 -121 -99 -20 -38 -23 -60 -26 -215 -4
        -194 2 -224 49 -241 32 -11 54 -6 82 17 15 13 17 35 17 182 0 154 2 169 21
        194 16 20 29 26 58 26 78 0 71 154 71 -1648 l0 -1612 -75 0 -75 0 0 1268 c0
        1217 -1 1270 -18 1285 -30 27 -87 22 -111 -9 -21 -27 -21 -27 -21 -1284 l0
        -1258 -88 -4 c-71 -3 -100 -10 -144 -31 -75 -37 -134 -98 -172 -177 l-31 -65
        -3 -214 c-4 -232 4 -304 43 -381 16 -31 79 -99 190 -205 92 -87 174 -173 183
        -189 13 -25 17 -68 20 -219 4 -187 4 -187 29 -207 25 -19 41 -20 480 -20 l454
        0 24 25 c14 13 25 33 25 44 0 11 -34 107 -75 215 l-75 196 0 562 c0 310 -4
        590 -9 623 -25 172 -149 290 -320 304 l-76 6 -3 298 c-1 193 1 297 8 297 5 0
        49 -12 97 -26 119 -35 316 -44 453 -20 205 36 445 137 718 301 99 60 693 460
        821 553 32 24 33 73 1 107 -45 48 -36 52 -472 -248 -538 -371 -786 -501 -1058
        -557 -178 -37 -365 -21 -527 45 l-43 17 0 894 c0 492 2 894 5 894 3 0 40 -29
        83 -63 348 -287 716 -398 1206 -367 89 5 271 23 406 40 373 45 563 56 728 42
        209 -19 353 -58 517 -140 73 -36 235 -136 235 -145 0 -1 -60 -2 -133 -3 -192
        -2 -359 -42 -582 -141 -119 -53 -135 -65 -135 -110 0 -34 36 -83 60 -83 6 0
        66 24 133 54 362 160 614 174 888 51 72 -33 82 -35 106 -23 33 16 57 60 48 88
        -10 32 -198 213 -299 289 -276 207 -546 301 -906 317 -155 7 -319 -4 -668 -46
        -136 -17 -315 -34 -399 -40 -502 -32 -859 91 -1199 413 l-94 89 0 104 c0 118
        -10 153 -61 201 -61 57 -160 78 -240 49z m468 -3319 c22 -15 50 -46 64 -70
        l24 -42 5 -623 5 -622 -285 0 -286 0 -29 67 c-24 56 -45 82 -134 167 -238 226
        -248 237 -261 297 -8 36 -11 121 -8 254 4 217 7 232 65 287 57 54 80 58 361
        58 329 0 312 -8 312 152 l0 110 64 -4 c49 -3 73 -10 103 -31z m182 -1566 c11
        -32 21 -61 21 -65 0 -3 -151 -6 -335 -6 l-335 0 0 65 0 65 314 0 313 0 22 -59z" />
        </g>
        </svg>
      </button>
    </div>
  </div>
</div>
  <div class="mx-auto align-middle">    
  <div class="container home-container">
   <div id="home-logo" class="my-5 mx-auto main-image main-logo"></div>
    <div id="home-menu" class="d-flex justify-content-center flex-wrap">
       <div class="menu-box mb-4" [ngClass]="[idvEnabled() ? 'eidv' : ' eidv icon-disabled bg-disabled']" [routerLink]="['/idv']">
        <div class="grid-style">
          <span class="default-icon">
            <img src="../../../assets/images/eIDV.svg" alt="eIDV" class="img-fluid module-icon mt-1">
          </span>
          <span class="hover-icon">
            <img src="../../../assets/images/eIDV-white.svg" alt="eIDV" class="img-fluid module-icon mt-1">
          </span>
        </div>
        <h4 class="module-title font-default">eIDV</h4>
      </div>

      <div class="menu-box mb-4" [ngClass]="[riskAssessmentEnabled() ? '' : 'icon-disabled bg-disabled']" [routerLink]="['/risk-assessment']">
        <div class="grid-style">
          <span class="default-icon">
            <img src="../../../assets/images/Assessment.svg" alt="Assessment" class="img-fluid module-icon mt-1">
          </span>
          <span class="hover-icon">
            <img src="../../../assets/images/Assessment-white.svg" alt="Assessment" class="img-fluid module-icon mt-1">
          </span>
        </div>
        <h4 class="module-title font-default">Assessment</h4>
      </div>

      <div class="menu-box mb-4" [ngClass]="[screeningEnabled() ? '' : 'icon-disabled bg-disabled']" [routerLink]="['/screen']" [state]='{ local: true, pageName: "screen", refresh: true }'>
        <div class="grid-style">
          <span class="default-icon">
            <img src="../../../assets/images/Screen.svg" alt="Screen" class="img-fluid mt-1 module-icon">
          </span>
          <span class="hover-icon">
            <img src="../../../assets/images/Screen-white.svg" alt="Screen" class="img-fluid module-icon mt-1">
          </span>
        </div>
        <h4 class="module-title font-default">Screen</h4>
      </div>

      <div class="menu-box mb-4" [ngClass]="[monitoringEnabled() ? '' : 'icon-disabled bg-disabled']" [routerLink]="['/monitor']" [state]='{ local: true, pageName: "monitor", refresh: true }'>
        <div class="grid-style">
          <span class="default-icon">
            <img src="../../../assets/images/Monitor.svg" alt="Monitor" class="img-fluid module-icon">
          </span>
          <span class="hover-icon">
            <img src="../../../assets/images/Monitor-white.svg" alt="Monitor" class="img-fluid module-icon">
          </span>
        </div>
        <h4 class="module-title font-default">Monitor</h4>
      </div>

      <div class="menu-box mb-4" [ngClass]="[searchEnabled() ? '' : 'icon-disabled bg-disabled']" [routerLink]="['/search']">
        <div class="grid-style">
          <span class="default-icon">
            <img src="../../../assets/images/Search.svg" alt="Search" class="img-fluid module-icon module-icon-wide">
          </span>
          <span class="hover-icon">
            <img src="../../../assets/images/Search-white.svg" alt="Search" class="img-fluid module-icon module-icon-wide">
          </span>
        </div>
        <h4 class="module-title font-default">Search</h4>
      </div>

      <div class="menu-box mb-4" [ngClass]="[intelEnabled() ? '' : 'icon-disabled bg-disabled']" [routerLink]="['/intelligence']">
        <div class="grid-style center-content">
          <span class="default-icon">
            <img src="../../../assets/images/Intelligence.svg" alt="Intelligence" class="img-fluid module-icon">
          </span>
          <span class="hover-icon">
            <img src="../../../assets/images/Intelligence-white.svg" alt="Intelligence" class="img-fluid module-icon">
          </span>
        </div>
        <h4 class="module-title font-default">Intelligence</h4>
      </div>

      <div class="menu-box mb-4" [ngClass]="[sarEnabled() ? 'sar' : ' sar icon-disabled bg-disabled']" [routerLink]="['/regulatory-reports']">
        <div class="grid-style center-content">
          <span class="default-icon">
            <img src="../../../assets/images/SAR.svg" alt="SAR" class="img-fluid module-icon">
          </span>
          <span class="hover-icon">
            <img src="../../../assets/images/SAR-white.svg" alt="SAR" class="img-fluid module-icon">
          </span>
        </div>
        <h4 class="module-title font-default">Reg. Report</h4>
      </div>
  
    </div>
  
    <div class="version">
      <span [innerHTML]="getVersionNumber()"></span>
    </div>
  </div>
</div>
