<h2>{{title}}</h2>

<div *ngIf="businessMatches?.length == 0; else showContent">
    No matching profiles found!
</div>

<ng-template #showContent>
    <div *ngFor="let bus of businessMatches; let pos = index">
        <div>
            <h5>{{bus.name}}
                <span class="float-end">
                    {{bus.score}} % <span class="text-center fst-italic mb-0 text-muted score-text">Accuracy</span>
                </span>                
            </h5>
        </div>
        <div>
            <span *ngIf="bus.currentStatus == 'open'"><span  class="badge rounded-pill bg-danger">Open Match</span>&nbsp;</span>
            <span *ngIf="bus.currentStatus == 'false-positive'"><span  class="badge rounded-pill bg-warning">Discounted</span>&nbsp;</span>
            <span *ngIf="bus.currentStatus == 'true-positive'"><span  class="badge rounded-pill bg-info">Matched</span>&nbsp;</span>
            <span *ngIf="isSOE(bus)"><span  class="badge rounded-pill bg-danger">SOE</span>&nbsp;</span>
            <span *ngIf="isFormerSOE(bus)"><span  class="badge rounded-pill bg-warning">Former SOE</span>&nbsp;</span>
            <span *ngIf="isSanction(bus)"><span  class="badge rounded-pill bg-danger">Sanctioned</span>&nbsp;</span>
            <span *ngIf="isFormerSanction(bus)"><span  class="badge rounded-pill bg-danger">Previous Sanctions</span>&nbsp;</span>
            <span *ngIf="hasLawEnforcement(bus)"><span  class="badge rounded-pill bg-danger">Law Enforcement</span>&nbsp;</span>
            <span *ngIf="hasAdverseMedia(bus)"><span  class="badge rounded-pill bg-warning">Adverse Media</span>&nbsp;</span>
        </div>
        <div *ngIf="bus?.countries?.length > 0" class="pt-2">
            Countries: {{getCountries(bus)}}
        </div>
    </div>
    <hr>
</ng-template>

