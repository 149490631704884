import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IActorBase } from 'src/app/dashboard/models/Actor';
import { EntityTypes, Event, Evidence, IIndividualInfo, RelEntry } from 'src/nswag';

@Component({
  selector: 'mon-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnChanges {

  @Input() profile: any;
  @Input() clientId: string;
  @Input() actor: IActorBase;
  @Input() name: string;
  @Input() incHeader = true;
  @Input() allowDiscounting = true;
  @Input() reportMode: boolean = false;

  public regEntries: RelEntry[];
  public lawEntries: RelEntry[];
  public evidences: Evidence[];
  public isSPIL = false;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.actor) {
      this.isSPIL = this.actor.actorType == EntityTypes.Individual;
    }
    
    if (!this.profile) {
      return;
    }
    this.evidences = this.profile?.evidences;
    this.lawEntries = new Array<RelEntry>();
    this.regEntries = new Array<RelEntry>();

    for (let entry of this.profile?.relEntries) {
      if (entry?.category == "Law Enforcement") {
        this.lawEntries.push(entry);
      }
      else if (entry?.category == "Financial Regulator") {
        this.regEntries.push(entry);
      }
    }     

  }

  public checkCategory(entry: RelEntry) : boolean {
    return entry?.events?.length > 0;
  }

  public getCategory(entry: RelEntry) : string {
    return entry?.subcategory?.length > 0 ? entry?.subcategory : ( entry?.category?.length > 0 ? entry.category : "Uncategorised");
  }

  crimeType(ind: IIndividualInfo): string {
    ind.convictionDate
    ind.court
    ind.sentence
    ind.dataSource
    switch (ind?.crimeType) {
      case "MSHT":
        return "Modern Slavery & Human Trafficking";
      case "IWT":
        return "Illegal Wildlife Trading";
      case "Fraud":
        return "Fraud";
      case "Money Laundering":
        return "Money Laundering";
      case "Corruption":
        return "Corruption";
      case "Environmental Crime":
        return "Environmental Crime";
    }
    return ind?.crimeType ?? "Unknown";
  }

  getSources(ind: IIndividualInfo): string[] {
    return  (ind?.dataSource ? ind?.dataSource.split(",") : []);
  }

  getSafeUrl(source: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(source);
  }
  
  get hasLegalEvidence(): boolean {
    return (this.profile?.ddEntries?.length > 0 || this.profile?.insEntries?.length > 0 || this.lawEntries?.length > 0 || this.regEntries?.length > 0) && this.evidences?.length > 0;
  }
}