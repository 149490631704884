import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Profile } from 'oidc-client';
import { ProfileService } from 'src/app/profile.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { ClientResult, Log } from 'src/nswag';

@Component({
  selector: 'mon-clienthistory',
  templateUrl: './client-history.component.html',
  styleUrls: ['./client-history.component.scss']
})
export class ClientHistoryComponent implements OnChanges {

  @Input() clientObject: ClientResult;
  @Input() showReport: boolean;
  @Input() auditLogs: Log[] = [];
  @Output() hide = new EventEmitter<boolean>();

  public showSpinner: boolean = false;
  public userAccount: Profile;
  public reportName: string;
  public addedDate: string;
  public monitorDays: string;

  public inMonitoring = false;
  public dateNow: string;

  constructor(private profileService: ProfileService) {
    this.userAccount = profileService.getProfile();
  }

  ngOnChanges() {
    if (this.showReport) {
      this.dateNow = UtilityService.convertToLocalDateTime(new Date(), "long");
      this.showSpinner = true;

      var w: any = window;
      if (w.fcWidget) {
        w.fcWidget.hide();
      }

      if (this.auditLogs?.length > 0) {
        this.reportName = this.clientObject?.client?.business?.name ?? this.clientObject?.client?.individual?.name;

        this.inMonitoring = this.clientObject?.client?.inMonitoring;

        this.addedDate = UtilityService.convertToLocalDateTime(new Date(this.auditLogs[this.auditLogs.length - 1].timestamp), "long");
        let addedTimestamp = new Date(this.addedDate).getMilliseconds();
        this.monitorDays = Math.round((new Date().getMilliseconds() - addedTimestamp) / (1000 * 60 * 60 * 24)).toString();

        this.showSpinner = false;
      }
    }
    else {
      var w: any = window;
      if (w.fcWidget) {
        w.fcWidget.show();
      }
    }
  }

  public onPrint(): void {
    window.print();
  }

  closeReport() {
    this.showReport = false;
    this.hide.emit(false);

    var w: any = window;
    if (w.fcWidget) {
      w.fcWidget.show();
    }
  }

  getAuditEntries(): any[] {
    return this.auditLogs;
  }

  get userName(): string { return this.userAccount?.email; }
  get userFullName(): string { return this.profileService?.fullName; }
  get companyName(): string { return this.profileService?.companyName; }
}
