import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProfileService } from 'src/app/profile.service';
import { ApiResponseOfGetReportFormSchemaModel, CreateReportCommand, UpdateReportCommand } from 'src/nswag';
import { CompleteReportDialogComponent } from '../complete-report-dialog/complete-report-dialog.component';
import { RegulatoryReportsService } from '../regulatory-reports.service';
import { PrimeLoaderService } from 'src/app/loader/prime-loader/prime-loader.service';

@Component({
  selector: 'app-report-content',
  templateUrl: './report-content.component.html',
  styleUrls: [
    './report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})

export class ReportContentComponent implements OnInit {

  public reportId: string = null;
  public reportTypeId: string;
  public reportVersionId: string;

  public step: number = 0;
  public totalSteps: number;

  public encodedSignatureUrl: string = null;
  public usersFullName: string = null;

  public options: FormlyFormOptions = {
    formState: {
      readonly: false
    }
  };
  
  public form = new FormGroup({});
  public fieldConfig: FormlyFieldConfig[] = [];
  public model = {}; 

  private dialogRef: DynamicDialogRef | undefined;

  constructor(private loader: PrimeLoaderService, private messageService: MessageService, public dialogService: DialogService, private router: Router, private confirmationService: ConfirmationService, private reportService: RegulatoryReportsService, public profileService: ProfileService, private cdr: ChangeDetectorRef) {
    const state = this.router.getCurrentNavigation()?.extras?.state
    this.reportId = state?.reportId ?? this.reportId;
    this.reportTypeId = state?.reportTypeId ?? this.reportTypeId;
    this.reportVersionId = state?.reportVersionId ?? this.reportVersionId;

    this.usersFullName = this.profileService.fullName;
    this.options.formState.readonly = state?.readOnly ?? false;
  }

  ngOnInit(): void {
    this.loader.show();
    this.reportService.getReportFormSchema(this.reportTypeId, this.reportVersionId).subscribe({
      next: (result: ApiResponseOfGetReportFormSchemaModel) => {      
        const parsedFieldConfig = this.deserializeFieldConfig(result.data.schema);
        this.fieldConfig = parsedFieldConfig;
        this.totalSteps = this.fieldConfig.length - 1;
      },
      error: (error) => {
        console.error(error);
        this.messageService.add({severity: 'error', summary: 'Something went wrong', detail: 'There was a problem fetching the report schema. Please try again, or contact support if the issue persists'});
      },
    }).add(() => {
      this.loader.hide();
      if (this.reportId) {
        this.loadSavedFormContent();
      } 
    }); 
  }

  public onNextSectionClicked(currentStep: any) {
    if (this.options.formState.readonly || this.validateStep(currentStep)) {
      // Section valid or form is readonly, proceed
      this.step = Math.min(++this.step, this.totalSteps);
      this.cdr.detectChanges();
    }
  }

  public onPreviousSectionClicked(event: any) {
    this.step = Math.max(--this.step, 0);
    this.cdr.detectChanges();    
  }

  public onExit(): void {
    if (this.formDirty) {
      this.confirmationService.confirm({
        message: 'Are you sure that you want to exit?',
        header: 'Unsaved Changes',
        icon: 'pi pi-exclamation-triangle',
        accept: () => { 
          this.confirmationService.close();
          this.navigateToList();
        },
        reject: () => {
          this.confirmationService.close();
        },
        rejectButtonStyleClass: 'p-button-danger p-button-outlined',
        defaultFocus: 'none'
      });
    } else {
      this.navigateToList();
    }
  }
  
  public get formDirty(): boolean {  
    return this.form.dirty;
  }

  public showCompleteReportDialog() {
    if (this.validateStep(this.step)) {
      this.dialogRef = this.dialogService.open(CompleteReportDialogComponent, {
        header: 'Sign & Complete',
        width: '550px',
        modal: true,
        data: {
          usersFullName: this.usersFullName
        }
      });
      let componentRef = this.dialogService.dialogComponentRefMap.get(this.dialogRef);
      componentRef.changeDetectorRef.detectChanges();
      const instance = componentRef.instance.componentRef.instance as CompleteReportDialogComponent;
      instance.onCompleteEvent.subscribe((signature: string) => {  
        this.dialogRef.close(); 
        this.onComplete(signature);
      });
    }
  }

  public onComplete(signatureUrl: string): void {
    if (signatureUrl) {
      this.encodedSignatureUrl = signatureUrl;
      this.onSave(true)
    }
  }

  public getFormBySection(sectionNumber: number): FormGroup {
    // Get the key for the current section
    const currentSectionKey = this.fieldConfig[sectionNumber]?.fieldGroup[0]?.key?.toString();
    // Get the form group for the current section
    return this.form.get(currentSectionKey) as FormGroup;
  }

  public onSave(markAsComplete: boolean = false): void {
    if (this.step === 1 && !this.validateStep(this.step)) {
      // Step 1 required for saving
      return;
    }

    this.loader.show();
    this.trimFormStringValues(this.form);

    const formContentJson = JSON.stringify(this.model);
    const sectionOneForm =  this.getFormBySection(1);
    const reportTitle = sectionOneForm.controls.reportName.value;

    var message: Message;

    if (this.reportId) {
      const command = new UpdateReportCommand({
        id: this.reportId,
        title: reportTitle,
        jsonFormData: formContentJson,
        markAsComplete: markAsComplete,
        encodedSignatureUrlSVG: this.encodedSignatureUrl
      });
      
      this.reportService.updateReport(command).subscribe({
        next: (res) => {
          if (res.isSuccess) {
            message = markAsComplete ? {severity: 'success', summary: 'Report completed', detail: `Report: ${reportTitle}`} : {severity: 'success', summary: 'Report saved', detail: `Report: ${reportTitle}`};
            this.markFormsAsPristine();
            if (markAsComplete) {
              this.onExit();
            } 
          }
        },
        error: (error) => {
          console.error(error);
          message = {severity: 'error', summary: 'Something went wrong', detail: 'There was a problem updating the report. Please try again, or contact support if the issue persists'};
        }
      }).add(() => { 
        this.messageService.add(message);
        this.loader.hide(); 
      });
    }
    else {
      const command = new CreateReportCommand({
        title: reportTitle,
        jsonFormData: formContentJson,
        markAsComplete: markAsComplete,
        encodedSignatureUrl: this.encodedSignatureUrl,
        reportTypeId: this.reportTypeId,
        reportVersionId: this.reportVersionId
      });
  
      this.reportService.createReport(command).subscribe({
        next: (res) => {
          if (res.isSuccess) {
            message = markAsComplete ? {severity: 'success', summary: 'Report completed', detail: `Report: ${reportTitle}`} : {severity: 'success', summary: 'Report created', detail: `Report: ${reportTitle}`};
            this.markFormsAsPristine();
            this.reportId = res.data;
            if (markAsComplete) {
              this.onExit(); 
            } 
          }
        },
        error: (error) => {
          console.error(error);
          message = {severity: 'error', summary: 'Something went wrong', detail: 'There was a problem creating the report. Please try again, or contact support if the issue persists'};
        }
        })
        .add(() => { 
          this.messageService.add(message);
          this.loader.hide(); 
      });
    }
  }

  private validateStep(step: number): boolean {
    const sectionForm = this.getFormBySection(step);

    if (sectionForm && sectionForm.invalid) {
      // show validators
      sectionForm.markAllAsTouched();
    }
    return sectionForm?.valid ?? false;
  }

  private markFormsAsPristine(): void {
    this.form.markAsPristine();
  }

  private loadSavedFormContent() {
    this.loader.show();
    this.reportService.getSavedReportContent(this.reportId).subscribe({
      next: (res) => {
        if (res.data) {
          const parsedData = JSON.parse(res.data.jsonFormData);
          this.model = parsedData;
        }
      },
      error: (error) => {
        console.error(error);
        this.messageService.add({severity: 'error', summary: 'Something went wrong', detail: 'There was a problem loading the report. Please try again, or contact support if the issue persists'});
      }
    }).add(() => { 
      this.loader.hide(); 
    });
  }

  private navigateToList() {
    this.router.navigate(['/regulatory-reports']);
  }

  private deserializeFieldConfig(jsonString: string): FormlyFieldConfig[] {
    return JSON.parse(jsonString, (key, value) => {
      if (typeof value === 'string' && (value.startsWith('function') || value.includes('=>'))) {
        return new Function(`return ${value}`)();
      }
      return value;
    });
  }

  private trimFormStringValues(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
  
      if (control instanceof FormControl && typeof control.value === 'string') {
        control.setValue(control.value.trim(), { emitEvent: false });
      } else if (control instanceof FormGroup || control instanceof FormArray) {
        this.trimFormStringValues(control);
      }
    });
  }
}
