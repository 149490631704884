import { Component } from '@angular/core';
import { AlertStatus, LAMPSStatus } from 'src/nswag';
import { BaseReportSectionComponent } from '../base-client-section.component';

@Component({
  selector: 'mon-status-section',
  templateUrl: './status-section.component.html',
  styleUrls: ['./status-section.component.scss']
})
export class StatusSectionComponent extends BaseReportSectionComponent {

  public get alertStatus() : string {

    return this.matchStatus?.alertStatus == AlertStatus.Closed ? "Monitored" : this.matchStatus?.alertStatus;
  }

  public isActive(status?: LAMPSStatus) : boolean {
    return status != LAMPSStatus.No;
  }

  public get hasMatches() : string {
    if (this.matchStatus?.isMatched == LAMPSStatus.Yes) {
      return `${this.clientName} has been matched to a profile.`;
    }
    if (this.matchStatus?.isMatched == LAMPSStatus.Changed) {
      return `${this.clientName} has been matched to a profile that has changes.`;
    }  
    if (this.matchStatus?.isMatched == LAMPSStatus.Discounted) {
      return "All potential matches have been discounted.";
    }  
    if (this.matchStatus?.matches == LAMPSStatus.No) {
      if (this.isScreened) {
        return `Good news! No matches have been found for ${this.clientName}.`;
      }
      return `${this.clientName} has been added to monitoring and is awaiting action.`
    }
    if (this.matchStatus?.matches == LAMPSStatus.Yes) {
      return `Matches have been found for ${this.clientName}.`;
    }
    if (this.matchStatus?.matches == LAMPSStatus.Changed) {
      return `New matches have been found for ${this.clientName}.`;
    }
  }
}
