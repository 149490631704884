<div *ngIf="isShareable()">
  <button class="ms-3 btn btn-secondary" (click)="showShareDialog(shareContent)">Share</button>
</div>

<ng-template #shareContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Share "{{dashService.investigationName}}" investigation? </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="checkCanShare() else showWarning">
      <div *ngIf="showSpinner; else displayList">
        <div class="spinner-border text-primary" role="status"></div>
      </div>
      <ng-template #displayList>
        <div *ngIf="hasUsersToShare(); else showNoResults">
          <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
            <thead>
              <tr>
                <th>Users</th>
              </tr>
            </thead>
            <tbody>
              <tr class="p-1">
                <td>
                  <input class="form-control" type="text" [(ngModel)]="nameFilter" placeholder="Filter ..." (keyup)="filterList()" />
                </td>
              </tr>
              <tr *ngFor="let user of userListFiltered" (click)="setUser(user)">
                <td>
                  <span *ngIf="user.thinking" class="spinner-border spinner-border-sm text-primary"></span>
                  <span *ngIf="!user.thinking">
                    <input type="checkbox" [checked]="user.status" (keyup)="setUser(user)" />
                  </span>
                  <span class="ms-2">{{user.name}}</span>
                </td>
              </tr>
          </table>
        </div>
      </ng-template>
    </div>
    <div class="mt-3">
      <div *ngIf="charLimitReached" class="form-control alert alert-warning">
        Max character limit reached.
      </div>
      <textarea class="form-control" placeholder="Add notes.." rows="5" [maxlength]="notesCharLimit" [(ngModel)]='notes' (change)="onTextChange(notes)"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" [disabled]="!hasUsersToShare()" (click)="shareInvestigation(modal)">Share</button>
  </div>
  
</ng-template>

<ng-template #showWarning>
  <p class="alert alert-danger">
    Please save your investigation before trying to share.
  </p>
</ng-template>

<ng-template #showNoResults>
  <p class="alert alert-warning">
    There are no other users registered to share this investigation with.
  </p>
</ng-template>
