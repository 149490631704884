<h4 class="bg-dark text-light p-4">
    {{individualClient?.name}}
    <div class="float-end">
        <mon-alerticongroup [client]="client" [removeTooltip]="true"></mon-alerticongroup> 
    </div>
</h4>
<div>
    <div *ngIf="individualClient?.clientRef" class="row">
        <div class="col-4">
            Client reference:
        </div>
        <div class="col-8">
            {{individualClient.clientRef}}
        </div>
    </div>
    <div *ngIf="individualClient?.nationality" class="row">
        <div class="col-4">
            Nationality:
        </div>
        <div class="col-8">
            {{getNationalityName(individualClient.nationality)}}
        </div>
    </div>
    <div *ngIf="individualClient?.dateOfBirth" class="row">
        <div class="col-4">
            Date of birth:
        </div>
        <div class="col-8">
            {{individualClient?.dateOfBirth}}
        </div>
    </div>
    <div *ngIf="individualClient?.riskLevel" class="row">
        <div class="col-4">
            Risk level:
        </div>
        <div class="col-8">
            {{individualClient.riskLevel}}
        </div>
    </div>
</div>


