<div class="container g-0">
  <div class="modal-header">
    <h4 class="modal-title col">View Results</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container g-0">
      <div *ngIf="currentRecipient.completedOn; else notComplete" class="row">
        <div class="col-10">
          We have analysed your individual diagnostics and your overall rating
          is:
        </div>
        <div class="col-2">
          <div class="badge-img float-end col-auto">
            <report-status-badge
              [currentRecipient]="currentRecipient"
            ></report-status-badge>
          </div>
        </div>
      </div>
      <ng-template #notComplete>
        <div class="col">
          The individual diagnostics cannot be analysed until the Assessment is
          complete.
        </div>
      </ng-template>
      <div *ngIf="recipientResponseControls.length > 0">
        <h2>Controls</h2>
        <div class="accordion" id="accordionPanelsStayOpenControls">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne-controls">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne-controls"
                aria-expanded="true"
                aria-controls="collapseOne-controls"
              >
                Overview
              </button>
            </h2>
            <div
              id="collapseOne-controls"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne-controls"
            >
              <div class="accordion-body">
                <charts
                  [type]="chartType.RADAR"
                  [currentRecipient]="currentRecipient"
                  [recipientResponse]="recipientResponseControls"
                  [questionSets]="questionSets"
                  [category]="'Controls'"
                  [industryAverages]="industryAverages"
                ></charts>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo-controls">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo-controls"
                aria-expanded="false"
                aria-controls="collapseTwo-controls"
              >
                Breakdown
              </button>
            </h2>
            <div
              id="collapseTwo-controls"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo-controls"
            >
              <div class="accordion-body">
                <charts
                  [type]="chartType.BAR"
                  [currentRecipient]="currentRecipient"
                  [recipientResponse]="recipientResponseControls"
                  [questionSets]="questionSets"
                  [category]="'Controls'"
                  [industryAverages]="industryAverages"
                ></charts>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree-controls">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree-controls"
                aria-expanded="false"
                aria-controls="collapseThree-controls"
              >
                Issues, risks and recommendations
              </button>
            </h2>
            <div
              id="collapseThree-controls"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree-controls"
            >
              <div class="accordion-body">
                <risks-table
                  [currentRecipient]="currentRecipient"
                  [category]="'Controls'"
                ></risks-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div *ngIf="recipientResponseCapabilities.length > 0">
        <h2>Capabilities</h2>
        <div class="accordion" id="accordionPanelsStayOpenCapabilities">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne-capabilities">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne-capabilities"
                aria-expanded="true"
                aria-controls="collapseOne-capabilities"
              >
                Overview
              </button>
            </h2>
            <div
              id="collapseOne-capabilities"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne-capabilities"
            >
              <div class="accordion-body">
                <charts
                  [type]="chartType.RADAR"
                  [currentRecipient]="currentRecipient"
                  [recipientResponse]="recipientResponseCapabilities"
                  [questionSets]="questionSets"
                  [category]="'Capabilities'"
                  [industryAverages]="industryAverages"
                ></charts>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo-capabilities">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo-capabilities"
                aria-expanded="false"
                aria-controls="collapseTwo-capabilities"
              >
                Breakdown
              </button>
            </h2>
            <div
              id="collapseTwo-capabilities"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo-capabilities"
            >
              <div class="accordion-body">
                <charts
                  [type]="chartType.BAR"
                  [currentRecipient]="currentRecipient"
                  [recipientResponse]="recipientResponseCapabilities"
                  [questionSets]="questionSets"
                  [category]="'Capabilities'"
                  [industryAverages]="industryAverages"
                ></charts>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree-capabilities">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree-capabilities"
                aria-expanded="false"
                aria-controls="collapseThree-capabilities"
              >
                Issues, risks and recommendations
              </button>
            </h2>
            <div
              id="collapseThree-capabilities"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree-capabilities"
            >
              <div class="accordion-body">
                <risks-table
                  [currentRecipient]="currentRecipient"
                  [category]="'Capabilities'"
                ></risks-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="currentRecipient.completedOn" class="float-end p-2">
        <button (click)="createReport()" class="btn m-1 bg-primary text-light">
          Create Report
        </button>
      </div>
    </div>
  </div>
</div>
