
  <app-nav-menu></app-nav-menu>
  <div class="container-fluid">    
    <alert></alert>
    <app-prime-toast></app-prime-toast>
    <app-prime-loader></app-prime-loader>
    <app-prime-confirm-dialog></app-prime-confirm-dialog>
    <app-problem-details></app-problem-details>
    <div id="dialogueContainer" name="dialogueContainer" class="dialogueContainer"></div>
    <router-outlet></router-outlet>
  </div>