
<div *ngIf="showMain">
    <div class="mt-3">
        <div class="row">
            <div class="col monitor-background">
                <div class="row mb-4 sc-search-container">
                    <div class="col-3 no-padding-left sc-advance-btns">
                        <app-screen-client-button ></app-screen-client-button>   
                    </div>
                    <div class="col-5 search-column">
                        <mon-search *ngIf="showScreen()" (searchData)="setSearch($event)" (performSearch)="searchClient()"></mon-search>    
                    </div>
                    <div class="col-1 advanced"><a *ngIf="showScreen()" class="advanced mt-2 text-center col-1" (click)="displaySearchOptions()">Advanced</a></div>
                    <div class="col-3">
                        <button class="nav-link text-dark btn btn-sm btn-warning btn-yellow-bg tour-flag" id="tourguide-btn" (click)="start()" title="Tour">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="20px" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">
                        
                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                            fill="#000000" stroke="none">
                            <path d="M919 5106 c-56 -20 -97 -54 -121 -99 -20 -38 -23 -60 -26 -215 -4
                            -194 2 -224 49 -241 32 -11 54 -6 82 17 15 13 17 35 17 182 0 154 2 169 21
                            194 16 20 29 26 58 26 78 0 71 154 71 -1648 l0 -1612 -75 0 -75 0 0 1268 c0
                            1217 -1 1270 -18 1285 -30 27 -87 22 -111 -9 -21 -27 -21 -27 -21 -1284 l0
                            -1258 -88 -4 c-71 -3 -100 -10 -144 -31 -75 -37 -134 -98 -172 -177 l-31 -65
                            -3 -214 c-4 -232 4 -304 43 -381 16 -31 79 -99 190 -205 92 -87 174 -173 183
                            -189 13 -25 17 -68 20 -219 4 -187 4 -187 29 -207 25 -19 41 -20 480 -20 l454
                            0 24 25 c14 13 25 33 25 44 0 11 -34 107 -75 215 l-75 196 0 562 c0 310 -4
                            590 -9 623 -25 172 -149 290 -320 304 l-76 6 -3 298 c-1 193 1 297 8 297 5 0
                            49 -12 97 -26 119 -35 316 -44 453 -20 205 36 445 137 718 301 99 60 693 460
                            821 553 32 24 33 73 1 107 -45 48 -36 52 -472 -248 -538 -371 -786 -501 -1058
                            -557 -178 -37 -365 -21 -527 45 l-43 17 0 894 c0 492 2 894 5 894 3 0 40 -29
                            83 -63 348 -287 716 -398 1206 -367 89 5 271 23 406 40 373 45 563 56 728 42
                            209 -19 353 -58 517 -140 73 -36 235 -136 235 -145 0 -1 -60 -2 -133 -3 -192
                            -2 -359 -42 -582 -141 -119 -53 -135 -65 -135 -110 0 -34 36 -83 60 -83 6 0
                            66 24 133 54 362 160 614 174 888 51 72 -33 82 -35 106 -23 33 16 57 60 48 88
                            -10 32 -198 213 -299 289 -276 207 -546 301 -906 317 -155 7 -319 -4 -668 -46
                            -136 -17 -315 -34 -399 -40 -502 -32 -859 91 -1199 413 l-94 89 0 104 c0 118
                            -10 153 -61 201 -61 57 -160 78 -240 49z m468 -3319 c22 -15 50 -46 64 -70
                            l24 -42 5 -623 5 -622 -285 0 -286 0 -29 67 c-24 56 -45 82 -134 167 -238 226
                            -248 237 -261 297 -8 36 -11 121 -8 254 4 217 7 232 65 287 57 54 80 58 361
                            58 329 0 312 -8 312 152 l0 110 64 -4 c49 -3 73 -10 103 -31z m182 -1566 c11
                            -32 21 -61 21 -65 0 -3 -151 -6 -335 -6 l-335 0 0 65 0 65 314 0 313 0 22 -59z"/>
                            </g>
                            </svg>
                        </button>
                        <button class="nav-link text-dark btn btn-sm btn-warning btn-yellow-bg tour-flag" id="archive-btn" (click)="openClientArchive()" title="Archived">
                            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" viewBox="0 0 16 16" class="bi bi-folder">
                                <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div *ngIf="showAdvancedOptions" [@inOutAnimation]>
                            <advanced-search-options [advancedQuery]="advancedSearchOptions" (searchData)="setSearchOptions($event)"></advanced-search-options>
                        </div>
                    </div>
                </div>
                <div class="row detail-table">
                    <mon-list
                        [clientList]="screeningService.ClientList"
                        [clientTotal]="screeningService.ClientListTotal"
                        [currentPage]="screeningService.ClientListPage"
                        [allowEdit]="false"
                        (showHistoryReportEvent)="viewHistoryReport($event)"
                        (showClientReportEvent)="viewClientReport($event)"
                        (deleteEvent)="deleteClient($event)"
                        (selectEvent)="selectClient($event)"
                        (changePageEvent)="changePage($event)">
                    </mon-list>
                    <div class="d-flex justify-content-end sc-export">
                        <mon-export-clients *ngIf="showScreen()" [currentModule]="currentModule" [exportClientListCallBack]="screeningService.exportClientList" [clientTotal]="screeningService.ClientListTotal"></mon-export-clients>
                    </div>
                </div>
            </div>
            <div *ngIf="screeningService.SelectedClient" class="col-4 detail-data">
                <mon-detail
                    [client]="screeningService.SelectedClient"
                    [profile]="screeningService.SelectedProfile"
                    [currentModule]="currentModule"
                    (clientChange) = "clientChange($event)"
                    (addToMonitoringEvent) = "addToMonitoring($event)"
                    (searchMatchesEvent)="searchMatches()"
                    (viewMatchesEvent)="viewMatches()">
                </mon-detail>
            </div>
        </div>
    </div>
</div>

<mon-clientreport [showReport]="showClientReport" [profile]="currentProfile" [individualMatches]="individualMatches" [businessMatches]="businessMatches" (hide)="closeClientReport($event)" [client]="currentClient" [module]="currentModule" [auditLogs]="auditLogs"></mon-clientreport>

<mon-clienthistory [showReport]="showHistoryReport" [auditLogs]="auditLogs" (hide)="closeHistoryReport($event)" [clientObject]="currentClient"></mon-clienthistory>

<app-match-report></app-match-report>

<app-loader [show]="isLoading"></app-loader>
