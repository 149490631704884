import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-idv-search',
  templateUrl: './idv-search.component.html',
  styleUrls: ['./idv-search.component.scss']
})
export class IdvSearchComponent implements OnInit {

  @Input() search: string;
  @Output() searchChange = new EventEmitter<string>(); 

  constructor() { }

  ngOnInit(): void {
  }

  public runSearch() {
    this.searchChange.emit(this.search);
  }

}
