<h2>{{title}}</h2>

<div *ngIf="individualMatches?.length == 0; else showContent">
    No matching profiles found!
</div>

<ng-template #showContent>
    <div *ngFor="let ind of individualMatches; let pos = index">
        <div>
            <h5>{{ind.name}}
                <span class="float-end">
                    {{ind.score}} % <span class="text-center fst-italic mb-0 text-muted score-text">Accuracy</span>
                </span>                
            </h5>
        </div>
        <div>
            <span *ngIf="ind.currentStatus == 'open'"><span class="badge rounded-pill bg-danger">New Match</span>&nbsp;</span>
            <span *ngIf="ind.currentStatus == 'false-positive'"><span  class="badge rounded-pill bg-warning">Discounted</span>&nbsp;</span>
            <span *ngIf="ind.currentStatus == 'true-positive'"><span  class="badge rounded-pill bg-info">Matched</span>&nbsp;</span>
            <span *ngIf="isPEP(ind)"><span  class="badge rounded-pill bg-danger">PEP</span>&nbsp;</span>
            <span *ngIf="isFormerPEP(ind)"><span  class="badge rounded-pill bg-warning">Former PEP</span>&nbsp;</span>
            <span *ngIf="isSanction(ind)"><span  class="badge rounded-pill bg-danger">Sanctioned</span>&nbsp;</span>
            <span *ngIf="isFormerSanction(ind)"><span  class="badge rounded-pill bg-danger">Previous Sanctions</span>&nbsp;</span>
            <span *ngIf="hasLawEnforcement(ind)"><span  class="badge rounded-pill bg-danger">Law Enforcement</span>&nbsp;</span>
            <span *ngIf="hasAdverseMedia(ind)"><span  class="badge rounded-pill bg-warning">Adverse Media</span>&nbsp;</span>
        </div>
        <div class="pt-2">
            Gender: {{ind.gender}}
        </div>
        <div *ngIf="ind?.countries?.length > 0">
            Countries: {{getCountries(ind)}}
        </div>
        <br>
    </div>
</ng-template>
