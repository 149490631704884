import { Component, OnInit } from '@angular/core';
import { ErrorDetail } from '../models/ErrorDetail';
import { ErrorService } from '../services/error.service';

@Component({
  selector: 'app-problem-details',
  templateUrl: './problem-details.component.html',
  styleUrl: './problem-details.component.scss'
})
export class ProblemDetailsComponent implements OnInit {
  problemDetails: ErrorDetail | null = null;

  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    this.problemDetails = null;
    this.errorService.problemDetails$.subscribe(details => {
      this.problemDetails = details;
    });
  }

  close() {
    this.problemDetails = null;
  }
}
