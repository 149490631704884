import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class IdvStatusCodeService {
    public static statusCodeToClass(status: string): string {
        switch (status?.toLowerCase()) {
          // ACCEPTED
          case "accepted": return "bg-success";
          // CREATED
          case "created": return "bg-secondary";
          // DECLINED
          case "declined": return "bg-danger";
          // FAILED
          case "failed": return "bg-warning";
          // WAITING
          case "waiting": return "bg-warning";
          // investigate other codes
          default: return "bg-danger";
        }
      }
}