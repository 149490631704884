<div ngbDropdown class="d-inline-block" [autoClose]="'outside'">
    <button class="btn {{buttonColour}} ms-3" id="dropdownBasic1" ngbDropdownToggle>Status: {{getStatus()}}</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <div *ngIf="status != _alertStatus.Open" ngbDropdownItem>
            <div class="mt-3">
                <textarea class="form-control" [readonly]="readonly" placeholder="{{ placeholderOpeningText }}" cols="70" rows="5" (ngModelChange)="validation($event)" [(ngModel)]='reason'></textarea>
                <div *ngIf="isMandatoryNotesEnabled && hasErrors" class="alert alert-danger">
                    <div *ngIf="hasErrors">
                      Please enter a reason.
                    </div>
                  </div>
            </div>
            <div class="mt-3">
                <button class="btn btn-block btn-primary" (click)="changeStatus(_alertStatus.Open)">Open this alert</button>
            </div>
        </div>
        <div *ngIf="checkCanClose" ngbDropdownItem>
            <div class="mt-3">
                <textarea class="form-control" [readonly]="readonly" placeholder="{{ placeholderClosingText }}" cols="70" rows="5" (ngModelChange)="validation($event)" [(ngModel)]='reason'></textarea>
                <div *ngIf="isMandatoryNotesEnabled && hasErrors" class="alert alert-danger">
                    <div *ngIf="hasErrors">
                        Please enter a reason.
                    </div>
                  </div>
            </div>
            <div class="mt-3">
                <button class="btn btn-block btn-danger" (click)="changeStatus(_alertStatus.Closed)">Close this alert</button>
            </div>
        </div>
        <!--
            <button *ngIf="status != _alertStatus.Escalated" (click)="changeStatus(_alertStatus.Escalated)" ngbDropdownItem>Escalate</button>
            <button *ngIf="status != _alertStatus.Investigating" (click)="changeStatus(_alertStatus.Investigating)" ngbDropdownItem>Investigate</button> 
        -->
    </div>
</div>
