import { Component, Input, OnInit } from '@angular/core';
import { AssessmentRecipient } from 'src/nswag';

@Component({
  selector: 'report-status-badge',
  templateUrl: './report-status-badge.component.html',
  styleUrls: ['./report-status-badge.component.scss']
})
export class ReportStatusBadgeComponent implements OnInit {
  public rating: string;

  @Input() currentRecipient: AssessmentRecipient;

  constructor() { }

  ngOnInit(): void {
    this.rating = this.currentRecipient.displayStatus;
  }

}
