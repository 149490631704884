<div *ngIf="existingRecipientList?.length > 0">
  <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
    <thead>
      <tr>
        <th scope="col">Add</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Company</th>
        <th scope="col">Industry</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let recipient of existingRecipientList | paginate : {
        id: 'existingRecipientPagination',
        itemsPerPage: pageSize,
        currentPage: currentPage,
        totalItems: recipientTotal
        };
        let i = index"
          (mouseenter)="setFocusedRecipient(recipient)" (click)="selectRecipient(recipient)" class="align-middle">
  
        <td><input id="chk_{{recipient.id}}" type="checkbox" [checked]="isChecked(recipient)" (change)="selectRecipient(recipient)" /></td>
        <td>{{displayName(recipient)}}</td>
        <td>{{displayEmail(recipient)}}</td>
        <td>{{displayCompany(recipient)}}</td>
        <td>{{displayIndustry(recipient)}}</td>
      </tr>
    </tbody>
  </table>
  <pagination-controls id="existingRecipientPagination"
                       class="pagination"
                       previousLabel="Prev"
                       nextLabel="Next"
                       [responsive]="true"
                       (pageChange)="handlePageChange($event)">
  </pagination-controls>
</div>
<span *ngIf="existingRecipientList?.length == 0">There are no records to display</span>