<div id="monitor-soe">
    <h5 *ngIf="incHeader">State Owned Enterprise</h5>
    <div *ngIf="isSOE; else notSOE">
        <p class="alert alert-danger">
            {{name}} {{isPrevious ? "was prevously" : "is"}} a {{level}} State Owned Enterprise (SOE).
        </p>
    </div>
    <ng-template #notSOE>
        <div class="col alert alert-info">
            {{name}} is not a State Own Enterprise (SOE).
        </div>
    </ng-template>
</div>
