import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { IIndividualInfo, IIdvUserProfile } from 'src/nswag';
import { ReportUtilities } from '../../report-utils';

@Component({
  selector: 'app-report-individualdetails',
  templateUrl: 'individualdetails.component.html',
  styleUrls: ['individualdetails.component.scss']
})

export class IndividualDetailsReportComponent implements OnInit{
  
  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public rowColors: Map<string, string> = new Map<string, string>();
  public individual : IIndividualInfo;
  public userProfile: IIdvUserProfile;

  constructor(public dashService: DashboardService, public reportUtils: ReportUtilities) {}

  ngOnInit() {
    this.individual = this.dashService.getInvestigation().asIndividualInfo;
    this.reinstateRowColors();
    setTimeout(() => {
      this.resizeAllTextAreas();
    }, 3000);
  }

  public hasCrimeData(): boolean {
    return !!(this.individual?.dataSource || 
            this.individual?.crimeType || 
            this.individual?.convictionDate || 
            this.individual?.court ||
            this.individual?.sentence);
  }

  public resizeAllTextAreas(): void {
    this.textareas.toArray().forEach((item) => { 
      item.nativeElement.style.height = 'auto';
      item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
    }); 
  }

  public setRowColor(controlName: string): void {
    var control = this.dashService.getInvestigation()?.reportActorFreeText?.controls[controlName];
    this.rowColors.set(controlName, this.reportUtils.getRowColor(control?.value));
  }
  
  private reinstateRowColors(): void {
    Object.keys(this.dashService.getInvestigation()?.reportActorFreeText?.controls).forEach(key => {
      this.setRowColor(key);
    });
  }
}
