import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BusinessLink, BusinessProfile } from 'src/nswag';

@Component({
  selector: 'app-discounted-businesses',
  templateUrl: './discounted-businesses.component.html',
  styleUrls: ['./discounted-businesses.component.scss']
})
export class DiscountedBusinessesComponent implements OnChanges {

  @Input() profile: BusinessProfile;
  @Input() incHeader = true;

  public hasBusinessDiscounts = false;

  public businessLinks: BusinessLink[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profile) {
      this.businessLinks = this.profile?.businessLinks;
      this.hasBusinessDiscounts = this.businessLinks?.find( e => e.discountReason?.isDiscounted ) != null;
    }
  }
}
