
<a *ngIf="enabled" role="button" [ngbPopover]="popContent" triggers="manual" #p="ngbPopover" container="body" [autoClose]="false" [popoverTitle]="popTitle" (click)="closeExistingPopovers(); p.open()">
    <span class="icon-trash2"></span>
    <ng-template #popTitle>
        <div class="pop-title-wrapper p-2">
            Enter a discount reason
        </div>
    </ng-template>
    <ng-template #popContent>
        <div class="m-2">
            <textarea class="form-control" type="text" [(ngModel)]="discountReason" placeholder="Enter your reason for discounting." ng-required="required"></textarea>
            <div class="button-wrapper pt-3 pb-2 float-end">
                <button class="btn btn-light me-5" (click)="p.close()">Cancel</button>
                <button class="btn" (click)="remove(p)">Delete</button>
            </div>
        </div>
    </ng-template>
</a>            
<span *ngIf="!enabled">
    <span class="icon-trash2 icon-disabled"></span>
</span>            
