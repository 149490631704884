import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormBuilder, FormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './dashboard.component';
import { SubjectComponent } from './subject/subject.component';
import { GraphComponent } from './graph/graph.component';
import { FinCrimeCheckClient, MyGraphClient } from 'src/nswag';
import { SaveandreportComponent } from './saveinvestigation/saveinvestigation.component';
import { RunCheckComponent } from './runcheck/runcheck.component';
import { WhistleblowComponent } from './whistleblow/whistleblow.component';
import { AuditReportComponent } from './showreport/audit-report/audit-report/audit-report.component';
import { SubjectEditorComponent } from './subject-editor/subject-editor.component';
import { CompanySubjectEditorComponent } from './subject-editor/company-subject-editor/company-subject-editor.component';
import { PersonSubjectEditorComponent } from './subject-editor/person-subject-editor/person-subject-editor.component';
import { ShareComponent } from './share/share.component';
import { LoaderModule } from '../loader/loader.module';
import { ReadMoreModule } from '../_readmore/readmore.module';
import { InvestigationDetailsComponent } from './investigation-details/investigation-details.component';
import { MatchesModule } from '../matching-service/matches.module';
import { LampsPanelsModule } from '../lamps-panels/lamps-panels.module';
import { AlertIconsModule } from '../alert-icons/alter-icons.module';
import { ReportModule } from './showreport/report.module';
import { PipesModule } from '../pipes/pipes.module';
import { NgxColorsModule } from 'ngx-colors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NodeExpansionComponent } from './graph/node-expansion/node-expansion.component';
import { InvestigationModule } from '../investigation/investigation.module';
import { DatePickerModule } from '../date-picker/date-picker.module';

@NgModule({
  declarations: [
    DashboardComponent,
    SubjectComponent,
    GraphComponent,
    SaveandreportComponent,
    RunCheckComponent,
    WhistleblowComponent,
    AuditReportComponent,
    SubjectEditorComponent,
    CompanySubjectEditorComponent,
    PersonSubjectEditorComponent,
    ShareComponent,
    InvestigationDetailsComponent,
    NodeExpansionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReadMoreModule,
    LoaderModule,
    MatchesModule,
    LampsPanelsModule,
    AlertIconsModule,
    PipesModule,
    ReportModule,
    NgxColorsModule,
    BrowserAnimationsModule,
    InvestigationModule,
    DatePickerModule
  ],
  providers: [
    MyGraphClient,
    FinCrimeCheckClient,
    UntypedFormBuilder
  ],

})
export class DashboardModule { }
