import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddBusinessCommand, AddIndividualCommand, ApiResponse, ApiResponseOfBusinessMatches, ApiResponseOfBusinessProfile, ApiResponseOfIndividualMatches, ApiResponseOfClientAuditLogs, UpdateBusinessCommand, UpdateIndividualCommand, UploadCommand, UploadFileMetadata, MonitorType, ApiResponseOfBoolean, ApiResponseOfGetWorklistDetailsWorklist, UpdateMonitorSettingsCommand, GetMonitoringClientExportQuery, ApiResponseOfListOfString, DeleteBusinessCommand, DeleteIndividualCommand, RecoverClientCommand } from 'src/nswag';
import { UtilityService } from 'src/app/utilitities/utilities';
import { ApiResponseOfIndividualProfile, BusinessMatch, Client, IndividualMatch } from 'src/nswag';
import { LAMPSSummary, LAMPSTypes, ListClientsQuery, MonitoringClient, AlertStatus, ClientResult } from 'src/nswag';
import { SelectBusinessMatchCommand, SelectIndividualMatchCommand, BusinessProfile, IndividualProfile } from 'src/nswag';
import { UpdateAlertStatusCommand, ApiResponseOfIndividualClient, ApiResponseOfBusinessClient } from 'src/nswag';
import { ApiResponseOfClientResult } from 'src/nswag';
import { CountriesService } from 'src/app/utils/countries.service';

@Injectable({
  providedIn: 'root'
})

export class MonitorService {
  public static pageSize = 10;

  constructor(private monitorClient: MonitoringClient) {
    this.currentClientList = new Array<ClientResult>();
  }

  private currentClientList: ClientResult[];
  private currentClientListTotal: number;
  private currentClientListQuery: ListClientsQuery;

  private alertSummary: LAMPSSummary;
  private selectedClient: ClientResult;
  private selectedBusinessProfile: BusinessProfile;
  private selectedIndividualProfile: IndividualProfile;

  public get Summary(): LAMPSSummary {
    return this.alertSummary;
  }

  public get ClientList(): ClientResult[] {
    return this.currentClientList;
  }

  public get ClientListTotal(): number {
    return this.currentClientListTotal ?? 0;
  }

  public get ClientListPage(): number {
    return this.currentClientListQuery?.pageNumber ?? 1;
  }

  public get SelectedClient(): ClientResult {
    return this.selectedClient;
  }
  
  public SetSelectedClient(client: ClientResult) {
    if (!client) {
      this.resetGlobals(true);
      return;
    }

    // We know we are in monitoring so set the flag each time
    client.client.inMonitoring = true;

    if (client.client.business) {
      this.getBusinessMatchedProfile(client).subscribe(r => {
        this.selectedClient = client;
        if (r.isSuccess) {
          this.selectedBusinessProfile = r.data;
          this.selectedIndividualProfile = null;
          this.refreshClientList(client);
        }
      });
    }
    else if (client.client.individual) {
      this.selectedClient = client;
      this.getIndividualMatchedProfile(client).subscribe(r => {
        if (r.isSuccess) {
          this.selectedIndividualProfile = r.data;
          this.selectedBusinessProfile = null;
          this.refreshClientList(client);
        }
      });
    }
  }

  public refreshClient() {
    if (this.selectedClient?.client) {
      this.monitorClient.getClientById(this.selectedClient.client.id).subscribe(result => {
        if (result.isSuccess) {
          this.SetSelectedClient(result.data);
        }
      });
    }
  }
  private refreshClientList(client: ClientResult) {
    if (this.currentClientList) {
      let i = this.currentClientList.findIndex(val => val.client.id == client.client.id);
      if (i > -1) {
        this.currentClientList[i] = client;
      }
    }
  }

  public get SelectedIndividualMatchedProfile(): IndividualProfile {
    return this.selectedIndividualProfile;
  }

  public set SelectedIndividualMatchedProfile(profile: IndividualProfile) {
    this.selectedIndividualProfile = profile;
  }

  public get SelectedBusinessMatchedProfile(): BusinessProfile {
    return this.selectedBusinessProfile;
  }

  public set SelectedBusinessMatchedProfile(profile: BusinessProfile) {
    this.selectedBusinessProfile = profile
  }

  public get SelectedProfile(): any {
    return this.selectedIndividualProfile ?? this.selectedBusinessProfile;
  }

  public refreshSummary() {
    var obs = this.monitorClient.getSummary();
    obs.subscribe(r => {
      if (r.isSuccess) {
        this.alertSummary = r.data;
      }
    });
  }

  // loads clients based on the type and status
  public loadLAMPSClients(lampsType?: LAMPSTypes, alertStatus?: AlertStatus) {
    let query = new ListClientsQuery;
    switch (lampsType) {
      case LAMPSTypes.Adv:
        query.hasAdvMedia = true;
        break;
      case LAMPSTypes.Law:
        query.hasLaw = true;
        break;
      case LAMPSTypes.Pep:
        query.hasPep = true;
        break;
      case LAMPSTypes.San:
        query.hasSanctions = true;
        break;
      default:
        break;
    }

    this.internalSearchClients(null, null, null, alertStatus, null, query.hasAdvMedia, query.hasSanctions, query.hasLaw, query.hasPep);
  }

  public loadArchivedClients() {
    this.currentClientListQuery = new ListClientsQuery({
      pageSize: MonitorService.pageSize,
      pageNumber: 1,
      showArchived: true
    });
    this.internalSearchClientsWithUpdatedQuery();
 }

  public loadLAMPSClientsPage(pageNumber: number) {
    if (this.currentClientListQuery) {
      this.currentClientListQuery.pageNumber = pageNumber;
      this.currentClientListQuery.pageSize = MonitorService.pageSize;
      this.internalSearchClientsWithUpdatedQuery();
    }
  }

  public searchForClients(query: ListClientsQuery) {
    let countryCode = null;
    if (query.countryCode) {
      var code = UtilityService.getCodeFromCountry(query.countryCode);
      countryCode = code == '' ? query.countryCode : code;
    }
    this.internalSearchClients(query.search, countryCode, query.dateOfBirth, null, null, query.hasAdvMedia, query.hasSanctions,
      query.hasLaw, query.hasPep, query.isOpen, query.isClosed, query.clientRef, query.tags, query.riskLevel, query.showArchived, query.orderByAsc);
  }

  public saveIndividual(client: Client): Observable<ApiResponseOfIndividualClient> {

    let nationality = CountriesService.getNationalityCode(client.individual.nationality);
    
    if (client.id) {
      let cmd = new UpdateIndividualCommand(client.individual);
      cmd.address = client.address;
      cmd.nationality = nationality;
      return this.monitorClient.updateIndividual(cmd, client.id);
    }
    let cmd = new AddIndividualCommand(client.individual);
    cmd.address = client.address;
    cmd.nationality = nationality;
    return this.monitorClient.addIndividual(cmd);
  }

  public exportClientList = (showArchived: boolean) => {
    var query = new GetMonitoringClientExportQuery({
      listClientsQuery: this.currentClientListQuery ?? new ListClientsQuery(),
      limit: 10000,
    });

    query.listClientsQuery.showArchived = showArchived;

    return this.monitorClient.getMatchedClientExport(query);
  }

  public saveBusiness(client: Client): Observable<ApiResponseOfBusinessClient> {

    let jurisdiction = CountriesService.getJurisdictionCode(client.business?.jurisdiction);
    
    if (client.id) {
      let cmd = new UpdateBusinessCommand(client.business);
      cmd.address = client.address;
      cmd.jurisdiction = jurisdiction;
      return this.monitorClient.updateBusiness(cmd, client.id)
    }
    let cmd = new AddBusinessCommand(client.business);
    cmd.address = client.address;
    cmd.jurisdiction = jurisdiction;
    return this.monitorClient.addBusiness(cmd);
  }

  public deleteClient(client: ClientResult, notes: string): Observable<any> {
    if (client?.client?.business) {
      return this.monitorClient.deleteBusiness(new DeleteBusinessCommand({id: client.client.id, notes: notes}));
    } else {
      return this.monitorClient.deleteIndividual(new DeleteIndividualCommand({id: client.client.id, notes: notes}));
    }
  }

  public recoverClient(client: ClientResult, notes: string): Observable<any> {
    return this.monitorClient.recoverClient(new RecoverClientCommand({clientId: client.client.id, notes: notes}));
  }

  public getIndividualMatches(client: ClientResult = null, showLoader: boolean = false): Observable<ApiResponseOfIndividualMatches> {
    let id = client?.client?.id ?? this.selectedClient?.client?.id;
    let monitorId = client?.client?.monitorRecordId ?? this.selectedClient?.client?.monitorRecordId;
    return this.monitorClient.getIndividualMatches(id, monitorId);
  }

  public getIndividualMatchedProfile(client: ClientResult, showLoader: boolean = true): Observable<ApiResponseOfIndividualProfile> {
    return this.monitorClient.getIndividualMatchedProfile(client?.client?.id);
  }

  public getIndividualProfile(individual: IndividualMatch): Observable<ApiResponseOfIndividualProfile> {
    return this.monitorClient.getIndividualProfile(individual.resourceId);
  }

  public getBusinessMatches(client: ClientResult = null, showLoader = false): Observable<ApiResponseOfBusinessMatches> {
    let id = client?.client?.id ?? this.selectedClient?.client?.id;
    let monitorId = client?.client?.monitorRecordId ?? this.selectedClient?.client?.monitorRecordId;
    return this.monitorClient.getBusinessMatches(id, monitorId);
  }

  public getBusinessMatchedProfile(client: ClientResult, showLoader: boolean = true): Observable<ApiResponseOfBusinessProfile> {
    return this.monitorClient.getBusinessMatchedProfile(client?.client?.id);
  }

  public checkClientExists(ref: string, type: MonitorType): Observable<ApiResponseOfBoolean> {
    return this.monitorClient.checkClientReferenceExists(ref, type);
  }

  public getBusinessProfile(business: BusinessMatch): Observable<ApiResponseOfBusinessProfile> {
    return this.monitorClient.getBusinessProfile(business.resourceId);
  }

  public getClientAuditLogs(clientId: string): Observable<ApiResponseOfClientAuditLogs> {
    return this.monitorClient.getClientLogs(clientId);
  }

  public selectBusinessMatch(command: SelectBusinessMatchCommand): Observable<ApiResponseOfClientResult> {
    return this.monitorClient.selectBusinessMatch(command, this.selectedClient.client.id);
  }

  public selectIndividualMatch(command: SelectIndividualMatchCommand): Observable<ApiResponseOfClientResult> {
    return this.monitorClient.selectIndividualMatch(command, this.selectedClient.client.id);
  }

  public refreshSelectedClient(client: ClientResult) {
    this.SetSelectedClient(client);
    this.refreshSummary();
  }

  public uploadImportFile(file: UploadFileMetadata): Observable<ApiResponseOfListOfString> {
    let cmd = new UploadCommand({
      file: file
    });
    return this.monitorClient.import(cmd);
  }

  public getWorklistDetails(tenantId: string): Observable<ApiResponseOfGetWorklistDetailsWorklist> {
    return this.monitorClient.getWorklistDetails(tenantId);
  }

  public updateMonitorSettings(command: UpdateMonitorSettingsCommand): Observable<ApiResponse> {
    return this.monitorClient.updateMonitorSettings(command);
  }

  public updateAlertStatus(status: AlertStatus): Observable<ApiResponseOfClientResult> {
    var command = new UpdateAlertStatusCommand({ status: status, clientId: this.selectedClient.client.id });
    return this.monitorClient.updateAlertStatus(command, this.selectedClient.client.id);
  }

  public removeClient(client: ClientResult) {
    var index = this.ClientList.indexOf(client, 0);
    if (index > -1) {
      this.ClientList.splice(index, 1);
    }
    this.SetSelectedClient(null);
  }

  private internalSearchClients(search?: string | null | undefined, countryCode?: string | null | undefined, dateOfBirth?: Date | null | undefined, alertStatus?: AlertStatus | null | undefined, lampsType?: LAMPSTypes | null | undefined, hasAdvMedia?: boolean | undefined, hasSanctions?: boolean | undefined, hasLaw?: boolean | undefined, hasPep?: boolean | undefined, isOpen?: boolean | undefined, isClosed?: boolean | undefined, clientRef?: string | null | undefined, tags?: string[] | null, riskLevel?: string | null | undefined, showArchived?: boolean | undefined, orderByAsc?: boolean | undefined,  pageNumber = 1) {
    this.resetGlobals(false);
    this.currentClientListQuery = new ListClientsQuery({
      search: search,
      hasAdvMedia: hasAdvMedia,
      hasLaw: hasLaw,
      hasSanctions: hasSanctions,
      hasPep: hasPep,
      alertStatus: alertStatus,
      isOpen: isOpen,
      isClosed: isClosed,
      countryCode: countryCode,
      dateOfBirth: dateOfBirth,
      clientRef: clientRef,
      tags: tags,
      riskLevel: riskLevel,
      pageSize: MonitorService.pageSize,
      pageNumber: pageNumber,
      showArchived: showArchived,
      orderByAsc: orderByAsc
    });
    this.internalSearchClientsWithUpdatedQuery();
  }

  private internalSearchClientsWithUpdatedQuery() {
    if (this.currentClientListQuery) {
      var obs = this.monitorClient.list(this.currentClientListQuery);
      obs.subscribe(r => {
        if (r.isSuccess) {
          this.currentClientList = r.data.results;
          this.currentClientListTotal = r.data.totalCount;
        }
      });
    }
  }

  public resetGlobals(justSelected: boolean) {
    if (!justSelected) {
      this.currentClientList = [];
      this.currentClientListQuery = null;
    }
    this.selectedClient = null;
    this.selectedBusinessProfile = null;
    this.selectedIndividualProfile = null;
  }
}
