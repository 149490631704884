<div id="monitor-sanction">
    <p class="alert alert-info my-3" *ngIf="hasSanctions && evidences?.length > 0">
        Selecting <strong>View URL Evidence</strong> will direct you to the link. Please note that the source may have expired and is provided by a third-party. Select <strong>View PDF Evidence</strong> to access the PDF version
    </p>
    <h5 *ngIf="incHeader">Sanctions</h5>
    <div *ngIf="hasSanctions; else emptySanctions">
        <div *ngIf="hasCurrent" class="row">
            <p class="alert alert-danger">Current sanctions for {{name}}</p>
            <ng-container [ngTemplateOutlet]="displaySanctions" [ngTemplateOutletContext]="{sanctions: sanEntries.current}"></ng-container>
        </div>
        <div *ngIf="hasPrevious" class="row">
            <p class="alert alert-danger">Previous sanctions for {{name}}</p>
            <ng-container [ngTemplateOutlet]="displaySanctions" [ngTemplateOutletContext]="{sanctions: sanEntries.former}"></ng-container>
        </div>
    </div>

    <ng-template #emptySanctions>    
        <div class="col alert alert-info">No sanctions have been reported against {{name}}.</div>
    </ng-template>

    <ng-template #displaySanctions let-sanctions="sanctions">
        <div *ngFor="let sanction of sanctions; let pos=index">
            <div *ngIf="sanction?.sanctionId" class="row" id="left-card">
                <div class="col-4">
                    <label class="displaylabel-style">Sanction Id:</label>
                </div>
                <div class="col-8">
                    <span class="displaydetail-style">{{sanction?.sanctionId}}</span>
                </div>        
            </div>        
            <div class="row" id="left-card">
                <div class="col-4">
                    <label class="displaylabel-style">Sanction body:</label>
                </div>
                <div class="col-8">
                    <span *ngIf="sanction?.regime?.body" class="displaydetail-style">{{sanction?.regime?.body}}<br/></span>
                    <span *ngIf="sanction?.regime?.name" class="displaydetail-style">{{sanction?.regime?.name}}<br/></span>
                    <span *ngIf="sanction?.regime?.origin" class="displaydetail-style">{{sanction?.regime?.origin}}<br/></span>
                    <span *ngIf="sanction?.regime?.types?.length>0" class="displaydetail-style">{{getAsStringList(sanction?.regime?.types)}}</span>
                </div>
            </div>        
            <div *ngIf="sanction?.measures?.length > 0" class="row" id="left-card">
                <div class="col-4">
                    <label class="displaylabel-style">Measures:</label>
                </div>
                <div class="col-8">
                    <span class="displaydetail-style">{{getAsStringList(sanction?.measures)}}</span>
                </div>        
            </div>
            <div *ngIf="sanction.events" class="row pt-3">
                <mon-events [clientId]="clientId" [profile]="profile" [events]="sanction.events" [evidences]="evidences" noDataMessage="" [incHeader]="incHeader" [allowDiscounting]="allowDiscounting" [reportMode]="reportMode"></mon-events>
            </div>
            <hr *ngIf="pos < sanctions.length-1"/>
        </div>
    </ng-template>
</div>
