<div class="style-background border">
    <legend>API Administration </legend>
    <hr class="divider">
    <div class="card mb-5">
        <div class="card-header">
            <label class="card-title">Client ID</label>
        </div>
        <div class="card-body">
            <div class="row mb-1">
                <div class="col col-sm-4">
                    <div class="input-group">
                        <input class="form-control" [type]="fieldType" [value]="clientId" autocomplete="off" disabled>
                        <div class="input-group-append">
                        <button id="showBtn" (click)="toggleHide()" [disabled]="!clientId" class="btn btn-secondary">show</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="btn btn-primary" type="button" (click)="openModal(secretModalContent)" [disabled]="!clientId">Refresh Secret</button>
    <a href="{{apiSwaggerUrl}}" target="_blank">Documentation</a>
</div>



<ng-template #secretModalContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title col">Refresh Secret</h4>
        <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal() ">
            <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
           </button>
    </div>
    <div class="modal-body">
        <div class="alert alert-warning" role="alert" *ngIf="!secret">
            <p>Are you sure you want to refresh the secret for your API client?</p>
            <p>Doing so will terminate the current secret for you client.</p>
        </div>
        <div class="alert alert-success" role="alert" *ngIf="secret">
            <p>Success! Copy your secret and keep it somewhere safe.</p>
            <p>Once you close this window you will not be able to view it again.</p>
            <div class="">
                <textarea type="text"  rows="1" class="form-control">{{secret}}</textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="!secret" type="button" class="btn btn-primary" (click)="confirmModal(modal)">Yes</button>
        <button type="button" class="btn btn-danger" (click)="dismissModal() ">Close</button>
    </div>
</ng-template>

