import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-static-html',
    template: `<div [innerHtml]="field.props.htmlContent" [ngClass]="field.props.class"></div>`,
    styleUrls: [
        '../report-content/report-content.component.scss',
        '../regulatory-reports-base.component.scss'
    ]
})
export class FormlyFieldHtml extends FieldType {}
