<section>
    <br>
    <div class="container mt-3">
        <h3>Appendix</h3>
        <p class="fw-bolder">Themis Anti Financial Crime Capability and Control Maturity Descriptions<p>            
        <div class="table-responsive">
            <table class="table table-bordered table-themis table-recipient">
                <tbody>
                    <tr>
                        <td class="AAA">AAA+</td>
                        <td>Best in class - exceeds the industry anti-financial crime best practice.</td>
                        <td class="BBB">BBB-</td>
                        <td>Lower median - many improvements are required in the anti-financial crime environment.</td>
                    </tr>
                    <tr>
                        <td class="AAA">AAA</td>
                        <td>Best in class - meets all the industry anti-financial crime best practice.</td>
                        <td class="BB">BB+</td>
                        <td>Lower quartile - considerable improvements required in the anti-financial crime environment.</td>
                    </tr>
                    <tr>
                        <td class="AAA">AAA-</td>
                        <td>Best in class - the industry anti-financial crime best practice.</td>
                        <td class="BB">BB</td>
                        <td>Lower quartile - significant improvements are required in the anti-financial crime environment.</td>
                    </tr>
                    <tr>
                        <td class="AA">AA+</td>
                        <td>Very strong and robust anti-financial crime environment.</td>
                        <td class="BB">BB-</td>
                        <td>Lower quartile - extensive improvements required in the anti-financial crime environment.</td>
                    </tr>
                    <tr>
                        <td class="AA">AA</td>
                        <td>Strong and robust anti-financial crime environment.</td>
                        <td class="B">B+</td>
                        <td>Weak - some financial crime exposures and weaknesses identified.</td>
                    </tr>
                    <tr>
                        <td class="AA">AA-</td>
                        <td>Good anti-financial crime environment</td>
                        <td class="B">B</td>
                        <td>Weak - many financial crime exposures and weaknesses identified</td>
                    </tr>
                    <tr>
                        <td class="A">A+</td>
                        <td>Median - opportunities to strengthen the anti-financial crime
                            environment.</td>
                        <td class="B">B-</td>
                        <td>Weak - significant financial crime exposures and weaknesses identified.</td>
                    </tr>
                    <tr>
                        <td class="A">A</td>
                        <td>Median - a number of opportunities to strengthen the anti-financial
                            crime environment.</td>
                        <td class="CCC">CCC+</td>
                        <td>High risk - some potential breaches of regulatory and legal anti-financial crime requirements.</td>
                    </tr>
                    <tr>
                        <td class="A">A-</td>
                        <td>Median - many opportunities to strengthen the anti-financial crime
                            environment.</td>
                        <td class="CCC">CCC</td>
                        <td>High risk - a number of breaches of regulatory and legal anti-financial crime requirements.</td>
                    </tr>
                    <tr>
                        <td class="BBB">BBB+</td>
                        <td>Lower median - some improvements are required in the anti-financial
                            crime environment.</td>
                        <td class="CCC">CCC-</td>
                        <td>High risk - many breaches of regulatory and legal anti-financial crime requirements.</td>
                    </tr>
                    <tr>
                        <td class="BBB">BBB</td>
                        <td>Lower median - a number of improvements are required in the antifinancial crime environment.</td>
                        <td class="D">D</td>
                        <td>Requires urgent attention - Significant breaches of regulatory and legal antifinancial crime requirements.</td>
                    </tr>
              </tbody>
            </table>
        </div>
      </div>
</section>
