<h4 class="bg-dark text-light p-4">
    {{businessClient?.name}}
    <div class="float-end">
        <mon-alerticongroup [client]="client" [removeTooltip]="true"></mon-alerticongroup> 
    </div>
</h4>
<div>
    <div *ngIf="businessClient?.clientRef" class="row">
        <div class="col-4">
            Client reference:
        </div>
        <div class="col-8">
            {{businessClient.clientRef}}
        </div>
    </div>
    <div *ngIf="businessClient?.jurisdiction" class="row">
        <div class="col-4">
            Jurisdiction:
        </div>
        <div class="col-8">
            {{getJurisdictionName(businessClient.jurisdiction)}}
        </div>
    </div>
    <div *ngIf="businessClient?.riskLevel" class="row">
        <div class="col-4">
            Risk level:
        </div>
        <div class="col-8">
            {{businessClient.riskLevel}}
        </div>
    </div>
</div>


