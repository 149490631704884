import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Menu } from 'primeng/menu';
import { PrimeLoaderService } from 'src/app/loader/prime-loader/prime-loader.service';
import { ApiResponseOfGetReportTypesModel, ExportToPDFCommand, ListReportsQuery, RegulatoryReportStatus, RegulatoryReportType, Report } from 'src/nswag';
import { RegulatoryReportsService } from '../regulatory-reports.service';
import { ReportDeclarationDialogComponent } from '../report-declaration-dialog/report-declaration-dialog.component';
import { ReportSelectionDialogComponent } from '../report-selection-dialog/report-selection-dialog.component';

@Component({
  selector: 'app-reg-reports',
  templateUrl: './reports.component.html',
  styleUrls: [
    './reports.component.scss',
    '../regulatory-reports-base.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {
  
  @ViewChild('menu') menu: Menu;

  public openMenuIndex: number | null = null;
  public reports: Report[] = []; 
  public filterText: string = '';
  public currentPage: number = 0;
  public pageSize: number = 10;
  public totalReports: number = 0;
  public menuActions: any[];
  public searchClicked: boolean = false;
  public isTableLoading: boolean = false;

  private dialogRef: DynamicDialogRef | undefined;

  public reportTypesMap: RegulatoryReportType[] = [];

  constructor(private messageService: MessageService, public loader: PrimeLoaderService, private dialogService: DialogService, private reportService: RegulatoryReportsService, private router: Router, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.loader.show();
    this.reportService.getReportTypes().subscribe({
      next: (res: ApiResponseOfGetReportTypesModel) => {
        this.reportTypesMap = res.data.reportTypesMap;
      },
      error: (error) => {
        console.error(error);
        this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'There was a problem fetching the report types. Please try again, or contact support if the issue persists'});
      }
    }).add(() => {
        this.loader.hide();
        this.getReportsList();
    });
  }
  
  public handleAction(event, report) {
    event.item.command({ item: report });
  }

  public generatePdf(reportId: string): void {
    if (reportId) {
      const cmd = new ExportToPDFCommand({ reportId: reportId });
      this.loader.show();
      this.reportService.export(cmd).subscribe({
        next: (file) => {
          if(file.data) {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(file.data)
            a.href = objectUrl
            a.download = file.fileName;
            a.click();
            URL.revokeObjectURL(objectUrl);
          }
        },
        error: (error) => {
          this.messageService.add({severity:'error', summary: 'Something went wrong', detail: 'There was a problem downloading the PDF. Please try again, or contact support if the issue persists'});
          console.error('Error downloading pdf:', error);
        }
      }).add(() => {
        this.loader.hide();
      });
    }
  }

  public canEdit(report: Report): boolean {
    return report.status === RegulatoryReportStatus.Draft;
  }

  public canMarkAsSubmitted(report: Report): boolean {
    return report.status === RegulatoryReportStatus.Complete;
  }

  public onArchiveReport(report: Report) {
    if (report) {
      this.confirmationService.confirm({
        message: `Are you sure you want to archive the report titled '${report.title}' ?`,
        header: 'Confirm archive',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.archiveReport(report); 
          this.confirmationService.close();
        },
        reject: () => {
          this.confirmationService.close();
        },
        acceptButtonStyleClass: 'p-button-primary',
        rejectButtonStyleClass: 'p-button-danger p-button-outlined',
        defaultFocus: 'none'
      });
    }
  }

  public handlePageChange(event: any): void {
    this.currentPage = event.page;
    this.getReportsList();
  }

  public search(): void {
    this.searchClicked = true;
    this.getReportsList();
  }

  public showMenu(event: Event, report: any) {
    const clone = this.cloneEvent(event);
    if (this.menu.visible) {
        this.menu.hide();
        this.menuActions = [];
    }
    if (!this.menu.visible) {
      setTimeout(() => {
          this.menuActions = this.getActions(report);
          this.menu.show(clone); 
      }, 0);
    }
}

  private cloneEvent(e) {
    /* Used to overcome error 'TypeError: Cannot read properties of null (reading 'offsetHeight')' */
    function ClonedEvent() {}
    let clone = new ClonedEvent();
    for (let p in e) {
      let d = Object.getOwnPropertyDescriptor(e, p);
      if (
        d &&
        (!d.writable || !d.configurable || !d.enumerable || d.get || d.set)
      ) {
        Object.defineProperty(clone, p, d);
      } else {
        clone[p] = e[p];
      }
    }
    Object.setPrototypeOf(clone, e);
    return clone;
  }

  private getActions(report: Report) {
    const actions = [];
  
    if (this.canEdit(report)) {
      actions.push({ label: 'Edit', icon: 'pi pi-pencil', command: () => this.editReport(report) });
    } else {
      actions.push({ label: 'View', icon: 'pi pi-eye', command: () => this.viewReport(report) });
    }
  
    if (this.canMarkAsSubmitted(report)) {
      actions.push({ label: 'Mark as Submitted', icon: 'pi pi-check', command: () => this.onMarkAsSubmitted(report) });
    }
  
    actions.push({ label: 'Archive', icon: 'pi pi-trash', command: () => this.onArchiveReport(report) });
  
    if (!this.canEdit(report) || this.canMarkAsSubmitted(report)) {
      actions.push({ label: 'Download', icon: 'pi pi-download', command: () => this.generatePdf(report.id) });
    }
  
    return actions;
  }

  private archiveReport(report: Report): void {
    this.loader.show();
    this.reportService.archiveReport(report.id).subscribe({
      next: (result) => {
        if (result.isSuccess) {
          this.messageService.add({severity: 'success', summary: 'Report archived', detail: `Report: ${report.title}`});
          this.getReportsList();
        }
      },
      error: (error) => {
        console.error(error);
        this.messageService.add({severity: 'error', summary: 'Something went wrong', detail: `There was a problem archiving the report: ${report.title}. Please try again or contact our support team if the problem persists`});
      }
    }).add(() => this.loader.hide());
  }

  private getReportsList(): void {
    const query =  new ListReportsQuery({
      filterText: this.filterText,
      pageNumber: this.currentPage + 1,
      pageSize: this.pageSize
    });
    this.isTableLoading = true;
    this.reportService.ListReports(query).subscribe({
      next: (res) => {
        if (res.isSuccess) {
          if (res.data) {
            this.reports = res.data.reports ?? [];
            this.totalReports = res.data.totalCount;

            if (!this.filterText && !this.totalReports) {
              this.searchClicked = false;
            }
          }
        }
      },
      error: (error) => {
        console.error(error);
        this.messageService.add({severity: 'error', summary: 'Something went wrong', detail: 'There was a problem fetching the reports. Please try again or contact our support team if the problem persists'});
      }
    }).add(() => this.isTableLoading = false);
  }

  private editReport(report: Report) {
    const navigationExtras: NavigationExtras = {
      state: {
        reportId: report.id,
        reportTypeId: report.reportTypeId,
        reportVersionId: report.versionId
      }
    };
    this.router.navigate(['regulatory-reports/edit'], navigationExtras);
  }

  private viewReport(report: Report) {
    const navigationExtras: NavigationExtras = {
      state: {
        reportId: report.id,
        readOnly: true,
        reportTypeId: report.reportTypeId,
        reportVersionId: report.versionId
      }
    };
    this.router.navigate(['regulatory-reports/view'], navigationExtras);
  }

  public showSelectionDialog() {
    this.dialogRef = this.dialogService.open(ReportSelectionDialogComponent, {
      header: 'Create New Report',
      width: '50vw',
      modal: true,
      styleClass: 'overflow-visible',
      data: {
        reportTypesMap: this.reportTypesMap
      }
    });
  }

  private onMarkAsSubmitted(report: Report) {
    this.dialogRef = this.dialogService.open(ReportDeclarationDialogComponent, {
      header: 'Declaration',
      width: '30rem',
      modal:true,
      data: {
        report: report
      }
    });
    let componentRef = this.dialogService.dialogComponentRefMap.get(this.dialogRef);
    componentRef.changeDetectorRef.detectChanges();
    const instance = componentRef.instance.componentRef.instance as ReportDeclarationDialogComponent;
    instance.submittedEvent.subscribe((dateSubmitted: Date) => {  
      this.dialogRef.close(); 
      this.getReportsList();
    });
  }
}
