import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RiskAssessmentComponent } from './risk-assessment.component';
import { AssessmentSearchComponent } from './assessment-search/assessment-search.component';
import { FormsModule } from '@angular/forms';
import { CreateAssessmentButtonComponent } from './create-assessment-button/create-assessment-button.component';
import { CreateAssessmentDialogComponent } from './create-assessment-dialog/create-assessment-dialog.component';
import { RecipientsModule } from './recipients/recipients.module';
import { ManageQuestionSetsDialogComponent } from './manage-question-sets-dialog/manage-question-sets-dialog.component';
import { GroupReportComponent } from './group-report/group-report.component';
import { HeatmapKeyDialogComponent } from './heatmap-key-dialog/heatmap-key-dialog.component';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { ViewAuditTrailComponent } from './view-audit-trail/view-audit-trail.component';

@NgModule({
  declarations: [
    AssessmentListComponent, 
    RiskAssessmentComponent, 
    AssessmentSearchComponent, 
    CreateAssessmentButtonComponent, 
    CreateAssessmentDialogComponent, 
    ManageQuestionSetsDialogComponent,
    GroupReportComponent,
    HeatmapKeyDialogComponent,
    HeatmapComponent,
    ViewAuditTrailComponent
  ],
  imports: [ NgbModule, CommonModule, NgxPaginationModule, FormsModule, RecipientsModule ],
  exports: [AssessmentListComponent, AssessmentSearchComponent, RiskAssessmentComponent],
})
export class RiskAssessmentModule { }