import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IntelligenceRequestComponent } from '../intelligence-request/intelligence-request.component';
import { CurrentModule } from 'src/app/monitor/client-report/client-report.component';
import { RequestProjectDialogComponent } from '../intelligence-admin/request-project-dialog/request-project-dialog.component';

@Component({
  selector: 'app-intelligence-request-button',
  templateUrl: './intelligence-request-button.component.html',
  styleUrls: ['./intelligence-request-button.component.scss']
})
export class IntelligenceRequestButtonComponent {
  constructor(public modalService: NgbModal) { }
  @Input() isIntelAdmin = false;

  public launch() {
      const modalRef = this.modalService.open(this.isIntelAdmin ? RequestProjectDialogComponent : IntelligenceRequestComponent, {
        backdrop: 'static',
        windowClass: 'contrast-modal'
      });

      modalRef.componentInstance.currentModule = CurrentModule.INTELLIGENCE;
  }

}
