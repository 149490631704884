<section>
  <br />
  <h2>Other red flags identified</h2>
  <form [formGroup]="dashService.getInvestigation().reportActorFreeText">
    <div class="wrapper">
      <textarea #textarea id="redFlags" type="textarea" class="discardable-text"
        placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
        formControlName="redflagsHeading"></textarea> <span class="edit-icon"> click to edit</span>
      <p id="redFlagsText"></p>
    </div>

    <ng-template #noRedFlags>
      <p>No other red flags found.</p>
    </ng-template>
  </form>

  <div *ngIf="genericEdge; then redFlags else noRedFlags"></div>
  <ng-template #redFlags>
    <h3>{{genericActor?.name}}</h3>

    <div class="operation-info">
      <p>{{genericActor?.asOperationInfo?.description}}</p>
      <p *ngFor="let source of splitCSVList(genericActor?.asOperationInfo?.sources)">
        <a href="{{source}}" target="_blank">{{source}}</a>
      </p>
    </div>

    <div *ngIf="selectedCompanyInfo" class="company-info">
      <p>
        {{selectedActor.name}} has a/an {{genericEdge.description}} implication to {{genericActor.name}}
      </p>
      <!-- <p> To do: company link to operation</p> -->
    </div>

    <div *ngIf="selectedIndividualInfo" class="individual-info">
      <p>
        {{selectedActor.name}} has been sentenced for {{selectedIndividualInfo?.sentence}}
        by the {{selectedIndividualInfo?.court}} on the {{selectedIndividualInfo?.convictionDate}}
        for {{selectedIndividualInfo?.crimeType}}
      </p>
      <p *ngFor="let source of splitCSVList(selectedIndividualInfo?.dataSource)">
        <a href="{{source}}" target="_blank">{{source}}</a>
      </p>
    </div>
  </ng-template>
</section>