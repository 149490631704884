import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RecipientComponent } from './recipients.component';
import { RecipientListComponent } from './recipient-list/recipient-list.component';
import { AddRecipientDialogComponent } from './add-recipient-dialog/add-recipient-dialog.component';
import { ExistingRecipientDialogComponent } from './existing-recipient-dialog/existing-recipient-dialog.component';
import { ExistingRecipientListComponent } from './existing-recipient-list/existing-recipient-list.component';
import { SendAssessmentRecipientDialogComponent } from './send-assessment-recipient-dialog/send-assessment-recipient-dialog.component';
import { ViewRecipientAnswersComponent } from './view-recipient-answers/view-recipient-answers.component';
import { ResultsComponent } from './results/results.component';
import { BaseChartDirective } from 'ng2-charts';
import { ViewRecipientReportsComponent } from './view-recipient-reports/view-recipient-reports.component';
import { ChartsComponent } from '../charts/charts.component';
import { RisksTableComponent } from '../risks-table/risks-table.component';
import { AppendixComponent } from '../appendix/appendix.component';
import { ReportStatusBadgeComponent } from './view-recipient-reports/report-status-badge/report-status-badge.component';
import { ResultStatusBadgeComponent } from '../result-status-badge/result-status-badge.component';
import { AssessmentTooltipComponent } from '../assessment-tooltip/assessment-tooltip.component';

@NgModule({
  declarations: [ 
    RecipientListComponent, 
    RecipientComponent, 
    AddRecipientDialogComponent, 
    ExistingRecipientDialogComponent, 
    ExistingRecipientListComponent, 
    SendAssessmentRecipientDialogComponent, 
    ViewRecipientAnswersComponent,
    ResultsComponent,
    ChartsComponent,
    RisksTableComponent,
    ViewRecipientReportsComponent,
    AppendixComponent,
    ReportStatusBadgeComponent,
    ResultStatusBadgeComponent,
    AssessmentTooltipComponent,
  ],
  imports: [ 
    NgbModule, 
    CommonModule, 
    NgxPaginationModule, 
    FormsModule,
    BaseChartDirective
  ],
  exports: [ 
    RecipientComponent, 
    AddRecipientDialogComponent, 
    ExistingRecipientDialogComponent, 
    SendAssessmentRecipientDialogComponent,
    ViewRecipientAnswersComponent,
    ResultsComponent,
    ChartsComponent,
    RisksTableComponent,
    ViewRecipientReportsComponent,
    AppendixComponent,
    ResultStatusBadgeComponent,
    AssessmentTooltipComponent
  ],
})
export class RecipientsModule { }
