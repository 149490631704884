import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-regulatory-reports-base',
  templateUrl: './regulatory-reports-base.component.html',
  styleUrls: ['./regulatory-reports-base.component.scss', 
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class RegulatoryReportsBaseComponent {

}
