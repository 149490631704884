import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HtmlEncodeService {

constructor() { }

encodeXml = function (value: string) : string {
    return value.replace(/[<>&'"]/g, function (c) {
      switch (c) {
          case '<': return '&lt;';
          case '>': return '&gt;';
          case '&': return '&amp;';
          case '\'': return '&apos;';
          case '"': return '&quot;';
      }
    });
  };
  decodeXml = function (value: string) : string {
    return value.replace(/(&apos;)|(&quot;)|(&gt;)|(&lt;)|(&amp;)/g, function (c) {
      switch (c) {
          case '&lt;' : return '<';
          case '&gt;' : return '>';
          case '&amp;' : return '&';
          case '&apos;' : return '\'';
          case '&quot;' : return '"';
      }
    });
  };
}
