<!-- <div id="main-container">
    <div id="bas-widget-container" style="min-height: 350px;"></div>
    <div *ngIf="checksSubmitted" class="redirect-container">
        <span class="redirect-link">If you are not directed to the dashboard in a few seconds, please <a [routerLink]="['/dashboard']">click here</a></span>
    </div>
</div> -->

<div id="home-logo" class="p-0 my-0 service-image service-logo"></div>

<div class="idv-content">
    <h1>Identity Verification</h1>
        <p>
            You are about to start an Identity Document Verification. 
            You will be asked to present your identity document (passport or driving licence). 
            This will be followed by a biometric liveness check. 
            The process will autostart in just a moment. If not then click the start button below to begin the verification.
        </p>
    <div>
        <button class="btn m-1 btn-primary text-light" type="button" [disabled]="isStarted" id="idv-btn-new" (click)="start()">Start</button>
        <button class="btn m-1 btn-secondary" id="idv-btn-screen" (click)="close()">Cancel</button>
    </div>
</div>

