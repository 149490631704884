import { Component } from '@angular/core';
import { PrintService } from '../services/print.service';

@Component({
  selector: 'app-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss']
})
export class PreviewPageComponent {

  constructor(private printService: PrintService) { }

  getInnerHtml() : string {
    return this.printService.htmlBodyContent;
  }

}
