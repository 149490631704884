<div class="grid">
    <div class="col">
        <h4>Sign and complete to finalize the report</h4>
        <app-signature-pad [inputName]="usersFullName" (onSignature)="onSignatureChange($event)" (onClear)="onClearSignature()"></app-signature-pad>
    </div>
</div>
<div class="grid mt-2">
    <div class="col">
        <p-message severity="warn" class="w-10" text="Please note: Once completed, the report will no longer be editable."></p-message>
    </div>
</div>
<div class="grid mt-2">
    <div class="col flex-container-end">
        <p-button severity="danger" [outlined]="true" class="mx-1" (click)="onClose()" label="Close"/>
        <p-button [disabled]="!signatureUrl" class="mx-1" (click)="onComplete()" label="Complete"/>
    </div>
</div>
