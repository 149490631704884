import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { PremiumSearchUtilityService } from 'src/app/utilitities/premium-search-utilities';
import { DiligenciaRelationshipType, EntityTypes } from 'src/nswag';
import { ActorEdge, IActorBase } from '../../models/Actor';

@Component({
  selector: 'app-actorgraphrelationships',
  templateUrl: './actorgraphrelationships.component.html',
  styleUrls: ['actorgraphrelationships.component.scss']
})
export class ActorGraphRelationshipsComponent implements OnInit {

  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public selectedActor : IActorBase;
  public connectedActorEdges: ActorEdge[];
  public levelTwoConnectedActorEdges: ActorEdge[] = [];
  public tempIterate: ActorEdge[] = [];
  public hasDirectStandardParties: boolean = false;
  public hasIndirectStandardParties: boolean = false;


  constructor(public dashService: DashboardService, public premiumUtils: PremiumSearchUtilityService) {}

  ngOnInit(): void {
    this.selectedActor = this.dashService.getInvestigation();
    this.connectedActorEdges = this.dashService.getConnectedActorEdges(this.selectedActor);
    this.findIndirectRelationships();
    this.hasDirectStandardParties = this.edgesConnectedToStandardActors(this.connectedActorEdges);
    this.hasIndirectStandardParties = this.edgesConnectedToStandardActors(this.levelTwoConnectedActorEdges);
  }

  private edgesConnectedToStandardActors(edges: ActorEdge[]): boolean {
    return edges.some(e => !this.premiumUtils.isPremiumNode(e.sourceActor) || !this.premiumUtils.isPremiumNode(e.targetActor));
  }

  getActorName(actorEdge: ActorEdge): string {
    var actor: IActorBase = this.getOtherActorFromEdge(actorEdge);
    if (actor.actorType == EntityTypes.Officer || actor.actorType == EntityTypes.OfficerCompany) {
      var officer = actor.asOfficerInfo;
      let text = officer.name;
      if (officer.companyName)
        text += ` (${officer.companyName})`;
      return text;
    }
    else if (actor.actorType == EntityTypes.Individual) {
      return actor.name;
    } else if (actor.actorType == EntityTypes.Company) {
      return actor.name;
    }
    return actor?.name;
  }

  isPremiumReport(): boolean {
    return this.selectedActor.actorType == EntityTypes.DiligenciaOrganisation || this.selectedActor.actorType == EntityTypes.DiligenciaPerson;
  }

  getActorRelationship(actorEdge: ActorEdge): string {
    return actorEdge.description;
  }

  public splitEdgeDescriptionByPipe(edgeDesc: string): string[] {
    return edgeDesc.split('|');
  }

  getActorStatus(actorEdge: ActorEdge): string {
    if (actorEdge.isFormer) {
      return (actorEdge.isFormer ? "Former" : "Current");
    }
    var actor: IActorBase = this.getOtherActorFromEdge(actorEdge);
    switch (actor.actorType) {
      case EntityTypes.Company:
        return (actor.asCompanyInfo?.isActive ? "Active" : "Inactive");
      case EntityTypes.Officer:
      case EntityTypes.OfficerCompany:
        return (actor.asOfficerInfo?.isActive ? "Active" : "Inactive");
      case EntityTypes.UBO:
        return "Active";
      case EntityTypes.DiligenciaOrganisation:
        return this.premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.statusDto) ?? "n/a";
      case EntityTypes.DiligenciaPerson:
        // not applicable for premium individuals
        return "n/a";
    }
    return "n/a";
  }

  getActorStartDate(actorEdge: ActorEdge): Date {
    var actor: IActorBase = this.getOtherActorFromEdge(actorEdge);
    switch (actor.actorType) {
      case EntityTypes.Officer:
      case EntityTypes.OfficerCompany:
        return actor.asOfficerInfo?.startDate;
    }
    return null;
  }
  getActorEndDate(actorEdge: ActorEdge): Date {
    var actor: IActorBase = this.getOtherActorFromEdge(actorEdge);
    switch (actor.actorType) {
      case EntityTypes.Officer:
      case EntityTypes.OfficerCompany:
        return actor.asOfficerInfo?.endDate;
    }
    return null;
  }

  getActorLocation(actorEdge: ActorEdge) : string {
    var actor: IActorBase = this.getOtherActorFromEdge(actorEdge);
    if (actor.actorType == EntityTypes.Officer || actor.actorType == EntityTypes.OfficerCompany) {
      var officer = actor.asOfficerInfo;
      return officer?.address?.fullAddress;
    }
    else if (actor.actorType == EntityTypes.Individual) {
      return actor.asIndividualInfo?.nationality;
    }
    else if (actor.actorType == EntityTypes.Company) {
      return actor.asCompanyInfo?.registeredAddress?.fullAddress;
    } else if (actor.actorType == EntityTypes.DiligenciaOrganisation) {
      var org = actor.asDiligenciaOrgInfo;
      return this.premiumUtils.getPremiumOrgInfosLocation(org);
    } else if (actor.actorType == EntityTypes.DiligenciaPerson) {
      return this.premiumUtils.dtoToSelectedLanguage(actor?.asDiligenciaPersonInfo?.countryOfResidenceDto) ?? "";
    }
  }

  private getOtherActorFromEdge(actorEdge: ActorEdge): IActorBase {
    if (this.selectedActor.id != actorEdge.sourceActor.id && this.selectedActor.id == actorEdge.targetActor.id) {
      return actorEdge.sourceActor;
    }
    return actorEdge.targetActor;
  }

  findIndirectRelationships() {
    var self = this;
    this.dashService.cytoscapeObject.nodes().forEach(node => {
      var actor: IActorBase = node.data('actor');
      if (actor) {
        var edges = self.dashService.getConnectedActorEdges(actor);
        self.levelTwoConnectedActorEdges.push(...edges);
      }      
    });
    // remove duplicate relationships
    this.levelTwoConnectedActorEdges = this.levelTwoConnectedActorEdges.filter((el, i, a) => i === a.indexOf(el));
    // filter all lvl 1 relationships out
    this.levelTwoConnectedActorEdges = this.levelTwoConnectedActorEdges.filter(item => !this.connectedActorEdges.includes(item));
  }

  resizeAllTextAreas() {
    this.textareas.toArray().forEach((item) => { 
      item.nativeElement.style.height = 'auto';
      item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
    }); 
  }

  isPremiumRelationshipCategory(relationship: string) : boolean {
    return Object.values(DiligenciaRelationshipType).includes(relationship as DiligenciaRelationshipType);
  }
}

