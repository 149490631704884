<div class="grid">
    <div class="col m-3">
        <div *ngFor="let reportType of reportTypesMap" class="field-checkbox d-flex">
            <p-radioButton 
                [inputId]="reportType.id" 
                [value]="reportType" 
                [(ngModel)]="selectedReportType"
                class="radio"/>
            <label [for]="reportType.id" class="ml-3">
                <h4 class="mt-0 mb-2">{{ reportType.name }}</h4>
                <p class="mb-0 mt-2 text-muted">{{ reportType.description }}</p>
            </label>
        </div>
    </div>
</div>
<div class="grid">
    <div class="col flex-container-end">
        <p-button class="mx-1" severity="danger" [outlined]="true" (click)="closeDialog()" label="Cancel"/>
        <p-button [disabled]="!selectedReportType" class="mx-1" (click)="launch()" label="Create Report"/>
    </div>
</div>