import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { Recipient } from 'src/nswag';
import { RiskAssessmentService } from '../../services/risk-assessment.service';

@Component({
  selector: 'add-recipient-dialog',
  templateUrl: './add-recipient-dialog.component.html',
  styleUrls: ['./add-recipient-dialog.component.scss']
})
export class AddRecipientDialogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal, public riskAssessmentService: RiskAssessmentService, public alertService: AlertService) { }

  public recipient = new Recipient();
  public existingAssessmentRecipient = new Recipient();
  public isEdit = false;
  public allRecipients: Recipient[] = [];
  @Input() ButtonText: string;
  @Input() TitleText: string;

  ngOnInit(): void {
    if (Object.keys(this.existingAssessmentRecipient).length !== 0) {
      this.recipient = Object.assign({}, this.existingAssessmentRecipient);
    }
    // load all recipients 
    this.riskAssessmentService.loadAllRecipients().subscribe((res) => {
      this.allRecipients = res.data.results;
    });
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  canAdd(): boolean {
    var canAdd = false;
    if (this.isEdit) {
      canAdd = ((this.recipient?.companyName && this.recipient?.contactEmail && this.recipient?.contactName && this.isExistingRecipient())
        && (this.existingAssessmentRecipient?.companyName.trim() !== this.recipient?.companyName.trim() 
          || this.existingAssessmentRecipient?.contactName.trim() !== this.recipient?.contactName.trim()))
    }
    else {
      canAdd = (!this.recipient?.companyName || !this.recipient?.contactEmail|| !this.recipient?.contactName || this.isExistingRecipient()) ? false : true;
    }
    return canAdd;
  }

  isExistingRecipient(): boolean{
    return  this.allRecipients.some(x => x.contactEmail.toLocaleLowerCase() === this.recipient.contactEmail.toLocaleLowerCase());
  }
  addRecipient() {
    this.recipient.industryVerticalsId = this.riskAssessmentService.CurrentAssessment.industryVerticalsId;
    this.recipient.industryVerticalsOther = this.riskAssessmentService.CurrentAssessment.industryVerticalsOther;

    if (this.isEdit){
      this.riskAssessmentService.editAssessmentRecipient(this.recipient).subscribe(result => {
        if (result.isSuccess) {
          this.riskAssessmentService.loadRecipients(this.riskAssessmentService.CurrentAssessment.id);
          const recipient = this.riskAssessmentService.RecipientList.find(x => x.selectedRecipientId === result.data.selectedRecipientId);
          this.riskAssessmentService.refreshCurrentRecipient(recipient);
          this.dismissModal();
        }
      },
      (error) => {
        this.alertService.error("Unfortunately, something has gone wrong. Please try again or contact support.");
        console.error(error);
      });
    }
    else{
      this.riskAssessmentService.addRecipient(this.recipient).subscribe(result => {
        if (result.isSuccess) {
          this.riskAssessmentService.loadRecipients(this.riskAssessmentService.CurrentAssessment.id);
          this.dismissModal();
        }
      },
      (error) => {
        this.alertService.error("Unfortunately, something has gone wrong. Please try again or contact support.");
        console.error(error);
      });
    }
  }
}
