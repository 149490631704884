import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MonitorService } from 'src/app/monitor/services/monitor.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { AlertService } from 'src/app/_alert';
import { DataProviderSettingsModel, DataSourceProviderId, DateOfBirthMatching, GetWorklistDetailsWorklist, TenantManagementClient, UpdateAcurisDataProviderSettingsCommand, UpdateMonitorSettingsCommand, UpdateMonitorSettingsCommandDataProviderSettings } from 'src/nswag';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'app-self-manage-features',
  templateUrl: './self-manage-features.component.html',
  styleUrls: ['./self-manage-features.component.scss']
})
export class SelfManageFeaturesComponent implements OnInit {
  tenantId: string;
  dobSensitivityOptions: DateOfBirthMatching[] = [];
  selectedDobOption;
  worklistSettings: GetWorklistDetailsWorklist;
  dataProviderSettings: DataProviderSettingsModel;
  searchFeature: boolean = false;
  idvFeature: boolean = false;
  monitorFeature: boolean = false;
  screenFeature: boolean = false;
  configurableThreshold: number;
  adminMonitoringEmailsOnly: boolean = false;
  allowSubThreshold: boolean = false;
  alertServiceOptions = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  constructor(private tenantClient: TenantManagementClient, private monitorService: MonitorService, private alertService: AlertService, private route: ActivatedRoute,
    private dataProviderService: DataProviderService, private confirmationService: ConfirmationDialogService) { }

  ngOnInit(): void {
    // TODO: DOES this need to be here?
    this.route.queryParams
      .subscribe(params => {
        this.tenantId = params.tenantId;
      }
      );
    this.getUsersTenantDetails();
  }

  private populateMonitorEnumList() {
    this.dobSensitivityOptions = Object.values(DateOfBirthMatching).filter((item) => {
      return isNaN(Number(item));
    });
  }

  public thresholdIsValid() {
    return this.configurableThreshold && this.configurableThreshold >= 65 && this.configurableThreshold <= 100;
  }

  public updateSettings() {
    let message = UtilityService.getUpdateSettingsMessageText(this.monitorFeature, this.searchFeature, this.screenFeature);
    //open model with warning
    this.confirmationService.confirm("Update Settings?", `Are you sure? ${message}`, false, "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          if (this.monitorFeature) {
            var command = new UpdateMonitorSettingsCommand();
            var dataProviderSettings = new UpdateMonitorSettingsCommandDataProviderSettings();
            if (this.dataProviderSettings?.dobSensitivity != this?.selectedDobOption
              || this.dataProviderSettings?.threshold != this?.configurableThreshold
              || this.worklistSettings?.adminMonitoringEmailsOnly != this.adminMonitoringEmailsOnly) {
              dataProviderSettings.dobSensitivity = this?.selectedDobOption;
              dataProviderSettings.dataProviderThreshold = this?.configurableThreshold;
              command.tenantId = this.tenantId;
              command.providerSettings = dataProviderSettings;
              command.adminMonitoringEmailsOnly = this.adminMonitoringEmailsOnly;
              this.monitorService.updateMonitorSettings(command).subscribe(res => {
                this.alertService.success('Monitor Settings Updated', this.alertServiceOptions);
              });
            }
          }
          if (this.searchFeature || this.screenFeature || this.monitorFeature) {
            var dpCommand = new UpdateAcurisDataProviderSettingsCommand();
            if (this.dataProviderSettings?.threshold != this.configurableThreshold
              || this.dataProviderSettings?.dobSensitivity != this.selectedDobOption
              || this.dataProviderSettings?.allowSubThreshold != this.allowSubThreshold) {
              dpCommand.tenantId = this.tenantId;
              dpCommand.dataProviderThreshold = this.configurableThreshold;
              dpCommand.dobSensitivity = this.selectedDobOption;
              dpCommand.allowSubThreshold = this.allowSubThreshold;
              this.dataProviderService.updateDataProviderSettings(dpCommand).subscribe(res => {
                this.alertService.success('Data Provider Settings Updated', this.alertServiceOptions);
              });
            }
          }
        }
      })
      .catch(() => { });
  }

  public fieldsAreDirty() {
    var dirty = false;
    if (this.screenFeature || this.searchFeature || this.monitorFeature) {
      dirty = this.dataProviderSettings?.dobSensitivity != this.selectedDobOption
        || this.dataProviderSettings?.threshold != this.configurableThreshold
        || this.dataProviderSettings?.allowSubThreshold != this.allowSubThreshold
        || this.worklistSettings?.adminMonitoringEmailsOnly != this.adminMonitoringEmailsOnly
    }
    return dirty;
  }

  public configSettingsAreValid(): boolean {
    var isValid = true;
    if (this.screenFeature || this.searchFeature) {
      isValid = this.thresholdIsValid();
    }
    if (this.monitorFeature == true) {
      isValid = this.thresholdIsValid() && this.selectedDobOption;
    }
    return isValid;
  }
  public changeSelectedDropdownItem(selectedItem: string) {
    this.selectedDobOption = selectedItem;
  }

  getUsersTenantDetails() {
    this.tenantClient.getTenantDetails(this.tenantId).subscribe(res => {
      if (res.isSuccess) {
        this.searchFeature = res.data.features?.findIndex(v => v == "Search") > -1;
        this.idvFeature = res.data.features?.findIndex(v => v == "IDV") > -1;
        this.monitorFeature = res.data.features?.findIndex(v => v == "Monitor") > -1;
        this.screenFeature = res.data.features?.findIndex(v => v == "Screen") > -1;

        if (this.searchFeature || this.screenFeature || this.monitorFeature) {
          this.getDataProviderSettings();
          this.populateMonitorEnumList();
        }
        if (this.monitorFeature) {
          this.getMonitorSettings();
        }
      }
    });
  }

  getDataProviderSettings() {
    if (this.searchFeature || this.screenFeature || this.monitorFeature) {
      this.dataProviderService.getTenantDataProviderDetails(this.tenantId).subscribe(res => {
        if (res.isSuccess) {
          var providers = res?.data?.providers;
          var acurisProvider = providers.find(x => x.name == DataSourceProviderId.Acuris);
          this.configurableThreshold = acurisProvider.threshold;
          this.allowSubThreshold = acurisProvider.allowSubThreshold;
          this.changeSelectedDropdownItem(acurisProvider?.dobSensitivity);
          this.dataProviderSettings = acurisProvider;
        }
      });
    }
  }

  getMonitorSettings() {
    if (this.monitorFeature) {
      this.monitorService.getWorklistDetails(this.tenantId).subscribe(res => {
        if (res.isSuccess) {
          this.worklistSettings = res?.data;
          this.adminMonitoringEmailsOnly = this.worklistSettings?.adminMonitoringEmailsOnly;
        }
      });
    }
  }
}
