import { Component, OnInit } from '@angular/core';
import { BaseReportSectionComponent } from '../base-client-section.component';
import { CountriesService } from 'src/app/utils/countries.service';

@Component({
  selector: 'mon-individual-section',
  templateUrl: './individual-section.component.html',
  styleUrls: ['./individual-section.component.scss']
})
export class IndividualSectionComponent extends BaseReportSectionComponent {
  getNationalityName(code: string) {
    return CountriesService.getNationalityName(code);
  }
}
