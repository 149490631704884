import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Evidence, InsEntry } from 'src/nswag';

@Component({
  selector: 'mon-insolvency',
  templateUrl: './insolvency.component.html',
  styleUrls: ['./insolvency.component.scss']
})
export class InsolvencyComponent implements OnChanges {
  
  // Assumption that it is a business or individual profile
  @Input() profile: any;
  @Input() clientId: string;
  @Input() name: string;
  @Input() incHeader = true;
  @Input() allowDiscounting = true;
  @Input() reportMode: boolean = false;
  
  public insEntries: InsEntry[];
  public evidences: Evidence[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.evidences = this.profile?.evidences;
    this.insEntries = this.profile?.insEntries;
  }

  public checkExists(val: string, ignoreList: string[] = null) {    
    return val != null && !ignoreList?.find( v => v == val) && val.trim().length > 0;
  }
}
  