import { Directive, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MonitorService } from 'src/app/monitor/services/monitor.service';
import { ProfileService } from 'src/app/profile.service';
import { ScreeningService } from 'src/app/screening/services/screening.service';
import { UtilityService } from 'src/app/utilitities/utilities';
import { AlertService } from 'src/app/_alert';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';
import { Address2, Client, ClientResult } from 'src/nswag';
import { MatchReportService } from '../match-report/match-report.service';
import { SelectMatchReportComponent } from '../match-report/select-match-report/select-match-report.component';
import { MatchesService } from '../matches.service';

@Directive()
export class BaseMatchesComponent {
  public resultCount: number = -1;
  public showMoreFlag: boolean[];
  public showMoreMatches: boolean = false;
  public selectedClient: ClientResult;

  @Input() searchName: string;
  @Input() searchFirstName: string;
  @Input() searchLastName: string;
  @Input() searchOtherName: string;
  @Input() client : Client;

  constructor(protected monitorService: MonitorService, protected confirmationService: ConfirmationDialogService, protected alertService: AlertService, private matchesService: MatchesService, protected matchReportService: MatchReportService, protected modalService: NgbModal, protected screenService: ScreeningService, protected profileService: ProfileService) { }

  get isBusy() : boolean {
    return this.matchesService.isBusy;
  } 

  toggleShowMore(): void {
    this.showMoreMatches = true;
  }

  getCountry(code: string): string {
    return UtilityService.getCountryFromCode(code);
  }

  getAddresses(addresses: Address2[]) : string[] {
    let sadds: string[] = [];
    for (let address of addresses) {
      sadds.push( UtilityService.getAddress(address));
    }
    return sadds;
  }

  public openMatches(matches: any, isBusiness: boolean) {
    const modal = this.modalService.open(SelectMatchReportComponent, { scrollable: true, size: 'lg'});
    if(isBusiness) {
      modal.componentInstance.businessMatches = matches
    } 
    else {
      modal.componentInstance.individualMatches = matches; 
    }
    modal.componentInstance.isBusiness = isBusiness;
  }
}
