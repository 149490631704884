import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbDateParserFormatter, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as cytoscape from 'cytoscape';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActorIdentifiers, ApiResponseOfBusinessProfile, ApiResponseOfIndividualProfile, BusinessProfile, ClientResult, EntityTypes, FinCrimeCheckClient, GetIdentifiersForNodesQuery, IdvUserProfile, IndividualProfile, ISubjectInfo, MediaClient, MonitoringClient, RemoveActorResultCommand, ScreeningClient, UserGraph } from '../../nswag';
import { ActorBase, ActorEdge, edgeType, GroupActor, IActorBase } from '../dashboard/models/Actor';
import { SubjectEditAuditLog } from '../dashboard/models/Models';
import { SubjectEditorComponent } from '../dashboard/subject-editor/subject-editor.component';
import { ProfileService } from '../profile.service';
import { UserSatisfactionComponent } from '../surveys/user-satisfaction/user-satisfaction.component';
import { ConfirmationDialogService } from '../_confirmation-dialog/ConfirmationDialog.service';
import { AuditService } from './audit.service';

export const pepLevelTitles = [
  "na",
  "PEP Level 1 (High Priority)",
  "PEP Level 2 (Medium Priority)",
  "PEP Level 3 (Low Priority)",
  "PEP Level 4 (Close Personal and Business Associates)",
  "PEP by Assocation"
];
export const pepLevelDescriptions = [
  "na",
  "Eg. Heads of state (including royal families) and government; cabinet members (including, European Commission) and parliaments (including European Parliament): Members of legislative assemblies and governments at state level in case of federal jurisdictions; Heads and senior member Judiciary (including European Court of Justice), Central Banks (including European Central Bank)., Military, Law Enforcement, uditors (including EU Court of Auditors): Top Ranking Political Party Officials.",
  "Eg. Members of legislative and executive bodies at regional, provincial, cantonal or equivalent levels (below the level of states in case of federal jurisdiction): Judges, justices, magistrates, prosecutors, attorneys in courts with jurisdiction at regional, provincial or equivalent level; Senior diplomats; Senior board members of State-Owned Enterprises (SOEs); Senior officials of International Organisations (1Os); mayors of capital and global cities; Heads and senior members of mainstream, religious groups;",
  "Eg. Heads and senior members of International NGOs; Heads, board members and senior officials of Trade-Unions; Advisers, heads of cabinet and similar roles of senior officials of the military, judiciary, law enforcement, central banks and other state agencies, authorities and state bodies; Senior civil servants at regional and provincial level; Middle ranking diplomats; Mayors and members of legislative and executive bodies at local level.",
  "Eg. Family members and close personal and business associates of PEPs defined as known sexual or non- sexual partners outside the family unit, business partners or associates, especially those that share (beneficial) ownership of legal entities with the PEP, or who are otherwise connected (e.g., through joint membership of a company board). In the case of personal relationships, the social, economic and cultural context may also play a role in determining how close those relationships generally are.",
  "A PEP by association is a person who is politically exposed due to their association to another high level politically exposed person.",
];

export class GraphData {
  // Update this to a new version if new data is to be stored
  version = 2;
  cytoElements: any = [];
  excludeInactive: boolean = false;
  showLabels: boolean = false;
  layout: string = "cola";

  // List of inactive node ids that have been removed
  inactiveNodeList: string[] = Array<string>();
  // List of removed node ids
  removedNodeList: string[] = Array<string>();
  // List of collapsed nodes
  collapsedNodeList: string[][] = Array<string[]>();
  // List of permanently hidden node ids (eg as a result of auto-merges)
  hiddenNodeList: string[] = Array<string>();

  constructor(graphJson?: string) {
    if (graphJson) {
      let savedData = JSON.parse(graphJson);

      this.excludeInactive = savedData?.excludeInactive ?? false;
      this.showLabels = savedData?.showLabels ?? false;
      this.cytoElements = savedData?.cytoElements?.elements ?? savedData?.elements;
      this.inactiveNodeList = savedData?.inactiveNodeList ?? Array<string>();
      this.removedNodeList = savedData?.removedNodeList ?? Array<string>();
      this.collapsedNodeList = savedData?.collapsedNodeList ?? Array<string[]>();
      this.hiddenNodeList = savedData?.hiddenNodeList ?? Array<string>();
      this.showLabels = savedData?.showLabels ?? false;
      this.layout = savedData?.layout ?? "cola";
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private formBuilder: UntypedFormBuilder, private auditService: AuditService, private modalService: NgbModal, public dateFormatter: NgbDateParserFormatter, private finCrimeChecksClient: FinCrimeCheckClient, private monitorClient: MonitoringClient, private screeningClient: ScreeningClient, private confirmationDialogService: ConfirmationDialogService, private profileService: ProfileService, private mediaClient: MediaClient) {
    this.refreshIcons = new BehaviorSubject<boolean>(false);
    this.auditService.clear();
  }

  private _hasChanged: boolean = false;

  // These are LAMPS associated with this invesitgated Actor
  public selectedLAMPS: any;
  public subjectEditHistory: SubjectEditAuditLog[] = [];

  //Graph fullscreen
  public isFullscreen = false;

  // Monitor integrationy
  public selectedClient: ClientResult;
  public selectedIndividualProfile: IndividualProfile;
  public selectedBusinessProfile: BusinessProfile;

  // List of cached profiles
  private cachedIndividualProfiles: Map<string, IndividualProfile> = new Map<string, IndividualProfile>();
  private cachedBusinessProfiles: Map<string, BusinessProfile> = new Map<string, BusinessProfile>();

  public getCachedProfileList(): string[] {
    let response: string[] = [];
    this.cachedBusinessProfiles?.forEach(v => response.push(v?.resourceId));
    this.cachedIndividualProfiles?.forEach(v => response.push(v?.resourceId));
    return response;
  }

  public graphLayout: any = {
    name: "cola",
    minNodeSpacing: 100,
    fit: true,
    animate: true,
    avoidoverlap: true
  };

  public addActorIdentifiers(profile: any) {
    let actorId = this.getInvestigation().id;
    this.finCrimeChecksClient.getIdentifiersForSingleNode(actorId).subscribe(result => {
      if (result.isSuccess) {
        this.investigatedActor.clientId = result.data.clientId;
        this.updateInvestigation(profile);
        this.addAuditTrail("Financial Crime check performed for " + this.getInvestigation()?.name + ". " + this.confirmationDialogService?.reason ?? "");
      }
    });
  }

  public bulkLAMPSToCheck: string[] = [];

  public bulkLAMPSGet() {
    let recordsRetrieved: ActorIdentifiers[] = [];

    if (this.bulkLAMPSToCheck.length > 0) {
      let query = new GetIdentifiersForNodesQuery();
      query.nodeIds = this.bulkLAMPSToCheck;

      this.finCrimeChecksClient.getIdentifiersForNodes(query).subscribe(results => {
        recordsRetrieved.push(...results.data);

        for (let actorid of this.bulkLAMPSToCheck) {
          let actor = this.getActorByNodeId(actorid);
          if (!actor) {
            continue;
          }
          let foundrecord = recordsRetrieved.find((r) => r.actorId == actorid);
          if (foundrecord) {
            actor.hasLAMPS = true;
            if (actor?.profileId && actor?.colour == '#20706B') {
              actor.colour = '#DC3545';
            }
            actor.profileId = foundrecord.profileId;
            actor.clientId = foundrecord.clientId;
          }

          this.setActorStyle(actor);
        }
        this.bulkLAMPSToCheck = [];
      });
    }
  }

  public singleLAMPSGet(nodeId: string) {
    if (nodeId) {
      this.finCrimeChecksClient.getIdentifiersForSingleNode(nodeId).subscribe(results => {
        let actor = this.getActorByNodeId(nodeId);
        if (!actor || !results?.data?.actorId) {
          return;
        }
        else {
          actor.hasLAMPS = true;
          if (actor?.profileId && actor?.colour == '#20706B') {
            actor.colour = '#DC3545';
          }
          actor.profileId = results.data?.profileId;
          actor.clientId = results.data?.clientId;
          this.setActorStyle(actor);
        }
        // push new data to the investigated (landing node) actor on screen
        if (this.investigatedActor && this.investigatedActor.id == results?.data?.actorId) {
          var profilesDiscounted: boolean = results?.data?.actorId && !results?.data?.profileId;
          this.loadSelectedProfile(actor, profilesDiscounted);
          this.loadSelectedClient(actor, true);
        }
      });
    }
  }

  public showSubjectEditorModal(): Promise<boolean> {
    var modalOptions: NgbModalOptions = {};
    modalOptions.backdrop = 'static';
    modalOptions.keyboard = false;
    modalOptions.scrollable = true;
    const modalRef = this.modalService.open(SubjectEditorComponent, modalOptions);
    return modalRef.result;
  }

  //user satsifaction modal service
  public showUserSatisfactionModal(): Promise<boolean> {
    let modalOptions: NgbModalOptions = {};
    modalOptions.backdrop = 'static';
    modalOptions.keyboard = false;
    //modalOptions.scrollable = false;
    const modalRef = this.modalService.open(UserSatisfactionComponent, modalOptions);
    return modalRef.result;
  }

  // used to reset the 'new' icons on the dashboard tabs
  public refreshIcons: BehaviorSubject<boolean>;

  public onRefreshIcons(): Observable<boolean> {
    return this.refreshIcons.asObservable();
  }

  // Observable to save investigation
  public saveInvestigationObs$ = new Subject();
  // Broadcast the save action
  public saveInvestigation() {
    this.saveInvestigationObs$.next(true);
  }

  // Name of savedInvestigation
  public investigationName: string;

  public cytoscapeObject: cytoscape.Core;

  // Manages discounting of nodes
  public removedCollection: cytoscape.CollectionReturnValue[] = Array<cytoscape.CollectionReturnValue>();
  public inactiveCollection: cytoscape.CollectionReturnValue[] = Array<cytoscape.CollectionReturnValue>();
  public collapsedMap: Map<string, cytoscape.CollectionReturnValue[]> = new Map<string, cytoscape.CollectionReturnValue[]>();
  // Nodes that are always hidden (eg auto-merged nodes)
  public hiddenNodesList = Array<string>();
  public excludeInactive: boolean = false;
  public showLabels: boolean = false;

  public setNodeStyle(node) {
    let actor = node.data('actor');
    if (actor) {
      if (actor.actorType != EntityTypes.Group) {
        actor.getStyles((name, value) => {
          if (name != 'background-image') {
            node.style(name, value);
          }
          else if (actor.profileImageId) {
            node.style('background-fit', 'contain');
            node.style(name, '/media/' + actor.profileImageId);
          }
          else if (actor.profileId) {
            var sub = this.profileLoader(actor);
            if (sub) {
              if (sub.value) {
                if (sub.value.profileImages?.length > 0) {
                  if (name == 'background-image' &&
                    (value == ActorBase.companyImage 
                      || value == ActorBase.filledCompanyImage
                      || value == ActorBase.identityImage
                      || value == ActorBase.filledIdentityImage)) {
                    this.mediaClient.getImage(sub.value.profileImages[0]).subscribe(result => {
                      const image = URL.createObjectURL(result.data);
                      node.style('background-fit', 'contain');
                      node.style('background-image', image);
                    });
                  }

                }
                else {
                  node.style(name, value);
                }
              }
              else {
                sub.subscribe(r => {
                  if (r?.profileImages?.length > 0) {
                    if (name == 'background-image' && 
                    (value == ActorBase.companyImage 
                      || value == ActorBase.filledCompanyImage 
                      || value == ActorBase.identityImage 
                      || value == ActorBase.filledIdentityImage)) {
                      this.mediaClient.getImage(sub.value.profileImages[0]).subscribe(result => {
                        const image = URL.createObjectURL(result.data);
                        node.style('background-fit', 'contain');
                        node.style('background-image', image);
                      });
                    }
                  }
                  else {
                    node.style(name, value);
                  }
                });
              }
            }
            else {
              node.style(name, value);
            }
          }
          else {
            node.style(name, value);
          }
        });
      }
      if (actor.colour) {
        node.style('border-color', actor.colour);
      }
      if (actor.backColour) {
        node.style('background-color', actor.backColour);
      }
    }
  }

  public setActorStyle(actor: IActorBase) {
    var node = this.findNode(actor.id);
    if (node) {
      this.setNodeStyle(node);
    }

  }
  public setEdgeStyle(edge: any) {
    let actorEdge: ActorEdge = edge.data('actorEdge');
    if (!actorEdge) {
      edge.style(
        'line-style', 'dashed',
        'line-dash-pattern', [4, 2],
        'font-size', '.6em',
        'text-rotation', 'autorotate',
        'text-transform', 'UPPERCASE'
      );
      return;
    }
    edge.style('label', (this.showLabels ? 'data(description)' : ''));
    if (actorEdge.type == edgeType.soft) {
      edge.style(
        'line-style', 'dashed',
        'line-dash-pattern', [4, 2],
        'font-size', '.6em',
        'text-rotation', 'autorotate',
        'text-transform', 'UPPERCASE'
      );
    }
    if (actorEdge.colour) {
      edge.style('line-color', actorEdge.colour);
    }
  }

  public getConnectedActorEdges(actor: IActorBase): ActorEdge[] {
    var connectedActorEdges: ActorEdge[] = new Array();
    if (this.cytoscapeObject) {
      var node: cytoscape.NodeSingular = this.findNode(actor.id);
      if (node) {
        node.connectedEdges().forEach(edge => {
          connectedActorEdges.push(edge.data("actorEdge"));
        });
      }
    }
    return connectedActorEdges;
  }

  public findNode(nodeId: string): cytoscape.NodeSingular {
    var nodes: cytoscape.NodeCollection = this.cytoscapeObject.nodes('#' + nodeId);
    return (nodes.length > 0 ? nodes[0] : null);
  }

  public findEdge(edgeId: string): cytoscape.EdgeSingular {
    var edges: cytoscape.EdgeCollection = this.cytoscapeObject.edges('#' + edgeId);
    return (edges.length > 0 ? edges[0] : null);
  }

  public mergeActor(sourceActor: IActorBase, targetActor: IActorBase, reason: string, sourceNode: cytoscape.NodeSingular = null) {
    targetActor.mergeActor(sourceActor, reason);
    this.setActorStyle(targetActor);
    this.addAuditTrail('Merged Actor ' + sourceActor.name + " with " + targetActor.name);
    sourceNode = sourceNode ?? this.findNode(sourceActor.id);
    if (sourceNode) {
      let removedNode = sourceNode.remove();
      this.removedCollection.push(removedNode);
    }
  }

  // This is the current actor under investigation
  public investigatedActor: IActorBase;

  // This is the saved Graph to use
  private selectedUserGraph: UserGraph;
  // This is the deserialised saved graph data
  private selectedGraphData: GraphData;
  // This is the saved IDV person to use
  private idvPerson: IdvUserProfile;
  // This is the subject selected off the search
  private selectedSubject: ISubjectInfo;
  // This is used of the search whem there as no results!
  private selectedDummyActor: IActorBase;

  // container for all the report formgroups - containing drop down selections and freetext (actor agnostic)
  // will need to save this object to the server eventually
  public reportForms: UntypedFormGroup = new UntypedFormGroup({});

  setInvestigation(actor: IActorBase) {
    // Delayed in order to overcome the error - NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    setTimeout(() => {
      this.investigatedActor = actor;
      this.loadActorForms(actor);
      this.loadSelectedProfile(actor);
      this.loadSelectedClient(actor);
    });
  }

  private loadSelectedClient(actor: IActorBase, force = false) {
    // We are going to Check if this is a client!
    if (actor?.clientId == null) {
      this.selectedClient = null;
      return;
    }
    if (!force && this.selectedClient?.client?.id == actor.clientId) {
      return;
    }
    if (this.profileService?.monitoringEnabled()) {
      this.monitorClient.getClientById(actor.clientId).subscribe(result => {
        if (result.isSuccess) {
          this.selectedClient = result.data;
        }
      });
      return;
    }
    if (this.profileService?.screeningEnabled()) {
      this.screeningClient.getClientById(actor.clientId).subscribe(result => {
        if (result.isSuccess) {
          this.selectedClient = result.data;
        }
      });
      return;
    }

    // This should not happen - but reset the client data if so.
    this.selectedClient = null;
    if (actor?.clientId == null) {
      actor.clientId = null;
    }
  }

  private loadSelectedProfile(actor: IActorBase, profilesDicounted: boolean = false) {
    var sub = this.profileLoader(actor);
    if (sub) {
      if (sub.value) {
        this.setSelectedProfile(sub.value);
      }
      else {
        sub.subscribe(r => {
          if (r) {
            this.setSelectedProfile(r);
          }
        });
      }
    }
    else {
      // actor has no financial crime check profile
      this.selectedIndividualProfile = null;
      this.selectedBusinessProfile = null;
      this.selectedLAMPS = null;
      if (profilesDicounted) {
        this.setSelectedProfile(null, profilesDicounted);
      }
    }
  }

  private setSelectedProfile(profile: any, profilesDiscounted: boolean = false) {
    this.updateInvestigation(profile, profilesDiscounted);
  }

  public profileLoader(actor: IActorBase): BehaviorSubject<any> {
    if (actor != null && actor.profileId != null) {
      if (actor.actorType == EntityTypes.Company || actor.actorType == EntityTypes.DiligenciaOrganisation) {
        let profile = this.cachedBusinessProfiles.get(actor.id);
        let subject = new BehaviorSubject<any>(profile);
        if (!profile) {
          this.getBusinessProfile(actor.profileId)?.subscribe(result => {
            if (result.isSuccess) {
              this.cachedBusinessProfiles.set(actor.id, result.data);
              subject.next(result.data);
            }
            else {
              subject.next(null);
            }
          });
        }
        return subject;
      }
      else {
        let profile = this.cachedIndividualProfiles.get(actor.id);
        let subject = new BehaviorSubject<any>(profile);
        if (!profile) {
          this.getIndividualProfile(actor.profileId)?.subscribe(result => {
            if (result.isSuccess) {
              this.cachedIndividualProfiles.set(actor.id, result.data);
              subject?.next(result.data);
            }
            else {
              subject?.next(null);
            }
          });
        }
        return subject;
      }
    }
    return null;
  }

  private getBusinessProfile(profileId: string): Observable<ApiResponseOfBusinessProfile> {
    if (this.profileService.monitoringEnabled()) {
      return this.monitorClient.getBusinessProfile(profileId);
    }
    else if (this.profileService.screeningEnabled()) {
      return this.screeningClient.getBusinessProfile(profileId);
    }
    return null;
  }

  private getIndividualProfile(profileId: string): Observable<ApiResponseOfIndividualProfile> {
    if (this.profileService.monitoringEnabled()) {
      return this.monitorClient.getIndividualProfile(profileId);
    }
    else if (this.profileService.screeningEnabled()) {
      return this.screeningClient.getIndividualProfile(profileId);
    }
    return null;
  }

  getInvestigation(): IActorBase {
    if (this.investigatedActor != null) {
      this.loadActorForms(this.investigatedActor);
    }
    return this.investigatedActor;
  }

  updateInvestigation(profile: any, profilesDiscounted: boolean = false) {
    if (profile || profilesDiscounted) {
      this.investigatedActor.hasLAMPS = true;
    }
    this.investigatedActor.profileId = profile?.resourceId;
    if (this.investigatedActor.hasLAMPS && this.investigatedActor?.colour == '#20706B') {
      this.investigatedActor.colour = '#DC3545';
    }
    this.setActorStyle(this.investigatedActor);
    if (profile instanceof BusinessProfile) {
      this.selectedLAMPS = this.selectedBusinessProfile = profile;
      this.selectedIndividualProfile = null;
    }
    else if (profile instanceof IndividualProfile) {
      this.selectedBusinessProfile = null;
      this.selectedLAMPS = this.selectedIndividualProfile = profile;
    }
    else {
      this.selectedLAMPS = null;
      this.selectedIndividualProfile = null;
      this.selectedBusinessProfile = null;
      this.investigatedActor.profileId = null;
    }

    this.hasChanged = true;
    this.loadSelectedClient(this.investigatedActor);
  }

  setSelectedUserGraph(graph: UserGraph, clearServiceDta: boolean = true) {
    if (clearServiceDta) {
      this.clearServiceData();
    }
    this.selectedUserGraph = graph;
    this.investigationName = graph?.name;

    // repopulate formgroup object with stored JSON
    var formData = graph?.formData as unknown as any;
    if (formData != null) {
      formData = JSON.parse(formData);
      this.reportForms = this.formBuilder.group(formData);
    }

    // Stored graph data
    this.selectedGraphData = new GraphData(graph?.graph);

    if (graph?.auditTrail) {
      this.auditService.loadEntriesFromXml(graph.auditTrail);
    }
  }
  getSelectedUserGraph(): UserGraph {
    return this.selectedUserGraph;
  }

  getSelectedGraphData(): GraphData {
    return this.selectedGraphData;
  }

  public removeLampsResult(): Observable<boolean> {
    let actor = this.getActorByNodeId(this.investigatedActor.id);
    let event = new Observable<boolean>((observer: any) => {
      if (actor?.hasLAMPS && !actor?.clientId) {
        this.confirmationDialogService.confirm('Clear Checks?', 'Are you sure that you want to remove these checks?', true, "Ok", "Cancel", "sm", true)
          .then((confirmed) => {
            if (confirmed == true) {
              let command = new RemoveActorResultCommand();
              command.actorId = actor?.id;
              this.finCrimeChecksClient.removeActorResult(command).subscribe(result => {
                if (actor.actorType == EntityTypes.Company) {
                  this.cachedBusinessProfiles.delete(actor.id);
                }
                else {
                  this.cachedIndividualProfiles.delete(actor.id);
                }
                actor.profileId = null;
                actor.reportActorFreeText = new UntypedFormGroup({});
                actor.hasLAMPS = false;
                if (actor.colour == '#DC3545') {
                  actor.colour = '#20706B';
                }
                this.selectedIndividualProfile = null;
                this.selectedBusinessProfile = null;

                this.setActorStyle(actor);
                this.addAuditTrail("Financial Crime check removed for " + actor?.name + ". " + this.confirmationDialogService?.reason);
                observer.next(true);
              });
            }
            else {
              observer.next(false);
            }
          })
          .catch((onRejected) => { /* modal closed */ });
      }
    });
    return event;
  }

  setIDVPerson(idvPerson: IdvUserProfile) {
    this.clearServiceData();
    this.idvPerson = idvPerson;
  }
  getIDVPerson() {
    return this.idvPerson;
  }

  setSelectedSubject(subject: ISubjectInfo) {
    this.clearServiceData();
    this.selectedSubject = subject;
  }
  getSelectedSubject(): ISubjectInfo {
    return this.selectedSubject;
  }

  setSelectedDummyActor(dummyActor: IActorBase, client: ClientResult = null, profile: any = null) {
    this.clearServiceData();
    this.selectedDummyActor = dummyActor;
    this.selectedClient = client;
    this.addProfileToCache(client, profile);
  }

  addProfileToCache(client: ClientResult = null, profile: any = null) {
    if (profile) {
      if (client?.client?.individual) {
        this.selectedIndividualProfile = profile;
        this.cachedIndividualProfiles.set(profile.id, profile);
      }
      else if (client?.client?.business) {
        this.selectedBusinessProfile = profile;
        this.cachedBusinessProfiles.set(profile.id, profile);
      }
    }
  }

  getSelectedDummyActor() {
    return this.selectedDummyActor;
  }

  clearServiceData() {
    this.auditService.clear();
    this.selectedGraphData = null;
    this.selectedUserGraph = null;
    this.idvPerson = null;
    this.selectedSubject = null;
    this.selectedDummyActor = null;
    this.investigatedActor = null;
    this.reportForms = new UntypedFormGroup({});
    this.subjectEditHistory = [];
    this.bulkLAMPSToCheck = [];

    this.selectedLAMPS = null;
    this.selectedClient = null;
    this.selectedIndividualProfile = null;
    this.selectedBusinessProfile = null;
    this.cachedIndividualProfiles = new Map<string, IndividualProfile>();
    this.cachedBusinessProfiles = new Map<string, IndividualProfile>();
  }

  public getActorByNodeId(nodeId: string): IActorBase {
    var node = this.findNode(nodeId);
    if (node != null) {
      var actor = node.data("actor") as IActorBase;
      if (actor != null) {
        this.loadActorForms(actor);
        return actor;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public loadActorForms(actor: IActorBase) {
    if (actor?.reportActorFreeText == null) {
      actor.reportActorFreeText = new UntypedFormGroup({});
    }
    // if data is a serialized JSON string
    if (typeof actor?.reportActorFreeText === 'string' || actor.reportActorFreeText instanceof String) {
      // deserialize to Formgroup.value
      actor.reportActorFreeText = JSON.parse(actor.reportActorFreeText as string);
      // reassign to FormGroup
      actor.reportActorFreeText = this.formBuilder.group(actor.reportActorFreeText);
    }

    // add controls to form if non-existent
    if (actor.reportActorFreeText?.controls['detailsHeading'] == null) {
      actor.reportActorFreeText?.addControl('detailsHeading', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['businessRelationship'] == null) {
      actor.reportActorFreeText?.addControl('businessRelationship', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['ragStatus'] == null) {
      actor.reportActorFreeText?.addControl('ragStatus', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['riskLevelComments'] == null) {
      actor.reportActorFreeText?.addControl('riskLevelComments', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['redflagsHeading'] == null) {
      actor.reportActorFreeText?.addControl('redflagsHeading', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['lampsSummary'] == null) {
      actor.reportActorFreeText?.addControl('lampsSummary', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['sanctionsSummary'] == null) {
      actor.reportActorFreeText?.addControl('sanctionsSummary', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['litigationSummary'] == null) {
      actor.reportActorFreeText?.addControl('litigationSummary', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['pepSummary'] == null) {
      actor.reportActorFreeText?.addControl('pepSummary', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['adverseMediaSummary'] == null) {
      actor.reportActorFreeText?.addControl('adverseMediaSummary', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['linkedPeopleSummary'] == null) {
      actor.reportActorFreeText?.addControl('linkedPeopleSummary', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['linkedBusinessesSummary'] == null) {
      actor.reportActorFreeText?.addControl('linkedBusinessesSummary', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['discountedArticlesHeading'] == null) {
      actor.reportActorFreeText?.addControl('discountedArticlesHeading', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['discountedAssociationsHeading'] == null) {
      actor.reportActorFreeText?.addControl('discountedAssociationsHeading', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['directlyAssociatedParties'] == null) {
      actor.reportActorFreeText?.addControl('directlyAssociatedParties', new UntypedFormControl(''));
    }
    if (actor.reportActorFreeText?.controls['indirectlyAssociatedParties'] == null) {
      actor.reportActorFreeText?.addControl('indirectlyAssociatedParties', new UntypedFormControl(''));
    }
  }

  get hasChanged(): boolean {
    return this._hasChanged;
  }
  set hasChanged(val: boolean) {
    this._hasChanged = val;
  }

  addAuditTrail(log: string) {
    this.hasChanged = true;
    this.auditService.add(log);
  }
  getEntriesAsHtml(): string {
    return this.auditService.getEntriesAsHtml();
  }
  getEntriesAsXml(): string {
    return this.auditService.getEntriesAsXml();
  }

  public addActorTograph(selectedActor: IActorBase, newActor: IActorBase, association: string) {
    this.addActorNode(newActor);
    this.addActorEdge(selectedActor, newActor, edgeType.hard, association);
    this.refreshLayout();
  }

  findRemovedNode(id: string): boolean {
    var result: Boolean = false;
    var val = this.removedCollection.find((v, i, o) => {
      v.forEach(e => {
        if (e.isNode() && e.id() == id) {
          result = true;
        }
      });
      return result;
    });
    return (val?.length > 0);
  }

  findExcludedNode(id: string): boolean {
    var result: Boolean = false;
    if (this.excludeInactive) {
      var val = this.inactiveCollection.find((v, i, o) => {
        v.forEach(e => {
          if (e.isNode() && e.id() == id) {
            result = true;
          }
        });
      });
      return (val?.length > 0);
    }
    return false;
  }

  addGroupNode(sourceNode: cytoscape.NodeSingular, groupName: string) {
    let groupActor = new GroupActor(groupName);
    let node = this.cytoscapeObject.add([{
      //group: 'nodes',
      data: { id: groupActor.id, groupname: groupActor.name, actor: groupActor }
    }]);
    sourceNode.move({ parent: groupActor.id });
    let grpdActorName = sourceNode.data().actor?.name ?? "Unnamed";
    this.addAuditTrail('New group ' + (groupName ? groupName : "Untitled") + " added containing " + grpdActorName);
  }

  addActorNode(actor: IActorBase, position?: cytoscape.Position, dontAudit?: boolean): boolean {
    // Check if actor is in the removed or excluded collection before adding!
    if (this.findRemovedNode(actor.id) || this.findExcludedNode(actor.id)) {
      return false;
    }

    var node = this.findNode(actor.id);
    if (!node) {
      node = this.cytoscapeObject.add({
        group: 'nodes',
        data: { id: actor.id, name: actor.name, actor: actor },
        renderedPosition: position
      });
      var anynode: any = node;
      anynode._private.position = position;
      if (!dontAudit) {
        this.addAuditTrail('Added node ' + actor.name);
      }
    }
    else {
      // We have found a node - but we might have more data now so ensure it is updated
      if (actor.isUBO) {
        var nodeActor: IActorBase = node.data("actor");
        if (!nodeActor.isUBO) {
          nodeActor.isUBO = true;
          nodeActor.infoObject.isUBO = true;
          node.data("actor", nodeActor);
        }
      }
    }
    if (actor.profileId && this.investigatedActor?.id == actor.id) {
      this.loadSelectedProfile(actor);
    }
    this.setNodeStyle(node);
    return true;
  }

  addGroupEdge(startActor: IActorBase, groupId: string, linkDescription: string): boolean {
    let id = groupId + startActor.id;
    let edges = this.cytoscapeObject.edges('#' + id);
    if (edges.length > 0) {
      return false;
    }

    let edge = this.cytoscapeObject.add({
      group: 'edges',
      data: { id: id, source: startActor.id, target: groupId, description: linkDescription }
    });
    this.setEdgeStyle(edge);
    return true;
  }

  addActorEdge(source: IActorBase, target: IActorBase, edgetype: edgeType, edgeDescription?: string, reason?: string): boolean {
    if (source.id == target.id) {
      // Prevent linking to yourself
      return false;
    }

    // Need to check nodes are already removed!
    if (this.findRemovedNode(source.id) || this.findExcludedNode(source.id) ||
      this.findRemovedNode(target.id) || this.findExcludedNode(target.id)) {
      return false;
    }

    let actorEdge = new ActorEdge(source, target, edgetype, edgeDescription, reason);

    // Look for reverse direction edges first
    let edges = this.cytoscapeObject.edges('#' + actorEdge.id)
    if (edges.length === 0) {
      // Now look for forward direction edges
      edges = this.cytoscapeObject.edges('#' + actorEdge.reverseId);
      if (edges.length === 0) {
        // Check whether relationship is current or not
        var edge = this.cytoscapeObject.add({
          group: 'edges',
          data: { id: actorEdge.id, source: source.id, target: target.id, actorEdge: actorEdge, description: actorEdge.description }
        });
        this.setEdgeStyle(edge);
      }
      else {
        this.checkIsUBO(edges, target, edgeDescription);
        return false;
      }
    }
    else {
      this.checkIsUBO(edges, target, edgeDescription);
      return false;
    }
    return true;
  }

  private checkIsUBO(edges: any, actor: IActorBase, edgeDescription: string) {
    if (actor.isUBO) {
      var actorEdge: ActorEdge = edges[0].data("actorEdge");
      if (actorEdge) {
        let newEdge = new ActorEdge(actorEdge.sourceActor, actor, actorEdge.type, edgeDescription);
        edges[0].data("actorEdge", newEdge);
        this.setEdgeStyle(edges[0]);
      }
    }
  }

  public refreshLayout(): void {
    let ly = this.cytoscapeObject.layout(this.graphLayout);
    this.refreshStyleSheets()
    ly.run();
  }

  public refreshStyleSheets(): void {
    let cy = this.cytoscapeObject;

    if (this.graphLayout.name == "dagre") {
      cy.style(
        [{
          selector: 'node[name]',
          style: {
            'content': 'data(name)',
            'background-color': 'white',
            'border-color': '#20706B',
            'border-width': '1px',
            'font-size': '.8em',
            'text-transform': 'none'
          }
        },
        {
          selector: 'node[groupname]',
          style: {
            'content': (this.showLabels ? 'data(groupname)' : ''),
          }
        },
        {
          selector: 'edge',
          style: {
            'label': (this.showLabels ? 'data(description)' : ''),
            'curve-style': 'taxi',
            "taxi-direction": "downward",
            "taxi-turn": 20,
            "taxi-turn-min-distance": 5,
            'target-arrow-shape': 'none',
            'width': '1pt',
            'line-color': '#68BF98',
            'font-size': '.6em',
          }
        }]);
    }
    else {
      cy.style(
        [{
          selector: 'node[name]',
          style: {
            'content': 'data(name)',
            'background-color': 'white',
            'border-color': '#20706B',
            'border-width': '1px',
            'font-size': '.8em',
            'text-transform': 'none'
          }
        },
        {
          selector: 'node[groupname]',
          style: {
            'content': (this.showLabels ? 'data(groupname)' : ''),
          }
        },
        {
          selector: 'edge',
          style: {
            'label': (this.showLabels ? 'data(description)' : ''),
            'curve-style': 'bezier',
            'target-arrow-shape': 'none',
            'width': '1pt',
            'line-color': '#68BF98',
            'font-size': '.6em',
          }
        }]);
    }
  }

  tryRestore(value: cytoscape.CollectionReturnValue): boolean {
    try {
      value.restore();
      return true;
    }
    catch (Error) {
      console.error(Error.message);
    }
    return false;
  }
}
