import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';import { AdvertDialogComponent } from './advert-dialog/advert-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { SelfServiceComponent } from './self-service.component';
import { SelfServiceScreenDialogComponent } from './self-service-screen-dialog/self-service-screen-dialog.component';

@NgModule({
    declarations: [
        SelfServiceComponent,
        AdvertDialogComponent,
        SelfServiceScreenDialogComponent
    ],
    providers: [
        
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        
    ],
    exports: [
        SelfServiceComponent,
    ]
})
export class SelfServiceModule { }
