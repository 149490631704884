import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { IIdvUserProfile, MediaItem, MediaClient } from 'src/nswag';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportUtilities } from '../../report-utils';
import { IdvStatusCodeService } from 'src/app/idv/status-codes.service';


@Component({
  selector: 'app-report-idvdetails',
  templateUrl: 'idvdetails.component.html',
  styleUrls: ['idvdetails.component.scss']
})

export class IDVDetailsReportComponent implements OnInit {
  
  @ViewChildren("textarea") textareas: QueryList<ElementRef>;
  public rowColors: Map<string, string> = new Map<string, string>();
  public userProfile: IIdvUserProfile;
  public identityDocuments: MediaItem[] = [];
  public utilityDocuments: MediaItem[] = [];
  public profileThumbnail: MediaItem;

  constructor(public dashService: DashboardService, public mediaClient: MediaClient, public sanitizer: DomSanitizer, public reportUtils: ReportUtilities) {}

  ngOnInit() {
    this.userProfile = this.dashService.getInvestigation().asUserProfile;
    this.dashService.getInvestigation()?.mergedOfficerList.sort(value => {
      return value.isActive ? -1 : 1 // active officers first
    });
    this.sortDocuments(this.userProfile);
    this.reinstateRowColors();
    setTimeout(() => {
      this.resizeAllTextAreas();
    }, 3000);
  }

  get name(): string {
    return this.userProfile.firstName+" "+this.userProfile.middleName+" "+this.userProfile.lastName;
  }

  statusCodeToClass(status: string): string {
    return IdvStatusCodeService.statusCodeToClass(status);
  }

  public resizeAllTextAreas(): void {
    this.textareas.toArray().forEach((item) => {
      item.nativeElement.style.height = 'auto';
      item.nativeElement.style.height = item.nativeElement.scrollHeight + 'px';
    }); 
  }

  public setRowColor(controlName: string): void {
    var control = this.dashService.getInvestigation()?.reportActorFreeText?.controls[controlName];
    this.rowColors.set(controlName, this.reportUtils.getRowColor(control?.value));
  }
  
  private reinstateRowColors(): void {
    Object.keys(this.dashService.getInvestigation()?.reportActorFreeText?.controls).forEach(key => {
      this.setRowColor(key);
    });
  }

  private sortDocuments(idvProfile: IIdvUserProfile): void {
    for(let document of idvProfile.documents) {
      if (document.name.startsWith("Identity")) {
        this.identityDocuments.push(document);
      }
      if (document.name.startsWith("Image"))
      {
        this.profileThumbnail = document;
      }
      else if (document.name.startsWith("Utility")) {
        this.utilityDocuments.push(document);
      }
    }
  }
}
