import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SearchService } from 'src/app/search/services/search.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Address } from 'src/nswag';
import { IActorBase } from '../../models/Actor';
import { SubjectEditAuditLog } from '../../models/Models';
import { DatePickerResult } from 'src/app/date-picker/date-picker.component';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map } from 'rxjs';
import { CountriesService } from 'src/app/utils/countries.service';

@Component({
  selector: 'app-person-subject-editor',
  templateUrl: './person-subject-editor.component.html',
  styleUrls: ['./person-subject-editor.component.scss']
})

export class PersonSubjectEditorComponent implements OnInit {

  @Output() log = new EventEmitter<SubjectEditAuditLog>();
  @Output() uploadImage = new EventEmitter<any>();
  @Output() dataEvent = new EventEmitter<any>();
  @Input() dateOfBirth: string;
  public ngbDate: NgbDateStruct = this.getDate(this.dashService.getInvestigation()?.infoObject?.dateOfBirth);
  private actor: IActorBase;

  constructor(public dashService: DashboardService, public searchService: SearchService, private dateFormatter: NgbDateParserFormatter) { 
    this.actor = this.dashService.getInvestigation();
  }

  onEdit(fieldName: string, fieldValue: string) {
    if(fieldName == "Full Name") {
      var node = this.dashService.findNode(this.actor.id) as cytoscape.NodeSingular;
      node.data("name", fieldValue);
    }
    var log: SubjectEditAuditLog = new SubjectEditAuditLog();
    log.fieldName = fieldName;
    log.fieldValue = fieldValue;
    this.log.emit(log);
  }

  onSelectFile(event) {
    this.uploadImage.emit(event);
  }

  public getProfileImage(images: string[]) {
    return images?.length > 0 ? images[0] : null; 
  }
  
  dateChanged(dateResult: DatePickerResult) {
    let date = this.dateFormatter.format(dateResult.ngbValue)
    this.onEdit('DateOfBirth', date);
    this.dataEvent.emit(dateResult);
  }

  public getDate(date: any) {
    if(date) {
      if(typeof date === 'string') {
        date = this.dateFormatter.parse(date);
        return date;
      } else {
        date = this.dateFormatter.format(date);
        return date;
      }
    }
  }

  ngOnInit(): void { 
    var fullAddress = this.dashService.getInvestigation()?.infoObject?.address?.fullAddress;
    if(!fullAddress) {
      var address: Address = new Address;
      address.fullAddress = "";
      this.dashService.getInvestigation().infoObject.address = address;
    }
  }

  nationalityTypeAhead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => {
        return this.nationalitySubSet(term);
      })
    );

    private nationalitySubSet(term: string) {
      return CountriesService.getNationalitySubSet(term);
    }
}
