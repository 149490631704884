import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-_confirmation-dialog',
  templateUrl: './_confirmation-dialog.component.html',
  styleUrls: ['./_confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() incReason: boolean;
  @Input() messageClass: string;
  @Input() checkForMandatoryNotes: boolean;
  @Output() reasonChanged = new EventEmitter<string>();
  
  public reasonText: string;
  public hasErrors: boolean = false;
  public isMandatoryNotesEnabled: boolean = false;
  public placeholderText: string = 'Enter a reason';

  constructor(private activeModal: NgbActiveModal, private profileService: ProfileService) { }

  ngOnInit() {
    if (this.checkForMandatoryNotes){
      this.isMandatoryNotesEnabled = this.profileService.isMandatoryNotesEnabled;
      if (this.isMandatoryNotesEnabled){
        this.placeholderText += ' (Required)';
      }
    }
  }

  public validation(text = null): void {
    if (!this.checkForMandatoryNotes) return;

    if (text != null){
      this.hasErrors = (!text?.trim()?.length || text?.trim().length < 1) && this.isMandatoryNotesEnabled;
    }
    else{
      this.hasErrors = (!this.reasonText?.trim()?.length || this.reasonText?.trim().length < 1) && this.isMandatoryNotesEnabled;
    }
  }
    

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.validation();
    if (this.hasErrors) return;
    this.reasonChanged.emit(this.reasonText);
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
