import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseOfListOfUserGraph, ApiResponseOfUserGraph, MyGraphClient, UserGraph, IdvUserProfile, UpdateSharedInvestigationCommand } from 'src/nswag';

@Injectable({
  providedIn: 'root'
})
export class GraphService {

  constructor(private client: MyGraphClient) { }

  //private selectedGraph: UserGraph;
  //private idvPerson: UserProfile;

  //setSelectedGraph(graph : UserGraph)  {
  //  this.clearServiceData();
  //  this.selectedGraph = graph;
  //}
  //getSelectedGraph()  {
  //  return this.selectedGraph;
  //}

  //setIDVPerson(idvPerson: UserProfile) {
  //  this.clearServiceData();
  //  this.idvPerson = idvPerson;
  //}
  //getIDVPerson() {
  //  return this.idvPerson;
  //}

  //clearServiceData() {
  //  this.selectedGraph = null;
  //  this.idvPerson = null;
  //}

  getAll() : Observable<ApiResponseOfListOfUserGraph> {
    return this.client.list();
  }

  getById(id): Observable<ApiResponseOfUserGraph> {
    return this.client.get(id);
  }

  deleteGraph(id) : Observable<void> {
    return this.client.delete(id);
  }

  addGraph(graph:UserGraph) : Observable<ApiResponseOfUserGraph> {
    return this.client.post(graph);
  }
  updateGraph(id, graph: UserGraph) : Observable<ApiResponseOfUserGraph> {
    return this.client.update(id, graph);
  }

  getSharedInvestions(): Observable<ApiResponseOfListOfUserGraph> {
    return this.client.getInvestigationsSharedWithMe();
  }

  unShareInvestigation(investigationId: string): Observable<void> {
    return this.client.removeInvestigationSharedWithMe(investigationId);
  }
}
