import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ProfileService } from 'src/app/profile.service';
import { AuditService } from '../services/audit.service';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnChanges {

  constructor(public auditService: AuditService, private profileService: ProfileService) {}
  public loaded = false;
  public showSpinner: boolean = false;
  @Input() showAuditReport: boolean = false;
  @Input() timespan: string;
  public showReport: boolean;
  public currentDate: string;

  @Output() hide = new EventEmitter<boolean>();

  ngOnChanges(): void {
    if (!this.showAuditReport) return;
    this.showSpinner = true;
    this.currentDate = UtilityService.convertToLocalDateTime(new Date(), "short");
    this.show();
  }

  private show(): void {
    this.showReport = true;
    this.showSpinner = false;
    var w: any = window;
    if (w.fcWidget) {
      w.fcWidget.hide();
    }
  }

  public onPrint(): void {
    window.print();
  }

  closeReport() {
    this.showReport = false;
    this.showAuditReport= false;
    this.hide.emit(false);

    var w: any = window;
    if (w.fcWidget) {
      w.fcWidget.show();
    }
  }

  get username(): string { return this.profileService?.fullName; }
  get companyName(): string { return this.profileService?.companyName; }
}
