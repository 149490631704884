<div class="container">
  <div class="modal-header">
    <h4 class="col">Select Previous Recipients</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row assessment-table">
        <existing-recipient-list [existingRecipientList]="riskAssessmentService.ExistingRecipientList" (recipientData)="setRecipientData($event)"></existing-recipient-list>
      </div>
      <div class="float-end pt-2 pb-2">
        <button class="btn m-1 bg-secondary text-light" (click)="dismissModal()">Cancel</button>
        <button class="btn m-1 bg-primary text-light" [disabled]="!canAdd()" (click)="addExistingRecipients()">Add Selected</button>
      </div>
  </div>
</div>
