import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { Assessment, QuestionSetDetail, QuestionSetSummary, QuestionSetSummaryList } from 'src/nswag';
import { RiskAssessmentService } from '../services/risk-assessment.service';
import { Model, StylesManager, SurveyNG } from "survey-angular";
import { UtilityService } from '../utilities/utilities';

StylesManager.applyTheme('modern');

@Component({
  selector: 'manage-question-sets-dialog',
  templateUrl: './manage-question-sets-dialog.component.html',
  styleUrls: ['./manage-question-sets-dialog.component.scss'],
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"',
    '[style.overflow]': '"hidden"',
  },
})
export class ManageQuestionSetsDialogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal, public riskAssessmentService: RiskAssessmentService, public alertService: AlertService, public utilityService: UtilityService) { }

  public assessment = new Assessment();
  public selectedQuestionSetIdsList = new Array<string>();
  public currentQuestionSetList: QuestionSetDetail[] = [];

  @Input() questionSets: QuestionSetDetail[];

  public questionSetGroups: Array<{ title: string, questionSet: QuestionSetDetail[] }>;

  async ngOnInit() {
    this.resetModelData();
    this.riskAssessmentService.getQuestionSets().subscribe(res => {
      this.currentQuestionSetList.push(...res.data.results);
      this.questionSets = this.currentQuestionSetList;

      const groups = this.questionSets ? this.utilityService.GetQuestionSetCategories(this.questionSets) : null;
      this.questionSetGroups = groups?.map(x => ({ title: x, questionSet: this.questionSets?.filter?.(y => y?.category === x)}));

      this.riskAssessmentService.getSelectedQuestionSets(this.assessment).subscribe(rs => {
        this.selectedQuestionSetIdsList.push(...rs.data);
        this.selectedQuestionSetIdsList.forEach(x => {
          //get the checkbox
          const checkbox = document.getElementById("chk" + x) as HTMLInputElement;
          checkbox.checked = true;
        });
      })
    });
  }

  public canSave(){
    if (this.assessment.sentOn !== undefined || this.assessment.locked){
      return false;
    }
    return true;
  }

  public renderSurvey(model, index: string) {
    const button = 'accButton' + index;
    const isExpanded = document.getElementById(button).getAttribute('aria-expanded');
    if (isExpanded == 'true') {
      const survey = new Model(model);
      survey.showProgressBar = 'top';
      survey.mode = 'display';
      const name = 'collapse' + index;
      SurveyNG.render(name, { model: survey });
    }
  }

  public selectQuestionSets() {
    let sets = new Array<QuestionSetSummary>();
    // loop through each checkbox to see if checked
    this.questionSets.forEach(qs => {
      const checkbox = document.getElementById("chk" + qs.id) as HTMLInputElement;
      if (checkbox.checked)
        sets.push(qs);
    });

    const questionList = new QuestionSetSummaryList;
    questionList.results = sets;

    this.riskAssessmentService.submitQuestionSets(this.assessment.id, questionList).subscribe(result => {
      if (result.isSuccess) {
        this.dismissModal();
      }
    },
      (error) => {
        this.alertService.error("Unfortunately, something has gone wrong. Please try again or contact support.");
        console.error(error);
      });
  }

  private resetModelData() {
  }

  dismissModal() {
    this.activeModal.dismiss();
  }
}
