import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IActorBase } from '../dashboard/models/Actor';
import { SearchCriteria } from './models/search-criteria';
import { SearchDialogComponent } from './search-dialog.component';

@Injectable({
    providedIn: 'root'
  })

@Injectable()
export class SearchDialogService {
    constructor(private modalService: NgbModal) { }

    public selectedActor: IActorBase;

  public showSearch(searchCriteria: SearchCriteria): Promise<boolean> {
        const modalRef = this.modalService.open(SearchDialogComponent, { scrollable: true, size: 'lg', 
        container:'#dialogueContainer' 
      });
        modalRef.componentInstance.searchCriteria = searchCriteria;
        return modalRef.result;
    }
}
