<div class="modal-header">
    <div class="col">
        @switch (currentAdState) {
        @case (AdvertState.ADDCREDITAD) {<h4 class="modal-title col">Credits Remaining:
            <strong>{{selfServeService.screeningCredits}}</strong></h4>}
        @case (AdvertState.INVESTIGATIONAD) {<h4 class="modal-title col">Start Investigating</h4>}
        @case (AdvertState.SCREENAD) {<h4 class="modal-title col">Start Screening</h4>}
        @case (AdvertState.MONITORAD) {<h4 class="modal-title col">Start Monitoring</h4> }
        @case (AdvertState.OUTOFCREDITAD) {<h4 class="modal-title col">Out Of Credit</h4>}
        @case (AdvertState.IDVAD) {<h4 class="modal-title col">Start eIDV</h4>}
        @case (AdvertState.ASSESSMENTAD) {<h4 class="modal-title col">Start Assessment</h4>}
        @case (AdvertState.APISETTINGSAD) {<h4 class="modal-title col">API Settings</h4>}
        @case (AdvertState.ASSESSMENTSETTINGSAD) {<h4 class="modal-title col">Assessment Settings</h4> }
        @case (AdvertState.INTELLIGENCEAD) {<h4 class="modal-title col">Start Intelligence</h4> }
        }
    </div>
    <select *ngIf="currentAdState == AdvertState.OUTOFCREDITAD || currentAdState == AdvertState.ADDCREDITAD"
        class="form-select form-control currency col-auto me-2" [(ngModel)]="selectedCurrency">
        <option value="gbp">£&emsp;GBP&emsp;💷</option>
        <option value="usd">$&emsp;USD&emsp;💵</option>
    </select>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="closeModals()">
        <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span
                class="icon-cross"></span></span>
    </button>
</div>

<div class="modal-body">
    <div>
        @switch (currentAdState) {
        @case (AdvertState.CHECKING) {
        <div class="spinner-border text-primary" role="status"></div>
        <span>Retrieving image ...</span>
        }
        @case (AdvertState.INVESTIGATIONAD) { 
        <img src="/assets/images/Themis-Search-Investigations.svg" class="self-serve-guide"
            alt="Themis investigations allow you to explore your links to financial crime" />
        }
        @case (AdvertState.SCREENAD) {
        <img src="/assets/images/Themis-Screening.svg" class="self-serve-guide"
            alt="Screen all your clients for political exposure, sanctions, law enforcement and adverse media all in one place" />
        }
        @case (AdvertState.MONITORAD) {
        <img src="/assets/images/Themis-Ongoing-Monitoring.svg" class="self-serve-guide"
            alt="Monitor all your financial crime risks on an ongoing basis" />
        }
        @case (AdvertState.INTELLIGENCEAD) {
        <img src="/assets/images/Themis-Intelligence.svg" class="self-serve-guide"
            alt="Tailored due dilligence reports from expert financial crime investigators" />
        }
        @case (AdvertState.REGULATORYREPORTAD) {
        <img src="/assets/images/Themis-RegulatoryReport.svg" class="self-serve-guide"
            alt="A single platform for all your regulatory reporting" />
        }
        @case (AdvertState.OUTOFCREDITAD) {
        <div *ngIf="selectedCurrency == Currency.POUNDS; else showUsPriceList">
            <ng-template [ngTemplateOutlet]="displayPricingListPounds"></ng-template>
        </div>
        <ng-template #showUsPriceList>
            <ng-template [ngTemplateOutlet]="displayPricingListDollars"></ng-template>
        </ng-template>
        }
        @case (AdvertState.IDVAD) {
        <img src="/assets/images/Themis-eIDV.svg" class="self-serve-guide"
            alt="Themis eID&V technology allows you to easily verify identities remotely" />
        }
        @case (AdvertState.ASSESSMENTAD) {
        <img src="/assets/images/Themis-Risk-Assessment.svg" class="self-serve-guide"
            alt="Themis Search & Monitoring unique end-to-end AML & KYC platform makes screening easy" />
        }
        @case (AdvertState.APISETTINGSAD) {
        <img src="/assets/images/Themis-API.svg" class="self-serve-guide"
            alt="Perform due diligence and monitor financial crime risk exposure on your platform using Themis API integration" />
        }
        @case (AdvertState.ASSESSMENTSETTINGSAD) {
        <img src="/assets/images/Themis-Risk-Assessment.svg" class="self-serve-guide"
            alt="Themis Search & Monitoring unique end-to-end AML & KYC platform makes screening easy" />
        }
        @case (AdvertState.ADDCREDITAD) {
        <div *ngIf="selectedCurrency == Currency.POUNDS; else showUsPriceList">
            <ng-template [ngTemplateOutlet]="displayPricingListPounds"></ng-template>
        </div>
        <ng-template #showUsPriceList>
            <ng-template [ngTemplateOutlet]="displayPricingListDollars"></ng-template>
        </ng-template>
        }

    }
  </div>
</div>

<div class="modal-footer modal-footer-ad">
    <div class="pb-2 btns-ad">
        <button class="btn m-1 bg-secondary text-light" (click)="closeModals()">Cancel</button>
        <button class="btn m-1 btn-yellow-bg text-light contact-ad" (click)="submitContactRequest(advertCriteria)">
            Contact Us
        </button>
    </div>
</div>

<ng-template #displayPricingListPounds>
    <div class="container text-center mb-5">
        <h1 class="display-4">Pricing</h1>
        <p class="lead">Simple transparent pricing. No contract. No surprise fees</p>
    </div>
    <div class="container text-center">
        <div class="row row-cols-1 row-cols-md-3">
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>20 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">£29 <h6 class="text-muted">+ VAT &#64;20%</h6>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>£1.45 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.POUNDS, Products.Screen_20)"
                            class="btn btn-outline-primary btn-block">Buy 20 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>50 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">£65 <h6 class="text-muted">+ VAT &#64;20%</h6>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>£1.30 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.POUNDS, Products.Screen_50)"
                            class="btn btn-outline-primary btn-block">Buy 50 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>100 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">£115 <h6 class="text-muted">+ VAT &#64;20%</h6>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>£1.15 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.POUNDS, Products.Screen_100)"
                            class="btn btn-outline-primary btn-block">Buy 100 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>250 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">£225 <h6 class="text-muted">+ VAT &#64;20%</h6>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>£0.90 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.POUNDS, Products.Screen_250)"
                            class="btn btn-outline-primary btn-block">Buy 250 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>500 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">£350 <h6 class="text-muted">+ VAT &#64;20%</h6>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>£0.70 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.POUNDS, Products.Screen_500)"
                            class="btn btn-outline-primary btn-block">Buy 500 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>1000 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">£500 <h6 class="text-muted">+ VAT &#64;20%</h6>
                        </h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>£0.50 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.POUNDS, Products.Screen_1000)"
                            class="btn btn-outline-primary btn-block">Buy 1000 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>+1000 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h6 class="my-0 font-weight-normal mb-4">Enquire for a tailored plan</h6>
                        <button (click)="submitContactRequest(advertCriteria)"
                            class="btn btn-outline-primary btn-block">Tailored Plan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #displayPricingListDollars>
    <div class="container text-center mb-5">
        <h1 class="display-4">Pricing</h1>
        <p class="lead">Simple transparent pricing. No contract. No surprise fees</p>
    </div>
    <div class="container text-center">
        <div class="row row-cols-1 row-cols-md-3">
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>20 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">$38</h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>$1.90 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.DOLLARS, Products.Screen_20)"
                            class="btn btn-outline-primary btn-block">Buy 20 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>50 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">$85</h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>$1.70 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.DOLLARS, Products.Screen_50)"
                            class="btn btn-outline-primary btn-block">Buy 50 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>100 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">$150</h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>$1.50 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.DOLLARS, Products.Screen_100)"
                            class="btn btn-outline-primary btn-block">Buy 100 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>250 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">$300</h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>$1.20 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.DOLLARS, Products.Screen_250)"
                            class="btn btn-outline-primary btn-block">Buy 250 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>500 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">$475</h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>$0.95 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.DOLLARS, Products.Screen_500)"
                            class="btn btn-outline-primary btn-block">Buy 500 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>1000 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h1 class="my-0 font-weight-normal">$650</h1>
                        <ul class="list-unstyled mt-3 mb-4">
                            <li>$0.65 per credit</li>
                            <li>Valid for 1 year</li>
                            <li>No extra charge</li>
                            <li>Create reports</li>
                        </ul>
                        <button (click)="makeSession(Currency.DOLLARS, Products.Screen_1000)"
                            class="btn btn-outline-primary btn-block">Buy 1000 Credits</button>
                    </div>
                </div>
            </div>
            <div class="col mb-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <h4>+1000 Credits</h4>
                    </div>
                    <div class="card-body">
                        <h6 class="my-0 font-weight-normal mb-4">Enquire for a tailored plan</h6>
                        <button (click)="submitContactRequest(advertCriteria)"
                            class="btn btn-outline-primary btn-block">Tailored Plan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
