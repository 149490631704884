<div class="container overflow-auto">
    <div class="modal-header">
      <h4 class="col">Audit Trail</h4>
      <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
        <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container">
        <div>
          <h1>
            Audit trail for {{assessment.name}}
          </h1>
          <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
            <thead>
              <tr>
                <th scope="col">User</th>
                <th scope="col">Timestamp</th>
                <th scope="col">Action</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let entry of audit.results | paginate : {
                id: 'auditTrail',
                itemsPerPage: pageSize,
                currentPage: currentPage,
                totalItems: total
              };
              let i = index">
                <td>
                  {{entry.createdBy}}
                </td>
                <td>
                  {{entry.createdOn}}
                </td>
                <td>
                  {{entry.action}}
                </td>
                <td>
                  {{entry.value}}
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls id="auditTrail"
                     class="pagination"
                     previousLabel="Prev"
                     nextLabel="Next"
                     [responsive]="true"
                     (pageChange)="handlePageChange($event)">
          </pagination-controls>
        </div>
      </div>
    </div>
      <div class="float-end p-2">
        <button class="btn m-1 bg-secondary text-light" (click)="dismissModal()">Close</button>
      </div>
  </div>

