<div id="investigations-tabs">
    <div class="float-end">
        <button type="button" class="close" aria-label="Close" (click)="close()">
            <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
        </button>
    </div>
    <ul ngbNav #nav="ngbNav" class="nav-tabs flex-nowrap">
        <ng-container [ngTemplateOutlet]="displayTab" [ngTemplateOutletContext]="{pos: 0, title:'Profile', icon: 'icon-user'}"></ng-container>
        <ng-container [ngTemplateOutlet]="displayTab" [ngTemplateOutletContext]="{pos: 1, title:'Sanctions', icon: 'icon-prohibited'}"></ng-container>
        <ng-container [ngTemplateOutlet]="displayTab" [ngTemplateOutletContext]="{pos: 2, title:'PEPs', icon: 'icon-library2'}"></ng-container>
        <ng-container [ngTemplateOutlet]="displayTab" [ngTemplateOutletContext]="{pos: 3, title:'Law Enforcement', icon: 'icon-balance'}"></ng-container>
        <ng-container [ngTemplateOutlet]="displayTab" [ngTemplateOutletContext]="{pos: 4, title:'Adverse Media', icon: 'icon-tv'}"></ng-container>
        <ng-container [ngTemplateOutlet]="displayTab" [ngTemplateOutletContext]="{pos: 5, title:'Adverse Media', icon: 'icon-group-work'}"></ng-container>
        <ng-template #displayTab let-title="title" let-pos="pos" let-icon="icon">
            <li [ngbNavItem]="pos">
                <a ngbNavLink><span [title]="title" [class]="icon"></span></a>
                <ng-template ngbNavContent>
                    <ng-container [ngTemplateOutlet]="displayInfo" [ngTemplateOutletContext]="{ title: title }"></ng-container>
                </ng-template>
            </li>
        </ng-template>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<ng-template #displayInfo let-title="title">
    <div class="card-body  overflow-auto text-wrap text-break text-break">
        <div class="row zero-margin-padding">
            <div class="col zero-margin-padding">
                <app-investigation *ngIf="client" [selectedClient]="client.client" [currentModule]="currentModule"></app-investigation> 
            </div>
        </div>
        <div *ngIf="discounted then displayDiscounts"></div>            
        <div *ngIf="!discounted && matches then displayMatches"></div>
        <div *ngIf="!discounted && !matches && inMonitoring && screened then displayNoMatchesMonitoring"></div>
        <div *ngIf="!discounted && !matches && inMonitoring && !screened then displayPendingMatchesMonitoring"></div>
        <div *ngIf="!discounted && !matches && !inMonitoring then displayNoMatchesScreening"></div>
        <span *ngIf="matches"></span>
    </div>
</ng-template>

<ng-template #displayDiscounts>
    <div class="row pt-2">
        <div class="col alert alert-info">
            <h5>Matches discounted</h5>
            <p><b>All potential matches found for {{name}} have been discounted.</b></p>
            <p *ngIf="allowDiscounting">Click below to view the discounted matches.</p>
        </div>
    </div>
    <div *ngIf="client?.client?.id && inMonitoring" class="row pt-2">
        <div class="col">
            <mon-alertstatus *ngIf="!isArchived" [client]="client" (clientChange)="onClientChange($event)"></mon-alertstatus>
        </div>
    </div>
    <div *ngIf="allowDiscounting" class="row pt-2">
        <div class="col">
            <app-detail-links 
                [client]="client"
                [profile]="profile"
                [discounted]="discounted"
                [screened]="true"
                (addToMonitoringEvent)="addToMonitoring()"
                (viewMatchesEvent)="viewMatches()">
            </app-detail-links>
        </div>
    </div>
</ng-template>

<ng-template #displayMatches>
    <div class="row pt-2">
        <div class="col alert alert-info">
            <h5>Potential matches found</h5>
            <p><b>Potential matches have been found for {{name}}!</b></p>
            <p>Click below to view the potential matches.</p>
        </div>
    </div>
    <!-- <div cass="row pt-2">
        <div class="col">
            <mon-alertstatus [client]="client" (clientChange)="onClientChange($event)"></mon-alertstatus>
        </div>
    </div> -->
    <div *ngIf="allowDiscounting" class="row pt-2">
        <div class="col">
            <app-detail-links 
                [client]="client" 
                [profile]="profile" 
                [discounted]="discounted"
                [screened]="true"
                (addToMonitoringEvent)="addToMonitoring()"
                (viewMatchesEvent)="viewMatches()">
            </app-detail-links>
        </div>
    </div>
</ng-template>

<ng-template #displayPendingMatchesMonitoring>
    <div class="row pt-2">
        <div class="col alert alert-info">
            <h5>Match pending</h5>
            <p><b>{{name}} has been added to monitoring and is awaiting action.</b></p>
            <p>The system will automatically screen this record for potential matches overnight and notify you of any changes; to run screening checks now, click the 'Screen Client' option below.</p>
        </div>
    </div>
    <div *ngIf="allowDiscounting" class="row pt-2">
        <div class="col">
            <app-detail-links 
                [client]="client"
                [profile]="profile"
                [hasMatches]="false"
                [discounted]="discounted"
                [screened]="false"
                (addToMonitoringEvent)="addToMonitoring()"
                (viewMatchesEvent)="viewMatches()"
                (searchMatchesEvent)="searchMatches()">
            </app-detail-links>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col">
        </div>
    </div>
</ng-template>

<ng-template #displayNoMatchesMonitoring>
    <div class="row pt-2">
        <div class="col alert alert-info">
            <h5>No matches found</h5>
            <p><b>Good news! No matches have been found for {{name}}.</b></p>
            <p>We will continue to monitor the client and notify you of any changes.</p>
        </div>
    </div>
    <div *ngIf="client?.client?.id && inMonitoring" class="row pt-2">
        <div class="col">
            <mon-alertstatus *ngIf="!isArchived" [client]="client" (clientChange)="onClientChange($event)"></mon-alertstatus>
        </div>
    </div>
    <div *ngIf="allowDiscounting" class="row pt-2">
        <div class="col">
            <app-detail-links 
                [client]="client"
                [profile]="profile"
                [hasMatches]="false"
                [discounted]="discounted"
                [screened]="true"
                (addToMonitoringEvent)="addToMonitoring()"
                (viewMatchesEvent)="viewMatches()"
                (searchMatchesEvent)="searchMatches()">
            </app-detail-links>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col">
        </div>
    </div>
</ng-template>

<ng-template #displayNoMatchesScreening>
    <div class="row pt-2">
        <div class="col alert alert-info">
            <h5>No matches found</h5>
            <p><b>Good news! No matches have been found for {{name}}.</b></p>
            <p>Click the &apos;launch investigation&apos; button below if you want to investigate more.</p>
            <p>Or click the &apos;move to monitoring&apos; button below and we will monitor the client and notify you of any changes.</p>
        </div>
    </div>
    <div *ngIf="allowDiscounting" class="row pt-2">
        <div class="col">
            <app-detail-links 
                [client]="client"
                [profile]="profile"
                [hasMatches]="false"
                [discounted]="discounted"
                [screened]="true"
                (addToMonitoringEvent)="addToMonitoring()"
                (viewMatchesEvent)="viewMatches()">
                <!-- (searchMatchesEvent)="searchMatches()"> -->
            </app-detail-links>
        </div>
    </div>
    <div class="row pt-2">
        <div class="col">
        </div>
    </div>
</ng-template>
