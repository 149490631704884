<div *ngIf="clientList.length > 0">
  <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
    <thead>
      <tr>
        <th scope="col">Status</th>
        <th scope="col">Name</th>
        <th scope="col">Reference</th>
        <th scope="col">Tags</th>
        <th scope="col">Country</th>
        <th scope="col">Date of Birth</th>
        @if (showAlertDate){
          <th scope="col" (click)="toggleSort()">Open Alert Date
              @if (sortAsc){
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                }
              @else {
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              }
        </th>
      }
        <th scope="col" class="text-center">Action</th>
      </tr>
    
    </thead>
    <tbody>
      <tr *ngFor="let client of clientList | paginate : {
          itemsPerPage: pageSize,
          currentPage: currentPage,
          totalItems: clientTotal
        };
        let i = index"
          (mouseenter)="setFocusedClient(client)" (click)="selectClient(client)" class="align-middle">
  
        <td>
          <mon-alerticongroup [client]="client"></mon-alerticongroup>
        </td>
        <td><span [class]="displayClientIcon(client)"></span> {{ displayClientName(client) }}</td>
        <td>{{displayClientRef(client)}}</td>
        <td><span *ngFor="let tag of displayClientTags(client)" class="badge bg-secondary text-dark text-uppercase">{{tag}}</span></td>
        <td>{{displayCountry(client)}}</td>
        <td>{{displayDoB(client)}}</td>
        @if(showAlertDate){<td>{{displayAlertDate(client)}}</td>}
        <td class="text-center verti">
          <div ngbDropdown class="d-inline-block mon" id="monitor-list">
            <div class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle (click)="dropDownClicked($event)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div *ngIf="!isArchived" ngbDropdownItem (click)="viewClientReport($event)">Client Report</div>
              <hr class="divider">
              <div ngbDropdownItem (click)="viewHistoryReport($event)">Audit Report</div>
              <span *ngIf="allowEdit">
                <hr class="divider">
                <div ngbDropdownItem (click)="editClient($event)">Edit Client</div>
              </span>
              <hr class="divider">
              <div *ngIf="!isArchived" ngbDropdownItem (click)="deleteClient($event)">Archive Client</div>
              <div *ngIf="isArchived" ngbDropdownItem (click)="recoverClient($event)">Recover Client</div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  
  <pagination-controls class="pagination"
                       previousLabel="Prev"
                       nextLabel="Next"
                       [responsive]="true"
                       (pageChange)="handlePageChange($event)">
  </pagination-controls>
</div>
<span *ngIf="clientList.length === 0">
  There are no records to display
</span>
<!-- Items per Page:
  <select (change)="handlePageSizeChange($event)">
    <option *ngFor="let size of pageSizes" [ngValue]="size">
      {{ size }}
    </option>
  </select> -->
