<div class="container">
  <div class="modal-header">
    <h4 class="col">Screen New Client</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>

  <app-problem-details></app-problem-details>

  <div class="modal-body">
    <div class="container">
      <!-- Company or Person -->
      <div class="row">
        <div class="input-group">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="officer" name="clientType" value="0" [checked]="!isBusiness" (change)="setClientType()">
            <label class="form-check-label" for="officer">Individual</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" id="company" name="clientType" value="1" [checked]="isBusiness" (change)="setClientType()">
            <label class="form-check-label" for="company">Company</label>
          </div>
        </div>
      </div>
      <div *ngIf="isBusiness then companyEditorBlock; else personEditorBlock"></div>
    </div>
    <div class="row">
      <div class="float-end p-2 mt-3 submit-action-btn">
        <button class="btn m-1 bg-secondary text-light screen-button" (click)="dismissModal()">Cancel</button>
        <button class="btn m-1 bg-primary text-light screen-button" [disabled]="!canSearch()" (click)="performScreen()"> 
          <span *ngIf="isSearching" class="spinner-border"></span>
          <span *ngIf="!isSearching">Screen</span>
        </button>
      </div>
    </div>
    <div *ngIf="showIndividualResults()" class="row">
      <mon-individualmatches [threshold]="threshold" [searchName]= "screenNameCopy" [searchFirstName] ="screenFirstNameCopy" [searchOtherName] ="screenOtherNameCopy" [searchLastName] ="screenLastNameCopy" [client]="client" [matches]="individualMatches" (viewedMoreMatches)="setViewedMoreMatches()" (match)="setIndividualMatch($event)"></mon-individualmatches>
    </div>
    <div *ngIf="showBusinessResults()" class="row">
      <mon-businessmatches [threshold]="threshold" [searchName] ="screenNameCopy" [matches]="businessMatches" (viewedMoreMatches)="setViewedMoreMatches()" (match)="setBusinessMatch($event)" [client]="client"></mon-businessmatches>
    </div>
  </div>
</div>

<app-loader [show]="isBusy"></app-loader>

<ng-template #companyEditorBlock>
  <div class="mt-2 d-flex">
    <div class="row required-input">
      <label>Company Name</label>
    </div>
    <small class="required-label">(Required)</small>
  </div>
  <div class="row">
    <div class="input-group required-name">
      <input type="text" required name="name" class="form-control mb-2" [(ngModel)]="screenName" #name="ngModel" (change)="updateClientInputModel()">
    </div>
    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="row">
      <div *ngIf="name.errors?.required" class="form-control alert alert-danger">
        Company Name is required.
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <h5 class="ps-2 mb-3">Additional Screening Criteria  &nbsp;&nbsp;<small>(Optional)</small></h5>
    <label>Jurisdiction</label>
  </div>
  <div class="row">
    <div class="input-group client-nationality">
      <input id="jurisdiction" 
      [(ngModel)]="jurisdiction"
      [ngbTypeahead]="countryTypeAhead" 
      class="form-control mb-2"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
      name="checkJurisdiction"
      #checkJurisdiction="ngModel" (change)="updateClientInputModel()"/>
    </div>
    <div *ngIf="(jurisdiction && !isJurisdictionValid()) && (checkJurisdiction.dirty || checkJurisdiction.touched) && !jurisdictionFocus" class="row">
      <div class="form-control alert alert-danger">
        Valid Country is required.
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <h5 class="ps-2">Additional Client Information &nbsp;&nbsp;<small>(Optional)</small></h5>
    <div class="col">
      <div class="mt-2">
        <label>Unique Client Reference ID</label>
      </div>
      <div class="input-group">
        <input type="text" class="form-control" id="uniqueScreenCli" [(ngModel)]="clientRef" (change)="checkBusinessClientExists(); updateClientInputModel()">
      </div>
      <div *ngIf="clientExists" class="row">
        <div class="form-control alert alert-danger">
          <small> A client with this ID already exists.</small>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="mt-2">
        <label>Tags</label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" id="uniqueClientDialogTag">
      <app-tags [(tagsInput)]="tags" [editable]=true></app-tags>
    </div>
  </div>
</ng-template>

<ng-template #personEditorBlock>
  <div class="mt-2 d-flex">
    <div class="row required-input">
      <label class="required-input">Individual's Name</label>
    </div>
    <small class="required-label">(Required)</small>
  </div>
  
  <div class="row">
    <div class="input-group required-name">
      <input type="text" required name="name" id="uniqueScreenName" class="form-control mb-2" [(ngModel)]="screenName" #name="ngModel" (change)="updateClientInputModel()">
    </div>
    <div *ngIf="(name.invalid || (!this.isIndividualNameValid())) && (name.dirty || name.touched) " class="row">
      <div *ngIf="name.errors?.required" class="form-control alert alert-danger">
        A name is required.
      </div>
      <div *ngIf="!this.isIndividualNameValid() && this.screenName" class="form-control alert alert-danger">
        Only alphabetical characters allowed.
      </div>
    </div>
  </div>
  
  <div class="mt-4">
    <h5 class="ps-2">Additional Screening Criteria &nbsp;&nbsp;<small>(Optional)</small></h5>
    <div class="row">
      <div class="col">
        <div class="mt-2">
          <label>Date of Birth</label>
        </div>
        <div id="uniqueClientDialogDate">
          <app-date-picker (dataEvent)='dateChanged($event); updateClientInputModel()'></app-date-picker>
        </div>
      </div>
      <div class="col">
        <div class="mt-2">
          <label>Gender</label>
        </div>
        <div class="input-group">
          <select class="form-select form-control" id="uniqueScrDialogGender" [(ngModel)]="gender" (change)="updateClientInputModel()">
            <option value=""></option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <label>Nationality / Country of Residence</label>
    </div>
    <div class="row">
      <div class="input-group client-nationality">
        <input 
        id="nationality"
        [(ngModel)]="nationality" 
        [ngbTypeahead]="nationalityTypeAhead" 
        class="form-control mb-2" 
        (focus)="onFocus($event)"
        (blur)="onBlur($event)"
        name="checkNationality"
        #checkNationality="ngModel" (change)="updateClientInputModel()"/>
      </div>
      <div *ngIf="(nationality && !isNationalityValid()) && (checkNationality.dirty || checkNationality.touched) && !nationalityFocus" class="row">
        <div class="form-control alert alert-danger">
          Valid Nationality is required.
        </div>
      </div>
    </div>
  </div>
  
  <div class="mt-4">
    <h5 class="ps-2">Additional Client Information &nbsp;&nbsp;<small>(Optional)</small></h5>
    <div class="row">
      <div class="col">
        <div class="mt-2">
          <label>Unique Client Reference ID</label>
        </div>
        <div class="input-group">
          <input type="text" class="form-control"  id="uniqueScreenCliTwo" [(ngModel)]="clientRef" (change)="checkIndividualClientExists(); updateClientInputModel()">
        </div>
        <div *ngIf="clientExists" class="row">
          <div class="form-control alert alert-danger">
            <small> A client with this ID already exists.</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mt-2">
          <label>Tags</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" id="uniqueClientDialogTwoTag">
       <app-tags [(tagsInput)]="tags" [editable]=true></app-tags>
      </div>
    </div>
  </div>
</ng-template>