import { animate, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientResult, ListClientsQuery, Log } from 'src/nswag';
import { CurrentModule } from '../monitor/client-report/client-report.component';
import { MonitorService } from '../monitor/services/monitor.service';
import { ScreeningService } from '../screening/services/screening.service';
import { AlertService } from '../_alert';
import { ConfirmationDialogService } from '../_confirmation-dialog/ConfirmationDialog.service';

@Component({
  selector: 'app-client-archive',
  templateUrl: './client-archive.component.html',
  styleUrls: ['./client-archive.component.scss'],
  animations: [
    trigger('inOutAnimation',[
      transition(':enter',[
        style({ height: 0, opacity: 0 }),
        animate('1s ease-out', style({ height: '100%', opacity: 1 }))
      ]),
      transition(':leave',[
        style({ height: '100%', opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class ClientArchiveComponent implements OnInit, OnDestroy {

  @Input() advancedSearchOptions = new ListClientsQuery();

  public showMain = true;
  public showHistoryReport = false;
  public showClientReport = false;
  public service: MonitorService | ScreeningService;
  public showAdvancedOptions = false;
  public auditLogs: Log[] = [];
  public currentClient: ClientResult;
  public currentModule: CurrentModule;
  public search: string;
  
  constructor(public screeningService: ScreeningService, public monitorService: MonitorService, private confirmationService: ConfirmationDialogService, private alertService: AlertService, private route: ActivatedRoute, private router: Router, private location: Location) {
    this.currentModule = this.router.getCurrentNavigation()?.extras?.state?.module;
  }

  ngOnDestroy(): void {
    if (this.service) {
      this.service.resetGlobals(false);
    }
  }

  ngOnInit(): void {
    this.setClientService(this.currentModule);
    this.service.resetGlobals(false);
    if(history?.state?.refresh) {
      this.service.loadArchivedClients();
    }
  }
  
  private setClientService(module: CurrentModule) {
    switch(module){
      case CurrentModule.MONITOR:
        this.service = this.monitorService;
        break;
      case CurrentModule.SCREEN:
        this.service = this.screeningService;
        break;
      default:
        console.log('failed to set client service. Redirecting..');
        this.router.navigate(['']);
        break;
    }
  }

  backClicked() {
    this.location.back()
  }

  public viewHistoryReport(client: ClientResult) {
    this.showMain = false;
    this.currentClient = client;
    this.showHistoryReport = true;
    this.loadAuditLogs(client);
  }

  private loadAuditLogs(client:ClientResult) {
    this.auditLogs = [];
    this.service.getClientAuditLogs(client.client.id).subscribe(result => {
      this.auditLogs = result.data.logEntries;
    });    
  }

  public closeHistoryReport(cancelled: boolean) {
    this.showMain = true;
    this.showHistoryReport = false;
  }

  public recoverClient(client: ClientResult) {
    this.confirmationService.confirm("Confirm recovery?", null, true, "Ok", "Cancel", "sm", true).then(r => {
      if (r) {
        this.service.recoverClient(client, this.confirmationService?.reason).subscribe(r => {
          var index = this.service.ClientList.indexOf(client, 0);
          if (index > -1) {
            this.service.ClientList.splice(index, 1);
          }
          this.service.removeClient(client);
          this.alertService.success("The client has been recovered", { autoClose: true });
        });
      }
    });
  }

  public setSearchOptions(data: ListClientsQuery) {
    this.advancedSearchOptions = data;
  }

  public displaySearchOptions() {
    if (this.showAdvancedOptions == true)
      this.showAdvancedOptions = false;
    else
      this.showAdvancedOptions = true;
  }

  public changePage(page: number) {
    this.service.loadLAMPSClientsPage(page);
  }

  public selectClient(client: ClientResult) {
    this.service.SetSelectedClient(client);
  }

  public clientChange(client: ClientResult) {
    this.service.refreshSelectedClient(client);
  }

  public setSearch(value: string) {
    this.search = value;
  }


  public searchClient() {
    this.advancedSearchOptions.search = this.search;
    this.advancedSearchOptions.showArchived = true;
    this.service.searchForClients(this.advancedSearchOptions);
  }
}
