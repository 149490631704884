<div *ngIf="recipientList?.length > 0">
  <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
    <thead>
      <tr>
        <th scope="col">Assigned To <span class="icon-question-circle ms-1" title="Tour Guide" [ngbTooltip]="showAssessToolTipTwo"></span></th>
        <th scope="col">Company</th>
        <th scope="col">Date Sent</th>
        <th scope="col">Date Completed</th>
        <th scope="col" class="text-center">Status</th>
        <th scope="col" class="text-center">Action <span class="icon-question-circle ms-1" title="Tour Guide" [ngbTooltip]="showActToolTipTwo"></span></th>
      </tr>
    </thead>
    <tbody>
      <tr [ngClass]="{'table-warning' : isErrorSending(recipient)}" *ngFor="let recipient of recipientList | paginate : {
          id: 'recipientPagination',
          itemsPerPage: pageSize,
          currentPage: currentPage,
          totalItems: recipientTotal
        };
        let i = index"
          (mouseenter)="setFocusedRecipient(recipient)" (click)="!canViewResults() ? $event.stopPropagation() : selectRecipient(recipient)" class="align-middle">
  
        <td>{{displayAssignedTo(recipient)}}</td>
        <td>{{displayCompany(recipient)}}</td>
        <td>{{displayDateCreated(recipient)}}</td>
        <td>{{displayDateCompleted(recipient)}}</td>
        <td class="text-center"><app-result-status-badge [recipient]="recipient"></app-result-status-badge></td>
        <td class="text-center verti">
          <div ngbDropdown class="d-inline-block mon" id="assessment-list">
            <div class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle (click)="dropDownClicked($event)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div [disabled]="isLocked()" ngbDropdownItem (click)="sendRecipient($event)">{{recipient?.sentOn ? 'Resend' : 'Send'}}</div>
              <hr class="divider">
              <div [disabled]="!canLock()" ngbDropdownItem (click)="lockRecipient($event)">{{isLocked() ? 'Unlock' : 'Lock'}}</div>
              <hr class="divider">
              <div [disabled]="!canViewResults()" ngbDropdownItem (click)="viewResults($event)">View Results</div>
              <hr class="divider">
              <div [disabled]="!canViewResults()" ngbDropdownItem (click)="viewAnswers($event)">{{isInProgress() ? 'View Progress' : 'View Answers'}}</div>
              <hr class="divider">
              <div [disabled]="isComplete()" ngbDropdownItem (click)="editRecipient($event)">Edit</div>
              <hr class="divider">
              <div [disabled]="isComplete()" ngbDropdownItem (click)="removeRecipient($event)">Remove</div>
              <!-- <hr class="divider">
              <div [disabled]="true" ngbDropdownItem (click)="screenRecipient($event)">Screen</div>
              <hr class="divider">
              <div [disabled]="true" ngbDropdownItem (click)="monitorRecipient($event)">Monitor</div> -->
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  
  <pagination-controls id="recipientPagination"
                       class="pagination"
                       previousLabel="Prev"
                       nextLabel="Next"
                       [responsive]="true"
                       (pageChange)="handlePageChange($event)">
  </pagination-controls>
</div>
<span *ngIf="recipientList?.length == 0">There are no records to display</span>
<!-- Items per Page:
  <select (change)="handlePageSizeChange($event)">
    <option *ngFor="let size of pageSizes" [ngValue]="size">
      {{ size }}
    </option>
  </select> -->


<!-- Templates for tour guide -->
<ng-template #showAssessToolTipTwo>
  <app-assessment-tooltip [toolTipTemplate]="'assessToolTipTwo'"></app-assessment-tooltip>
</ng-template>

<ng-template #showActToolTipTwo>
  <app-assessment-tooltip [toolTipTemplate]="'actToolTipTwo'"></app-assessment-tooltip>
</ng-template>