import { Component, Input } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { EntityTypes, IOfficerInfo, MediaClient } from 'src/nswag'
import tippy from 'tippy.js';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PremiumSearchUtilityService } from 'src/app/utilitities/premium-search-utilities';
import { IActorBase } from '../models/Actor';
import { CountriesService } from 'src/app/utils/countries.service';
import { IdvStatusCodeService } from 'src/app/idv/status-codes.service';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss']
})
export class SubjectComponent {

  @Input() actor: IActorBase;
  
  constructor(public dashService: DashboardService, public dateFormatter: NgbDateParserFormatter, private mediaClient: MediaClient, public premiumUtils: PremiumSearchUtilityService) {}

  showSubjectEditor(): boolean {
    let show = true;
    // SPIL
    if (this.actor?.actorType == EntityTypes.Individual) {
      show = false;
    } 
    // IDV
    else if (this.actor?.actorType == EntityTypes.IDV) {
      show = false;
    } 
    // PREMIUM
    else if (this.actor?.actorType == EntityTypes.DiligenciaOrganisation || this.actor?.actorType == EntityTypes.DiligenciaPerson) {
      show = false;
    }
    return show;
  }

  getFullAddress(address: string) {
    return (address ?? "");
  }

  getJurisdictionName(code: string) {
    return CountriesService.getJurisdictionName(code);
  }
  getNationalityName(code: string) {
    return CountriesService.getNationalityName(code);
  }

  // Required to be able to reference enums in the html template
  public EntityTypeEnums = EntityTypes;
  public dateObj: NgbDateStruct;
  public splitCSVList(data: string) {
    return data.split(',').map(item => item.trim());
  }

  public showMore: boolean = false;

  public hasCompanies() {
    return (this.actor?.mergedOfficerList?.length > 0);
  }

  public getFirstMergedOfficer(): IOfficerInfo {
    if (this.actor?.mergedOfficerList?.length > 0) {
      return this.actor?.mergedOfficerList[0];
    }
  }

  public onBadgeHover() {
    var rejectionReason = this.actor?.asUserProfile?.rejectionReason;
    if(rejectionReason != null) {
      let el: any = document.getElementById('idvStatus');
      if (el) {
        let tippyCtrl = el._tippy;
        if (!tippyCtrl) {
          tippy('#idvStatus', {
            allowHTML: true,
            content: rejectionReason
          });
        }
      }
    }
  }

  public statusCodeToClass(status: string): string {
    return IdvStatusCodeService.statusCodeToClass(status);
  }

  public splitFullAddress(data: string) {
    return data.split(',').map(item => item.trim());
  }
}
