import { Component, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NgbDateParserFormatter, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { Client, UserInteractionClient } from 'src/nswag';
import { IActorBase } from '../dashboard/models/Actor';
import { ScreeningService } from '../screening/services/screening.service';
import { MonitorService } from '../monitor/services/monitor.service';
import { CurrentModule } from '../monitor/client-report/client-report.component';
import { IntelligenceRequestComponent } from '../intelligence/intelligence-request/intelligence-request.component';

@Component({
  selector: 'app-investigation',
  templateUrl: './investigation.component.html',
  styleUrls: ['./investigation.component.scss']
})
export class InvestigationComponent {
  @Input() selectedClient: Client;
  @Input() currentModule: CurrentModule;
  @Input() selectedActor: IActorBase;

  public fullName: string;
  public dateOfBirth: string;
  public nationality: string;
  public gender: string;
  public indAddress: string;
  public isBusiness: boolean;
  public companyName: string;
  public companyNumber: string;
  public jurisdiction: string;
  public address: string;
  public information: string;
  public disableAll = false;
  public dateIsValid = true;

  public modal: NgbModalRef;
  
  constructor(public dashService: DashboardService, public screeningService: ScreeningService, public monitorService: MonitorService, private modalService: NgbModal, private alertService: AlertService, public userInteractionClient: UserInteractionClient, private dateFormatter: NgbDateParserFormatter) { }

  public investigateSubject() {
    const modalRef = this.modalService.open(IntelligenceRequestComponent, {
      backdrop: 'static',
      windowClass: 'contrast-modal'
    });

    modalRef.componentInstance.currentModule = this.currentModule;
    modalRef.componentInstance.selectedClient = this.selectedClient;
    modalRef.componentInstance.selectedActor = this.selectedActor;
  }
}
