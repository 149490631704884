import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AlertService } from 'src/app/_alert';
import { BusinessProfile, Evidence, FinCrimeCheckClient, MonitorType } from 'src/nswag';

@Component({
  selector: 'mon-evidences',
  templateUrl: './evidences.component.html',
  styleUrls: ['./evidences.component.scss']
})
export class EvidencesComponent implements OnChanges {

  @Input() clientId: string;
  @Input() profile: any;
  @Input() evidenceIds: string[];
  @Input() evidences: Evidence[];
  @Input() incHeader = true;
  @Input() allowDiscounting = true;
  @Input() reportMode: boolean = false;
  
  public evidenceList: Evidence[];
  public isLoading: boolean = false; 

  constructor(private finCrimeCheckClient: FinCrimeCheckClient, private alertService: AlertService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.incHeader) {
      this.allowDiscounting = false;
    }
    if (this.evidenceIds != null) {
      // filter the list
      this.evidenceList = new Array<Evidence>();
      for (let id of this.evidenceIds) {
        // Only include non-discounted links
        let evidence = this.evidences.find((v,i) => v.evidenceId == id && (v.discountReason == null || !v.discountReason.isDiscounted) );
        if (evidence) {
          this.evidenceList.push(evidence);
        }
      }
    }
    else {
      this.evidenceList = this.evidences;
    }

    this.evidenceList.sort((a,b) => {
      let adate = this.getDate(a) ?? "";
      let bdate = this.getDate(b) ?? "";
      return adate > bdate ? -1 : adate < bdate ? 1 : 0;
    });
  }

  isEvidence(evidence: Evidence) : boolean {
    return (evidence.discountReason == null || !evidence.discountReason.isDiscounted) && (evidence?.title?.length > 0 || evidence?.summary?.length > 0  || evidence?.originalUrl?.length > 0  || evidence?.assetUrl?.length > 0 );
  }

  getDate(evidence: Evidence): string {
    return evidence?.publicationDateIso ?? evidence?.captureDateIso;
  }

  getTitle(evidence: Evidence): string {
    return evidence?.title?.length > 0 ? evidence?.title : evidence?.originalUrl ;
  }

  checkSummary(evidence: Evidence): boolean {
    return evidence?.summary ? true : false;
  }

  getOriginalUrl(evidence: Evidence): string {
    if (evidence?.originalUrl == 'C6 Internal') {
      return null;
    }
    return evidence?.originalUrl;
  }

  async handlePDFEvidenceClick(evidence: Evidence): Promise<void> {
    try {
      const url = await this.getPdfEvidenceUrl(evidence);
      if (!url) throw new Error('No URL returned');

      const newTab = window.open(url, '_blank');
      if (!newTab) throw new Error('Failed to open new tab');
    
    } catch (error) {
      console.error('Error opening evidence URL:', error);
      this.alertService.error('There was an error retrieving the PDF document. Please try again or contact support if the problem persists');
    }
  }

  getPdfEvidenceUrl(evidence: Evidence): Promise<string> {
    return new Promise<string>((resolve, reject) => {      
      if (evidence.assetUrl) {
        resolve(evidence.assetUrl);
        return;
      }
  
      if (evidence.isCopyrighted) {
        this.isLoading = true;

        this.finCrimeCheckClient.getCopyrightedEvidence(this.profile instanceof BusinessProfile ? MonitorType.Businesses : MonitorType.Individuals, this.profile.resourceId, evidence.evidenceId).subscribe({
            next: (result) => {
              var url = null;
              if(result.data) {
                const byteCharacters = atob(result.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                url = URL.createObjectURL(blob); 
              }
              resolve(url);
            },
            error: (error) => {
              console.error('Error fetching evidence:', error);
              reject(error);
            }
          }).add(() => this.isLoading = false);
      } else {
        reject(new Error('Evidence URL not found.'));
      }
    });
  }
}
