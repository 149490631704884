import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailComponent } from './detail.component';
import { DetailLinksComponent } from './detail-links/detail-links.component';
import { NotMatchedComponent } from './not-matched/not-matched.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LampsPanelsModule } from '../lamps-panels/lamps-panels.module';
import { AlertIconsModule } from '../alert-icons/alter-icons.module';
import { InvestigationLinkerModule } from '../investigation-linker/investigation-linker.module';
import { InvestigationModule } from '../investigation/investigation.module';


@NgModule({
  declarations: [
    DetailComponent,
    DetailLinksComponent,
    NotMatchedComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    LampsPanelsModule,
    AlertIconsModule,
    InvestigationLinkerModule,
    InvestigationModule
  ],
  providers: [
  ],
  exports: [
    DetailComponent
  ]
})
export class ProfileDetailsModule { }
