import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { QuestionSetDetail, SelectedRecipientStatus } from 'src/nswag';
import { Model, StylesManager, SurveyNG } from 'survey-angular';
import { RiskAssessmentService } from '../../services/risk-assessment.service';

StylesManager.applyTheme('modern');

@Component({
  selector: 'app-view-recipient-answers',
  templateUrl: './view-recipient-answers.component.html',
  styleUrls: ['./view-recipient-answers.component.scss']
})
export class ViewRecipientAnswersComponent implements OnInit {

  constructor(private activeModal: NgbActiveModal, public riskAssessmentService: RiskAssessmentService, public alertService: AlertService) { }

  public currentAnsweredSetList: QuestionSetDetail[] = [];


  async ngOnInit() {
    let recipientIds = [];
    recipientIds.push(this.riskAssessmentService.CurrentRecipient.selectedRecipientId)
    this.riskAssessmentService.getResponseBySelectedRecipient(recipientIds).subscribe(res => {
      if (res.isSuccess) {
        if (res.data.results?.length > 0) {
          this.currentAnsweredSetList = res.data.results;
        }
      }
    });
  }

  public renderSurvey(model: any, index: number, answers: string) {
    const button = 'accButton' + index;
    const isExpanded = document.getElementById(button).getAttribute('aria-expanded');
    if (isExpanded == 'true') {
      const survey = new Model(model);
      survey.showProgressBar = 'top';
      survey.mode = 'display';
      survey.data = JSON.parse(answers);
      const name = 'collapse' + index;
      SurveyNG.render(name, { model: survey });
    }
  }

  public isInProgress(): boolean {
    return this.riskAssessmentService.CurrentRecipient?.status === SelectedRecipientStatus.InProgress 
  }

  private resetModelData() {
  }

  dismissModal() {
    this.activeModal.dismiss();
  }
}
