import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { ConfigurationClient, IdentityManagementClient, TenantManagementClient } from 'src/nswag';
import { AlertService } from '../_alert';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-api-admin',
  templateUrl: './api-admin.component.html',
  styleUrls: ['./api-admin.component.scss']
})
export class ApiAdminComponent implements OnInit {
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    scrollable: true
  };

  private tenantId: string;
  public show: boolean = false;
  public fieldType: string = 'password';
  public clientId: string;
  public secret: string;
  public apiSwaggerUrl: string;

  constructor(private configClient: ConfigurationClient, private modalService: NgbModal, private tenantManagementClient: TenantManagementClient, private alertService: AlertService, private identityManagementClient: IdentityManagementClient, private profileService: ProfileService) {}
  
  ngOnInit(): void {
    if (!this.profileService.screeningEnabled() && !this.profileService.monitoringEnabled()) {
      this.alertService.warn("No modules have been enabled to use the Web APIs", { autoClose: false, keepAfterRouteChange: false });
    }
    forkJoin({
      tenantDetails: this.tenantManagementClient.getTenantDetails(),
      config: this.configClient.getApiConfiguration(),
      clientDetails: this.identityManagementClient.getAPIKey()
    })
    .subscribe(res => {
      if (res.tenantDetails.isSuccess) {
        this.tenantId = String(res.tenantDetails.data.tenantId);
      }
      if (res.config.isSuccess) {
        this.apiSwaggerUrl = res.config.data.baseUrl
      }
      if (res.clientDetails.isSuccess) {
        this.clientId = res.clientDetails.data;
      }
    });
  }

  openModal(content: any) {
    this.modalService.open(content, { scrollable: true }).result.then((result) => {
      if (result) {
        this.refreshClientSecret(this.clientId);
        this.modalService.open(content, { scrollable: true });
      }
    }).catch((res) => { });
  }

  confirmModal(modal: any) {
    modal.close(true);
    this.clearSecret();
  }

  dismissModal() {
    this.modalService.dismissAll();
    this.clearSecret();
  }

  toggleHide() {
    if (this.show) {
      this.show = false;
      this.fieldType = 'password';
    } else {
      this.show = true;
      this.fieldType = 'text';
    }
  }

  refreshClientSecret(clientId: string) {
    this.identityManagementClient.generateNewToken(clientId.toString()).subscribe(
      response => {
        this.secret = response.data.clientSecret;
        this.alertService.success('Successfully refreshed API secret!');
      },
      error => {
        console.log('error', error);
        this.alertService.error('Something went wrong. Please try again later or contact our support team.');
      }
    );
  }

  clearSecret() {
    this.secret = null;
  }
}
