<div class="style-background border">
    <legend>Configure System Settings</legend>
    <hr class="divider">
    <div *ngIf="searchFeature || screenFeature || monitorFeature then systemFeatures"></div> 
    <button class="btn btn-primary" (click)="updateSettings()"  [disabled]="!fieldsAreDirty()">Save</button>
</div>

<ng-template #systemFeatures>
    <div class="card mb-5">
        <div class="card-header">
            <label class="card-title">Make Adding of Notes Mandatory</label>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-8">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [value]="makeNotesMandatory" [(ngModel)]="makeNotesMandatory">
                    <label class="form-check-label">Make the adding of notes mandatory</label>
                  </div>
                 </div>
            </div>
        </div>
    </div>
</ng-template>
