<div class="card shadow mb-3">
  <div class="row no-gutters">
    <div class="col-2 card-header bg-primary text-light py-4 card-radius-left-only advance-style">
      <div *ngIf="isMonitoring">
        <div class="row mt-0">
          <label>Action</label>
        </div>
        <div class="row">
          <div class="input-group display">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="open" name="status" value="1" [(ngModel)]="advancedQuery.isOpen" (change)="composeSearchString()" />
              <label class="form-check-label" for="open">Open</label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="closed" name="status" value="2" [(ngModel)]="advancedQuery.isClosed" (change)="composeSearchString()" />
              <label class="form-check-label" for="closed">Closed</label>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="[isMonitoring ? 'row pt-2' : 'row mt-0']">
        <label>Status</label>
        <div class="input-group display">
          <div class="form-check">
            <input [(ngModel)]="advancedQuery.hasSanctions" class="form-check-input" type="checkbox" id="sanctions" name="searchType" value="4" (change)="composeSearchString()" />
            <label class="form-check-label" for="sanctions">Sanctions</label>
          </div>
          <div class="form-check">
            <input [(ngModel)]="advancedQuery.hasPep" class="form-check-input" type="checkbox" id="pep" name="searchtype" value="3" (change)="composeSearchString()" />
            <label class="form-check-label" for="pep">Political Exposure</label>
          </div>
          <div class="form-check">
            <input [(ngModel)]="advancedQuery.hasLaw" class="form-check-input" type="checkbox" id="legal" name="searchtype" value="2" (change)="composeSearchString()" />
            <label class="form-check-label" for="legal">Law Enforcement</label>
          </div>
          <div class="form-check">
            <input [(ngModel)]="advancedQuery.hasAdvMedia" class="form-check-input" type="checkbox" id="adversemedia" name="searchtype" value="1" (change)="composeSearchString()" />
            <label class="form-check-label" for="adversemedia">Adverse Media</label>
          </div>
        </div>
      </div>
    </div>
    <div class="col no-padding">
      <div class="card-body card-radius-right-only">
        <div class="row advance-input">
          <div class="col p-0">
            <div class="mt-2">
              <label>Unique Client Reference ID</label>
            </div>
            <div class="input-group">
              <input type="text" class="form-control" id="uniqueRefAdSearch" [(ngModel)]="advancedQuery.clientRef" (change)="composeSearchString()" />
            </div>
          </div>
          <div class="col p-0">
            <div class="row mt-2">
              <label>Country</label>
            </div>
            <div class="row">
              <div class="input-group client-nationality pr-0 advance-nationality">
                <input [(ngModel)]="advancedQuery.countryCode" [ngbTypeahead]="countryTypeAhead" class="form-control" id="uniqueCountry" (change)="composeSearchString()" />
              </div>
            </div>
          </div>
          <div class="col p-0">
            <div class="row mt-2">
              <label class="p-0">Date of Birth</label>
            </div>
            <div class="row" id="uniqueAdSearchDate">
              <app-date-picker (dataEvent)='dateChanged($event)'></app-date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col p-0">
            <div class="mt-2">
              <label>Tags</label>
            </div>
            <div class="row">
              <div class="col p-0" id="uniqueAdSearchTag">
                <app-tags [(tagsInput)]="advancedQuery.tags" [editable]=false (change)="composeSearchString()"></app-tags>
              </div>
            </div>
          </div>
          <div class="col p-0">
            <div *ngIf="isMonitoring">
              <div class="row mt-2">
                <label>Risk Level</label>
              </div>
              <div class="row">
                <div class="input-group">
                  <select class="form-select form-control"  id="uniqueAdvSearchOption" [(ngModel)]="advancedQuery.riskLevel">
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col p-0">
            <button class="clearButton ms-3 btn btn-secondary add-client-tour" (click)="resetFilters()">Clear Filters</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
