<div *ngIf="inMonitoring(); else notInMonitoring" class="run-checked">
  <div class="runcheck">
    <button class="btn btn-primary btn-block mt-3" disabled>In monitoring</button>
  </div>
</div>  

<ng-template #notInMonitoring>
  <div *ngIf="canRunCheck(); else addToMonitoring" class="runcheck">
    <button class="btn btn-primary btn-block mt-3" (click)="runChecks()">Screen Client</button>
  </div>
</ng-template>

<ng-template #addToMonitoring>
  <div *ngIf="canAddToMonitoring() else moveToMonitoring" class="runcheck">
    <button class="btn btn-primary btn-block mt-3" (click)="addActorToMonitoring()">Add to Monitoring</button>
  </div>
</ng-template>

<ng-template #moveToMonitoring>
  <div *ngIf="canMoveToMonitoring()" class="runcheck">
    <button class="btn btn-primary btn-block mt-3" (click)="moveScreenedClientToMonitoring()">Move to Monitoring</button>
  </div>
</ng-template>
