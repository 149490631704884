import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class GTMService {

  constructor(private gtmService: GoogleTagManagerService) { }

  // use format const gtmTag = { event: "event name", data: "data to pass" };
  add(event: string, data: any): void {
    const gtmTag = {event: event, data: data};
    this.gtmService.pushTag(gtmTag);
  }
}