<div *ngIf="assessmentList.length > 0">
  <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
    <thead>
      <tr>
        <th scope="col">Assessment <span class="icon-question-circle ms-1" title="Tour Guide" [ngbTooltip]="showAssessToolTip"></span></th>
        <th scope="col">Description</th>
        <th scope="col">Date Created</th>
        <th scope="col">Date First Sent</th>
        <th scope="col">Author</th>
        <th scope="col" class="text-center">Status</th>
        <th scope="col" class="text-center">Action <span class="icon-question-circle ms-1" title="Tour Guide" [ngbTooltip]="showActToolTip"></span></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let assessment of assessmentList | paginate : {
          itemsPerPage: pageSize,
          currentPage: currentPage,
          totalItems: assessmentTotal
        };
        let i = index"
          (mouseenter)="setFocusedAssessment(assessment)" (mouseleave)="closeMenu()" (click)="manageRecipients($event)" class="align-middle">
  
        <td>{{displayAssessment(assessment)}}</td>
        <td>{{displayDescription(assessment)}}</td>
        <td>{{displayDateCreated(assessment)}}</td>
        <td>{{displayDateSent(assessment)}}</td>
        <td>{{displayAuthor(assessment)}}</td>
        <td class="text-center"><app-result-status-badge [assessment]="assessment"></app-result-status-badge></td>
        <td class="text-center verti">
          <div ngbDropdown class="d-inline-block mon" id="assessment-list" #menu="ngbDropdown">
            <div  class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle (click)="dropDownClicked($event)">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
              </svg>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <div ngbDropdownItem (click)="manageRecipients($event)">Manage Recipients</div>
              <hr class="divider">
              <div ngbDropdownItem (click)="manageQuestionSets($event)">Manage Question Sets</div>
              <hr class="divider">
              <div [disabled]="assessment?.sentOn !== undefined" ngbDropdownItem (click)="sendAssessment($event)">Send Assessment</div>
              <hr class="divider">
              <div [disabled]="assessment?.completedRecipients === 0"  ngbDropdownItem (click)="viewGroupReport($event)">View Group Report</div>
              <hr class="divider">
              <div ngbDropdownItem (click)="viewAuditHistory($event)">View Audit History</div>
              <hr class="divider">
              <div [disabled]="assessment?.sentOn !== undefined" ngbDropdownItem (click)="editAssessment($event)">Edit Assessment</div>
              <hr class="divider">
              <div ngbDropdownItem (click)="copyAssessment($event)">Copy Assessment</div>
              <hr class="divider">
              <div [disabled]="assessment?.sentOn !== undefined && assessment?.status !== 'Created' || !isAdministrator" ngbDropdownItem (click)="deleteAssessment($event)">Delete Assessment</div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  
  <pagination-controls class="pagination"
                       previousLabel="Prev"
                       nextLabel="Next"
                       [responsive]="true"
                       (pageChange)="handlePageChange($event)">
  </pagination-controls>
</div>
<span *ngIf="assessmentList.length === 0">
  There are no records to display
</span>
<!-- Items per Page:
  <select (change)="handlePageSizeChange($event)">
    <option *ngFor="let size of pageSizes" [ngValue]="size">
      {{ size }}
    </option>
  </select> -->


<!-- Templates for tour guide -->
<ng-template #showAssessToolTip>
  <app-assessment-tooltip [toolTipTemplate]="'assessToolTip'"></app-assessment-tooltip>
</ng-template>

<ng-template #showActToolTip>
  <app-assessment-tooltip [toolTipTemplate]="'actToolTip'"></app-assessment-tooltip>
</ng-template>
