import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { AuditList, Project } from 'src/nswag';
import { IntelligenceService } from '../services/intelligence.service';

@Component({
  selector: 'app-view-audit-trail:not(p)',
  templateUrl: './view-audit-trail.component.html',
  styleUrls: ['./view-audit-trail.component.scss']
})
export class ViewAuditTrailComponent implements OnInit {
  public audit: AuditList = new AuditList();
  public project = new Project();
  public currentPage = 1;
  public total: number;
  public pageSize = IntelligenceService.pageSize;

  constructor(private activeModal: NgbActiveModal, public intelService: IntelligenceService, public alertService: AlertService) { }

  ngOnInit(): void {
    this.load(this.currentPage);
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  private load(pageNumber: number) {
    this.intelService.getAuditTrail(this.project, pageNumber, this.pageSize).subscribe(rs => {
      this.audit = rs.data;
      this.currentPage = pageNumber;
      this.total = rs.data.totalCount;
    })

  }
  public handlePageChange(event: number): void {
    this.load(event);
  }

}
