import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AlertStatus, ClientResult, LAMPSStatus } from 'src/nswag';

@Component({
  selector: 'mon-alerticongroup',
  templateUrl: './alert-icon-group.component.html',
  styleUrls: ['./alert-icon-group.component.scss']
})
export class AlertIconGroupComponent implements OnChanges {
  @Input() client: ClientResult;
  @Input() removeTooltip: boolean = false;

  public newMatches: LAMPSStatus;
  public justMonitored: LAMPSStatus;
  public discounted: LAMPSStatus;
  public matchVoided: LAMPSStatus;
  public noLamps: LAMPSStatus;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.newMatches = this.client?.matchStatus?.matches == LAMPSStatus.Changed ? LAMPSStatus.Changed : LAMPSStatus.No;
    this.matchVoided = LAMPSStatus.No;
    this.discounted = LAMPSStatus.No;
    this.noLamps = LAMPSStatus.No
    if (this.client?.matchStatus?.matches == LAMPSStatus.No) {
      // If no matches (yet)
      this.client?.matchStatus?.isMatched == LAMPSStatus.Voided && this.client?.matchStatus?.alertStatus != AlertStatus.Open ? LAMPSStatus.Changed : LAMPSStatus.Yes;
      this.justMonitored = this.client?.matchStatus?.alertStatus == AlertStatus.Closed ? LAMPSStatus.Yes : LAMPSStatus.Changed;
    }
    else if (this.client?.matchStatus?.isMatched == LAMPSStatus.No) {
      this.justMonitored = LAMPSStatus.No;
    }
    else if (this.client?.matchStatus?.isMatched == LAMPSStatus.Discounted) {
      this.discounted = this.client?.matchStatus?.alertStatus == AlertStatus.Closed ? LAMPSStatus.Yes : LAMPSStatus.Changed;
      this.justMonitored = LAMPSStatus.No;
    }
    else if (this.client?.matchStatus?.isMatched == LAMPSStatus.Voided) {
      this.matchVoided = this.client?.matchStatus?.alertStatus == AlertStatus.Closed ? LAMPSStatus.Yes : LAMPSStatus.Changed;
      this.justMonitored = LAMPSStatus.No;
    }
    else if (this.client?.client?.screened &&
      this.client?.matchStatus?.hasAdvMedia == LAMPSStatus.No &&
      this.client?.matchStatus?.hasLawEnforcement == LAMPSStatus.No &&
      this.client?.matchStatus?.hasSanctions == LAMPSStatus.No &&
      this.client?.matchStatus?.isPEP == LAMPSStatus.No &&
      this.client?.matchStatus?.matches == LAMPSStatus.Yes) {
      // Open alert, matched with no lamps
      this.noLamps = LAMPSStatus.Yes;
      this.justMonitored = LAMPSStatus.No;
    }
    else if (this.client?.matchStatus?.alertStatus != AlertStatus.Closed &&
      this.client?.matchStatus?.hasAdvMedia != LAMPSStatus.Changed &&
      this.client?.matchStatus?.hasLawEnforcement != LAMPSStatus.Changed &&
      this.client?.matchStatus?.hasSanctions != LAMPSStatus.Changed &&
      this.client?.matchStatus?.isPEP != LAMPSStatus.Changed &&
      this.client?.matchStatus?.matches != LAMPSStatus.Changed) {
      // if re-opened
      this.justMonitored = LAMPSStatus.Changed;
    }
    else {
      this.justMonitored = LAMPSStatus.No;
    }
  }
}
