import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientResult } from 'src/nswag';
import { ClientEditorComponent } from '../clienteditor/clienteditor.component';

@Component({
  selector: 'mon-addclient',
  templateUrl: './addclient.component.html',
  styleUrls: ['./addclient.component.scss']
})
export class AddclientComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  @Output() saveClient = new EventEmitter<ClientResult>();

  ngOnInit(): void {
  }

  editClient(client: ClientResult, title?: string | undefined, buttonTitle?: string | undefined) {
    
    let modalRef = this.modalService.open(ClientEditorComponent, { backdrop : 'static', keyboard : false, scrollable : true});
    if (client) modalRef.componentInstance.Client = client;
    if (title) modalRef.componentInstance.title = title;
    if (buttonTitle) modalRef.componentInstance.buttonTitle = buttonTitle;

    modalRef.componentInstance.ClientChange.subscribe((result: ClientResult) => {
      this.saveClient.emit(result);
    });
  }

  addClient() {    
    this.editClient(null);
  }
}