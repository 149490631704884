import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseOfCheckCanRequestProfileModel, ApiResponseOfGuid, CheckCanRequestProfileQuery, RequestProfileCommand, UserInteractionClient } from 'src/nswag';

@Injectable({
  providedIn: 'root'
})
export class ProfileRequestService {

  constructor(private userInteractionClient: UserInteractionClient) { }

  public checkCredits(): Observable<ApiResponseOfCheckCanRequestProfileModel> {
    return this.userInteractionClient.checkProfileRequestCredits(new CheckCanRequestProfileQuery());
  }

  public submitRequest(command: RequestProfileCommand): Observable<ApiResponseOfGuid> {
    return this.userInteractionClient.requestProfile(command)
  }
}
