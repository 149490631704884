<div class="container">
  <div class="modal-header">
    <h4 class="col">Send Assessment</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row mt-2">
        <label>Email invitation message</label>
      </div>
      <div class="row">
        <div class="input-group">
          <textarea class="form-control" maxlength="500" rows="3" [(ngModel)]="assessmentRecipient.emailMessage"></textarea>
        </div>
      </div>
      <div class="row mt-2">
        <label>Welcome page message</label>
      </div>
      <div class="row">
        <div class="input-group">
          <textarea class="form-control" rows="3" maxlength="500" [(ngModel)]="assessmentRecipient.welcomeMessage"></textarea>
        </div>
      </div>
    </div>
    <div class="float-end p-2">
      <button class="btn m-1 bg-secondary text-light" (click)="dismissModal()">Cancel</button>
      <button class="btn m-1 bg-primary text-light" [disabled]="!canSend()" (click)="sendAssessmentRecipient()">Send</button>
    </div>
  </div>
</div>
