import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AddclientComponent } from 'src/app/monitor/manage/addclient/addclient.component';
import { ApiResponseOfBoolean, ApiResponseOfBusinessMatches, ApiResponseOfBusinessProfile, ApiResponseOfClientResult, MonitorType, GetScreeningClientExportQuery, IdvUserProfile, Address2, Address, RecoverClientCommand, DeleteClientCommand } from 'src/nswag';
import { ApiResponseOfClientAuditLogs, ApiResponseOfIndividualProfile, BusinessMatch, IndividualMatch } from 'src/nswag';
import { LAMPSTypes, ListClientsQuery, ScreeningClient, AlertStatus, ClientResult } from 'src/nswag';
import { BusinessProfile, IndividualProfile, ApiResponseOfIndividualMatches } from 'src/nswag';
import { UtilityService } from '../../utilitities/utilities';
import { ScreenClientDialogComponent } from '../screen-client-dialog/screen-client-dialog.component';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';
import { ErrorService } from 'src/app/services/error.service';

@Injectable({
  providedIn: 'root'
})

export class ScreeningService {
  public static pageSize = 10;

  constructor(private screeningClient: ScreeningClient, private modalService: NgbModal, private confirmationService: ConfirmationDialogService,
    private errorService: ErrorService
  ) {
    this.currentClientList = new Array<ClientResult>();
  }

  private currentClientList: ClientResult[];
  private currentClientListTotal: number;
  private currentClientListQuery: ListClientsQuery;
  private selectedClient: ClientResult;
  private selectedBusinessProfile: BusinessProfile;
  private selectedIndividualProfile: IndividualProfile;
  private hasClients = false;

  public get HasClients(): boolean {
    return this.hasClients;
  }

  public get ClientList(): ClientResult[] {
    return this.currentClientList;
  }

  public get ClientListTotal(): number {
    return this.currentClientListTotal ?? 0;
  }

  public get ClientListPage(): number {
    return this.currentClientListQuery?.pageNumber ?? 1;
  }

  public get SelectedClient(): ClientResult {
    return this.selectedClient;
  }

  public launchScreeningFromIDV(idvProfile: IdvUserProfile): Observable<ClientResult> {
    let ref = this.openScreeningModal();
    ref.componentInstance.screenName = idvProfile.fullName;
    ref.componentInstance.gender = idvProfile.gender;
    ref.componentInstance.nationality = UtilityService.getNationalityFromCode(idvProfile.country);
    ref.componentInstance.dateOfBirth = idvProfile.dateOfBirth?.toString();
    ref.componentInstance.address = this.validateAddress(idvProfile.address);
    return ref.componentInstance.addClientEvent;
  }

  private validateAddress(address: any): Address2 {
    if (!address) {
        return null;
    }
    if (address instanceof Address2 || 'line1' in address) {
        return address;
    }
    if (address instanceof Address || "addressLine1" in address || "fullAddress" in address) {
        return UtilityService.convertToAddress2(address);
    }
    return null;
  }    

  public launchScreening() {
    let ref = this.openScreeningModal();
    let event = ref.componentInstance.addClientEvent;
    event.subscribe(client => {
      this.ClientList.unshift(client);
      this.refreshSelectedClient(client);
      this.hasClients = true;
    });
  }

  // Introduced to deal with user clicking off the dialog
  private openScreeningModal(): NgbModalRef {
    let ref: NgbModalRef;
    let modalOptions: NgbModalOptions = {};
    modalOptions.backdrop = true;
    modalOptions.keyboard = true;
    modalOptions.beforeDismiss = () => { 
      if (!ref.componentInstance.hasScreeningRun) {
        return true;
      }
      this.confirmationService.confirm("Cancel Screening?", "Are you sure you want to cancel? Please note that any information found will not be recorded.", false, "Yes","No")
      .then((confirmed) => {
        if(confirmed == true) {
          ref.close();
        }
      })
      .catch((onRejected) => { /* modal closed */ });
      return false; 
    }
    ref = this.modalService.open(ScreenClientDialogComponent, modalOptions);
    return ref;

  }
  public checkClientExists(ref: string, type: MonitorType): Observable<ApiResponseOfBoolean> {
    return this.screeningClient.checkClientReferenceExists(ref, type);
  }

  public SetSelectedClient(client: ClientResult) {
    if (!client) {
      this.resetGlobals(true);
      return;
    }

    // We know we are in screening so set the flag each time
    client.client.inMonitoring = false;

    if (client.client.business) {
      this.getBusinessMatchedProfile(client).subscribe(r => {
        this.selectedClient = client;
        if (r.isSuccess) {
          this.selectedBusinessProfile = r.data;
          this.selectedIndividualProfile = null;
          this.refreshClientList(client);
        }
      });
    }
    else if (client.client.individual) {
      this.selectedClient = client;
      this.getIndividualMatchedProfile(client).subscribe(r => {
        if (r.isSuccess) {
          this.selectedIndividualProfile = r.data;
          this.selectedBusinessProfile = null;
          this.refreshClientList(client);
        }
      });
    }
  }

  public refreshClient() {
    if (this.selectedClient?.client) {
      this.screeningClient.getClientById(this.selectedClient.client.id).subscribe(result => {
        if (result.isSuccess) {
          this.SetSelectedClient(result.data);
        }
      });
    }
  }
  private refreshClientList(client: ClientResult) {
    if (this.currentClientList) {
      let i = this.currentClientList.findIndex(val => val.client.id == client.client.id);
      if (i > -1) {
        this.currentClientList[i] = client;
      }
    }
  }

  public get SelectedIndividualMatchedProfile(): IndividualProfile {
    return this.selectedIndividualProfile;
  }

  public set SelectedIndividualMatchedProfile(profile: IndividualProfile) {
    this.selectedIndividualProfile = profile;
  }

  public get SelectedBusinessMatchedProfile(): BusinessProfile {
    return this.selectedBusinessProfile;
  }

  public set SelectedBusinessMatchedProfile(profile: BusinessProfile) {
    this.selectedBusinessProfile = profile
  }

  public get SelectedProfile(): any {
    return this.selectedIndividualProfile ?? this.selectedBusinessProfile;
  }

  // loads all clients - used when screening component is first initialised
  public loadLAMPSClients(lampsType?: LAMPSTypes, alertStatus?: AlertStatus) {
     this.internalSearchClients(null, null, null, alertStatus, lampsType);
  }

  public loadArchivedClients() {
    this.currentClientListQuery = new ListClientsQuery({
      pageSize: ScreeningService.pageSize,
      pageNumber: 1,
      showArchived: true
    });
    this.internalSearchClientsWithUpdatedQuery();
 }

  public loadLAMPSClientsPage(pageNumber: number) {
    if (this.currentClientListQuery) {
      this.currentClientListQuery.pageNumber = pageNumber;
      this.internalSearchClientsWithUpdatedQuery();
    }
  }

  public searchForClients(query: ListClientsQuery) {
    let countryCode = null;
    if (query.countryCode) {
      var code = UtilityService.getCodeFromCountry(query.countryCode);
      countryCode = code == '' ? query.countryCode : code;
    }
    this.internalSearchClients(query.search, countryCode, query.dateOfBirth, null, null, query.hasAdvMedia, query.hasSanctions,
      query.hasLaw, query.hasPep, query.isOpen, query.isClosed, query.clientRef, query.tags, query.showArchived);
  }

  public MoveToMonitoring(client: ClientResult): Observable<ClientResult> {
    let event = new Observable<ClientResult>((observer: any) => {
      let editor = new AddclientComponent(this.modalService);
      editor.editClient(client, "Move Client to Monitoring", "Move to monitoring");
      editor.saveClient.subscribe(result => {
        this.addToMonitoring(result).subscribe(r => {
          this.modalService.dismissAll();
          if (r.isSuccess) {
            this.removeClient(client);
            r.data.client.inMonitoring = true;
            observer.next(r.data);
          }
          else {
            observer.next(null);
          }
        },
          (error) => {
            this.errorService.setProblemDetailsFromError(error);
            observer.next(null);
          });
      });
    });
    return event;
  }

  public deleteClient(client: ClientResult, notes: string): Observable<any> {
    return this.screeningClient.deleteClient(new DeleteClientCommand({ id: client.client.id, notes: notes}));
  }

  public getIndividualMatches(client: ClientResult = null, showLoader: boolean = false): Observable<ApiResponseOfIndividualMatches> {
    let id = client?.client?.id ?? this.selectedClient?.client?.id;
    let monitorId = client?.client?.monitorRecordId ?? this.selectedClient?.client?.monitorRecordId;
    return this.screeningClient.getIndividualMatches(id, monitorId);
  }

  public getIndividualMatchedProfile(client: ClientResult, showLoader: boolean = true): Observable<ApiResponseOfIndividualProfile> {
    return this.screeningClient.getIndividualMatchedProfile(client?.client?.id);
  }

  public getIndividualProfile(individual: IndividualMatch): Observable<ApiResponseOfIndividualProfile> {
    return this.screeningClient.getIndividualProfile(individual.resourceId);
  }

  public getBusinessMatches(client: ClientResult = null, showLoader = false): Observable<ApiResponseOfBusinessMatches> {
    let id = client?.client?.id ?? this.selectedClient?.client?.id;
    let monitorId = client?.client?.monitorRecordId ?? this.selectedClient?.client?.monitorRecordId;
    return this.screeningClient.getBusinessMatches(id, monitorId);
  }

  public getBusinessMatchedProfile(client: ClientResult, showLoader: boolean = true): Observable<ApiResponseOfBusinessProfile> {
    return this.screeningClient.getBusinessMatchedProfile(client?.client?.id);
  }

  public getBusinessProfile(business: BusinessMatch): Observable<ApiResponseOfBusinessProfile> {
    return this.screeningClient.getBusinessProfile(business.resourceId);
  }

  public getClientAuditLogs(clientId: string): Observable<ApiResponseOfClientAuditLogs> {
    return this.screeningClient.getClientLogs(clientId);
  }

  public refreshSelectedClient(client: ClientResult) {
    this.SetSelectedClient(client);
  }

  public addToMonitoring(client: ClientResult): Observable<ApiResponseOfClientResult> {
    return this.screeningClient.addToMonitoring(client);
  }

  public removeClient(client: ClientResult) {
    var index = this.ClientList.indexOf(client, 0);
    if (index > -1) {
      this.ClientList.splice(index, 1);
    }
    this.SetSelectedClient(null);
  }

  public recoverClient(client: ClientResult, notes: string): Observable<any> {
    return this.screeningClient.recoverClient(new RecoverClientCommand({clientId: client.client.id, notes: notes}));
  }

  public exportClientList = (showArchived: boolean) => {
    var query = new GetScreeningClientExportQuery({
      listScreenedClientsQuery: this.currentClientListQuery ?? new ListClientsQuery(),
      limit: 10000,
    });

    query.listScreenedClientsQuery.showArchived = showArchived;    

    return this.screeningClient.getMatchedClientExport(query);
  }

  private internalSearchClients(search?: string | null | undefined, countryCode?: string | null | undefined, dateOfBirth?: Date | null | undefined, alertStatus?: AlertStatus | null | undefined, lampsType?: LAMPSTypes | null | undefined, hasAdvMedia?: boolean | undefined, hasSanctions?: boolean | undefined, hasLaw?: boolean | undefined, hasPep?: boolean | undefined, isOpen?: boolean | undefined, isClosed?: boolean | undefined, clientRef?: string | null | undefined, tags?: string[] | null, showArchived?: boolean | undefined, pageNumber = 1) {
    this.resetGlobals(false);
    this.currentClientListQuery = new ListClientsQuery({
      search: search,
      hasAdvMedia: hasAdvMedia,
      hasLaw: hasLaw,
      hasSanctions: hasSanctions,
      hasPep: hasPep,
      alertStatus: alertStatus,
      isOpen: isOpen,
      isClosed: isClosed,
      countryCode: countryCode,
      dateOfBirth: dateOfBirth,
      clientRef: clientRef,
      tags: tags,
      pageSize: ScreeningService.pageSize,
      pageNumber: pageNumber,
      showArchived: showArchived
    });
    this.internalSearchClientsWithUpdatedQuery();
  }

  private internalSearchClientsWithUpdatedQuery() {
    if (this.currentClientListQuery) {
      var obs = this.screeningClient.list(this.currentClientListQuery);
      obs.subscribe(r => {
        if (r.isSuccess) {
          this.currentClientList = r.data.results;
          this.currentClientListTotal = r.data.totalCount;
          this.hasClients = this.currentClientListTotal > 0;
        }
      });
    }
  }

  public resetGlobals(justSelected: boolean) {
    if (!justSelected) {
      this.currentClientList = [];
      this.currentClientListQuery = null;
    }
    this.selectedClient = null;
    this.selectedBusinessProfile = null;
    this.selectedIndividualProfile = null;
  }
}
