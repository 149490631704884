import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { DiligenciaRelationshipType, DueDiligenceClient, EntityTypes, OrgRelationships, PersonRelationships } from 'src/nswag';
import { NodeExpansionComponent } from './node-expansion.component';
import { IActorBase, edgeType } from '../../models/Actor';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';

// Wrapper class for use by the NodeExpansionComponent to hold on to the
// list of found actors and their edge type (relationship to the originating source actor)
export class NodeExpandActor {
  constructor(public actor: IActorBase, public edgeType: edgeType) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class NodeExpansionService {
  constructor(public dashService: DashboardService, private modalService: NgbModal, private confirmService: ConfirmationDialogService, private ddClient: DueDiligenceClient) { }

  public graphNodeLimit: number = 200;

  public expandNode(sourceActor: IActorBase): Observable<boolean> {
    return new Observable<boolean>((observer: any) => {
      this.findActorRelationships(sourceActor).subscribe(expandActorList => {
        observer.next(true);
        if (expandActorList?.length == 0) {
          // No relationships found
          sourceActor.isExpanded = true;
          let message = "No other relationships have been found. You can add more companies and individuals by clicking on the risk map and selecting 'Search' from the menu.";
          if (sourceActor.profileId) {
            // We have a profile associated
            let profile = this.dashService.profileLoader(sourceActor)?.value;
            if (profile?.businessLinks || profile?.individualLinks) {
              // There are associations
              if (sourceActor.id == this.dashService.getInvestigation().id) {
                message = "Other associated individuals or companies may be found on the right-hand panel in the 'Associated Profiles' tab. Additional companies and individuals can be added to the investigation by clicking on the risk map and selecting 'Search' from the menu.";
              }
              else {
                message = `Other associated individuals or companies may be found by selecting '${sourceActor.name}' on the risk map and checking the 'Associated Profiles' tab on the right-hand panel. Additional companies and individuals can be added to the investigation by clicking on the risk map and selecting 'Search' from the menu.`;
              }
            }
          }
          else if (!sourceActor.clientId && !sourceActor.hasLAMPS) {
            // Offer up screening button
            if (sourceActor.id == this.dashService.getInvestigation().id) {
              message = "Other associated individuals or companies may be found by clicking the 'Screen Client' button and checking the 'Associated Profiles' tab on the right-hand panel. Additional companies and individuals can be added to the investigation by clicking on the risk map and selecting 'Search' from the menu.";
            }
            else {
              message = `Other associated individuals or companies may be found by selecting '${sourceActor.name}' on the risk map, clicking 'Screen Client' and checking the 'Associated Profiles' tab on the right-hand panel. Additional companies and individuals can be added to the investigation by clicking on the risk map and selecting 'Search' from the menu.`;
            }
          }
          this.confirmService.confirm("No relationships found", message, false, null, "Cancel", "lg", false, "alert alert-info");
        }
        else {
          const modalRef = this.modalService.open(NodeExpansionComponent, {
            scrollable: true, size: 'lg',
            container: '#dialogueContainer'
          });
          modalRef.componentInstance.expandActorList = expandActorList;
          modalRef.componentInstance.sourceActor = sourceActor;
        }
      });
    });
  }

  private findActorRelationships(sourceActor: IActorBase): Observable<NodeExpandActor[]> {
    return new Observable<NodeExpandActor[]>((observer: any) => {
      let actorList: NodeExpandActor[] = [];
      let self = this;
      // Need to cater for merged actors
      let countTotal = sourceActor.mergedActorList.length;
      let counter = 0;
      sourceActor.findRelationships(this.ddClient, this.dashService,
        function (source: IActorBase, target: IActorBase, type: edgeType) {
          self.addToList(sourceActor, target, type, actorList);
        },
        function () {
          if (counter == countTotal) {
            observer.next(actorList);
          }
          else {
            counter++;
          }
        });
    });
  }

  public diligenciaOrgRelationshipsToEdgeDesc(relationships: OrgRelationships): string {
    var edgeDesc = "";
    if (relationships?.types?.length > 0) {
      for (let type of relationships.types) {
        // relationship type header
        edgeDesc += `${type}|`;
        switch (type) {
          case DiligenciaRelationshipType.Holding: {
            if (relationships?.holdingsInfo?.length > 0) {
              for (let holding of relationships.holdingsInfo) {
                edgeDesc += `Shares: ${holding.shares ?? " N/A"}. Value: ${holding.value ?? "N/A"} (${holding.status})|`;
              }
            }
            break;
          }
          case DiligenciaRelationshipType.Management: {
            if (relationships?.managementInfo?.length > 0) {
              for (let management of relationships.managementInfo) {
                edgeDesc += `${management.position} - ${management.positionType} (${management.status})|`;
              }
            }
            break;
          }
          case DiligenciaRelationshipType.Ownership: {
            if (relationships?.ownersInfo?.length > 0) {
              for (let owner of relationships.ownersInfo) {
                edgeDesc += `Shares: ${owner?.shares ?? " N/A"}. Value: ${owner?.value ?? " N/A"} (${owner.status})|`;
              }
            }
            break;
          }
        }
      }
    }
    return edgeDesc;
  }

  public diligenciaPersonRelationshipsToEdgeDesc(relationships: PersonRelationships): string {
    var edgeDesc = "";
    if (relationships?.types?.length > 0) {
      for (let type of relationships.types) {
        // relationship type header
        edgeDesc += `${type}|`;
        switch (type) {
          case DiligenciaRelationshipType.Holding: {
            if (relationships?.holdingsInfo?.length > 0) {
              for (let holding of relationships.holdingsInfo) {
                edgeDesc += `Shares: ${holding.shares ?? " N/A"}. Value: ${holding.value ?? "N/A"} (${holding.status})|`;
              }
            }
            break;
          }
          case DiligenciaRelationshipType.Management: {
            if (relationships?.managementInfo?.length > 0) {
              for (let management of relationships.managementInfo) {
                edgeDesc += `${management.position} - ${management.positionType} (${management.status})|`;
              }
            }
            break;
          }
        }
      }
    }
    return edgeDesc;
  }

  private addToList(sourceActor: IActorBase, newActor: IActorBase, type: edgeType, actorList: NodeExpandActor[]) {
    if ((sourceActor.id == newActor.id)) {
      if (newActor.actorType == EntityTypes.OfficerCompany) {
        // We need to see if the company associated with the actor is on the map
        let coId = newActor.idsanitizer(newActor.asOfficerInfo.companyId);

        if (this.dashService.findRemovedNode(coId)) {
          return;
        }
        if (this.dashService.hiddenNodesList.find(i => i == coId)) {
          return;
        }
        let exists = this.dashService.findNode(coId) != null;
        if (exists) {
          this.dashService.addActorEdge(sourceActor, newActor, type);
          return;
        }
        actorList.push(new NodeExpandActor(newActor, type));
      }
      return;
    }
    if (actorList.find(l => l.actor.id == newActor.id)) {
      return;
    }
    if (this.dashService.excludeInactive && !newActor.isActive) {
      return;
    }
    if (this.dashService.findRemovedNode(newActor.id)) {
      return;
    }
    if (this.dashService.hiddenNodesList.find(i => i == newActor.id)) {
      return;
    }
    let exists = this.dashService.findNode(newActor.id) != null;
    if (exists) {
      if (sourceActor.actorType === EntityTypes.DiligenciaPerson || EntityTypes.DiligenciaOrganisation) {
        let existingActor = this.dashService.getActorByNodeId(newActor.id);
        let edgeDesc = "";
        let newId = newActor?.asDiligenciaPersonInfo?.id ?? newActor?.asDiligenciaOrgInfo?.id;
        switch (sourceActor.actorType) {
          case EntityTypes.DiligenciaPerson:
            edgeDesc = this.diligenciaPersonRelationshipsToEdgeDesc(sourceActor.asDiligenciaPersonInfo?.relationships?.find(rel => rel.diligenciaSubjectId == newId.toString()));
            break;
          case EntityTypes.DiligenciaOrganisation:
            edgeDesc = this.diligenciaOrgRelationshipsToEdgeDesc(sourceActor.asDiligenciaOrgInfo?.relationships?.find(rel => rel.diligenciaSubjectId == newId.toString()));
            break;
        }
        this.dashService.addActorEdge(sourceActor, existingActor, type, edgeDesc);
      } else {
        this.dashService.addActorEdge(sourceActor, newActor, type);
      }
      return;
    }
    actorList.push(new NodeExpandActor(newActor, type));
  }
}
