import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ApiResponseOfProject, Project, ProjectList, TenantProjectDetails, TenantProjectDetailsList, UploadCommand, UploadFileMetadata } from 'src/nswag';
import { IntelligenceService } from '../../services/intelligence.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadReportComponent } from '../upload-report/upload-report.component';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'int-admin-list',
  templateUrl: './intelligence-admin-list.component.html',
  styleUrls: ['./intelligence-admin-list.component.scss']
})
export class IntelligenceAdminListComponent implements OnChanges {

  public selectedRowIndex: number;
  @Input() currentPage = 1;
  @Input() clientList : TenantProjectDetailsList[];
  @Input() clientTotal = 0;
  @Output() selectEvent = new EventEmitter<TenantProjectDetails>();
  @Output() changePageEvent = new EventEmitter<number>();
  @Output() viewAuditHistoryEvent = new EventEmitter<Project>();
  @Output() showEditDialogEvent = new EventEmitter<Project>();

  public pageSize = 10;
  public selectedUsersProjects: ProjectList;
  public isLoading = false;
  public isReport: boolean;

  constructor(public intelService: IntelligenceService, private modalService: NgbModal) { 
  }

  ngOnChanges(changes: SimpleChanges): void {
    // When project count changes, collapse list
    if (changes?.clientList?.previousValue){
      // Initialize project counts
      let pCount = 0;
      let cCount = 0;

      // Safely retrieve previous and current tenant projects, defaulting to empty arrays if undefined
      const previousTenantProjects = changes?.clientList?.previousValue ?? [];
      const currentTenantProjects = changes?.clientList?.currentValue ?? [];

      // Map project counts and reduce to total
      pCount = previousTenantProjects.map((x: { projectCount: any; }) => x.projectCount).reduce((total: any, num: any) => total + num, 0);
      cCount = currentTenantProjects.map((x: { projectCount: any; }) => x.projectCount).reduce((total: any, num: any) => total + num, 0);

      // Determine if the list should be collapsed
      if (pCount !== cCount){
        this.selectedRowIndex = null;
      }
    }
  }
  
  public uploadDocument(isReport: boolean) {
    this.isReport = isReport;
    const model = this.modalService.open(UploadReportComponent, { backdrop : 'static', keyboard : false, scrollable : true });
    model.componentInstance.isSuccessfullCallback = this.importSuccessful;
    model.componentInstance.uploadDocumentCallback = this.importDocument;
    model.componentInstance.isLoadingCallback = this.setIsLoading;
    model.componentInstance.title = isReport ? 'Report' : 'Proposal';
    model.result.then(val => model.close()).catch((onRejected) => { /* modal closed */ });
  }

  public importSuccessful = (): void => {
    this.intelService.getUsersProjects(this.intelService.CurrentTenantProject.userId, 1, 100);
  }

  public importDocument = (file: UploadFileMetadata): Observable<ApiResponseOfProject> => {
    let cmd = new UploadCommand({
      file: file
    });

    if (this.isReport){
      return this.intelService.uploadReport(cmd);
    }
    else{
      return this.intelService.uploadProposal(cmd);
    }
  }

  public setIsLoading = (loading: boolean): void => {
    this.isLoading = loading;
  }

  public viewAudit(event: Project) {
    this.viewAuditHistoryEvent.emit(event)
  }
  
  public setFocusedProject(project: Project) {
    this.intelService.setCurrentProject(project);
  }

  public showEditDialog(event: Project){
    this.showEditDialogEvent.emit(event)
  }

  public toggleRowDetails(id: number, row: TenantProjectDetails) {
    if (id == this.selectedRowIndex) {
      this.selectedRowIndex = null;
    } 
    else {
      // load details
     this.displayUsersProjects(row.userId);
     this.intelService.setCurrentTenantProject(row);
     this.selectedRowIndex = id;
    }
  }
  
  public getDate(date?: Date): string {
    if (date) {
      return UtilityService.convertToLocalDateTime(date, "long")
    }
  }

  private displayUsersProjects(userId: string) {
    this.intelService.getUsersProjects(userId, 1, 100);
  }

  public handlePageChange(event: number): void {
    this.currentPage = event;
    this.changePageEvent.emit(event);
  }

  // Displays company or person's name
  public displayCompanyName(tenantProjectDetails: TenantProjectDetails) : string {
    return tenantProjectDetails.companyName;
  }

  public displayAccount(tenantProjectDetails: TenantProjectDetails) : string {
    return tenantProjectDetails.fullName;
  }

  public displayProjectNumber(tenantProjectDetails: TenantProjectDetails) : number {
    return tenantProjectDetails.projectCount;
  }
  
  public displayTenantDescription(tenantProjectDetails: TenantProjectDetails) : string {
    return tenantProjectDetails.description;
  }
  
  public dropDownClicked(event: { stopPropagation: () => void; }) {
    event.stopPropagation();
  }

}
