import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utilitities/utilities';
import { Log } from 'src/nswag';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss']
})
export class AuditListComponent implements OnInit {

  @Input() auditLogs: Log[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  getDate(date: Date): string {
    return UtilityService.convertToLocalDateTime(new Date(date), "long");
  }

}
