import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Project, ProjectList } from 'src/nswag';
import { IntelligenceService } from '../services/intelligence.service';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'int-list',
  templateUrl: './intelligence-list.component.html',
  styleUrls: ['./intelligence-list.component.scss']
})
export class IntelligenceListComponent {

  public selectedRowIndex: number;
  @Input() currentPage = 1;
  @Input() projectList : Project[];
  @Input() projectTotal = 0
  @Output() changePageEvent = new EventEmitter<number>();
  @Output() viewAuditHistoryEventEvent = new EventEmitter<Project>();
  @Output() downloadReportEvent = new EventEmitter<string>();

  public pageSize = IntelligenceService.pageSize;
  public selectedUsersProjects: ProjectList;
  public isLoading = false;

  constructor(public intelService: IntelligenceService) { 
  }

  public setIsLoading = (loading: boolean): void => {
    this.isLoading = loading;
  }
  
  public setFocusedProject(project: Project) {
    this.intelService.setCurrentProject(project);
  }

  
  public getDate(date?: Date): string {
    if (date) {
      return UtilityService.convertToLocalDateTime(date, "long");
    }
  }

  public viewAudit(event: Project) {
    this.viewAuditHistoryEventEvent.emit(event)
  }
  
  public downloadReport(event: string){
    this.downloadReportEvent.emit(event);
  }

  public handlePageChange(event: number): void {
    this.currentPage = event;
    this.changePageEvent.emit(event);
  }
  
  public dropDownClicked(event) {
    event.stopPropagation();
  }

}
