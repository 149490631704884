import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/_alert';
import { Project, ProjectStatus, UpdateProjectCommand } from 'src/nswag';
import { IntelligenceService } from '../../services/intelligence.service';

// optional: Record type annotation guaranties that 
// all the values from the enum are presented in the mapping
export const StatusRangesLabelMapping: Record<ProjectStatus, string> = {
  [ProjectStatus.Requested]: 'Requested',
  [ProjectStatus.Proposed]: 'Proposed',
  [ProjectStatus.Accepted]: "Accepted",
  [ProjectStatus.Cancelled]: "Cancelled",
  [ProjectStatus.In_Progress]: "In Progress",
  [ProjectStatus.Completed]: "Completed"
};

@Component({
  selector: 'edit-project-dialog',
  templateUrl: './edit-project-dialog.component.html',
  styleUrls: ['./edit-project-dialog.component.scss']
})

export class EditProjectDialogComponent implements OnInit {
  public statusRanges = Object.values(ProjectStatus);
  public statusRangesLabelMapping = StatusRangesLabelMapping;
  public statusRange: ProjectStatus;

  public project = new UpdateProjectCommand();
  public originalProject: Project;

  public isSaving = false;

  constructor(private activeModal: NgbActiveModal, public intelService: IntelligenceService, public alertService: AlertService) { }

  ngOnInit(): void {
    if (Object.keys(this.originalProject).length !== 0) {
      this.project = Object.assign({}, this.originalProject);
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  canUpdate(): boolean {
    if (this.isSaving) return false;
    if (!this.isProjectNameValid()) return false;
    if (this.project.name.trim() == this.originalProject.name.trim() && this.project.status == this.originalProject.status) return false;
    return true;
  }

  private isProjectNameValid(): boolean {
    return this.project?.name && this.project?.name?.trim()?.length > 1;
  }

  updateProject() {
    this.isSaving = true;

    const error = (error) => {
      this.alertService.error('Unfortunately, something has gone wrong. Please try again or contact support.', { autoClose: true });
      this.isSaving = false;
      console.error(error);
    };

    this.intelService.updateProject(this.project).subscribe(result => {
      this.isSaving = false;
      if (result.isSuccess) {
        this.intelService.refreshProjectList(result.data);
        this.alertService.success('Project updated successfully', { autoClose: true });
        this.dismissModal();
      }
    }, error);
  }
}
