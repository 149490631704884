import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'heatmap-key-dialog',
  templateUrl: './heatmap-key-dialog.component.html',
  styleUrls: ['./heatmap-key-dialog.component.scss']
})
export class HeatmapKeyDialogComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  dismissModal() {
    this.activeModal.dismiss();
  }
}
