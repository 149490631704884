import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Evidence, RreEntry } from 'src/nswag';

@Component({
  selector: 'mon-adversemedia',
  templateUrl: './adversemedia.component.html',
  styleUrls: ['./adversemedia.component.scss']
})
export class AdversemediaComponent implements OnChanges {

  // Assumption that it is aa business or individual profile
  @Input() profile: any;
  @Input() clientId: string;
  @Input() name: string;
  @Input() incHeader = true;
  @Input() allowDiscounting = true;
  @Input() reportMode: boolean = false;

  public rreEntries: RreEntry[];
  public evidences: Evidence[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.evidences = this.profile?.evidences;
    this.rreEntries = this.profile?.rreEntries;
  }

  public checkCategory(entry: RreEntry) : boolean {
    return entry?.events?.length > 0;
  }

  public getCategory(entry: RreEntry) : string {
    return entry?.subcategory?.length > 0 ? entry?.subcategory : ( entry?.category?.length > 0 ? entry.category : "Uncategorised");
  }

  public showEntry() : boolean {
    const checkEmptyEntries = this.rreEntries?.filter(x => x?.events?.length === 0);
    return checkEmptyEntries?.length === this.rreEntries?.length ? true : false;
  }
}
