import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ApiResponseOfString, ConfigurationClient, CreatePaymentSessionCommand, GetUserPreferencesQuery, SelfServeClient, UpdateUserPreferencesCommand, } from 'src/nswag';
import { AdvertDialogComponent } from '../advert-dialog/advert-dialog.component';


@Injectable({
  providedIn: 'root'
})

export class SelfServiceService {

  public screeningCredits: number = 0;
  public doNotAskMeAgain: boolean = false;
  private selfServeUser: boolean;
  private viewedTourGuide: boolean;
  private stripe = null;
  
  constructor(private modalService: NgbModal, private selfServeClient: SelfServeClient, public router: Router, private configClient: ConfigurationClient) {
  }

  public get ViewedTourGuide(): boolean {
    return this.viewedTourGuide;
  }

  public get SelfServeUser(): boolean {
    return this.selfServeUser;
  }

  public SetViewedTourGuide(value: boolean){
    this.viewedTourGuide = value;
  }

  public SetSelfServeUser(value: boolean) {
    this.selfServeUser = value;
  }
  public openAdvertDialog(advertCriteria: string) {
    const modalRef = this.modalService.open(AdvertDialogComponent, { backdrop : 'static', keyboard : false, scrollable : true, size: 'lg'});
    modalRef.componentInstance.advertCriteria = advertCriteria;
  }

  public getCredits(){
    this.selfServeClient.getRemainingCredits().subscribe(result => {
      if (result.isSuccess){
        this.screeningCredits = !result.data || result.data?.screening < 0 ? 0 : result.data?.screening;
      }
    })
  }

  public initalisePayment(): void{
    if (!this.stripe){
      this.configClient.getPaymentConfiguration().subscribe(result => {
        if (result.isSuccess){
          this.stripe = result.data.stripePublicKey;
        }
      })
    }
  }

  public getStripeKey(): string {
    return this.stripe;
  }
  public createPaymentSession(session: CreatePaymentSessionCommand){
    return this.selfServeClient.createPaymentSession(session);
  }

  public completePaymentSession(sessionId: string){
    return this.selfServeClient.completePaymentSession(sessionId);
  }

  public updateUserPreferences(value: boolean){
    var command = new UpdateUserPreferencesCommand();
    command.dontAskMeAgain = value;
    return this.selfServeClient.updateUserPreferences(command).subscribe(result => {
      if (result.isSuccess){
        this.doNotAskMeAgain = result.data.doNotAskAgain;
      }
    });
  }

  public getUserPreferences(){
    this.selfServeClient.getUserPreferences(new GetUserPreferencesQuery).subscribe(result => {
      if (result.isSuccess){
        this.doNotAskMeAgain = !result.data ? false : result.data.doNotAskAgain;
      }
    });
  }
}
