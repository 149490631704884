import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ISubjectInfo} from 'src/nswag'
import { DashboardService } from '../services/dashboard.service';
import { IActorBase } from '../dashboard/models/Actor';
import { SearchService } from './services/search.service';
import { ShepherdService } from 'angular-shepherd';
import { steps as defaultSteps, defaultStepOptions} from '../search/data';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnDestroy {

  constructor(public searchService: SearchService, public dashService: DashboardService, public router: Router, private guideTour: ShepherdService) {}

  ngOnDestroy() {
    this.guideTour = null;
  }

  runSearch() {
    this.searchService.runSearch(this.searchService.searchCriteria, this.searchService.searchResultState);
  }

  onResult(result: ISubjectInfo) {
    this.dashService.setSelectedSubject(result);
    this.router.navigate(['search/dashboard']);
  }

  createDummyAndRedirect() {
    var dummy: IActorBase = this.searchService.searchResultState.CreateDummyActor(this.searchService.searchCriteria);
    this.dashService.setSelectedDummyActor(dummy);
    this.router.navigate(['search/dashboard']);
  }

  start() {
    this.guideTour.defaultStepOptions = defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    this.guideTour.addSteps(defaultSteps);
    this.guideTour.start();
  }
}
