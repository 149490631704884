import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Evidence, SanEntries } from 'src/nswag';

@Component({
  selector: 'mon-sanctions',
  templateUrl: './sanctions.component.html',
  styleUrls: ['./sanctions.component.scss']
})
export class SanctionsComponent implements OnChanges {

  // Assumption that it is aa business or individual profile
  @Input() profile: any;
  @Input() clientId: string;
  @Input() name: string;
  @Input() incHeader = true;
  @Input() allowDiscounting = true;
  @Input() reportMode: boolean = false;

  public hasSanctions : boolean;
  public hasCurrent : boolean;
  public hasPrevious : boolean;

  public sanEntries: SanEntries;
  public evidences: Evidence[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.evidences = this.profile?.evidences;
    this.sanEntries = this.profile?.sanEntries;
    this.hasCurrent = this.sanEntries?.current?.length > 0;
    this.hasPrevious = this.sanEntries?.former?.length > 0;
    this.hasSanctions = this.hasCurrent || this.hasPrevious;
  }


  public getAsStringList(types: string[]) : string {
    var response: string = "";
    types?.forEach((e,i) => {
      if (i > 0) response+=", ";
      response += e;
    });
    return response;
  }
}
