import { Component, OnInit } from '@angular/core';
import { IntelligenceService } from '../services/intelligence.service';
import { EditProjectDialogComponent } from './edit-project-dialog/edit-project-dialog.component';
import { Project } from 'src/nswag';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewAuditTrailComponent } from '../view-audit-trail/view-audit-trail.component';

@Component({
  selector: 'app-intelligence-admin',
  templateUrl: './intelligence-admin.component.html',
  styleUrls: ['./intelligence-admin.component.scss']
})
export class IntelligenceAdminComponent implements OnInit {
  constructor(public intelligenceAdminService: IntelligenceService, private modalService: NgbModal) {}
  
  ngOnInit(): void {
    this.intelligenceAdminService.loadTenantProjectPage(1);
  }

  public changePage(page: number) {
    this.intelligenceAdminService.loadTenantProjectPage(page);
  }

  public showEditDialog(project: Project){
    const model = this.modalService.open(EditProjectDialogComponent,  { scrollable: true });
    model.componentInstance.originalProject = project;
  }

  public viewAudit(project: Project) {
    const model = this.modalService.open(ViewAuditTrailComponent, { size: 'lg', scrollable: true });
    model.componentInstance.project = project;
  }

  
}
