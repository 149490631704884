import { ISubjectInfo, SharedModelsDiligenciaName } from 'src/nswag';

export class SubjectRow {
    subjectInfo: ISubjectInfo;
    name: string;
    isActive: string = "";
    jurisdiction: string;
}

export class PersonRow extends SubjectRow {
    companyOp: string;
    position: string;
    nationality: string;
    dateOfBirth: string;
}

export class DiligenciaPersonRow extends SubjectRow {
    genderDto: SharedModelsDiligenciaName;
    countryOfResidenceDto: SharedModelsDiligenciaName;
    nationalityDto: SharedModelsDiligenciaName;
    hasRulingFamilyConnection: boolean;
    dateOfDeath: string;
    dateOfBirth: string;
    nameDto: SharedModelsDiligenciaName;
}

export class DiligenciaOrgRow extends SubjectRow {
    registrationNumber: string;
    sectorsDto: SharedModelsDiligenciaName[];
    cityOfRegistrationDto: SharedModelsDiligenciaName;
    countryOfRegistrationDto: SharedModelsDiligenciaName;
    licenseNumber: string;
    isStateOwned: boolean;
    nameDto: SharedModelsDiligenciaName;
    statusDto: SharedModelsDiligenciaName;
}


export class CompanyRow extends SubjectRow {
    address: string;
}
