import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { CreateAccountCommand, GetAccountByIdModel, ListAccountsModel, TenantManagementClient } from 'src/nswag';
import { UserManagementService } from './../../services/user-management.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {
  constructor(private userManagementService: UserManagementService, private router: Router, private tenantManagementClient: TenantManagementClient, private alertService: AlertService, private route: ActivatedRoute,
    private errorService: ErrorService  ) { }

  public tenantId: string;
  users: ListAccountsModel[];
  selectedUser: GetAccountByIdModel;
  filterText: string;

  alertServiceOptions = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.tenantId = params.tenantId;
      }
      );
    this.resetList()
  }

  // public loadFeatureManagementForTenant(tenantId: string) {
  //   this.router.navigate(['/feature-management/self-manage-features'], {
  //     queryParams: {
  //       tenantId: tenantId
  //     },
  //     queryParamsHandling: 'merge',
  //   });
  // }

  setSelectedUser(id: string) {
    this.resetForm();
    this.userManagementService.getUser(id).subscribe(r => this.selectedUser = r.data, err => console.error(err));
  }

  createUser() {
    this.selectedUser = new GetAccountByIdModel();
  }

  createOrUpdateUser() {
    if (this.selectedUser.userId) this.updateUser();
    else this.saveNewUser();
  }

  canDeleteUser(): boolean {
    return this.selectedUser.userId != null;
  }
  canResetPassword(): boolean {
    return this.selectedUser.emailConfirmed;
  }
  canResendEmail(): boolean {
    return this.canResetPassword() || this.selectedUser.userId == null;
  }
  canViewForm(): boolean {
    return this.selectedUser != null;
  }

  resetPassword() {
    this.userManagementService.resetPassword(this.selectedUser.email).subscribe(() => {
      this.alertService.info(`An email has been sent to the user`, this.alertServiceOptions);
    });
  }

  updateUser() {
    this.resetForm();
    this.userManagementService.updateUser(this.selectedUser).subscribe(() => {
      this.resetList();
      this.alertService.success('Account updated successfully', this.alertServiceOptions);
    });
  }

  saveNewUser() {

    if (!this.selectedUser.firstName) {
      this.alertService.error(`Please enter the users first name.`, this.alertServiceOptions);
      return;
    }
    if (!this.selectedUser.lastName) {
      this.alertService.error(`Please enter the users last name.`, this.alertServiceOptions);
      return;
    }
    if (!this.selectedUser.email) {
      this.alertService.error(`Please enter a valid email address.`, this.alertServiceOptions);
      return;
    }    

    const cmd = new CreateAccountCommand();
    cmd.firstName = this.selectedUser.firstName;
    cmd.lastName = this.selectedUser.lastName;
    cmd.email = this.selectedUser.email?.trim();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]/;
    const emailCheck = emailRegex.test(cmd.email);
    if (!emailCheck) {
      this.alertService.error(`The following is not a valid email '${cmd.email}'.`, this.alertServiceOptions);
      return;
    }
    
    this.resetForm();
    if (this.tenantId) {
      cmd.tenantId = this.tenantId;
      this.tenantManagementClient.createUser(cmd).subscribe((res) => {
        this.resetList();
        const users = this.users?.find(x => x.email == cmd.email);
        if (users){
          this.selectedUser = users;
        }
        this.alertService.success('Account created successfully, please check email to confirm and set password', this.alertServiceOptions);
      },
      error => {
        this.errorService.setProblemDetailsFromError(error);
      });
    } else {
      this.userManagementService.createUser(cmd).subscribe(() => {
        this.resetList();
        const users = this.users?.find(x => x.email == cmd.email);
        if (users){
          this.selectedUser = users;
        }
        this.alertService.success('Account created successfully, please check email to confirm and set password', this.alertServiceOptions);
      },
      error => {
        this.errorService.setProblemDetailsFromError(error);
      });
    }
  }

  deleteUser() {
    this.userManagementService.deleteUser(this.selectedUser.userId).subscribe(() => {
      this.resetList();
      this.alertService.success('Account removed successfully', this.alertServiceOptions);
    });
  }

  resetList() {
    if (this.tenantId) {
      this.tenantManagementClient.users(this.filterText, this.tenantId).subscribe(r => {
        this.users = r.data;
        let defaultUser: GetAccountByIdModel = null;
        if (r.data.length) {
          if (this.selectedUser) {
            r.data.forEach(element => {
              if (element.userId == this.selectedUser.userId)
                defaultUser = element;
            });
          }
          defaultUser = defaultUser ?? r.data[0];
        }
        //this.selectedUser = defaultUser;
        if (defaultUser)
          this.userManagementService.getUser(defaultUser.userId).subscribe((r) => this.selectedUser = r.data);
      });
    } else {
      this.userManagementService.getUsers(this.filterText).subscribe(r => {
        this.users = r.data;
        let defaultUser: GetAccountByIdModel = null;
        if (r.data.length) {
          if (this.selectedUser) {
            r.data.forEach(element => {
              if (element.userId == this.selectedUser.userId)
                defaultUser = element;
            });
          }
          defaultUser = defaultUser ?? r.data[0];
        }
        //this.selectedUser = defaultUser;
        if (defaultUser)
          this.userManagementService.getUser(defaultUser.userId).subscribe((r) => this.selectedUser = r.data);
      });
    }
  }

  resetForm() {
    const form = document.getElementById('userDetailsForm');
    const elements = form.getElementsByTagName('small');
    for (let index = 0; index < elements.length; index++) {
      const element = elements.item(index);
      element.innerText = '';
    }
  }

  removeUserRole(userId: string, roleId: string) {
    this.userManagementService.removeUserRole(userId, roleId).subscribe(() => {
      this.resetList();
      this.alertService.success('Role removed successfully', this.alertServiceOptions);
    });
  }

  assignUserRole(userId: string, roleId: string) {
    this.userManagementService.assignUserRole(userId, roleId).subscribe(() => {
      this.resetList();
      this.alertService.success('Role assigned successfully', this.alertServiceOptions);
    });
  }

  resendEmailConfirmation(email) {
    this.userManagementService.resendEmailConfirmation(email).subscribe(() => {
      this.alertService.success(`An email has been sent to ${email}`, this.alertServiceOptions);
    });
  }
}
