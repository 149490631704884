
<div *ngIf="showMain">
    <div class="mt-3">
        <div class="row">
            <div class="col monitor-background">
                <h2>Initial Screening Report</h2>
                <div class="row mb-4 sc-search-container">
                    <div class="col-5 no-padding-left sc-advance-btns">
                    </div>
                    <div class="col-5 search-column">   
                    </div>
                    <div class="col-2 advanced">
                        <div class="input-group">
                            <select class="form-select form-control" id="uniqueScrDialogGender" [(ngModel)]="dateRange" (change)="reloadList()">
                              <option *ngFor="let range of dateRanges" 
                                [value]="range">
                                {{dateRangesLabelMapping[range]}}
                            </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="alert alert-info">
                    <b>This report shows the state of each client at the point when they were first screened. Click on the dropdown list to change the time frame.</b>
                </div>
                <div class="row detail-table">
                    <audit-list
                        [auditList]="auditService.AuditList"
                        [auditTotal]="auditService.AuditListTotal"
                        [currentPage]="auditService.AuditListPage"
                        (changePageEvent)="changePage($event)">
                    </audit-list>
                    <div class="d-flex justify-content-end sc-export">
                        <button class="ms-3 btn screen-btn-new add-client-tour btn-yellow-bg" (click)="viewAuditReport(0, dateRange)">
                            Generate Report
                        </button>    
                        
                    </div>
                        
                </div>
            </div>
        </div>
    </div>
</div>

<audit-report [showAuditReport]="showAuditReport" [timespan]="dateRangesLabelMapping[dateRange]" (hide)="closeAuditReport($event)"></audit-report>

<app-loader [show]="isLoading"></app-loader>
