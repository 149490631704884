<table class="table table-striped table-hover table-themis table-click">
  <thead class="text-light bg-primary">
      <tr>
          <th scope="col">Project</th>
          <th scope="col">Status</th>
          <th scope="col">Date of Request</th>
          <th scope="col" class="text-center">Action</th>
      </tr>
  </thead>
  <tbody>
  <tr *ngFor="let project of intelService.ProjectList | paginate : {
        itemsPerPage: pageSize,
        currentPage: currentPage,
        totalItems: projectTotal
    };" (mouseenter)="setFocusedProject(project)">
      <td>{{ project?.name }}</td>
      <td>{{ project?.status }}</td>
      <td>{{ getDate(project?.createdOn) }}</td>
      <td class="text-center verti">
      <div ngbDropdown class="d-inline-block mon" id="monitor-list">
          <div class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-three-dots" viewBox="0 0 16 16">
              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
          </svg>
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div ngbDropdownItem (click)="viewAudit(project)">Audit Trail</div>
          <div *ngIf="project.reportMediaId" ngbDropdownItem (click)="downloadReport(project.reportMediaId)">Download Report</div>
          <div *ngIf="project.proposalMediaId" ngbDropdownItem (click)="downloadReport(project.proposalMediaId)">Download Proposal</div>
          </div>
      </div>
      </td>
  </tr>
  <tr *ngIf="intelService.ProjectList.length == 0" class="alert alert-info text-center">
      <td colspan="4">
          You currently have no ongoing Intelligence Reports. To start one, click the 'Request a Report' button at the top of the page.
      </td>
  </tr>
  </tbody>
</table>      

<pagination-controls class="pagination"
                     previousLabel="Prev"
                     nextLabel="Next"
                     [responsive]="true"
                     (pageChange)="handlePageChange($event)">
</pagination-controls>