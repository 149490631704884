import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RiskAssessmentService } from 'src/app/risk-assessment/services/risk-assessment.service';
import { AlertService } from 'src/app/_alert';
import { AssessmentSettings, MediaClient, TenantManagementClient, UpdateAssessmentSettingsCommand, UploadCommand, UploadFileMetadata } from 'src/nswag';
import { FileService } from 'src/app/utils/file.service';

@Component({
  selector: 'risk-assessment-feature',
  templateUrl: './risk-assessment-feature.component.html',
  styleUrls: ['./risk-assessment-feature.component.scss']
})
export class RiskAssessmentFeatureComponent implements OnInit {
  tenantId: string;
  assessmentSettings = new AssessmentSettings;
  riskAssessmentFeature: boolean = false;
  emailMessage: string;
  welcomeMessage: string;
  logoMediaId: string;
  alertServiceOptions = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  constructor(private tenantClient: TenantManagementClient, private riskAssessmentService: RiskAssessmentService, private alertService: AlertService, private route: ActivatedRoute, private mediaClient: MediaClient) {}

  public fileTooLarge = false;
  public wrongMimeType = false;
  public maxFileSize = 2097152;
  public selectedFileName: string;
  public selectedFileSizeInKilobytes: number;
  public selectedFile: any;
  public validationErrors: string[];
  public isSuccess: boolean;
  public defaultEmailMessage = RiskAssessmentService.defaultEmailMessage;
  public defaultWelcomeMessage = RiskAssessmentService.defaultWelcomeMessage;


  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.tenantId = params.tenantId;
      }
      );
    this.getUsersTenantDetails();
  }

  public canSave(){
    if (this.assessmentSettings?.emailMessage !== this.emailMessage
      || this.assessmentSettings?.welcomeMessage !== this.welcomeMessage
      || this.assessmentSettings?.logoMediaId !== this.logoMediaId
      || this.selectedFile){
        return true;
  }
    return false;
  }
  private resetMessaging() {
    this.validationErrors = null;
  }

  public onFileChange(event) {
    this.resetMessaging?.();

    if (event?.target?.files && event?.target?.files[0]) {
      var file = event?.target?.files[0];
      FileService.LoadFile(file, meta => {
        if (meta.fileSize > this.maxFileSize) {
          this.alertService.error("An error occurred trying to load the file. Please try again.", { autoClose: true });
          this.fileTooLarge = true;
          event.target.value = null;
        } else {
          var payload: UploadCommand = new UploadCommand();
          payload.file = meta;

          this.mediaClient.upload(payload).subscribe(result => {
            this.assessmentSettings.logoMediaId = result;
          });
        }
      });
    }
  }

  public updateSettings() {
    if (this.riskAssessmentFeature) {
      var command = new UpdateAssessmentSettingsCommand();
      command.emailMessage = this.assessmentSettings.emailMessage;
      command.welcomeMessage = this.assessmentSettings.welcomeMessage;
      command.logoMediaId = this.assessmentSettings.logoMediaId;
      command.tenantId = this.tenantId;
      this.riskAssessmentService.updateAssessmentSettings(command).subscribe(res => {
        if(res.isSuccess){
          this.alertService.success('Risk Assessment Defaults Updated', this.alertServiceOptions);
        }
      });
    }
  }

  getUsersTenantDetails() {
    this.tenantClient.getTenantDetails(this.tenantId).subscribe(res => {
      if (res.isSuccess) {
        this.riskAssessmentFeature = res.data.features?.findIndex(v => v == "RiskAssessment") > -1;
        if (this.riskAssessmentFeature) {
          this.getRiskAssessmentSettings();
        }
      }
    });
  }

  getRiskAssessmentSettings() {
    if (this.riskAssessmentFeature) {
      this.riskAssessmentService.getAssessmentSettings(this.tenantId).subscribe(res => {
        if (res.isSuccess) {
          this.assessmentSettings = res?.data;
          this.welcomeMessage = this.assessmentSettings?.welcomeMessage;
          this.emailMessage = this.assessmentSettings?.emailMessage;
          this.logoMediaId = this.assessmentSettings?.logoMediaId
        }
      });
    }
  }
}
