import { MediaClient } from 'src/nswag';
import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class DownloadService {

  constructor(private client: MediaClient) {}

  download(id: string) {
    this.client
      .download(id)
      .subscribe(blob => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob.data)
        a.href = objectUrl
        a.download = blob.fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
  }
}