import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModule } from '../loader/loader.module';
import { MonitorComponent } from './monitor.component';
import { SummaryComponent } from './summary/summary.component';
import { SearchComponent } from './search/search.component';
import { ListComponent } from './list/list.component';
import { AlertComponent } from './summary/alert/alert.component';
import { MonitoringClient } from 'src/nswag';
import { AddclientComponent } from './manage/addclient/addclient.component';
import { ClientEditorComponent } from './manage/clienteditor/clienteditor.component';
import { ClientReportComponent } from './client-report/client-report.component';
import { ClientHistoryComponent } from './client-history/client-history.component';
import { IndividualSectionComponent } from './client-report/individual-section/individual-section.component';
import { BusinessSectionComponent } from './client-report/business-section/business-section.component';
import { StatusSectionComponent } from './client-report/status-section/status-section.component';
import { IndividualMatchesSectionComponent } from './client-report/individual-matches-section/individual-matches-section.component';
import { BusinessMatchesSectionComponent } from './client-report/business-matches-section/business-matches-section.component';
import { ImportClientsComponent } from './manage/import-clients/import-clients.component';
import { MatchesModule } from '../matching-service/matches.module';
import { LampsPanelsModule } from '../lamps-panels/lamps-panels.module';
import { AlertIconsModule } from '../alert-icons/alter-icons.module';
import { InvestigationLinkerModule } from '../investigation-linker/investigation-linker.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProfileDetailsModule } from '../profile-details/profile-details.module';
import { ExportClientsComponent } from './manage/export-clients/export-clients.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdvancedSearchOptionsModule } from '../advanced-search-options/advanced-search-options.module';
import { TagsModule } from '../tags/tags.module';
import { AuditListComponent } from './client-history/audit-list/audit-list.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { ProblemDetailsModule } from '../problem-details/problem-details/problem-details.module';

@NgModule({
  declarations: [
    MonitorComponent,
    SummaryComponent,
    SearchComponent,
    ListComponent,
    AlertComponent,
    AddclientComponent,
    ClientEditorComponent,
    ClientReportComponent,
    ClientHistoryComponent,
    IndividualSectionComponent,
    BusinessSectionComponent,
    StatusSectionComponent,
    IndividualMatchesSectionComponent,
    BusinessMatchesSectionComponent,
    ImportClientsComponent,
    ExportClientsComponent,
    AuditListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    LoaderModule,
    MatchesModule,
    LampsPanelsModule,
    AlertIconsModule,
    InvestigationLinkerModule,
    NgxPaginationModule,
    ProfileDetailsModule,
    NgSelectModule,
    AdvancedSearchOptionsModule,
    TagsModule,
    DatePickerModule,
    ProblemDetailsModule
  ],
  providers: [
    MonitoringClient
  ],
  exports: [
    MonitorComponent,
    SearchComponent,
    ListComponent,
    ClientReportComponent,
    ClientHistoryComponent,
    ImportClientsComponent,
    AddclientComponent,
    ExportClientsComponent,
    IndividualSectionComponent
  ]
})
export class MonitorModule { }
