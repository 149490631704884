import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileRequestComponent } from './modal/profile-request.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { LoaderModule } from '../loader/loader.module';
import { ProfileRequestButtonComponent } from './button/profile-request-button.component';



@NgModule({
  declarations: [
    ProfileRequestComponent,
    ProfileRequestButtonComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerModule,
    LoaderModule
  ],
  exports: [
    ProfileRequestButtonComponent,
    ProfileRequestComponent
  ]
})
export class ProfileRequestModule { }
