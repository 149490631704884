import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SearchService } from 'src/app/search/services/search.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Address } from 'src/nswag';
import { IActorBase } from '../../models/Actor';
import { SubjectEditAuditLog } from '../../models/Models';

@Component({
  selector: 'app-company-subject-editor',
  templateUrl: './company-subject-editor.component.html',
  styleUrls: ['./company-subject-editor.component.scss']
})
export class CompanySubjectEditorComponent implements OnInit {

  @Output() log = new EventEmitter<SubjectEditAuditLog>();
  @Output() uploadImage = new EventEmitter<any>();
  private actor: IActorBase;

  constructor(public dashService: DashboardService, public searchService: SearchService) { 
    this.actor = this.dashService.getInvestigation();
  }

  onEdit(fieldName: string, fieldValue: string) {
    if(fieldName == "Company Name") {
      var node = this.dashService.findNode(this.actor.id) as cytoscape.NodeSingular;
      node.data("name", fieldValue);
    }
    var log: SubjectEditAuditLog = new SubjectEditAuditLog();
    log.fieldName = fieldName;
    log.fieldValue = fieldValue;
    this.log.emit(log);
  }

  public getProfileImage(images: string[]) {
    return images?.length > 0 ? images[0] : null; 
  }

  onSelectFile(event) {
    this.uploadImage.emit(event);
  }

  ngOnInit(): void { 
    var fullAddress = this.dashService.getInvestigation()?.asCompanyInfo?.registeredAddress?.fullAddress;
    if(!fullAddress) {
      var address: Address = new Address;
      address.fullAddress = "";
      this.dashService.getInvestigation().asCompanyInfo.registeredAddress = address;
    }
  }
}
