import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LAMPSSummary, LAMPSTypes } from 'src/nswag';
import { MonitorService } from '../services/monitor.service';

@Component({
  selector: 'mon-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  constructor() { }

  @Input() summary: LAMPSSummary;
  @Output() showOpenAlerts = new EventEmitter<boolean>();
  @Output() lampsType = new EventEmitter<LAMPSTypes>();

  // Required to be able to reference enums in the html template
  public _lampsTypes = LAMPSTypes;

  ngOnInit(): void {
  }

  setLampsType(lamps: LAMPSTypes){
    this.lampsType.emit(lamps);
  }

  setShowOpenAlerts(showAlerts: boolean){
    this.showOpenAlerts.emit(showAlerts);
  }

}
