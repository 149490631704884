import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Event, Evidence, RelEntry } from 'src/nswag';
 
  class ExpandedEvent extends Event {
    public evidences: Evidence[];
  }
  
  @Component({
    selector: 'mon-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
  })
  export class EventsComponent implements OnChanges {
  

    @Input() clientId: string;
    @Input() profile: any;
    @Input() events: Event[];
    @Input() title: string;
    @Input() noDataMessage: string;
    @Input() evidences: Evidence[];
    @Input() incHeader = true;
    @Input() allowDiscounting = true;
    @Input() reportMode: boolean = false;
    
    public expandedEvents: ExpandedEvent[];
  
    constructor() { }
  
    ngOnChanges(changes: SimpleChanges): void {
      this.expandedEvents = new Array<ExpandedEvent>();
      for (let event of this.events) {
        this.fixUpNames(event);
        let expandedEvent = new ExpandedEvent(event);
        expandedEvent.evidences = new Array<Evidence>();
        for (let evid of event.evidenceIds) {
          let evidence = this.evidences.find( (v,i) => v.evidenceId == evid && (v.discountReason == null || !v.discountReason.isDiscounted));
          if (evidence) {
            expandedEvent.evidences.push(evidence);
          }
        }   
        this.expandedEvents.push(expandedEvent);
      }
    }
  
    private fixUpNames(event: Event) {
      let bits = event?.type?.split(" Event Type");
      if (bits?.length > 0) {
        event.type = bits[0];
      }
    }

    displayAmount(event: ExpandedEvent): string {
      return (event?.amount > 0 ? event?.amount.toLocaleString() +  (event?.currencyCode ?? "") : "");
    }
  }
  
