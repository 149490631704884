import { Component } from '@angular/core';
import { FeatureState, GoogleTagClickEvents } from 'src/app/enums';
import { ProfileService } from '../profile.service';
import { GTMService } from '../services/gtm.service';
import { SelfServiceService } from './services/self-service.service';


@Component({
  selector: 'app-self-service',
  templateUrl: './self-service.component.html',
  styleUrls: ['./self-service.component.scss']
})

export class SelfServiceComponent {
  
  constructor(public selfServeService: SelfServiceService, private profileService: ProfileService, private gtmService: GTMService) { }

  private logClick(event: GoogleTagClickEvents){
    this.gtmService.add(event, this.profileService.userId);
  }

  public displaySelfService(advertCriteria: string) {
    if (this.selfServeService.screeningCredits <= 0) {
      this.selfServeService.openAdvertDialog(FeatureState.OUTOFCREDITS)
    } //show out of credits pop up
    else {
      this.logClick(GoogleTagClickEvents.CREDITS_REMAINING);
      this.selfServeService.openAdvertDialog(advertCriteria);
    }
  }

}
