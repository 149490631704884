import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Address2, BusinessAlias, BusinessProfile, ClientResult } from 'src/nswag';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'mon-businessprofile',
  templateUrl: './businessprofile.component.html',
  styleUrls: ['./businessprofile.component.scss']
})
export class BusinessprofileComponent implements OnChanges {

  @Input() profile: BusinessProfile;
  @Input() client: ClientResult;
  @Input() showAllLists = false;
  @Input() removeTooltip: boolean = false;
  @Input() isArchived: boolean = false;

  @Output() clientChange = new EventEmitter<ClientResult>(); 

  constructor() { }

  public image : string;
  public jurisdiction : string;
  public addressList : string[];
  public businessTypes: string;
  public aliasList: string[];

  public showAlertStatus = false;

  ngOnChanges(changes: SimpleChanges): void {
  
    if (this.profile instanceof BusinessProfile && this.profile?.resourceId) {

      this.image = this.profile?.profileImages?.length > 0  ? this.profile?.profileImages[0] : "/assets/images/EDD-Avatar-company.png";
  
      this.addressList = []
      for (const address of this.profile?.addresses) {
        let add = UtilityService.getAddress(address);
        if (add?.length > 0 && add != address.countryIsoCode) {
          this.addressList.push (address.addressType + ": " + add);
        }
      }

      this.jurisdiction = this.getCountries();

      this.businessTypes = null;
      for (let index = 0; index < this.profile?.businessTypes?.length; index++) {
        if (index==0) {
          this.businessTypes = this.profile?.businessTypes[index];
        }
        else {
          this.businessTypes += ", " + this.profile?.businessTypes[index];
        }
      }
      this.aliasList = [];
      for (const alias of this.profile?.aliases) {
        this.aliasList.push(this.getAliasName(alias) + this.getAliasType(alias));
      }
    }
    this.showAlertStatus = !this.showAllLists && this.client?.client?.inMonitoring;
  }
  
  onClientChange(newClient: ClientResult) {
    this.client = newClient;
    this.clientChange.emit(this.client);
  }

  public getDate(dates: string[]) : string {
    return (dates?.length > 0 ? dates[0] : "" );
  }

  public getAliasName(alias: BusinessAlias) : string {
    var busalias: any = alias;
    return busalias.alias;
  }

  public getAliasType(alias: BusinessAlias) : string {
    return alias.type ? " (" + alias.type + ")" : "";
  }

  public getAddress(address: Address2): string {
    return address.addressType + ": " + UtilityService.getAddress(address);
  }

  public isAddress(address: Address2): boolean {
    let add = UtilityService.getAddress(address)
    return add?.length > 0 && add != address.countryIsoCode;
  }

  private getCountries() : string {
    let countrycodes: string[] = []; 
    for (let address of this?.profile?.addresses) {
      const country = UtilityService.getCountryFromCode(address?.countryIsoCode);
      if (address?.countryIsoCode) {
        if (!countrycodes.find(v => v == country)) {
          //countrycodes.push(address?.countryIsoCode);
          countrycodes.push(country);
        }
      }
    }
    return countrycodes.join();
  }
}
