<div class="container overflow-auto">
    <div class="modal-header">
      <h4>{{isInProgress() ? 'View Progress' : 'View Answers'}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
        <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container">
        <div class="accordion accordion--custom" id="accordionExample">
            <div class="accordion-item" *ngFor="let set of currentAnsweredSetList; let i = index;">
              <h2 class="accordion-header" [id]="'heading'+i">
                <button [id]="'accButton'+i" class="accordion-button" type="button" (click)="renderSurvey(set.questions, i, set.answers)" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse'+i">
                  <div>
                    {{set.name}}
                    <small class="set-desc">
                      {{set.description}}
                    </small>
                  </div>
                </button>
                
              </h2>
              <div [id]="'collapse'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+i" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                </div>
              </div>
            </div>
        </div>      
      </div>
      <div class="float-end p-2">
        <button class="btn m-1 bg-secondary text-light" (click)="dismissModal()">Close</button>
      </div>
    </div>
</div>