import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/_alert';
import { ApiResponseOfListOfString, UploadFileMetadata } from 'src/nswag';

@Component({
  selector: 'mon-import-clients',
  templateUrl: './import-clients.component.html',
  styleUrls: ['./import-clients.component.scss']
})
export class ImportClientsComponent {

  @ViewChild('importView') importTemplateRef: TemplateRef<any>;
  @Output() fileSelectedEvent = new EventEmitter<UploadFileMetadata>();
  @Input() importClientsCallback: (file: UploadFileMetadata) => Observable<ApiResponseOfListOfString>;
  @Input() isLoadingCallback: (loading: boolean) => void;
  @Input() isSuccessfullCallback: () => void;

  public fileTooLarge = false;
  public wrongMimeType = false;
  public maxFileSizeInKilobytes = 2048;
  public selectedFileName: string;
  public selectedFileSizeInKilobytes: number;
  public selectedFile: any;
  public validationErrors: string[];
  public isSuccess: boolean;

  constructor(private modalService: NgbModal, private alertService: AlertService) { }

  public importClients() {
    const modalRef = this.modalService.open(this.importTemplateRef, { backdrop : 'static', keyboard : false, scrollable : true});
    modalRef.result.then(val => modalRef.close()).catch((onRejected) => { /* modal closed */ });
  }

  private resetMessaging() {
    this.validationErrors = null;
    this.isSuccess = null;
  }

  public closeModals() {
    this.resetMessaging?.();

    this.modalService.dismissAll();
  }

  public onFileChange(event) {
    this.resetMessaging?.();

    this.fileTooLarge = false;
    this.wrongMimeType = false;
    this.selectedFileName = null;
    this.selectedFileSizeInKilobytes = null;
    this.selectedFile = null;
    if (event.target.files && event.target.files.length > 0) {
      var file = event.target.files[0];
      this.selectedFileSizeInKilobytes = Math.trunc(file.size / 1000);
      this.selectedFileName = file.name;
      // Don't allow file sizes over 2MB
      if (this.selectedFileSizeInKilobytes <= this.maxFileSizeInKilobytes) {
        if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          this.selectedFile = file;
        }
        else {
          this.wrongMimeType = true;
        }
      }
      else {
        this.fileTooLarge = true;
      }
    }
  }

  uploadFile() {
    this.resetMessaging?.();
    if (this.selectedFile) {
      let reader = new FileReader();
      reader.onload = (e) => {
        var file = new UploadFileMetadata({
          fileAsBase64: e?.target?.result?.toString()?.split(',')[1],
          fileName: this.selectedFileName,
          fileSize: this.selectedFileSizeInKilobytes
        });
        this.import(file);
        // this.fileSelectedEvent.emit(file);
      }
      reader.onerror = (e) => {
        this.alertService.error("An error occurred trying to load the file. Please try again.", { autoClose: true });
      }
      reader.readAsDataURL(this.selectedFile);
    }
  }

  public import(file: UploadFileMetadata) {
    this.resetMessaging();
    this.isLoadingCallback?.(true);

    this.importClientsCallback(file).subscribe(result => {
      if (result.isSuccess) {
        this.isLoadingCallback?.(false);
        if(!result.data || result.data?.length === 0) {
          this.isSuccess = true;
          this.isSuccessfullCallback?.();
        } else {
          this.validationErrors = result.data;
        }
      }
    },
    (error) => {
      this.isLoadingCallback?.(false);
      const errorMessage = JSON.parse(error.response);
      this.alertService.error(errorMessage.errors[0].errorMessage, { autoClose: false });
    });
  }

}

