import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index;">
      <div class="row-border">
        <div class="grid m-1">
          <div class="col" *ngFor="let subField of field.fieldGroup">
            <formly-field [field]="subField"></formly-field>
          </div>
          <div *ngIf="formControl.enabled" class="col-1 flex align-items-center justify-content-center">
            <p-button *ngIf="i > 0" icon="pi pi-trash" [rounded]="true" [text]="true" severity="contrast" (click)="remove(i)"/>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="formControl.enabled" class="grid my-2">
      <div class="col">
        <p-button icon="pi pi-plus" [outlined]="true" (click)="add()"/>
      </div>
    </div>
  `,
  styles: [`
    .row-border {
      border-bottom: 1px solid #ccc;
    }
  `],
  styleUrls: [
    '../report-content/report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ]
})
export class FormlyFieldRepeat extends FieldArrayType {
  add() {
    super.add();
    this.formControl.updateValueAndValidity();
  }

  remove(index: number) {
    super.remove(index);
    this.formControl.updateValueAndValidity();
  }
}
