import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AlertStatus, LAMPSStatus } from 'src/nswag';

@Component({
  selector: 'mon-alerticon',
  templateUrl: './alerticon.component.html',
  styleUrls: ['./alerticon.component.scss']
})
export class AlerticonComponent implements OnChanges {

  constructor() { }

  @Input() lampsStatus: LAMPSStatus;
  @Input() title: string;
  @Input() icon: string;
  @Input() alwaysShow: boolean = false;
  @Input() small: boolean = false;
  @Input() alertStatus: AlertStatus;

  public iconClasses : string;
  public displayIcon : boolean;
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.lampsStatus == LAMPSStatus.Yes && this.alertStatus == AlertStatus.Closed){
      // show icons
      this.iconClasses = this.icon + (this.small ? " p-1" : " newBlob");
    }
    else {
      this.iconClasses = this.icon + (this.lampsStatus == LAMPSStatus.Changed ? (this.small ? " p-1 newBlob-sm" : " newBlob") : (this.small ? " p-1" : ""));
    }
    
    this.displayIcon = this.alwaysShow || this.lampsStatus != LAMPSStatus.No;
  }
}
