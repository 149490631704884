import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditLog, ListClientsQuery } from 'src/nswag';
import { AuditService } from './services/audit.service';

enum DateRangesEnum {
  TODAY,
  YESTERDAY,
  LASTWEEK,
  LASTFORTNIGHT,
  LAST30DAYS,
  YEARTODATE,
  LASTYEAR
}

// optional: Record type annotation guaranties that 
// all the values from the enum are presented in the mapping
export const DateRangesLabelMapping: Record<DateRangesEnum, string> = {
  [DateRangesEnum.TODAY]: 'Today',
  [DateRangesEnum.YESTERDAY]: 'Yesterday',
  [DateRangesEnum.LASTWEEK]: "Last 7 Days",
  [DateRangesEnum.LASTFORTNIGHT]: "Last 14 Days",
  [DateRangesEnum.LAST30DAYS]: "Last 30 Days",
  [DateRangesEnum.YEARTODATE]: "Year To Date",
  [DateRangesEnum.LASTYEAR]: "Last Year"
};

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss'],
})
export class AuditComponent implements OnInit, OnDestroy {

  public dateRanges = Object.values(DateRangesEnum).filter(value => typeof value === 'number');
  public dateRangesLabelMapping = DateRangesLabelMapping;
  public showAuditReport = false;
  public auditLogs: AuditLog[] = [];

  public showMain = true;
  public isLoading = false;

  public dateRange: string;
  
  @Input() refresh: boolean = false;
  @Input() advancedSearchOptions = new ListClientsQuery;
  
  constructor(private modalService: NgbModal, public auditService: AuditService) {
  }

  ngOnInit(): void {
    //load page - as last 30 day default
    this.dateRange = DateRangesEnum.LAST30DAYS.toString();
    this.loadAuditLogs(0, this.dateRange);
  }

  ngOnDestroy() {
    // close all modal pop upon component destroy
    this.modalService.dismissAll(false);
  }

  reloadList(): void {
    this.loadAuditLogs(0, this.dateRange)
  }

  public viewAuditReport(skip: number, timeFrame: string) {
    this.showMain = false;
    this.showAuditReport = true;

    this.loadAuditLogs(skip, timeFrame);
  }

  private loadAuditLogs(skip: number, timeframe: string) {
    this.auditService.getScreeningAuditLogs(skip, timeframe);
  }

  public closeAuditReport(cancelled: boolean) {
    this.showMain = true;
    this.showAuditReport = false;
  }

  public changePage(page: number) {
    //this.auditService.setAuditListPage(page);
  }
}
