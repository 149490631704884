import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { ListTenantsModel, TenantManagementClient, UpdateTenantIsMandatoryNotesEnabledCommand } from 'src/nswag';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';

@Component({
  selector: 'app-system-features',
  templateUrl: './system-features.component.html',
  styleUrls: ['./system-features.component.scss']
})
export class SystemFeaturesComponent implements OnInit {
  tenantId: string;
  tenantSettings: ListTenantsModel;
  monitorFeature: boolean = false;
  screenFeature: boolean = false;
  searchFeature: boolean = false;
  makeNotesMandatory: boolean = false;
  alertServiceOptions = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  constructor(private tenantClient: TenantManagementClient, private alertService: AlertService, private route: ActivatedRoute,
     private confirmationService: ConfirmationDialogService) { }

  ngOnInit(): void {
    this.getUsersTenantDetails();
  }


  public updateSettings() {
    let message = 'This will be avaliable on the next user login.';
    //open model with warning
    this.confirmationService.confirm("Update Settings?", `Are you sure? ${message}`, false, "Yes", "No")
      .then((confirmed) => {
        if (confirmed) {
          if (this.searchFeature || this.screenFeature || this.monitorFeature) {
            var tenantCommand = new UpdateTenantIsMandatoryNotesEnabledCommand();
            if (this.tenantSettings?.isMandatoryNotesEnabled != this.makeNotesMandatory) {
              tenantCommand.isMandatoryNotesEnabled = this.makeNotesMandatory;
              this.tenantClient.updateIsMandatoryNotesEnabled(tenantCommand).subscribe(res => {
                this.alertService.success('Tenant Settings Updated', this.alertServiceOptions);
              });
            }
          }
        }
      })
      .catch(() => { });
  }

  public fieldsAreDirty() {
    var dirty = false;
    if (this.searchFeature || this.screenFeature || this.monitorFeature) {
      dirty = this.tenantSettings?.isMandatoryNotesEnabled != this.makeNotesMandatory;
    }
    return dirty;
  }


  getUsersTenantDetails() {
    this.tenantClient.getTenantDetails(this.tenantId).subscribe(res => {
      if (res.isSuccess) {
        this.monitorFeature = res.data.features?.findIndex(v => v == "Monitor") > -1;
        this.screenFeature = res.data.features?.findIndex(v => v == "Screen") > -1;
        this.searchFeature = res.data.features?.findIndex(v => v == "Search") > -1;

        if (this.searchFeature || this.screenFeature || this.monitorFeature) {
          this.tenantSettings = res.data;
          this.makeNotesMandatory = res.data.isMandatoryNotesEnabled;
        }
      }
    });
  }
}
