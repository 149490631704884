import Step from 'shepherd.js/src/types/step';

export const builtInButtons = {
  cancel: {
    classes: 'cancel-button',
    secondary: true,
    text: 'Exit',
    type: 'cancel'
    // action: function() {
    //   const items = JSON.parse(localStorage.getItem('shepherd-tour')) ?? [];

    //   items.push('monitor');
    //   localStorage.setItem('shepherd-tour', JSON.stringify(items));
    //   return this.hide();
    // }
  },
  next: {
    classes: 'next-button',
    text: 'Next',
    type: 'next'
  },
  back: {
    classes: 'back-button',
    secondary: true,
    text: 'Back',
    type: 'back'
  }
};

export const defaultStepOptions: Step.StepOptions = {
  classes: 'shepherd-theme-arrows custom-default-class',
  scrollTo: true,
  cancelIcon: {
    enabled: true
  }
};

export const steps: Step.StepOptions[] = [
  {
      attachTo: {
          element: null,
          on: 'bottom'
      },
      buttons: [
          builtInButtons.cancel,
          builtInButtons.next
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      id: 'intro',
      title:`
              <b>Welcome to Themis Monitor!</b>
            `,
      text: `
              <p style="display: flex; justify-content: center;"><img src="/assets/images/tour-guide.jpg" class="avatar-image" width="400px" />
              <p>    
              <p>
              Let&rsquo;s start with a quick guided product tour and we will have you up and running in no time!
              </p>`
  },
  {
    attachTo: {
      element: '.add-client-tour',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'installation',
    title: 'Add or Import Clients',
    text: `
      <p>Click the &apos;add&apos; button to add new clients individually or</p>
      <p>Click the &apos;import&apos; button to import clients either manually or by using the spreadsheet provided.</p>
      <p>Click the &apos;export&apos; button to download your list of monitored clients.</p>
    `
  },
  {
    attachTo: {
      element: '.summary-container',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Alert Summary',
    text: `
        <p>
          This is the alert summary section where you receive updates on any changes to your clients that are being monitored.
        </p>`
  },
  {
    attachTo: {
      element: '.total-btn-link',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Total Numbers',
    text: `
        <p>
          The numbers in black are the total numbers of alerts.
        </p>`
  },
  {
    attachTo: {
      element: '.alert-btn-link',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'New Alerts',
    text: `      
        <p>
          The numbers in red are the total numbers of <span style = "color: red;">open</span> alerts.
        </p>`
  },
  {
    attachTo: {
      element: '#searchFieldMain',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'intro',
    title: 'Enter your Search Criteria',
    text: `
          <p>
            Input the name of the company or individual you wish to find.
          </p>`
  },
  {
    attachTo: {
      element: '.search-btn-new',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'installation',
    title: 'Search Button',
    text: 'Click the search button to begin your search. Any matching clients will be displayed below.'
  },
  {
    attachTo: {
      element: '.advanced',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'installation',
    title: 'Advance Search Options',
    text: 'Click on the &apos;Advanced&apos; link to customise your search using the various filters available.'
  },
  {
    attachTo: {
      element: '.detail-table',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Alert List',
    text: `
        <p>
          This displays a list of clients that are currently being monitored, you can navigate the list of client pages using the control panel at the bottom of the list.
        </p>`
  },
  {
    attachTo: {
      element: '#tourguide-btn',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'installation',
    title: 'Tour Guide',
    text: 'If at any time you need to access this tour guide. Simply click the flag button here.'
  },
  {
    attachTo: {
      element: '#archive-btn',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'installation',
    title: 'Archive',
    text: 'Click here to view all archived records.'
  },
  {
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back
    ],
    id: 'noAttachTo',
    title: 'Congratulations',
    classes: 'custom-class-name-1 custom-class-name-2',
    text: 'You completed the tutorial! Press the Exit button.'
  }
];
