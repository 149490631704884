<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
    <div class="container-fluid">
      <a class="navbar-brand p-0 my-0" [routerLink]="['/']">
        <div class="p-0 my-0 service-image service-logo"></div>
      </a>
      <app-self-service></app-self-service>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target=".navbar-collapse"
        aria-label="Toggle navigation"
        [attr.aria-expanded]="isExpanded"
        (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex justify-content-end"
           [ngClass]="{ show: isExpanded }">
           <app-login-menu></app-login-menu>
      </div>
    </div>
  </nav>
</header>
