<section>
  <br />
  <h2>Personal Details</h2>
  <h4 class="bg-dark text-light">{{individual?.name}}</h4>
  <form [formGroup]="dashService.getInvestigation().reportActorFreeText">
    <div class="table d-table">
      <div class="row">
        <div class="lamps-image-col">
          <div *ngIf="dashService.getInvestigation()?.profileImageId; else displayLAMPSAvatar">
            <img [src]="'/media/'+dashService.getInvestigation()?.profileImageId+'' | secure | async"
              class="avatar-image">
          </div>
          <ng-template #displayLAMPSAvatar>
            <img src="/assets/images/EDD-Avatar-individual.png" class="avatar-image" />
          </ng-template>
        </div>
        <div class="col" *ngIf="individual?.dateOfBirth || individual?.nationality">
          <p *ngIf="individual?.dateOfBirth" class="c24 c41">Date Of Birth: {{individual?.dateOfBirth}}</p>
          <p *ngIf="individual?.nationality" class="c24 c41">Nationality: {{individual?.nationality}}</p>
        </div>
        <div class="col address" *ngIf="individual?.address?.fullAddress">
          <p *ngFor="let addressLine of reportUtils.splitFullAddress(individual?.address?.fullAddress)">{{addressLine}}
          </p>
        </div>
        <div class="col address" *ngIf="!individual?.address?.fullAddress">
          <p>{{individual?.address?.addressLine1}}</p>
          <p>{{individual?.address?.addressLine2}}</p>
          <p>{{individual?.address?.countyOrState}}</p>
          <p>{{individual?.address?.locality}}</p>
          <p>{{individual?.address?.postOrZipCode}}</p>
          <p>{{individual?.address?.country}}</p>
        </div>
      </div>
      <div *ngIf="hasCrimeData()">
        <div class="row bg-dark">
          <div class="col">
            <p class="text-light">Source</p>
          </div>
          <div class="col">
            <p class="text-light">Crime Information</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p *ngIf="individual?.dataSource" class="c24 c41">{{individual?.dataSource}}</p>
          </div>
          <div class="col">
            <p *ngIf="individual?.crimeType" class="c24 c41">Crime Type: {{individual?.crimeType}}</p>
            <p *ngIf="individual?.convictionDate" class="c24 c41">Conviction date: {{individual?.convictionDate}}</p>
            <p *ngIf="individual?.court" class="c24 c41">Court: {{individual?.court}}</p>
            <p *ngIf="individual?.sentence" class="c24 c41">Sentence: {{individual?.sentence}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="details" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="detailsHeading"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="detailsText"></p>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Business Relationship</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="businessRelationship" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="businessRelationship"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="businessRelationshipText"></p>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Risk Level</p>
        </div>
        <div class="col">
          <p class="text-light">Comments</p>
        </div>
      </div>
      <div class="row" [style.background-color]="rowColors.get('ragStatus')">
        <div class="col">
          <select class="form-select form-control" id="uniqueIndDetOption" formControlName="ragStatus"
            (change)="setRowColor('ragStatus')">
            <option value="" selected></option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
            <option value="N/A">N/A</option>
          </select>
          <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
        </div>
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="ragStatus" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="riskLevelComments"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="ragStatusText"></p>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>