import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { UserGraph } from 'src/nswag';
import { GraphService } from '../services/graph.service'
import { DashboardService } from '../services/dashboard.service'
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-list',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  savedGraphs: UserGraph[];
  sharedGraphs: UserGraph[];
  
  searchEnabled = true;
  
  isLoading: boolean = false;

  private alertServiceOptions = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(public graphService: GraphService, public dashService: DashboardService, private alertService: AlertService,
    public router: Router, private profileService: ProfileService) { }

  ngOnInit(): void {
    this.graphService.getAll().subscribe(r => this.savedGraphs = r.data);
    this.graphService.getSharedInvestions().subscribe(r => this.sharedGraphs = r.data);
    
    this.isLoading = false;

    this.searchEnabled = this.profileService.searchEnabled();

  }

  deleteGraph(graph: UserGraph) {
    this.graphService.deleteGraph(graph.id).subscribe(() => {
      const index = this.savedGraphs.indexOf(graph);
      if (index > -1) {
        this.savedGraphs.splice(index, 1);
      }
      this.alertService.success('The saved graph has been removed successfully', this.alertServiceOptions);
    });
  }

  unshareGraph(graph: UserGraph) {
    this.graphService.unShareInvestigation(graph.id).subscribe(() => {
      this.graphService.getSharedInvestions().subscribe(r => this.sharedGraphs = r.data);
    })
  }

  addGraph(graph: UserGraph) {
    this.graphService.addGraph(graph).subscribe(r => {
      if(!this.savedGraphs) {
        this.savedGraphs = [];
      } 
      this.savedGraphs.push(graph);
    });
  }

  selectGraph(graph: UserGraph) {
    this.isLoading = true;
    this.graphService.getById(graph.id).subscribe(result => {
      this.isLoading = false;
      if (result.isSuccess) {
        this.dashService.setSelectedUserGraph(result.data);
        this.router.navigate(['/search/dashboard']);
      }
    }, error => {
        //this.alertService.error("Sorry! This investigation has been deleted by the other user!", { autoClose: true });
        this.graphService.getSharedInvestions().subscribe(r => this.sharedGraphs = r.data);
        this.isLoading = false;
    })
  }
}
