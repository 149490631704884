<div class="container">
  <div class="modal-header">
    <h4 class="col">Edit</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row mt-2 required-input">
        <label>Title</label>
      </div>
      <div class="row">
        <div class="input-group required-name">
          <input type="text" required minlength="2" name="name" class="form-control" [(ngModel)]="project.name" #name="ngModel">
        </div>
        <div *ngIf="name.invalid " class="row">
          <div *ngIf="(!name.value || name.errors?.required) && name.touched" class="form-control alert alert-danger">
            Title is required.
          </div>
          <div *ngIf="name.errors?.minlength" class="form-control alert alert-danger">
            Title must be at least 2 characters long.
          </div>
        </div>

      </div>
      <div class="row mt-2">
        <label>Status</label>
      </div>
      <div class="row">
        <div class="input-group">
          <select class="form-select form-control" id="statusDropdown" [(ngModel)]="project.status">
            <option *ngFor="let range of statusRanges" 
              [value]="range">
              {{statusRangesLabelMapping[range]}}
          </option>
          </select>
      </div>
      </div>
    <div class="float-end p-2">
      <button class="btn m-1 bg-secondary text-light" id="uniqueCancelAssDialog" (click)="dismissModal()">Cancel</button>
      <button id="saveBtn" class="btn m-1 bg-primary text-light" [disabled]="!canUpdate()" (click)="updateProject()"> 
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm btn-padding"></span>
        <span *ngIf="!isSaving">Save Changes</span>
      </button>
    </div>
  </div>
</div>