import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlerticonComponent } from './alerticon/alerticon.component';
import { AlertIconGroupComponent } from './alert-icon-group/alert-icon-group.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    AlerticonComponent,
    AlertIconGroupComponent
  ],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [
    AlerticonComponent,
    AlertIconGroupComponent
  ]
})
export class AlertIconsModule { }
