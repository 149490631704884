import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Observable, isObservable } from 'rxjs';

@Component({
  selector: 'formly-field-primeng-dropdown',
  template: `
    <p-dropdown
      [options]="dropdownOptions"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder"
      [filter]="props.filter">
    </p-dropdown>
  `,
  styleUrls: [
    '../report-content/report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ],
})
export class FormlyFieldPrimengDropdown extends FieldType {

  public dropdownOptions: any[] = [];

  ngOnInit() {
    if (isObservable(this.props.options)) {
      (this.props.options as Observable<any[]>).subscribe(data => this.dropdownOptions = data);
    } else {
      this.dropdownOptions = this.props.options;
    }
  }

  get formControl(): FormControl {
    return this.field.formControl as FormControl;
  }  
}
