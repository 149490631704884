import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Profile } from 'oidc-client';
import { ProfileService } from 'src/app/profile.service';
import { ConfirmationDialogService } from 'src/app/_confirmation-dialog/ConfirmationDialog.service';
import { MyGraphClient, UpdateSharedInvestigationCommand } from 'src/nswag';
import { AuditService } from '../../services/audit.service';
import { DashboardService } from '../../services/dashboard.service';

class UserShare {
  userId: string;
  name: string;
  status: boolean;
  thinking: boolean;
  isChanged: boolean;
}

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})

export class ShareComponent implements OnInit {

  public loggedinUser: Profile;

  public investigationId: string;

  public nameFilter: string;

  public userList: UserShare[];
  public userListFiltered: UserShare[];

  public notes: string;
  public notesCharLimit: number = 500;
  public charLimitReached: boolean = false;

  public showSpinner = true;

  constructor(
    public dashService: DashboardService,
    private modalService: NgbModal,
    private profileService: ProfileService,
    private auditService: AuditService,
    private graphClient: MyGraphClient,
    private confirmService: ConfirmationDialogService) { 
  }

  public ngOnInit() {
    this.loggedinUser = this.profileService.getProfile();
  }

  public onTextChange(notes: string) {
    this.charLimitReached = notes?.length == this.notesCharLimit;
  }

  public checkCanShare() {
    return this.investigationId && !this.dashService.hasChanged && this.dashService?.investigationName;
  }

  public isShareable() {
    return this.dashService.getSelectedUserGraph()?.userId == this?.loggedinUser?.sub;
  }

  public hasUsersToShare(): boolean {
    return this.userList.length > 0;
  }
  
  public filterList() {
    this.userListFiltered = this.userList?.filter(s => {
      return s?.name?.toLowerCase().includes(this.nameFilter.toLowerCase());
    });
  }

  public showShareDialog(content: any): Promise<boolean> {
    this.reset();
    const modalRef = this.modalService.open(content, { scrollable: true });
    this.userList = this.userListFiltered = new Array<UserShare>();
    this.investigationId = this.dashService.getSelectedUserGraph()?.id;
    this.graphClient.getInvestigationSharedWithOthers(this.investigationId).subscribe((results) => {
      if (results.isSuccess) {
        for (let us of results.data) {
          if (this.loggedinUser.sub != us.userId) {
            this.userList.push({
              userId: us.userId,
              name: us.firstName + " " + us.lastName,
              status: us.status,
              thinking: false,
              isChanged: false
            });
          }
        }
      }
      this.showSpinner = false;
    });
    return modalRef.result;
  }

  public setUser(userShare: UserShare) {
    userShare.isChanged = ! userShare.isChanged;
    userShare.status = !userShare.status;
  }

  public shareInvestigation(modal: any) {
    let counter = 0;
    let total = 0;
    for (let userShare of this.userList) {
      if (userShare.isChanged) {
        total ++;
        userShare.thinking = true;
        let command: UpdateSharedInvestigationCommand = new UpdateSharedInvestigationCommand()
        command.investigationId = this.investigationId;
        command.recipientUserId = userShare.userId;
        command.status = userShare.status;
        if (this.notes) command.notes = this.notes;
        
        this.graphClient.updateSharedInvestigation(command).subscribe(() => {
          let message: string = (userShare.status ?
            `Investigation '${this.dashService.investigationName}' shared with ${userShare.name}` :
            `Investigation '${this.dashService.investigationName}' sharing with ${userShare.name} stopped`);
          this.auditService.add(message);
          userShare.thinking = false;
          userShare.status = userShare.status;
          userShare.isChanged = false;
          counter++;
          this.checkCounterEnd(counter, total, modal);
        },
        error => {
          userShare.thinking = false;
          counter++;
          this.checkCounterEnd(counter, total, modal);          
        });
      }
    }
  }

  private reset() {
    this.userList = [];
    this.notes = null;
  }
  
  private checkCounterEnd(counter: number, total: number, modal: any) {
    if (counter == total) {
      let errorCount = 0;
      for (let userShare of this.userList) { errorCount += userShare.isChanged ? 1 : 0 }
      if (errorCount == 0) {
        modal.close();
      }
      else if (errorCount == 1) {
        this.confirmService.confirm("Unfortunately, an error has occurred","Please try again or report this to Themis Support.",null,"Close","lg");
      }
      else {
        this.confirmService.confirm("Unfortunately, some errors have occurred","Please try again or report this to Themis Support.", null,"Close","lg");
      }
    }
  }
}
