<div class="container">
  <div class="modal-header">
    <h4 class="col">{{TitleText}}</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row mt-2 required-input">
        <label>Company name</label>
      </div>
      <div class="row">
        <div class="input-group required-name">
          <input type="text" required name="name" class="form-control" [(ngModel)]="recipient.companyName" #name="ngModel">
        </div>
        <div *ngIf="name.invalid && (name.dirty || name.touched)" class="row">
          <div *ngIf="name.errors?.required" class="form-control alert alert-danger">
            Company name is required.
          </div>
        </div>
      </div>
      <div class="row mt-2 required-input">
        <label>Recipient name</label>
      </div>
      <div class="row">
        <div class="input-group required-name">
          <input type="text" required name="recipientName" class="form-control" [(ngModel)]="recipient.contactName" #recipientName="ngModel">
        </div>
        <div *ngIf="recipientName.invalid && (recipientName.dirty || recipientName.touched)" class="row">
          <div *ngIf="recipientName.errors?.required" class="form-control alert alert-danger">
            Recipient name is required.
          </div>
        </div>
      </div>
      <div class="row mt-2 required-input">
        <label>Email</label>
      </div>
      <div class="row">
        <div class="input-group required-name">
          <input [disabled]="isEdit ? true : false" type="text" required name="email" class="form-control" [(ngModel)]="recipient.contactEmail" #email="ngModel">
        </div>
        <div *ngIf="(email.invalid || isExistingRecipient()) && (email.dirty || email.touched)" class="row">
          <div *ngIf="email.errors?.required" class="form-control alert alert-danger">
            Email is required.
          </div>
          <div *ngIf="isExistingRecipient()" class="form-control alert alert-danger">
            This Recipient already exists.
          </div>
        </div>
      </div>
    </div>
    <div class="float-end p-2">
      <button class="btn m-1 bg-secondary text-light" (click)="dismissModal()">Cancel</button>
      <button class="btn m-1 bg-primary text-light" [disabled]="!canAdd()" (click)="addRecipient()">{{ButtonText}}</button>
    </div>
  </div>
</div>
