<div class="py-1 float-end">
    <button class="ms-3 btn btn-primary" (click)="openSave(saveContent)"> Save Investigation</button>
</div>

<ng-template #saveContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title col">Save investigation</h4>
        <button type="button" class="close float-end col-auto" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
           </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="fileName">Name</label>
            <div class="input-group">
                <input type="text" id="name" name="name" class="form-control" required minlength="4" [(ngModel)]="invName" #name="ngModel" (keyup.enter)="closeSaveModal(modal)">
            </div>
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors?.required">
                    Name is required.
                </div>
                <div *ngIf="name.errors?.minlength">
                    Name must be at least 4 characters long.
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="closeSaveModal(modal)">Save</button>
    </div>
</ng-template>
