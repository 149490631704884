import { Component, ViewEncapsulation } from '@angular/core';
import { PrimeNgModule } from '../modules/prime-ng/prime-ng.module';

@Component({
  selector: 'app-prime-confirm-dialog',
  standalone: true,
  imports: [PrimeNgModule],
  templateUrl: './prime-confirm-dialog.component.html',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class PrimeConfirmDialogComponent {

}
