import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IDVDetailsReportComponent } from './details/idvdetails/idvdetails.component';
import { ReportComponent } from './report.component';
import { OfficerDetailsReportComponent } from './details/officerdetails/officerdetails.component';
import { IndividualDetailsReportComponent } from './details/individualdetails/individualdetails.component';
import { UBODetailsReportComponent } from './details/ubodetails/ubodetails.component';
import { LampsInformationReportComponent } from './lampsinformation/lampsinformation.component';
import { ActorGraphRelationshipsComponent } from './actorgraphrelationships/actorgraphrelationships.component';
import { CompanyDetailsReportComponent } from './details/companydetails/companydetails.component';
import { RedFlagsComponent } from './redflags/redflags.component';
import { RiskSummaryComponent } from './risk-summary/risk-summary.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { LampsPanelsModule } from 'src/app/lamps-panels/lamps-panels.module';
import { DiligenciaPersonDetailsComponent } from './details/diligencia-person-details/diligencia-person-details.component';
import { DiligenciaOrgDetailsComponent } from './details/diligencia-org-details/diligencia-org-details.component';

@NgModule({
  declarations: [
    ReportComponent,
    IDVDetailsReportComponent,
    OfficerDetailsReportComponent,
    IndividualDetailsReportComponent,
    UBODetailsReportComponent,
    LampsInformationReportComponent,
    ActorGraphRelationshipsComponent,
    CompanyDetailsReportComponent,
    RedFlagsComponent,
    RiskSummaryComponent,
    DiligenciaPersonDetailsComponent,
    DiligenciaOrgDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    PipesModule,
    LampsPanelsModule
  ],
  exports: [
    ReportComponent
  ],
  providers: [
    UntypedFormBuilder,
  ],

})
export class ReportModule { }
