import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ClientResult } from 'src/nswag';

@Component({
  selector: 'app-detail-links',
  templateUrl: './detail-links.component.html',
  styleUrls: ['./detail-links.component.scss']
})
export class DetailLinksComponent implements OnChanges  {

  @Input() profile : any;
  @Input() client : ClientResult;
  @Input() hasMatches = true;
  @Input() discounted = false;
  @Input() screened = false;

  @Output() viewMatchesEvent = new EventEmitter<boolean>();
  @Output() addToMonitoringEvent = new EventEmitter<boolean>();
  @Output() searchMatchesEvent = new EventEmitter<boolean>();

  public changeInvestigationStyling: boolean = false;

  public inMonitoring = false;

  constructor() { }

  ngOnChanges() {
    this.changeInvestigationStyling = this.hasMatches ? false : true;
    this.inMonitoring = this.client?.client?.inMonitoring;
  }

  public viewMatches() {
    this.viewMatchesEvent.emit(true);
  }

  public addToMonitoring() {
    this.addToMonitoringEvent.emit(true);
  }

  public searchMatches() {
    this.searchMatchesEvent.emit(true);
  }

}
