import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/_alert';
import { FileService } from 'src/app/utils/file.service';
import { ApiResponseOfListOfString, UploadFileMetadata } from 'src/nswag';

@Component({
  selector: 'int-upload-report',
  templateUrl: './upload-report.component.html',
  styleUrls: ['./upload-report.component.scss']
})
export class UploadReportComponent {

  @Output() fileSelectedEvent = new EventEmitter<UploadFileMetadata>();
  @Input() uploadDocumentCallback: (file: UploadFileMetadata) => Observable<ApiResponseOfListOfString>;
  @Input() isLoadingCallback: (loading: boolean) => void;
  @Input() isSuccessfullCallback: () => void;
  @Input() title: string;

  public fileTooLarge = false;
  public wrongMimeType = false;
  public maxFileSizeInKilobytes = 20480;
  public selectedFileName: string;
  public selectedFileSizeInKilobytes: number;
  public selectedFile: any;
  public validationErrors: string[];
  public isSuccess: boolean;
  public isLoading: boolean;

  constructor(private modalService: NgbModal, private alertService: AlertService) { }

  private resetMessaging() {
    this.validationErrors = null;
    this.isSuccess = null;
  }

  public closeModals() {
    this.resetMessaging?.();

    this.modalService.dismissAll();
  }

  public onFileChange(event) {
    this.resetMessaging?.();

    this.fileTooLarge = false;
    this.wrongMimeType = false;
    this.selectedFileName = null;
    this.selectedFileSizeInKilobytes = null;
    this.selectedFile = null;
    if (event.target.files && event.target.files.length > 0) {
      var file = event.target.files[0];
      this.selectedFileSizeInKilobytes = Math.trunc(file.size / 1000);
      this.selectedFileName = file.name;
      // Don't allow file sizes over 2MB
      if (this.selectedFileSizeInKilobytes <= this.maxFileSizeInKilobytes) {
        // allow pdfs
        if (file.type == "application/pdf") {
          this.selectedFile = file;
        }
        else {
          this.wrongMimeType = true;
        }
      }
      else {
        this.fileTooLarge = true;
      }
    }
  }

  uploadFile() {
    this.isLoading = true;
    this.resetMessaging?.();
    if (this.selectedFile) {
      FileService.LoadFile(this.selectedFile, (meta) => {
        this.import(meta);
      });
    }
  }

  public import(file: UploadFileMetadata) {
    this.resetMessaging();
    this.isLoadingCallback?.(true);

    this.uploadDocumentCallback(file).subscribe(result => {
      if (result.isSuccess) {
        this.isLoadingCallback?.(false);
          this.isSuccess = true;
          this.isSuccessfullCallback?.();
      }
    },
    (error) => {
      this.isLoadingCallback?.(false);
      const errorMessage = JSON.parse(error.response);
      this.alertService.error(errorMessage.errors[0].errorMessage, { autoClose: false });
      }).add(() => {
        this.isLoading = false;
    });
  }

}

