import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BusinessMatch, BusinessProfile, IndividualMatch, IndividualProfile } from 'src/nswag';
import { MatchReportService } from '../match-report.service';
import { UtilityService } from 'src/app/utilitities/utilities';

@Component({
  selector: 'app-select-match-report',
  templateUrl: './select-match-report.component.html',
  styleUrls: ['./select-match-report.component.scss']
})
export class SelectMatchReportComponent implements OnInit {

  
  @Input() individualMatches: IndividualMatch[];
  @Input() businessMatches: BusinessMatch[];
  @Input() isBusiness: boolean;
  private selectedIndividualMatchList: IndividualMatch[];
  private selectedBusinessMatchList: BusinessMatch[];
  public individualProfiles: IndividualProfile[];
  public businessProfiles: BusinessProfile[];

  constructor(private activeModal: NgbActiveModal, public matchReportService: MatchReportService) { }
 
  ngOnInit(): void {
    this.selectedIndividualMatchList = []; 
    this.selectedBusinessMatchList = [];
  }

  public selectAll(event: any) {
    if (this.isBusiness) {
      this.selectedBusinessMatchList = [];
      if (event.target.checked && this.businessMatches) {
        for (let a of this.businessMatches) {
          this.selectedBusinessMatchList.push(a);
        }
      }
    }
    else {
      this.selectedIndividualMatchList = [];
      if (event.target.checked && this.individualMatches) {
        for (let a of this.individualMatches) {
          this.selectedIndividualMatchList.push(a);
        }
      }
    }
  }

  public selectIndividualMatch(profile: IndividualMatch) {
    let existingProfile = this.selectedIndividualMatchList.findIndex(a => {
      return a.resourceId == profile.resourceId;
    })
    if (existingProfile == -1) {
      // Add the selected profile
      this.selectedIndividualMatchList.push(profile);
    }
    else {
      // remove the unselected profile
      this.selectedIndividualMatchList.splice(existingProfile,1);
    }
  }

  public selectBusinessMatch(profile: BusinessMatch) {
    let existingProfile = this.selectedBusinessMatchList.findIndex(a => {
      return a.resourceId == profile.resourceId;
    })
    if (existingProfile == -1) {
      // Add the selected profile
      this.selectedBusinessMatchList.push(profile);
    }
    else {
      // remove the unselected profile
      this.selectedBusinessMatchList.splice(existingProfile,1);
    }
  }

  public checkSelectedIndividualStatus(profile: IndividualMatch): boolean {
    return this.selectedIndividualMatchList.findIndex(a => {
      return a.resourceId == profile.resourceId;
    }) > -1;
  }

  public checkSelectedBusinessStatus(profile: BusinessMatch): boolean {
    return this.selectedBusinessMatchList.findIndex(a => {
      return a.resourceId == profile.resourceId;
    }) > -1;
  }

  public getFullName(profile: IndividualMatch): string {
    return UtilityService.getIndividualFullName(profile?.name, profile?.firstName, profile?.middleName, profile?.lastName);
  }

  public getCountry(code: string): string {
    return UtilityService.getCountryFromCode(code);
  }

  public closeModal() {
    this.activeModal.close();
  }

  public canCreateReport() {
    if (this.selectedBusinessMatchList.length !== 0 || this.selectedIndividualMatchList.length !==0) {
      return true;
    }
    return false;
  }

  public printList() {
    if(this.isBusiness) {
      this.matchReportService.displayBusinessReport(this.selectedBusinessMatchList, this.businessProfiles, false);
    }
    else {
      this.matchReportService.displayIndividualReport(this.selectedIndividualMatchList, this.individualProfiles, false);
    }
  }
}
