<div *ngIf="!editable">
    <ng-select
    [placeholder]="placeholder"
    [items]="existingClientTags"
    [loading]="isLoading"
    bindLabel="value"
    bindValue="value"
    [multiple]="true"
    [addTag]="addCustomTag"
    [(ngModel)]="tagsInput"
    (change)="modelChange()">
    </ng-select>
</div>

<div *ngIf="editable">
    <div class="alert alert-warning mb-2" *ngIf="tagsInput?.length === 3 && select.focused">
        <small>Max selection reached</small>
    </div> 
    <ng-select #select
        [placeholder]="placeholder"
        [items]="existingClientTags"
        [loading]="isLoading"
        bindLabel="value"
        bindValue="value"
        [addTag]="addCustomTag"
        [multiple]="true"
        [maxSelectedItems]="3"
        [(ngModel)]="tagsInput"
        (change)="modelChange()">
    </ng-select> 
</div>
