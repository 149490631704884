<section>
  <br />
  <h2>Personal Details</h2>
  <h4 class="bg-dark text-light">{{name}}</h4>
  <form [formGroup]="dashService.getInvestigation().reportActorFreeText">
    <div class="row">
      <div class="col">
        <div class="wrapper">
          <textarea #textarea id="details" type="textarea" class="discardable-text"
            placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
            formControlName="detailsHeading"></textarea> <span class="edit-icon"> click to edit</span>
          <p id="detailsText"></p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="row">
        <div class="lamps-image-col">
          <div
            *ngIf="dashService.getInvestigation()?.profileImageId ?? profileThumbnail then displayCustomAvatar else displayDefaultAvatar">
          </div>
          <ng-template #displayCustomAvatar>
            <div
              *ngIf="dashService.getInvestigation()?.profileImageId then displayInvestigationAvatar else displayIdvAvatar">
            </div>
          </ng-template>

          <ng-template #displayDefaultAvatar>
            <img src="/assets/images/EDD-Avatar-individual.png" class="avatar-image" />
          </ng-template>

          <ng-template #displayInvestigationAvatar>
            <img [src]="'/media/'+dashService.getInvestigation()?.profileImageId+'' | secure | async"
              class="avatar-image">
          </ng-template>

          <ng-template #displayIdvAvatar>
            <img [src]="'/media/'+profileThumbnail?.id+'' | secure | async" alt="Trulli" class="avatar-image">
          </ng-template>
        </div>
        <div class="col" *ngIf="userProfile?.dateOfBirth || userProfile?.country">
          <p *ngIf="userProfile?.dateOfBirth">Date Of Birth: {{userProfile?.dateOfBirth | date}}</p>
          <p *ngIf="userProfile?.country">Country: {{userProfile?.country}}</p>
        </div>
        <div class="col address" *ngIf="userProfile?.address?.fullAddress">
          <p *ngFor="let addressLine of reportUtils.splitFullAddress(userProfile?.address?.fullAddress)">{{addressLine}}
          </p>
        </div>
        <div class="col address" *ngIf="!userProfile?.address?.fullAddress">
          <p *ngIf="this.userProfile?.address?.addressLine1">{{userProfile?.address?.addressLine1}}</p>
          <p *ngIf="this.userProfile?.address?.addressLine2">{{userProfile?.address?.addressLine2}}</p>
          <p *ngIf="this.userProfile?.address?.locality">{{userProfile?.address?.locality}}</p>
          <p *ngIf="this.userProfile?.address?.countyOrState">{{userProfile?.address?.countyOrState}}</p>
          <p *ngIf="this.userProfile?.address?.postOrZipCode">{{userProfile?.address?.postOrZipCode}}</p>
          <p *ngIf="this.userProfile?.address?.country">{{userProfile?.address?.country}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div *ngIf="dashService.getInvestigation()?.mergedOfficerList?.length > 0">
            <p>Linked Companies: </p>
            <ul>
              <li *ngFor="let mergedOfficer of dashService.getInvestigation()?.mergedOfficerList">
                {{mergedOfficer.companyName}} ({{mergedOfficer.companyNumber}}). {{mergedOfficer.position}}
                {{mergedOfficer.isUBO ? "(UBO)" : ""}} {{mergedOfficer.isActive ? "(Active)" : "(Inactive)"}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Business Relationship</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="businessRelationship" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="businessRelationship"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="businessRelationshipText"></p>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Risk Level</p>
        </div>
        <div class="col">
          <p class="text-light">Comments</p>
        </div>
      </div>
      <div class="row" [style.background-color]="rowColors.get('ragStatus')">
        <div class="col">
          <select class="form-select form-control" id="uniqueIDVOption" formControlName="ragStatus"
            (change)="setRowColor('ragStatus')">
            <option value="" selected></option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
            <option value="N/A">N/A</option>
          </select>
          <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
        </div>
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="ragStatus" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="riskLevelComments"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="ragStatusText"></p>
          </div>
        </div>
      </div>
    </div>

    <h4>ID&amp;V Status: <span class="badge {{statusCodeToClass(userProfile.status)}}">{{userProfile?.status}}</span>
    </h4>
    <h5 *ngIf="userProfile?.rejectionReason">Rejection reason:
      {{userProfile?.rejectionReason}}</h5>
    <hr>
    <div class="table d-table">
      <div *ngIf="identityDocuments.length> 0" class="identity-documents">
        <h5>Proof of Identity</h5>
        <div class="row">
          <div class="col" *ngFor="let document of identityDocuments">
            <figure>
              <!--                <figcaption>{{document?.name}}</figcaption>-->
              <img [src]="'/media/'+document?.id+'' | secure | async" alt="Trulli" width="300px">
            </figure>
          </div>
        </div>
      </div>
      <div *ngIf="utilityDocuments.length> 0" class="utility-documents">
        <h5>Proof of Address</h5>
        <div class="row">
          <div class="col" *ngFor="let document of utilityDocuments">
            <figure>
              <!--            <figcaption>{{document?.name}}</figcaption>-->
              <img [src]="'/media/'+document?.id+'' | secure | async" alt="Trulli" width="300px">
            </figure>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>