<div class="row style-background" id="user-table">
    <div class="col">                
        <button class="btn btn-primary float-end" (click)="createUser()">
            &plus; Create New User
        </button>  
        <div class="input-group mt-5">                 
            <label for="filterText" class="input-group-text">Filter</label>
            <input id="filterText" type="text" class="form-control" [(ngModel)]="filterText" (keyup.enter)="resetList()" placeholder="Search for users name, email or company" />                
            <button class="input-group-text btn btn-outline-secondary" type="button" (click)="resetList()">
                <i class="icon-magnifier"></i>
            </button>
        </div>        
        <table *ngIf="users?.length > 0" class="table table-striped table-hover table-themis">
            <thead class="table-light">
                <th>Name</th>
                <th>
                    Email
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let user of users">
                    <td>
                        <a href="#" (click)="$event.preventDefault();setSelectedUser(user.userId)">
                            {{user.firstName}}&nbsp;{{user.lastName}}
                        </a>
                    </td>
                    <td>
                        {{user.email}}
                    </td>
                </tr>
            </tbody>
        </table>
        <span *ngIf="users?.length == 0">There are no records to display</span>
    </div>
    <div class="col">
        <fieldset class="form-group border p-3">
            <legend>User Profile</legend>
            <div class="form" *ngIf="canViewForm()" id="userDetailsForm">

                <div class="form-group">
                    <label for="firstName">First name</label>
                    <input type="text" class="form-control" [(ngModel)]="selectedUser.firstName" id="firstName" name="FirstName" aria-describedby="firstNameHelpBlock" />
                    <small id="firstNameHelpBlock" class="text-danger"></small>
                </div>
                
                <div class="form-group">
                    <label for="lastName">Last name</label>
                    <input type="text" class="form-control" [(ngModel)]="selectedUser.lastName" id="lastName" name="LastName" aria-describedby="lastNameHelpBlock" />
                    <small id="lastNameHelpBlock" class="text-danger"></small>
                </div>
    
                <div class="form-group">
                    <label for="email">Email</label>
                    <div class="input-group">
                        <span class="input-group-text">
                            <i class="icon-envelope"></i>
                        </span>
                        <input type="email" class="form-control" [(ngModel)]="selectedUser.email" id="email" name="Email" aria-describedby="emailHelpBlock" />                    
                        <div class="input-group-text">
                            <input type="checkbox" title="Email confirmed" disabled [checked]="selectedUser.emailConfirmed" id="emailConfirmed" name="EmailConfirmed" aria-describedby="emailHelpBlock" />
                        </div>
                    </div>
                    <small id="emailHelpBlock" class="text-danger"></small>
                </div>
                
                
                <div>
                    <button class="btn btn-primary me-2" (click)="createOrUpdateUser()">
                        Save
                    </button>
                    <button class="btn btn-secondary me-2" (click)="resetPassword()" [hidden]="!canResetPassword()">
                        Reset password
                    </button>
                    <button class="btn btn-secondary me-2" (click)="resendEmailConfirmation(selectedUser.email)" [hidden]="canResendEmail()">
                        Resend email confirmation
                    </button>
                    <button class="btn btn-danger" (click)="deleteUser()" [hidden]="!canDeleteUser()" >
                        Delete          
                    </button>
                </div>
            </div>
        </fieldset>
        <fieldset class="form-group border p-3" [hidden]="selectedUser?.userId == null">
            <legend>User Roles</legend>
            <div>
                <table class="table table-striped table-hover table-themis">
                    <thead>
                        <th>Assigned Roles</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let role of selectedUser?.assignedRoles">
                            <td>
                                {{role}}
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="removeUserRole(selectedUser.userId, role)">
                                    Remove
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot [hidden]="(selectedUser?.assignedRoles?.length !== 0)">
                        <tr>
                            <td colspan="2">
                                No roles assigned
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <table class="table table-striped table-hover table-themis">
                    <thead>
                        <th>Available Roles</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let role of selectedUser?.availableRoles">
                            <td>
                                {{role}}
                            </td>
                            <td>
                                <button class="btn btn-primary" (click)="assignUserRole(selectedUser.userId, role)">
                                    Add
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </fieldset>
    </div>
  </div>
