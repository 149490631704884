<div class="modal-header">
    <h4 class="modal-title col">Client Profile</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismiss()">
        <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
    </button>
</div>
<div class="modal-body">
    <div class="details-card p-3">
        <mon-detail 
            [client]="clientResult" 
            [profile]="matchedProfile"
            [allowDiscounting]="false"
            [canClose]="false"
            [currentModule]="currentModule">
        </mon-detail>
    </div> 
</div>


