import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";
import { MonitorLinkService } from "src/app/services/monitor-link.service";
import { UtilityService } from "src/app/utilitities/utilities";
import { ApiResponseOfBusinessProfile, BusinessAlias, BusinessClient, BusinessMatch, BusinessProfile } from 'src/nswag';
import { BaseMatchesComponent } from "../basematches.component";

@Component({
  selector: 'mon-businessmatches',
  templateUrl: './businessmatches.component.html',
  styleUrls: ['./businessmatches.component.scss']
})
export class BusinessMatchesComponent extends BaseMatchesComponent implements OnChanges {

  @Input() matches: BusinessMatch[];
  @Input() threshold: number;
  @Output() match = new EventEmitter<BusinessMatch>();
  @Output() viewedMoreMatches = new EventEmitter<boolean>();

  public profiles: BusinessProfile[];
  public selectedProfile: BusinessProfile;
  public subThresholdMatches: BusinessMatch[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    this.resultCount = this.matches?.length ?? 0;
    this.showMoreFlag = new Array(this.resultCount).fill(false);
    this.profiles = new Array(this.resultCount).fill(null);
    this.showMoreMatches = false;
    this.subThresholdMatches = [];
    if (this.matches.some(m => m.score < this.threshold)) {
      this.removeSubThresholdMatches();
    }
  }

  private removeSubThresholdMatches(): void {
    // store for optional loading later
    this.subThresholdMatches = this.matches.filter(m => m.score < this.threshold);
    // filter and reassign
    this.matches = this.matches.filter(m => m.score >= this.threshold);
  }

  public loadSubThresholdMatches(): void {
    // if not already loaded
    if (!this.showMoreMatches) {
      this.matches.push(...this.subThresholdMatches);
      this.subThresholdMatches = [];
      this.toggleShowMore();
      this.viewedMoreMatches.emit();
    }    
  }

  public get canShowMoreMatches(): boolean {
    return !this.showMoreMatches && this.subThresholdMatches.length > 0;
  }

  closeReport() {
    this.matchReportService.hideReport();
  }

  changeBusinessShowFlag(event: any, business: BusinessMatch,pos: number){
    event.stopPropagation();
    if (!this.showMoreFlag[pos]) {
      if (!this.profiles[pos]) {
        this.getBusinessProfile(business).subscribe(r=>{
          if (r.isSuccess) {
            this.showMoreFlag.fill(false);
            this.profiles[pos] = r.data;
            this.showMoreFlag[pos] = true;
            this.selectedProfile = r.data;
            this.createDummyClient();
          }
        });
      }
      else {
        this.showMoreFlag.fill(false);
        this.selectedProfile = this.profiles[pos];
        this.showMoreFlag[pos] = true;
        this.createDummyClient();
      }
    }
    else {
      this.showMoreFlag[pos] = false;
    }
  }

  private createDummyClient() {
    this.selectedClient = MonitorLinkService.createDummyClientresult(this.selectedProfile);
    this.selectedClient.client.business = new BusinessClient();
  }

  selectResult(match: BusinessMatch = null) {
    this.match.emit(match);
  }

  showBusinessCheck(pos: number) : BusinessProfile {
    return this.showMoreFlag[pos] ? this.profiles[pos] : undefined;
  }

  getBusinessAliasList(aliases: BusinessAlias[]): string[] {
    return UtilityService.getBusinessAliasList(aliases);
  }

  private getBusinessProfile(profile: BusinessMatch): Observable<ApiResponseOfBusinessProfile> {
    if (this.profileService.monitoringEnabled()) {
      return this.monitorService.getBusinessProfile(profile);
    }
    else if (this.profileService.screeningEnabled()) {
      return this.screenService.getBusinessProfile(profile);
    }
    return null;
  }

  public expandMatchReport(matches: BusinessMatch[]) {
    return this.openMatches(matches, true);
  }
}
