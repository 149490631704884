<div class="card shadow-sm">
  <div class="card-body" id="companyLeftCard">
    @switch (actor?.actorType) {
    @case (EntityTypeEnums.IDV) {
    <ng-template [ngTemplateOutlet]="displayPersonHeader"></ng-template>
    <div id="left-card" class="row">
      <div class="col-4">
        <label class="displaylabel-style">Status</label>
      </div>
      <div class="col-8">
        <span id="idvStatus" class="badge {{statusCodeToClass(actor.asUserProfile?.status)}}"
          (mouseover)="onBadgeHover()">{{actor.asUserProfile?.status}}</span>
      </div>
    </div>
    <ng-template [ngTemplateOutlet]="displayPersonDetail"></ng-template>
    <ng-template [ngTemplateOutlet]="displayMergedOfficers"></ng-template>
    }
    @case (EntityTypeEnums.Officer) {
    <ng-template [ngTemplateOutlet]="displayOfficer"></ng-template>
    }
    @case (EntityTypeEnums.OfficerCompany) {
    <ng-template [ngTemplateOutlet]="displayOfficer"></ng-template>
    }
    @case (EntityTypeEnums.Company) {
    <div class="row">
      <div class="col">
        <div *ngIf="actor.profileImageId; else displayCompanyLAMPSAvatar">
          <img onerror="src='/assets/images/EDD-Avatar-company.png'"
            [src]="'/media/'+actor.profileImageId+'' | secure | async" class="avatar-image">
        </div>
        <ng-template #displayCompanyLAMPSAvatar>
          <img src="/assets/images/EDD-Avatar-company.png" class="avatar-image" />
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col status-col">
        <p><span
            [className]="actor.asCompanyInfo?.isActive? 'bg-success rounded-pill badge' : 'bg-dark rounded-pill badge text-light'">{{actor.asCompanyInfo?.isActive?
            "Active" : "Inactive"}}</span></p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h4>{{actor.asCompanyInfo?.name}}</h4>
      </div>
      <hr>
    </div>
    <br />
    <div class="row" id="left-card">
      <div class="col-4">
        <label class="displaylabel-style">Company No.:</label>
      </div>
      <div class="col-8">
        <span class="displaydetail-style">{{actor.asCompanyInfo?.entityId}}</span>
      </div>
    </div>
    <div class="row" id="left-card">
      <div class="col-4">
        <label class="displaylabel-style">Jurisdiction:</label>
      </div>
      <div class="col-8">
        <span class="displaydetail-style">{{getJurisdictionName(actor.asCompanyInfo?.jurisdiction)}}</span>
      </div>
    </div>
    <div class="row" id="left-card">
      <div class="col-4">
        <label class="displaylabel-style">Address:</label>
      </div>
      <div class="col-8">
        <span class="displaydetail-style">{{getFullAddress(actor.asCompanyInfo?.registeredAddress?.fullAddress)}}</span>
      </div>
    </div>
    }
    @case (EntityTypeEnums.DiligenciaOrganisation) {
    <div class="row">
      <div class="col">
        <div *ngIf="actor.profileImageId; else displayCompanyLAMPSAvatar">
          <img onerror="src='/assets/images/EDD-Avatar-company.png'"
            [src]="'/media/'+actor.profileImageId+'' | secure | async" class="avatar-image">
        </div>
        <ng-template #displayCompanyLAMPSAvatar>
          <img src="/assets/images/EDD-Avatar-company.png" class="avatar-image" />
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col status-col">
        <p><span
            [className]="premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.statusDto) == 'Active' ? 'bg-success rounded-pill badge text-light' : 'bg-dark rounded-pill badge text-light'">{{premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.statusDto)}}</span>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h4>{{premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.companyNameDto)}}</h4>
      </div>
      <hr>
    </div>
    <br />
    <ng-template [ngTemplateOutlet]="displayDiligenciaOrgDetails"></ng-template>
    }
    @case (EntityTypeEnums.Individual) {
    <ng-template [ngTemplateOutlet]="displayPerson"></ng-template>
    <div *ngIf="actor.asIndividualInfo?.crimeType">
      <div class="row">
        <div class="col-4">
          <label>Crime Type</label>
        </div>
        <div class="col-8">
          <span>{{actor.asIndividualInfo?.crimeType}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label>Conviction Date</label>
        </div>
        <div class="col-8">
          <span>{{actor.asIndividualInfo?.convictionDate}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label>Court</label>
        </div>
        <div class="col-8">
          <span>{{actor.asIndividualInfo?.court}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
          <label>Sentence</label>
        </div>
        <div class="col-8">
          <span>{{actor.asIndividualInfo?.sentence}}</span>
        </div>
      </div>
    </div>
    }
    @case (EntityTypeEnums.DiligenciaPerson) {
    <ng-template [ngTemplateOutlet]="displayPersonHeader"></ng-template>
    <ng-template [ngTemplateOutlet]="displayDiligenciaPersonDetails"></ng-template>
    }
    @case (EntityTypeEnums.UBO) {
    <ng-template [ngTemplateOutlet]="displayPerson"></ng-template>
    }
    }
  </div>

  <div class="btn-container" *ngIf="showSubjectEditor()">
    <button class="btn icon-pencil" (click)="dashService.showSubjectEditorModal()"></button>
  </div>
</div>

<ng-template #displayDiligenciaPersonDetails>
  <div *ngIf="actor.asDiligenciaPersonInfo?.dateOfBirth | date: 'yyy-MM-dd'" class="row" id="left-card">
    <div class="col-4">
      <label class="displaylabel-style">Date of birth:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaPersonInfo?.dateOfBirth | date: 'yyy-MM-dd'}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaPersonInfo?.dateOfDeath | date: 'yyy-MM-dd'" class="row" id="left-card">
    <div class="col-4">
      <label class="displaylabel-style">Date of death:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaPersonInfo?.dateOfDeath | date: 'yyy-MM-dd'}}</span>
    </div>
  </div>
  <div *ngIf="premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaPersonInfo?.genderDto)" class="row" id="left-card">
    <div class="col-4">
      <label class="displaylabel-style">Gender:</label>
    </div>
    <div class="col-8">
      <span
        class="displaydetail-style">{{premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaPersonInfo?.genderDto)}}</span>
    </div>
  </div>
  <div *ngIf="premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaPersonInfo?.nationalityDto)" class="row"
    id="left-card">
    <div class="col-4">
      <label class="displaylabel-style">Nationality:</label>
    </div>
    <div class="col-8">
      <span
        class="displaydetail-style">{{premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaPersonInfo?.nationalityDto)}}</span>
    </div>
  </div>
  <div *ngIf="premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaPersonInfo?.countryOfResidenceDto)" class="row"
    id="left-card">
    <div class="col-4">
      <label class="displaylabel-style">Country of Residence:</label>
    </div>
    <div class="col-8">
      <span
        class="displaydetail-style">{{premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaPersonInfo?.countryOfResidenceDto)}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaPersonInfo?.hasRulingFamilyConnection != null" class="row" id="left-card">
    <div class="col-4">
      <label class="displaylabel-style">Has ruling family connection:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaPersonInfo?.hasRulingFamilyConnection ? "Yes" :
        "No"}}</span>
    </div>
  </div>
</ng-template>

<ng-template #displayDiligenciaOrgDetails>
  <div *ngIf="premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.tradingNamesDto)" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Trading Names:</label>
    </div>
    <div class="col-8">
      <span
        class="displaydetail-style">{{premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.tradingNamesDto)}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaOrgInfo?.companyNumber" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Company No:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaOrgInfo?.companyNumber}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaOrgInfo?.registrationNumber" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Registration No:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaOrgInfo?.registrationNumber}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaOrgInfo?.licenseNumber" class="row">
    <div class="col-4">
      <label class="displaylabel-style">License No:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaOrgInfo?.licenseNumber}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaOrgInfo?.sectorsDto?.length > 0" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Sectors:</label>
    </div>
    <div class="col-8">
      <div class="sector-list displaydetail-style"
        *ngFor="let sector of premiumUtils.dtoArrayToSelectedLanguage(actor.asDiligenciaOrgInfo?.sectorsDto)">
        <div class="row">{{sector}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.registeredCountryDto)" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Registered Country:</label>
    </div>
    <div class="col-8">
      <span
        class="displaydetail-style">{{premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.registeredCountryDto)}}</span>
    </div>
  </div>
  <div *ngIf="premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.registeredCityDto)" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Registered City:</label>
    </div>
    <div class="col-8">
      <span
        class="displaydetail-style">{{premiumUtils.dtoToSelectedLanguage(actor.asDiligenciaOrgInfo?.registeredCityDto)}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaOrgInfo?.isStateOwned != null" class="row">
    <div class="col-4">
      <label class="displaylabel-style">State Owned:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaOrgInfo?.isStateOwned ? "Yes" : "No"}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaOrgInfo?.phoneNumber != null" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Phone Number:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaOrgInfo?.phoneNumber}}</span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaOrgInfo?.websiteURL != null" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Website:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">
        <a target="_blank"
          href="{{actor.asDiligenciaOrgInfo?.websiteURL}}">{{actor.asDiligenciaOrgInfo?.websiteURL}}</a>
      </span>
    </div>
  </div>
  <div *ngIf="actor.asDiligenciaOrgInfo?.registeredAddress?.fullAddress != null" class="row">
    <div class="col-4">
      <label class="displaylabel-style">Registered Address:</label>
    </div>
    <div class="col-8">
      <span class="displaydetail-style">{{actor.asDiligenciaOrgInfo?.registeredAddress?.fullAddress}}</span>
    </div>
  </div>
</ng-template>

<ng-template #displayPerson>
  <ng-template [ngTemplateOutlet]="displayPersonHeader"></ng-template>
  <ng-template [ngTemplateOutlet]="displayPersonDetail"></ng-template>
  <ng-template [ngTemplateOutlet]="displayMergedOfficers"></ng-template>
</ng-template>

<ng-template #displayPersonHeader>
  <div class="row">
    <div class="col">
      <div>
        <div *ngIf="actor.profileImageId; else displayPersonLAMPSAvatar">
          <img onerror="src='/assets/images/EDD-Avatar-individual.png'"
            [src]="'/media/'+actor.profileImageId+'' | secure | async" class="avatar-image">
        </div>
        <ng-template #displayPersonLAMPSAvatar>
          <img src="/assets/images/EDD-Avatar-individual.png" class="avatar-image" />
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      @switch (actor.actorType) {
      @case (EntityTypeEnums.IDV) {<h4>{{actor.name}}</h4>}
      @case (EntityTypeEnums.DiligenciaPerson) {<h4>{{actor.name}}</h4>}
      @default {<h4>{{actor.infoObject?.name}}</h4>}
      }
      <hr>
    </div>
  </div>
  <br />
</ng-template>

<ng-template #displayPersonDetail>
  <div>
    <div class="row" id="left-card">
      <div class="col-4">
        <label class="displaylabel-style">Date of birth:</label>
      </div>
      <div class="col-8">
        <span class="displaydetail-style">{{actor.infoObject?.dateOfBirth | date: 'yyy-MM-dd'}}</span>
      </div>
    </div>
    <div class="row" id="left-card">
      <div class="col-4">
        <label class="displaylabel-style">Gender:</label>
      </div>
      <div class="col-8">
        <span class="displaydetail-style">{{actor.infoObject?.gender}}</span>
      </div>
    </div>
    <div class="row" id="left-card">
      <div class="col-4">
        <label class="displaylabel-style">Nationality:</label>
      </div>
      <div class="col-8">
        <span class="displaydetail-style">{{getNationalityName(actor.infoObject?.nationality)}}</span>
      </div>
    </div>
    <div class="row" id="left-card">
      <div class="col-4">
        <label class="displaylabel-style">Jurisdiction:</label>
      </div>
      <div class="col-8">
        <span class="displaydetail-style">{{getJurisdictionName(actor.infoObject?.jurisdiction)}}</span>
      </div>
    </div>
    <div class="row" id="left-card">
      <div class="col-4">
        <label class="displaylabel-style">Address:</label>
      </div>
      <div class="col-8">
        <span class="displaydetail-style">{{getFullAddress(actor.infoObject?.address?.fullAddress)}}</span>
      </div>
    </div>
  </div>
</ng-template>

<!-- Officer or OfficerCOmpany -->
<ng-template #displayOfficer>

  <ng-template [ngTemplateOutlet]="displayPersonHeader"></ng-template>

  <ng-template [ngTemplateOutlet]="displayMergedOfficers"></ng-template>

  <ng-template [ngTemplateOutlet]="displayPersonDetail"></ng-template>
</ng-template>

<ng-template #displayMergedOfficers>
  <div *ngIf="getFirstMergedOfficer(); let firstofficer">
    <ng-template [ngTemplateOutlet]="displayOfficerDetail"
      [ngTemplateOutletContext]="{officer: firstofficer, incLabel: true}"></ng-template>
    <div *ngIf="actor.mergedOfficerList?.length > 1">
      <div class="row">
        <div class="col-4">
        </div>
        <div class="col-8">
          <a class="text-success" *ngIf="!showMore" (click)="showMore=true"><label>[Show more]</label></a>
        </div>
      </div>
      <div *ngIf="showMore">
        <div *ngFor="let officer of actor.mergedOfficerList; let i = index">
          <div *ngIf="i>0">
            <ng-template [ngTemplateOutlet]="displayOfficerDetail"
              [ngTemplateOutletContext]="{officer: officer, incLabel: false}"></ng-template>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4">
        </div>
        <div class="col-8">
          <a class="text-success" *ngIf="showMore" (click)="showMore=false">[Show less]<label></label></a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #displayOfficerDetail let-officer="officer" let-incLabel="incLabel">
  <div class="spaceDiv">
    <div *ngIf="officer?.companyName" class="row" id="PersonLeftCard">
      <div class="col-4">
        <label class="displaylabel-style"><span *ngIf="incLabel">Company:</span></label>
      </div>
      <div class="col-8">
        <label>
          <span class="displaydetail-style">
            {{officer?.companyName}}
          </span>
          <span *ngIf="officer?.companyNumber">
            ({{officer.companyNumber}})
          </span>
          <br />{{officer.isActive ? "Active" : "Inactive"}}
          <span *ngIf="officer.isUBO">
            (UBO)&nbsp;
          </span>
          <span *ngIf="officer.position">
            &nbsp;{{officer.position}}
          </span>
        </label>
      </div>
    </div>
  </div>
</ng-template>
