import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'formly-field-primeng-panel',
    template: `
        <p-panel [header]="field.props.header">
            <div [innerHtml]="field.props.htmlContent"></div>
        </p-panel>
    `,
    styleUrls: [
        '../report-content/report-content.component.scss',
        '../regulatory-reports-base.component.scss'
    ]
})
export class FormlyFieldPrimengPanel extends FieldType {}
