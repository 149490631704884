import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelfServiceService } from '../services/self-service.service';
import { AlertService } from 'src/app/_alert';

@Component({
  selector: 'app-self-service-screen-dialog',
  templateUrl: './self-service-screen-dialog.component.html',
  styleUrl: './self-service-screen-dialog.component.scss'
})
export class SelfServiceScreenDialogComponent implements OnInit {

  @Input() screenName: string;
  @Output() performScreening = new EventEmitter<boolean>();
  public credits: number;
  public dontAskMeAgain: boolean = false;

  constructor(private activeModal: NgbActiveModal, private selfServeService: SelfServiceService, private alertService: AlertService) { }
  

  ngOnInit(): void {
    this.credits = this.selfServeService.screeningCredits;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  screen(){
    this.dismissModal();
    this.selfServeService.updateUserPreferences(this.dontAskMeAgain);
    this.performScreening.emit(true);
  }

}
