<div class="container">
  <div class="modal-header">
    <h3>Edit Profile</h3>
  </div>
  <div class="modal-body">
    <div class="editProfileSection">
      @switch (dashService.getInvestigation()?.actorType) {
      @case (EntityTypeEnums.Company) {
      <app-company-subject-editor (log)="logEdit($event)"
        (uploadImage)="onSelectFile($event)"></app-company-subject-editor>
      }
      @case (EntityTypeEnums.Officer) {
      <app-person-subject-editor (log)="logEdit($event)" (uploadImage)="onSelectFile($event)"
        (dataEvent)='dateChanged($event)'></app-person-subject-editor>
      }
      @case (EntityTypeEnums.OfficerCompany) {
      <app-person-subject-editor (log)="logEdit($event)"
        (uploadImage)="onSelectFile($event)"></app-person-subject-editor>
      }
      @case (EntityTypeEnums.Individual) {
      <app-person-subject-editor (log)="logEdit($event)"
        (uploadImage)="onSelectFile($event)"></app-person-subject-editor>
      }
      @case (EntityTypeEnums.UBO) {
      <app-person-subject-editor (log)="logEdit($event)"
        (uploadImage)="onSelectFile($event)"></app-person-subject-editor>
      }
      }
    </div>
    <div class="btn-container">
      <button class="btn bg-secondary text-light" (click)="dismissModal()" [disabled]="hasModelErrors()">Close
        Editor</button>
    </div>
  </div>
</div>