<!-- <ng-template #surveyContent let-modal> </ng-template> -->
<div class="form user-survey" id="form">
    <div class="modal-header">
        <h4>Your feedback is important to us. Thank you!</h4>
    </div>
    <div class="modal-body">
        <div class="form-control">
            <div class="required-input">
                <label>How much does our product/services/company satisfy your needs?</label>
            </div>
            
            <div *ngFor="let option of satisfactionOptions" >
                <label for="selection-{{option}}">
                    <input type="radio" class="me-1" [value]="option" [(ngModel)]="selectedSatisfaction">{{option}}
                </label>
            </div>
        </div>
    
        <div class="form-control">
            <div class="required-input">
                <label>How likely is it that you would recommend our product / services / company to a friend or colleague?</label>
            </div>
            
            <!-- Input Type radio -->
            <div class="filters">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" [value]="1" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio1">1</label>
                
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" [value]="2" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio2">2</label>

                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" [value]="3" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio3">3</label>

                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" [value]="4" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio4">4</label>

                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio5" [value]="5" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio5">5</label>

                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio6" [value]="6" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio6">6</label>

                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio7" [value]="7" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio7">7</label>

                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio8" [value]="8" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio8">8</label>

                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio9" [value]="9" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio9">9</label>

                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio10" [value]="10" [(ngModel)]="selectedRecommendation">
                <label class="form-check-label" for="inlineRadio10">10</label>
            </div>
            <div class="range-description">
                <p>FROM NOT AT ALL LIKELY</p>
                <p>TO EXTREMELY LIKELY</p>
            </div>
        </div>
    
        <div class="form-control">
            <label for="userComments">
                We are always looking to work with our clients to make the system more valuable for you. 
                If there are additional change requests or new areas of functionality or data you would like to suggest, 
                please let us know here and we will happily evaluate this. Many thanks.
            </label>
    
            <!-- multi-line text input control -->
            <textarea minlength="2" maxlength="500" [(ngModel)]="userComments"
                placeholder="Enter your comments here">
            </textarea>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" (click)="submitSurvey(false)" [disabled]="!selectedSatisfaction || !selectedRecommendation">Submit</button>
        <button class="btn btn-secondary" (click)="submitSurvey(true)">Skip</button>
    </div>
</div>

