<div class="signature-container">
    <p class="mt-3 lead">Use the canvas to draw your signature</p>
    <div class="p-card canvas-wrapper">
        <canvas #signatureCanvas></canvas>
        <button pButton id="clear-canvas-btn" icon="pi pi-undo" [rounded]="true" class="m-2" (click)="clearSignature()"></button>
    </div>  
    <p class="mt-3 lead">Or generate via text</p>
    <div class="p-inputgroup">
        <input type="text" [(ngModel)]="inputName" [placeholder]="inputName" #name="ngModel" pInputText [maxlength]="textInputCharacterLimit"/>
        <button type="button" pButton icon="pi pi-pencil" class="p-button primary-button" (click)="renderText(inputName)"></button>
    </div>
</div>
