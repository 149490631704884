import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DatePickerResult {
  constructor(public ngbValue: NgbDateStruct, public dateValue: Date, public isValid = false) {}
}

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnChanges {

  @Input() dateOfBirth: any;
  @Input() readOnly: boolean = false;  
  @Input() allowFutureDate: boolean = true;
  @Input() showValidation: boolean = true;
  @Output() dataEvent = new EventEmitter<DatePickerResult>();
  public ngbDate: NgbDateStruct;
  public todaysDate: Date = new Date();
  public minDate: Date = new Date(1900, 0, 1);
  public isValid: boolean = true;
  public dateInFuture: boolean = false;

  constructor(private dateFormatter: NgbDateParserFormatter) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.dateOfBirth) {
      if (typeof this.dateOfBirth === 'object' && this.dateOfBirth !== null && 'year' in this.dateOfBirth && 'month' in this.dateOfBirth && 'day' in this.dateOfBirth) {
        this.ngbDate = this.dateOfBirth as NgbDateStruct;
      } else if (this.dateOfBirth instanceof Date) {
        this.ngbDate = this.dateToNgbDate(this.dateOfBirth);
      } else if (typeof this.dateOfBirth === "string" && this.dateOfBirth?.length === 10) {
        this.ngbDate = this.dateFormatter.parse(this.dateOfBirth);
      }
    }
  }

  public dateToNgbDate(date: Date): NgbDateStruct {
    return { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1, day: date.getUTCDate() };
  }

  public ngbDateToDate(ngbDate: NgbDateStruct): Date {
    return new Date(Date.UTC(ngbDate?.year, ngbDate?.month - 1, ngbDate?.day));
  }

  public onEditChange() {
    this.validateDate();
    this.dataEvent.emit(new DatePickerResult(this.ngbDate, this.ngbDateToDate(this.ngbDate), this.isValid));
  }

  public onInputBlur() {
    this.onEditChange();
  }

  private validateDate() {
    if (this.ngbDate) {
      const date = new Date(this.ngbDate.year, this.ngbDate.month - 1, this.ngbDate.day);
      if (!this.ngbDate || isNaN(date.getTime()) || date < this.minDate) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
  
      this.dateInFuture = date > this.todaysDate;
  
      if(!this.allowFutureDate && this.dateInFuture) {
        this.isValid = false;
      }
    }
  }
}