import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { AlertService } from 'src/app/_alert';
import { AssessmentRecipient, FeedbackGroup, IndustryVerticalsAverage, QuestionSetDetail, RecipientQuestionSetResponse } from 'src/nswag';
import { CHART_TYPE } from '../../charts/charts.component';
import { RiskAssessmentService } from '../../services/risk-assessment.service';
import { RecipientReportService } from '../view-recipient-reports/recipient-report.service';

@Component({
  selector: 'results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  public recipientResponseControls: RecipientQuestionSetResponse[] = [];
  public recipientResponseCapabilities: RecipientQuestionSetResponse[] = []
  public chartType = CHART_TYPE;
  public rating: string;
  public chart: any;
  public radarRendered: boolean = true;
  public name: string;
  public feedbackList = new Array<FeedbackGroup[]>();
  public currentRecipient: AssessmentRecipient;
  public labels: string[] = [];
  public questionSets: QuestionSetDetail[] = [];
  public industryAverages: IndustryVerticalsAverage[] = [];

  constructor(private activeModal: NgbActiveModal, public riskAssessmentService: RiskAssessmentService, public alertService: AlertService, private modalService: NgbModal, private reportingService: RecipientReportService) { }

  async ngOnInit() {

    forkJoin({
      questionSets: this.riskAssessmentService.getQuestionSets(),
      recipientQuestionSetResponses: this.riskAssessmentService.getResponseBySelectedRecipient([this.currentRecipient.selectedRecipientId]),
      industryAverages: this.riskAssessmentService.loadIndustryVerticalsAverageByIndustryVerticalsId(this.riskAssessmentService.CurrentAssessment.industryVerticalsId)
    }).subscribe(res => {
      if (res?.questionSets?.isSuccess) {
        this.questionSets.push(...res.questionSets?.data.results);
      }
      if (res?.recipientQuestionSetResponses?.isSuccess) {
        this.recipientResponseCapabilities = res?.recipientQuestionSetResponses?.data?.results.filter(x => x.category === 'Capabilities');
        this.recipientResponseControls = res?.recipientQuestionSetResponses?.data?.results.filter(x => x.category === 'Controls');
        this.rating = this.currentRecipient.displayStatus;
        this.name = this.riskAssessmentService.CurrentAssessment.name;
      }
      this.industryAverages = res?.industryAverages?.isSuccess ? res.industryAverages?.data?.results : null;
    });
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  public createReport() {
    this.reportingService.displayIndividualReport(this.currentRecipient);
  }
}
