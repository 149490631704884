import { Injectable } from '@angular/core';
import { ProfileService } from '../profile.service';
import { HtmlEncodeService } from './html-encode.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  private logEntries: LogEntry[] = [];

  constructor(private profileService: ProfileService, private encodeService: HtmlEncodeService) { }

  add(message: string): void {
    this.logEntries.push(new LogEntry(message, this.profileService.userId, `${this.profileService.firstName} ${this.profileService.lastName}`, new Date().toISOString()));
  }

  clear() {
    this.logEntries = [];
  }
  
  loadEntriesFromXml(xml: string) {
    this.clear();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml,"text/xml");
    const elements = xmlDoc.getElementsByTagName('entry');
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      this.logEntries.push(new LogEntry(this.encodeService.decodeXml(element.getAttribute('message')), element.getAttribute('userId'), element.getAttribute('user'), element.getAttribute('timestamp')));
    }
  }
  
  getUserFullName() : string {
    return `${this.profileService.firstName} ${this.profileService.lastName}`;
  }

  getEntries() : LogEntry[] {
    return this.logEntries;
  }
  getEntriesAsXml() : string {
    return this.getEntriesAsText('entries',
      entry => `<entry message="${this.encodeService.encodeXml(entry.message)}" userId="${entry.userId}" user="${entry.user}" timestamp="${entry.timestamp}" />`);
  }
  getEntriesAsHtml() : string {
    return this.getEntriesAsText('ul', 
      entry => `<li><p>${entry.message}</p><span data-id="${entry.userId}">${entry.user}</span>&nbsp;<time datetime="${entry.timestamp}">${entry.timestamp}</time></li>`);
  }
  private getEntriesAsText(root: string, callback: (entry: LogEntry) => string) : string {
    if (this.logEntries.length == 0) return null;
    let text: string = `<${root}>`;
    for (let index = 0; index <this.logEntries.length; index++) {
      const entry = this.logEntries[index];
      text += callback(entry);
    }
    text += `</${root}>`;
    return text;
  }
}

export class LogEntry {

  constructor(message: string, userId: string, userFullName: string, timestamp: string) { 
    this._message = message;
    this._timestamp = timestamp;
    this._user = userFullName;
    this._userId = userId;
    this._date = new Date(timestamp);
  }
  
  private _message: string;
  private _timestamp: string;
  private _user: string;
  private _userId: string;
  private _date: Date;

  get message() : string {
    return this._message;
  }
  get user() : string {
    return this._user;
  }
  get userId() : string {
    return this._userId;
  }
  get timestamp() : string {
    return this._timestamp;
  }
  get datetime() : Date {
    return this._date;
  }
}