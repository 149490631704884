import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-assessment-tooltip',
  templateUrl: './assessment-tooltip.component.html',
  styleUrls: ['./assessment-tooltip.component.scss']
})
export class AssessmentTooltipComponent implements OnInit {

  constructor() { }
  @Input() toolTipTemplate : string;

  ngOnInit(): void {
  }

}
