<button id="import-btn" class="ms-3 btn btn-secondary" (click)="importClients()">
    <span class="icon-upload2" title="upload icon"></span>&nbsp;Import
</button>

<ng-template #importView>
    <div class="modal-header">
        <h4 class="modal-title col">{{isSuccess ? "File uploaded" : "File to import"}}</h4>
        <button type="button" class="close float-end col-auto" aria-label="Close" (click)="closeModals()">
            <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span
                    class="icon-cross"></span></span>
        </button>
    </div>
    <div class="modal-body overflow-visible">
        @switch (isSuccess){
        @case (true){
        <div>
            <div class="pt-2">
                <div class="alert alert-success">
                    Your file has been uploaded and is being processed.
                </div>
            </div>
            <div class="float-end p-2">
                <button class="btn m-1 bg-secondary text-light" (click)="closeModals()">Close</button>
            </div>
        </div>
        }
        @default{
        <div>
            <label for="file">Import file</label>
            <div class="pt-2">
                <input type="file" class="form-control" id="formFile" (change)="onFileChange($event)">
            </div>
            <div *ngIf="fileTooLarge" class="pt-2">
                <div class="alert alert-danger">
                    This file is too large to upload.
                    File size must be under {{maxFileSizeInKilobytes}}KB.
                    Current size: {{selectedFileSizeInKilobytes}}KB.
                </div>
            </div>
            <div *ngIf="wrongMimeType" class="pt-2">
                <div class="alert alert-danger">
                    This file is not of the right format.
                    Please ensure you upload the populated spreadsheet.
                </div>
            </div>
            <div class="pt-2">
                Download and populate the import spreadsheet <a
                    href="../../../assets/files/Themis Monitor Import File.xlsx">here</a>.
            </div>
            <div class="float-end p-2">
                <button class="btn m-1 bg-secondary text-light" (click)="closeModals()">Cancel</button>
                <button class="btn m-1 bg-primary text-light" [disabled]="selectedFile == null" (click)="uploadFile()">
                    <span class="icon-upload2" title="upload icon"></span>&nbsp;Import
                </button>
            </div>
        </div>
        }
        }
    </div>
    <div *ngIf="validationErrors && validationErrors.length > 0" class="modal-body">
        <div class="alert alert-danger pt-2">
            <p>The import failed because of the validation error below.
                Please correct this error and retry importing.</p>
            <hr>
            <p class="mb-0">{{validationErrors[0]}}</p>
        </div>
    </div>
</ng-template>