<div *ngIf="showAuditReport">
    <div *ngIf="showSpinner" id="temp-page-modal">
        <div class="mx-auto text-center align-self-center mt-5 py-5">
            <div class="spinner-border text-primary mt-5" role="status">
                <span class="sr-only"></span>
            </div>
            <p class="text-center text-primary">Preparing report</p>
        </div>
    </div>

    <div *ngIf="showReport" id="report-page-modal" class="overflow-auto">
        <form>
            <div class="button-container">
                <div class="float-left p-3">
                    <button id="print-button" class="btn btn-primary print" aria-label="Print" (click)="onPrint()">Print Report
                        <span class="icon-printer"></span>
                    </button>
                </div>
                <div class="float-end p-3">
                    <button id="close-button" type="btn btn-link btn-primary" class="close" aria-label="Close" (click)="closeReport()">
                        <span class="icon-cross"></span>
                    </button>
                </div>
            </div>

            <div class="container-report mx-auto">
                <img alt="Report Cover" src="../../../../assets/images/audit-cover.png"
                    style="width:100%; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                    title="">

                    <section>
                        <br/><br/>
                        <h2>Disclaimer</h2>
                        <ul class="disclaimer">
                            <li class="c8 li-bullet-0">
                                The information contained in this document is strictly confidential
                                and intended only for the use of the recipient. Any unauthorised use, dissemination, printing or copying
                                of this report is prohibited without the prior consent of the authors.
                            </li>
                            <li class="c8 li-bullet-0">
                                The content of this report has been generated by {{username}}, {{companyName}} on {{currentDate}} using the Themis Financial Crime
                                Search Engine.
                            </li>

                            <li class="c8 li-bullet-0">
                                All data sources are named but have defined which data to include or not in this report.
                            </li>
                            <li class="c8 li-bullet-0">
                                The content of this report may not reflect the view and opinions of {{companyName}}. {{username}} agrees to indemnify
                                and hold harmless against any damages or claims resulting from such unauthorised use. This material represents
                                the best judgement of the authors and is based solely on information provided to them.
                            </li>
                            <li class="c8 li-bullet-0">
                                This report has therefore been put together by {{username}} who take full responsibility and liability for
                                any contents.
                            </li>
                            <li class="c8 li-bullet-0">
                                Themis International Services or Themis MENA shall not be held responsible for any reason whatsoever with respect to the content of its report.
                            </li>
                        </ul>
                    </section>
                <section>
                    <br/><br/>
                    <h3>Audit Report - {{ timespan }}</h3>
                    <div>
                        <audit-list 
                            [auditList]="auditService.AuditList"
                            [auditTotal]="auditService.AuditListTotal"
                            [currentPage]="1"
                            [pageSize]="auditService.AuditListTotal"
                            [hidePagination]="true">
                        </audit-list>
                    </div>
                </section>
                <section>
                    <br/><br/><hr/>
                    <h2>About Themis</h2>
                    <br/>
                    <p>
                        This report has been created by {{companyName}} powered by Themis&copy;
                    </p>
                    <p>
                        This report has been compiled by {{companyName}} using the Themis Financial Crime Search Engine.
                        Themis accepts no liability or responsibility for the contents of this
                        report.
                    </p>
                    <p>The information contained in this document is a combination of data from various sources. These include:</p>
                    <div class="d-flex">
                        <ul>
                          <li>OpenCorporates</li>
                          <li>Acuris Intelligence</li>
                          <li>Themis Special Interest Index</li>
                          <li>Diligencia</li>
                        </ul>
                        <ul>
                          <li>ZignSec</li>
                          <li>Companies House</li>
                          <li>ICIJ</li>
                        </ul>
                    </div>
                
                    <p>
                        Themis helps clients and members identify and manage their specific financial crime
                        risks, through a combination of insight, intelligence and innovation.
                    </p>
                    <p>
                        Our cutting edge technology platform helps organisations understand these
                        strategic threats through an ESG and socio-economic lens and protects their customers, staff, suppliers and
                        shareholders from criminal attacks or association. In this way, we are fuelling sustainable change in
                        the public and private sector.
                    </p>
                    <p>Specialist Areas</p>
                    <p>
                        Money Laundering | Sanctions | Bribery &amp; Corruption | Fraud | Tax evasion |
                        Modern Slavery &amp; Human Trafficking | Market Abuse | Cyber Crime | Proliferation Financing | Illegal
                        wildlife trade | Cyber Terrorism | Terrorist Financing
                    </p>
                    <p>
                        For more information on Themis enhanced due diligence reports visit www.crime.financial/investigations.
                    </p>
                    <p></p>
                    <br/><hr>
                
                    <div class="footer">
                        <table class="footer">
                            <tbody>
                                <tr>
                                    <td class="left-align">
                                        <img alt="" src="../../../assets/images/data-provider-logos.png"
                                             style="width: 90%; margin-bottom:38px;" title="">
                                    </td>
                                    <td class="right-align" style="padding-bottom: 38px;">
                                        <p class="footer"> Themis Due Diligence Report</p>
                                        <p class="footer"> INNOVATION | INSIGHT | INTELLIGENCE</p>
                                        <a href="https://themisservices.co.uk/" target="_blank" class="footer">themisservices.co.uk</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </form>
    </div>
</div>
