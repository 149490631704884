import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoginMenuService {

    constructor() {
    }

    public hideMenu = false;
}