import Step from 'shepherd.js/src/types/step';

export const builtInButtons = {
  cancel: {
    classes: 'cancel-button',
    secondary: true,
    text: 'Exit',
    type: 'cancel'
  },
  next: {
    classes: 'next-button',
    text: 'Next',
    type: 'next'
  },
  back: {
    classes: 'back-button',
    secondary: true,
    text: 'Back',
    type: 'back'
  }
};

export const defaultStepOptions: Step.StepOptions = {
  classes: 'shepherd-theme-arrows custom-default-class',
  scrollTo: true,
  cancelIcon: {
    enabled: true
  }
};

export const steps: Step.StepOptions[] = [
  {
      attachTo: {
          element: null,
          on: 'bottom'
      },
      buttons: [
          builtInButtons.cancel,
          builtInButtons.next
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      id: 'intro',
      title: '<b>Investigation Dashboard</b>',
      text: `  
              <p>
              Let&rsquo;s start with a quick tour!
              </p>`
  },
 
  {
    attachTo: {
      element: '.investigation-section',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Investigation Buttons',
    text: `  
        <p>
          You can Whistle Blow, Audit Trail, Create a Report, Save the Investigation, or request an Enhanced Due Diligence investigation via the buttons above.
        </p>`
  },
  {
    attachTo: {
      element: '#companyLeftCard',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Details Card',
    text: `
        <p>
          This is the profile panel which displays the details of your search. And if you are yet to run a check on the profile, click the 'run-check' button to match the profile.
        </p>
        `
  },
  {
    attachTo: {
      element: '.toolbar',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Graph Toolbar',
    text: `
        <p>
          This is the layout panel which has control over the investigation graph. Here you can:
        </p>
        <ul style="font-size:14px;">
          <li>
            Change the layout of the graph from the dropdown box.
          </li>
          <li>
            Opt whether to hide	'inactive people and or companies' by clicking the check box.
          </li>
          <li>
            Click the 'Fit' button to help resize your graph during an investigation.
          </li>   
        </ul>    
        `
  },
  {
    attachTo: {
      element: '.cy __________cytoscape_container',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Risk Map',
    text: `
        <p>
          This is the investigation risk map that displays the main subjects of your investigation. You can:
        </p>
        <ul style="font-size:14px;">
          <li>
            View the various connections in the map. 
          </li>
          <li>
            Clicking on a node will display a menu where you can:
          </li>
          <ul>
            <li>Select it to investigate further</li>
            <li>Expand to see other name matches we can find</li>
            <li>Create a link between this node and another, or</li>
            <li>Remove the node from the map.</li>
          </ul>
        </ul>
        `
  },
  {
    attachTo: {
      element: '#investigations-tabs',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Investigations Tab',
    text: `
        <p>
          This is your investigation tab. Click on each button above to find the Litigation, Adverse Media, PEP, and Sanction details of the profile you are searching for.
        </p>
        `
  },
  {
    attachTo: {
      element: '.nav-tabs',
      on: 'bottom'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'modal',
    title: 'Navigation Tab',
    text: `
        <p>
          This is your navigation tab. Click on different buttoned icons to find the Litigation, Adverse Media, PEP, and Sanction details for the currently investigated subject.
        </p>
        `
  },
  {
    attachTo: {
      element: '.tour-flag',
      on: 'top'
    },
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back,
      builtInButtons.next
    ],
    classes: 'custom-class-name-1 custom-class-name-2',
    id: 'installation',
    title: 'Tour Guide',
    text: 'If at any time you need to access this tour guide. Simply click the flag button here.'
  },
  
  {
    buttons: [
      builtInButtons.cancel,
      builtInButtons.back
    ],
    id: 'noAttachTo',
    title: 'Well done!',
    classes: 'custom-class-name-1 custom-class-name-2',
    text: 'You can now continue with your search. Press the Exit button to begin.'
  }
];
