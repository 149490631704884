
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: "app-readmore",
    templateUrl: "./readmore.component.html"
})

export class ReadMoreComponent implements OnChanges {

    @Input() summary: string;
    @Input() list: string[];
    @Input() sliceLength = 100;    
    shortSummary: string;
    fullSummary: string;
    displayShort: boolean;
    readMore = true;

    ngOnChanges(changes: SimpleChanges): void {
        if (this.summary) {
            this.fullSummary = this.summary;
            this.displayShort = this.fullSummary.length > this.sliceLength;
            this.shortSummary = (this.displayShort ? this.fullSummary.slice(0, this.sliceLength) : this.fullSummary);
        } else {
            this.fullSummary = this.shortSummary = "";
            if (this.list?.length > 0) {
                this.displayShort = this.list?.length > 1;
                if (this.displayShort) {
                    this.shortSummary = this.list[0];
                    for (const item of this.list) {
                        this.fullSummary += item + "<br/>";
                    }
                }
                else {
                    this.fullSummary = this.list[0];
                }
            }
        }
    }
}
