import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateAssessmentDialogComponent } from '../create-assessment-dialog/create-assessment-dialog.component';

@Component({
  selector: 'create-assessment-button',
  templateUrl: './create-assessment-button.component.html',
  styleUrls: ['./create-assessment-button.component.scss']
})
export class CreateAssessmentButtonComponent implements OnInit {
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  displayCreateAssessment() {
    const model = this.modalService.open(CreateAssessmentDialogComponent);
    model.componentInstance.ButtonText = 'Next';
    model.componentInstance.TitleText = 'Create';
  }
}
