<div class="style-background border">
    <legend>Risk Assessment Defaults</legend>
    <hr class="divider">
    <div *ngIf="riskAssessmentFeature then riskAssessmentOptions"></div>
    <button [disabled]="!canSave()" class="btn btn-primary" (click)="updateSettings()">Save</button>
</div>

<ng-template #riskAssessmentOptions>
    <div class="card mb-5">
        <div class="card-header">
            <label class="card-title">Email Invitation Message</label>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="input-group">
              <textarea type="text" class="form-control" rows="3" name="name"
                maxlength="500"
                [placeholder]="defaultEmailMessage"
                [value]="assessmentSettings.emailMessage" appRiskAssessmentEmailValidator
                [(ngModel)]="assessmentSettings.emailMessage" #name="ngModel"></textarea>
            </div>
            <small class="form-text text-muted">
              Please use the placeholders <strong>{{'{{SenderName}}'}}</strong> and <strong>{{'{{CompanyName}}'}}</strong> for the sender and company name respectively.
              <br />
              e.g. <i>{{defaultEmailMessage}}</i>
            </small>
          </div>
          <div *ngIf="name.errors?.invalidPlaceHolders && (name.dirty || name.touched)" class="row">
            <div *ngIf="name.errors?.invalidPlaceHolders?.length > 0" class="alert alert-danger">
              <p>Invalid placeholder(s): {{name.errors?.invalidPlaceHolders}}.</p>
            </div>
          </div>
        </div>
    </div>
    <div class="card mb-5">
        <div class="card-header">
            <label class="card-title">Welcome Page Message</label>
        </div>
        <div class="card-body">
            <div class="row">
              <div class="input-group">
                <textarea class="form-control" rows="3" [value]="assessmentSettings.welcomeMessage"
                  maxlength="500"
                  [placeholder]="defaultWelcomeMessage"
                  appRiskAssessmentWelcomeMessageValidator
                  [(ngModel)]="assessmentSettings.welcomeMessage"></textarea>
              </div>
              <small class="form-text text-muted">
                Please use the placeholder <strong>{{'{{AssessmentName}}'}}</strong> for the Assessment name.
                <br />
                e.g. <i>{{defaultWelcomeMessage}}</i>
              </small>
            </div>
            <div *ngIf="name.errors?.invalidPlaceHolders && (name.dirty || name.touched)" class="row">
              <div *ngIf="name.errors?.invalidPlaceHolders?.length > 0" class="alert alert-danger">
                <p>Invalid placeholder(s): {{name.errors?.invalidPlaceHolders}}.</p>
              </div>
            </div>
        </div>
    </div>
    <div class="card mb-5">
      <div class="card-header">
          <label class="card-title">Welcome Screen Logo</label>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="avatar-container">
              <label class="btn btn-default p-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus bg-secondary" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
                <input id="fileInput" type='file' class="btn form-control" accept="image/jpg, image/jpeg, image/png" (change)="onFileChange($event)" hidden>
                <ng-container *ngIf="this.assessmentSettings?.logoMediaId; then display; else displayAvatar"></ng-container>
                <ng-template #display>
                  <img onerror="src='/assets/images/EDD-Avatar-company.png'" [src]="'/media/'+this.assessmentSettings.logoMediaId+'' | secure | async" class="d-block avatar-image">
                </ng-template>
                <ng-template #displayAvatar>
                  <img class="d-block avatar-image" src="/assets/images/EDD-Avatar-company.png"/>
                </ng-template>
              </label>
          </div>
        </div>
    </div>
  </div>
</ng-template>
