<mon-alerticon [lampsStatus]="client?.matchStatus?.hasSanctions" title="Sanctions" [small]="true" icon="icon-prohibited" [ngbTooltip]="!removeTooltip ? sanctionToolTip : ''" container="body" ></mon-alerticon>
<mon-alerticon [lampsStatus]="client?.matchStatus?.isPEP" title="Political Exposure" [small]="true" icon="icon-library2" [ngbTooltip]="!removeTooltip ? pepToolTip : ''" container="body"></mon-alerticon>
<mon-alerticon [lampsStatus]="client?.matchStatus?.hasLawEnforcement" title="Law Enforcement" [small]="true" icon="icon-balance" [ngbTooltip]="!removeTooltip ? lawToolTip : ''" container="body"></mon-alerticon>
<mon-alerticon [lampsStatus]="client?.matchStatus?.hasAdvMedia" title="Adverse Media" [small]="true" icon="icon-tv" [ngbTooltip]="!removeTooltip ? advToolTip : ''" container="body"></mon-alerticon>
<mon-alerticon [lampsStatus]="newMatches" title="Matches" [small]="true" icon="icon-link" [ngbTooltip]="!removeTooltip ? linkToolTip : ''" container="body"></mon-alerticon>
<mon-alerticon [lampsStatus]="justMonitored" title="Monitored" [small]="true" icon="icon-eye" [ngbTooltip]="!removeTooltip ? monToolTip : ''" container="body"></mon-alerticon>
<mon-alerticon [lampsStatus]="discounted" title="Discounted" [small]="true" icon="icon-eye-crossed" [ngbTooltip]="!removeTooltip ? discountToolTip : ''" container="body"></mon-alerticon>
<mon-alerticon [lampsStatus]="matchVoided" title="Voided Match Profile" [small]="true" icon="icon-unlink" [ngbTooltip]="!removeTooltip ? voidedToolTip : ''" container="body"></mon-alerticon>
<mon-alerticon [lampsStatus]="noLamps" title="No Risk & Compliance Data" [small]="true" icon="icon-checkmark-circle" [ngbTooltip]="!removeTooltip ? noLAMPSToolTip : ''" container="body"></mon-alerticon>

<ng-template #sanctionToolTip>
  <span>
    <p>
      <mon-alerticon title="Sanctions" [small]="true" icon="icon-prohibited"></mon-alerticon>
      &nbsp;indicates that the client is currently or has previously been sanctioned.
    </p>
    <p>The yellow dot indicates that there is an open alert for this client that relates to their sanction status.</p>
  </span>
</ng-template>

<ng-template #pepToolTip>
  <span>
    <p>
      <mon-alerticon title="Political Exposure" [small]="true" icon="icon-library2"></mon-alerticon>
      &nbsp;indicates that the client is currently or previously politically exposed (or is a State Owned Enterprise (SOE) in the case that this is a business).
    </p>
    <p>The yellow dot indicates that there is an open alert for this client that relates to their political exposure status.</p>
  </span>
</ng-template>

<ng-template #lawToolTip>
  <span>
    <p>
      <mon-alerticon title="Law Enforcement" [small]="true" icon="icon-balance"></mon-alerticon>
      &nbsp;indicates that the client has law enforcement.
    </p>
    <p>The yellow dot indicates that there is an open alert for this client that relates to their law enforcement status.</p>
  </span>
</ng-template>

<ng-template #advToolTip>
  <span>
    <p>
      <mon-alerticon title="Adverse Media" [small]="true" icon="icon-tv"></mon-alerticon>
      &nbsp;indicates that the client has adverse media.
    </p>
    <p>The yellow dot indicates that there is an open alert for this client that relates to their adverse media status.</p>
  </span>
</ng-template>

<ng-template #linkToolTip>
  <span>
    <p>
      <mon-alerticon title="Matches" [small]="true" icon="icon-link"></mon-alerticon>
      &nbsp;indicates that matches have been found for the client and is typically displayed when a match has not yet been selected.
    </p>
    <p>The yellow dot indicates that there is an open alert for this client that relates to new matching profiles being found.</p>
  </span>
</ng-template>

<ng-template #monToolTip>
  <span>
    <p>
      <mon-alerticon title="Monitored" [small]="true" icon="icon-eye"></mon-alerticon>
      &nbsp;Indicates that no matching profiles have been found so far.
    </p>
    <p>The yellow dot indicates that there is an open alert for this client and typically indicates that the user re-opened the alert manually.</p>
  </span>
</ng-template>

<ng-template #discountToolTip>
  <span>
    <p>
      <mon-alerticon title="Discounted" [small]="true" icon="icon-eye-crossed"></mon-alerticon>
      &nbsp;indicates that the client has had all matching profiles discounted.
    </p>
    <p>The yellow dot indicates that there is an open alert for this client and typically indicates that the user re-opened the alert manually.</p>
  </span>
</ng-template>

<ng-template #voidedToolTip>
  <span>
    <p>
      <mon-alerticon title="Voided" [small]="true" icon="icon-unlink"></mon-alerticon>
      &nbsp;indicates that the clients matched profile no longer meets the minimum threshold % configured for Monitoring. No further match updates will appear for this profile.
    </p>
    <p>Please review and action the available matches for this client to ensure the client is monitored appropriately.</p>
    <p>The yellow dot indicates that there is an open alert for this client that relates to the matched profile being rendered as void.</p>
  </span>
</ng-template>
<ng-template #noLAMPSToolTip>
  <span>
    <p>
      <mon-alerticon title="NoRisk&ComplianceData" [small]="true" icon="icon-checkmark-circle"></mon-alerticon>
      &nbsp;indicates that the client has no risk and compliance data.
    </p>
  </span>
</ng-template>