<div class="container">
    <div class="modal-header">
        <h4 class="col">{{isSuccess ? title + " Document Uploaded" : "Upload " + title + " Document"}}</h4>
        <button type="button" class="close float-end col-auto" aria-label="Close" (click)="closeModals()">
            <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span
                    class="icon-cross"></span></span>
        </button>
    </div>
    <div class="modal-body overflow-visible">
        @switch (isSuccess) {
        @case (true) {
        <div class="pt-2">
            <div class="alert alert-success">
                Your file has been uploaded.
            </div>
        </div>
        <div class="float-end p-2">
            <button class="btn m-1 bg-secondary text-light" (click)="closeModals()">Close</button>
        </div>
        }
        @default {
        <label for="file">Upload file</label>
        <div class="pt-2">
            <input type="file" class="form-control" id="formFile" (change)="onFileChange($event)">
        </div>
        <div *ngIf="fileTooLarge" class="pt-2">
            <div class="alert alert-danger">
                This file is too large to upload.
                File size must be under {{maxFileSizeInKilobytes}}KB.
                Current size: {{selectedFileSizeInKilobytes}}KB.
            </div>
        </div>
        <div *ngIf="wrongMimeType" class="pt-2">
            <div class="alert alert-danger">
                This file is not of the right format.
            </div>
        </div>
        <div class="float-end p-2">
            <button class="btn m-1 bg-secondary text-light" (click)="closeModals()">Cancel</button>
            <button class="btn m-1 bg-primary text-light" [disabled]="selectedFile == null || isLoading"
                (click)="uploadFile()">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm btn-padding"></span>
                <span *ngIf="!isLoading" class="icon-upload2" title="upload icon">&nbsp;Upload</span>
            </button>
        </div>
        }


        }
    </div>
    <div *ngIf="validationErrors && validationErrors.length > 0" class="modal-body">
        <div class="alert alert-danger pt-2">
            <p>The upload failed because of the validation error below.
                Please correct this error and retry uploading.</p>
            <hr>
            <p class="mb-0">{{validationErrors[0]}}</p>
        </div>
    </div>
</div>