import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReadMoreComponent } from './readmore.component';

@NgModule({
    declarations:   [ReadMoreComponent],
    imports:        [BrowserModule],
    exports:        [ReadMoreComponent] 
})
export class ReadMoreModule { }
