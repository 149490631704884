import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { TagsService } from './tags.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnDestroy {

  @Input() editable: boolean = false;
  @Input() tagsInput: string[] = [];
  @Output() tagsInputChange = new EventEmitter<string[]>();

  private getTagsSubscription: Subscription;
  public placeholder: string = "Search for tags..";
  public isLoading: boolean = true; 
  public existingClientTags: string[] = [];

  constructor(private tagService: TagsService) { }

  ngOnInit(): void {
    this.getTagsSubscription = this.tagService.getClientTags().subscribe(res => {
      if (res.isSuccess) {
        this.existingClientTags = [...res.data?.tags]
        this.isLoading = false;
      }
    },
    error => {
      console.log(error);
      this.isLoading = false;
      this.placeholder = "Something went wrong, please refresh or contact support."
    });
  }

  ngOnDestroy() {
    this.getTagsSubscription.unsubscribe()
  }

  modelChange(){
    this.tagsInputChange.emit(this.tagsInput);
  }

  public addCustomTag = (term) => ({value: term});

}
