export enum FeatureState {
    SEARCH = 'search',
    SCREEN = 'screen',
    MONITOR = 'monitor',
    OUTOFCREDITS = 'OutOfCredits',
    IDV = 'idv',
    ASSESSMENT = 'risk-assessment',
    APISETTINGS = 'api',
    ASSESSMENTSETTINGS = 'assessment-settings',
    INTELLIGENCE = 'intelligence',
    REGULATORYREPORT = 'regulatory-report'
}

export enum GoogleTagClickEvents {
    IDV_NAV = 'eIDV-nav',
    ASSESSMENT_NAV = 'Assessment-nav',
    MONITOR_NAV = 'Monitor-nav',
    SEARCH_NAV = 'Search-nav',
    API_SETTINGS_NAV = 'APISettings-nav',
    ASSESSMENT_SETTINGS_NAV = 'AssessmentSettings-nav',
    LAUNCH_INVESTIGATION = 'LaunchInvestigation',
    MOVE_TO_MONITORING = 'MoveToMonitoring',
    CREDITS_REMAINING = 'CreditsRemaining',
    INTELLIGENCE_NAV = 'Intelligence-nav',
    REGULATORYREPORT_NAV = 'RegulatoryReport-nav'
}

export enum ExceptionPropertyNames
{
    RESPONSETOOLARGE = "ResponseTooLarge"
}