<div>
    <div class="mt-3">
        <div class="row">
            <div class="col monitor-background">
                <div class="row mb-4 sc-search-container">
                    <div class="col-3 no-padding-left sc-advance-btns">
                        <h2>Intelligence&nbsp;Administration</h2>
                    </div>
                    <div class="col">
                        <div class="col d-flex justify-content-end">
                            <div>
                                <app-intelligence-request-button [isIntelAdmin]="true"></app-intelligence-request-button>
                            </div>
                        </div>      
                    </div>
                </div>
                <div class="row detail-table">
                    <int-admin-list
                        [clientList]="intelligenceAdminService.TenantProjectList" 
                        [clientTotal]="intelligenceAdminService.TenantProjectListTotal"
                        [currentPage]="intelligenceAdminService.TenantProjectListPage" 
                        (changePageEvent)="changePage($event)"
                        (viewAuditHistoryEvent)="viewAudit($event)"
                        (showEditDialogEvent)="showEditDialog($event)">
                    </int-admin-list>
                </div>
            </div>
        </div>  
    </div>
</div>