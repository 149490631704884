import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/utilitities/utilities';
import { BusinessMatch, Client, IndividualMatch } from 'src/nswag';
import { MatchesService } from '../matches.service';

enum matchingState {
  checking,
  nothingFound,
  individualsFound,
  businessesFound
}

@Component({
  selector: 'mon-matches',
  templateUrl: './matches.component.html',
  styleUrls: ['./matches.component.scss']
})

export class MatchesComponent {

  public viewedMoreMatches: boolean = false;
  matchingState = matchingState;
  currentState = matchingState.checking;
  get isBusy() : boolean {
    return this.matchesService.isBusy;
  } 

  @Input() threshold: number;
  @Input() individualMatches: IndividualMatch[];
  @Input() businessMatches: BusinessMatch[];
  @Input() searchName: string;
  @Input() searchFirstName: string;
  @Input() searchOtherName: string;
  @Input() searchLastName: string;
  @Input() client : Client;
  
  @Output() businessMatch = new EventEmitter<BusinessMatch>();
  @Output() individualMatch = new EventEmitter<IndividualMatch>();

  constructor(private activeModal: NgbActiveModal, private matchesService: MatchesService, protected modalService: NgbModal)  { }

  init() {
    if (this.individualMatches) {
      if (this.individualMatches?.length > 0) {
        this.currentState = matchingState.individualsFound;
      }
      else {
        this.currentState = matchingState.nothingFound;
      }
    }
    else if (this.businessMatches) {
      if (this.businessMatches?.length > 0) {
        this.currentState = matchingState.businessesFound;
      }
      else {
        this.currentState = matchingState.nothingFound;
      }
    }
    else {
      this.currentState = matchingState.nothingFound;
    }
  }
  
  setViewedMoreMatches() {
    this.viewedMoreMatches = true;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  isIndividual() : boolean {
    return this.individualMatches != null;
  }

  fullName(): string{
    return UtilityService.getIndividualFullName(this.searchName, this.searchFirstName, this.searchOtherName, this.searchLastName);
  }

  isBusiness() : boolean{
    return this.businessMatches != null;
  }

  setIndividualMatch(match : IndividualMatch) {
    this.individualMatch.emit(match);
  }

  setBusinessMatch(match : BusinessMatch) {
    this.businessMatch.emit(match);
  }

  recordNoMatches() {
    if (this.isIndividual()) {
      this.individualMatch.emit();
    }
    else {
      this.businessMatch.emit();
    }
  }
}
