import { Injectable } from '@angular/core';
import {  ScreeningAuditLogs, AuditLog, ScreeningClient } from 'src/nswag';

@Injectable({
  providedIn: 'root'
})

export class AuditService {
  public static pageSize = 10;

  constructor(private screenClient: ScreeningClient) {
  }

  private currentAuditList: ScreeningAuditLogs;
  private currentAuditListTotal: number;
  private currentAuditListQuery: number;

  public get AuditList(): AuditLog[] {
    return this.currentAuditList?.logEntries;
  }

  public get AuditListTotal(): number {
    return this.currentAuditListTotal ?? 0;
  }

  public get AuditListPage(): number {
    return this.currentAuditListQuery ?? 1;
  }

  public getScreeningAuditLogs(skip: number, timeframe: string) {
    var obs =  this.screenClient.getScreeningLogs(skip, timeframe);
    obs.subscribe(result => {
      if (result.isSuccess){
        this.currentAuditList = result.data;
        this.currentAuditListTotal = result.data.logEntries.length;
      }
    });
  }  
}
