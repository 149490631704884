<div class="row" id="userDetailsForm">
    <div class="col-md-10 offset-md-1 form-1-box wow fadeInUp">
        <fieldset class="form-group border p-3" *ngIf="emailConfirmed">
            <legend>My Profile</legend>
            <div>
                <div class="form-group">
                    <label for="firstName">First name</label>
                    <input type="text" class="form-control" [(ngModel)]="firstName" id="firstName" name="FirstName" aria-describedby="firstNameHelpBlock" />
                    <small id="firstNameHelpBlock" class="text-danger"></small>
                </div>
                
                <div class="form-group">
                    <label for="lastName">Last name</label>
                    <input type="text" class="form-control" [(ngModel)]="lastName" id="lastName" name="LastName" aria-describedby="lastNameHelpBlock" />
                    <small id="lastNameHelpBlock" class="text-danger"></small>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <div class="input-group">
                        <span class="input-group-text">
                            <i class="icon-envelope"></i>
                        </span>
                        <input type="text" class="form-control" [(ngModel)]="email" id="email" name="Email" aria-describedby="emailHelpBlock" />                    
                        <span class="input-group-text">
                                <input type="checkbox" title="Email confirmed" disabled [checked]="emailConfirmed" id="emailConfirmed" name="EmailConfirmed" aria-describedby="emailHelpBlock" />
                        </span>
                    </div>
                    <small id="emailHelpBlock" class="text-danger"></small>
                </div>
                <div>
                    <button class="btn btn-primary" (click)="updateProfile()">Update</button>
                </div>
            </div>
        </fieldset>
        <!-- <fieldset class="form-group border p-3">
            <legend>Change password</legend>
            <div>
                <div class="form-group">
                    <label for="currentPassword">Current password</label>
                    <input type="password" class="form-control" [(ngModel)]="changePasswordModel.currentPassword" id="currentPassword" name="CurrentPassword" aria-describedby="currentPasswordHelpBlock" />
                    <small id="currentPasswordHelpBlock" class="text-danger"></small>
                </div>
                <div class="form-group">
                    <label for="newPassword">New password</label>
                    <input type="password" class="form-control" [(ngModel)]="changePasswordModel.newPassword" id="newPassword" name="NewPassword" aria-describedby="newPasswordHelpBlock" />
                    <small id="newPasswordHelpBlock" class="text-danger"></small>
                </div>
                <div class="form-group">
                    <label for="confirmPassword">Confirm password</label>
                    <input type="password" class="form-control" [(ngModel)]="changePasswordModel.confirmPassword" id="confirmPassword" name="ConfirmPassword" aria-describedby="confirmPasswordHelpBlock" />
                    <small id="confirmPasswordHelpBlock" class="text-danger"></small>
                </div>
                <div>
                    <button class="btn btn-primary" (click)="changePassword()">Update</button>
                </div>
            </div>
        </fieldset> -->
        <fieldset class="form-group border p-3 secure">
            <legend>Security</legend>
            <div class="piped">
                <a href="/Identity/Account/Manage/ChangePassword">Change password</a>
                <a href="/Identity/Account/Manage/TwoFactorAuthentication">Two-factor authentication</a>
                <a href="/Identity/Account/Manage/ExternalLogins">External logins</a>
            </div>
        </fieldset>
    </div>
</div>