import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchesComponent } from './matches/matches.component';
import { FinCrimeCheckClient, MonitoringClient } from 'src/nswag';
import { IndividualMatchesComponent } from './matches/individualmatches/individualmatches.component';
import { BusinessMatchesComponent } from './matches/businessmatches/businessmatches.component';
import { LoaderModule } from '../loader/loader.module';
import { ReadMoreModule } from '../_readmore/readmore.module';
import { ProfileDetailsModule } from '../profile-details/profile-details.module';
import { LampsPanelsModule } from '../lamps-panels/lamps-panels.module';
import { MatchReportComponent } from './match-report/match-report.component';
import { AlertIconsModule } from '../alert-icons/alter-icons.module';
import { SelectMatchReportComponent } from './match-report/select-match-report/select-match-report.component';
import { FormsModule } from '@angular/forms';
import { LampsGroupComponent } from './matches/lamps-group/lamps-group.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { ProfileRequestModule } from '../profile-request/profile-request.module';


@NgModule({
  declarations: [
    MatchesComponent,
    IndividualMatchesComponent,
    BusinessMatchesComponent,
    MatchReportComponent,
    SelectMatchReportComponent,
    LampsGroupComponent,
  ],
  imports: [
    CommonModule,
    LoaderModule,
    ReadMoreModule,
    ProfileDetailsModule,
    LampsPanelsModule,
    AlertIconsModule,
    FormsModule,
    DatePickerModule,
    ProfileRequestModule
  ],
  providers: [
    MonitoringClient,
    FinCrimeCheckClient
  ],
  exports: [
    IndividualMatchesComponent,
    BusinessMatchesComponent,
    MatchReportComponent
  ]
})
export class MatchesModule { }
