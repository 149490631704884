export class SubjectEditAuditLog implements ISubjectEditAuditLog {
    constructor() { }
    fieldName: string = "";
    fieldValue: string = "";
    subjectName: string = "";
}

export interface ISubjectEditAuditLog {
    fieldName: string;
    fieldValue: string;
    subjectName: string;
}

export enum DiligenciaLanguage {
    Arabic,
    English,
    Farsi
}
  