<div class="style-background border">
    <legend>Configure Screening Logic</legend>
    <hr class="divider">
    <div *ngIf="searchFeature || screenFeature || monitorFeature then dataProviderOptions"></div> 
    <button class="btn btn-primary" (click)="updateSettings()"  [disabled]="!configSettingsAreValid() || !fieldsAreDirty()">Save</button>
</div>

<ng-template #dataProviderOptions>
    <div class="card mb-5">
        <div class="card-header">
            <label class="card-title">Date of Birth Matching Sensitivity</label>
        </div>
        <div class="card-body">
            <div class="row mb-3">
                <div class="mb-3 col-sm-6">
                    <div ngbDropdown id="dobMatchingDropdown">
                        <button type="button" class="btn btn-outline-primary feature-setting" id="dropdownBasic1" placeholder="Select.." ngbDropdownToggle>{{selectedDobOption}}</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <div *ngFor="let option of dobSensitivityOptions">
                                <button ngbDropdownItem (click)="changeSelectedDropdownItem(option)">{{option}}</button>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-5">
        <div class="card-header">
            <label class="card-title">Screening Confidence Threshold</label>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-8">
                    <input type="range" id="rangeInput" name="rangeInput" class="form-range feature-setting" min="65" max="100" [value]="configurableThreshold" [(ngModel)]="configurableThreshold" oninput="amount.value=rangeInput.value"/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div class="input-group">
                        <output type="text" class="form-control" id="amount" name="amount" for="rangeInput" value="configurableThreshold">{{configurableThreshold}}</output>
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="monitorFeature" class="card mb-5">
        <div class="card-header">
            <label class="card-title">Admin Monitoring Emails Only</label>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-8">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [value]="adminMonitoringEmailsOnly" [(ngModel)]="adminMonitoringEmailsOnly">
                        <label class="form-check-label" for="flexSwitchCheckChecked">Send overnight monitoring emails to admin only</label>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    <div class="card mb-5">
        <div class="card-header">
            <label class="card-title">Enable View More Matches Button</label>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-8">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" [value]="allowSubThreshold" [(ngModel)]="allowSubThreshold">
                    <label class="form-check-label">Enable screening hits below the confidence threshold</label>
                  </div>
                 </div>
            </div>
        </div>
    </div>
</ng-template>
