import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-dynamic-heading',
  template: `
    <ng-container [ngSwitch]="field.props.element">
      <h1 *ngSwitchCase="'h1'" [ngClass]="field.props.class">{{ field.props.text }}</h1>
      <h2 *ngSwitchCase="'h2'" [ngClass]="field.props.class">{{ field.props.text }}</h2>
      <h3 *ngSwitchCase="'h3'" [ngClass]="field.props.class">{{ field.props.text }}</h3>
      <h4 *ngSwitchCase="'h4'" [ngClass]="field.props.class">{{ field.props.text }}</h4>
      <h5 *ngSwitchCase="'h5'" [ngClass]="field.props.class">{{ field.props.text }}</h5>
      <p *ngSwitchCase="'p'" [ngClass]="field.props.class">{{ field.props.text }}</p>
    </ng-container>
  `,
  styleUrls: [
      '../report-content/report-content.component.scss',
      '../regulatory-reports-base.component.scss'
  ]
})
export class FormlyFieldHeading extends FieldType {}
