import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { SearchService } from './services/search.service';
import { SearchCriteria } from './models/search-criteria';
import { EntityTypes, SearchOfficersAndCompaniesByFreeTextSearchType } from 'src/nswag';
import { CountriesService } from '../utils/countries.service';

@Component({
  selector: 'app-search-criteria',
  templateUrl: './search-criteria.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchCriteriaComponent implements OnChanges {

  @Input() criteria!: SearchCriteria;
  @Input() searchType: EntityTypes;
  @Input() centre: boolean = false;
  @Input() fullwidth: boolean = false;

  @Output() criteriaChange = new EventEmitter<SearchCriteria>();
  @Output() run = new EventEmitter<boolean>();
  
  public searchFilterPlaceholderText: string;

  private companySearchTab: number = 1;
  private individualSearchTab: number = 2;
  private defaultTab: number = this.companySearchTab;
  public activeTab: number = this.defaultTab;

  constructor(public searchService: SearchService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.searchFilterPlaceholderText = this.searchType == EntityTypes.Company ? 'Enter the name of the company' : 'Enter the name of the person';
  }

  runSearch() {
    this.criteria.jurisdictionCode = CountriesService.getJurisdictionCode(this.criteria.jurisdictionName);
    this.run.emit(true);
  }

  getSelectedTab(): number {
    let tabId = this.defaultTab;
    if (this.criteria.searchType == EntityTypes.Officer) {
      tabId = this.individualSearchTab;
    } 
    else if (this.criteria.searchType == EntityTypes.Company) {
      tabId = this.companySearchTab;
    }
    return tabId;
  }

  setIndividualSearch() {
    this.criteria.searchType = EntityTypes.Officer;
  }

  setCompanySearch() {
    this.criteria.searchType = EntityTypes.Company;
  }

  isPremiumSearch() {
    return this.criteria.serviceType == SearchOfficersAndCompaniesByFreeTextSearchType.Premium;
  }

  isStandardSearch() {
    return this.criteria.serviceType == SearchOfficersAndCompaniesByFreeTextSearchType.Standard;
  }

  togglePremiumSearch(isPremium: boolean) {
    if (isPremium) {
      this.criteria.serviceType = SearchOfficersAndCompaniesByFreeTextSearchType.Premium;
    } else {
      this.criteria.serviceType = SearchOfficersAndCompaniesByFreeTextSearchType.Standard;
    }
  }

  searchLocationPlaceholder() {
    return this.isStandardSearch() ? 'All Jurisdictions' : (this.criteria.searchType == EntityTypes.Company ? 'Country of Incorporation' : 'Country of Residence');
  }

  setSearchType() {
    this.searchFilterPlaceholderText = this.criteria.searchType == EntityTypes.Company ? 'Enter the name of the company' : 'Enter the name of the person';
  }

  toggleAdvSearch() {
    this.criteria.advSearch = !this.criteria.advSearch;
  }

  premiumSearchEnabled(): boolean {
    return this.searchService.premiumSearchEnabled.getValue();
  }
}
  

