<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title col">Select items to add to the match report by clicking on the checkboxes.</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="closeModal()" >
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross" ></span></span>
    </button>
  </div>
  <div class="modal-body"> 
      <div *ngIf="!isBusiness; then individualMatchesList; else businessMatchesList"></div>
  </div>
  <div class="modal-footer">
    <span id="print-container">
        <button [disabled]="!canCreateReport()" (click)="printList()" class="btn btn-secondary">View Selected Matches Report</button>
    </span>
  </div>
</div>

 <ng-template #individualMatchesList>
    <table class="table table-striped table-themis table-click" aria-labelledby="individualsTableLabel">
      <thead>
        <tr>
          <th scope="col" sortable="">
            <input class="form-check-input" type="checkbox" (change)="selectAll($event)">
          </th>
          <th scope="col">Name</th>
          <th scope="col">Gender</th>
          <th scope="col">Jurisdiction</th>
          <th scope="col">Date&nbsp;of&nbsp;birth</th>
          <th scope="col">Accuracy</th>
        </tr>
      </thead>
      <tbody>         
        <tr *ngFor="let profile of individualMatches" (click)="selectIndividualMatch(profile)">
          <td><input class="form-check-input" type="checkbox" id="labels" name="labels" [ngModel]="checkSelectedIndividualStatus(profile)"></td>
          <td id="filter">{{getFullName(profile)}}</td>
          <td id="filter">{{profile?.gender}}</td>
          <td id="filter">{{getCountry(profile?.countries[0])}}</td>
          <td id="filter">{{profile?.datesOfBirthIso[0]}}</td>
          <td id="filter">{{profile?.score}} %</td>
        </tr>
      </tbody>
    </table>
</ng-template>

<ng-template #businessMatchesList>
    <table class="table table-striped table-themis table-click" aria-labelledby="businessesTableLabel">
      <thead>
        <tr>
          <th scope="col" sortable="">
            <input class="form-check-input" type="checkbox" (change)="selectAll($event)">
          </th>
          <th scope="col">Name</th>
          <th scope="col">Jurisdiction</th>
          <th scope="col">Accuracy</th>
        </tr>
      </thead>
      <tbody>    
        <tr *ngFor="let profile of businessMatches" (click)="selectBusinessMatch(profile)">
          <td><input class="form-check-input" type="checkbox" id="labels" name="labels" [ngModel]="checkSelectedBusinessStatus(profile)"></td>
          <td id="filter">{{profile?.name}}</td>
          <td id="filter">{{getCountry(profile?.countries[0])}}</td>
          <td id="filter">{{profile?.score}} %</td>
        </tr>
      </tbody>
    </table>
</ng-template>