<div *ngIf="problemDetails" class="alert alert-danger alert-dismissible fade show">
    <a role="button" (click)="close()" class="btn-close"></a>
    <!-- <h2 *ngIf="problemDetails.title">{{ problemDetails.title }}</h2> -->
    <!-- <p *ngIf="problemDetails.status"><strong>Status:</strong> {{ problemDetails.status }}</p> -->
    <!-- <p *ngIf="problemDetails.type"><strong>Type:</strong> {{ problemDetails.type }}</p> -->
    <!-- <p *ngIf="problemDetails.detail"><strong>Detail:</strong> {{ problemDetails.detail }}</p> -->
    <!-- <p *ngIf="problemDetails.instance"><strong>Instance:</strong> {{ problemDetails.instance }}</p> -->
    <!-- <p *ngIf="problemDetails.trace"><strong>Trace:</strong> {{ problemDetails.trace }}</p> -->
    <p *ngIf="problemDetails.errors"><strong>Errors:</strong></p>
    <p *ngFor="let error of problemDetails.errors">        
        <span>
            <strong>{{error.propertyName}}:</strong> {{ error.errorMessage }}
        </span>
    </p>
    <!-- <p *ngIf="problemDetails.exception"><strong>Exception:</strong> {{ problemDetails.exception }}</p>     -->
  </div>