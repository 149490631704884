export const country_list = {
	"Countries": [
		{"JurisdictionCode":"ad","JurisdictionName":"Andorra","CountryCode":"AD","CountryName":"Andorra","Nationality":"Andorran"},
		{"JurisdictionCode":"ae","JurisdictionName":"United Arab Emirates","CountryCode":"AE","CountryName":"United Arab Emirates","Nationality":"Emirati, Emirian, Emiri","AlternativeCode":"uae"},
		{"JurisdictionCode":"ae_az","JurisdictionName":"Abu Dhabi (United Arab Emirates)","CountryCode":"AE","CountryName":"United Arab Emirates","Nationality":"Emirati, Emirian, Emiri"},
		{"JurisdictionCode":"ae_du","JurisdictionName":"Dubai (United Arab Emirates)","CountryCode":"AE","CountryName":"United Arab Emirates","Nationality":"Emirati, Emirian, Emiri"},
		{"JurisdictionCode":"af","JurisdictionName":"Afghanistan","CountryCode":"AF","CountryName":"Afghanistan","Nationality":"Afghan"},
		{"JurisdictionCode":"ag","JurisdictionName":"Antigua and Barbuda","CountryCode":"AG","CountryName":"Antigua and Barbuda","Nationality":"Antiguan or Barbudan"},
		{"JurisdictionCode":"ai","JurisdictionName":"Anguilla","CountryCode":"AI","CountryName":"Anguilla","Nationality":"Anguillan"},
		{"JurisdictionCode":"al","JurisdictionName":"Albania","CountryCode":"AL","CountryName":"Albania","Nationality":"Albanian"},
		{"JurisdictionCode":"am","JurisdictionName":"Armenia","CountryCode":"AM","CountryName":"Armenia","Nationality":"Armenian"},
		{"JurisdictionCode":"an","JurisdictionName":"Netherlands Antilles","CountryCode":"AN","CountryName":"Netherlands Antilles","Nationality":"Netherlands Antillean"},
		{"JurisdictionCode":"ao","JurisdictionName":"Angola","CountryCode":"AO","CountryName":"Angola","Nationality":"Angolan"},
		{"JurisdictionCode":"aq","JurisdictionName":"Antarctica","CountryCode":"AQ","CountryName":"Antarctica","Nationality":"Antarctic"},
		{"JurisdictionCode":"ar","JurisdictionName":"Argentina","CountryCode":"AR","CountryName":"Argentina","Nationality":"Argentine"},
		{"JurisdictionCode":"as","JurisdictionName":"American Samoa","CountryCode":"AS","CountryName":"American Samoa","Nationality":"American Samoan"},
		{"JurisdictionCode":"at","JurisdictionName":"Austria","CountryCode":"AT","CountryName":"Austria","Nationality":"Austrian"},
		{"JurisdictionCode":"au","JurisdictionName":"Australia","CountryCode":"AU","CountryName":"Australia","Nationality":"Australian"},
		{"JurisdictionCode":"aw","JurisdictionName":"Aruba","CountryCode":"AW","CountryName":"Aruba","Nationality":"Aruban"},
		{"JurisdictionCode":"az","JurisdictionName":"Azerbaijan","CountryCode":"AZ","CountryName":"Azerbaijan","Nationality":"Azerbaijani, Azeri"},
		{"JurisdictionCode":"ba","JurisdictionName":"Bosnia and Herzegovina","CountryCode":"BA","CountryName":"Bosnia and Herzegovina","Nationality":"Bosnian or Herzegovinian"},
		{"JurisdictionCode":"bb","JurisdictionName":"Barbados","CountryCode":"BB","CountryName":"Barbados","Nationality":"Barbadian"},
		{"JurisdictionCode":"bd","JurisdictionName":"Bangladesh","CountryCode":"BD","CountryName":"Bangladesh","Nationality":"Bangladeshi"},
		{"JurisdictionCode":"be","JurisdictionName":"Belgium","CountryCode":"BE","CountryName":"Belgium","Nationality":"Belgian"},
		{"JurisdictionCode":"bf","JurisdictionName":"Burkina Faso","CountryCode":"BF","CountryName":"Burkina Faso","Nationality":"Burkinabé"},
		{"JurisdictionCode":"bg","JurisdictionName":"Bulgaria","CountryCode":"BG","CountryName":"Bulgaria","Nationality":"Bulgarian"},
		{"JurisdictionCode":"bh","JurisdictionName":"Bahrain","CountryCode":"BH","CountryName":"Bahrain","Nationality":"Bahraini"},
		{"JurisdictionCode":"bi","JurisdictionName":"Burundi","CountryCode":"BI","CountryName":"Burundi","Nationality":"Burundian"},
		{"JurisdictionCode":"bj","JurisdictionName":"Benin","CountryCode":"BJ","CountryName":"Benin","Nationality":"Beninese, Beninois"},
		{"JurisdictionCode":"bl","JurisdictionName":"Saint Barthélemy","CountryCode":"BL","CountryName":"Saint Barthélemy","Nationality":"Barthélemois, Barthélemoises"},
		{"JurisdictionCode":"bm","JurisdictionName":"Bermuda","CountryCode":"BM","CountryName":"Bermuda","Nationality":"Bermudian, Bermudan"},
		{"JurisdictionCode":"bn","JurisdictionName":"Brunei","CountryCode":"BN","CountryName":"Brunei","Nationality":"Bruneian"},
		{"JurisdictionCode":"bo","JurisdictionName":"Bolivia","CountryCode":"BO","CountryName":"Bolivia","Nationality":"Bolivian"},
		{"JurisdictionCode":"br","JurisdictionName":"Brazil","CountryCode":"BR","CountryName":"Brazil","Nationality":"Brazilian"},
		{"JurisdictionCode":"bs","JurisdictionName":"Bahamas","CountryCode":"BS","CountryName":"Bahamas","Nationality":"Bahamian"},
		{"JurisdictionCode":"bt","JurisdictionName":"Bhutan","CountryCode":"BT","CountryName":"Bhutan","Nationality":"Bhutanese"},
		{"JurisdictionCode":"bv","JurisdictionName":"Bouvet Island","CountryCode":"BV","CountryName":"Bouvet Island","Nationality":"Bouvet Islanders"},
		{"JurisdictionCode":"bw","JurisdictionName":"Botswana","CountryCode":"BW","CountryName":"Botswana","Nationality":"Motswana, Botswanan"},
		{"JurisdictionCode":"by","JurisdictionName":"Belarus","CountryCode":"BY","CountryName":"Belarus","Nationality":"Belarusian"},
		{"JurisdictionCode":"bz","JurisdictionName":"Belize","CountryCode":"BZ","CountryName":"Belize","Nationality":"Belizean"},
		{"JurisdictionCode":"ca","JurisdictionName":"Canada","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_ab","JurisdictionName":"Alberta (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_bc","JurisdictionName":"British Columbia (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_mb","JurisdictionName":"Manitoba (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_nb","JurisdictionName":"New Brunswick (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_nl","JurisdictionName":"Newfoundland and Labrador (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_ns","JurisdictionName":"Nova Scotia (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_on","JurisdictionName":"Ontario (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_pe","JurisdictionName":"Prince Edward Island (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_qc","JurisdictionName":"Quebec\/Québec (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"ca_sk","JurisdictionName":"Saskatchewan (Canada)","CountryCode":"CA","CountryName":"Canada","Nationality":"Canadian"},
		{"JurisdictionCode":"cc","JurisdictionName":"Cocos (Keeling) Islands","CountryCode":"CC","CountryName":"Cocos (Keeling) Islands","Nationality":"Cocos Island"},
		{"JurisdictionCode":"cd","JurisdictionName":"Congo, the Democratic Republic of the","CountryCode":"CD","CountryName":"Congo, the Democratic Republic of the","Nationality":"Congolese"},
		{"JurisdictionCode":"cf","JurisdictionName":"Central African Republic","CountryCode":"CF","CountryName":"Central African Republic","Nationality":"Central African"},
		{"JurisdictionCode":"cg","JurisdictionName":"Congo","CountryCode":"CG","CountryName":"Congo","Nationality":"Congolese"},
		{"JurisdictionCode":"ch","JurisdictionName":"Switzerland","CountryCode":"CH","CountryName":"Switzerland","Nationality":"Swiss"},
		{"JurisdictionCode":"ci","JurisdictionName":"Ivory Coast","CountryCode":"CI","CountryName":"Ivory Coast","Nationality":"Ivorian"},
		{"JurisdictionCode":"ck","JurisdictionName":"Cook Islands","CountryCode":"CK","CountryName":"Cook Islands","Nationality":"Cook Island"},
		{"JurisdictionCode":"cl","JurisdictionName":"Chile","CountryCode":"CL","CountryName":"Chile","Nationality":"Chilean"},
		{"JurisdictionCode":"cm","JurisdictionName":"Cameroon","CountryCode":"CM","CountryName":"Cameroon","Nationality":"Cameroonian"},
		{"JurisdictionCode":"cn","JurisdictionName":"China","CountryCode":"CN","CountryName":"China","Nationality":"Chinese"},
		{"JurisdictionCode":"co","JurisdictionName":"Colombia","CountryCode":"CO","CountryName":"Colombia","Nationality":"Colombian"},
		{"JurisdictionCode":"cr","JurisdictionName":"Costa Rica","CountryCode":"CR","CountryName":"Costa Rica","Nationality":"Costa Rican"},
		{"JurisdictionCode":"cu","JurisdictionName":"Cuba","CountryCode":"CU","CountryName":"Cuba","Nationality":"Cuban"},
		{"JurisdictionCode":"cv","JurisdictionName":"Cape Verde","CountryCode":"CV","CountryName":"Cape Verde","Nationality":"Cabo Verdean"},
		{"JurisdictionCode":"cw","JurisdictionName":"Curacao","CountryCode":"CW","CountryName":"Curacao","Nationality":"Curacaoans"},
		{"JurisdictionCode":"cx","JurisdictionName":"Christmas Island","CountryCode":"CX","CountryName":"Christmas Island","Nationality":"Christmas Islanders"},
		{"JurisdictionCode":"cy","JurisdictionName":"Cyprus","CountryCode":"CY","CountryName":"Cyprus","Nationality":"Cypriot"},
		{"JurisdictionCode":"cz","JurisdictionName":"Czech Republic","CountryCode":"CZ","CountryName":"Czech Republic","Nationality":"Czech"},
		{"JurisdictionCode":"de","JurisdictionName":"Germany","CountryCode":"DE","CountryName":"Germany","Nationality":"German"},
		{"JurisdictionCode":"dj","JurisdictionName":"Djibouti","CountryCode":"DJ","CountryName":"Djibouti","Nationality":"Djiboutian"},
		{"JurisdictionCode":"dk","JurisdictionName":"Denmark","CountryCode":"DK","CountryName":"Denmark","Nationality":"Danish"},
		{"JurisdictionCode":"dm","JurisdictionName":"Dominica","CountryCode":"DM","CountryName":"Dominica","Nationality":"Dominican (DM)"},
		{"JurisdictionCode":"do","JurisdictionName":"Dominican Republic","CountryCode":"DO","CountryName":"Dominican Republic","Nationality":"Dominican (DO)"},
		{"JurisdictionCode":"dz","JurisdictionName":"Algeria","CountryCode":"DZ","CountryName":"Algeria","Nationality":"Algerian"},
		{"JurisdictionCode":"ec","JurisdictionName":"Ecuador","CountryCode":"EC","CountryName":"Ecuador","Nationality":"Ecuadorian"},
		{"JurisdictionCode":"ee","JurisdictionName":"Estonia","CountryCode":"EE","CountryName":"Estonia","Nationality":"Estonian"},
		{"JurisdictionCode":"eg","JurisdictionName":"Egypt","CountryCode":"EG","CountryName":"Egypt","Nationality":"Egyptian"},
		{"JurisdictionCode":"eh","JurisdictionName":"Western Sahara","CountryCode":"EH","CountryName":"Western Sahara","Nationality":"Sahrawi, Sahrawian, Sahraouian"},
		{"JurisdictionCode":"er","JurisdictionName":"Eritrea","CountryCode":"ER","CountryName":"Eritrea","Nationality":"Eritrean"},
		{"JurisdictionCode":"es","JurisdictionName":"Spain","CountryCode":"ES","CountryName":"Spain","Nationality":"Spanish"},
		{"JurisdictionCode":"et","JurisdictionName":"Ethiopia","CountryCode":"ET","CountryName":"Ethiopia","Nationality":"Ethiopian"},
		{"JurisdictionCode":"fi","JurisdictionName":"Finland","CountryCode":"FI","CountryName":"Finland","Nationality":"Finnish"},
		{"JurisdictionCode":"fj","JurisdictionName":"Fiji","CountryCode":"FJ","CountryName":"Fiji","Nationality":"Fijian"},
		{"JurisdictionCode":"fk","JurisdictionName":"Falkland Islands (Malvinas)","CountryCode":"FK","CountryName":"Falkland Islands (Malvinas)","Nationality":"Falkland Island"},
		{"JurisdictionCode":"fm","JurisdictionName":"Micronesia, Federated States of","CountryCode":"FM","CountryName":"Micronesia, Federated States of","Nationality":"Micronesian"},
		{"JurisdictionCode":"fo","JurisdictionName":"Faroe Islands","CountryCode":"FO","CountryName":"Faroe Islands","Nationality":"Faroese"},
		{"JurisdictionCode":"fr","JurisdictionName":"France","CountryCode":"FR","CountryName":"France","Nationality":"French"},
		{"JurisdictionCode":"ga","JurisdictionName":"Gabon","CountryCode":"GA","CountryName":"Gabon","Nationality":"Gabonese"},
		{"JurisdictionCode":"gb","JurisdictionName":"United Kingdom","CountryCode":"GB","CountryName":"United Kingdom","Nationality":"British, UK","AlternativeCode":"uk"},
		{"JurisdictionCode":"gd","JurisdictionName":"Grenada","CountryCode":"GD","CountryName":"Grenada","Nationality":"Grenadian"},
		{"JurisdictionCode":"ge","JurisdictionName":"Georgia","CountryCode":"GE","CountryName":"Georgia","Nationality":"Georgian"},
		{"JurisdictionCode":"gf","JurisdictionName":"French Guiana","CountryCode":"GF","CountryName":"French Guiana","Nationality":"French Guianese"},
		{"JurisdictionCode":"gg","JurisdictionName":"Guernsey","CountryCode":"GG","CountryName":"Guernsey","Nationality":"Channel Island (GG)"},
		{"JurisdictionCode":"gh","JurisdictionName":"Ghana","CountryCode":"GH","CountryName":"Ghana","Nationality":"Ghanaian"},
		{"JurisdictionCode":"gi","JurisdictionName":"Gibraltar","CountryCode":"GI","CountryName":"Gibraltar","Nationality":"Gibraltarians"},
		{"JurisdictionCode":"gl","JurisdictionName":"Greenland","CountryCode":"GL","CountryName":"Greenland","Nationality":"Greenlandic"},
		{"JurisdictionCode":"gm","JurisdictionName":"Gambia","CountryCode":"GM","CountryName":"Gambia","Nationality":"Gambian"},
		{"JurisdictionCode":"gn","JurisdictionName":"Guinea","CountryCode":"GN","CountryName":"Guinea","Nationality":"Guinean"},
		{"JurisdictionCode":"gp","JurisdictionName":"Guadeloupe","CountryCode":"GP","CountryName":"Guadeloupe","Nationality":"Guadeloupians, Guadeloupeans"},
		{"JurisdictionCode":"gq","JurisdictionName":"Equatorial Guinea","CountryCode":"GQ","CountryName":"Equatorial Guinea","Nationality":"Equatorial Guinean, Equatoguinean"},
		{"JurisdictionCode":"gr","JurisdictionName":"Greece","CountryCode":"GR","CountryName":"Greece","Nationality":"Greek, Hellenic"},
		{"JurisdictionCode":"gs","JurisdictionName":"South Georgia and the South Sandwich Islands","CountryCode":"GS","CountryName":"South Georgia and the South Sandwich Islands","Nationality":"South Georgia or South Sandwich Islands"},
		{"JurisdictionCode":"gt","JurisdictionName":"Guatemala","CountryCode":"GT","CountryName":"Guatemala","Nationality":"Guatemalan"},
		{"JurisdictionCode":"gu","JurisdictionName":"Guam","CountryCode":"GU","CountryName":"Guam","Nationality":"Guamanian, Guambat"},
		{"JurisdictionCode":"gw","JurisdictionName":"Guinea-Bissau","CountryCode":"GW","CountryName":"Guinea-Bissau","Nationality":"Bissau-Guinean"},
		{"JurisdictionCode":"gy","JurisdictionName":"Guyana","CountryCode":"GY","CountryName":"Guyana","Nationality":"Guyanese"},
		{"JurisdictionCode":"hk","JurisdictionName":"Hong Kong","CountryCode":"HK","CountryName":"Hong Kong","Nationality":"Hong Kong, Hong Kongese"},
		{"JurisdictionCode":"hm","JurisdictionName":"Heard Island and McDonald Islands","CountryCode":"HM","CountryName":"Heard Island and McDonald Islands","Nationality":"Heard Island or McDonald Islands"},
		{"JurisdictionCode":"hn","JurisdictionName":"Honduras","CountryCode":"HN","CountryName":"Honduras","Nationality":"Honduran"},
		{"JurisdictionCode":"hr","JurisdictionName":"Croatia","CountryCode":"HR","CountryName":"Croatia","Nationality":"Croatian"},
		{"JurisdictionCode":"ht","JurisdictionName":"Haiti","CountryCode":"HT","CountryName":"Haiti","Nationality":"Haitian"},
		{"JurisdictionCode":"hu","JurisdictionName":"Hungary","CountryCode":"HU","CountryName":"Hungary","Nationality":"Hungarian, Magyar"},
		{"JurisdictionCode":"id","JurisdictionName":"Indonesia","CountryCode":"ID","CountryName":"Indonesia","Nationality":"Indonesian"},
		{"JurisdictionCode":"ie","JurisdictionName":"Ireland","CountryCode":"IE","CountryName":"Ireland","Nationality":"Irish"},
		{"JurisdictionCode":"il","JurisdictionName":"Israel","CountryCode":"IL","CountryName":"Israel","Nationality":"Israeli"},
		{"JurisdictionCode":"im","JurisdictionName":"Isle of Man","CountryCode":"IM","CountryName":"Isle of Man","Nationality":"Manx"},
		{"JurisdictionCode":"in","JurisdictionName":"India","CountryCode":"IN","CountryName":"India","Nationality":"Indian"},
		{"JurisdictionCode":"io","JurisdictionName":"British Indian Ocean Territory","CountryCode":"IO","CountryName":"British Indian Ocean Territory","Nationality":"BIOT"},
		{"JurisdictionCode":"iq","JurisdictionName":"Iraq","CountryCode":"IQ","CountryName":"Iraq","Nationality":"Iraqi"},
		{"JurisdictionCode":"ir","JurisdictionName":"Iran, Islamic Republic of","CountryCode":"IR","CountryName":"Iran, Islamic Republic of","Nationality":"Iranian, Persian"},
		{"JurisdictionCode":"is","JurisdictionName":"Iceland","CountryCode":"IS","CountryName":"Iceland","Nationality":"Icelandic"},
		{"JurisdictionCode":"it","JurisdictionName":"Italy","CountryCode":"IT","CountryName":"Italy","Nationality":"Italian"},
		{"JurisdictionCode":"je","JurisdictionName":"Jersey","CountryCode":"JE","CountryName":"Jersey","Nationality":"Channel Island (JE)"},
		{"JurisdictionCode":"jm","JurisdictionName":"Jamaica","CountryCode":"JM","CountryName":"Jamaica","Nationality":"Jamaican"},
		{"JurisdictionCode":"jo","JurisdictionName":"Jordan","CountryCode":"JO","CountryName":"Jordan","Nationality":"Jordanian"},
		{"JurisdictionCode":"jp","JurisdictionName":"Japan","CountryCode":"JP","CountryName":"Japan","Nationality":"Japanese"},
		{"JurisdictionCode":"ke","JurisdictionName":"Kenya","CountryCode":"KE","CountryName":"Kenya","Nationality":"Kenyan"},
		{"JurisdictionCode":"kg","JurisdictionName":"Kyrgyzstan","CountryCode":"KG","CountryName":"Kyrgyzstan","Nationality":"Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"},
		{"JurisdictionCode":"kh","JurisdictionName":"Cambodia","CountryCode":"KH","CountryName":"Cambodia","Nationality":"Cambodian"},
		{"JurisdictionCode":"ki","JurisdictionName":"Kiribati","CountryCode":"KI","CountryName":"Kiribati","Nationality":"I-Kiribati"},
		{"JurisdictionCode":"km","JurisdictionName":"Comoros","CountryCode":"KM","CountryName":"Comoros","Nationality":"Comoran, Comorian"},
		{"JurisdictionCode":"kn","JurisdictionName":"Saint Kitts and Nevis","CountryCode":"KN","CountryName":"Saint Kitts and Nevis","Nationality":"Kittitian or Nevisian"},
		{"JurisdictionCode":"kp","JurisdictionName":"Korea, Democratic People's Republic of","CountryCode":"KP","CountryName":"Korea, Democratic People's Republic of","Nationality":"North Korean"},
		{"JurisdictionCode":"kr","JurisdictionName":"South Korea","CountryCode":"KR","CountryName":"South Korea","Nationality":"South Korean"},
		{"JurisdictionCode":"kw","JurisdictionName":"Kuwait","CountryCode":"KW","CountryName":"Kuwait","Nationality":"Kuwaiti"},
		{"JurisdictionCode":"ky","JurisdictionName":"Cayman Islands","CountryCode":"KY","CountryName":"Cayman Islands","Nationality":"Caymanian"},
		{"JurisdictionCode":"kz","JurisdictionName":"Kazakhstan","CountryCode":"KZ","CountryName":"Kazakhstan","Nationality":"Kazakhstani, Kazakh"},
		{"JurisdictionCode":"la","JurisdictionName":"Lao People's Democratic Republic","CountryCode":"LA","CountryName":"Lao People's Democratic Republic","Nationality":"Lao, Laotian"},
		{"JurisdictionCode":"lb","JurisdictionName":"Lebanon","CountryCode":"LB","CountryName":"Lebanon","Nationality":"Lebanese"},
		{"JurisdictionCode":"lc","JurisdictionName":"Saint Lucia","CountryCode":"LC","CountryName":"Saint Lucia","Nationality":"Saint Lucian"},
		{"JurisdictionCode":"li","JurisdictionName":"Liechtenstein","CountryCode":"LI","CountryName":"Liechtenstein","Nationality":"Liechtensteiners"},
		{"JurisdictionCode":"lk","JurisdictionName":"Sri Lanka","CountryCode":"LK","CountryName":"Sri Lanka","Nationality":"Sri Lankan"},
		{"JurisdictionCode":"lr","JurisdictionName":"Liberia","CountryCode":"LR","CountryName":"Liberia","Nationality":"Liberian"},
		{"JurisdictionCode":"ls","JurisdictionName":"Lesotho","CountryCode":"LS","CountryName":"Lesotho","Nationality":"Basotho"},
		{"JurisdictionCode":"lt","JurisdictionName":"Lithuania","CountryCode":"LT","CountryName":"Lithuania","Nationality":"Lithuanian"},
		{"JurisdictionCode":"lu","JurisdictionName":"Luxembourg","CountryCode":"LU","CountryName":"Luxembourg","Nationality":"Luxembourg, Luxembourgish"},
		{"JurisdictionCode":"lv","JurisdictionName":"Latvia","CountryCode":"LV","CountryName":"Latvia","Nationality":"Latvian"},
		{"JurisdictionCode":"ly","JurisdictionName":"Libya","CountryCode":"LY","CountryName":"Libya","Nationality":"Libyan"},
		{"JurisdictionCode":"ma","JurisdictionName":"Morocco","CountryCode":"MA","CountryName":"Morocco","Nationality":"Moroccan"},
		{"JurisdictionCode":"mc","JurisdictionName":"Monaco","CountryCode":"MC","CountryName":"Monaco","Nationality":"Monégasque, Monacan"},
		{"JurisdictionCode":"md","JurisdictionName":"Moldova, Republic of","CountryCode":"MD","CountryName":"Moldova, Republic of","Nationality":"Moldovan"},
		{"JurisdictionCode":"me","JurisdictionName":"Montenegro","CountryCode":"ME","CountryName":"Montenegro","Nationality":"Montenegrin"},
		{"JurisdictionCode":"mf","JurisdictionName":"Saint Martin (French part)","CountryCode":"MF","CountryName":"Saint Martin (French part)","Nationality":"Saint-Martinois, Saint-Martinoises"},
		{"JurisdictionCode":"mg","JurisdictionName":"Madagascar","CountryCode":"MG","CountryName":"Madagascar","Nationality":"Malagasy"},
		{"JurisdictionCode":"mh","JurisdictionName":"Marshall Islands","CountryCode":"MH","CountryName":"Marshall Islands","Nationality":"Marshallese"},
		{"JurisdictionCode":"mk","JurisdictionName":"Macedonia, the former Yugoslav Republic of","CountryCode":"MK","CountryName":"Macedonia, the former Yugoslav Republic of","Nationality":"Macedonian"},
		{"JurisdictionCode":"ml","JurisdictionName":"Mali","CountryCode":"ML","CountryName":"Mali","Nationality":"Malian, Malinese"},
		{"JurisdictionCode":"mm","JurisdictionName":"Burma","CountryCode":"MM","CountryName":"Burma","Nationality":"Burmese"},
		{"JurisdictionCode":"mn","JurisdictionName":"Mongolia","CountryCode":"MN","CountryName":"Mongolia","Nationality":"Mongolian"},
		{"JurisdictionCode":"mo","JurisdictionName":"Macao","CountryCode":"MO","CountryName":"Macao","Nationality":"Macanese, Chinese"},
		{"JurisdictionCode":"mp","JurisdictionName":"Northern Mariana Islands","CountryCode":"MP","CountryName":"Northern Mariana Islands","Nationality":"Northern Marianan"},
		{"JurisdictionCode":"mq","JurisdictionName":"Martinique","CountryCode":"MQ","CountryName":"Martinique","Nationality":"Martiniquais, Martinican"},
		{"JurisdictionCode":"mr","JurisdictionName":"Mauritania","CountryCode":"MR","CountryName":"Mauritania","Nationality":"Mauritanian"},
		{"JurisdictionCode":"ms","JurisdictionName":"Montserrat","CountryCode":"MS","CountryName":"Montserrat","Nationality":"Montserratian"},
		{"JurisdictionCode":"mt","JurisdictionName":"Malta","CountryCode":"MT","CountryName":"Malta","Nationality":"Maltese"},
		{"JurisdictionCode":"mu","JurisdictionName":"Mauritius","CountryCode":"MU","CountryName":"Mauritius","Nationality":"Mauritian"},
		{"JurisdictionCode":"mv","JurisdictionName":"Maldives","CountryCode":"MV","CountryName":"Maldives","Nationality":"Maldivian"},
		{"JurisdictionCode":"mw","JurisdictionName":"Malawi","CountryCode":"MW","CountryName":"Malawi","Nationality":"Malawian"},
		{"JurisdictionCode":"mx","JurisdictionName":"Mexico","CountryCode":"MX","CountryName":"Mexico","Nationality":"Mexican"},
		{"JurisdictionCode":"my","JurisdictionName":"Malaysia","CountryCode":"MY","CountryName":"Malaysia","Nationality":"Malaysian"},
		{"JurisdictionCode":"mz","JurisdictionName":"Mozambique","CountryCode":"MZ","CountryName":"Mozambique","Nationality":"Mozambican"},
		{"JurisdictionCode":"na","JurisdictionName":"Namibia","CountryCode":"NA","CountryName":"Namibia","Nationality":"Namibian"},
		{"JurisdictionCode":"nc","JurisdictionName":"New Caledonia","CountryCode":"NC","CountryName":"New Caledonia","Nationality":"New Caledonian"},
		{"JurisdictionCode":"ne","JurisdictionName":"Niger","CountryCode":"NE","CountryName":"Niger","Nationality":"Nigerien"},
		{"JurisdictionCode":"nf","JurisdictionName":"Norfolk Island","CountryCode":"NF","CountryName":"Norfolk Island","Nationality":"Norfolk Islanders"},
		{"JurisdictionCode":"ng","JurisdictionName":"Nigeria","CountryCode":"NG","CountryName":"Nigeria","Nationality":"Nigerian"},
		{"JurisdictionCode":"ni","JurisdictionName":"Nicaragua","CountryCode":"NI","CountryName":"Nicaragua","Nationality":"Nicaraguan"},
		{"JurisdictionCode":"nl","JurisdictionName":"Netherlands","CountryCode":"NL","CountryName":"Netherlands","Nationality":"Dutch, Netherlandic"},
		{"JurisdictionCode":"no","JurisdictionName":"Norway","CountryCode":"NO","CountryName":"Norway","Nationality":"Norwegian"},
		{"JurisdictionCode":"np","JurisdictionName":"Nepal","CountryCode":"NP","CountryName":"Nepal","Nationality":"Nepali, Nepalese"},
		{"JurisdictionCode":"nr","JurisdictionName":"Nauru","CountryCode":"NR","CountryName":"Nauru","Nationality":"Nauruan"},
		{"JurisdictionCode":"nu","JurisdictionName":"Niue","CountryCode":"NU","CountryName":"Niue","Nationality":"Niuean"},
		{"JurisdictionCode":"nz","JurisdictionName":"New Zealand","CountryCode":"NZ","CountryName":"New Zealand","Nationality":"New Zealand, NZ"},
		{"JurisdictionCode":"om","JurisdictionName":"Oman","CountryCode":"OM","CountryName":"Oman","Nationality":"Omani"},
		{"JurisdictionCode":"pa","JurisdictionName":"Panama","CountryCode":"PA","CountryName":"Panama","Nationality":"Panamanian"},
		{"JurisdictionCode":"pe","JurisdictionName":"Peru","CountryCode":"PE","CountryName":"Peru","Nationality":"Peruvian"},
		{"JurisdictionCode":"pf","JurisdictionName":"French Polynesia","CountryCode":"PF","CountryName":"French Polynesia","Nationality":"French Polynesian"},
		{"JurisdictionCode":"pg","JurisdictionName":"Papua New Guinea","CountryCode":"PG","CountryName":"Papua New Guinea","Nationality":"Papua New Guinean, Papuan"},
		{"JurisdictionCode":"ph","JurisdictionName":"Philippines","CountryCode":"PH","CountryName":"Philippines","Nationality":"Philippine, Filipino"},
		{"JurisdictionCode":"pk","JurisdictionName":"Pakistan","CountryCode":"PK","CountryName":"Pakistan","Nationality":"Pakistani"},
		{"JurisdictionCode":"pl","JurisdictionName":"Poland","CountryCode":"PL","CountryName":"Poland","Nationality":"Polish"},
		{"JurisdictionCode":"pm","JurisdictionName":"Saint Pierre and Miquelon","CountryCode":"PM","CountryName":"Saint Pierre and Miquelon","Nationality":"Saint-Pierrais or Miquelonnais"},
		{"JurisdictionCode":"pn","JurisdictionName":"Pitcairn","CountryCode":"PN","CountryName":"Pitcairn","Nationality":"Pitcairn Island"},
		{"JurisdictionCode":"pr","JurisdictionName":"Puerto Rico","CountryCode":"PR","CountryName":"Puerto Rico","Nationality":"Puerto Rican"},
		{"JurisdictionCode":"ps","JurisdictionName":"Palestine","CountryCode":"PS","CountryName":"Palestine","Nationality":"Palestinian"},
		{"JurisdictionCode":"pt","JurisdictionName":"Portugal","CountryCode":"PT","CountryName":"Portugal","Nationality":"Portuguese"},
		{"JurisdictionCode":"pw","JurisdictionName":"Palau","CountryCode":"PW","CountryName":"Palau","Nationality":"Palauan"},
		{"JurisdictionCode":"py","JurisdictionName":"Paraguay","CountryCode":"PY","CountryName":"Paraguay","Nationality":"Paraguayan"},
		{"JurisdictionCode":"qa","JurisdictionName":"Qatar","CountryCode":"QA","CountryName":"Qatar","Nationality":"Qatari"},
		{"JurisdictionCode":"re","JurisdictionName":"Réunion","CountryCode":"RE","CountryName":"Réunion","Nationality":"Réunionese, Réunionnais"},
		{"JurisdictionCode":"ro","JurisdictionName":"Romania","CountryCode":"RO","CountryName":"Romania","Nationality":"Romanian"},
		{"JurisdictionCode":"rs","JurisdictionName":"Serbia","CountryCode":"RS","CountryName":"Serbia","Nationality":"Serbian"},
		{"JurisdictionCode":"ru","JurisdictionName":"Russia","CountryCode":"RU","CountryName":"Russia","Nationality":"Russian"},
		{"JurisdictionCode":"rw","JurisdictionName":"Rwanda","CountryCode":"RW","CountryName":"Rwanda","Nationality":"Rwandan"},
		{"JurisdictionCode":"sa","JurisdictionName":"Saudi Arabia","CountryCode":"SA","CountryName":"Saudi Arabia","Nationality":"Saudi, Saudi Arabian"},
		{"JurisdictionCode":"sb","JurisdictionName":"Solomon Islands","CountryCode":"SB","CountryName":"Solomon Islands","Nationality":"Solomon Island"},
		{"JurisdictionCode":"sc","JurisdictionName":"Seychelles","CountryCode":"SC","CountryName":"Seychelles","Nationality":"Seychellois"},
		{"JurisdictionCode":"sd","JurisdictionName":"Sudan","CountryCode":"SD","CountryName":"Sudan","Nationality":"Sudanese"},
		{"JurisdictionCode":"se","JurisdictionName":"Sweden","CountryCode":"SE","CountryName":"Sweden","Nationality":"Swedish"},
		{"JurisdictionCode":"sg","JurisdictionName":"Singapore","CountryCode":"SG","CountryName":"Singapore","Nationality":"Singaporean"},
		{"JurisdictionCode":"sh","JurisdictionName":"Saint Helena, Ascension and Tristan da Cunha","CountryCode":"SH","CountryName":"Saint Helena, Ascension and Tristan da Cunha","Nationality":"Saint Helenian"},
		{"JurisdictionCode":"si","JurisdictionName":"Slovenia","CountryCode":"SI","CountryName":"Slovenia","Nationality":"Slovenian, Slovene"},
		{"JurisdictionCode":"sj","JurisdictionName":"Svalbard and Jan Mayen","CountryCode":"SJ","CountryName":"Svalbard and Jan Mayen","Nationality":"Svalbard"},
		{"JurisdictionCode":"sk","JurisdictionName":"Slovakia","CountryCode":"SK","CountryName":"Slovakia","Nationality":"Slovak"},
		{"JurisdictionCode":"sl","JurisdictionName":"Sierra Leone","CountryCode":"SL","CountryName":"Sierra Leone","Nationality":"Sierra Leonean"},
		{"JurisdictionCode":"sm","JurisdictionName":"San Marino","CountryCode":"SM","CountryName":"San Marino","Nationality":"Sammarinese"},
		{"JurisdictionCode":"sn","JurisdictionName":"Senegal","CountryCode":"SN","CountryName":"Senegal","Nationality":"Senegalese"},
		{"JurisdictionCode":"so","JurisdictionName":"Somalia","CountryCode":"SO","CountryName":"Somalia","Nationality":"Somali, Somalian"},
		{"JurisdictionCode":"sr","JurisdictionName":"Suriname","CountryCode":"SR","CountryName":"Suriname","Nationality":"Surinamese"},
		{"JurisdictionCode":"ss","JurisdictionName":"South Sudan","CountryCode":"SS","CountryName":"South Sudan","Nationality":"South Sudanese"},
		{"JurisdictionCode":"st","JurisdictionName":"Sao Tome and Principe","CountryCode":"ST","CountryName":"Sao Tome and Principe","Nationality":"São Toméan"},
		{"JurisdictionCode":"sv","JurisdictionName":"El Salvador","CountryCode":"SV","CountryName":"El Salvador","Nationality":"Salvadoran"},
		{"JurisdictionCode":"sy","JurisdictionName":"Syrian Arab Republic","CountryCode":"SY","CountryName":"Syrian Arab Republic","Nationality":"Syrian"},
		{"JurisdictionCode":"sz","JurisdictionName":"Swaziland","CountryCode":"SZ","CountryName":"Swaziland","Nationality":"Swazi"},
		{"JurisdictionCode":"tc","JurisdictionName":"Turks and Caicos Islands","CountryCode":"TC","CountryName":"Turks and Caicos Islands","Nationality":"Turks and Caicos Island"},
		{"JurisdictionCode":"td","JurisdictionName":"Chad","CountryCode":"TD","CountryName":"Chad","Nationality":"Chadian"},
		{"JurisdictionCode":"tf","JurisdictionName":"French Southern Territories","CountryCode":"TF","CountryName":"French Southern Territories","Nationality":"French"},
		{"JurisdictionCode":"tg","JurisdictionName":"Togo","CountryCode":"TG","CountryName":"Togo","Nationality":"Togolese"},
		{"JurisdictionCode":"th","JurisdictionName":"Thailand","CountryCode":"TH","CountryName":"Thailand","Nationality":"Thai"},
		{"JurisdictionCode":"tj","JurisdictionName":"Tajikistan","CountryCode":"TJ","CountryName":"Tajikistan","Nationality":"Tajikistani"},
		{"JurisdictionCode":"tk","JurisdictionName":"Tokelau","CountryCode":"TK","CountryName":"Tokelau","Nationality":"Tokelauan"},
		{"JurisdictionCode":"tl","JurisdictionName":"Timor-Leste","CountryCode":"TL","CountryName":"Timor-Leste","Nationality":"Timorese"},
		{"JurisdictionCode":"tm","JurisdictionName":"Turkmenistan","CountryCode":"TM","CountryName":"Turkmenistan","Nationality":"Turkmen"},
		{"JurisdictionCode":"tn","JurisdictionName":"Tunisia","CountryCode":"TN","CountryName":"Tunisia","Nationality":"Tunisian"},
		{"JurisdictionCode":"to","JurisdictionName":"Tonga","CountryCode":"TO","CountryName":"Tonga","Nationality":"Tongan"},
		{"JurisdictionCode":"tr","JurisdictionName":"Turkey","CountryCode":"TR","CountryName":"Turkey","Nationality":"Turkish"},
		{"JurisdictionCode":"tt","JurisdictionName":"Trinidad and Tobago","CountryCode":"TT","CountryName":"Trinidad and Tobago","Nationality":"Trinidadian or Tobagonian"},
		{"JurisdictionCode":"tv","JurisdictionName":"Tuvalu","CountryCode":"TV","CountryName":"Tuvalu","Nationality":"Tuvaluan"},
		{"JurisdictionCode":"tw","JurisdictionName":"Taiwan","CountryCode":"TW","CountryName":"Taiwan","Nationality":"Chinese, Taiwanese"},
		{"JurisdictionCode":"tz","JurisdictionName":"Tanzania, United Republic of","CountryCode":"TZ","CountryName":"Tanzania, United Republic of","Nationality":"Tanzanian"},
		{"JurisdictionCode":"ua","JurisdictionName":"Ukraine","CountryCode":"UA","CountryName":"Ukraine","Nationality":"Ukrainian"},
		{"JurisdictionCode":"ug","JurisdictionName":"Uganda","CountryCode":"UG","CountryName":"Uganda","Nationality":"Ugandan"},
		{"JurisdictionCode":"um","JurisdictionName":"United States Minor Outlying Islands","CountryCode":"UM","CountryName":"United States Minor Outlying Islands","Nationality":"American (UM)"},
		{"JurisdictionCode":"us","JurisdictionName":"United States","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ak","JurisdictionName":"Alaska (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_al","JurisdictionName":"Alabama (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ar","JurisdictionName":"Arkansas (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_az","JurisdictionName":"Arizona (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ca","JurisdictionName":"California (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_co","JurisdictionName":"Colorado (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ct","JurisdictionName":"Connecticut (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_dc","JurisdictionName":"District of Columbia (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_de","JurisdictionName":"Delaware (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_fl","JurisdictionName":"Florida (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ga","JurisdictionName":"Georgia (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_hi","JurisdictionName":"Hawaii (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ia","JurisdictionName":"Iowa (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_id","JurisdictionName":"Idaho (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_il","JurisdictionName":"Illinois (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_in","JurisdictionName":"Indiana (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ks","JurisdictionName":"Kansas (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ky","JurisdictionName":"Kentucky (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_la","JurisdictionName":"Louisiana (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ma","JurisdictionName":"Massachusetts (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_md","JurisdictionName":"Maryland (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_me","JurisdictionName":"Maine (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_mi","JurisdictionName":"Michigan (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_mn","JurisdictionName":"Minnesota (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_mo","JurisdictionName":"Missouri (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ms","JurisdictionName":"Mississippi (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_mt","JurisdictionName":"Montana (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_nc","JurisdictionName":"North Carolina (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_nd","JurisdictionName":"North Dakota (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ne","JurisdictionName":"Nebraska (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_nh","JurisdictionName":"New Hampshire (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_nj","JurisdictionName":"New Jersey (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_nm","JurisdictionName":"New Mexico (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_nv","JurisdictionName":"Nevada (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ny","JurisdictionName":"New York (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_oh","JurisdictionName":"Ohio (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ok","JurisdictionName":"Oklahoma (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_or","JurisdictionName":"Oregon (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_pa","JurisdictionName":"Pennsylvania (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ri","JurisdictionName":"Rhode Island (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_sc","JurisdictionName":"South Carolina (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_sd","JurisdictionName":"South Dakota (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_tn","JurisdictionName":"Tennessee (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_tx","JurisdictionName":"Texas (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_ut","JurisdictionName":"Utah (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_va","JurisdictionName":"Virginia (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_vt","JurisdictionName":"Vermont (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_wa","JurisdictionName":"Washington (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_wi","JurisdictionName":"Wisconsin (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_wv","JurisdictionName":"West Virginia (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"us_wy","JurisdictionName":"Wyoming (United States)","CountryCode":"US","CountryName":"United States","Nationality":"American"},
		{"JurisdictionCode":"uy","JurisdictionName":"Uruguay","CountryCode":"UY","CountryName":"Uruguay","Nationality":"Uruguayan"},
		{"JurisdictionCode":"uz","JurisdictionName":"Uzbekistan","CountryCode":"UZ","CountryName":"Uzbekistan","Nationality":"Uzbekistani, Uzbek"},
		{"JurisdictionCode":"va","JurisdictionName":"Holy See (Vatican City State)","CountryCode":"VA","CountryName":"Holy See (Vatican City State)","Nationality":"Vatican"},
		{"JurisdictionCode":"vc","JurisdictionName":"St. Vincent and the Grenadines","CountryCode":"VC","CountryName":"St. Vincent and the Grenadines","Nationality":"Saint Vincentian, Vincentian"},
		{"JurisdictionCode":"ve","JurisdictionName":"Venezuela","CountryCode":"VE","CountryName":"Venezuela","Nationality":"Venezuelan"},
		{"JurisdictionCode":"vg","JurisdictionName":"Virgin Islands, British","CountryCode":"VG","CountryName":"Virgin Islands, British","Nationality":"British Virgin Island"},
		{"JurisdictionCode":"vi","JurisdictionName":"Virgin Islands, U.S.","CountryCode":"VI","CountryName":"Virgin Islands, U.S.","Nationality":"U.S. Virgin Island"},
		{"JurisdictionCode":"vn","JurisdictionName":"Vietnam","CountryCode":"VN","CountryName":"Vietnam","Nationality":"Vietnamese"},
		{"JurisdictionCode":"vu","JurisdictionName":"Vanuatu","CountryCode":"VU","CountryName":"Vanuatu","Nationality":"Ni-Vanuatu, Vanuatuan"},
		{"JurisdictionCode":"wf","JurisdictionName":"Wallis and Futuna","CountryCode":"WF","CountryName":"Wallis and Futuna","Nationality":"Wallis and Futuna, Wallisian or Futunan"},
		{"JurisdictionCode":"ws","JurisdictionName":"Samoa","CountryCode":"WS","CountryName":"Samoa","Nationality":"Samoan"},
		{"JurisdictionCode":"ye","JurisdictionName":"Yemen","CountryCode":"YE","CountryName":"Yemen","Nationality":"Yemeni"},
		{"JurisdictionCode":"yt","JurisdictionName":"Mayotte","CountryCode":"YT","CountryName":"Mayotte","Nationality":"Mahoran"},
		{"JurisdictionCode":"za","JurisdictionName":"South Africa","CountryCode":"ZA","CountryName":"South Africa","Nationality":"South African"},
		{"JurisdictionCode":"zm","JurisdictionName":"Zambia","CountryCode":"ZM","CountryName":"Zambia","Nationality":"Zambian"},
		{"JurisdictionCode":"zw","JurisdictionName":"Zimbabwe","CountryCode":"ZW","CountryName":"Zimbabwe","Nationality":"Zimbabwean"}
	]
};