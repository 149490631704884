<div *ngIf="matches.length > 0; else IndividualNotFound">
  <div class="alert alert-info">
    <b>We have found {{matches.length}} potential match{{matches.length == 1 ? "":"es"}} for {{searchName}}!</b>
  </div>
  <div *ngFor="let ind of matches; let pos = index">
    <div class="card shadow-sm mt-3" (click)="selectResult(ind)">
      <div class="card-body p-0">
        <div class="d-flex data-row-card justify-content-between">
          <div>
            <div class="wordwrap"><h5>{{buildName(ind)}}</h5></div>
            <div>
              <span *ngIf="ind.currentStatus == 'open'" class="badge rounded-pill bg-danger me-1">Open Match</span>
              <span *ngIf="ind.currentStatus == 'false-positive'" class="badge rounded-pill bg-warning me-1">Discounted</span>
              <span *ngIf="ind.currentStatus == 'true-positive'" class="badge rounded-pill bg-info me-1">Matched</span>
              <app-lamps-group [datasets]="ind.datasets"></app-lamps-group>
              <div class="mt-2">
                <span><span class="icon-user"></span> {{ind.gender}}&nbsp;&nbsp;&nbsp;</span>
                <span *ngIf="ind?.countries?.length > 0"><span class="icon-map-marker"></span> {{getCountry(ind.countries[0])}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span *ngIf="ind?.datesOfBirthIso?.length > 0"><span class="icon-calendar-user"></span> {{ind.datesOfBirthIso[0]}}</span>
              </div>
            </div>
          </div>
          <div class="score high">
            <div>{{ind.score}} %</div>
            <p class="text-center fst-italic mb-0 text-muted score-text">Match</p>
          </div>
        </div>
        <div>
          <a class="btn btn-link-light moreButton p-0 px-2" type="button" (click)="changeIndividualShowFlag($event, ind, pos)"><span [ngClass]="[ showIndividualCheck(pos) ? 'icon-eye' : 'icon-eye2']"></span> preview details</a>
        </div>
      </div>
    </div>
    <div *ngIf="showIndividualCheck(pos); let profile" class="details-card p-3">
      <mon-detail [client]="selectedClient"
                  [profile]="selectedProfile"
                  [allowDiscounting]="false"
                  [canClose]="false">
      </mon-detail>
    </div>
  </div>
  <div class="alert alert-info mt-3">
    <p> 
      <b>Didn’t find a matching profile for {{searchName}}? </b><br>
      Good news! This means that they are not politically exposed, sanctioned or the subject of any financial crime-related adverse media or law enforcement; click the ‘Discount all matches’ button below to record this.
    </p>
    <ng-container *ngIf="canShowMoreMatches" [ngTemplateOutlet]="showMoreMatchesInfo"></ng-container>
    <ng-container [ngTemplateOutlet]="requestProfileInfo"></ng-container>
  </div>
  <div *ngIf="canShowMoreMatches" class="text-center">
    <button class="show-more-btn btn" (click)="loadSubThresholdMatches()">View More<span class="icon-chevron-down-circle"></span></button>
  </div>
    <div class="matches-btn-container container">
      <div class="row">
        <div class="col">
          <app-profile-request-button [client]="client"></app-profile-request-button>
        </div>
        <div class="col">
          <button (click)="expandMatchReport(matches)" class="btn btn-secondary">Create Matches Report</button>
        </div>
        <div class="col">
          <button (click)="selectResult()" class="btn btn-warning">Discount All Matches</button>
        </div>
      </div>
    </div>
</div>

<ng-template #IndividualNotFound>
  <div class="alert alert-info">
    <p>
      <b>Good news! No matches have been found for "{{searchName}}".</b><br>
      This means that they are not politically exposed, sanctioned or the subject of any financial crime-related adverse media or litigation.
      Click the "Record no match" button below to record this.
    </p>
    <ng-container *ngIf="canShowMoreMatches" [ngTemplateOutlet]="showMoreMatchesInfo"></ng-container>
    <ng-container [ngTemplateOutlet]="requestProfileInfo"></ng-container>
  </div>

  <div *ngIf="canShowMoreMatches" class="text-center">
    <button class="show-more-btn btn" (click)="loadSubThresholdMatches()">View More<span class="icon-chevron-down-circle"></span></button>
  </div>
  <div class="matches-btn-container container">
    <div class="row">
      <div class="col text-center">
        <app-profile-request-button [client]="client"></app-profile-request-button>
      </div>
      <div class="col text-center">
        <button (click)="selectResult()" class="btn btn-warning">Record No Match</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showMoreMatchesInfo>
  <p><b>Want to find more? </b>Your fuzzy logic threshold is set at {{threshold}}%, but there are other potential matches with a lower confidence score; click the “View More” button below if you want to review these.</p>
</ng-template>
<ng-template #requestProfileInfo>
  <p><b>Would you like more information? </b>Click the "Request more information" and one of our KYC specialists will investigate "{{searchName}}" further.</p>
</ng-template>
