import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScreenClientDialogComponent } from '../screen-client-dialog/screen-client-dialog.component';
import { ScreeningService } from '../services/screening.service';

@Component({
  selector: 'app-screen-client-button',
  templateUrl: './screen-client-button.component.html',
  styleUrls: ['./screen-client-button.component.scss']
})
export class ScreenClientButtonComponent implements OnInit {

  constructor(private modalService: NgbModal, private screeningService: ScreeningService) {}

  ngOnInit(): void {
  }

  displaySearch() {
    this.screeningService.launchScreening();
  }
}
