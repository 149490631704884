<section>
  <br />
  <h2>Company Details</h2>
  <h4 class="bg-dark text-light">{{premiumUtils.dtoToSelectedLanguage(company?.companyNameDto)}}</h4>
  <form [formGroup]="dashService.getInvestigation().reportActorFreeText">
    <div class="row">
      <div class="col">
        <div class="wrapper">
          <textarea #textarea id="details" type="textarea" class="discardable-text"
            placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
            formControlName="detailsHeading"></textarea> <span class="edit-icon"> click to edit</span>
          <p id="detailsText"></p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="row">
        <div class="col"
          *ngIf="premiumUtils.dtoToSelectedLanguage(company?.statusDto) || premiumUtils.getPremiumOrgInfosLocation(company)">
          <p *ngIf="premiumUtils.dtoToSelectedLanguage(company?.statusDto)" class="c24 c41">Status: {{
            premiumUtils.dtoToSelectedLanguage(company?.statusDto) }}</p>
          <p *ngIf="premiumUtils.getPremiumOrgInfosLocation(company)" class="c24 c41">Jurisdiction: {{
            premiumUtils.getPremiumOrgInfosLocation(company) }}</p>
          <p *ngIf="premiumUtils.dtoToSelectedLanguage(company?.tradingNamesDto)" class="c24 c41">Trading Names: {{
            premiumUtils.dtoToSelectedLanguage(company?.tradingNamesDto) }}</p>
          <p *ngIf="company?.phoneNumber" class="c24 c41">Phone: {{ company?.phoneNumber }}</p>
          <p>Url: <a *ngIf="company?.websiteURL" class="c24 c41" target="_blank" href="{{company?.websiteURL}}">{{
              company?.websiteURL }}</a></p>
        </div>
        <div class="col">
          <p *ngIf="company?.companyNumber" class="c24 c41">Company No: {{ company?.companyNumber }}</p>
          <p *ngIf="company?.registrationNumber" class="c24 c41">Registration No: {{ company?.registrationNumber }}</p>
          <p *ngIf="company?.licenseNumber" class="c24 c41">Registration No: {{ company?.licenseNumber }}</p>
          <p *ngIf="premiumUtils.dtoArrayToSelectedLanguage(company?.sectorsDto)" class="c24 c41">Sectors: {{
            premiumUtils.dtoArrayToSelectedLanguage(company?.sectorsDto).toString() }}</p>
          <p *ngIf="company?.isStateOwned != null" class="c24 c41">State Owned: {{ company?.isStateOwned ? "Yes" : "No"
            }}</p>
        </div>
        <div class="col">
          <div *ngIf="company?.registeredAddress?.fullAddress">
            <p *ngFor="let addressLine of reportUtils.splitFullAddress(company?.registeredAddress?.fullAddress)">
              {{addressLine}}</p>
          </div>
          <div *ngIf="!company?.registeredAddress?.fullAddress">
            <p>{{company?.registeredAddress?.addressLine1}}</p>
            <p>{{company?.registeredAddress?.addressLine2}}</p>
            <p>{{company?.registeredAddress?.countyOrState}}</p>
            <p>{{company?.registeredAddress?.locality}}</p>
            <p>{{company?.registeredAddress?.postOrZipCode}}</p>
            <p>{{company?.registeredAddress?.country}}</p>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Business Relationship</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="businessRelationship" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="businessRelationship"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="businessRelationshipText"></p>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Risk Level</p>
        </div>
        <div class="col">
          <p class="text-light">Comments</p>
        </div>
      </div>
      <div class="row" [style.background-color]="rowColors.get('ragStatus')">
        <div class="col">
          <select class="form-select form-control" id="uniqueDiliOrgOption" formControlName="ragStatus"
            (change)="setRowColor('ragStatus')">
            <option value="" selected></option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
            <option value="N/A">N/A</option>
          </select>
          <div class="form-select form-control" style="display:none">High,Medium,Low</div>
        </div>
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="ragStatus" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="riskLevelComments"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="ragStatusText"></p>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>