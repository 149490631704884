<div class="container">
  <div class="modal-header">
    <h4>Heatmap Key</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
    </div>
  </div>
</div>