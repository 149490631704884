<div class="modal-header">
  <h4 class="modal-title col">Matched Profiles</h4>
  <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismiss()">
    <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span
        class="icon-cross"></span></span>
  </button>
</div>
<div class="modal-body">
  @switch (currentState) {
  @case (matchingState.checking) {
  <div class="spinner-border text-primary" role="status"></div>
  <span>Retrieving matches ...</span>
  }
  @case (matchingState.nothingFound) {
  <div class="alert alert-info">
    <p><b>Good news! No matches have been found. Do you want to record that {{ isIndividual() ? fullName() : searchName
        }} as having no matches?</b></p>
  </div>
  <div class="matches-btn-container container">
    <div class="row">
      <div class="col text-center">
        <app-profile-request-button [client]="client"></app-profile-request-button>
      </div>
      <div class="col text-center">
        <button (click)="recordNoMatches()" class="btn btn-warning">Record no matches</button>
      </div>
    </div>
  </div>
  }
  @case (matchingState.individualsFound) {
  <mon-individualmatches [threshold]="threshold" [searchName]="searchName" [searchFirstName]="searchFirstName"
    [searchLastName]="searchLastName" [searchOtherName]="searchOtherName" [matches]="individualMatches"
    (viewedMoreMatches)="setViewedMoreMatches()" (match)="setIndividualMatch($event)"
    [client]="client"></mon-individualmatches>
  }
  @case (matchingState.businessesFound) {
  <mon-businessmatches [threshold]="threshold" [searchName]="searchName" [matches]="businessMatches"
    (viewedMoreMatches)="setViewedMoreMatches()" (match)="setBusinessMatch($event)"
    [client]="client"></mon-businessmatches>
  }
  }
</div>

<app-loader [show]="isBusy"></app-loader>