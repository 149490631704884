import { Component } from '@angular/core';
import { ProfileService } from 'src/app/profile.service';
import { ScreeningService } from 'src/app/screening/services/screening.service';
import { MonitorLinkService } from 'src/app/services/monitor-link.service';
import { EntityTypes, Gender } from 'src/nswag';
import { MatchesService } from '../../matching-service/matches.service';
import { DashboardService } from '../../services/dashboard.service';
import { AlertService } from '../../_alert';
import { ConfirmationDialogService } from '../../_confirmation-dialog/ConfirmationDialog.service';
import { IActorBase } from '../models/Actor';
import { PremiumSearchUtilityService } from 'src/app/utilitities/premium-search-utilities';

@Component({
  selector: 'app-runcheck',
  templateUrl: './runcheck.component.html',
  styleUrls: ['./runcheck.component.scss']
})

export class RunCheckComponent {
  constructor(
    private dashService: DashboardService,
    private confirmationService: ConfirmationDialogService,
    private matchService: MatchesService,
    private alertService: AlertService,
    private monitorLinkService: MonitorLinkService, 
    private profileService: ProfileService,
    private screeningService: ScreeningService,
    private premiumUtils: PremiumSearchUtilityService) { }


  public inMonitoring(): boolean {
    if (!this.profileService?.monitoringEnabled()) {
      return false;
    }
    return (this.dashService.selectedClient?.client?.inMonitoring);
  }

  public canRunCheck(): boolean {
    let actor = this.dashService.investigatedActor;
    return (actor?.clientId == null && actor?.hasLAMPS == false);
  }

  public canAddToMonitoring(): boolean {
    if (!this.profileService?.monitoringEnabled()) {
      return false;
    }
    // Only add when its none screened
    return (this.dashService?.selectedClient == null);
  }

  public canMoveToMonitoring(): boolean {
    if (!this.profileService?.monitoringEnabled()) {
      return false;
    }
    return (this.dashService?.selectedClient?.client && !this.dashService.selectedClient.client.inMonitoring);
  }

  public addActorToMonitoring() {
    if (!this.canAddToMonitoring()) {
      return;
    }
    let actor = this.dashService.getActorByNodeId(this.dashService.investigatedActor?.id);
    if (!actor) {
      return;
    }
    if (this.dashService.investigationName == null) {
      this.alertService.error("Please save your investigation before trying to add  to monitoring.", { autoClose: true });
    }

    // let title = "Add to monitoring";
    // let message = "Do you want to add " + actor?.name + " to monitoring?";
    // this.confirmationService.confirm(title, message, "Yes", "Cancel", "sm", true).then(r => {
    //   if (r) {
        this.monitorLinkService.addToMonitoring(actor.id, actor.infoObject, actor.actorType, this.dashService.selectedBusinessProfile ?? this.dashService.selectedIndividualProfile, this.confirmationService.reason).subscribe(result => {
          if (result) {
            actor.clientId = result.client.id;
            this.dashService.selectedClient = result;
            result.client.inMonitoring = true;
            this.dashService.addAuditTrail(actor.name + " added to monitoring");
            this.alertService.success(actor.name + " has been added to ongoing monitoring.", { autoClose: true });
          }
          else {
            this.alertService.error("Unfortunately, something has gone wrong. Please try again or contact support.");
          }
        });
//      }
//    });
  }

  public moveScreenedClientToMonitoring() {
    if (!this.canMoveToMonitoring()) {
      return;
    }
    this.screeningService.MoveToMonitoring(this.dashService.selectedClient).subscribe(c => {
      if (c) {
        this.dashService.selectedClient = c;
        this.dashService.addAuditTrail(this.dashService.investigatedActor.name + "  moved to monitoring");
        this.alertService.success("The client has been successfully added to Monitoring. Select Monitoring from the menu to view the ongoing status of this client.", { autoClose: true });
      }
      else {
        this.alertService.error("Unfortunately, something has gone wrong. Please try again or contact support.");
      }
    });
  }

  public runChecks() {
    let selectedActor = this.dashService.getActorByNodeId(this.dashService.investigatedActor?.id);
    if (!selectedActor) {
      return;
    }

    if (selectedActor.asUserProfile) {
      var up = selectedActor.asUserProfile;
      this.searchPerson(selectedActor.name, new Date(up.dateOfBirth).toString(), up.gender);
    }
    else {
      switch (selectedActor.actorType) {
        case EntityTypes.Company:
          this.searchBusiness(selectedActor.name);
          break;
        case EntityTypes.Individual:
          let ind = selectedActor.asIndividualInfo;
          this.searchPerson(selectedActor.name, ind?.dateOfBirth, ind?.gender);
          break;
        case EntityTypes.Officer:
        case EntityTypes.OfficerCompany:
          let off = selectedActor.asOfficerInfo;
          this.searchPerson(selectedActor.name, off?.dateOfBirth, off?.gender);
          break;
        case EntityTypes.UBO:
          let ubo = selectedActor.asUBOInfo;
          this.searchPerson(selectedActor.name, ubo?.dateOfBirth, ubo?.gender);
          break;
        case EntityTypes.DiligenciaOrganisation:
          this.searchBusiness(selectedActor.name, true);
          break;
        case EntityTypes.DiligenciaPerson:
          let person = selectedActor.asDiligenciaPersonInfo;
          let gender = (this.premiumUtils.dtoToSelectedLanguage(person.genderDto) as Gender) ?? Gender.Unknown;
          this.searchPerson(selectedActor.name, person.dateOfBirth, gender, true);
          break;
        default:
          console.error("Entity type " + selectedActor.actorType + " not configured for LAMPS checks");
      }
    }
  }

  private searchPerson(name: string, dob: string, gender: Gender, isPremiumActor: boolean = false) {
      this.matchService.selectIndividualMatchForActor(this.dashService.investigatedActor.id, dob, name, null, gender, isPremiumActor).subscribe(result => {
      this.dashService.addActorIdentifiers(result.actorProfile);
    });
  }

  private searchBusiness(name: string, isPremiumActor: boolean = false) {
      this.matchService.selectBusinessMatchForActor(this.dashService.investigatedActor.id, name, null, isPremiumActor).subscribe(result => {
      this.dashService.addActorIdentifiers(result.actorProfile);
    });
  }
}
