import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { AddBusinessCommand, AddIndividualCommand, Address, Address2, AlertStatus, BusinessClient, BusinessProfile, Client, ClientResult, CompanyInfo, DiligenciaOrganisationInfo, DiligenciaPersonInfo, EntityTypes, Gender, IdvUserProfile, IndividualClient, IndividualInfo, IndividualProfile, LAMPSStatus, MatchStatus, MonitoringClient, OfficerInfo, SelectBusinessMatchCommand, SelectIndividualMatchCommand, UBO } from 'src/nswag';
import { AddclientComponent } from "../monitor/manage/addclient/addclient.component";
import { ProfileService } from "../profile.service";
import { UtilityService } from "../utilitities/utilities";
import { PremiumSearchUtilityService } from "../utilitities/premium-search-utilities";
import { CountriesService } from "../utils/countries.service";
import { ErrorService } from "./error.service";

@Injectable({
  providedIn: 'root'
})
export class MonitorLinkService {
  constructor(private monitorClient: MonitoringClient, private profileService: ProfileService, private modalService: NgbModal, private premiumUtils: PremiumSearchUtilityService,
    private errorService: ErrorService
  ) { }

  public static createDummyClientresult(profile: any): ClientResult {
    var isPEP = profile?.pepEntries?.current?.length > 0 ||
      profile?.pepEntries?.former?.length > 0 ||
      profile?.soeEntry != null ||
      profile?.pepByAssociationEntries?.length > 0 ||
      profile?.poiEntries?.length > 0;

    var client = new ClientResult({
      client: new Client(),
      matchStatus: new MatchStatus({
        alertStatus: AlertStatus.Closed,
        hasAdvMedia: profile?.rreEntries?.length > 0 ? LAMPSStatus.Changed : LAMPSStatus.No,
        hasLawEnforcement: profile?.relEntries?.length > 0 || profile?.insEntries?.length > 0 || profile?.ddEntries?.length > 0 ? LAMPSStatus.Changed : LAMPSStatus.No,
        hasSanctions: profile?.sanEntries?.current?.length > 0 || profile?.sanEntries?.former?.length > 0 ? LAMPSStatus.Changed : LAMPSStatus.No,
        isPEP: isPEP ? LAMPSStatus.Changed : LAMPSStatus.No,
        isMatched: LAMPSStatus.Yes
      })
    })
    return client;
  }

  public addToMonitoring(nodeId: string, infoObject: any, eType: EntityTypes, profile: any, reason: string): Observable<ClientResult> {
    if (!this.profileService.monitoringEnabled()) {
      return;
    }
    switch (eType) {
      case EntityTypes.Company:
        return this.addCompanyToMonitoring(nodeId, infoObject, profile, reason);
      case EntityTypes.IDV:
        return this.addIDVToMonitoring(nodeId, infoObject, profile, reason);
      case EntityTypes.Individual:
        return this.addIndToMonitoring(nodeId, infoObject, profile, reason);
      case EntityTypes.Officer:
      case EntityTypes.OfficerCompany:
        return this.addOfficerToMonitoring(nodeId, infoObject, profile, reason);
      case EntityTypes.UBO:
        return this.addUBOToMonitoring(nodeId, infoObject, profile, reason);
      case EntityTypes.DiligenciaPerson:
        return this.addDiligenciaPersonToMonitoring(nodeId, infoObject, profile, reason);
      case EntityTypes.DiligenciaOrganisation:
        return this.addDiligenciaOrgToMonitoring(nodeId, infoObject, profile, reason);
      default:
        throw Error(eType + " is cannot be added to monitoring");
    }
  }

  public addCompanyToMonitoring(nodeId: string, company: CompanyInfo, profile: IndividualProfile, reason: string): Observable<ClientResult> {
    let business =  new BusinessClient({
      name: company.name,
      jurisdiction: (company.jurisdiction 
          ? UtilityService.getCountryFromJurisdictionCode(company.jurisdiction)
          : company.registeredAddress?.country),
      address: this.validateAddress(company.registeredAddress)
    });
    return this.addBusinessToMonitoring(nodeId, business, company.entityId, profile, reason);
  }

  private addDiligenciaOrgToMonitoring(nodeId: string, org: DiligenciaOrganisationInfo, profile: BusinessProfile, reason: string): Observable<ClientResult> {
    let address = this.validateAddress(org?.registeredAddress);
    let business = new BusinessClient({
      name: this.premiumUtils.dtoToSelectedLanguage(org.companyNameDto),
      jurisdiction: org?.jurisdiction
        ? UtilityService.getCountryFromJurisdictionCode(org.jurisdiction)
        : this.premiumUtils.dtoToSelectedLanguage(org.registeredCountryDto),
      address: !address && UtilityService.getCountryFromJurisdictionCode(org.jurisdiction) 
        ?  new Address2({countryIsoCode: UtilityService.getCountryFromJurisdictionCode(org.jurisdiction)}) 
        : address
    });

    return this.addBusinessToMonitoring(nodeId, business, org.companyNumber, profile, reason);
  }

  public addIDVToMonitoring(nodeId: string, idvProfile: IdvUserProfile, profile: IndividualProfile, reason: string): Observable<ClientResult> {
    let ind = new IndividualClient({
      name: UtilityService.getIndividualName(idvProfile),
      address: this.validateAddress(idvProfile.address),
      nationality: UtilityService.getNationalityFromCode(idvProfile.country),
      dateOfBirth: this.parseDate(idvProfile.dateOfBirth),
      gender: idvProfile.gender
    });
    return this.addIndividualToMonitoring(nodeId, ind, profile, reason);
  }

  private addIndToMonitoring(nodeId: string, i: IndividualInfo, profile: IndividualProfile, reason: string): Observable<ClientResult> {
    let ind = new IndividualClient({
      name: i.name,
      address: this.validateAddress(i.address),
      nationality: UtilityService.getNationalityFromCountry(i.nationality),
      dateOfBirth: this.parseDate(i.dateOfBirth),
      gender: i.gender,
    })
    return this.addIndividualToMonitoring(nodeId, ind, profile, reason);
  }

  private addDiligenciaPersonToMonitoring(nodeId: string, person: DiligenciaPersonInfo, profile: IndividualProfile, reason: string): Observable<ClientResult> {
    let ind = new IndividualClient({
      name: this.premiumUtils.dtoToSelectedLanguage(person.nameDto),
      nationality: UtilityService.getCodeFromNationality(this.premiumUtils.dtoToSelectedLanguage(person.nationalityDto)) 
        ? this.premiumUtils.dtoToSelectedLanguage(person.nationalityDto) 
        : UtilityService.getNationalityFromCountry(this.premiumUtils.dtoToSelectedLanguage(person?.countryOfResidenceDto)),
      dateOfBirth: this.parseDate(person.dateOfBirth),
      gender: (this.premiumUtils.dtoToSelectedLanguage(person.genderDto) as Gender) ?? Gender.Unknown,
      address: new Address2({countryIsoCode: UtilityService.getCodeFromCountry(this.premiumUtils.dtoToSelectedLanguage(person?.countryOfResidenceDto))}) 
    })
    return this.addIndividualToMonitoring(nodeId, ind, profile, reason);
  }

  private addOfficerToMonitoring(nodeId: string, o: OfficerInfo, profile: IndividualProfile, reason: string): Observable<ClientResult> {
    let ind = new IndividualClient({
      name: o.name,
      address: this.validateAddress(o.address),
      nationality: UtilityService.getNationalityFromCountry(o.nationality),
      dateOfBirth: this.parseDate(o.dateOfBirth),
      gender: o.gender
    })
    return this.addIndividualToMonitoring(nodeId, ind, profile, reason);
  }

  private addUBOToMonitoring(nodeId: string, ubo: UBO, profile: IndividualProfile, reason: string): Observable<ClientResult> {
    let ind = new IndividualClient({
      name: ubo.name,
      address: this.validateAddress(ubo.address),
      nationality: UtilityService.getNationalityFromCountry(ubo.nationality),
      dateOfBirth: this.parseDate(ubo.dateOfBirth),
      gender: ubo.gender,
    })
    return this.addIndividualToMonitoring(nodeId, ind, profile, reason);
  }

  private parseDate(thedate: any) {
    if (typeof thedate == "string" || thedate instanceof String) {
      let sdate = thedate as string;
      let m = UtilityService.convertToLocalDateTime(new Date(sdate), "short");
      if (sdate && m) {
        return m;
      }
    }
    else if (thedate instanceof Date) {
      let ddate = thedate as Date;
      if (ddate) {
        let month = (ddate.getMonth() < 9 ? "0" : "") + (ddate.getMonth() + 1);
        return ddate.getFullYear() + "-" + month + "-" + ddate.getDate();
      }
    }
    return null;
  }
  private addIndividualToMonitoring(nodeId: string, ind: IndividualClient, profile: IndividualProfile, reason: string): Observable<ClientResult> {
    this.errorService.reset();
    let event = new Observable<ClientResult>((obs: any) => {
      let client = new ClientResult({
        client: new Client({
          individual: ind,
          address: ind.address
        })
      });
      let editor = new AddclientComponent(this.modalService);
      editor.editClient(client, "Add Client to Monitoring", "Add to monitoring");
      editor.saveClient.subscribe(result => {
        let ind = result.client.individual;
        let cmd = new AddIndividualCommand({
          nodeId: nodeId,
          name: ind.name,
          address: result.client.address,
          nationality: CountriesService.getNationalityCode(ind.nationality),
          dateOfBirth: ind.dateOfBirth,
          gender: ind.gender,
          clientRef: ind.clientRef,
          riskLevel: ind.riskLevel,
          tags: ind?.tags
        });
        this.monitorClient.addIndividual(cmd).subscribe(result => {
          if (result.isSuccess) {
            let client = MonitorLinkService.createDummyClientresult(profile);
            client.client.individual = result.data;
            client.client.id = result.data.id;
            client.client.address = result.data.address;
            client.client.inMonitoring = true;
            let command = new SelectIndividualMatchCommand({
              resourceId: profile?.resourceId,
              clientId: client.client.id,
              notes: reason,
              resultsMediaId: null, // historical match, ID not available
              nodeId: nodeId
            });
            this.monitorClient.selectIndividualMatch(command, client.client.id).subscribe(result => {
              this.modalService.dismissAll();
              if (result.isSuccess) {
                obs.next(result.data);
              }
              else {
                obs.next(client);
              }
            });
          }
          else {
            obs.next();
          }
        }, error => {
          this.errorService.setProblemDetailsFromError(error);
          this.modalService.dismissAll();
        });
      });
    });
    return event;
  }

  private addBusinessToMonitoring(nodeId: string, bus: BusinessClient, companyNumber: string, profile: BusinessProfile, reason: string): Observable<ClientResult> {
    this.errorService.reset();
    let event = new Observable<ClientResult>((obs: any) => {
      let client = new ClientResult({
        client: new Client({
          business: bus,
          address: bus.address
        })
      });
      let editor = new AddclientComponent(this.modalService);
      editor.editClient(client, "Add Client to Monitoring", "Add to monitoring");
      editor.saveClient.subscribe(result => {
        let bus = result.client.business;
        let cmd = new AddBusinessCommand({
          nodeId: nodeId,
          companyNumber: companyNumber,
          name: bus.name,
          address: result.client.address,
          jurisdiction: CountriesService.getJurisdictionCode(bus.jurisdiction),
          clientRef: bus.clientRef,
          riskLevel: bus.riskLevel,
          tags: bus?.tags
        });

        this.monitorClient.addBusiness(cmd).subscribe(result => {
          if (result.isSuccess) {
            let client = MonitorLinkService.createDummyClientresult(profile);
            client.client.business = result.data;
            client.client.id = result.data.id;
              let command = new SelectBusinessMatchCommand({
                resourceId: profile?.resourceId,
                clientId: client.client.id,
                notes: reason,
                resultsMediaId: null, // historical match, ID not available
                nodeId: nodeId
              });
              this.monitorClient.selectBusinessMatch(command, client.client.id).subscribe(result => {
                this.modalService.dismissAll();
                if (result.isSuccess) {
                  obs.next(result.data);
                }
                else {
                  obs.next(client);
                }
              });
          }
          else {
            obs.next();
          }
        }, error => {
          this.errorService.setProblemDetailsFromError(error);
          this.modalService.dismissAll();
        });
      });
    });
    return event;
  }

  private validateAddress(address: any): Address2 {
    if (!address) {
      return null;
    }
    if (address instanceof Address2 || 'line1' in address) {
      return address;
    }
    if (address instanceof Address || "addressLine1" in address || "fullAddress" in address) {
      return UtilityService.convertToAddress2(address);
    }
    return null;
  }
}
