import { Inject, Injectable } from '@angular/core';
import { SearchOfficersAndCompaniesByFreeTextSearchType, DueDiligenceClient, EntityTypes} from 'src/nswag';
import { BehaviorSubject, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SearchCriteria } from '../models/search-criteria';
import { SearchResultState } from '../models/search-result-state';
import { CompanyRow, DiligenciaOrgRow, DiligenciaPersonRow, PersonRow } from '../models/search-table-models';
import { AlertService } from '../../_alert';
import { ExceptionPropertyNames } from 'src/app/enums';
import { ProfileService } from 'src/app/profile.service';
import { CountriesService } from 'src/app/utils/countries.service';
import { ErrorService } from 'src/app/services/error.service';


@Injectable({
    providedIn: 'root'
})
export class SearchService {
    public isBusy: boolean = false;
    public searchCriteria: SearchCriteria;
    public searchResultState: SearchResultState;
    public premiumSearchEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(@Inject(DueDiligenceClient) private client: DueDiligenceClient, private alertService: AlertService, private profileService: ProfileService,
                private errorService: ErrorService) {
        this.searchCriteria = new SearchCriteria();
        this.searchResultState = new SearchResultState();
        this.setPremiumSearch();
    }

  setPremiumSearch() {
    if (this.profileService.searchEnabled()) {
        this.client.premiumSearchEnabled().subscribe(result => {
            this.premiumSearchEnabled.next(result.data.isEnabled);
        }, error => {
            console.error(error); 
            this.premiumSearchEnabled.next(false);
        });
    }
  }  

  getJurisdictionName(code: string) {
    return CountriesService.getJurisdictionName(code);
  }
  getNationalityName(code: string) {
    return CountriesService.getNationalityName(code);
  }

  runSearch(searchCriteria: SearchCriteria, searchResultState: SearchResultState): boolean{
      if (!this.validateCriteria(searchCriteria)) {
        return false;
      }
        searchResultState.reset();
        this.isBusy = true;
        this.errorService.reset();
        this.client.searchOfficersAndCompaniesByFreeText(null, searchCriteria.searchType, searchCriteria.searchString, searchCriteria.jurisdictionCode, null, searchCriteria.serviceType).subscribe(result => {
            if (result.isSuccess) {
                if (searchCriteria.serviceType == SearchOfficersAndCompaniesByFreeTextSearchType.Premium) {
                    if (searchCriteria.searchType == EntityTypes.Officer) {
                        searchResultState.listedDiligenciaPeople = searchResultState.sortediligenciaPeople = Array<DiligenciaPersonRow>();
                        searchResultState.diligenciaPeopleFilter = new DiligenciaPersonRow();
                        result.data.forEach(i => {
                            if (i?.diligenciaPerson) {
                                searchResultState.sortediligenciaPeople.push({
                                    subjectInfo: i,
                                    nameDto: i.diligenciaPerson.nameDto,
                                    nationalityDto: i.diligenciaPerson.nationalityDto,
                                    dateOfBirth: i.diligenciaPerson.dateOfBirth ?? "",
                                    genderDto: i.diligenciaPerson.genderDto,
                                    countryOfResidenceDto: i.diligenciaPerson.countryOfResidenceDto,
                                    hasRulingFamilyConnection: i.diligenciaPerson.hasRulingFamilyConnection,
                                    dateOfDeath: i.diligenciaPerson.dateOfDeath,
                                    name: '',
                                    isActive: '',
                                    jurisdiction: ''
                                });
                            }
                        });
                    } else {
                        searchResultState.listedDiligenciaOrgs = searchResultState.sortediligenciaOrgs = Array<DiligenciaOrgRow>();
                        searchResultState.diligenciaOrgFilter = new DiligenciaOrgRow();
                        result.data.forEach(i => {
                            searchResultState.sortediligenciaOrgs.push({
                                subjectInfo: i,
                                name: i.companyName,
                                isActive: i.diligenciaOrganisation.isDissolved ? "Inactive" : "Active",
                                jurisdiction: i.diligenciaOrganisation?.jurisdiction ?? "",
                                registrationNumber: i.diligenciaOrganisation.registrationNumber,
                                sectorsDto: i.diligenciaOrganisation.sectorsDto,
                                cityOfRegistrationDto: i.diligenciaOrganisation.registeredCityDto,
                                countryOfRegistrationDto: i.diligenciaOrganisation.registeredCountryDto,
                                licenseNumber: i.diligenciaOrganisation.licenseNumber,
                                isStateOwned: i.diligenciaOrganisation.isStateOwned,
                                nameDto: i.diligenciaOrganisation.companyNameDto,
                                statusDto: i.diligenciaOrganisation.statusDto
                            });
                        });
                    }
                } else {
                    if (searchCriteria.searchType == EntityTypes.Officer) {
                        searchResultState.listedPeople = searchResultState.sortedPeople = Array<PersonRow>();
                        searchResultState.peopleFilter = new PersonRow();
                        result.data.forEach(i => {
                            if (i?.officer) {
                                searchResultState.sortedPeople.push({
                                    subjectInfo: i,
                                    name: i.officer.name ?? "",
                                    companyOp: i.officer.companyName + " (" + i.officer.companyNumber + ")",
                                    isActive: i.officer.isActive ? "Active" : "Inactive",
                                    position: i.officer.position ?? "",
                                    jurisdiction: this.getJurisdictionName(i.officer.jurisdiction) ?? "",
                                    nationality: this.getNationalityName(i.officer.nationality) ?? "",
                                    dateOfBirth: i.officer.dateOfBirth ?? ""
                                });
                            }
                            else if (i?.individual) {
                                searchResultState.sortedPeople.push({
                                    subjectInfo: i,
                                    name: i.individual.name ?? "",
                                    companyOp: i.individual.operationName ?? "",
                                    isActive: "Active",
                                    position: "",
                                    jurisdiction: i.individual.jurisdictionName ?? i.individual.jurisdiction,
                                    nationality: i.individual.nationality ?? "",
                                    dateOfBirth: i.individual.dateOfBirth ?? ""
                                });
                            }
                            else {
                                console.error("Subject of type " + searchCriteria.searchType + " not recognised was returned in the search")
                            }
                        });
                    }
                    else {
                        searchResultState.listedCompanies = searchResultState.sortedCompanies = Array<CompanyRow>();
                        searchResultState.companyFilter = new CompanyRow();
                        result.data.forEach(i => {

                            let jurisdictionName = CountriesService.getJurisdictionName(i.company?.jurisdiction);

                            searchResultState.sortedCompanies.push({
                                subjectInfo: i,
                                name: i.companyName + " (" + i.companyNumber + ")",
                                isActive: i.company.isActive ? "Active" : "Inactive",
                                jurisdiction: jurisdictionName ?? "",
                                address: i.company?.registeredAddress?.fullAddress ?? ""
                            });
                        });
                    }
                }
            
            } else if (result?.errors?.length > 0) {   
                const tooManyResultsError = result?.errors?.find(error => error.propertyName === ExceptionPropertyNames.RESPONSETOOLARGE);
                if (tooManyResultsError?.errorMessage)
                    this.alertService.warn(tooManyResultsError?.errorMessage, { autoClose: true });
                else
                    this.alertService.error(result?.errors[0].errorMessage, { autoClose: false });
            } else {
                this.alertService.warn("Something went wrong. Please try again or contact our support team if the problem persists.", { autoClose: true });
            }            
      
            if (searchCriteria.serviceType == SearchOfficersAndCompaniesByFreeTextSearchType.Standard) {
                searchResultState.showDummyCreateOption = true;
            }
        }, error => { 
            this.errorService.setProblemDetailsFromError(error);
        }).add(() => {
            this.isBusy = false;
        });
      return true;
    }

  private validateCriteria(searchCriteria: SearchCriteria): boolean {
      if (!this.searchCriteria.serviceType) {
        this.alertService.error("Please select a search option.", { autoClose: true })
        return false;
      }
      if (!searchCriteria?.searchString) {
        this.alertService.error("You need to provide a search string.", { autoClose: true })
        return false;
      }
      if (searchCriteria.searchType == EntityTypes.Company &&
        !isNaN(Number(searchCriteria.searchString)) &&
        !searchCriteria?.jurisdictionCode) {
        this.alertService.error("You must provide a jurisdiction if searching on a company number.", { autoClose: true })
        return false;
      }
      return true;
    }

    jurisdictions: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => {
            var list = [];
            if (term.length >= 2) {
                list = CountriesService.getJurisdictionSubSetIncludingAdditionalCodes(term)
            }
            return list;
        })
    );

}
