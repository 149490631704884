import { Injectable } from "@angular/core";
import { DiscountReasonComponent } from "./discount-reason.component";

@Injectable({
    providedIn: 'root'
})
export class DiscountReasonService {
    private lastDiscount: DiscountReasonComponent;

    public open(discount: DiscountReasonComponent) {
        if (this.lastDiscount) {
            this.lastDiscount.close();
        }
        this.lastDiscount = discount;
    }
}
