<button class="ms-3 btn btn-secondary sc-client-export" (click)="openExortClientsModal()">
  <span class="icon-download2" title="download icon"></span>&nbsp;Export
</button>

<ng-template #exportView>
  <div class="modal-header">
    <div class="d-flex col">
      @switch (stage) {
      @case (stageEnum.NOCLIENTS) {<h4 class="modal-title col">Export Clients</h4>}
      @case (stageEnum.INFO || stageEnum.NOCLIENTS) {<h4 class="modal-title col">Export Clients</h4>}
      @case (stageEnum.EXPORTING) {<h4 class="modal-title col">Exporting Clients...</h4>}
      @case (stageEnum.DOWNLOADREADY) {<h4 class="modal-title col">Clients Exported</h4>}
      @case (stageEnum.ERROR) {<h4 class="modal-title col">Error</h4>}
      @default {<div>...</div>}
      }

    </div>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="closeModals()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span
          class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    @switch (stage) {
    @case (stageEnum.NOCLIENTS) {
    <div class="alert alert-danger">
      <p class="mb-0">No Clients to be exported. Introduce/import new Clients</p>
    </div>
    }
    @case (stageEnum.INFO) {
    <!-- too many clients error -->
    <div *ngIf="clientCountMoreThanLimit">
      <div class="alert alert-danger">
        <p>Too many Clients to be exported.</p>
        <p>Current total number of Clients: <strong>{{clientTotal}}</strong>.</p>
        <p>Client Export limit: <strong>{{clientExportLimit}}</strong>.</p>
        <p class="mb-0">Press the "Export" button to download your first {{clientExportLimit}} Clients, or contact
          support for further assistance.</p>
      </div>
    </div>
    <p class="p-2">Number of clients to export: <strong>{{clientCountMoreThanLimit ? '10000' : clientTotal}}</strong>.
    </p>
    <form #f="ngForm" (ngSubmit)="exportClientList(fileName.value)" novalidate>
      <!-- file input -->
      <div class="p-2">
        <label for="file-name-group">File name:</label>
        <div class="input-group" id="file-name-group">
          <input class="form-control" type="text" id="file-name" name="fileName" [placeholder]="defaultExportFileName"
            ngModel required #fileName="ngModel">
          <div class="input-group-append">
            <span class="input-group-text">.xlsx</span>
          </div>
        </div>
      </div>
      <!-- form submit and close-->
      <div class="float-end p-2">
        <button class="btn m-1 bg-secondary text-light" (click)="closeModals()">Cancel</button>
        <button class="btn m-1 bg-primary text-light">
          <span class="icon-download2" title="download icon"></span>&nbsp;Export
        </button>
      </div>
    </form>
    }
    @case (stageEnum.EXPORTING) {
    <span class="loader" title="loading"></span>
    }
    @case (stageEnum.DOWNLOADREADY) {
    <p>The Client Export List ({{fileName}}) will be available in your "downloads folder"</p>
    <p>If your download does not start automatically <a [href]="fileLink" [download]="fileName">Click Here</a></p>
    <div class="float-end p-2">
      <button class="btn m-1 bg-secondary text-light" (click)="closeModals()">Close</button>
    </div>
    }
    @case (stageEnum.ERROR) {
    <div class="alert alert-danger">
      <p>An error has occured</p>
      <p class="mb-0">Please try again or contact support for further assistance.</p>
    </div>
    }
    }
  </div>
</ng-template>