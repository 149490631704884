<div class="alert-container mt-4">
  <div class="row ms-3">
    <div class="col-9 mt-3 ps-1">
      <a class="{{!counter || counter?.alerts == 0 ? 'alert-zero-btn-link' : 'alert-btn-link'}}" title="{{openTitle}}" (click)="selectAlerts()">{{counter ? abbrNumbers(counter.alerts, 1) : "-"}}</a>
      <span class="alert-separator">|</span>
      <a *ngIf="clickable; else nonClickable" title="{{totalTitle}}" class="total-btn-link" (click)="selectTotals()">{{counter ? abbrNumbers(counter.total, 1) : "-"}}</a>
      <ng-template #nonClickable>
        <span title="{{totalTitle}}" class="total-nonbtn-link">{{counter ? abbrNumbers(counter.total, 1) : "-"}}</span>
      </ng-template>
    </div>
    <div class="col-3 mt-4 new-alert">
      <span class="{{icon}} alert-icon rounded float-end" title="{{title}}"></span>
    </div>
  </div>
  <div class="row ps-2 status-title">
    <div>{{title}}</div>
  </div>
</div>


