<div class="modal-header">
  <h4 class="modal-title col">{{ title }}</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [ngClass]="[messageClass]" >
      {{ message }}
    </div>    
    <div *ngIf="incReason" class="mt-1">
      <textarea class="form-control" (ngModelChange)="validation($event)" placeholder="{{ placeholderText }}" cols="50" rows="5" [(ngModel)]="reasonText"></textarea>
      <div *ngIf="isMandatoryNotesEnabled && hasErrors" class="alert alert-danger">
        <div *ngIf="hasErrors">
          Please enter a reason.
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="decline()">{{ btnCancelText }}</button>
    <button *ngIf="btnOkText" type="button" class="btn btn-danger" (click)="accept()">{{ btnOkText }}</button>
  </div>