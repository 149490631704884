import { Component } from '@angular/core';
import { BaseReportSectionComponent } from '../base-client-section.component';
import { CountriesService } from 'src/app/utils/countries.service';


@Component({
  selector: 'mon-business-section',
  templateUrl: './business-section.component.html',
  styleUrls: ['./business-section.component.scss']
})
export class BusinessSectionComponent extends BaseReportSectionComponent {
  getJurisdictionName(code: string) {
    return CountriesService.getJurisdictionName(code);
  }  
}
