import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { FileResponse } from 'src/nswag';
import { CurrentModule } from '../../client-report/client-report.component';

enum Stage {
  NOCLIENTS,
  INFO,
  EXPORTING,
  DOWNLOADREADY,
  ERROR,
}

@Component({
  selector: 'mon-export-clients',
  templateUrl: './export-clients.component.html',
  styleUrls: ['./export-clients.component.scss']
})
export class ExportClientsComponent implements OnInit {
  public clientExportLimit: number = 10000;
  public defaultExportFileName: string;
  public stageEnum = Stage;

  public clientCountMoreThanLimit: boolean;
  public stage: Stage = Stage.INFO;
  public fileName: string;
  public fileLink;

  @ViewChild('exportView') exportTemplateRef: TemplateRef<any>;
  @Input() clientTotal: number;
  @Input() currentModule: CurrentModule;
  @Input() showArchived: boolean = false;
  @Input() exportClientListCallBack: (showArchived: boolean) => Observable<FileResponse>;

  constructor(private sanitizer: DomSanitizer, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.setFileName();
  }

  private setFileName(): void {
    if(this.currentModule) {
      this.defaultExportFileName = `Themis ${this.currentModule} Client Export`;
    }
  }

  public openExortClientsModal() {
    this.clientCountMoreThanLimit = this.clientTotal > this.clientExportLimit;
    this.stage = this.clientTotal > 0 ? Stage.INFO : Stage.NOCLIENTS;

    const modalRef = this.modalService.open(
      this.exportTemplateRef, {
        backdrop : 'static',
        keyboard : false,
        scrollable : true
      }
    );

    modalRef.result.then(() => modalRef.close()).catch((onRejected) => { /* modal closed */ });
  }

  public closeModals() {
    this.modalService.dismissAll();
    this.stage = Stage.INFO;
  }

  public exportClientList(fileName) {
    this.stage = Stage.EXPORTING;
    this.exportClientListCallBack(this.showArchived).subscribe(
      file => {
        this.stage = Stage.DOWNLOADREADY;
        this.fileName = `${fileName.length === 0 ? this.defaultExportFileName : fileName}.xlsx`;
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(file.data)
        a.href = objectUrl
        a.download = this.fileName;
        a.click();
        this.fileLink = this.sanitizer.bypassSecurityTrustResourceUrl(objectUrl);
      },
      error => {
        this.stage = Stage.ERROR;
      }
    );
  }
}

