<div class="toolbar m-3">
  <div class="row">
    <div class="more-options">
      <button class="icon-menu btn btn-outline-primary"></button>
      <div class="dropdown-content">
        <div class="col custom-margin-left dropdown-style">
          <label>Layout:</label>
          <select class="form-select form-control" [disabled]="disableChartStyle" [(ngModel)]="layoutType" (change)="changeLayout()">
            <option value="cola">Clustered</option>
            <option value="dagre">Hierarchical</option>
            <option value="breadthfirst">Breadth first</option>
            <option value="concentric">Concentric</option>
            <option value="circle">Circular</option>
          </select>  
        </div>
        <div class="col custom-margin-auto dropdown-style">
          <input class="form-check-input" type="checkbox" id="inactive" name="inactive" [checked]="dashService.excludeInactive" (change)="changeInactiveState()">
          <label class="form-check-label" for="inactive" title="Hide all inactive subjects on the map">Hide inactives</label>
        </div>
        <div class="col custom-margin-auto dropdown-style">
        <input class="form-check-input" type="checkbox" id="labels" name="labels" [checked]="dashService.showLabels" (change)="changeLabelState()">
        <label class="form-check-label" for="labels" title="Show labels between nodes">Show labels</label>
        
        <!-- Save risk map as a png file  -->
        <div class="icon-download2" (click)="downloadGraph()" >
          <label class="ms-1 save-image">Save Image</label>
        </div>
      </div>
      </div>
    </div>

    <div class="col toolbar-btns">
      <!-- Search bar in the graph session -->
      <div class="search-container">
        <div class="search-bar">
          <div class="input-group">
            <input placeholder="Enter your search" type="text" [(ngModel)]="nodeSearchText" (ngModelChange)="searchNodes($event)">
            <div class="input-group-append">
              <span class="input-group-text icon-magnifier"></span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="dashService.removedCollection?.length > 0">
        <button class="btn btn-outline-primary me-2" (click)="undoLastRemove()" title="Undo the last remove">Undo</button>
      </div>
      <div>
        <button class="btn btn-outline-primary" (click)="resetMap()" title="Resize the map to fit the dislpay area">Fit</button>
      </div>
    </div>
  </div>
</div>

<div (window:resize)="onResize($event)" id="cy" class="cy" style="width:100%"></div>

<!-- Tippy Menu Control -->
<div id="tippyWrapper" hidden>

    <div id="companyHoverElement">
      <span class="fst-italic fw-bold text-success">{{ tippyController.sourceActor?.asCompanyInfo?.isActive ? "Active " : "Inactive " }}</span> Company
      <div>
        {{getFullAddress(tippyController.sourceActor?.asCompanyInfo?.registeredAddress?.fullAddress)}}
      </div>
    </div>

    <div id="officerHoverElement">
      <ng-container [ngTemplateOutlet]="displayMergedOfficers"></ng-container>
    </div>

    <div id="UBOHoverElement">
      {{tippyController.sourceActor?.asUBOInfo?.name}} (UBO)
      <ng-container [ngTemplateOutlet]="displayMergedOfficers"></ng-container>
    </div>

    <div id="individualHoverElement">
      {{tippyController.sourceActor?.asIndividualInfo?.name}}
      <div *ngIf="tippyController.sourceActor?.asIndividualInfo?.dateOfBirth">
        Date of birth: {{tippyController.sourceActor?.asIndividualInfo?.dateOfBirth}}
      </div>
      <div *ngIf="tippyController.sourceActor?.asIndividualInfo?.dateOfBirth">
        Gender: {{tippyController.sourceActor?.asIndividualInfo?.gender}}
      </div>
      <div *ngIf="tippyController.sourceActor?.asIndividualInfo?.nationality">
        Nationality: {{tippyController.sourceActor?.asIndividualInfo?.nationality}}
      </div>
      <div *ngIf="tippyController.sourceActor?.asIndividualInfo?.operationName">
        <div *ngIf="tippyController.sourceActor?.asIndividualInfo?.crimeType; else displayAsInvestigation">
          Criminal Operation: {{tippyController.sourceActor?.asIndividualInfo?.operationName}}
        </div>
        <ng-template #displayAsInvestigation>
          Investigation: {{tippyController.sourceActor?.asIndividualInfo?.operationName}}
        </ng-template>
      </div>
      <div *ngIf="tippyController.sourceActor?.asIndividualInfo?.convictionDate">
        Conviction date: {{tippyController.sourceActor?.asIndividualInfo?.convictionDate}}
      </div>
      <div *ngIf="tippyController.sourceActor?.asIndividualInfo?.sentence">
        Sentence: {{tippyController.sourceActor?.asIndividualInfo?.sentence}}
      </div>
      <div *ngIf="tippyController.sourceActor?.asIndividualInfo?.court">
        Court: {{tippyController.sourceActor?.asIndividualInfo?.court}}
      </div>
      <ng-container [ngTemplateOutlet]="displayMergedOfficers"></ng-container>
    </div>

    <div id="groupHoverElement">
      {{tippyController.sourceActor?.name ? tippyController.sourceActor?.name : "Untitled group"}}
    </div>
    
    <div id="operationHoverElement">
      {{tippyController.sourceActor?.asOperationInfo?.name}} <br />
      {{tippyController.sourceActor?.asOperationInfo?.description}}
    </div>

    <div id="diligenciaPersonHoverElement">
      <div *ngIf="premiumUtils.dtoToSelectedLanguage(tippyController.sourceActor?.asDiligenciaPersonInfo?.genderDto)"><span class="fw-bold">Gender: </span>{{ premiumUtils.dtoToSelectedLanguage(tippyController.sourceActor?.asDiligenciaPersonInfo?.genderDto) }}</div>
      <div *ngIf="tippyController.sourceActor?.asDiligenciaPersonInfo?.dateOfBirth"><span class="fw-bold">Date of Birth: </span>{{ tippyController.sourceActor?.asDiligenciaPersonInfo?.dateOfBirth }}</div>
      <div *ngIf="getPremiumActorsLocation(tippyController.sourceActor)"><span class="fw-bold">Nationality: </span>{{ getPremiumActorsLocation(tippyController.sourceActor) }}</div>
      <div *ngIf="premiumUtils.dtoToSelectedLanguage(tippyController.sourceActor?.asDiligenciaPersonInfo?.countryOfResidenceDto)"><span class="fw-bold">Country of Residence: </span>{{ premiumUtils.dtoToSelectedLanguage(tippyController.sourceActor?.asDiligenciaPersonInfo?.countryOfResidenceDto) }}</div>
      
      <div *ngIf="!premiumUtils.dtoToSelectedLanguage(tippyController.sourceActor?.asDiligenciaPersonInfo?.genderDto) 
        && !tippyController.sourceActor?.asDiligenciaPersonInfo?.dateOfBirth 
        && !getPremiumActorsLocation(tippyController.sourceActor) 
        && !premiumUtils.dtoToSelectedLanguage(tippyController.sourceActor?.asDiligenciaPersonInfo?.countryOfResidenceDto)">
        <ng-container [ngTemplateOutlet]="emptyHoverElementMessage"></ng-container>
      </div>
    </div>

    <div id="diligenciaOrgHoverElement">
      <div *ngIf="getPremiumActorsLocation(tippyController.sourceActor)"><span class="fw-bold">Registered In: </span>{{getPremiumActorsLocation(tippyController.sourceActor)}}</div>
      <div *ngIf="tippyController.sourceActor?.asDiligenciaOrgInfo?.registrationNumber"><span class="fw-bold">Registration No: </span>{{tippyController.sourceActor?.asDiligenciaOrgInfo?.registrationNumber}}</div>
      <div *ngIf="tippyController.sourceActor?.asDiligenciaOrgInfo?.licenseNumber"><span class="fw-bold">Licence No: </span>{{tippyController.sourceActor?.asDiligenciaOrgInfo?.licenseNumber}}</div>
      <div *ngIf="!getPremiumActorsLocation(tippyController.sourceActor) 
        && !tippyController.sourceActor?.asDiligenciaOrgInfo?.registrationNumber 
        && !tippyController.sourceActor?.asDiligenciaOrgInfo?.licenseNumber">
        <ng-container [ngTemplateOutlet]="emptyHoverElementMessage"></ng-container>
      </div>
    </div>

    <ng-template #emptyHoverElementMessage>
      <span>Nothing to show</span>
    </ng-template>

    <div id="idvHoverElement">
      {{tippyController.sourceActor?.name}}
      <div *ngIf="tippyController.sourceActor?.asUserProfile">
        <div>Date of birth: {{getIDVDateOfBirth()}}</div>
        <div *ngIf="tippyController.sourceActor?.asUserProfile.address">
          {{tippyController.sourceActor?.asUserProfile.address.fullAddress}}
        </div>
        <div>
          Identity status: <span class="badge {{statusCodeToClass(tippyController.sourceActor?.asUserProfile.status)}}">{{tippyController.sourceActor?.asUserProfile.status}}</span>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="displayMergedOfficers"></ng-container>
    </div>

    <ng-template #premiumEdge>
      <div *ngIf="tippyController.actorEdge?.description" class="premium-edge">
        <div *ngFor="let relationship of splitEdgeDescriptionByPipe(tippyController.actorEdge?.description)">
            <ng-container 
              [ngTemplateOutlet]="tippyController.isPremiumRelationshipCategory(relationship) ? premiumRelationshipDesc : premiumRelationshipCategory" 
              [ngTemplateOutletContext]="{relationship:relationship}">
            </ng-container>
        </div>        
      </div>
    </ng-template>

    <ng-template #premiumRelationshipDesc let-relationship='relationship'>
      <h6 class="fw-bold" *ngIf="relationship">{{relationship}}</h6>
    </ng-template>

    <ng-template #premiumRelationshipCategory let-relationship='relationship'>
      <ul *ngIf="relationship"><li>{{relationship}}</li></ul>
    </ng-template>

    <ng-template #standardEdge>
      <div>
        {{tippyController.actorEdge?.description}}
      </div>
    </ng-template>


    <div id="edgeHoverElement">
      <div *ngIf="premiumUtils.isPremiumEdge(tippyController?.actorEdge) then premiumEdge else standardEdge"></div>
    </div>

    <div id="tapCanvasElement">
      <div *ngIf="!isLinkingStarted; else showCreateCancelControl">
        <button class="btn btn-block btn-primary" (click)="createNewNode()">Search</button>
      </div>
      <ng-template #showCreateCancelControl>
        <button class="btn btn-block btn-primary" (click)="endAddLink(false)">Cancel link</button>
      </ng-template>
    </div>

    <div id="tapElement">
      <div *ngIf="!isLinkingStarted; else showCreateLinkControls">
        <button class="btn btn-block btn-primary" (click)="setInvestigationSubject()">Select</button>
        <ng-template [ngTemplateOutlet]="showExpandCollapseControls"></ng-template>
        <div class="mt-3">
          <button class="btn btn-block btn-secondary" (click)="startAddLink()">Create link</button>
        </div>
        <ng-template [ngTemplateOutlet]="showGroupControls"></ng-template>
        <ng-template [ngTemplateOutlet]="showRemoveControls"></ng-template>
        <ng-template [ngTemplateOutlet]="showColours"></ng-template>
      </div>
    </div>

    <div id="tapGroupElement">
      <div *ngIf="!isLinkingStarted; else showCreateLinkControls">
        <div *ngIf="!tippyController.groupEditFlag; else editLabel" class="mt-3">
          <button class="btn btn-block btn-primary" (click)="tippyController.groupEditFlag = !tippyController.groupEditFlag">Edit label</button>
        </div>
        <ng-template #editLabel>
          <div class="mt-3">
            <input class="form-control" [(ngModel)]="tippyController.groupName">
          </div>
          <div class="mt-3">
            <button class="btn btn-block btn-primary" (click)="saveGroupLabel()">Save</button>
          </div>
        </ng-template>

        <div class="mt-3">
          <button class="btn btn-block btn-danger" (click)="removeGroup()">Remove Group</button>
        </div>  
        <ng-template [ngTemplateOutlet]="showColours"></ng-template>
      </div>
    </div>


    <div id="tapOperationElement">
      <div *ngIf="!isLinkingStarted; else showCreateLinkControls">
        <button class="btn btn-block btn-primary" disabled>Select</button>
        <ng-template [ngTemplateOutlet]="showExpandCollapseControls"></ng-template>
        <div class="mt-3">
          <button class="btn btn-block btn-secondary" (click)="startAddLink()">Create link</button>
        </div>
        <ng-template [ngTemplateOutlet]="showRemoveControls"></ng-template>
      </div>
    </div>

    <div class="d-block" id="noOperationMergeElement">
      You cannot merge operations
    </div>
    <div class="d-block" id="noCompanyMergeElement">
      You cannot merge companies
    </div>
    <div class="d-block" id="noLAMPSMergeElement">
      You cannot merge subjects that
      have financial crime check data.
    </div>
    <div class="d-block" id="noInvestigationMergeElement">
      You cannot merge the investigated
      subject with another subject.
    </div>
    <div class="d-block" id="noIndividualMergeElement">
      You cannot merge a subject of an operation with another subject.
    </div>
    <div class="d-block" id="noPremiumMergeElement">
      You cannot merge a premium node with another subject.
    </div>
    <div id="mergeElement">
      Do you want to merge <br />
      {{tippyController?.sourceActor?.name}} with {{tippyController?.targetActor?.name}}?
      <div class="mt-3">
        <textarea class="form-control" placeholder="Enter your reason for merging." cols="50" rows="5" [(ngModel)]='tippyController.reason'></textarea>
      </div>
      <div class="mt-3">
        <button class="btn btn-block btn-primary" (click)="completePersonNodeMerge()">Confirm</button>
      </div>
      <div class="mt-3">
        <button class="btn btn-block btn-secondary" (click)="cancelNodeMerge()">Cancel</button>
      </div>
    </div>
    <div id="mergeCompanies">
      Do you want to merge <br />
      {{tippyController?.sourceActor?.name}} with {{tippyController?.targetActor?.name}}?
      <div class="mt-3">
        <textarea class="form-control" placeholder="Enter your reason for merging." cols="50" rows="5" [(ngModel)]='tippyController.reason'></textarea>
      </div>
      <div class="mt-3">
        <button class="btn btn-block btn-primary" (click)="completeCompanyNodeMerge()">Confirm</button>
      </div>
      <div class="mt-3">
        <button class="btn btn-block btn-secondary" (click)="cancelNodeMerge()">Cancel</button>
      </div>
    </div>

    <div id="tapEdgeElement">
      <div *ngIf="!tippyController.expandRemove; else showExpandedRemove">
        <div *ngIf="!tippyController.edgeLabelFlag; else editLabel" class="mt-3">
          <button class="btn btn-block btn-primary" (click)="tippyController.edgeLabelFlag = !tippyController.edgeLabelFlag">Edit label</button>
        </div>
        <ng-template #editLabel>
          <div class="mt-3">
            <input class="form-control" [(ngModel)]="tippyController.edgeLabel">
          </div>
          <div class="mt-3">
            <button class="btn btn-block btn-primary" (click)="saveEdgeLabel()">Save</button>
          </div>
        </ng-template>
        <div class="mt-3">
          <button [disabled]="!canRemoveTippyEdge()" class="btn btn-block btn-danger" (click)="tippyController.expandRemove = !tippyController.expandRemove">Remove</button>
        </div>
        <ng-template [ngTemplateOutlet]="showBorderColours"></ng-template>
      </div>
      <ng-template #showExpandedRemove>
        <div class="mt-3">
          <textarea class="form-control" placeholder="Enter your reason for discounting." cols="50" rows="5" [(ngModel)]='tippyController.reason'></textarea>
        </div>
        <div class="mt-3">
          <button class="btn btn-block btn-danger" (click)="removeTippyEdge()">Remove</button>
        </div>
      </ng-template>
    </div>

    <ng-template #showCreateLinkControls>
      Enter name of relationship
      <div class="mt-3">
        <input class="form-control" placeholder="Enter a name for the link" [(ngModel)]='linkDescription'>
      </div>
      <div class="mt-3">
        <button class="btn btn-block btn-primary" (click)="endAddLink(true)">Confirm link</button>
      </div>
      <div class="mt-3">
        <button class="btn btn-block btn-secondary" (click)="endAddLink(false)">Cancel link</button>
      </div>
    </ng-template>
    <ng-template #showExpandCollapseControls>
      <div class="mt-3">
        <button class="btn btn-block btn-secondary" [disabled]="!canExpandNode()" (click)="expandNode()">Expand</button>
      </div>
      <div *ngIf="tippyController.canCollapseNode" class="mt-3">
        <button class="btn btn-block btn-secondary" (click)="collapseTippyNode()">Collapse</button>
      </div>
    </ng-template>

    <ng-template #showGroupControls>
      <div *ngIf="isInGroup(); else createGroupControls" class="mt-3">
        <div class="mt-3">
          <button class="btn btn-block btn-warning" (click)="removeFromGroup()">Remove from Group</button>
        </div>  
      </div>
      <ng-template #createGroupControls>
        <div *ngIf="!tippyController.expandGroupCreate; else expandedCreate" class="mt-3">
          <button class="btn btn-block btn-secondary" (click)="createGroup()">Create Group</button>
        </div>
        <ng-template #expandedCreate>
          <div class="mt-3">
            <input class="form-control" placeholder="Enter a group name" [(ngModel)]='tippyController.groupName'>
          </div>
          <div class="mt-3">
            <button class="btn btn-block btn-secondary" (click)="createGroup()">Create Group</button>
          </div>
        </ng-template>
                    

      </ng-template>
    </ng-template>

    <ng-template #showRemoveControls>
      <div *ngIf="!tippyController.expandRemove; else expandedRemove" class="mt-3">
        <button [disabled]="!canRemoveTippyNode()" class="btn btn-block btn-danger" (click)="tippyController.expandRemove = !tippyController.expandRemove">Remove</button>
      </div>
      <ng-template #expandedRemove>
        <div class="mt-3">
          <textarea class="form-control" placeholder="Enter your reason for discounting." cols="50" rows="5" [(ngModel)]='tippyController.reason'></textarea>
        </div>
        <div class="mt-3">
          <button class="btn btn-block btn-danger" (click)="removeTippyNode()">Remove</button>
        </div>
      </ng-template>
    </ng-template>

    <ng-template #showColours>
      <ng-template [ngTemplateOutlet]="showBorderColours"></ng-template>
      <div class="row mt-1">
        <div class="col custom-margin-auto">
          Background:
        </div>
        <div class="col">
          <ngx-colors class="float-end"  ngx-colors-trigger [(ngModel)]="tippyController.backgroundColour" [attachTo]="attachColorPaleteTo()"></ngx-colors>
        </div>
      </div>    
    </ng-template>

    <ng-template #showBorderColours>
      <div class="row mt-1">
        <div class="col custom-margin-auto">
          Border:
        </div>
        <div class="col">
          <ngx-colors class="float-end" ngx-colors-trigger [(ngModel)]="tippyController.borderColour" [attachTo]="attachColorPaleteTo()"></ngx-colors>
        </div>
      </div>    
    </ng-template>

    <ng-template #displayMergedOfficers>
      <div *ngFor="let officer of tippyController?.sourceActor?.mergedOfficerList">
        <span class="fst-italic fw-bold text-success">
          {{officer.isActive ? "Active" : "Inactive"}}
        </span>
        <span *ngIf="officer.isUBO">
          (UBO)&nbsp;
        </span>
        <span *ngIf="officer.position">
          &nbsp;{{officer.position}}
        </span>
        <div>
          {{officer.companyName}}<span *ngIf="officer.jurisdictionName">&nbsp;({{officer.jurisdictionName}})</span>
        </div>
        <div *ngIf="officer.startDate">
          Started: {{officer.startDate | date}}
        </div>
        <div *ngIf="officer.endDate">
          Ended: {{officer.endDate | date}}
        </div>
        <span class="d-block">
          {{getFullAddress(officer?.address?.fullAddress)}}
        </span>
      </div>
    </ng-template>
</div>

<app-loader [show]="isBusy"></app-loader>