import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/app/services/dashboard.service';
import { PremiumSearchUtilityService } from 'src/app/utilitities/premium-search-utilities';
import { DueDiligenceClient, EntityTypes } from 'src/nswag';
import { edgeType, IActorBase } from '../../models/Actor';
import { NodeExpandActor, NodeExpansionService } from './node-expansion.service';

@Component({
    selector: 'app-node-expansion',
    templateUrl: './node-expansion.component.html',
    styleUrls: ['./node-expansion.component.scss']
})

export class NodeExpansionComponent implements OnInit {
    @Input() expandActorList: NodeExpandActor[];
    @Input() sourceActor: IActorBase;

    public selectedActorList: NodeExpandActor[];
    public nodesOnGraph: number; 

    constructor(private dashService: DashboardService, private activeModal: NgbActiveModal, private ddClient: DueDiligenceClient, public nodeExpansionService: NodeExpansionService, public premiumUtils: PremiumSearchUtilityService) { }

    ngOnInit() {
        this.selectedActorList = [];
        this.nodesOnGraph = this.dashService.cytoscapeObject.nodes().length;
    }

    public selectedActorsWithinGraphLimits(): boolean {
        return (this.selectedActorList?.length + this.nodesOnGraph) <= this.nodeExpansionService.graphNodeLimit;
    }

    public selectAll(event: any) {
        this.selectedActorList = [];
        if (event.target.checked) {
            for (let a of this.expandActorList) {
                this.selectedActorList.push(a);
            }
        }
    }

    public checkSelectedStatus(expandActor: NodeExpandActor): boolean {
        return this.selectedActorList.findIndex(a => {
            return a.actor.id == expandActor.actor.id;
        }) > -1;
    }

    public selectActor(expandActor: NodeExpandActor) {
        let existingActor = this.selectedActorList.findIndex(a => {
            return a.actor.id == expandActor.actor.id;
        })
        if (existingActor == -1) {
            // Add the selected actor
            this.selectedActorList.push(expandActor);
        }
        else {
            // remove the unselected actor
            this.selectedActorList.splice(existingActor, 1);
        }
    }

    public canAddToGraph(): boolean {
        return this.selectedActorList.length > 0 && this.selectedActorsWithinGraphLimits();
    }

    public isActive(nodeActor: NodeExpandActor): string {
        switch (nodeActor.actor.isActive) {
            case true:
                return "Active";
            case false:
                return "Inactive";
        }
        if (nodeActor.actor.actorType == EntityTypes.UBO) {
            return "Active";
        }
        return "";
    }

    public addToGraph() {
        let autoRefresh = true;
        if (this.canAddToGraph()) {
            for (let expandActor of this.selectedActorList) {
                if (expandActor.actor.actorType == EntityTypes.OfficerCompany) {
                    autoRefresh = false;
                    let self = this;
                    let hasChildren = false;
                    expandActor.actor.findRelationships(this.ddClient, this.dashService,
                        function (source: IActorBase, target: IActorBase, type: edgeType) {
                            self.dashService.hiddenNodesList.push(expandActor.actor.id);
                            self.dashService.addActorNode(target);
                            self.dashService.addActorEdge(target, self.sourceActor, type, "Officer");
                            self.dashService.singleLAMPSGet(target.id);
                            hasChildren = true;
                        },
                        function () {
                            if (hasChildren) {
                                self.dashService.refreshLayout();
                            }
                        });
                }
                else if (this.sourceActor.actorType == EntityTypes.DiligenciaPerson) {
                    let expandActorId = expandActor.actor?.asDiligenciaOrgInfo?.id ?? expandActor.actor?.asDiligenciaPersonInfo?.id;

                    // pull out related actor in the relationships list and build edge
                    var relationships = this.sourceActor.asDiligenciaPersonInfo.relationships.find(rel => rel.diligenciaSubjectId == expandActorId.toString());
                    let edgeDescription = this.nodeExpansionService.diligenciaOrgRelationshipsToEdgeDesc(relationships);

                    this.dashService.bulkLAMPSToCheck.push(expandActor.actor.id);
                    this.dashService.addActorNode(expandActor.actor);
                    this.dashService.addActorEdge(this.sourceActor, expandActor.actor, expandActor.edgeType, edgeDescription);
                }
                else if (this.sourceActor.actorType == EntityTypes.DiligenciaOrganisation) {
                    let expandActorId = expandActor.actor?.asDiligenciaOrgInfo?.id ?? expandActor.actor?.asDiligenciaPersonInfo?.id;

                    // pull out related actor in the relationships list and build edge
                    let relationships = this.sourceActor.asDiligenciaOrgInfo.relationships.find(rel => rel.diligenciaSubjectId == expandActorId.toString());
                    let edgeDescription = this.nodeExpansionService.diligenciaOrgRelationshipsToEdgeDesc(relationships);

                    this.dashService.bulkLAMPSToCheck.push(expandActor.actor.id);
                    this.dashService.addActorNode(expandActor.actor);
                    this.dashService.addActorEdge(this.sourceActor, expandActor.actor, expandActor.edgeType, edgeDescription);
                }
                else {
                    this.dashService.bulkLAMPSToCheck.push(expandActor.actor.id);
                    this.dashService.addActorNode(expandActor.actor);
                    this.dashService.addActorEdge(this.sourceActor, expandActor.actor, expandActor.edgeType);
                }
            }

            this.dashService.bulkLAMPSGet();
        
            if (autoRefresh) {
                this.dashService.refreshLayout();
            }
            this.sourceActor.isExpanded = this.selectedActorList.length == this.expandActorList.length;
            this.closeModal();
        }
    }

    public getPremiumActorsRelationshipTypes(expandActor: NodeExpandActor): string {
        let types: string[] = [];
        let expandActorId = expandActor?.actor?.asDiligenciaPersonInfo?.id ?? expandActor?.actor?.asDiligenciaOrgInfo?.id;
        switch (this.sourceActor?.actorType) {
            case EntityTypes.DiligenciaPerson:
                types = this.sourceActor.asDiligenciaPersonInfo.relationships.find(rel => rel.diligenciaSubjectId == expandActorId).types;
                break;
            case EntityTypes.DiligenciaOrganisation:
                types = this.sourceActor.asDiligenciaOrgInfo.relationships.find(rel => rel.diligenciaSubjectId == expandActorId)?.types;
                break;
        }
        return types?.join(', ');
    }

    public getPremiumActorsName(expandActor: NodeExpandActor): string {
        let nameDto = expandActor.actor?.asDiligenciaPersonInfo?.nameDto ?? expandActor.actor?.asDiligenciaOrgInfo?.companyNameDto;
        return this.premiumUtils.dtoToSelectedLanguage(nameDto);
    }

    public getPremiumActorsLocation(expandActor: NodeExpandActor): string {;
        switch (expandActor?.actor?.actorType) {
            case EntityTypes.DiligenciaOrganisation:
                return this.premiumUtils.getPremiumOrgInfosLocation(expandActor.actor?.asDiligenciaOrgInfo);
            case EntityTypes.DiligenciaPerson:
                return this.premiumUtils.dtoToSelectedLanguage(expandActor.actor?.asDiligenciaPersonInfo?.nationalityDto) ?? "";
        }
    }

    public getFullAddress(expandActor: NodeExpandActor): string {
        switch (expandActor.actor.actorType) {
            case EntityTypes.Individual:
                return expandActor.actor.asIndividualInfo?.address?.fullAddress;
            case EntityTypes.Officer:
            case EntityTypes.OfficerCompany:
                return expandActor.actor.asOfficerInfo?.address?.fullAddress;
            case EntityTypes.Company:
                return expandActor.actor.asCompanyInfo?.registeredAddress?.fullAddress;
            case EntityTypes.UBO:
                return expandActor.actor.asUBOInfo?.address?.fullAddress;
            default:
                return "";
        }
    }

    public getPosition(expandActor: NodeExpandActor): string {
        switch (expandActor.actor.actorType) {
            case EntityTypes.Officer:
            case EntityTypes.OfficerCompany:
                return expandActor.actor.asOfficerInfo?.position ?? expandActor.actor.asOfficerInfo?.relationship;
            case EntityTypes.UBO:
                return "UBO";
            case EntityTypes.Company:
                if (expandActor.actor?.isUBO) {
                    return "Ultimate Business Owner";
                }
                if (expandActor.actor?.isUCC) {
                    return "Ultimate Controlling Company";
                }
                if (expandActor.actor?.asCompanyInfo?.relationship) {
                    return expandActor.actor?.asCompanyInfo?.relationship;
                }
            default:
                return "";
        }
    }

    public getJurisdiction(expandActor: NodeExpandActor): string {
        switch (expandActor.actor.actorType) {
            case EntityTypes.Individual:
                return expandActor.actor.asIndividualInfo?.jurisdictionName;
            case EntityTypes.Officer:
            case EntityTypes.OfficerCompany:
                return expandActor.actor.asOfficerInfo?.jurisdictionName;
            case EntityTypes.UBO:
                return expandActor.actor.asUBOInfo?.jurisdictionName;
            default:
                return "";
        }
    }

    public getCompanyInfo(expandActor: NodeExpandActor): string {
        switch (expandActor.actor.actorType) {
            case EntityTypes.Officer:
            case EntityTypes.OfficerCompany:
                return expandActor.actor.asOfficerInfo?.companyName;
            case EntityTypes.Company:
                return this.sourceActor.name;
            case EntityTypes.Individual:
            case EntityTypes.UBO:
            default:
                return "";
        }
    }

    public closeModal() {
        this.activeModal.close();
    }
}
