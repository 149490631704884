<div class="container">
  <div class="modal-header">
    <h4 class="col">Create Report Request</h4>
    <button type="button" class="close float-end col-auto" aria-label="Close" (click)="dismissModal()">
      <span class="d-flex align-items-center"><span class="btn-label-cancel" aria-hidden="true"></span><span class="icon-cross"></span></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
          <p>
            Creating a report will request for an EDD report on behalf of a Client.
          </p>
      </div>
      <div class="row required-input">
        <label>Tenant</label>
      </div>
      <div class="row">
        <div class="input-group client-nationality">
          <input 
          id="tenantName"
          [(ngModel)]="tenantName" 
          [ngbTypeahead]="tenantTypeAhead" 
          class="form-control mb-2" 
          (focus)="onFocus($event)"
          (blur)="onBlur($event)"
          name="checkTenant"
          #checkTenant="ngModel"
          (change)="updateModal()"
           />
        </div>
        <div *ngIf="((checkTenant.dirty || checkTenant.touched) && !tenantFocus) || this.tenantLoadedClicked" class="row">
          <div *ngIf="(tenantName && !isTenantValid())" class="form-control alert alert-danger">
            Valid Tenant Name is required.
          </div>
          <div *ngIf="(!tenantName)" class="form-control alert alert-danger">
            Tenant Name is required.
          </div>
        </div>
      </div>
      <div class="row mb-4 sc-search-container">
        <div class="col-4 no-padding-left sc-advance-btns">
          <button class="btn btn-primary" [disabled]="!isTenantValid()" (click)="loadTenant()">Load Tenant</button>
        </div>
      </div>
      <div *ngIf="showUser">
        <div class="row mt-2 required-input">
          <label>User</label>
        </div>
        <div class="row">
          <div class="input-group">
            <select class="form-select form-control" id="uuserDropdown" [(ngModel)]="userName" name="checkUser"
            #checkUser="ngModel" (change)="selectUser(userName)">
              <option *ngFor="let range of userList" 
                [value]="range.userId">
                {{range.firstName}} {{range.lastName}}
            </option>
            </select>
        </div>
        <div *ngIf="(userName && !isUserValid()) && (checkUser.dirty || checkUser.touched)" class="row">
          <div class="form-control alert alert-danger">
            Valid User Name is required.
          </div>
        </div>
      </div>
      
      </div>
      <div *ngIf="showReportName">
        <div class="row mt-2 required-input">
          <label>Report Name</label>
        </div>
        <div class="row">
          <div class="input-group required-name">
            <input type="text" required minlength="2" name="name" class="form-control mb-2" [(ngModel)]="reportName" #name="ngModel" (keyup)="resetFlag()">
          </div>
          <div *ngIf="name.invalid || duplicateName" class="row">
            <div *ngIf="(!name.value || name.errors?.required) && name.touched" class="form-control alert alert-danger">
              Report Name is required.
            </div>
            <div *ngIf="duplicateName" class="form-control alert alert-danger">
              Report Name already exists.
            </div>
            <div *ngIf="name.errors?.minlength" class="form-control alert alert-danger">
              Report Name must be at least 2 characters long.
            </div>
          </div>
      </div>
      
      <div class="float-end p-2">
        <button class="btn btn-outline-danger m-1" id="uniqueCancelAssDialog" (click)="dismissModal()">Cancel</button>
        <button id="saveBtn" class="btn btn-primary" [disabled]="!canRequestReport()" (click)="submitRequest()"> 
          <span *ngIf="isSaving" class="spinner-border spinner-border-sm btn-padding"></span>
          <span *ngIf="!isSaving">Create Report Request</span>
        </button>
      </div>
      </div>
    
  </div>
</div>