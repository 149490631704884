import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from 'src/app/profile.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { AlertService } from 'src/app/_alert';
import { SendEmailCommand, SendEmailFileMetadata, UserInteractionClient } from 'src/nswag';

@Component({
  selector: 'app-whistleblow',
  templateUrl: './whistleblow.component.html',
  styleUrls: ['./whistleblow.component.scss']
})
export class WhistleblowComponent implements OnInit, OnDestroy {
  public information: string;
  public subject: string;
  private name: string;
  private email: string;
  public command: SendEmailCommand;
  public selectedFile: any;
  public processedFile: SendEmailFileMetadata;
  public fileTooLarge: boolean = false;
  public uploadedFile: string;
  public maxFileSizeInKilobytes = 2048;
  public selectedFileSizeInKilobytes: number;
  public selectedFileName: string;
  constructor(public dashService: DashboardService, private modalService: NgbModal, private profileService: ProfileService, private alertService: AlertService, public userInteractionClient: UserInteractionClient) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  public onFileChange(event) {
    this.fileTooLarge = false;
    this.selectedFileName = null;
    this.selectedFileSizeInKilobytes = null;
    this.selectedFile = null;
    let file = event.target.files[0];

    this.selectedFileSizeInKilobytes = Math.trunc(file.size / 1000);
    this.selectedFileName = file.name;

    if (event.target.files && event.target.files.length > 0) {
      // Don't allow file sizes over 2MB
      if (this.selectedFileSizeInKilobytes <= this.maxFileSizeInKilobytes) {
        this.selectedFile = file;
      }
      else {
        this.fileTooLarge = true;
      }
    }
  }
  public reportSubject(content: any) {
    this.subject = "";
    this.information = "";
    this.processedFile = null;
    this.selectedFile = null;
    this.selectedFileName = null;
    this.selectedFileSizeInKilobytes = null;

    this.name = this.profileService.fullName;
    this.email = this.profileService.email;

    this.modalService.open(content, { scrollable: true });
  }

  public readFile(inputFile: any) {
    this.processedFile = new SendEmailFileMetadata();
    let reader = new FileReader();
    let self = this;

    self.processedFile.fileName = inputFile.name;
    self.processedFile.fileSize = inputFile.size;
    self.processedFile.fileType = inputFile.type;
    self.processedFile.lastModifiedTime = inputFile.lastModified;
    self.processedFile.lastModifiedDate = inputFile.lastModifiedDate;

    reader.onload = function () {
      self.processedFile.fileAsBase64 = reader.result.toString();
      self.processedFile.fileAsByteArray = btoa(reader.result.toString());
    }

    reader.readAsDataURL(inputFile);
  }

  public submitWhistleblow(modal: any) {
    if (!this.subject) {
      this.alertService.error('Subject is required', { autoClose: true, keepAfterRouteChange: false });
      return false;
    }

    if (!this.information) {
      this.alertService.error('Description is required', { autoClose: true, keepAfterRouteChange: false });
      return false;
    }

    let node = this.dashService.getInvestigation();
    let command = new SendEmailCommand();
    command.actorId = node.id;
    command.body = this.information;
    command.subject = this.subject;
    command.isAnonymised = true;
    
    if (this.selectedFile != null) {
      this.readFile(this.selectedFile)
      command.file = this.processedFile;
    }

    this.userInteractionClient.sendEmail(command).subscribe(result => {
      modal.close();
      this.alertService.success('The subject has been reported successfully', { autoClose: true, keepAfterRouteChange: false });
    },
      (error) => {
        this.alertService.error('Unfortunately, the request failed. Please try again.', { autoClose: true, keepAfterRouteChange: false });
      });
  }
}
