import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdvancedSearchOptionsModule } from '../advanced-search-options/advanced-search-options.module';
import { MonitorModule } from '../monitor/monitor.module';
import { ProfileDetailsModule } from '../profile-details/profile-details.module';
import { ScreeningModule } from '../screening/screening.module';
import { ClientArchiveComponent } from './client-archive.component';



@NgModule({
  declarations: [
    ClientArchiveComponent
  ],
  imports: [
    AdvancedSearchOptionsModule,
    CommonModule,
    MonitorModule,
    ScreeningModule,
    ProfileDetailsModule
  ],
  exports: [ ClientArchiveComponent ]
})
export class ClientArchiveModule { }
