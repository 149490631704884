<div *ngIf="hasDiscounts">
    <div *ngIf="incHeader">
        <br><hr>
        <h4>Discounted Evidence</h4>
    </div>
    <div class="row pt-2">
        <div class="col-sm-8 ">
            <h5>Evidence</h5>
        </div>
        <div class="col-sm-4">
            <h5>Reason</h5>
        </div>
    </div>
    <div *ngFor="let evidence of profile.evidences; let pos=index">
        <div *ngIf="evidence?.discountReason?.isDiscounted" class="row pt-2">
            <div class="col-sm-8">
                <div *ngIf="getOriginalUrl(evidence); else noUrl">
                    <a href="{{evidence.originalUrl}}" target="_blank">{{getTitle(evidence)}}</a>
                </div>
                <ng-template #noUrl>
                    <span>{{evidence?.title}}</span><br/>
                </ng-template>
                <div *ngIf="evidence.assetUrl">
                    <a href="{{evidence.assetUrl}}" target="_blank">{{evidence.assetUrl}}</a>
                </div>
                <!-- <span>Credibility: {{evidence?.credibility ?? "Low"}}<br/></span> -->
                <span *ngIf="getDate(evidence)">Date: {{getDate(evidence)}}<br/></span>
                <div class="pt-2">
                    <span>{{evidence?.summary}}</span>
                </div>
            </div>
            <div class="col-sm-4">
                <span>{{evidence.discountReason.reason}}</span>
            </div>
        </div>
    </div>
</div>
