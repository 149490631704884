import { Injectable } from '@angular/core';
import { DiligenciaLanguage } from '../dashboard/models/Models';
import { EntityTypes, IDiligenciaOrganisationInfo } from 'src/nswag';
import { ActorEdge, IActorBase } from '../dashboard/models/Actor';

@Injectable({
    providedIn: 'root'
})
export class PremiumSearchUtilityService {

    public selectedDiligenciaLanguage: DiligenciaLanguage = DiligenciaLanguage.English;

    public setLanguage(language: DiligenciaLanguage) {
        this.selectedDiligenciaLanguage = language;
    }


    public dtoToSelectedLanguage(nameDto: any): string {
        if (!nameDto) {
            return;
        }
        if (this.selectedDiligenciaLanguage == DiligenciaLanguage.English) {
            return nameDto?.englishName;
        }
        else if (this.selectedDiligenciaLanguage == DiligenciaLanguage.Arabic) {
            return nameDto?.arabicName;
        }
        else if (this.selectedDiligenciaLanguage == DiligenciaLanguage.Farsi) {
            return nameDto?.farsiName;
        }
    }

    public dtoArrayToSelectedLanguage(nameDtoArray: any[]): string[] {
        if (nameDtoArray === undefined || nameDtoArray?.length == 0) {
            return;
        }
        if (this.selectedDiligenciaLanguage == DiligenciaLanguage.English) {
            var result = nameDtoArray.filter(res => res?.englishName).map(ele => ele?.englishName);
            return result;
        }
        else if (this.selectedDiligenciaLanguage == DiligenciaLanguage.Arabic) {
            var result = nameDtoArray.filter(res => res?.arabicName).map(ele => ele?.arabicName);
            return result;
        }
        else if (this.selectedDiligenciaLanguage == DiligenciaLanguage.Farsi) {
            var result = nameDtoArray.filter(res => res?.farsiName).map(ele => ele?.farsiName);
            return result;
        }
    }

    public getPremiumOrgInfosLocation(orgInfo: IDiligenciaOrganisationInfo): string {
        let orgLocation: String[] = []; 
        let country = this.dtoToSelectedLanguage(orgInfo?.registeredCountryDto);
        let city = this.dtoToSelectedLanguage(orgInfo?.registeredCityDto);
        if (country) orgLocation.push(country);
        if (city) orgLocation.push(city);
        return orgLocation?.join(', ');
    }

    public isPremiumEdge(edge: ActorEdge): boolean {
        // if premium-to-premium node, then edge is premium
        return ((edge?.sourceActor?.actorType == EntityTypes.DiligenciaPerson) || (edge?.sourceActor?.actorType == EntityTypes.DiligenciaOrganisation)) && 
                ((edge?.targetActor?.actorType == EntityTypes.DiligenciaPerson) || (edge?.targetActor?.actorType == EntityTypes.DiligenciaOrganisation));
    }

    public isPremiumNode(actor: IActorBase): boolean {
        return (actor?.actorType == EntityTypes.DiligenciaPerson) || (actor?.actorType == EntityTypes.DiligenciaOrganisation);
    }
}
