import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RegulatoryReportType } from 'src/nswag';

@Component({
  selector: 'app-report-selection-dialog',
  templateUrl: './report-selection-dialog.component.html',
  styleUrls: [
    './report-selection-dialog.component.scss',
    '../regulatory-reports-base.component.scss'
  ],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ReportSelectionDialogComponent implements OnInit {

  public reportTypesMap: RegulatoryReportType[];
  public selectedReportType: RegulatoryReportType;

  constructor(private router: Router, private dialogConfig: DynamicDialogConfig, private dialogRef: DynamicDialogRef) {}

  ngOnInit(): void {
    if (this.dialogConfig.data) {
      this.reportTypesMap = this.dialogConfig.data.reportTypesMap;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public launch() {
    this.closeDialog();
    const navigationExtras: NavigationExtras = {
      state: {
        reportTypeId: this.selectedReportType.id,
        reportVersionId: this.selectedReportType.versionId
      }
    };
    this.router.navigate(['regulatory-reports/new'], navigationExtras);
  }
}
