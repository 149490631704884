<div class="row">
    <div class="col search-column">
        <h3>{{ assessment?.name }}</h3>
    </div>
    <div class="col d-flex justify-content-end">
        <div>
            <button class="ms-3 btn btn-secondary add-client-tour recipient-btn-mobile" (click)="existingRecipient()">
                Select Previous Recipients
            </button>
            <button class="ms-3 btn btn-secondary add-client-tour" (click)="addRecipient()">
                +&nbsp;Add New Recipient
            </button>
        </div>
    </div>
</div>
<div class="row detail-table">
    <recipient-list
        [recipientList]="riskAssessmentService.RecipientList"
        [recipientTotal]="riskAssessmentService.RecipientListTotal"
        [currentPage]="riskAssessmentService.RecipientListPage"
        (sendEvent)="sendRecipient($event)"
        (lockEvent)="lockRecipient($event)"
        (viewResultsEvent)="viewResults($event)"
        (selectEvent)="selectRecipient($event)"
        (viewAnswersEvent)="viewAnswers($event)"
        (editEvent)="editRecipient($event)"
        (removeEvent)="removeRecipient($event)"
        (screenEvent)="screenRecipient()"
        (monitorEvent)="monitorRecipient()"
        (changePageEvent)="changePage($event)">
    </recipient-list>
</div>
