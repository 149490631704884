import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BusinessLink, BusinessProfile } from 'src/nswag';

@Component({
  selector: 'mon-business-associations',
  templateUrl: './business-associations.component.html',
  styleUrls: ['./business-associations.component.scss']
})
export class BusinessAssociationsComponent implements OnChanges {

  @Input() profile: any;
  @Input() clientId: string;
  @Input() name: string;
  @Input() incHeader = true;
  @Input() canAddToGraph: boolean;
  @Input() cachedProfiles: string[] = [];
  @Output() newProfile = new EventEmitter<string>();
  @Input() allowDiscounting = true;

  
  public businessLinks: BusinessLink[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.businessLinks = this.profile?.businessLinks;
  }

  addToGraph(profile: any) {
    this.newProfile.emit(profile.resourceId);
  }

  checkCanAddToGraph(profile: BusinessProfile) : boolean {
    if(!this.canAddToGraph) {
      return false;
    }
    return !(this.cachedProfiles.find(v => v == profile.resourceId));
  }

  checkCanDiscount(profile: BusinessProfile) : boolean {    
    if(!this.canAddToGraph) {
      // Allow to discount if not used with the graph
      return true;
    }
    return !(this.cachedProfiles.find(v => v == profile.resourceId));
  }
}
