import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IndividualProfile } from 'src/nswag';
import { IdvDetailBaseComponent } from '../idv-detail/idv-detail-base.component';
import { CurrentModule } from 'src/app/monitor/client-report/client-report.component';

@Component({
  selector: 'app-idv-client-detail',
  templateUrl: './idv-client-detail.component.html',
  styleUrls: ['./idv-client-detail.component.scss']
})
export class IdvClientDetailComponent extends IdvDetailBaseComponent {

  @Input() matchedProfile: IndividualProfile;

  public currentModule: CurrentModule = CurrentModule.EIDV;
  constructor(private activeModal: NgbActiveModal) { 
    super();
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
