<section>
  <br />
  <h2>Personal Details</h2>
  <h4 class="bg-dark text-light">{{premiumUtils.dtoToSelectedLanguage(individual?.nameDto)}}</h4>
  <form [formGroup]="dashService.getInvestigation().reportActorFreeText">
    <div class="table d-table">
      <div class="row">
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="details" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="detailsHeading"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="detailsText"></p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col"
          *ngIf="individual?.dateOfBirth || premiumUtils.dtoToSelectedLanguage(individual?.nationalityDto) || individual?.dateOfDeath">
          <p *ngIf="individual?.dateOfBirth" class="c24 c41">Date Of Birth: {{individual?.dateOfBirth}}</p>
          <p *ngIf="individual?.dateOfDeath" class="c24 c41">Date Of Death: {{individual?.dateOfDeath}}</p>
          <p *ngIf="premiumUtils.dtoToSelectedLanguage(individual?.genderDto)" class="c24 c41">Gender:
            {{premiumUtils.dtoToSelectedLanguage(individual?.genderDto)}}</p>
        </div>
        <div class="col">
          <p *ngIf="premiumUtils.dtoToSelectedLanguage(individual?.nationalityDto)" class="c24 c41">Nationality:
            {{premiumUtils.dtoToSelectedLanguage(individual?.nationalityDto)}}</p>
          <p *ngIf="premiumUtils.dtoToSelectedLanguage(individual?.countryOfResidenceDto)" class="c24 c41">Country of
            Residence: {{premiumUtils.dtoToSelectedLanguage(individual?.countryOfResidenceDto)}}</p>
          <p *ngIf="individual?.hasRulingFamilyConnection" class="c24 c41">Has ruling family connection:
            {{individual?.hasRulingFamilyConnection ? "Yes" : "No"}}</p>
        </div>
      </div>

      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Business Relationship</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="businessRelationship" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="businessRelationship"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="businessRelationshipText"></p>
          </div>
        </div>
      </div>
      <div class="row bg-dark">
        <div class="col">
          <p class="text-light">Risk Level</p>
        </div>
        <div class="col">
          <p class="text-light">Comments</p>
        </div>
      </div>
      <div class="row" [style.background-color]="rowColors.get('ragStatus')">
        <div class="col">
          <select class="form-select form-control" id="uniqueDiliPersOption" formControlName="ragStatus"
            (change)="setRowColor('ragStatus')">
            <option value="" selected></option>
            <option value="High">High</option>
            <option value="Medium">Medium</option>
            <option value="Low">Low</option>
            <option value="N/A">N/A</option>
          </select>
          <div class="form-select form-control" style="display:none">High,Medium,Low,N/A</div>
        </div>
        <div class="col">
          <div class="wrapper">
            <textarea #textarea id="ragStatus" type="textarea" class="discardable-text"
              placeholder="Click here to start adding text" contenteditable="true" (keyup)="resizeAllTextAreas()"
              formControlName="riskLevelComments"></textarea> <span class="edit-icon"> click to edit</span>
            <p id="ragStatusText"></p>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>