import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { isObservable, Observable } from "rxjs";

@Component({
  selector: 'formly-field-primeng-radiobutton',
  template: `
    <div class="flex flex-wrap gap-3">
      <div class="flex align-items-center" *ngFor="let option of radioOptions">
        <p-radioButton
          [name]="field.key.toString()"
          [value]="option.value"
          [formControl]="formControl"
          [formlyAttributes]="field"
          inputId="{{option.id}}">
        </p-radioButton>
        <label [for]="option.id" class="ml-2 mb-0">
          {{ option.label }}
        </label>
      </div>
    </div>
  `,
  styleUrls: [
    '../report-content/report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ]
})
export class FormlyFieldPrimengRadioButton extends FieldType implements OnInit {
  radioOptions: any[] = [];

  ngOnInit() {
    if (isObservable(this.field.props.options)) {
      (this.field.props.options as Observable<any[]>).subscribe(data => this.radioOptions = data);
    } else {
      this.radioOptions = this.field.props.options;
    }
  }

  get formControl(): FormControl {
    return this.field.formControl as FormControl;
  }
}
