<div *ngIf="state.sortedPeople" id="table-heading">
  <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel">
    <thead>
      <tr>
        <th scope="col" sortable="PersonName" (sort)="onSort($event)">Name</th>
        <th scope="col" sortable="PersonCompanyOperationName" (sort)="onSort($event)">Company/Operation</th>
        <th scope="col" sortable="PersonActive" (sort)="onSort($event)">Status</th>
        <th scope="col" sortable="PersonPosition" (sort)="onSort($event)">Position</th>
        <th scope="col" sortable="PersonJurisdiction" (sort)="onSort($event)">Jurisdiction</th>
        <th scope="col" sortable="PersonNationality" (sort)="onSort($event)">Nationality</th>
        <th scope="col" sortable="PersonDateOfBirth" (sort)="onSort($event)">Date of birth</th>
      </tr>
    </thead>
    <tr>
      <td><input class="form-control" type="text" [(ngModel)]="state.peopleFilter.name" placeholder="Filter ..."
          (keyup)="filterPeopleSearch(state.peopleFilter.name,'name')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.peopleFilter.companyOp" placeholder="Filter ..."
          (keyup)="filterPeopleSearch(state.peopleFilter.companyOp,'companyOp')" /></td>

      <td>
        <select class="form-select form-control" [(ngModel)]="state.peopleFilter.isActive"
          (change)="filterPeopleSearch(state.peopleFilter.isActive,'isActive')">
          <option value="">All</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </td>
      <td><input class="form-control" type="text" [(ngModel)]="state.peopleFilter.position" placeholder="Filter ..."
          (keyup)="filterPeopleSearch(state.peopleFilter.position,'position')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.peopleFilter.jurisdiction" placeholder="Filter ..."
          (keyup)="filterPeopleSearch(state.peopleFilter.jurisdiction,'jurisdiction')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.peopleFilter.nationality" placeholder="Filter ..."
          (keyup)="filterPeopleSearch(state.peopleFilter.nationality,'nationality')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.peopleFilter.dateOfBirth" placeholder="Filter ..."
          (keyup)="filterPeopleSearch(state.peopleFilter.dateOfBirth,'dateOfBirth')" /></td>
    </tr>
    <tbody class="filter-result">
      <tr *ngFor="let subject of state.sortedPeople" (click)="selectSubject(subject)">
        <td id="filter">{{subject.name }}</td>
        <td id="filter">{{subject.companyOp}}</td>
        <td id="filter">{{subject.isActive }}</td>
        <td id="filter">{{subject.position}}</td>
        <td id="filter">{{subject.jurisdiction}}</td>
        <td id="filter">{{subject.nationality}}</td>
        <td id="filter">{{subject.dateOfBirth}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="state.sortedCompanies?.length > 0" id="table-body">
  <table class="table table-striped table-themis table-click" aria-labelledby="tableLabel">
    <thead>
      <tr>
        <th scope="col" sortable="CompanyName" (sort)="onSort($event)">Name</th>
        <th scope="col" sortable="CompanyActive" (sort)="onSort($event)">Status</th>
        <th scope="col" sortable="CompanyJurisdiction" (sort)="onSort($event)">Jurisdiction</th>
        <th scope="col" sortable="CompanyAddress" (sort)="onSort($event)">Registered Address</th>
      </tr>
    </thead>
    <tr>
      <td><input class="form-control" type="text" [(ngModel)]="state.companyFilter.name" placeholder="Filter ..."
          (keyup)="filterCompanySearch(state.companyFilter.name,'name')" /></td>
      <td>
        <select class="form-select form-control" [(ngModel)]="state.companyFilter.isActive"
          (change)="filterCompanySearch(state.companyFilter.isActive,'isActive')">
          <option value="">All</option>
          <option value="active">Active</option>
          <option value="inactive">Inactive</option>
        </select>
      </td>
      <td><input class="form-control" type="text" [(ngModel)]="state.companyFilter.jurisdiction"
          placeholder="Filter ..." (keyup)="filterCompanySearch(state.companyFilter.jurisdiction,'jurisdiction')" />
      </td>
      <td><input class="form-control" type="text" [(ngModel)]="state.companyFilter.address" placeholder="Filter ..."
          (keyup)="filterCompanySearch(state.companyFilter.address,'address')" /></td>
    </tr>
    <tbody class="filter-result">
      <tr (click)="selectSubject(subject)" *ngFor="let subject of state.sortedCompanies">
        <td>{{subject.name}}</td>
        <td>{{subject.isActive}}</td>
        <td>{{subject.jurisdiction}}</td>
        <td>{{subject.address}}</td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #diligenciaLanguageSelection>
  <button mat-button class="btn btn-light" (click)="premiumUtils.setLanguage(diligenciaLanguage.English)">English
  </button>
  <button mat-button class="btn btn-light" (click)="premiumUtils.setLanguage(diligenciaLanguage.Arabic)">Arabic
  </button>
  <button mat-button class="btn btn-light" (click)="premiumUtils.setLanguage(diligenciaLanguage.Farsi)">Farsi </button>
</ng-template>

<ng-template #arabicDiligenciaOrgTableHeaders>
  <th scope="col" sortable="DiligenciaCompanyName" (sort)="onSort($event)">اسم</th>
  <th scope="col" sortable="DiligenciaCompanyRegNumber" (sort)="onSort($event)">رقم التسجيل</th>
  <th scope="col" sortable="DiligenciaCompanyStatus" (sort)="onSort($event)">حالة</th>
  <th scope="col" sortable="DiligenciaCompanySectors" (sort)="onSort($event)">القطاعات</th>
  <th scope="col" sortable="DiligenciaCompanyRegisteredCountryCity" (sort)="onSort($event)">بلد ومدينة التسجيل</th>
  <th scope="col" sortable="DiligenciaCompanyLicenseNo" (sort)="onSort($event)">رقم الرخصة</th>
  <th scope="col" sortable="DiligenciaCompanyIsSOE" (sort)="onSort($event)">مؤسسة مملوكة للدولة</th>
</ng-template>

<ng-template #englishDiligenciaOrgTableHeaders>
  <th scope="col" sortable="DiligenciaCompanyName" (sort)="onSort($event)">Name</th>
  <th scope="col" sortable="DiligenciaCompanyRegNumber" (sort)="onSort($event)">Registration Number</th>
  <th scope="col" sortable="DiligenciaCompanyStatus" (sort)="onSort($event)">Status</th>
  <th scope="col" sortable="DiligenciaCompanySectors" (sort)="onSort($event)">Sectors</th>
  <th scope="col" sortable="DiligenciaCompanyRegisteredCountryCity" (sort)="onSort($event)">Country & City of
    Registration</th>
  <th scope="col" sortable="DiligenciaCompanyLicenseNo" (sort)="onSort($event)">License Number</th>
  <th scope="col" sortable="DiligenciaCompanyIsSOE" (sort)="onSort($event)">SOE</th>
</ng-template>

<ng-template #farsiDiligenciaOrgTableHeaders>
  <th scope="col" sortable="DiligenciaCompanyName" (sort)="onSort($event)">اسم</th>
  <th scope="col" sortable="DiligenciaCompanyRegNumber" (sort)="onSort($event)">شماره ثبت</th>
  <th scope="col" sortable="DiligenciaCompanyStatus" (sort)="onSort($event)">موقعیت</th>
  <th scope="col" sortable="DiligenciaCompanySectors" (sort)="onSort($event)">بخش</th>
  <th scope="col" sortable="DiligenciaCompanyRegisteredCountryCity" (sort)="onSort($event)">شهر یا کشور در حاله زندگی
  </th>
  <th scope="col" sortable="DiligenciaCompanyLicenseNo" (sort)="onSort($event)">شماره مجوز</th>
  <th scope="col" sortable="DiligenciaCompanyIsSOE" (sort)="onSort($event)">دولت متعلق به شرکت</th>
</ng-template>


<div *ngIf="state.sortediligenciaOrgs" id="table-heading">
  <ng-container *ngIf="displayPremiumLanguageSelect" [ngTemplateOutlet]="diligenciaLanguageSelection"></ng-container>
  <table class="table table-striped table-themis table-click" aria-labelledby="tableLabel"
    [ngClass]="[premiumUtils.selectedDiligenciaLanguage == diligenciaLanguage.English ? 'text-start' : 'text-end']">
    <thead>
      <tr>
        @switch (premiumUtils.selectedDiligenciaLanguage) {
        @case (diligenciaLanguage.English) {<ng-container
          [ngTemplateOutlet]="englishDiligenciaOrgTableHeaders"></ng-container>}
        @case (diligenciaLanguage.Arabic) {<ng-container
          [ngTemplateOutlet]="arabicDiligenciaOrgTableHeaders"></ng-container>}
        @case (diligenciaLanguage.Farsi) {<ng-container
          [ngTemplateOutlet]="farsiDiligenciaOrgTableHeaders"></ng-container>}
        }
      </tr>
    </thead>
    <tr>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaOrgFilter.nameDto"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaCompanySearch(state.diligenciaOrgFilter.nameDto,'nameDto')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaOrgFilter.registrationNumber"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaCompanySearch(state.diligenciaOrgFilter.registrationNumber,'registrationNumber')" />
      </td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaOrgFilter.statusDto"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaCompanySearch(state.diligenciaOrgFilter.statusDto,'statusDto')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaOrgFilter.sectorsDto"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaCompanySearch(state.diligenciaOrgFilter.sectorsDto,'sectorsDto')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaOrgFilter.countryOfRegistrationDto"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaCompanySearch(state.diligenciaOrgFilter.countryOfRegistrationDto,'countryOfRegistrationDto')" />
      </td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaOrgFilter.licenseNumber"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaCompanySearch(state.diligenciaOrgFilter.licenseNumber,'licenseNumber')" /></td>
      <td>
        <div class="btn-group" role="group">
          <button (click)="filterDiligenciaCompanySearch('true','isStateOwned')" value="true" type="button"
            class="btn btn-light"><span class="icon-check"></span></button>
          <button (click)="filterDiligenciaCompanySearch('','isStateOwned')" value="" type="button"
            class="btn btn-light"><span class="icon-chevrons-expand-horizontal"></span></button>
          <button (click)="filterDiligenciaCompanySearch('false','isStateOwned')" value="false" type="button"
            class="btn btn-light"><span class="icon-cross"></span></button>
        </div>
      </td>
    </tr>
    <tbody class="filter-result">
      <tr (click)="selectSubject(subject)" *ngFor="let subject of state.sortediligenciaOrgs">
        <td>{{premiumUtils.dtoToSelectedLanguage(subject?.nameDto)}}</td>
        <td>{{subject.registrationNumber}}</td>
        <td>{{premiumUtils.dtoToSelectedLanguage(subject.statusDto)}}</td>
        <td>{{premiumUtils.dtoArrayToSelectedLanguage(subject.sectorsDto)?.toString()}}</td>
        <td>{{premiumUtils.dtoToSelectedLanguage(subject.countryOfRegistrationDto)}},
          {{premiumUtils.dtoToSelectedLanguage(subject.cityOfRegistrationDto)}}</td>
        <td>{{subject.licenseNumber}}</td>
        <td><span [ngClass]="[subject.isStateOwned ? 'icon-check' : 'icon-cross']"></span></td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #englishDiligenciaPersonTableHeaders>
  <th scope="col" sortable="DiligenciaPersonName" (sort)="onSort($event)">Name</th>
  <th scope="col" sortable="DiligenciaPersonGender" (sort)="onSort($event)">Gender</th>
  <th scope="col" sortable="DiligenciaPersonNationality" (sort)="onSort($event)">Nationality</th>
  <th scope="col" sortable="DiligenciaPersonCountryOfResidence" (sort)="onSort($event)">Country of Residence</th>
  <th scope="col" sortable="DiligenciaPersonRulingFamilyConnection" (sort)="onSort($event)">Ruling Family Connection
  </th>
  <th scope="col" sortable="DiligenciaPersonDateOfBirth" (sort)="onSort($event)">Date of Birth</th>
  <th scope="col" sortable="DiligenciaPersonDateOfDeath" (sort)="onSort($event)">Date of Death</th>
</ng-template>

<ng-template #arabicDiligenciaPersonTableHeaders>
  <th scope="col" sortable="DiligenciaPersonName" (sort)="onSort($event)">اسم</th>
  <th scope="col" sortable="DiligenciaPersonGender" (sort)="onSort($event)">جنس</th>
  <th scope="col" sortable="DiligenciaPersonNationality" (sort)="onSort($event)">جنسية</th>
  <th scope="col" sortable="DiligenciaPersonCountryOfResidence" (sort)="onSort($event)">بلد الإقامة</th>
  <th scope="col" sortable="DiligenciaPersonRulingFamilyConnection" (sort)="onSort($event)">صلة الأسرة الحاكمة</th>
  <th scope="col" sortable="DiligenciaPersonDateOfBirth" (sort)="onSort($event)">تاريخ الميلاد</th>
  <th scope="col" sortable="DiligenciaPersonDateOfDeath" (sort)="onSort($event)">تاريخ الوفاة</th>
</ng-template>

<ng-template #farsiDiligenciaPersonTableHeaders>
  <th scope="col" sortable="DiligenciaPersonName" (sort)="onSort($event)">اسم</th>
  <th scope="col" sortable="DiligenciaPersonGender" (sort)="onSort($event)">جنسیت</th>
  <th scope="col" sortable="DiligenciaPersonNationality" (sort)="onSort($event)">مليت</th>
  <th scope="col" sortable="DiligenciaPersonCountryOfResidence" (sort)="onSort($event)">کشور در حاله زندگی</th>
  <th scope="col" sortable="DiligenciaPersonRulingFamilyConnection" (sort)="onSort($event)">ارتباط خانوادگی حاکم</th>
  <th scope="col" sortable="DiligenciaPersonDateOfBirth" (sort)="onSort($event)">تاریخ تولد</th>
  <th scope="col" sortable="DiligenciaPersonDateOfDeath" (sort)="onSort($event)">تاریخ مرگ</th>
</ng-template>

<div *ngIf="state.sortediligenciaPeople" id="table-heading">
  <ng-container *ngIf="displayPremiumLanguageSelect" [ngTemplateOutlet]="diligenciaLanguageSelection"></ng-container>
  <table class='table table-striped table-themis table-click' aria-labelledby="tableLabel"
    [ngClass]="[premiumUtils.selectedDiligenciaLanguage == diligenciaLanguage.English ? 'text-start' : 'text-end']">
    <thead>
      <tr>
        @switch (premiumUtils.selectedDiligenciaLanguage) {
        @case (diligenciaLanguage.English) {<ng-container
          [ngTemplateOutlet]="englishDiligenciaPersonTableHeaders"></ng-container>}
        @case (diligenciaLanguage.Arabic) {<ng-container class="text-end"
          [ngTemplateOutlet]="arabicDiligenciaPersonTableHeaders"></ng-container>}
        @case (diligenciaLanguage.Farsi) {<ng-container class="text-end"
          [ngTemplateOutlet]="farsiDiligenciaPersonTableHeaders"></ng-container>}
        }
      </tr>
    </thead>
    <tr>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaPeopleFilter.nameDto"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaPeopleSearch(state.diligenciaPeopleFilter.nameDto,'nameDto')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaPeopleFilter.genderDto"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaPeopleSearch(state.diligenciaPeopleFilter.genderDto,'genderDto')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaPeopleFilter.nationalityDto"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaPeopleSearch(state.diligenciaPeopleFilter.nationalityDto,'nationalityDto')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaPeopleFilter.countryOfResidenceDto"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaPeopleSearch(state.diligenciaPeopleFilter.countryOfResidenceDto,'countryOfResidenceDto')" />
      </td>
      <td>
        <div class="btn-group" role="group">
          <button (click)="filterDiligenciaPeopleSearch('true','hasRulingFamilyConnection')" value="true" type="button"
            class="btn btn-light"><span class="icon-check"></span></button>
          <button (click)="filterDiligenciaPeopleSearch('','hasRulingFamilyConnection')" value="" type="button"
            class="btn btn-light"><span class="icon-chevrons-expand-horizontal"></span></button>
          <button (click)="filterDiligenciaPeopleSearch('false','hasRulingFamilyConnection')" value="false"
            type="button" class="btn btn-light"><span class="icon-cross"></span></button>
        </div>
      </td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaPeopleFilter.dateOfBirth"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaPeopleSearch(state.diligenciaPeopleFilter.dateOfBirth,'dateOfBirth')" /></td>
      <td><input class="form-control" type="text" [(ngModel)]="state.diligenciaPeopleFilter.dateOfDeath"
          placeholder="Filter ..."
          (keyup)="filterDiligenciaPeopleSearch(state.diligenciaPeopleFilter.dateOfDeath,'dateOfDeath')" /></td>
    </tr>
    <tbody class="filter-result">
      <tr *ngFor="let subject of state.sortediligenciaPeople" (click)="selectSubject(subject)">
        <td id="filter">{{premiumUtils.dtoToSelectedLanguage(subject.nameDto)}}</td>
        <td id="filter">{{premiumUtils.dtoToSelectedLanguage(subject.genderDto)}}</td>
        <td id="filter">{{premiumUtils.dtoToSelectedLanguage(subject.nationalityDto)}}</td>
        <td id="filter">{{premiumUtils.dtoToSelectedLanguage(subject.countryOfResidenceDto)}}</td>
        <td id="filter"><span [ngClass]="[subject.hasRulingFamilyConnection ? 'icon-check' : 'icon-cross']"></span></td>
        <td id="filter">{{subject.dateOfBirth}}</td>
        <td id="filter">{{subject.dateOfDeath}}</td>
      </tr>
    </tbody>
  </table>
</div>

<app-loader [show]="isBusy"></app-loader>