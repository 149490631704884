import { Component } from '@angular/core';
import { IdvUserProfile, MediaItem } from 'src/nswag';
import { IdvDetailBaseComponent } from '../idv-detail-base.component';

@Component({
  selector: 'app-idv-docs-detail',
  templateUrl: './idv-docs-detail.component.html',
  styleUrls: ['./idv-docs-detail.component.scss']
})
export class IdvDocsDetailComponent extends IdvDetailBaseComponent {

  public isMovie(document: MediaItem): boolean {
    return document?.name?.indexOf("Video",0) == 0;
  }
  public isImage(document: MediaItem): boolean {
    return document?.name?.indexOf("Image",0) == 0;
  }
  public isIdentity(document: MediaItem) : boolean {
    return document?.name?.indexOf("Identity",0) == 0;
  }

}
