import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertService } from '../../_alert';

@Component({
  selector: 'assessment-search',
  templateUrl: './assessment-search.component.html',
  styleUrls: ['./assessment-search.component.scss']
})
export class AssessmentSearchComponent implements OnInit {

  @Input() search: string;
  @Output() searchChange = new EventEmitter<string>(); 

  constructor(private alertService: AlertService) { 
  }

  ngOnInit(): void {
  }

  public runSearch() {
    this.searchChange.emit(this.search);
  }
}
