import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-primeng-input',
  template: `
    <input
    type="text" 
    pInputText
    [formControl]="formControl"
    [formlyAttributes]="field"
    pTooltip="{{ field.props.tooltip }}"
    tooltipPosition="{{ field.props.tooltipPosition || 'top' }}"/>
  `,
  styleUrls: [
    '../report-content/report-content.component.scss',
    '../regulatory-reports-base.component.scss'
  ],  
})
export class FormlyFieldPrimengInput extends FieldType {
    get formControl(): FormControl {
        return this.field.formControl as FormControl;
    }
}