<div *ngIf="profile?.resourceId; else noProfile">
    <div id="investigations-tabs">
        <ul ngbNav #nav="ngbNav" class="nav-tabs flex-nowrap">
            <li [ngbNavItem]="0" (click)="setTabAsClicked(0)">
                <a ngbNavLink><span title="Profile" aria-label="Profile" class="icon-user"></span></a>
                <ng-template ngbNavContent>
                    <div *ngIf="canClearProfile" class="clear-text">
                        <div class="clear-text1">
                            <a (click)="removeLampsResult()" class="btn link-danger"><button>Clear</button></a>
                        </div>
                    </div>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-individualprofile *ngIf="!isBusiness" [client]="client" [profile]="individualProfile"
                            (clientChange)="onClientChange($event)"></mon-individualprofile>
                        <mon-businessprofile *ngIf="isBusiness" [client]="client" [profile]="businessProfile"
                            (clientChange)="onClientChange($event)"></mon-businessprofile>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="1" (click)="setTabAsClicked(1)">
                <a ngbNavLink>
                    <mon-alerticon [lampsStatus]="client?.matchStatus?.hasSanctions"
                        [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Sanctions"
                        icon="icon-prohibited"></mon-alerticon>
                </a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-sanctions [clientId]="client?.client?.id" [profile]="profile"
                            [name]="name"></mon-sanctions>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" (click)="setTabAsClicked(2)">
                <a ngbNavLink>
                    <mon-alerticon [lampsStatus]="client?.matchStatus?.isPEP"
                        [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="PEPs"
                        icon="icon-library2"></mon-alerticon>
                </a>
                <ng-template ngbNavContent>
                    <div class="card-body overflow-auto text-wrap text-break text-break">
                        <mon-peps *ngIf="!isBusiness" [clientId]="client?.client?.id" [profile]="individualProfile"
                            [name]="name"></mon-peps>
                        <mon-soe *ngIf="isBusiness" [clientId]="client?.client?.id" [profile]="businessProfile"
                            [name]="name"></mon-soe>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="3" (click)="setTabAsClicked(3)">
                <a ngbNavLink>
                    <mon-alerticon [lampsStatus]="client?.matchStatus?.hasLawEnforcement"
                        [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Legal"
                        icon="icon-balance"></mon-alerticon>
                </a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-legal [clientId]="client.client.id" [profile]="profile" [name]="name"
                            [actor]="actor"></mon-legal>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="4" (click)="setTabAsClicked(4)">
                <a ngbNavLink>
                    <mon-alerticon [lampsStatus]="client?.matchStatus?.hasAdvMedia"
                        [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Adverse Media"
                        icon="icon-tv"></mon-alerticon>
                </a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-adversemedia [clientId]="client.client.id" [profile]="profile"
                            [name]="name"></mon-adversemedia>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="5" (click)="setTabAsClicked(5)">
                <a ngbNavLink><span title="Associated Profiles" aria-label="Associated Profiles"
                        class="icon-group-work"></span></a>
                <ng-template ngbNavContent>
                    <div class="card-body  overflow-auto text-wrap text-break text-break">
                        <mon-individual-associations [clientId]="client.client.id" [profile]="profile" [name]="name"
                            [canAddToGraph]="true" [cachedProfiles]="cachedProfiles"
                            (newProfile)="addIndividualToGraph($event)"></mon-individual-associations>
                        <p></p>
                        <p></p>
                        <mon-business-associations [profile]="profile" [clientId]="client.client.id" [name]="name"
                            [canAddToGraph]="true" [cachedProfiles]="cachedProfiles"
                            (newProfile)="addBusinessToGraph($event)"></mon-business-associations>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
</div>

<!-- Display no action -->
<ng-template #noProfile>
    <div id="investigations-tabs">
        <div class="">
            <ul ngbNav #nav="ngbNav" class="nav-tabs flex-nowrap">
                <li [ngbNavItem]="0" (click)="setTabAsClicked(0)">
                    <a ngbNavLink><span title="Profile" aria-label="Profile" class="icon-user"></span></a>
                    <ng-template ngbNavContent>
                        <div *ngIf="canClearProfile" class="clear-text">
                            <div class="clear-text1">
                                <a (click)="removeLampsResult()" class="btn link-danger"><button>Clear</button></a>
                            </div>
                        </div>
                        <div class="card-body card-padding overflow-auto text-wrap text-break text-break">
                            <h5>Profile</h5>
                            <ng-container [ngTemplateOutlet]="displayNoResults"></ng-container>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="1" (click)="setTabAsClicked(1)">
                    <a ngbNavLink>
                        <mon-alerticon [lampsStatus]="client?.matchStatus?.hasSanctions"
                            [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Sanctions"
                            icon="icon-prohibited"></mon-alerticon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card-body card-padding overflow-auto text-wrap text-break text-break">
                            <h5>Sanctions</h5>
                            <ng-container [ngTemplateOutlet]="displayNoResults"></ng-container>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" (click)="setTabAsClicked(2)">
                    <a ngbNavLink>
                        <mon-alerticon [lampsStatus]="client?.matchStatus?.isPEP"
                            [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="PEPs"
                            icon="icon-library2"></mon-alerticon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card-body card-padding overflow-auto text-wrap text-break text-break">
                            <h5>Political Exposure</h5>
                            <ng-container [ngTemplateOutlet]="displayNoResults"></ng-container>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" (click)="setTabAsClicked(3)">
                    <a ngbNavLink>
                        <mon-alerticon [lampsStatus]="hasLawEnforcement"
                            [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Legal"
                            icon="icon-balance"></mon-alerticon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card-body card-padding overflow-auto text-wrap text-break text-break">
                            <h5>Law Enforcement</h5>
                            <ng-container [ngTemplateOutlet]="displayCheckSPIL"></ng-container>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4" (click)="setTabAsClicked(4)">
                    <a ngbNavLink>
                        <mon-alerticon [lampsStatus]="client?.matchStatus?.hasAdvMedia"
                            [alertStatus]="client?.matchStatus?.alertStatus" [alwaysShow]="true" title="Adverse Media"
                            icon="icon-tv"></mon-alerticon>
                    </a>
                    <ng-template ngbNavContent>
                        <div class="card-body card-padding overflow-auto text-wrap text-break text-break">
                            <h5>Adverse Media</h5>
                            <ng-container [ngTemplateOutlet]="displayNoResults"></ng-container>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="5" (click)="setTabAsClicked(5)">
                    <a ngbNavLink><span title="Associated Profiles" aria-label="Associated Profiles"
                            class="icon-group-work"></span></a>
                    <ng-template ngbNavContent>
                        <div class="card-body card-padding overflow-auto text-wrap text-break text-break">
                            <h5>Associated Profiles</h5>
                            <ng-container [ngTemplateOutlet]="displayNoResults"></ng-container>
                        </div>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
</ng-template>

<ng-template #displayNoResults>
    <div *ngIf="actor.hasLAMPS; else runCheck">
        @switch (currentTab) {
        @case (0) {
        <div class="col alert alert-info">
            <p>
                <b>Didn't find a matching profile for {{name}}?</b><br>
                Good news! This means that they are not {{isBusiness ? "a state owned enterprise" : "politically
                exposed"}}, sanctioned or the subject of any financial crime-related adverse media or law enforcement.
            </p>
            <p>If you are aware of any financial crime risk factors concerning {{name}} that are not in the public
                domain, please let us know via the <b>whistleblowing</b> button above and we will investigate.</p>
            <p>If you still have concerns about the financial crime risk associated with {{name}}, you can contact us to
                commission an <b>enhanced due diligence report</b> via the magnifying glass button above.</p>
        </div>
        }
        @case (1) {
        <div class="col alert alert-info">
            Good news! {{name}} is not sanctioned.
        </div>
        }
        @case (2) {
        <div class="col alert alert-info">
            Good news! {{name}} is not {{isBusiness ? "a state owned enterprise" : "politically exposed"}}.
        </div>
        }
        @case (3) {
        <div class="col alert alert-info">
            Good news! {{name}} is not the subject of any financial crime-related law enforcement.
        </div>
        }
        @case (4) {
        <div class="col alert alert-info">
            Good news! {{name}} is not the subject of any financial crime-related adverse media.
        </div>
        }
        @case (5) {
        <div class="col alert alert-info">
            <p>This tab shows the profiles of any associated individuals or companies that could pose a potential
                financial crime risk.</p>
            <p>There are no profiles listed here as {{name}} has not been matched to a profile.
                If you would like to check {{name}} against our corporate directory,
                click on {{name}} on the risk map and select 'Expand' from the menu.</p>
        </div>
        }
        }
    </div>
    <ng-template #runCheck>
        <div *ngIf="client?.client?.inMonitoring == true">
            <div class="col alert alert-info">
                <p>Good news! No matches have been found for {{name}}. </p>
                <p>We will continue to monitor the client and notify you of any changes.</p>
            </div>
        </div>
        <div *ngIf="client?.client?.inMonitoring == false">
            <div class="col alert alert-info">
                <p>Good news! No matches have been found for {{name}}.</p>
                <p>However, if you are privy to knowledge about {{name}} that is not in the public domain, please let us
                    know via the <b>whistleblowing button</b> above, and we will look into it.</p>
                <p>If you still have concerns about potential financial crime exposures with {{name}}, you can talk to
                    us about creating an enhanced due diligence report for you via the <b>investigation button</b>
                    (magnifying glass icon) above.</p>
            </div>
        </div>
        <div *ngIf="!actor.clientId && !profile">
            @switch (currentTab) {
            @case (0) {
            <div class="col alert alert-info">
                Click the 'Screen Client' button to check whether {{name}} is {{isBusiness ? "a state owned enterprise"
                : "politically exposed"}},
                sanctioned or the subject of any financial crime-related adverse media or law enforcement.
            </div>
            }
            @case (1) {
            <div class="col alert alert-info">
                Click the 'Screen Client' button to check whether {{name}} is sanctioned.
            </div>
            }
            @case (2) {
            <div class="col alert alert-info">
                Click the 'Screen Client' button to check whether {{name}} is {{isBusiness ? "a state owned enterprise"
                : "politically exposed"}}.
            </div>
            }
            @case (3) {
            <div class="col alert alert-info">
                Click the 'Screen Client' button to check whether {{name}} is the subject of any financial crime-related
                law enforcement.
            </div>
            }
            @case (4) {
            <div class="col alert alert-info">
                Click the 'Screen Client' button to check whether {{name}} is the subject of any financial crime-related
                adverse media.
            </div>
            }
            @case (5) {
            <div class="col alert alert-info">
                Click the 'Screen Client' button to check whether {{name}} is associated with any individuals or
                companies that may pose a financial crime risk.
            </div>
            }
            }
        </div>
    </ng-template>
</ng-template>

<ng-template #displayCheckSPIL>
    <div *ngIf="isSPIL; else displayNoResults">
        <mon-legal [clientId]="client.client.id" [name]="name" [actor]="actor"></mon-legal>
    </div>
</ng-template>