export const IndustryList = [
  { "Id": "afe301ec-ab16-4083-a24c-001adf5a7838", "Name": "Money Services Businesses" },
  { "Id": "cc35a475-e1cb-457e-9a20-02574f7c0296", "Name": "Police & Security" },
  { "Id": "fea3f905-f48e-4325-ac27-02ab1a6414b2", "Name": "Investment Manager" },
  { "Id": "c6b6429f-1c2d-4cd3-a0a5-0db6c38ae526", "Name": "Antiques" },
  { "Id": "60aab49d-8284-4535-b9d5-1d11d0095a24", "Name": "Fund Manager" },
  { "Id": "b9e2aafd-633d-4fa0-a4ee-2222bb38b3b0", "Name": "Tax Adviser" },
  { "Id": "971c2b49-15ee-4d59-ba67-23df6852ec2a", "Name": "Wealth Manager" },
  { "Id": "ec4422c2-b669-42a7-bc96-27a34e9304cb", "Name": "Auditor" },
  { "Id": "003e3582-722a-441a-b825-29df00757018", "Name": "Web Design & Development" },
  { "Id": "13bc2ef5-1c72-460e-a49e-2a00bb3cffe5", "Name": "Trust & Company Service Provider" },
  { "Id": "4b67917b-dece-4a9f-8a1d-2e4a67f2a87d", "Name": "IT & Software Development" },
  { "Id": "b2a813e3-580a-4b58-9e63-4009a42c249b", "Name": "Car Dealer" },
  { "Id": "9da80bc5-11ab-4d5d-8e9c-4265c0f79f2b", "Name": "Foreign Exchange" },
  { "Id": "639bc81d-3dd7-4675-be3a-43b62c5b54f1", "Name": "Private Bank" },
  { "Id": "e2ff7711-2d8b-40b7-b1d8-48deb9bf5ea8", "Name": "Corporate Bank" },
  { "Id": "f8008706-a256-44cf-97d9-575a6801711b", "Name": "Venture Capital" },
  { "Id": "2ffe1dad-89f3-42ec-9785-5ccdd7c19024", "Name": "Schools & Universities" },
  { "Id": "58ccb5b3-ea33-47cc-bad9-60aa95aaaf1f", "Name": "NGO" },
  { "Id": "bf81e310-363e-4c28-b14f-621a8db60b82", "Name": "Motor Dealer" },
  { "Id": "b5c70c50-1726-4672-a23a-62bbbeabc0c4", "Name": "News & Media" },
  { "Id": "5f5745d9-fcea-49d4-b979-62fed2187001", "Name": "Regulator" },
  { "Id": "f77ba2d3-6d60-47b3-bad7-63143b1ab01d", "Name": "Auctioneer" },
  { "Id": "7fc7e152-9845-49a1-ae85-66640657bec1", "Name": "Technology" },
  { "Id": "51e89a82-645c-4e14-b7cf-67b9630834fc", "Name": "Fashion" },
  { "Id": "57f42224-6f40-4f94-a06e-6800493170b5", "Name": "Industry Association" },
  { "Id": "7f36362e-588b-4342-9d28-6d9201f7df51", "Name": "Insolvency Practitioner" },
  { "Id": "87f8ebd2-a3b5-41cf-aedc-700f3d78ffa4", "Name": "Retailing" },
  { "Id": "9f68b77c-74be-4ab9-b6da-734707202d51", "Name": "Boat Dealer" },
  { "Id": "301c43d6-6303-44d8-b5d4-77391ab3e7b7", "Name": "Legal Professional" },
  { "Id": "8dd250b6-7d2e-46c9-9f0b-77873d4af934", "Name": "Building Society" },
  { "Id": "e20bbfb4-b7ee-4317-bd50-7991a34b0359", "Name": "Real Estate Agent" },
  { "Id": "cf6504cd-496b-4271-8592-79cc07688606", "Name": "Fine Art" },
  { "Id": "b04e8535-f40d-4942-958d-7cfe4a035477", "Name": "Hospitality" },
  { "Id": "72b538a8-afe5-4892-a72b-7ee3eb84af90", "Name": "Mining" },
  { "Id": "6ed45918-955f-41c6-acd6-7fa886c1c604", "Name": "Insurance" },
  { "Id": "75b634ff-8c39-4609-b7ec-7ffb01adc62e", "Name": "Wholesale Banking" },
  { "Id": "2ce40a2b-b29e-4746-b657-81a6e31c675f", "Name": "Risk Management" },
  { "Id": "75915b64-74da-4ca8-9b76-822d6f12eee0", "Name": "Other" },
  { "Id": "e80a978e-5d96-459e-a132-8357b814637f", "Name": "Office Supplies" },
  { "Id": "64b9d81c-1f23-4b56-a367-9053f9f874ce", "Name": "Cyber Security" },
  { "Id": "beb637b2-006c-4015-9e8c-932106523e92", "Name": "Marketing & PR" },
  { "Id": "499f1d6c-011d-4503-a02c-972ce1f2f0a3", "Name": "Printing" },
  { "Id": "768b40a2-c635-45a6-93c8-9c0e29cb22f1", "Name": "Jeweller" },
  { "Id": "1cbc31dd-5c39-43a1-b0a0-ad18733d47d0", "Name": "IT Hardware" },
  { "Id": "9788c8a4-8457-4c3b-a53a-b16e33322bac", "Name": "Publishing" },
  { "Id": "9a952fc9-3841-4744-8739-b6c0a6e6f9f1", "Name": "Construction" },
  { "Id": "95acb01c-7127-4299-be8e-b956a58e13b5", "Name": "Global Payments Provider" },
  { "Id": "64277332-605f-4854-8b15-c346cc546cbc", "Name": "Real Estate Developer" },
  { "Id": "e97a2d87-f382-41ef-a31c-ca4d8531201b", "Name": "Builder" },
  { "Id": "b66781dd-65f9-4b6b-a575-cb2b4a813f23", "Name": "Managed Service Providers" },
  { "Id": "10c391f1-8307-4055-bc65-cb2ba73da904", "Name": "Gambling Operators" },
  { "Id": "464fbdec-9a09-439a-9fa6-cd6fe5448749", "Name": "Consultancy Services" },
  { "Id": "7242daf2-1c5b-492e-ad26-d33d9977f23c", "Name": "Retail Bank" },
  { "Id": "6893da71-9fde-4fcf-918f-d7703ccfc0eb", "Name": "Law Enforcement" },
  { "Id": "78242324-548b-4ca0-80e7-d81783bd12a4", "Name": "Payment Services" },
  { "Id": "9cd172d3-136e-4335-8d1f-e56e60ad7ac5", "Name": "Exchange House" },
  { "Id": "a0e0bb75-b45a-478d-9142-e8b9d9e59d5d", "Name": "Accountant" },
  { "Id": "47a852ad-73b9-4014-882f-ee2ca6fe3df3", "Name": "Government Body" },
  { "Id": "312d548e-f464-433a-8f27-f0e91a236e2e", "Name": "Private Equity" },
  { "Id": "73205f0e-0e84-45cd-8969-f1e97cb337b6", "Name": "Investment Banking" },
  { "Id": "4a82d128-9dec-416f-baa4-fc418b971d8c", "Name": "Wine Merchant" },
];
